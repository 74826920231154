/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "flags/models.proto" (package "rd.flags", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../google/protobuf/timestamp_pb";
import { UUID } from "../proto/uuid/models_pb";
/**
 * Value is a value a Flag can take.
 *
 * @generated from protobuf message rd.flags.Value
 */
export interface Value {
    /**
     * @generated from protobuf field: bool bool_val = 1;
     */
    boolVal: boolean;
    /**
     * @generated from protobuf field: int64 int_val = 2;
     */
    intVal: bigint;
    /**
     * @generated from protobuf field: string string_val = 3;
     */
    stringVal: string;
}
/**
 * Keys are the entities that Flag Values are assigned to.
 *
 * @generated from protobuf message rd.flags.Key
 */
export interface Key {
    /**
     * @generated from protobuf field: rd.flags.Key.Type type = 1;
     */
    type: Key_Type;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID uuid = 2;
     */
    uuid?: UUID;
}
/**
 * @generated from protobuf enum rd.flags.Key.Type
 */
export enum Key_Type {
    /**
     * @generated from protobuf enum value: TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: TYPE_GLOBAL = 1;
     */
    GLOBAL = 1,
    /**
     * @generated from protobuf enum value: TYPE_COMPANY = 2;
     */
    COMPANY = 2,
    /**
     * @generated from protobuf enum value: TYPE_LOCATION = 3;
     */
    LOCATION = 3,
    /**
     * @generated from protobuf enum value: TYPE_STAFF = 4;
     */
    STAFF = 4,
    /**
     * @generated from protobuf enum value: TYPE_DOOER_STAFF = 5;
     */
    DOOER_STAFF = 5,
    /**
     * @generated from protobuf enum value: TYPE_APP = 6;
     */
    APP = 6
}
/**
 * Mapping attaches a Value to a Flag for a given set of Keys.
 *
 * @generated from protobuf message rd.flags.Mapping
 */
export interface Mapping {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.flags.Flag flag = 4;
     */
    flag: Flag;
    /**
     * @generated from protobuf field: rd.flags.Value value = 5;
     */
    value?: Value;
    /**
     * @generated from protobuf field: map<string, rd.flags.Key> keys = 6;
     */
    keys: {
        [key: string]: Key;
    };
    /**
     * @generated from protobuf field: string comment = 7;
     */
    comment: string;
}
/**
 * MappingLog logs changes to Mappings
 *
 * @generated from protobuf message rd.flags.MappingLog
 */
export interface MappingLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.flags.Mapping mapping = 3;
     */
    mapping?: Mapping;
}
/**
 * Flag enumerates the defined flags.
 *
 * @generated from protobuf enum rd.flags.Flag
 */
export enum Flag {
    /**
     * Place permanent flags here
     *
     * @generated from protobuf enum value: FLAG_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: FLAG_ENABLE_ADMIN_SEARCH_UPDATES = 12;
     */
    ENABLE_ADMIN_SEARCH_UPDATES = 12,
    /**
     * collect search endpoint usage data
     *
     * @generated from protobuf enum value: FLAG_LOG_SEARCH_REQUESTS = 13;
     */
    LOG_SEARCH_REQUESTS = 13,
    /**
     * minimum log level to output; 0==all
     *
     * @generated from protobuf enum value: FLAG_LOG_LEVEL = 14;
     */
    LOG_LEVEL = 14,
    /**
     * configure search matching parameters; json string
     *
     * @generated from protobuf enum value: FLAG_SEARCH_MATCH_CONFIG = 15;
     */
    SEARCH_MATCH_CONFIG = 15,
    /**
     * url for download sold sales demo video
     *
     * @generated from protobuf enum value: FLAG_DOWNLOAD_SOLD_SALES_DEMO_URL = 16;
     */
    DOWNLOAD_SOLD_SALES_DEMO_URL = 16,
    /**
     * enable writing to dblog table
     *
     * @generated from protobuf enum value: FLAG_DBLOG = 17;
     */
    DBLOG = 17,
    /**
     * set the maximum number of webauthn pubkeys to match against
     *
     * @generated from protobuf enum value: FLAG_AUTH_KEY_MAX = 18;
     */
    AUTH_KEY_MAX = 18,
    /**
     * enable core banking
     *
     * @generated from protobuf enum value: FLAG_CORE_BANKING = 19;
     */
    CORE_BANKING = 19,
    /**
     * prevents automatic tax rate updates
     *
     * @generated from protobuf enum value: FLAG_FREEZE_TAX_RATE = 20;
     */
    FREEZE_TAX_RATE = 20,
    /**
     * polling interval for updating search indexes in ms
     *
     * @generated from protobuf enum value: FLAG_SEARCH_INDEX_INTERVAL_MS = 21;
     */
    SEARCH_INDEX_INTERVAL_MS = 21,
    /**
     * override domain for frontend search requests
     *
     * @generated from protobuf enum value: FLAG_SEARCH_DOMAIN = 22;
     */
    SEARCH_DOMAIN = 22,
    /**
     * enables showing the issuing card in the financial dashboard
     *
     * @generated from protobuf enum value: FLAG_ENABLE_ISSUING_CARD = 1083;
     */
    ENABLE_ISSUING_CARD = 1083,
    /**
     * used to be FLAG_BALANCE_SHEET
     *
     * @generated from protobuf enum value: FLAG_BALANCE_SHEET = 1086;
     */
    BALANCE_SHEET = 1086,
    /**
     * enables displaying product images
     *
     * @generated from protobuf enum value: FLAG_ENABLE_PRODUCT_IMAGES = 1109;
     */
    ENABLE_PRODUCT_IMAGES = 1109,
    /**
     * use new identifier mapping table
     *
     * @generated from protobuf enum value: FLAG_IDENTIFIER_MAPPING = 1118;
     */
    IDENTIFIER_MAPPING = 1118,
    /**
     * control limit for number of changes
     *
     * @generated from protobuf enum value: FLAG_BUB_UPDATE_LIMIT = 1120;
     */
    BUB_UPDATE_LIMIT = 1120,
    /**
     * control limit for streamed updates
     *
     * @generated from protobuf enum value: FLAG_BUB_RELOAD_LIMIT = 1122;
     */
    BUB_RELOAD_LIMIT = 1122,
    /**
     * enable AI chat
     *
     * @generated from protobuf enum value: FLAG_ENABLE_AI = 1127;
     */
    ENABLE_AI = 1127,
    /**
     * use serializable txn in upsertsale/insertbillpayment
     *
     * @generated from protobuf enum value: FLAG_SERIALIZABLE_UPSERT_SALE = 1139;
     */
    SERIALIZABLE_UPSERT_SALE = 1139,
    /**
     * enable allow discount terms
     *
     * @generated from protobuf enum value: FLAG_ALLOW_DISCOUNT_TERMS = 1141;
     */
    ALLOW_DISCOUNT_TERMS = 1141,
    /**
     * write to and use inventory ledger
     *
     * @generated from protobuf enum value: FLAG_INVENTORY_LEDGER = 1147;
     */
    INVENTORY_LEDGER = 1147,
    /**
     * enable inventory ledger accounting
     *
     * @generated from protobuf enum value: FLAG_INVENTORY_LEDGER_ACCOUNTING = 1151;
     */
    INVENTORY_LEDGER_ACCOUNTING = 1151,
    /**
     * enable updating bill payment date
     *
     * @generated from protobuf enum value: FLAG_UPDATE_BILL_PAYMENT_DATE = 1156;
     */
    UPDATE_BILL_PAYMENT_DATE = 1156,
    /**
     * enable updating sale/purchase date for unpaid charge account sales
     *
     * @generated from protobuf enum value: FLAG_UPDATE_UNPAID_CHARGE_ACCOUNT_SALE_DATE = 1157;
     */
    UPDATE_UNPAID_CHARGE_ACCOUNT_SALE_DATE = 1157,
    /**
     * @generated from protobuf enum value: FLAG_APPLY_REPORT = 1160;
     */
    APPLY_REPORT = 1160,
    /**
     * @generated from protobuf enum value: FLAG_CUSTOM_REPORTS = 1161;
     */
    CUSTOM_REPORTS = 1161,
    /**
     * @generated from protobuf enum value: FLAG_ENABLE_OPEN_ITEM_BALANCE_FORWARD_STATEMENT = 1162;
     */
    ENABLE_OPEN_ITEM_BALANCE_FORWARD_STATEMENT = 1162,
    /**
     * enable the first phase of redesigning bill payment
     *
     * @generated from protobuf enum value: FLAG_RECEIVABLES_REDESIGN_PHASE_1 = 1163;
     */
    RECEIVABLES_REDESIGN_PHASE_1 = 1163
}
// @generated message type with reflection information, may provide speed optimized methods
class Value$Type extends MessageType<Value> {
    constructor() {
        super("rd.flags.Value", [
            { no: 1, name: "bool_val", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "int_val", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "string_val", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Value>): Value {
        const message = { boolVal: false, intVal: 0n, stringVal: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Value>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Value): Value {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool bool_val */ 1:
                    message.boolVal = reader.bool();
                    break;
                case /* int64 int_val */ 2:
                    message.intVal = reader.int64().toBigInt();
                    break;
                case /* string string_val */ 3:
                    message.stringVal = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Value, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool bool_val = 1; */
        if (message.boolVal !== false)
            writer.tag(1, WireType.Varint).bool(message.boolVal);
        /* int64 int_val = 2; */
        if (message.intVal !== 0n)
            writer.tag(2, WireType.Varint).int64(message.intVal);
        /* string string_val = 3; */
        if (message.stringVal !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.stringVal);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.flags.Value
 */
export const Value = new Value$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Key$Type extends MessageType<Key> {
    constructor() {
        super("rd.flags.Key", [
            { no: 1, name: "type", kind: "enum", T: () => ["rd.flags.Key.Type", Key_Type, "TYPE_"] },
            { no: 2, name: "uuid", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Key>): Key {
        const message = { type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Key>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Key): Key {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.flags.Key.Type type */ 1:
                    message.type = reader.int32();
                    break;
                case /* rd.proto.uuid.UUID uuid */ 2:
                    message.uuid = UUID.internalBinaryRead(reader, reader.uint32(), options, message.uuid);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Key, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.flags.Key.Type type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* rd.proto.uuid.UUID uuid = 2; */
        if (message.uuid)
            UUID.internalBinaryWrite(message.uuid, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.flags.Key
 */
export const Key = new Key$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Mapping$Type extends MessageType<Mapping> {
    constructor() {
        super("rd.flags.Mapping", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "flag", kind: "enum", T: () => ["rd.flags.Flag", Flag, "FLAG_"] },
            { no: 5, name: "value", kind: "message", T: () => Value },
            { no: 6, name: "keys", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Key } },
            { no: 7, name: "comment", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Mapping>): Mapping {
        const message = { flag: 0, keys: {}, comment: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Mapping>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Mapping): Mapping {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.flags.Flag flag */ 4:
                    message.flag = reader.int32();
                    break;
                case /* rd.flags.Value value */ 5:
                    message.value = Value.internalBinaryRead(reader, reader.uint32(), options, message.value);
                    break;
                case /* map<string, rd.flags.Key> keys */ 6:
                    this.binaryReadMap6(message.keys, reader, options);
                    break;
                case /* string comment */ 7:
                    message.comment = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap6(map: Mapping["keys"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Mapping["keys"] | undefined, val: Mapping["keys"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Key.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.flags.Mapping.keys");
            }
        }
        map[key ?? ""] = val ?? Key.create();
    }
    internalBinaryWrite(message: Mapping, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.flags.Flag flag = 4; */
        if (message.flag !== 0)
            writer.tag(4, WireType.Varint).int32(message.flag);
        /* rd.flags.Value value = 5; */
        if (message.value)
            Value.internalBinaryWrite(message.value, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.flags.Key> keys = 6; */
        for (let k of Object.keys(message.keys)) {
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Key.internalBinaryWrite(message.keys[k], writer, options);
            writer.join().join();
        }
        /* string comment = 7; */
        if (message.comment !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.comment);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.flags.Mapping
 */
export const Mapping = new Mapping$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MappingLog$Type extends MessageType<MappingLog> {
    constructor() {
        super("rd.flags.MappingLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "mapping", kind: "message", T: () => Mapping }
        ]);
    }
    create(value?: PartialMessage<MappingLog>): MappingLog {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MappingLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MappingLog): MappingLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.flags.Mapping mapping */ 3:
                    message.mapping = Mapping.internalBinaryRead(reader, reader.uint32(), options, message.mapping);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MappingLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.flags.Mapping mapping = 3; */
        if (message.mapping)
            Mapping.internalBinaryWrite(message.mapping, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.flags.MappingLog
 */
export const MappingLog = new MappingLog$Type();
