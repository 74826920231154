/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "api/company/service.proto" (package "rd.api.company", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { FinanceChargeSetting } from "../../company/models_pb";
import { FinancingTerms_Term } from "../../company/models_pb";
import { FinancingTerms_Term_ReferenceDate } from "../../company/models_pb";
import { Customer } from "../../customer/models_pb";
import { PricingTiers_Tier } from "../../company/models_pb";
import { Company_StatementEnvelopeType } from "../../company/models_pb";
import { Company_StatementDay } from "../../company/models_pb";
import { ProductCategory } from "../../company/models_pb";
import { ProductCategory_Type } from "../../company/models_pb";
import { Product } from "../../product/models_pb";
import { AdditionalFees_Fee } from "../../company/models_pb";
import { User } from "../../user/models_pb";
import { Staff } from "../../company/models_pb";
import { CardReader } from "../../payments/models_pb";
import { Money } from "../../proto/money/models_pb";
import { Address } from "../../proto/address/models_pb";
import { CashRegister } from "../../company/models_pb";
import { TaxRate } from "../../company/models_pb";
import { TaxRate_Type } from "../../company/models_pb";
import { Decimal } from "../../proto/decimal/models_pb";
import { Entity } from "../../company/models_pb";
import { BankAccount } from "../../payments/models_pb";
import { Company } from "../../company/models_pb";
import { Location } from "../../company/models_pb";
import { UUID } from "../../proto/uuid/models_pb";
/**
 * Company
 *
 * @generated from protobuf message rd.api.company.GetCompanyForSubdomainReq
 */
export interface GetCompanyForSubdomainReq {
    /**
     * @generated from protobuf field: string subdomain = 1;
     */
    subdomain: string;
}
/**
 * @generated from protobuf message rd.api.company.GetCompanyForSubdomainRes
 */
export interface GetCompanyForSubdomainRes {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated rd.company.Location locations = 3;
     */
    locations: Location[];
    /**
     * @generated from protobuf field: string logo_image_url = 4;
     */
    logoImageUrl: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID default_location_id = 5;
     */
    defaultLocationId?: UUID;
}
/**
 * @generated from protobuf message rd.api.company.GetCompanyReq
 */
export interface GetCompanyReq {
}
/**
 * @generated from protobuf message rd.api.company.GetCompanyRes
 */
export interface GetCompanyRes {
    /**
     * @generated from protobuf field: rd.company.Company company = 1;
     */
    company?: Company;
    /**
     * @generated from protobuf field: rd.payments.BankAccount bank_account = 2;
     */
    bankAccount?: BankAccount;
    /**
     * @generated from protobuf field: rd.company.Entity entity = 3;
     */
    entity?: Entity;
}
// Tax Rates 

/**
 * @generated from protobuf message rd.api.company.CreateCustomTaxRateReq
 */
export interface CreateCustomTaxRateReq {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal rate = 2;
     */
    rate?: Decimal;
}
/**
 * @generated from protobuf message rd.api.company.CreateCustomTaxRateRes
 */
export interface CreateCustomTaxRateRes {
}
/**
 * @generated from protobuf message rd.api.company.UpdateCustomTaxRateReq
 */
export interface UpdateCustomTaxRateReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal rate = 3;
     */
    rate?: Decimal;
}
/**
 * @generated from protobuf message rd.api.company.UpdateCustomTaxRateRes
 */
export interface UpdateCustomTaxRateRes {
}
/**
 * @generated from protobuf message rd.api.company.ReorderCustomTaxRatesReq
 */
export interface ReorderCustomTaxRatesReq {
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID ids = 1;
     */
    ids: UUID[];
}
/**
 * @generated from protobuf message rd.api.company.ReorderCustomTaxRatesRes
 */
export interface ReorderCustomTaxRatesRes {
}
/**
 * @generated from protobuf message rd.api.company.DeactivateCustomTaxRateReq
 */
export interface DeactivateCustomTaxRateReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.company.DeactivateCustomTaxRateRes
 */
export interface DeactivateCustomTaxRateRes {
}
/**
 * @generated from protobuf message rd.api.company.ListTaxRatesForCompanyReq
 */
export interface ListTaxRatesForCompanyReq {
    /**
     * @generated from protobuf field: bool filter_active = 1;
     */
    filterActive: boolean;
    /**
     * @generated from protobuf field: repeated rd.company.TaxRate.Type filter_types = 2;
     */
    filterTypes: TaxRate_Type[];
}
/**
 * @generated from protobuf message rd.api.company.ListTaxRatesForCompanyRes
 */
export interface ListTaxRatesForCompanyRes {
    /**
     * @generated from protobuf field: repeated rd.company.TaxRate tax_rates = 1;
     */
    taxRates: TaxRate[];
}
/**
 * @generated from protobuf message rd.api.company.GetTaxRateReq
 */
export interface GetTaxRateReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.company.GetTaxRateRes
 */
export interface GetTaxRateRes {
    /**
     * @generated from protobuf field: rd.company.TaxRate tax_rate = 1;
     */
    taxRate?: TaxRate;
}
// Stripe 

/**
 * @generated from protobuf message rd.api.company.GetStripeOnboardingURLReq
 */
export interface GetStripeOnboardingURLReq {
}
/**
 * @generated from protobuf message rd.api.company.GetStripeOnboardingURLRes
 */
export interface GetStripeOnboardingURLRes {
    /**
     * @generated from protobuf field: string stripe_onboarding_url = 1;
     */
    stripeOnboardingUrl: string;
}
/**
 * @generated from protobuf message rd.api.company.GetStripeLoginURLReq
 */
export interface GetStripeLoginURLReq {
}
/**
 * @generated from protobuf message rd.api.company.GetStripeLoginURLRes
 */
export interface GetStripeLoginURLRes {
    /**
     * @generated from protobuf field: string stripe_login_url = 1;
     */
    stripeLoginUrl: string;
}
// Location 

/**
 * @generated from protobuf message rd.api.company.GetLocationReq
 */
export interface GetLocationReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 1;
     */
    locationId?: UUID;
}
/**
 * @generated from protobuf message rd.api.company.GetLocationRes
 */
export interface GetLocationRes {
    /**
     * @generated from protobuf field: rd.company.Location location = 1;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.company.CashRegister cash_register = 2;
     */
    cashRegister?: CashRegister;
}
/**
 * @generated from protobuf message rd.api.company.CreateLocationReq
 */
export interface CreateLocationReq {
    /**
     * @generated from protobuf field: string prefix = 1;
     */
    prefix: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string phone = 3;
     */
    phone: string;
    /**
     * @generated from protobuf field: rd.proto.address.Address address = 4;
     */
    address?: Address;
    /**
     * @generated from protobuf field: bool is_active = 5;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: rd.proto.money.Money float = 7;
     */
    float?: Money; // TODO: Make it required.
    /**
     * @generated from protobuf field: string email = 8;
     */
    email: string;
    /**
     * @generated from protobuf field: string display_name = 9;
     */
    displayName: string;
}
/**
 * @generated from protobuf message rd.api.company.CreateLocationRes
 */
export interface CreateLocationRes {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.company.UpdateLocationReq
 */
export interface UpdateLocationReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: string prefix = 2;
     */
    prefix: string;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: string phone = 4;
     */
    phone: string;
    /**
     * @generated from protobuf field: rd.proto.address.Address address = 5;
     */
    address?: Address;
    /**
     * @generated from protobuf field: string email = 8;
     */
    email: string;
    /**
     * @generated from protobuf field: string display_name = 9;
     */
    displayName: string;
}
/**
 * @generated from protobuf message rd.api.company.UpdateLocationRes
 */
export interface UpdateLocationRes {
}
/**
 * @generated from protobuf message rd.api.company.ListLocationsForCompanyReq
 */
export interface ListLocationsForCompanyReq {
    /**
     * @generated from protobuf field: bool filter_active = 1;
     */
    filterActive: boolean;
}
/**
 * @generated from protobuf message rd.api.company.ListLocationsForCompanyRes
 */
export interface ListLocationsForCompanyRes {
    /**
     * @generated from protobuf field: repeated rd.company.Location locations = 1;
     */
    locations: Location[];
    /**
     * @generated from protobuf field: map<string, rd.company.CashRegister> cash_registers_by_id = 2;
     */
    cashRegistersById: {
        [key: string]: CashRegister;
    };
    /**
     * @generated from protobuf field: map<string, rd.company.TaxRate> tax_rates_by_id = 3;
     */
    taxRatesById: {
        [key: string]: TaxRate;
    };
}
/**
 * @generated from protobuf message rd.api.company.ListCardReadersForLocationReq
 */
export interface ListCardReadersForLocationReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 1;
     */
    locationId?: UUID;
}
/**
 * @generated from protobuf message rd.api.company.ListCardReadersForLocationRes
 */
export interface ListCardReadersForLocationRes {
    /**
     * @generated from protobuf field: repeated rd.payments.CardReader card_readers = 1;
     */
    cardReaders: CardReader[];
}
/**
 * @generated from protobuf message rd.api.company.RegisterCardReaderForLocationReq
 */
export interface RegisterCardReaderForLocationReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 1;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: string registration_code = 2;
     */
    registrationCode: string;
    /**
     * @generated from protobuf field: string label = 3;
     */
    label: string;
}
/**
 * @generated from protobuf message rd.api.company.RegisterCardReaderForLocationRes
 */
export interface RegisterCardReaderForLocationRes {
    /**
     * @generated from protobuf field: rd.payments.CardReader card_reader = 1;
     */
    cardReader?: CardReader;
}
/**
 * @generated from protobuf message rd.api.company.ClearCardReaderReq
 */
export interface ClearCardReaderReq {
    /**
     * @generated from protobuf field: string stripe_reader_id = 1;
     */
    stripeReaderId: string;
}
/**
 * @generated from protobuf message rd.api.company.ClearCardReaderRes
 */
export interface ClearCardReaderRes {
}
// Staff 

/**
 * @generated from protobuf message rd.api.company.GetStaffReq
 */
export interface GetStaffReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.company.GetStaffRes
 */
export interface GetStaffRes {
    /**
     * @generated from protobuf field: rd.company.Staff staff = 1;
     */
    staff?: Staff;
}
/**
 * @generated from protobuf message rd.api.company.CreateStaffReq
 */
export interface CreateStaffReq {
    /**
     * @generated from protobuf field: string first_name = 1;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string last_name = 2;
     */
    lastName: string;
    /**
     * @generated from protobuf field: string phone = 3;
     */
    phone: string;
    /**
     * @generated from protobuf field: string email = 4;
     */
    email: string;
    /**
     * @generated from protobuf field: rd.proto.address.Address address = 5;
     */
    address?: Address;
    /**
     * @generated from protobuf field: string code = 6;
     */
    code: string;
    /**
     * @generated from protobuf field: repeated string role_ids = 7;
     */
    roleIds: string[]; // will be deprecated
}
/**
 * @generated from protobuf message rd.api.company.CreateStaffRes
 */
export interface CreateStaffRes {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: string stytch_user_id = 2;
     */
    stytchUserId: string;
}
/**
 * @generated from protobuf message rd.api.company.UpdateStaffReq
 */
export interface UpdateStaffReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: string first_name = 2;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string last_name = 3;
     */
    lastName: string;
    /**
     * @generated from protobuf field: string email = 5;
     */
    email: string;
    /**
     * @generated from protobuf field: rd.proto.address.Address address = 6;
     */
    address?: Address;
    /**
     * @generated from protobuf field: bool is_active = 7;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: string code = 8;
     */
    code: string;
    /**
     * @generated from protobuf field: repeated string role_ids = 9;
     */
    roleIds: string[];
}
/**
 * @generated from protobuf message rd.api.company.UpdateStaffRes
 */
export interface UpdateStaffRes {
}
/**
 * @generated from protobuf message rd.api.company.GetCurrentUserReq
 */
export interface GetCurrentUserReq {
}
/**
 * @generated from protobuf message rd.api.company.GetCurrentUserRes
 */
export interface GetCurrentUserRes {
    /**
     * @generated from protobuf field: rd.user.User user = 1;
     */
    user?: User;
}
/**
 * @generated from protobuf message rd.api.company.ListStaffsForCompanyReq
 */
export interface ListStaffsForCompanyReq {
}
/**
 * @generated from protobuf message rd.api.company.ListStaffsForCompanyRes
 */
export interface ListStaffsForCompanyRes {
    /**
     * @generated from protobuf field: repeated rd.company.Staff staffs = 1;
     */
    staffs: Staff[];
}
/**
 * @generated from protobuf message rd.api.company.DeactivateStaffReq
 */
export interface DeactivateStaffReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.company.DeactivateStaffRes
 */
export interface DeactivateStaffRes {
}
/**
 * @generated from protobuf message rd.api.company.ReactivateStaffReq
 */
export interface ReactivateStaffReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.company.ReactivateStaffRes
 */
export interface ReactivateStaffRes {
}
/**
 * @generated from protobuf message rd.api.company.DeactivateStaffWebAuthnKeysReq
 */
export interface DeactivateStaffWebAuthnKeysReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.company.DeactivateStaffWebAuthnKeysRes
 */
export interface DeactivateStaffWebAuthnKeysRes {
}
/**
 * @generated from protobuf message rd.api.company.ListAuthorizedEndpointsReq
 */
export interface ListAuthorizedEndpointsReq {
}
/**
 * @generated from protobuf message rd.api.company.ListAuthorizedEndpointsRes
 */
export interface ListAuthorizedEndpointsRes {
    /**
     * @generated from protobuf field: repeated string endpoints = 1;
     */
    endpoints: string[];
}
/**
 * @generated from protobuf message rd.api.company.UpdateStaffPermissionsReq
 */
export interface UpdateStaffPermissionsReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: repeated string role_ids = 2;
     */
    roleIds: string[];
}
/**
 * @generated from protobuf message rd.api.company.UpdateStaffPermissionsRes
 */
export interface UpdateStaffPermissionsRes {
}
// Additional Fees 

/**
 * @generated from protobuf message rd.api.company.CreateAdditionalFeeReq
 */
export interface CreateAdditionalFeeReq {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money fee = 2;
     */
    fee?: Money;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal fee_percent = 3;
     */
    feePercent?: Decimal;
    /**
     * @generated from protobuf field: bool is_taxable = 4;
     */
    isTaxable: boolean;
}
/**
 * @generated from protobuf message rd.api.company.CreateAdditionalFeeRes
 */
export interface CreateAdditionalFeeRes {
    /**
     * @generated from protobuf field: string id_string = 1;
     */
    idString: string;
}
/**
 * @generated from protobuf message rd.api.company.UpdateAdditionalFeeReq
 */
export interface UpdateAdditionalFeeReq {
    /**
     * @generated from protobuf field: string id_string = 1;
     */
    idString: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money fee = 3;
     */
    fee?: Money;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal fee_percent = 4;
     */
    feePercent?: Decimal;
    /**
     * @generated from protobuf field: bool is_taxable = 5;
     */
    isTaxable: boolean;
}
/**
 * @generated from protobuf message rd.api.company.UpdateAdditionalFeeRes
 */
export interface UpdateAdditionalFeeRes {
}
/**
 * @generated from protobuf message rd.api.company.DeleteAdditionalFeeReq
 */
export interface DeleteAdditionalFeeReq {
    /**
     * @generated from protobuf field: string id_string = 1;
     */
    idString: string;
}
/**
 * @generated from protobuf message rd.api.company.DeleteAdditionalFeeRes
 */
export interface DeleteAdditionalFeeRes {
}
/**
 * @generated from protobuf message rd.api.company.ReorderAdditionalFeesReq
 */
export interface ReorderAdditionalFeesReq {
    /**
     * @generated from protobuf field: repeated string id_strings = 1;
     */
    idStrings: string[];
}
/**
 * @generated from protobuf message rd.api.company.ReorderAdditionalFeesRes
 */
export interface ReorderAdditionalFeesRes {
}
/**
 * @generated from protobuf message rd.api.company.ListAdditionalFeesForCompanyReq
 */
export interface ListAdditionalFeesForCompanyReq {
}
/**
 * @generated from protobuf message rd.api.company.ListAdditionalFeesForCompanyRes
 */
export interface ListAdditionalFeesForCompanyRes {
    /**
     * @generated from protobuf field: repeated rd.company.AdditionalFees.Fee fees = 1;
     */
    fees: AdditionalFees_Fee[];
}
/**
 * @generated from protobuf message rd.api.company.ListProductsForFeeReq
 */
export interface ListProductsForFeeReq {
    /**
     * @generated from protobuf field: string id_string = 1;
     */
    idString: string;
}
/**
 * @generated from protobuf message rd.api.company.ListProductsForFeeRes
 */
export interface ListProductsForFeeRes {
    /**
     * @generated from protobuf field: repeated rd.product.Product products = 1;
     */
    products: Product[];
}
/**
 * Product Category V2
 *
 * @generated from protobuf message rd.api.company.CreateProductCategoryV2Req
 */
export interface CreateProductCategoryV2Req {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.company.ProductCategory.Type type = 2;
     */
    type: ProductCategory_Type;
    /**
     * @generated from protobuf field: bool is_default = 3;
     */
    isDefault: boolean;
}
/**
 * @generated from protobuf message rd.api.company.CreateProductCategoryV2Res
 */
export interface CreateProductCategoryV2Res {
}
/**
 * @generated from protobuf message rd.api.company.UpdateProductCategoryV2Req
 */
export interface UpdateProductCategoryV2Req {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.company.ProductCategory.Type type = 3;
     */
    type: ProductCategory_Type;
    /**
     * @generated from protobuf field: bool is_default = 4;
     */
    isDefault: boolean;
}
/**
 * @generated from protobuf message rd.api.company.UpdateProductCategoryV2Res
 */
export interface UpdateProductCategoryV2Res {
}
/**
 * @generated from protobuf message rd.api.company.ListProductCategoriesForCompanyV2Req
 */
export interface ListProductCategoriesForCompanyV2Req {
}
/**
 * @generated from protobuf message rd.api.company.ListProductCategoriesForCompanyV2Res
 */
export interface ListProductCategoriesForCompanyV2Res {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID default_department_id = 1;
     */
    defaultDepartmentId?: UUID;
    /**
     * @generated from protobuf field: repeated rd.company.ProductCategory departments = 2;
     */
    departments: ProductCategory[];
    /**
     * @generated from protobuf field: repeated rd.company.ProductCategory classes = 3;
     */
    classes: ProductCategory[];
    /**
     * @generated from protobuf field: repeated rd.company.ProductCategory finelines = 4;
     */
    finelines: ProductCategory[];
}
/**
 * @generated from protobuf message rd.api.company.ListProductsForCategoryV2Req
 */
export interface ListProductsForCategoryV2Req {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.company.ListProductsForCategoryV2Res
 */
export interface ListProductsForCategoryV2Res {
    /**
     * @generated from protobuf field: repeated rd.product.Product products = 1;
     */
    products: Product[];
}
/**
 * @generated from protobuf message rd.api.company.DeactivateProductCategoryV2Req
 */
export interface DeactivateProductCategoryV2Req {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.company.DeactivateProductCategoryV2Res
 */
export interface DeactivateProductCategoryV2Res {
}
// Company 

/**
 * @generated from protobuf message rd.api.company.UpdateCompanyGeneralSettingsReq
 */
export interface UpdateCompanyGeneralSettingsReq {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string website_url = 2;
     */
    websiteUrl: string;
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyGeneralSettingsRes
 */
export interface UpdateCompanyGeneralSettingsRes {
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanySecuritySettingsReq
 */
export interface UpdateCompanySecuritySettingsReq {
    /**
     * @generated from protobuf field: int32 pos_inactivity_timeout_minutes = 1;
     */
    posInactivityTimeoutMinutes: number;
    /**
     * @generated from protobuf field: bool autolock_after_checkout = 2;
     */
    autolockAfterCheckout: boolean;
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanySecuritySettingsRes
 */
export interface UpdateCompanySecuritySettingsRes {
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyStatementSettingsReq
 */
export interface UpdateCompanyStatementSettingsReq {
    /**
     * @generated from protobuf field: rd.company.Company.StatementDay statement_day = 1;
     */
    statementDay: Company_StatementDay;
    /**
     * @generated from protobuf field: rd.company.Company.StatementEnvelopeType statement_envelope_type = 3;
     */
    statementEnvelopeType: Company_StatementEnvelopeType;
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyStatementSettingsRes
 */
export interface UpdateCompanyStatementSettingsRes {
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyGeneralCommunicationsSettingsReq
 */
export interface UpdateCompanyGeneralCommunicationsSettingsReq {
    /**
     * @generated from protobuf field: int32 default_quote_expiry_days = 2;
     */
    defaultQuoteExpiryDays: number;
    /**
     * @generated from protobuf field: string return_policy = 3;
     */
    returnPolicy: string;
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyGeneralCommunicationsSettingsRes
 */
export interface UpdateCompanyGeneralCommunicationsSettingsRes {
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyShouldAutoSubmitContractorRewardsReq
 */
export interface UpdateCompanyShouldAutoSubmitContractorRewardsReq {
    /**
     * @generated from protobuf field: bool should_auto_submit_contractor_rewards = 1;
     */
    shouldAutoSubmitContractorRewards: boolean;
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyShouldAutoSubmitContractorRewardsRes
 */
export interface UpdateCompanyShouldAutoSubmitContractorRewardsRes {
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyContractorRewardsSettingsReq
 */
export interface UpdateCompanyContractorRewardsSettingsReq {
    /**
     * @generated from protobuf field: string contractor_rewards_bonus_code = 1;
     */
    contractorRewardsBonusCode: string;
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyContractorRewardsSettingsRes
 */
export interface UpdateCompanyContractorRewardsSettingsRes {
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyIsDiscountShownToCustomersReq
 */
export interface UpdateCompanyIsDiscountShownToCustomersReq {
    /**
     * @generated from protobuf field: bool is_discount_shown_to_customers = 1;
     */
    isDiscountShownToCustomers: boolean;
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyIsDiscountShownToCustomersRes
 */
export interface UpdateCompanyIsDiscountShownToCustomersRes {
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyIsPriceShownOnWillCallReq
 */
export interface UpdateCompanyIsPriceShownOnWillCallReq {
    /**
     * @generated from protobuf field: bool is_price_shown_on_will_call = 1;
     */
    isPriceShownOnWillCall: boolean;
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyIsPriceShownOnWillCallRes
 */
export interface UpdateCompanyIsPriceShownOnWillCallRes {
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyIsColorFormulaHiddenReq
 */
export interface UpdateCompanyIsColorFormulaHiddenReq {
    /**
     * @generated from protobuf field: bool is_color_formula_hidden = 1;
     */
    isColorFormulaHidden: boolean;
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyIsColorFormulaHiddenRes
 */
export interface UpdateCompanyIsColorFormulaHiddenRes {
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyIsFinancingTermsShownOnInvoiceReq
 */
export interface UpdateCompanyIsFinancingTermsShownOnInvoiceReq {
    /**
     * @generated from protobuf field: bool is_financing_terms_shown_on_invoice = 1;
     */
    isFinancingTermsShownOnInvoice: boolean;
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyIsFinancingTermsShownOnInvoiceRes
 */
export interface UpdateCompanyIsFinancingTermsShownOnInvoiceRes {
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyIsCardSurchargeTaxExemptReq
 */
export interface UpdateCompanyIsCardSurchargeTaxExemptReq {
    /**
     * @generated from protobuf field: bool is_card_surcharge_tax_exempt = 1;
     */
    isCardSurchargeTaxExempt: boolean;
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyIsCardSurchargeTaxExemptRes
 */
export interface UpdateCompanyIsCardSurchargeTaxExemptRes {
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyIsLoyaltyProgramEnabledReq
 */
export interface UpdateCompanyIsLoyaltyProgramEnabledReq {
    /**
     * @generated from protobuf field: bool is_loyalty_program_enabled = 1;
     */
    isLoyaltyProgramEnabled: boolean;
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyIsLoyaltyProgramEnabledRes
 */
export interface UpdateCompanyIsLoyaltyProgramEnabledRes {
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyLoyaltyProgramPolicyReq
 */
export interface UpdateCompanyLoyaltyProgramPolicyReq {
    /**
     * @generated from protobuf field: string loyalty_program_policy = 1;
     */
    loyaltyProgramPolicy: string;
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyLoyaltyProgramPolicyRes
 */
export interface UpdateCompanyLoyaltyProgramPolicyRes {
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq
 */
export interface UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq {
    /**
     * @generated from protobuf field: bool should_auto_sign_out_after_sale = 1;
     */
    shouldAutoSignOutAfterSale: boolean;
}
/**
 * @generated from protobuf message rd.api.company.UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes
 */
export interface UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes {
}
// Pricing Tiers 

/**
 * @generated from protobuf message rd.api.company.CreatePricingTierReq
 */
export interface CreatePricingTierReq {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: bool is_default = 2;
     */
    isDefault: boolean;
    /**
     * @generated from protobuf field: bool is_rule_based = 3;
     */
    isRuleBased: boolean;
}
/**
 * @generated from protobuf message rd.api.company.CreatePricingTierRes
 */
export interface CreatePricingTierRes {
    /**
     * @generated from protobuf field: string id_string = 1;
     */
    idString: string;
}
/**
 * @generated from protobuf message rd.api.company.UpdatePricingTierReq
 */
export interface UpdatePricingTierReq {
    /**
     * @generated from protobuf field: string id_string = 1;
     */
    idString: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: bool is_default = 3;
     */
    isDefault: boolean;
}
/**
 * @generated from protobuf message rd.api.company.UpdatePricingTierRes
 */
export interface UpdatePricingTierRes {
}
/**
 * @generated from protobuf message rd.api.company.DeletePricingTierReq
 */
export interface DeletePricingTierReq {
    /**
     * @generated from protobuf field: string id_string = 1;
     */
    idString: string;
}
/**
 * @generated from protobuf message rd.api.company.DeletePricingTierRes
 */
export interface DeletePricingTierRes {
}
/**
 * @generated from protobuf message rd.api.company.ReorderPricingTiersReq
 */
export interface ReorderPricingTiersReq {
    /**
     * @generated from protobuf field: repeated string id_strings = 1;
     */
    idStrings: string[];
}
/**
 * @generated from protobuf message rd.api.company.ReorderPricingTiersRes
 */
export interface ReorderPricingTiersRes {
}
/**
 * @generated from protobuf message rd.api.company.ListPricingTiersForCompanyReq
 */
export interface ListPricingTiersForCompanyReq {
}
/**
 * @generated from protobuf message rd.api.company.ListPricingTiersForCompanyRes
 */
export interface ListPricingTiersForCompanyRes {
    /**
     * @generated from protobuf field: repeated rd.company.PricingTiers.Tier tiers = 1;
     */
    tiers: PricingTiers_Tier[];
    /**
     * @generated from protobuf field: string default_id_string = 2;
     */
    defaultIdString: string;
}
/**
 * @generated from protobuf message rd.api.company.ListCustomersForTierReq
 */
export interface ListCustomersForTierReq {
    /**
     * @generated from protobuf field: string id_string = 1;
     */
    idString: string;
}
/**
 * @generated from protobuf message rd.api.company.ListCustomersForTierRes
 */
export interface ListCustomersForTierRes {
    /**
     * @generated from protobuf field: repeated rd.customer.Customer customers = 1;
     */
    customers: Customer[];
}
// Financing terms 

/**
 * @generated from protobuf message rd.api.company.CreateFinancingTermReq
 */
export interface CreateFinancingTermReq {
    /**
     * @generated from protobuf field: bool is_default = 1;
     */
    isDefault: boolean;
    /**
     * @generated from protobuf field: rd.company.FinancingTerms.Term.ReferenceDate reference_date = 2;
     */
    referenceDate: FinancingTerms_Term_ReferenceDate;
    /**
     * @generated from protobuf field: int32 due_days = 3;
     */
    dueDays: number;
    /**
     * @generated from protobuf field: int32 discount_reference_days = 4;
     */
    discountReferenceDays: number;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal discount_rate = 5;
     */
    discountRate?: Decimal;
    /**
     * @generated from protobuf field: bool is_early_payment_discount_enabled = 6;
     */
    isEarlyPaymentDiscountEnabled: boolean;
    /**
     * @generated from protobuf field: string name = 7;
     */
    name: string;
}
/**
 * @generated from protobuf message rd.api.company.CreateFinancingTermRes
 */
export interface CreateFinancingTermRes {
    /**
     * @generated from protobuf field: string id_string = 1;
     */
    idString: string;
}
/**
 * @generated from protobuf message rd.api.company.UpdateFinancingTermReq
 */
export interface UpdateFinancingTermReq {
    /**
     * @generated from protobuf field: string id_string = 1;
     */
    idString: string;
    /**
     * @generated from protobuf field: bool is_default = 2;
     */
    isDefault: boolean;
    /**
     * @generated from protobuf field: rd.company.FinancingTerms.Term.ReferenceDate reference_date = 3;
     */
    referenceDate: FinancingTerms_Term_ReferenceDate;
    /**
     * @generated from protobuf field: int32 due_days = 4;
     */
    dueDays: number;
    /**
     * @generated from protobuf field: int32 discount_days = 5;
     */
    discountDays: number;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal discount_rate = 6;
     */
    discountRate?: Decimal;
    /**
     * @generated from protobuf field: bool is_early_payment_discount_enabled = 7;
     */
    isEarlyPaymentDiscountEnabled: boolean;
    /**
     * @generated from protobuf field: string name = 8;
     */
    name: string;
}
/**
 * @generated from protobuf message rd.api.company.UpdateFinancingTermRes
 */
export interface UpdateFinancingTermRes {
}
/**
 * @generated from protobuf message rd.api.company.DeleteFinancingTermReq
 */
export interface DeleteFinancingTermReq {
    /**
     * @generated from protobuf field: string id_string = 1;
     */
    idString: string;
}
/**
 * @generated from protobuf message rd.api.company.DeleteFinancingTermRes
 */
export interface DeleteFinancingTermRes {
}
/**
 * @generated from protobuf message rd.api.company.DeleteFinancingTermError
 */
export interface DeleteFinancingTermError {
    /**
     * @generated from protobuf field: repeated rd.customer.Customer customers_with_balance = 1;
     */
    customersWithBalance: Customer[];
}
/**
 * @generated from protobuf message rd.api.company.ReorderFinancingTermsReq
 */
export interface ReorderFinancingTermsReq {
    /**
     * @generated from protobuf field: repeated string id_strings = 1;
     */
    idStrings: string[];
}
/**
 * @generated from protobuf message rd.api.company.ReorderFinancingTermsRes
 */
export interface ReorderFinancingTermsRes {
}
/**
 * @generated from protobuf message rd.api.company.ListFinancingTermsForCompanyReq
 */
export interface ListFinancingTermsForCompanyReq {
}
/**
 * @generated from protobuf message rd.api.company.ListFinancingTermsForCompanyRes
 */
export interface ListFinancingTermsForCompanyRes {
    /**
     * @generated from protobuf field: repeated rd.company.FinancingTerms.Term terms = 1;
     */
    terms: FinancingTerms_Term[];
    /**
     * @generated from protobuf field: map<string, string> names_by_id_string = 2;
     */
    namesByIdString: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: string default_id_string = 3;
     */
    defaultIdString: string;
}
/**
 * @generated from protobuf message rd.api.company.ListCustomersForTermReq
 */
export interface ListCustomersForTermReq {
    /**
     * @generated from protobuf field: string id_string = 1;
     */
    idString: string;
}
/**
 * @generated from protobuf message rd.api.company.ListCustomersForTermRes
 */
export interface ListCustomersForTermRes {
    /**
     * @generated from protobuf field: repeated rd.customer.Customer customers = 1;
     */
    customers: Customer[];
}
/**
 * @generated from protobuf message rd.api.company.UpdateCardSurchargeRateReq
 */
export interface UpdateCardSurchargeRateReq {
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal rate = 1;
     */
    rate?: Decimal;
}
/**
 * @generated from protobuf message rd.api.company.UpdateCardSurchargeRateRes
 */
export interface UpdateCardSurchargeRateRes {
}
// Roles 

/**
 * @generated from protobuf message rd.api.company.GetPermissionsGroupsReq
 */
export interface GetPermissionsGroupsReq {
}
/**
 * @generated from protobuf message rd.api.company.GetPermissionsGroupsRes
 */
export interface GetPermissionsGroupsRes {
    /**
     * @generated from protobuf field: map<string, rd.api.company.GetPermissionsGroupsRes.CategoryGroups> category_groups = 1;
     */
    categoryGroups: {
        [key: string]: GetPermissionsGroupsRes_CategoryGroups;
    };
}
/**
 * @generated from protobuf message rd.api.company.GetPermissionsGroupsRes.Group
 */
export interface GetPermissionsGroupsRes_Group {
    /**
     * @generated from protobuf field: string id_string = 1;
     */
    idString: string;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * @generated from protobuf field: int32 position = 3;
     */
    position: number;
}
/**
 * @generated from protobuf message rd.api.company.GetPermissionsGroupsRes.CategoryGroups
 */
export interface GetPermissionsGroupsRes_CategoryGroups {
    /**
     * @generated from protobuf field: string category = 1;
     */
    category: string;
    /**
     * @generated from protobuf field: repeated rd.api.company.GetPermissionsGroupsRes.Group groups = 2;
     */
    groups: GetPermissionsGroupsRes_Group[];
}
// Finance Charge Settings 

/**
 * @generated from protobuf message rd.api.company.CreateFinanceChargeSettingReq
 */
export interface CreateFinanceChargeSettingReq {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal percentage_fee = 2;
     */
    percentageFee?: Decimal;
    /**
     * @generated from protobuf field: rd.proto.money.Money min_assessment_balance = 3;
     */
    minAssessmentBalance?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money min_charge_fee = 4;
     */
    minChargeFee?: Money;
    /**
     * @generated from protobuf field: int32 grace_period_days = 5;
     */
    gracePeriodDays: number;
    /**
     * @generated from protobuf field: bool is_compounded = 6;
     */
    isCompounded: boolean;
    /**
     * @generated from protobuf field: bool is_default = 7;
     */
    isDefault: boolean;
}
/**
 * @generated from protobuf message rd.api.company.CreateFinanceChargeSettingRes
 */
export interface CreateFinanceChargeSettingRes {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.company.UpdateFinanceChargeSettingReq
 */
export interface UpdateFinanceChargeSettingReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal percentage_fee = 3;
     */
    percentageFee?: Decimal;
    /**
     * @generated from protobuf field: rd.proto.money.Money min_assessment_balance = 4;
     */
    minAssessmentBalance?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money min_charge_fee = 5;
     */
    minChargeFee?: Money;
    /**
     * @generated from protobuf field: int32 grace_period_days = 6;
     */
    gracePeriodDays: number;
    /**
     * @generated from protobuf field: bool is_compounded = 7;
     */
    isCompounded: boolean;
    /**
     * @generated from protobuf field: bool is_default = 8;
     */
    isDefault: boolean;
    /**
     * @generated from protobuf field: int32 position = 9;
     */
    position: number;
}
/**
 * @generated from protobuf message rd.api.company.UpdateFinanceChargeSettingRes
 */
export interface UpdateFinanceChargeSettingRes {
}
/**
 * @generated from protobuf message rd.api.company.ListActiveFinanceChargeSettingsForCompanyReq
 */
export interface ListActiveFinanceChargeSettingsForCompanyReq {
}
/**
 * @generated from protobuf message rd.api.company.ListActiveFinanceChargeSettingsForCompanyRes
 */
export interface ListActiveFinanceChargeSettingsForCompanyRes {
    /**
     * @generated from protobuf field: repeated rd.company.FinanceChargeSetting finance_charge_settings = 1;
     */
    financeChargeSettings: FinanceChargeSetting[];
}
/**
 * @generated from protobuf message rd.api.company.DeactivateFinanceChargeSettingReq
 */
export interface DeactivateFinanceChargeSettingReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.company.DeactivateFinanceChargeSettingRes
 */
export interface DeactivateFinanceChargeSettingRes {
}
/**
 * @generated from protobuf message rd.api.company.ReorderFinanceChargeSettingsReq
 */
export interface ReorderFinanceChargeSettingsReq {
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID ids = 1;
     */
    ids: UUID[];
}
/**
 * @generated from protobuf message rd.api.company.ReorderFinanceChargeSettingsRes
 */
export interface ReorderFinanceChargeSettingsRes {
}
/**
 * @generated from protobuf message rd.api.company.ListCustomersForFinanceChargeSettingReq
 */
export interface ListCustomersForFinanceChargeSettingReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.company.ListCustomersForFinanceChargeSettingRes
 */
export interface ListCustomersForFinanceChargeSettingRes {
    /**
     * @generated from protobuf field: repeated rd.customer.Customer customers = 1;
     */
    customers: Customer[];
}
/**
 * @generated from protobuf message rd.api.company.UpdateLocationGoogleReviewUrlReq
 */
export interface UpdateLocationGoogleReviewUrlReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 1;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: string google_review_url = 2;
     */
    googleReviewUrl: string;
}
/**
 * @generated from protobuf message rd.api.company.UpdateLocationGoogleReviewUrlRes
 */
export interface UpdateLocationGoogleReviewUrlRes {
}
/**
 * @generated from protobuf message rd.api.company.UpdateLocationBMOutletNumberReq
 */
export interface UpdateLocationBMOutletNumberReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 1;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: string bm_outlet_number = 2;
     */
    bmOutletNumber: string;
}
/**
 * @generated from protobuf message rd.api.company.UpdateLocationBMOutletNumberRes
 */
export interface UpdateLocationBMOutletNumberRes {
}
/**
 * @generated from protobuf message rd.api.company.UpdateLocationCashFloatReq
 */
export interface UpdateLocationCashFloatReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 1;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.money.Money cash_float = 2;
     */
    cashFloat?: Money;
}
/**
 * @generated from protobuf message rd.api.company.UpdateLocationCashFloatRes
 */
export interface UpdateLocationCashFloatRes {
}
/**
 * @generated from protobuf message rd.api.company.UpdateLocationLogoImageUrlReq
 */
export interface UpdateLocationLogoImageUrlReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 1;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: string logo_image_url = 2;
     */
    logoImageUrl: string;
}
/**
 * @generated from protobuf message rd.api.company.UpdateLocationLogoImageUrlRes
 */
export interface UpdateLocationLogoImageUrlRes {
}
/**
 * @generated from protobuf message rd.api.company.UpdateLocationQuickbooksClassReq
 */
export interface UpdateLocationQuickbooksClassReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 1;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: string quickbooks_class = 2;
     */
    quickbooksClass: string;
}
/**
 * @generated from protobuf message rd.api.company.UpdateLocationQuickbooksClassRes
 */
export interface UpdateLocationQuickbooksClassRes {
}
/**
 * @generated from protobuf message rd.api.company.UpdateLocationStatementMessageReq
 */
export interface UpdateLocationStatementMessageReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 1;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: string statement_message = 2;
     */
    statementMessage: string;
}
/**
 * @generated from protobuf message rd.api.company.UpdateLocationStatementMessageRes
 */
export interface UpdateLocationStatementMessageRes {
}
// @generated message type with reflection information, may provide speed optimized methods
class GetCompanyForSubdomainReq$Type extends MessageType<GetCompanyForSubdomainReq> {
    constructor() {
        super("rd.api.company.GetCompanyForSubdomainReq", [
            { no: 1, name: "subdomain", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetCompanyForSubdomainReq>): GetCompanyForSubdomainReq {
        const message = { subdomain: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCompanyForSubdomainReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCompanyForSubdomainReq): GetCompanyForSubdomainReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string subdomain */ 1:
                    message.subdomain = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCompanyForSubdomainReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string subdomain = 1; */
        if (message.subdomain !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.subdomain);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.GetCompanyForSubdomainReq
 */
export const GetCompanyForSubdomainReq = new GetCompanyForSubdomainReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCompanyForSubdomainRes$Type extends MessageType<GetCompanyForSubdomainRes> {
    constructor() {
        super("rd.api.company.GetCompanyForSubdomainRes", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "locations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Location },
            { no: 4, name: "logo_image_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "default_location_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<GetCompanyForSubdomainRes>): GetCompanyForSubdomainRes {
        const message = { name: "", locations: [], logoImageUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCompanyForSubdomainRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCompanyForSubdomainRes): GetCompanyForSubdomainRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated rd.company.Location locations */ 3:
                    message.locations.push(Location.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string logo_image_url */ 4:
                    message.logoImageUrl = reader.string();
                    break;
                case /* rd.proto.uuid.UUID default_location_id */ 5:
                    message.defaultLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.defaultLocationId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCompanyForSubdomainRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated rd.company.Location locations = 3; */
        for (let i = 0; i < message.locations.length; i++)
            Location.internalBinaryWrite(message.locations[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string logo_image_url = 4; */
        if (message.logoImageUrl !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.logoImageUrl);
        /* rd.proto.uuid.UUID default_location_id = 5; */
        if (message.defaultLocationId)
            UUID.internalBinaryWrite(message.defaultLocationId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.GetCompanyForSubdomainRes
 */
export const GetCompanyForSubdomainRes = new GetCompanyForSubdomainRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCompanyReq$Type extends MessageType<GetCompanyReq> {
    constructor() {
        super("rd.api.company.GetCompanyReq", []);
    }
    create(value?: PartialMessage<GetCompanyReq>): GetCompanyReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCompanyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCompanyReq): GetCompanyReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetCompanyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.GetCompanyReq
 */
export const GetCompanyReq = new GetCompanyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCompanyRes$Type extends MessageType<GetCompanyRes> {
    constructor() {
        super("rd.api.company.GetCompanyRes", [
            { no: 1, name: "company", kind: "message", T: () => Company },
            { no: 2, name: "bank_account", kind: "message", T: () => BankAccount },
            { no: 3, name: "entity", kind: "message", T: () => Entity }
        ]);
    }
    create(value?: PartialMessage<GetCompanyRes>): GetCompanyRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCompanyRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCompanyRes): GetCompanyRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.company.Company company */ 1:
                    message.company = Company.internalBinaryRead(reader, reader.uint32(), options, message.company);
                    break;
                case /* rd.payments.BankAccount bank_account */ 2:
                    message.bankAccount = BankAccount.internalBinaryRead(reader, reader.uint32(), options, message.bankAccount);
                    break;
                case /* rd.company.Entity entity */ 3:
                    message.entity = Entity.internalBinaryRead(reader, reader.uint32(), options, message.entity);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCompanyRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.company.Company company = 1; */
        if (message.company)
            Company.internalBinaryWrite(message.company, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.BankAccount bank_account = 2; */
        if (message.bankAccount)
            BankAccount.internalBinaryWrite(message.bankAccount, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Entity entity = 3; */
        if (message.entity)
            Entity.internalBinaryWrite(message.entity, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.GetCompanyRes
 */
export const GetCompanyRes = new GetCompanyRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateCustomTaxRateReq$Type extends MessageType<CreateCustomTaxRateReq> {
    constructor() {
        super("rd.api.company.CreateCustomTaxRateReq", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 2, name: "rate", kind: "message", T: () => Decimal }
        ]);
    }
    create(value?: PartialMessage<CreateCustomTaxRateReq>): CreateCustomTaxRateReq {
        const message = { name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateCustomTaxRateReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateCustomTaxRateReq): CreateCustomTaxRateReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* rd.proto.decimal.Decimal rate */ 2:
                    message.rate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.rate);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateCustomTaxRateReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* rd.proto.decimal.Decimal rate = 2; */
        if (message.rate)
            Decimal.internalBinaryWrite(message.rate, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.CreateCustomTaxRateReq
 */
export const CreateCustomTaxRateReq = new CreateCustomTaxRateReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateCustomTaxRateRes$Type extends MessageType<CreateCustomTaxRateRes> {
    constructor() {
        super("rd.api.company.CreateCustomTaxRateRes", []);
    }
    create(value?: PartialMessage<CreateCustomTaxRateRes>): CreateCustomTaxRateRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateCustomTaxRateRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateCustomTaxRateRes): CreateCustomTaxRateRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CreateCustomTaxRateRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.CreateCustomTaxRateRes
 */
export const CreateCustomTaxRateRes = new CreateCustomTaxRateRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCustomTaxRateReq$Type extends MessageType<UpdateCustomTaxRateReq> {
    constructor() {
        super("rd.api.company.UpdateCustomTaxRateReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 3, name: "rate", kind: "message", T: () => Decimal }
        ]);
    }
    create(value?: PartialMessage<UpdateCustomTaxRateReq>): UpdateCustomTaxRateReq {
        const message = { name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCustomTaxRateReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCustomTaxRateReq): UpdateCustomTaxRateReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* rd.proto.decimal.Decimal rate */ 3:
                    message.rate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.rate);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCustomTaxRateReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* rd.proto.decimal.Decimal rate = 3; */
        if (message.rate)
            Decimal.internalBinaryWrite(message.rate, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCustomTaxRateReq
 */
export const UpdateCustomTaxRateReq = new UpdateCustomTaxRateReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCustomTaxRateRes$Type extends MessageType<UpdateCustomTaxRateRes> {
    constructor() {
        super("rd.api.company.UpdateCustomTaxRateRes", []);
    }
    create(value?: PartialMessage<UpdateCustomTaxRateRes>): UpdateCustomTaxRateRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCustomTaxRateRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCustomTaxRateRes): UpdateCustomTaxRateRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateCustomTaxRateRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCustomTaxRateRes
 */
export const UpdateCustomTaxRateRes = new UpdateCustomTaxRateRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReorderCustomTaxRatesReq$Type extends MessageType<ReorderCustomTaxRatesReq> {
    constructor() {
        super("rd.api.company.ReorderCustomTaxRatesReq", [
            { no: 1, name: "ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<ReorderCustomTaxRatesReq>): ReorderCustomTaxRatesReq {
        const message = { ids: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReorderCustomTaxRatesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReorderCustomTaxRatesReq): ReorderCustomTaxRatesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.proto.uuid.UUID ids */ 1:
                    message.ids.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReorderCustomTaxRatesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.proto.uuid.UUID ids = 1; */
        for (let i = 0; i < message.ids.length; i++)
            UUID.internalBinaryWrite(message.ids[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ReorderCustomTaxRatesReq
 */
export const ReorderCustomTaxRatesReq = new ReorderCustomTaxRatesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReorderCustomTaxRatesRes$Type extends MessageType<ReorderCustomTaxRatesRes> {
    constructor() {
        super("rd.api.company.ReorderCustomTaxRatesRes", []);
    }
    create(value?: PartialMessage<ReorderCustomTaxRatesRes>): ReorderCustomTaxRatesRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReorderCustomTaxRatesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReorderCustomTaxRatesRes): ReorderCustomTaxRatesRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ReorderCustomTaxRatesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ReorderCustomTaxRatesRes
 */
export const ReorderCustomTaxRatesRes = new ReorderCustomTaxRatesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeactivateCustomTaxRateReq$Type extends MessageType<DeactivateCustomTaxRateReq> {
    constructor() {
        super("rd.api.company.DeactivateCustomTaxRateReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<DeactivateCustomTaxRateReq>): DeactivateCustomTaxRateReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeactivateCustomTaxRateReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeactivateCustomTaxRateReq): DeactivateCustomTaxRateReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeactivateCustomTaxRateReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.DeactivateCustomTaxRateReq
 */
export const DeactivateCustomTaxRateReq = new DeactivateCustomTaxRateReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeactivateCustomTaxRateRes$Type extends MessageType<DeactivateCustomTaxRateRes> {
    constructor() {
        super("rd.api.company.DeactivateCustomTaxRateRes", []);
    }
    create(value?: PartialMessage<DeactivateCustomTaxRateRes>): DeactivateCustomTaxRateRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeactivateCustomTaxRateRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeactivateCustomTaxRateRes): DeactivateCustomTaxRateRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeactivateCustomTaxRateRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.DeactivateCustomTaxRateRes
 */
export const DeactivateCustomTaxRateRes = new DeactivateCustomTaxRateRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTaxRatesForCompanyReq$Type extends MessageType<ListTaxRatesForCompanyReq> {
    constructor() {
        super("rd.api.company.ListTaxRatesForCompanyReq", [
            { no: 1, name: "filter_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "filter_types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.company.TaxRate.Type", TaxRate_Type, "TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<ListTaxRatesForCompanyReq>): ListTaxRatesForCompanyReq {
        const message = { filterActive: false, filterTypes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListTaxRatesForCompanyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTaxRatesForCompanyReq): ListTaxRatesForCompanyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool filter_active */ 1:
                    message.filterActive = reader.bool();
                    break;
                case /* repeated rd.company.TaxRate.Type filter_types */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.filterTypes.push(reader.int32());
                    else
                        message.filterTypes.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTaxRatesForCompanyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool filter_active = 1; */
        if (message.filterActive !== false)
            writer.tag(1, WireType.Varint).bool(message.filterActive);
        /* repeated rd.company.TaxRate.Type filter_types = 2; */
        if (message.filterTypes.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.filterTypes.length; i++)
                writer.int32(message.filterTypes[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListTaxRatesForCompanyReq
 */
export const ListTaxRatesForCompanyReq = new ListTaxRatesForCompanyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTaxRatesForCompanyRes$Type extends MessageType<ListTaxRatesForCompanyRes> {
    constructor() {
        super("rd.api.company.ListTaxRatesForCompanyRes", [
            { no: 1, name: "tax_rates", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TaxRate }
        ]);
    }
    create(value?: PartialMessage<ListTaxRatesForCompanyRes>): ListTaxRatesForCompanyRes {
        const message = { taxRates: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListTaxRatesForCompanyRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTaxRatesForCompanyRes): ListTaxRatesForCompanyRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.company.TaxRate tax_rates */ 1:
                    message.taxRates.push(TaxRate.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTaxRatesForCompanyRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.company.TaxRate tax_rates = 1; */
        for (let i = 0; i < message.taxRates.length; i++)
            TaxRate.internalBinaryWrite(message.taxRates[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListTaxRatesForCompanyRes
 */
export const ListTaxRatesForCompanyRes = new ListTaxRatesForCompanyRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTaxRateReq$Type extends MessageType<GetTaxRateReq> {
    constructor() {
        super("rd.api.company.GetTaxRateReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetTaxRateReq>): GetTaxRateReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTaxRateReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTaxRateReq): GetTaxRateReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTaxRateReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.GetTaxRateReq
 */
export const GetTaxRateReq = new GetTaxRateReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTaxRateRes$Type extends MessageType<GetTaxRateRes> {
    constructor() {
        super("rd.api.company.GetTaxRateRes", [
            { no: 1, name: "tax_rate", kind: "message", T: () => TaxRate }
        ]);
    }
    create(value?: PartialMessage<GetTaxRateRes>): GetTaxRateRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTaxRateRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTaxRateRes): GetTaxRateRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.company.TaxRate tax_rate */ 1:
                    message.taxRate = TaxRate.internalBinaryRead(reader, reader.uint32(), options, message.taxRate);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTaxRateRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.company.TaxRate tax_rate = 1; */
        if (message.taxRate)
            TaxRate.internalBinaryWrite(message.taxRate, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.GetTaxRateRes
 */
export const GetTaxRateRes = new GetTaxRateRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStripeOnboardingURLReq$Type extends MessageType<GetStripeOnboardingURLReq> {
    constructor() {
        super("rd.api.company.GetStripeOnboardingURLReq", []);
    }
    create(value?: PartialMessage<GetStripeOnboardingURLReq>): GetStripeOnboardingURLReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetStripeOnboardingURLReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetStripeOnboardingURLReq): GetStripeOnboardingURLReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetStripeOnboardingURLReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.GetStripeOnboardingURLReq
 */
export const GetStripeOnboardingURLReq = new GetStripeOnboardingURLReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStripeOnboardingURLRes$Type extends MessageType<GetStripeOnboardingURLRes> {
    constructor() {
        super("rd.api.company.GetStripeOnboardingURLRes", [
            { no: 1, name: "stripe_onboarding_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetStripeOnboardingURLRes>): GetStripeOnboardingURLRes {
        const message = { stripeOnboardingUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetStripeOnboardingURLRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetStripeOnboardingURLRes): GetStripeOnboardingURLRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_onboarding_url */ 1:
                    message.stripeOnboardingUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetStripeOnboardingURLRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_onboarding_url = 1; */
        if (message.stripeOnboardingUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripeOnboardingUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.GetStripeOnboardingURLRes
 */
export const GetStripeOnboardingURLRes = new GetStripeOnboardingURLRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStripeLoginURLReq$Type extends MessageType<GetStripeLoginURLReq> {
    constructor() {
        super("rd.api.company.GetStripeLoginURLReq", []);
    }
    create(value?: PartialMessage<GetStripeLoginURLReq>): GetStripeLoginURLReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetStripeLoginURLReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetStripeLoginURLReq): GetStripeLoginURLReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetStripeLoginURLReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.GetStripeLoginURLReq
 */
export const GetStripeLoginURLReq = new GetStripeLoginURLReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStripeLoginURLRes$Type extends MessageType<GetStripeLoginURLRes> {
    constructor() {
        super("rd.api.company.GetStripeLoginURLRes", [
            { no: 1, name: "stripe_login_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetStripeLoginURLRes>): GetStripeLoginURLRes {
        const message = { stripeLoginUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetStripeLoginURLRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetStripeLoginURLRes): GetStripeLoginURLRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_login_url */ 1:
                    message.stripeLoginUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetStripeLoginURLRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_login_url = 1; */
        if (message.stripeLoginUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripeLoginUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.GetStripeLoginURLRes
 */
export const GetStripeLoginURLRes = new GetStripeLoginURLRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLocationReq$Type extends MessageType<GetLocationReq> {
    constructor() {
        super("rd.api.company.GetLocationReq", [
            { no: 1, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetLocationReq>): GetLocationReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetLocationReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLocationReq): GetLocationReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID location_id */ 1:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLocationReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID location_id = 1; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.GetLocationReq
 */
export const GetLocationReq = new GetLocationReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLocationRes$Type extends MessageType<GetLocationRes> {
    constructor() {
        super("rd.api.company.GetLocationRes", [
            { no: 1, name: "location", kind: "message", T: () => Location },
            { no: 2, name: "cash_register", kind: "message", T: () => CashRegister }
        ]);
    }
    create(value?: PartialMessage<GetLocationRes>): GetLocationRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetLocationRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLocationRes): GetLocationRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.company.Location location */ 1:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.company.CashRegister cash_register */ 2:
                    message.cashRegister = CashRegister.internalBinaryRead(reader, reader.uint32(), options, message.cashRegister);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLocationRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.company.Location location = 1; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.CashRegister cash_register = 2; */
        if (message.cashRegister)
            CashRegister.internalBinaryWrite(message.cashRegister, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.GetLocationRes
 */
export const GetLocationRes = new GetLocationRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateLocationReq$Type extends MessageType<CreateLocationReq> {
    constructor() {
        super("rd.api.company.CreateLocationReq", [
            { no: 1, name: "prefix", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 3, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true, "rd.validator.is_phone": true } },
            { no: 4, name: "address", kind: "message", T: () => Address, options: { "rd.validator.require": true } },
            { no: 5, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "float", kind: "message", T: () => Money },
            { no: 8, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true, "rd.validator.is_email": true } },
            { no: 9, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<CreateLocationReq>): CreateLocationReq {
        const message = { prefix: "", name: "", phone: "", isActive: false, email: "", displayName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateLocationReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateLocationReq): CreateLocationReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string prefix */ 1:
                    message.prefix = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string phone */ 3:
                    message.phone = reader.string();
                    break;
                case /* rd.proto.address.Address address */ 4:
                    message.address = Address.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                case /* bool is_active */ 5:
                    message.isActive = reader.bool();
                    break;
                case /* rd.proto.money.Money float */ 7:
                    message.float = Money.internalBinaryRead(reader, reader.uint32(), options, message.float);
                    break;
                case /* string email */ 8:
                    message.email = reader.string();
                    break;
                case /* string display_name */ 9:
                    message.displayName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateLocationReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string prefix = 1; */
        if (message.prefix !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.prefix);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string phone = 3; */
        if (message.phone !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.phone);
        /* rd.proto.address.Address address = 4; */
        if (message.address)
            Address.internalBinaryWrite(message.address, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bool is_active = 5; */
        if (message.isActive !== false)
            writer.tag(5, WireType.Varint).bool(message.isActive);
        /* rd.proto.money.Money float = 7; */
        if (message.float)
            Money.internalBinaryWrite(message.float, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string email = 8; */
        if (message.email !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.email);
        /* string display_name = 9; */
        if (message.displayName !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.displayName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.CreateLocationReq
 */
export const CreateLocationReq = new CreateLocationReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateLocationRes$Type extends MessageType<CreateLocationRes> {
    constructor() {
        super("rd.api.company.CreateLocationRes", [
            { no: 1, name: "id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<CreateLocationRes>): CreateLocationRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateLocationRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateLocationRes): CreateLocationRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateLocationRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.CreateLocationRes
 */
export const CreateLocationRes = new CreateLocationRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLocationReq$Type extends MessageType<UpdateLocationReq> {
    constructor() {
        super("rd.api.company.UpdateLocationReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "prefix", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 4, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true, "rd.validator.is_phone": true } },
            { no: 5, name: "address", kind: "message", T: () => Address, options: { "rd.validator.require": true } },
            { no: 8, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.is_email": true } },
            { no: 9, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<UpdateLocationReq>): UpdateLocationReq {
        const message = { prefix: "", name: "", phone: "", email: "", displayName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateLocationReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateLocationReq): UpdateLocationReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* string prefix */ 2:
                    message.prefix = reader.string();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string phone */ 4:
                    message.phone = reader.string();
                    break;
                case /* rd.proto.address.Address address */ 5:
                    message.address = Address.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                case /* string email */ 8:
                    message.email = reader.string();
                    break;
                case /* string display_name */ 9:
                    message.displayName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateLocationReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string prefix = 2; */
        if (message.prefix !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.prefix);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string phone = 4; */
        if (message.phone !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.phone);
        /* rd.proto.address.Address address = 5; */
        if (message.address)
            Address.internalBinaryWrite(message.address, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string email = 8; */
        if (message.email !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.email);
        /* string display_name = 9; */
        if (message.displayName !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.displayName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateLocationReq
 */
export const UpdateLocationReq = new UpdateLocationReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLocationRes$Type extends MessageType<UpdateLocationRes> {
    constructor() {
        super("rd.api.company.UpdateLocationRes", []);
    }
    create(value?: PartialMessage<UpdateLocationRes>): UpdateLocationRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateLocationRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateLocationRes): UpdateLocationRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateLocationRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateLocationRes
 */
export const UpdateLocationRes = new UpdateLocationRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLocationsForCompanyReq$Type extends MessageType<ListLocationsForCompanyReq> {
    constructor() {
        super("rd.api.company.ListLocationsForCompanyReq", [
            { no: 1, name: "filter_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ListLocationsForCompanyReq>): ListLocationsForCompanyReq {
        const message = { filterActive: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListLocationsForCompanyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListLocationsForCompanyReq): ListLocationsForCompanyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool filter_active */ 1:
                    message.filterActive = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListLocationsForCompanyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool filter_active = 1; */
        if (message.filterActive !== false)
            writer.tag(1, WireType.Varint).bool(message.filterActive);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListLocationsForCompanyReq
 */
export const ListLocationsForCompanyReq = new ListLocationsForCompanyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLocationsForCompanyRes$Type extends MessageType<ListLocationsForCompanyRes> {
    constructor() {
        super("rd.api.company.ListLocationsForCompanyRes", [
            { no: 1, name: "locations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Location },
            { no: 2, name: "cash_registers_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => CashRegister } },
            { no: 3, name: "tax_rates_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => TaxRate } }
        ]);
    }
    create(value?: PartialMessage<ListLocationsForCompanyRes>): ListLocationsForCompanyRes {
        const message = { locations: [], cashRegistersById: {}, taxRatesById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListLocationsForCompanyRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListLocationsForCompanyRes): ListLocationsForCompanyRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.company.Location locations */ 1:
                    message.locations.push(Location.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* map<string, rd.company.CashRegister> cash_registers_by_id */ 2:
                    this.binaryReadMap2(message.cashRegistersById, reader, options);
                    break;
                case /* map<string, rd.company.TaxRate> tax_rates_by_id */ 3:
                    this.binaryReadMap3(message.taxRatesById, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: ListLocationsForCompanyRes["cashRegistersById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ListLocationsForCompanyRes["cashRegistersById"] | undefined, val: ListLocationsForCompanyRes["cashRegistersById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = CashRegister.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.company.ListLocationsForCompanyRes.cash_registers_by_id");
            }
        }
        map[key ?? ""] = val ?? CashRegister.create();
    }
    private binaryReadMap3(map: ListLocationsForCompanyRes["taxRatesById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ListLocationsForCompanyRes["taxRatesById"] | undefined, val: ListLocationsForCompanyRes["taxRatesById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = TaxRate.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.company.ListLocationsForCompanyRes.tax_rates_by_id");
            }
        }
        map[key ?? ""] = val ?? TaxRate.create();
    }
    internalBinaryWrite(message: ListLocationsForCompanyRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.company.Location locations = 1; */
        for (let i = 0; i < message.locations.length; i++)
            Location.internalBinaryWrite(message.locations[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.company.CashRegister> cash_registers_by_id = 2; */
        for (let k of Object.keys(message.cashRegistersById)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            CashRegister.internalBinaryWrite(message.cashRegistersById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.company.TaxRate> tax_rates_by_id = 3; */
        for (let k of Object.keys(message.taxRatesById)) {
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            TaxRate.internalBinaryWrite(message.taxRatesById[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListLocationsForCompanyRes
 */
export const ListLocationsForCompanyRes = new ListLocationsForCompanyRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCardReadersForLocationReq$Type extends MessageType<ListCardReadersForLocationReq> {
    constructor() {
        super("rd.api.company.ListCardReadersForLocationReq", [
            { no: 1, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<ListCardReadersForLocationReq>): ListCardReadersForLocationReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListCardReadersForLocationReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCardReadersForLocationReq): ListCardReadersForLocationReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID location_id */ 1:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListCardReadersForLocationReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID location_id = 1; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListCardReadersForLocationReq
 */
export const ListCardReadersForLocationReq = new ListCardReadersForLocationReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCardReadersForLocationRes$Type extends MessageType<ListCardReadersForLocationRes> {
    constructor() {
        super("rd.api.company.ListCardReadersForLocationRes", [
            { no: 1, name: "card_readers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CardReader }
        ]);
    }
    create(value?: PartialMessage<ListCardReadersForLocationRes>): ListCardReadersForLocationRes {
        const message = { cardReaders: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListCardReadersForLocationRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCardReadersForLocationRes): ListCardReadersForLocationRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.payments.CardReader card_readers */ 1:
                    message.cardReaders.push(CardReader.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListCardReadersForLocationRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.payments.CardReader card_readers = 1; */
        for (let i = 0; i < message.cardReaders.length; i++)
            CardReader.internalBinaryWrite(message.cardReaders[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListCardReadersForLocationRes
 */
export const ListCardReadersForLocationRes = new ListCardReadersForLocationRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterCardReaderForLocationReq$Type extends MessageType<RegisterCardReaderForLocationReq> {
    constructor() {
        super("rd.api.company.RegisterCardReaderForLocationReq", [
            { no: 1, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "registration_code", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 3, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<RegisterCardReaderForLocationReq>): RegisterCardReaderForLocationReq {
        const message = { registrationCode: "", label: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RegisterCardReaderForLocationReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterCardReaderForLocationReq): RegisterCardReaderForLocationReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID location_id */ 1:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* string registration_code */ 2:
                    message.registrationCode = reader.string();
                    break;
                case /* string label */ 3:
                    message.label = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegisterCardReaderForLocationReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID location_id = 1; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string registration_code = 2; */
        if (message.registrationCode !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.registrationCode);
        /* string label = 3; */
        if (message.label !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.label);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.RegisterCardReaderForLocationReq
 */
export const RegisterCardReaderForLocationReq = new RegisterCardReaderForLocationReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterCardReaderForLocationRes$Type extends MessageType<RegisterCardReaderForLocationRes> {
    constructor() {
        super("rd.api.company.RegisterCardReaderForLocationRes", [
            { no: 1, name: "card_reader", kind: "message", T: () => CardReader }
        ]);
    }
    create(value?: PartialMessage<RegisterCardReaderForLocationRes>): RegisterCardReaderForLocationRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RegisterCardReaderForLocationRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterCardReaderForLocationRes): RegisterCardReaderForLocationRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.payments.CardReader card_reader */ 1:
                    message.cardReader = CardReader.internalBinaryRead(reader, reader.uint32(), options, message.cardReader);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegisterCardReaderForLocationRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.payments.CardReader card_reader = 1; */
        if (message.cardReader)
            CardReader.internalBinaryWrite(message.cardReader, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.RegisterCardReaderForLocationRes
 */
export const RegisterCardReaderForLocationRes = new RegisterCardReaderForLocationRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClearCardReaderReq$Type extends MessageType<ClearCardReaderReq> {
    constructor() {
        super("rd.api.company.ClearCardReaderReq", [
            { no: 1, name: "stripe_reader_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<ClearCardReaderReq>): ClearCardReaderReq {
        const message = { stripeReaderId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ClearCardReaderReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClearCardReaderReq): ClearCardReaderReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_reader_id */ 1:
                    message.stripeReaderId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ClearCardReaderReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_reader_id = 1; */
        if (message.stripeReaderId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripeReaderId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ClearCardReaderReq
 */
export const ClearCardReaderReq = new ClearCardReaderReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClearCardReaderRes$Type extends MessageType<ClearCardReaderRes> {
    constructor() {
        super("rd.api.company.ClearCardReaderRes", []);
    }
    create(value?: PartialMessage<ClearCardReaderRes>): ClearCardReaderRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ClearCardReaderRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClearCardReaderRes): ClearCardReaderRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ClearCardReaderRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ClearCardReaderRes
 */
export const ClearCardReaderRes = new ClearCardReaderRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStaffReq$Type extends MessageType<GetStaffReq> {
    constructor() {
        super("rd.api.company.GetStaffReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetStaffReq>): GetStaffReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetStaffReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetStaffReq): GetStaffReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetStaffReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.GetStaffReq
 */
export const GetStaffReq = new GetStaffReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStaffRes$Type extends MessageType<GetStaffRes> {
    constructor() {
        super("rd.api.company.GetStaffRes", [
            { no: 1, name: "staff", kind: "message", T: () => Staff }
        ]);
    }
    create(value?: PartialMessage<GetStaffRes>): GetStaffRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetStaffRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetStaffRes): GetStaffRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.company.Staff staff */ 1:
                    message.staff = Staff.internalBinaryRead(reader, reader.uint32(), options, message.staff);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetStaffRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.company.Staff staff = 1; */
        if (message.staff)
            Staff.internalBinaryWrite(message.staff, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.GetStaffRes
 */
export const GetStaffRes = new GetStaffRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateStaffReq$Type extends MessageType<CreateStaffReq> {
    constructor() {
        super("rd.api.company.CreateStaffReq", [
            { no: 1, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 2, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 3, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true, "rd.validator.is_phone": true } },
            { no: 4, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.is_email": true } },
            { no: 5, name: "address", kind: "message", T: () => Address },
            { no: 6, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 7, name: "role_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateStaffReq>): CreateStaffReq {
        const message = { firstName: "", lastName: "", phone: "", email: "", code: "", roleIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateStaffReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateStaffReq): CreateStaffReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string first_name */ 1:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 2:
                    message.lastName = reader.string();
                    break;
                case /* string phone */ 3:
                    message.phone = reader.string();
                    break;
                case /* string email */ 4:
                    message.email = reader.string();
                    break;
                case /* rd.proto.address.Address address */ 5:
                    message.address = Address.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                case /* string code */ 6:
                    message.code = reader.string();
                    break;
                case /* repeated string role_ids */ 7:
                    message.roleIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateStaffReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string first_name = 1; */
        if (message.firstName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 2; */
        if (message.lastName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.lastName);
        /* string phone = 3; */
        if (message.phone !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.phone);
        /* string email = 4; */
        if (message.email !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.email);
        /* rd.proto.address.Address address = 5; */
        if (message.address)
            Address.internalBinaryWrite(message.address, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string code = 6; */
        if (message.code !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.code);
        /* repeated string role_ids = 7; */
        for (let i = 0; i < message.roleIds.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.roleIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.CreateStaffReq
 */
export const CreateStaffReq = new CreateStaffReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateStaffRes$Type extends MessageType<CreateStaffRes> {
    constructor() {
        super("rd.api.company.CreateStaffRes", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "stytch_user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateStaffRes>): CreateStaffRes {
        const message = { stytchUserId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateStaffRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateStaffRes): CreateStaffRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* string stytch_user_id */ 2:
                    message.stytchUserId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateStaffRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string stytch_user_id = 2; */
        if (message.stytchUserId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.stytchUserId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.CreateStaffRes
 */
export const CreateStaffRes = new CreateStaffRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStaffReq$Type extends MessageType<UpdateStaffReq> {
    constructor() {
        super("rd.api.company.UpdateStaffReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 3, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 5, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.is_email": true } },
            { no: 6, name: "address", kind: "message", T: () => Address },
            { no: 7, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 9, name: "role_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateStaffReq>): UpdateStaffReq {
        const message = { firstName: "", lastName: "", email: "", isActive: false, code: "", roleIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateStaffReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateStaffReq): UpdateStaffReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* string first_name */ 2:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 3:
                    message.lastName = reader.string();
                    break;
                case /* string email */ 5:
                    message.email = reader.string();
                    break;
                case /* rd.proto.address.Address address */ 6:
                    message.address = Address.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                case /* bool is_active */ 7:
                    message.isActive = reader.bool();
                    break;
                case /* string code */ 8:
                    message.code = reader.string();
                    break;
                case /* repeated string role_ids */ 9:
                    message.roleIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateStaffReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string first_name = 2; */
        if (message.firstName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 3; */
        if (message.lastName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.lastName);
        /* string email = 5; */
        if (message.email !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.email);
        /* rd.proto.address.Address address = 6; */
        if (message.address)
            Address.internalBinaryWrite(message.address, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bool is_active = 7; */
        if (message.isActive !== false)
            writer.tag(7, WireType.Varint).bool(message.isActive);
        /* string code = 8; */
        if (message.code !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.code);
        /* repeated string role_ids = 9; */
        for (let i = 0; i < message.roleIds.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.roleIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateStaffReq
 */
export const UpdateStaffReq = new UpdateStaffReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStaffRes$Type extends MessageType<UpdateStaffRes> {
    constructor() {
        super("rd.api.company.UpdateStaffRes", []);
    }
    create(value?: PartialMessage<UpdateStaffRes>): UpdateStaffRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateStaffRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateStaffRes): UpdateStaffRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateStaffRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateStaffRes
 */
export const UpdateStaffRes = new UpdateStaffRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurrentUserReq$Type extends MessageType<GetCurrentUserReq> {
    constructor() {
        super("rd.api.company.GetCurrentUserReq", []);
    }
    create(value?: PartialMessage<GetCurrentUserReq>): GetCurrentUserReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCurrentUserReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCurrentUserReq): GetCurrentUserReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetCurrentUserReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.GetCurrentUserReq
 */
export const GetCurrentUserReq = new GetCurrentUserReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurrentUserRes$Type extends MessageType<GetCurrentUserRes> {
    constructor() {
        super("rd.api.company.GetCurrentUserRes", [
            { no: 1, name: "user", kind: "message", T: () => User }
        ]);
    }
    create(value?: PartialMessage<GetCurrentUserRes>): GetCurrentUserRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCurrentUserRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCurrentUserRes): GetCurrentUserRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.user.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCurrentUserRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.user.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.GetCurrentUserRes
 */
export const GetCurrentUserRes = new GetCurrentUserRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStaffsForCompanyReq$Type extends MessageType<ListStaffsForCompanyReq> {
    constructor() {
        super("rd.api.company.ListStaffsForCompanyReq", []);
    }
    create(value?: PartialMessage<ListStaffsForCompanyReq>): ListStaffsForCompanyReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListStaffsForCompanyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListStaffsForCompanyReq): ListStaffsForCompanyReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListStaffsForCompanyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListStaffsForCompanyReq
 */
export const ListStaffsForCompanyReq = new ListStaffsForCompanyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStaffsForCompanyRes$Type extends MessageType<ListStaffsForCompanyRes> {
    constructor() {
        super("rd.api.company.ListStaffsForCompanyRes", [
            { no: 1, name: "staffs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Staff }
        ]);
    }
    create(value?: PartialMessage<ListStaffsForCompanyRes>): ListStaffsForCompanyRes {
        const message = { staffs: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListStaffsForCompanyRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListStaffsForCompanyRes): ListStaffsForCompanyRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.company.Staff staffs */ 1:
                    message.staffs.push(Staff.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListStaffsForCompanyRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.company.Staff staffs = 1; */
        for (let i = 0; i < message.staffs.length; i++)
            Staff.internalBinaryWrite(message.staffs[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListStaffsForCompanyRes
 */
export const ListStaffsForCompanyRes = new ListStaffsForCompanyRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeactivateStaffReq$Type extends MessageType<DeactivateStaffReq> {
    constructor() {
        super("rd.api.company.DeactivateStaffReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<DeactivateStaffReq>): DeactivateStaffReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeactivateStaffReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeactivateStaffReq): DeactivateStaffReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeactivateStaffReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.DeactivateStaffReq
 */
export const DeactivateStaffReq = new DeactivateStaffReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeactivateStaffRes$Type extends MessageType<DeactivateStaffRes> {
    constructor() {
        super("rd.api.company.DeactivateStaffRes", []);
    }
    create(value?: PartialMessage<DeactivateStaffRes>): DeactivateStaffRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeactivateStaffRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeactivateStaffRes): DeactivateStaffRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeactivateStaffRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.DeactivateStaffRes
 */
export const DeactivateStaffRes = new DeactivateStaffRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReactivateStaffReq$Type extends MessageType<ReactivateStaffReq> {
    constructor() {
        super("rd.api.company.ReactivateStaffReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<ReactivateStaffReq>): ReactivateStaffReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReactivateStaffReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReactivateStaffReq): ReactivateStaffReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReactivateStaffReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ReactivateStaffReq
 */
export const ReactivateStaffReq = new ReactivateStaffReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReactivateStaffRes$Type extends MessageType<ReactivateStaffRes> {
    constructor() {
        super("rd.api.company.ReactivateStaffRes", []);
    }
    create(value?: PartialMessage<ReactivateStaffRes>): ReactivateStaffRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReactivateStaffRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReactivateStaffRes): ReactivateStaffRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ReactivateStaffRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ReactivateStaffRes
 */
export const ReactivateStaffRes = new ReactivateStaffRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeactivateStaffWebAuthnKeysReq$Type extends MessageType<DeactivateStaffWebAuthnKeysReq> {
    constructor() {
        super("rd.api.company.DeactivateStaffWebAuthnKeysReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<DeactivateStaffWebAuthnKeysReq>): DeactivateStaffWebAuthnKeysReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeactivateStaffWebAuthnKeysReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeactivateStaffWebAuthnKeysReq): DeactivateStaffWebAuthnKeysReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeactivateStaffWebAuthnKeysReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.DeactivateStaffWebAuthnKeysReq
 */
export const DeactivateStaffWebAuthnKeysReq = new DeactivateStaffWebAuthnKeysReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeactivateStaffWebAuthnKeysRes$Type extends MessageType<DeactivateStaffWebAuthnKeysRes> {
    constructor() {
        super("rd.api.company.DeactivateStaffWebAuthnKeysRes", []);
    }
    create(value?: PartialMessage<DeactivateStaffWebAuthnKeysRes>): DeactivateStaffWebAuthnKeysRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeactivateStaffWebAuthnKeysRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeactivateStaffWebAuthnKeysRes): DeactivateStaffWebAuthnKeysRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeactivateStaffWebAuthnKeysRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.DeactivateStaffWebAuthnKeysRes
 */
export const DeactivateStaffWebAuthnKeysRes = new DeactivateStaffWebAuthnKeysRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAuthorizedEndpointsReq$Type extends MessageType<ListAuthorizedEndpointsReq> {
    constructor() {
        super("rd.api.company.ListAuthorizedEndpointsReq", []);
    }
    create(value?: PartialMessage<ListAuthorizedEndpointsReq>): ListAuthorizedEndpointsReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListAuthorizedEndpointsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAuthorizedEndpointsReq): ListAuthorizedEndpointsReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListAuthorizedEndpointsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListAuthorizedEndpointsReq
 */
export const ListAuthorizedEndpointsReq = new ListAuthorizedEndpointsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAuthorizedEndpointsRes$Type extends MessageType<ListAuthorizedEndpointsRes> {
    constructor() {
        super("rd.api.company.ListAuthorizedEndpointsRes", [
            { no: 1, name: "endpoints", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListAuthorizedEndpointsRes>): ListAuthorizedEndpointsRes {
        const message = { endpoints: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListAuthorizedEndpointsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAuthorizedEndpointsRes): ListAuthorizedEndpointsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string endpoints */ 1:
                    message.endpoints.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAuthorizedEndpointsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string endpoints = 1; */
        for (let i = 0; i < message.endpoints.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.endpoints[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListAuthorizedEndpointsRes
 */
export const ListAuthorizedEndpointsRes = new ListAuthorizedEndpointsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStaffPermissionsReq$Type extends MessageType<UpdateStaffPermissionsReq> {
    constructor() {
        super("rd.api.company.UpdateStaffPermissionsReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "role_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateStaffPermissionsReq>): UpdateStaffPermissionsReq {
        const message = { roleIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateStaffPermissionsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateStaffPermissionsReq): UpdateStaffPermissionsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* repeated string role_ids */ 2:
                    message.roleIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateStaffPermissionsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated string role_ids = 2; */
        for (let i = 0; i < message.roleIds.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.roleIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateStaffPermissionsReq
 */
export const UpdateStaffPermissionsReq = new UpdateStaffPermissionsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStaffPermissionsRes$Type extends MessageType<UpdateStaffPermissionsRes> {
    constructor() {
        super("rd.api.company.UpdateStaffPermissionsRes", []);
    }
    create(value?: PartialMessage<UpdateStaffPermissionsRes>): UpdateStaffPermissionsRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateStaffPermissionsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateStaffPermissionsRes): UpdateStaffPermissionsRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateStaffPermissionsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateStaffPermissionsRes
 */
export const UpdateStaffPermissionsRes = new UpdateStaffPermissionsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateAdditionalFeeReq$Type extends MessageType<CreateAdditionalFeeReq> {
    constructor() {
        super("rd.api.company.CreateAdditionalFeeReq", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 2, name: "fee", kind: "message", T: () => Money },
            { no: 3, name: "fee_percent", kind: "message", T: () => Decimal },
            { no: 4, name: "is_taxable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CreateAdditionalFeeReq>): CreateAdditionalFeeReq {
        const message = { name: "", isTaxable: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateAdditionalFeeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateAdditionalFeeReq): CreateAdditionalFeeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* rd.proto.money.Money fee */ 2:
                    message.fee = Money.internalBinaryRead(reader, reader.uint32(), options, message.fee);
                    break;
                case /* rd.proto.decimal.Decimal fee_percent */ 3:
                    message.feePercent = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.feePercent);
                    break;
                case /* bool is_taxable */ 4:
                    message.isTaxable = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateAdditionalFeeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* rd.proto.money.Money fee = 2; */
        if (message.fee)
            Money.internalBinaryWrite(message.fee, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal fee_percent = 3; */
        if (message.feePercent)
            Decimal.internalBinaryWrite(message.feePercent, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bool is_taxable = 4; */
        if (message.isTaxable !== false)
            writer.tag(4, WireType.Varint).bool(message.isTaxable);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.CreateAdditionalFeeReq
 */
export const CreateAdditionalFeeReq = new CreateAdditionalFeeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateAdditionalFeeRes$Type extends MessageType<CreateAdditionalFeeRes> {
    constructor() {
        super("rd.api.company.CreateAdditionalFeeRes", [
            { no: 1, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateAdditionalFeeRes>): CreateAdditionalFeeRes {
        const message = { idString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateAdditionalFeeRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateAdditionalFeeRes): CreateAdditionalFeeRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_string */ 1:
                    message.idString = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateAdditionalFeeRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_string = 1; */
        if (message.idString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idString);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.CreateAdditionalFeeRes
 */
export const CreateAdditionalFeeRes = new CreateAdditionalFeeRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateAdditionalFeeReq$Type extends MessageType<UpdateAdditionalFeeReq> {
    constructor() {
        super("rd.api.company.UpdateAdditionalFeeReq", [
            { no: 1, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 3, name: "fee", kind: "message", T: () => Money },
            { no: 4, name: "fee_percent", kind: "message", T: () => Decimal },
            { no: 5, name: "is_taxable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateAdditionalFeeReq>): UpdateAdditionalFeeReq {
        const message = { idString: "", name: "", isTaxable: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateAdditionalFeeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateAdditionalFeeReq): UpdateAdditionalFeeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_string */ 1:
                    message.idString = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* rd.proto.money.Money fee */ 3:
                    message.fee = Money.internalBinaryRead(reader, reader.uint32(), options, message.fee);
                    break;
                case /* rd.proto.decimal.Decimal fee_percent */ 4:
                    message.feePercent = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.feePercent);
                    break;
                case /* bool is_taxable */ 5:
                    message.isTaxable = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateAdditionalFeeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_string = 1; */
        if (message.idString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idString);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* rd.proto.money.Money fee = 3; */
        if (message.fee)
            Money.internalBinaryWrite(message.fee, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal fee_percent = 4; */
        if (message.feePercent)
            Decimal.internalBinaryWrite(message.feePercent, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bool is_taxable = 5; */
        if (message.isTaxable !== false)
            writer.tag(5, WireType.Varint).bool(message.isTaxable);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateAdditionalFeeReq
 */
export const UpdateAdditionalFeeReq = new UpdateAdditionalFeeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateAdditionalFeeRes$Type extends MessageType<UpdateAdditionalFeeRes> {
    constructor() {
        super("rd.api.company.UpdateAdditionalFeeRes", []);
    }
    create(value?: PartialMessage<UpdateAdditionalFeeRes>): UpdateAdditionalFeeRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateAdditionalFeeRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateAdditionalFeeRes): UpdateAdditionalFeeRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateAdditionalFeeRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateAdditionalFeeRes
 */
export const UpdateAdditionalFeeRes = new UpdateAdditionalFeeRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteAdditionalFeeReq$Type extends MessageType<DeleteAdditionalFeeReq> {
    constructor() {
        super("rd.api.company.DeleteAdditionalFeeReq", [
            { no: 1, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<DeleteAdditionalFeeReq>): DeleteAdditionalFeeReq {
        const message = { idString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteAdditionalFeeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteAdditionalFeeReq): DeleteAdditionalFeeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_string */ 1:
                    message.idString = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteAdditionalFeeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_string = 1; */
        if (message.idString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idString);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.DeleteAdditionalFeeReq
 */
export const DeleteAdditionalFeeReq = new DeleteAdditionalFeeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteAdditionalFeeRes$Type extends MessageType<DeleteAdditionalFeeRes> {
    constructor() {
        super("rd.api.company.DeleteAdditionalFeeRes", []);
    }
    create(value?: PartialMessage<DeleteAdditionalFeeRes>): DeleteAdditionalFeeRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteAdditionalFeeRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteAdditionalFeeRes): DeleteAdditionalFeeRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteAdditionalFeeRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.DeleteAdditionalFeeRes
 */
export const DeleteAdditionalFeeRes = new DeleteAdditionalFeeRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReorderAdditionalFeesReq$Type extends MessageType<ReorderAdditionalFeesReq> {
    constructor() {
        super("rd.api.company.ReorderAdditionalFeesReq", [
            { no: 1, name: "id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<ReorderAdditionalFeesReq>): ReorderAdditionalFeesReq {
        const message = { idStrings: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReorderAdditionalFeesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReorderAdditionalFeesReq): ReorderAdditionalFeesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string id_strings */ 1:
                    message.idStrings.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReorderAdditionalFeesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string id_strings = 1; */
        for (let i = 0; i < message.idStrings.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.idStrings[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ReorderAdditionalFeesReq
 */
export const ReorderAdditionalFeesReq = new ReorderAdditionalFeesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReorderAdditionalFeesRes$Type extends MessageType<ReorderAdditionalFeesRes> {
    constructor() {
        super("rd.api.company.ReorderAdditionalFeesRes", []);
    }
    create(value?: PartialMessage<ReorderAdditionalFeesRes>): ReorderAdditionalFeesRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReorderAdditionalFeesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReorderAdditionalFeesRes): ReorderAdditionalFeesRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ReorderAdditionalFeesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ReorderAdditionalFeesRes
 */
export const ReorderAdditionalFeesRes = new ReorderAdditionalFeesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAdditionalFeesForCompanyReq$Type extends MessageType<ListAdditionalFeesForCompanyReq> {
    constructor() {
        super("rd.api.company.ListAdditionalFeesForCompanyReq", []);
    }
    create(value?: PartialMessage<ListAdditionalFeesForCompanyReq>): ListAdditionalFeesForCompanyReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListAdditionalFeesForCompanyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAdditionalFeesForCompanyReq): ListAdditionalFeesForCompanyReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListAdditionalFeesForCompanyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListAdditionalFeesForCompanyReq
 */
export const ListAdditionalFeesForCompanyReq = new ListAdditionalFeesForCompanyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAdditionalFeesForCompanyRes$Type extends MessageType<ListAdditionalFeesForCompanyRes> {
    constructor() {
        super("rd.api.company.ListAdditionalFeesForCompanyRes", [
            { no: 1, name: "fees", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AdditionalFees_Fee }
        ]);
    }
    create(value?: PartialMessage<ListAdditionalFeesForCompanyRes>): ListAdditionalFeesForCompanyRes {
        const message = { fees: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListAdditionalFeesForCompanyRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAdditionalFeesForCompanyRes): ListAdditionalFeesForCompanyRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.company.AdditionalFees.Fee fees */ 1:
                    message.fees.push(AdditionalFees_Fee.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAdditionalFeesForCompanyRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.company.AdditionalFees.Fee fees = 1; */
        for (let i = 0; i < message.fees.length; i++)
            AdditionalFees_Fee.internalBinaryWrite(message.fees[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListAdditionalFeesForCompanyRes
 */
export const ListAdditionalFeesForCompanyRes = new ListAdditionalFeesForCompanyRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListProductsForFeeReq$Type extends MessageType<ListProductsForFeeReq> {
    constructor() {
        super("rd.api.company.ListProductsForFeeReq", [
            { no: 1, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListProductsForFeeReq>): ListProductsForFeeReq {
        const message = { idString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListProductsForFeeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListProductsForFeeReq): ListProductsForFeeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_string */ 1:
                    message.idString = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListProductsForFeeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_string = 1; */
        if (message.idString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idString);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListProductsForFeeReq
 */
export const ListProductsForFeeReq = new ListProductsForFeeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListProductsForFeeRes$Type extends MessageType<ListProductsForFeeRes> {
    constructor() {
        super("rd.api.company.ListProductsForFeeRes", [
            { no: 1, name: "products", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Product }
        ]);
    }
    create(value?: PartialMessage<ListProductsForFeeRes>): ListProductsForFeeRes {
        const message = { products: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListProductsForFeeRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListProductsForFeeRes): ListProductsForFeeRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.product.Product products */ 1:
                    message.products.push(Product.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListProductsForFeeRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.product.Product products = 1; */
        for (let i = 0; i < message.products.length; i++)
            Product.internalBinaryWrite(message.products[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListProductsForFeeRes
 */
export const ListProductsForFeeRes = new ListProductsForFeeRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateProductCategoryV2Req$Type extends MessageType<CreateProductCategoryV2Req> {
    constructor() {
        super("rd.api.company.CreateProductCategoryV2Req", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 2, name: "type", kind: "enum", T: () => ["rd.company.ProductCategory.Type", ProductCategory_Type, "TYPE_"], options: { "rd.validator.require": true } },
            { no: 3, name: "is_default", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CreateProductCategoryV2Req>): CreateProductCategoryV2Req {
        const message = { name: "", type: 0, isDefault: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateProductCategoryV2Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateProductCategoryV2Req): CreateProductCategoryV2Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* rd.company.ProductCategory.Type type */ 2:
                    message.type = reader.int32();
                    break;
                case /* bool is_default */ 3:
                    message.isDefault = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateProductCategoryV2Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* rd.company.ProductCategory.Type type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* bool is_default = 3; */
        if (message.isDefault !== false)
            writer.tag(3, WireType.Varint).bool(message.isDefault);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.CreateProductCategoryV2Req
 */
export const CreateProductCategoryV2Req = new CreateProductCategoryV2Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateProductCategoryV2Res$Type extends MessageType<CreateProductCategoryV2Res> {
    constructor() {
        super("rd.api.company.CreateProductCategoryV2Res", []);
    }
    create(value?: PartialMessage<CreateProductCategoryV2Res>): CreateProductCategoryV2Res {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateProductCategoryV2Res>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateProductCategoryV2Res): CreateProductCategoryV2Res {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CreateProductCategoryV2Res, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.CreateProductCategoryV2Res
 */
export const CreateProductCategoryV2Res = new CreateProductCategoryV2Res$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateProductCategoryV2Req$Type extends MessageType<UpdateProductCategoryV2Req> {
    constructor() {
        super("rd.api.company.UpdateProductCategoryV2Req", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 3, name: "type", kind: "enum", T: () => ["rd.company.ProductCategory.Type", ProductCategory_Type, "TYPE_"], options: { "rd.validator.require": true } },
            { no: 4, name: "is_default", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateProductCategoryV2Req>): UpdateProductCategoryV2Req {
        const message = { name: "", type: 0, isDefault: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateProductCategoryV2Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateProductCategoryV2Req): UpdateProductCategoryV2Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* rd.company.ProductCategory.Type type */ 3:
                    message.type = reader.int32();
                    break;
                case /* bool is_default */ 4:
                    message.isDefault = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateProductCategoryV2Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* rd.company.ProductCategory.Type type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        /* bool is_default = 4; */
        if (message.isDefault !== false)
            writer.tag(4, WireType.Varint).bool(message.isDefault);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateProductCategoryV2Req
 */
export const UpdateProductCategoryV2Req = new UpdateProductCategoryV2Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateProductCategoryV2Res$Type extends MessageType<UpdateProductCategoryV2Res> {
    constructor() {
        super("rd.api.company.UpdateProductCategoryV2Res", []);
    }
    create(value?: PartialMessage<UpdateProductCategoryV2Res>): UpdateProductCategoryV2Res {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateProductCategoryV2Res>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateProductCategoryV2Res): UpdateProductCategoryV2Res {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateProductCategoryV2Res, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateProductCategoryV2Res
 */
export const UpdateProductCategoryV2Res = new UpdateProductCategoryV2Res$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListProductCategoriesForCompanyV2Req$Type extends MessageType<ListProductCategoriesForCompanyV2Req> {
    constructor() {
        super("rd.api.company.ListProductCategoriesForCompanyV2Req", []);
    }
    create(value?: PartialMessage<ListProductCategoriesForCompanyV2Req>): ListProductCategoriesForCompanyV2Req {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListProductCategoriesForCompanyV2Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListProductCategoriesForCompanyV2Req): ListProductCategoriesForCompanyV2Req {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListProductCategoriesForCompanyV2Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListProductCategoriesForCompanyV2Req
 */
export const ListProductCategoriesForCompanyV2Req = new ListProductCategoriesForCompanyV2Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListProductCategoriesForCompanyV2Res$Type extends MessageType<ListProductCategoriesForCompanyV2Res> {
    constructor() {
        super("rd.api.company.ListProductCategoriesForCompanyV2Res", [
            { no: 1, name: "default_department_id", kind: "message", T: () => UUID },
            { no: 2, name: "departments", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProductCategory },
            { no: 3, name: "classes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProductCategory },
            { no: 4, name: "finelines", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProductCategory }
        ]);
    }
    create(value?: PartialMessage<ListProductCategoriesForCompanyV2Res>): ListProductCategoriesForCompanyV2Res {
        const message = { departments: [], classes: [], finelines: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListProductCategoriesForCompanyV2Res>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListProductCategoriesForCompanyV2Res): ListProductCategoriesForCompanyV2Res {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID default_department_id */ 1:
                    message.defaultDepartmentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.defaultDepartmentId);
                    break;
                case /* repeated rd.company.ProductCategory departments */ 2:
                    message.departments.push(ProductCategory.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.company.ProductCategory classes */ 3:
                    message.classes.push(ProductCategory.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.company.ProductCategory finelines */ 4:
                    message.finelines.push(ProductCategory.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListProductCategoriesForCompanyV2Res, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID default_department_id = 1; */
        if (message.defaultDepartmentId)
            UUID.internalBinaryWrite(message.defaultDepartmentId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.company.ProductCategory departments = 2; */
        for (let i = 0; i < message.departments.length; i++)
            ProductCategory.internalBinaryWrite(message.departments[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.company.ProductCategory classes = 3; */
        for (let i = 0; i < message.classes.length; i++)
            ProductCategory.internalBinaryWrite(message.classes[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.company.ProductCategory finelines = 4; */
        for (let i = 0; i < message.finelines.length; i++)
            ProductCategory.internalBinaryWrite(message.finelines[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListProductCategoriesForCompanyV2Res
 */
export const ListProductCategoriesForCompanyV2Res = new ListProductCategoriesForCompanyV2Res$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListProductsForCategoryV2Req$Type extends MessageType<ListProductsForCategoryV2Req> {
    constructor() {
        super("rd.api.company.ListProductsForCategoryV2Req", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<ListProductsForCategoryV2Req>): ListProductsForCategoryV2Req {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListProductsForCategoryV2Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListProductsForCategoryV2Req): ListProductsForCategoryV2Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListProductsForCategoryV2Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListProductsForCategoryV2Req
 */
export const ListProductsForCategoryV2Req = new ListProductsForCategoryV2Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListProductsForCategoryV2Res$Type extends MessageType<ListProductsForCategoryV2Res> {
    constructor() {
        super("rd.api.company.ListProductsForCategoryV2Res", [
            { no: 1, name: "products", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Product }
        ]);
    }
    create(value?: PartialMessage<ListProductsForCategoryV2Res>): ListProductsForCategoryV2Res {
        const message = { products: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListProductsForCategoryV2Res>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListProductsForCategoryV2Res): ListProductsForCategoryV2Res {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.product.Product products */ 1:
                    message.products.push(Product.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListProductsForCategoryV2Res, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.product.Product products = 1; */
        for (let i = 0; i < message.products.length; i++)
            Product.internalBinaryWrite(message.products[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListProductsForCategoryV2Res
 */
export const ListProductsForCategoryV2Res = new ListProductsForCategoryV2Res$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeactivateProductCategoryV2Req$Type extends MessageType<DeactivateProductCategoryV2Req> {
    constructor() {
        super("rd.api.company.DeactivateProductCategoryV2Req", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<DeactivateProductCategoryV2Req>): DeactivateProductCategoryV2Req {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeactivateProductCategoryV2Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeactivateProductCategoryV2Req): DeactivateProductCategoryV2Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeactivateProductCategoryV2Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.DeactivateProductCategoryV2Req
 */
export const DeactivateProductCategoryV2Req = new DeactivateProductCategoryV2Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeactivateProductCategoryV2Res$Type extends MessageType<DeactivateProductCategoryV2Res> {
    constructor() {
        super("rd.api.company.DeactivateProductCategoryV2Res", []);
    }
    create(value?: PartialMessage<DeactivateProductCategoryV2Res>): DeactivateProductCategoryV2Res {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeactivateProductCategoryV2Res>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeactivateProductCategoryV2Res): DeactivateProductCategoryV2Res {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeactivateProductCategoryV2Res, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.DeactivateProductCategoryV2Res
 */
export const DeactivateProductCategoryV2Res = new DeactivateProductCategoryV2Res$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyGeneralSettingsReq$Type extends MessageType<UpdateCompanyGeneralSettingsReq> {
    constructor() {
        super("rd.api.company.UpdateCompanyGeneralSettingsReq", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 2, name: "website_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCompanyGeneralSettingsReq>): UpdateCompanyGeneralSettingsReq {
        const message = { name: "", websiteUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyGeneralSettingsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyGeneralSettingsReq): UpdateCompanyGeneralSettingsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string website_url */ 2:
                    message.websiteUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCompanyGeneralSettingsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string website_url = 2; */
        if (message.websiteUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.websiteUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyGeneralSettingsReq
 */
export const UpdateCompanyGeneralSettingsReq = new UpdateCompanyGeneralSettingsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyGeneralSettingsRes$Type extends MessageType<UpdateCompanyGeneralSettingsRes> {
    constructor() {
        super("rd.api.company.UpdateCompanyGeneralSettingsRes", []);
    }
    create(value?: PartialMessage<UpdateCompanyGeneralSettingsRes>): UpdateCompanyGeneralSettingsRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyGeneralSettingsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyGeneralSettingsRes): UpdateCompanyGeneralSettingsRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateCompanyGeneralSettingsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyGeneralSettingsRes
 */
export const UpdateCompanyGeneralSettingsRes = new UpdateCompanyGeneralSettingsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanySecuritySettingsReq$Type extends MessageType<UpdateCompanySecuritySettingsReq> {
    constructor() {
        super("rd.api.company.UpdateCompanySecuritySettingsReq", [
            { no: 1, name: "pos_inactivity_timeout_minutes", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "rd.validator.require": true } },
            { no: 2, name: "autolock_after_checkout", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCompanySecuritySettingsReq>): UpdateCompanySecuritySettingsReq {
        const message = { posInactivityTimeoutMinutes: 0, autolockAfterCheckout: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanySecuritySettingsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanySecuritySettingsReq): UpdateCompanySecuritySettingsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 pos_inactivity_timeout_minutes */ 1:
                    message.posInactivityTimeoutMinutes = reader.int32();
                    break;
                case /* bool autolock_after_checkout */ 2:
                    message.autolockAfterCheckout = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCompanySecuritySettingsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 pos_inactivity_timeout_minutes = 1; */
        if (message.posInactivityTimeoutMinutes !== 0)
            writer.tag(1, WireType.Varint).int32(message.posInactivityTimeoutMinutes);
        /* bool autolock_after_checkout = 2; */
        if (message.autolockAfterCheckout !== false)
            writer.tag(2, WireType.Varint).bool(message.autolockAfterCheckout);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanySecuritySettingsReq
 */
export const UpdateCompanySecuritySettingsReq = new UpdateCompanySecuritySettingsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanySecuritySettingsRes$Type extends MessageType<UpdateCompanySecuritySettingsRes> {
    constructor() {
        super("rd.api.company.UpdateCompanySecuritySettingsRes", []);
    }
    create(value?: PartialMessage<UpdateCompanySecuritySettingsRes>): UpdateCompanySecuritySettingsRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanySecuritySettingsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanySecuritySettingsRes): UpdateCompanySecuritySettingsRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateCompanySecuritySettingsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanySecuritySettingsRes
 */
export const UpdateCompanySecuritySettingsRes = new UpdateCompanySecuritySettingsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyStatementSettingsReq$Type extends MessageType<UpdateCompanyStatementSettingsReq> {
    constructor() {
        super("rd.api.company.UpdateCompanyStatementSettingsReq", [
            { no: 1, name: "statement_day", kind: "enum", T: () => ["rd.company.Company.StatementDay", Company_StatementDay, "STATEMENT_DAY_"], options: { "rd.validator.require": true } },
            { no: 3, name: "statement_envelope_type", kind: "enum", T: () => ["rd.company.Company.StatementEnvelopeType", Company_StatementEnvelopeType] }
        ]);
    }
    create(value?: PartialMessage<UpdateCompanyStatementSettingsReq>): UpdateCompanyStatementSettingsReq {
        const message = { statementDay: 0, statementEnvelopeType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyStatementSettingsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyStatementSettingsReq): UpdateCompanyStatementSettingsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.company.Company.StatementDay statement_day */ 1:
                    message.statementDay = reader.int32();
                    break;
                case /* rd.company.Company.StatementEnvelopeType statement_envelope_type */ 3:
                    message.statementEnvelopeType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCompanyStatementSettingsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.company.Company.StatementDay statement_day = 1; */
        if (message.statementDay !== 0)
            writer.tag(1, WireType.Varint).int32(message.statementDay);
        /* rd.company.Company.StatementEnvelopeType statement_envelope_type = 3; */
        if (message.statementEnvelopeType !== 0)
            writer.tag(3, WireType.Varint).int32(message.statementEnvelopeType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyStatementSettingsReq
 */
export const UpdateCompanyStatementSettingsReq = new UpdateCompanyStatementSettingsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyStatementSettingsRes$Type extends MessageType<UpdateCompanyStatementSettingsRes> {
    constructor() {
        super("rd.api.company.UpdateCompanyStatementSettingsRes", []);
    }
    create(value?: PartialMessage<UpdateCompanyStatementSettingsRes>): UpdateCompanyStatementSettingsRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyStatementSettingsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyStatementSettingsRes): UpdateCompanyStatementSettingsRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateCompanyStatementSettingsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyStatementSettingsRes
 */
export const UpdateCompanyStatementSettingsRes = new UpdateCompanyStatementSettingsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyGeneralCommunicationsSettingsReq$Type extends MessageType<UpdateCompanyGeneralCommunicationsSettingsReq> {
    constructor() {
        super("rd.api.company.UpdateCompanyGeneralCommunicationsSettingsReq", [
            { no: 2, name: "default_quote_expiry_days", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "rd.validator.require": true } },
            { no: 3, name: "return_policy", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<UpdateCompanyGeneralCommunicationsSettingsReq>): UpdateCompanyGeneralCommunicationsSettingsReq {
        const message = { defaultQuoteExpiryDays: 0, returnPolicy: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyGeneralCommunicationsSettingsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyGeneralCommunicationsSettingsReq): UpdateCompanyGeneralCommunicationsSettingsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 default_quote_expiry_days */ 2:
                    message.defaultQuoteExpiryDays = reader.int32();
                    break;
                case /* string return_policy */ 3:
                    message.returnPolicy = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCompanyGeneralCommunicationsSettingsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 default_quote_expiry_days = 2; */
        if (message.defaultQuoteExpiryDays !== 0)
            writer.tag(2, WireType.Varint).int32(message.defaultQuoteExpiryDays);
        /* string return_policy = 3; */
        if (message.returnPolicy !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.returnPolicy);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyGeneralCommunicationsSettingsReq
 */
export const UpdateCompanyGeneralCommunicationsSettingsReq = new UpdateCompanyGeneralCommunicationsSettingsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyGeneralCommunicationsSettingsRes$Type extends MessageType<UpdateCompanyGeneralCommunicationsSettingsRes> {
    constructor() {
        super("rd.api.company.UpdateCompanyGeneralCommunicationsSettingsRes", []);
    }
    create(value?: PartialMessage<UpdateCompanyGeneralCommunicationsSettingsRes>): UpdateCompanyGeneralCommunicationsSettingsRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyGeneralCommunicationsSettingsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyGeneralCommunicationsSettingsRes): UpdateCompanyGeneralCommunicationsSettingsRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateCompanyGeneralCommunicationsSettingsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyGeneralCommunicationsSettingsRes
 */
export const UpdateCompanyGeneralCommunicationsSettingsRes = new UpdateCompanyGeneralCommunicationsSettingsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyShouldAutoSubmitContractorRewardsReq$Type extends MessageType<UpdateCompanyShouldAutoSubmitContractorRewardsReq> {
    constructor() {
        super("rd.api.company.UpdateCompanyShouldAutoSubmitContractorRewardsReq", [
            { no: 1, name: "should_auto_submit_contractor_rewards", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCompanyShouldAutoSubmitContractorRewardsReq>): UpdateCompanyShouldAutoSubmitContractorRewardsReq {
        const message = { shouldAutoSubmitContractorRewards: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyShouldAutoSubmitContractorRewardsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyShouldAutoSubmitContractorRewardsReq): UpdateCompanyShouldAutoSubmitContractorRewardsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool should_auto_submit_contractor_rewards */ 1:
                    message.shouldAutoSubmitContractorRewards = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCompanyShouldAutoSubmitContractorRewardsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool should_auto_submit_contractor_rewards = 1; */
        if (message.shouldAutoSubmitContractorRewards !== false)
            writer.tag(1, WireType.Varint).bool(message.shouldAutoSubmitContractorRewards);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyShouldAutoSubmitContractorRewardsReq
 */
export const UpdateCompanyShouldAutoSubmitContractorRewardsReq = new UpdateCompanyShouldAutoSubmitContractorRewardsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyShouldAutoSubmitContractorRewardsRes$Type extends MessageType<UpdateCompanyShouldAutoSubmitContractorRewardsRes> {
    constructor() {
        super("rd.api.company.UpdateCompanyShouldAutoSubmitContractorRewardsRes", []);
    }
    create(value?: PartialMessage<UpdateCompanyShouldAutoSubmitContractorRewardsRes>): UpdateCompanyShouldAutoSubmitContractorRewardsRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyShouldAutoSubmitContractorRewardsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyShouldAutoSubmitContractorRewardsRes): UpdateCompanyShouldAutoSubmitContractorRewardsRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateCompanyShouldAutoSubmitContractorRewardsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyShouldAutoSubmitContractorRewardsRes
 */
export const UpdateCompanyShouldAutoSubmitContractorRewardsRes = new UpdateCompanyShouldAutoSubmitContractorRewardsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyContractorRewardsSettingsReq$Type extends MessageType<UpdateCompanyContractorRewardsSettingsReq> {
    constructor() {
        super("rd.api.company.UpdateCompanyContractorRewardsSettingsReq", [
            { no: 1, name: "contractor_rewards_bonus_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCompanyContractorRewardsSettingsReq>): UpdateCompanyContractorRewardsSettingsReq {
        const message = { contractorRewardsBonusCode: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyContractorRewardsSettingsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyContractorRewardsSettingsReq): UpdateCompanyContractorRewardsSettingsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string contractor_rewards_bonus_code */ 1:
                    message.contractorRewardsBonusCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCompanyContractorRewardsSettingsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string contractor_rewards_bonus_code = 1; */
        if (message.contractorRewardsBonusCode !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.contractorRewardsBonusCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyContractorRewardsSettingsReq
 */
export const UpdateCompanyContractorRewardsSettingsReq = new UpdateCompanyContractorRewardsSettingsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyContractorRewardsSettingsRes$Type extends MessageType<UpdateCompanyContractorRewardsSettingsRes> {
    constructor() {
        super("rd.api.company.UpdateCompanyContractorRewardsSettingsRes", []);
    }
    create(value?: PartialMessage<UpdateCompanyContractorRewardsSettingsRes>): UpdateCompanyContractorRewardsSettingsRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyContractorRewardsSettingsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyContractorRewardsSettingsRes): UpdateCompanyContractorRewardsSettingsRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateCompanyContractorRewardsSettingsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyContractorRewardsSettingsRes
 */
export const UpdateCompanyContractorRewardsSettingsRes = new UpdateCompanyContractorRewardsSettingsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyIsDiscountShownToCustomersReq$Type extends MessageType<UpdateCompanyIsDiscountShownToCustomersReq> {
    constructor() {
        super("rd.api.company.UpdateCompanyIsDiscountShownToCustomersReq", [
            { no: 1, name: "is_discount_shown_to_customers", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCompanyIsDiscountShownToCustomersReq>): UpdateCompanyIsDiscountShownToCustomersReq {
        const message = { isDiscountShownToCustomers: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyIsDiscountShownToCustomersReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyIsDiscountShownToCustomersReq): UpdateCompanyIsDiscountShownToCustomersReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool is_discount_shown_to_customers */ 1:
                    message.isDiscountShownToCustomers = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCompanyIsDiscountShownToCustomersReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool is_discount_shown_to_customers = 1; */
        if (message.isDiscountShownToCustomers !== false)
            writer.tag(1, WireType.Varint).bool(message.isDiscountShownToCustomers);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyIsDiscountShownToCustomersReq
 */
export const UpdateCompanyIsDiscountShownToCustomersReq = new UpdateCompanyIsDiscountShownToCustomersReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyIsDiscountShownToCustomersRes$Type extends MessageType<UpdateCompanyIsDiscountShownToCustomersRes> {
    constructor() {
        super("rd.api.company.UpdateCompanyIsDiscountShownToCustomersRes", []);
    }
    create(value?: PartialMessage<UpdateCompanyIsDiscountShownToCustomersRes>): UpdateCompanyIsDiscountShownToCustomersRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyIsDiscountShownToCustomersRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyIsDiscountShownToCustomersRes): UpdateCompanyIsDiscountShownToCustomersRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateCompanyIsDiscountShownToCustomersRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyIsDiscountShownToCustomersRes
 */
export const UpdateCompanyIsDiscountShownToCustomersRes = new UpdateCompanyIsDiscountShownToCustomersRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyIsPriceShownOnWillCallReq$Type extends MessageType<UpdateCompanyIsPriceShownOnWillCallReq> {
    constructor() {
        super("rd.api.company.UpdateCompanyIsPriceShownOnWillCallReq", [
            { no: 1, name: "is_price_shown_on_will_call", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCompanyIsPriceShownOnWillCallReq>): UpdateCompanyIsPriceShownOnWillCallReq {
        const message = { isPriceShownOnWillCall: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyIsPriceShownOnWillCallReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyIsPriceShownOnWillCallReq): UpdateCompanyIsPriceShownOnWillCallReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool is_price_shown_on_will_call */ 1:
                    message.isPriceShownOnWillCall = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCompanyIsPriceShownOnWillCallReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool is_price_shown_on_will_call = 1; */
        if (message.isPriceShownOnWillCall !== false)
            writer.tag(1, WireType.Varint).bool(message.isPriceShownOnWillCall);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyIsPriceShownOnWillCallReq
 */
export const UpdateCompanyIsPriceShownOnWillCallReq = new UpdateCompanyIsPriceShownOnWillCallReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyIsPriceShownOnWillCallRes$Type extends MessageType<UpdateCompanyIsPriceShownOnWillCallRes> {
    constructor() {
        super("rd.api.company.UpdateCompanyIsPriceShownOnWillCallRes", []);
    }
    create(value?: PartialMessage<UpdateCompanyIsPriceShownOnWillCallRes>): UpdateCompanyIsPriceShownOnWillCallRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyIsPriceShownOnWillCallRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyIsPriceShownOnWillCallRes): UpdateCompanyIsPriceShownOnWillCallRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateCompanyIsPriceShownOnWillCallRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyIsPriceShownOnWillCallRes
 */
export const UpdateCompanyIsPriceShownOnWillCallRes = new UpdateCompanyIsPriceShownOnWillCallRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyIsColorFormulaHiddenReq$Type extends MessageType<UpdateCompanyIsColorFormulaHiddenReq> {
    constructor() {
        super("rd.api.company.UpdateCompanyIsColorFormulaHiddenReq", [
            { no: 1, name: "is_color_formula_hidden", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCompanyIsColorFormulaHiddenReq>): UpdateCompanyIsColorFormulaHiddenReq {
        const message = { isColorFormulaHidden: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyIsColorFormulaHiddenReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyIsColorFormulaHiddenReq): UpdateCompanyIsColorFormulaHiddenReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool is_color_formula_hidden */ 1:
                    message.isColorFormulaHidden = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCompanyIsColorFormulaHiddenReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool is_color_formula_hidden = 1; */
        if (message.isColorFormulaHidden !== false)
            writer.tag(1, WireType.Varint).bool(message.isColorFormulaHidden);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyIsColorFormulaHiddenReq
 */
export const UpdateCompanyIsColorFormulaHiddenReq = new UpdateCompanyIsColorFormulaHiddenReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyIsColorFormulaHiddenRes$Type extends MessageType<UpdateCompanyIsColorFormulaHiddenRes> {
    constructor() {
        super("rd.api.company.UpdateCompanyIsColorFormulaHiddenRes", []);
    }
    create(value?: PartialMessage<UpdateCompanyIsColorFormulaHiddenRes>): UpdateCompanyIsColorFormulaHiddenRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyIsColorFormulaHiddenRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyIsColorFormulaHiddenRes): UpdateCompanyIsColorFormulaHiddenRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateCompanyIsColorFormulaHiddenRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyIsColorFormulaHiddenRes
 */
export const UpdateCompanyIsColorFormulaHiddenRes = new UpdateCompanyIsColorFormulaHiddenRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyIsFinancingTermsShownOnInvoiceReq$Type extends MessageType<UpdateCompanyIsFinancingTermsShownOnInvoiceReq> {
    constructor() {
        super("rd.api.company.UpdateCompanyIsFinancingTermsShownOnInvoiceReq", [
            { no: 1, name: "is_financing_terms_shown_on_invoice", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCompanyIsFinancingTermsShownOnInvoiceReq>): UpdateCompanyIsFinancingTermsShownOnInvoiceReq {
        const message = { isFinancingTermsShownOnInvoice: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyIsFinancingTermsShownOnInvoiceReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyIsFinancingTermsShownOnInvoiceReq): UpdateCompanyIsFinancingTermsShownOnInvoiceReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool is_financing_terms_shown_on_invoice */ 1:
                    message.isFinancingTermsShownOnInvoice = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCompanyIsFinancingTermsShownOnInvoiceReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool is_financing_terms_shown_on_invoice = 1; */
        if (message.isFinancingTermsShownOnInvoice !== false)
            writer.tag(1, WireType.Varint).bool(message.isFinancingTermsShownOnInvoice);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyIsFinancingTermsShownOnInvoiceReq
 */
export const UpdateCompanyIsFinancingTermsShownOnInvoiceReq = new UpdateCompanyIsFinancingTermsShownOnInvoiceReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyIsFinancingTermsShownOnInvoiceRes$Type extends MessageType<UpdateCompanyIsFinancingTermsShownOnInvoiceRes> {
    constructor() {
        super("rd.api.company.UpdateCompanyIsFinancingTermsShownOnInvoiceRes", []);
    }
    create(value?: PartialMessage<UpdateCompanyIsFinancingTermsShownOnInvoiceRes>): UpdateCompanyIsFinancingTermsShownOnInvoiceRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyIsFinancingTermsShownOnInvoiceRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyIsFinancingTermsShownOnInvoiceRes): UpdateCompanyIsFinancingTermsShownOnInvoiceRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateCompanyIsFinancingTermsShownOnInvoiceRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyIsFinancingTermsShownOnInvoiceRes
 */
export const UpdateCompanyIsFinancingTermsShownOnInvoiceRes = new UpdateCompanyIsFinancingTermsShownOnInvoiceRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyIsCardSurchargeTaxExemptReq$Type extends MessageType<UpdateCompanyIsCardSurchargeTaxExemptReq> {
    constructor() {
        super("rd.api.company.UpdateCompanyIsCardSurchargeTaxExemptReq", [
            { no: 1, name: "is_card_surcharge_tax_exempt", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCompanyIsCardSurchargeTaxExemptReq>): UpdateCompanyIsCardSurchargeTaxExemptReq {
        const message = { isCardSurchargeTaxExempt: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyIsCardSurchargeTaxExemptReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyIsCardSurchargeTaxExemptReq): UpdateCompanyIsCardSurchargeTaxExemptReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool is_card_surcharge_tax_exempt */ 1:
                    message.isCardSurchargeTaxExempt = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCompanyIsCardSurchargeTaxExemptReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool is_card_surcharge_tax_exempt = 1; */
        if (message.isCardSurchargeTaxExempt !== false)
            writer.tag(1, WireType.Varint).bool(message.isCardSurchargeTaxExempt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyIsCardSurchargeTaxExemptReq
 */
export const UpdateCompanyIsCardSurchargeTaxExemptReq = new UpdateCompanyIsCardSurchargeTaxExemptReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyIsCardSurchargeTaxExemptRes$Type extends MessageType<UpdateCompanyIsCardSurchargeTaxExemptRes> {
    constructor() {
        super("rd.api.company.UpdateCompanyIsCardSurchargeTaxExemptRes", []);
    }
    create(value?: PartialMessage<UpdateCompanyIsCardSurchargeTaxExemptRes>): UpdateCompanyIsCardSurchargeTaxExemptRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyIsCardSurchargeTaxExemptRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyIsCardSurchargeTaxExemptRes): UpdateCompanyIsCardSurchargeTaxExemptRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateCompanyIsCardSurchargeTaxExemptRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyIsCardSurchargeTaxExemptRes
 */
export const UpdateCompanyIsCardSurchargeTaxExemptRes = new UpdateCompanyIsCardSurchargeTaxExemptRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyIsLoyaltyProgramEnabledReq$Type extends MessageType<UpdateCompanyIsLoyaltyProgramEnabledReq> {
    constructor() {
        super("rd.api.company.UpdateCompanyIsLoyaltyProgramEnabledReq", [
            { no: 1, name: "is_loyalty_program_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCompanyIsLoyaltyProgramEnabledReq>): UpdateCompanyIsLoyaltyProgramEnabledReq {
        const message = { isLoyaltyProgramEnabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyIsLoyaltyProgramEnabledReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyIsLoyaltyProgramEnabledReq): UpdateCompanyIsLoyaltyProgramEnabledReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool is_loyalty_program_enabled */ 1:
                    message.isLoyaltyProgramEnabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCompanyIsLoyaltyProgramEnabledReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool is_loyalty_program_enabled = 1; */
        if (message.isLoyaltyProgramEnabled !== false)
            writer.tag(1, WireType.Varint).bool(message.isLoyaltyProgramEnabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyIsLoyaltyProgramEnabledReq
 */
export const UpdateCompanyIsLoyaltyProgramEnabledReq = new UpdateCompanyIsLoyaltyProgramEnabledReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyIsLoyaltyProgramEnabledRes$Type extends MessageType<UpdateCompanyIsLoyaltyProgramEnabledRes> {
    constructor() {
        super("rd.api.company.UpdateCompanyIsLoyaltyProgramEnabledRes", []);
    }
    create(value?: PartialMessage<UpdateCompanyIsLoyaltyProgramEnabledRes>): UpdateCompanyIsLoyaltyProgramEnabledRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyIsLoyaltyProgramEnabledRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyIsLoyaltyProgramEnabledRes): UpdateCompanyIsLoyaltyProgramEnabledRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateCompanyIsLoyaltyProgramEnabledRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyIsLoyaltyProgramEnabledRes
 */
export const UpdateCompanyIsLoyaltyProgramEnabledRes = new UpdateCompanyIsLoyaltyProgramEnabledRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyLoyaltyProgramPolicyReq$Type extends MessageType<UpdateCompanyLoyaltyProgramPolicyReq> {
    constructor() {
        super("rd.api.company.UpdateCompanyLoyaltyProgramPolicyReq", [
            { no: 1, name: "loyalty_program_policy", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCompanyLoyaltyProgramPolicyReq>): UpdateCompanyLoyaltyProgramPolicyReq {
        const message = { loyaltyProgramPolicy: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyLoyaltyProgramPolicyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyLoyaltyProgramPolicyReq): UpdateCompanyLoyaltyProgramPolicyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string loyalty_program_policy */ 1:
                    message.loyaltyProgramPolicy = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCompanyLoyaltyProgramPolicyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string loyalty_program_policy = 1; */
        if (message.loyaltyProgramPolicy !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.loyaltyProgramPolicy);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyLoyaltyProgramPolicyReq
 */
export const UpdateCompanyLoyaltyProgramPolicyReq = new UpdateCompanyLoyaltyProgramPolicyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyLoyaltyProgramPolicyRes$Type extends MessageType<UpdateCompanyLoyaltyProgramPolicyRes> {
    constructor() {
        super("rd.api.company.UpdateCompanyLoyaltyProgramPolicyRes", []);
    }
    create(value?: PartialMessage<UpdateCompanyLoyaltyProgramPolicyRes>): UpdateCompanyLoyaltyProgramPolicyRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyLoyaltyProgramPolicyRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyLoyaltyProgramPolicyRes): UpdateCompanyLoyaltyProgramPolicyRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateCompanyLoyaltyProgramPolicyRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyLoyaltyProgramPolicyRes
 */
export const UpdateCompanyLoyaltyProgramPolicyRes = new UpdateCompanyLoyaltyProgramPolicyRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq$Type extends MessageType<UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq> {
    constructor() {
        super("rd.api.company.UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq", [
            { no: 1, name: "should_auto_sign_out_after_sale", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq>): UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq {
        const message = { shouldAutoSignOutAfterSale: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq): UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool should_auto_sign_out_after_sale */ 1:
                    message.shouldAutoSignOutAfterSale = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool should_auto_sign_out_after_sale = 1; */
        if (message.shouldAutoSignOutAfterSale !== false)
            writer.tag(1, WireType.Varint).bool(message.shouldAutoSignOutAfterSale);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq
 */
export const UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq = new UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes$Type extends MessageType<UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes> {
    constructor() {
        super("rd.api.company.UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes", []);
    }
    create(value?: PartialMessage<UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes>): UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes): UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes
 */
export const UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes = new UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreatePricingTierReq$Type extends MessageType<CreatePricingTierReq> {
    constructor() {
        super("rd.api.company.CreatePricingTierReq", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 2, name: "is_default", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "is_rule_based", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CreatePricingTierReq>): CreatePricingTierReq {
        const message = { name: "", isDefault: false, isRuleBased: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreatePricingTierReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreatePricingTierReq): CreatePricingTierReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* bool is_default */ 2:
                    message.isDefault = reader.bool();
                    break;
                case /* bool is_rule_based */ 3:
                    message.isRuleBased = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreatePricingTierReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* bool is_default = 2; */
        if (message.isDefault !== false)
            writer.tag(2, WireType.Varint).bool(message.isDefault);
        /* bool is_rule_based = 3; */
        if (message.isRuleBased !== false)
            writer.tag(3, WireType.Varint).bool(message.isRuleBased);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.CreatePricingTierReq
 */
export const CreatePricingTierReq = new CreatePricingTierReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreatePricingTierRes$Type extends MessageType<CreatePricingTierRes> {
    constructor() {
        super("rd.api.company.CreatePricingTierRes", [
            { no: 1, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreatePricingTierRes>): CreatePricingTierRes {
        const message = { idString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreatePricingTierRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreatePricingTierRes): CreatePricingTierRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_string */ 1:
                    message.idString = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreatePricingTierRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_string = 1; */
        if (message.idString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idString);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.CreatePricingTierRes
 */
export const CreatePricingTierRes = new CreatePricingTierRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdatePricingTierReq$Type extends MessageType<UpdatePricingTierReq> {
    constructor() {
        super("rd.api.company.UpdatePricingTierReq", [
            { no: 1, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 3, name: "is_default", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdatePricingTierReq>): UpdatePricingTierReq {
        const message = { idString: "", name: "", isDefault: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdatePricingTierReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdatePricingTierReq): UpdatePricingTierReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_string */ 1:
                    message.idString = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* bool is_default */ 3:
                    message.isDefault = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdatePricingTierReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_string = 1; */
        if (message.idString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idString);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* bool is_default = 3; */
        if (message.isDefault !== false)
            writer.tag(3, WireType.Varint).bool(message.isDefault);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdatePricingTierReq
 */
export const UpdatePricingTierReq = new UpdatePricingTierReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdatePricingTierRes$Type extends MessageType<UpdatePricingTierRes> {
    constructor() {
        super("rd.api.company.UpdatePricingTierRes", []);
    }
    create(value?: PartialMessage<UpdatePricingTierRes>): UpdatePricingTierRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdatePricingTierRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdatePricingTierRes): UpdatePricingTierRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdatePricingTierRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdatePricingTierRes
 */
export const UpdatePricingTierRes = new UpdatePricingTierRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeletePricingTierReq$Type extends MessageType<DeletePricingTierReq> {
    constructor() {
        super("rd.api.company.DeletePricingTierReq", [
            { no: 1, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<DeletePricingTierReq>): DeletePricingTierReq {
        const message = { idString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeletePricingTierReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeletePricingTierReq): DeletePricingTierReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_string */ 1:
                    message.idString = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeletePricingTierReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_string = 1; */
        if (message.idString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idString);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.DeletePricingTierReq
 */
export const DeletePricingTierReq = new DeletePricingTierReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeletePricingTierRes$Type extends MessageType<DeletePricingTierRes> {
    constructor() {
        super("rd.api.company.DeletePricingTierRes", []);
    }
    create(value?: PartialMessage<DeletePricingTierRes>): DeletePricingTierRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeletePricingTierRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeletePricingTierRes): DeletePricingTierRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeletePricingTierRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.DeletePricingTierRes
 */
export const DeletePricingTierRes = new DeletePricingTierRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReorderPricingTiersReq$Type extends MessageType<ReorderPricingTiersReq> {
    constructor() {
        super("rd.api.company.ReorderPricingTiersReq", [
            { no: 1, name: "id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<ReorderPricingTiersReq>): ReorderPricingTiersReq {
        const message = { idStrings: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReorderPricingTiersReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReorderPricingTiersReq): ReorderPricingTiersReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string id_strings */ 1:
                    message.idStrings.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReorderPricingTiersReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string id_strings = 1; */
        for (let i = 0; i < message.idStrings.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.idStrings[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ReorderPricingTiersReq
 */
export const ReorderPricingTiersReq = new ReorderPricingTiersReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReorderPricingTiersRes$Type extends MessageType<ReorderPricingTiersRes> {
    constructor() {
        super("rd.api.company.ReorderPricingTiersRes", []);
    }
    create(value?: PartialMessage<ReorderPricingTiersRes>): ReorderPricingTiersRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReorderPricingTiersRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReorderPricingTiersRes): ReorderPricingTiersRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ReorderPricingTiersRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ReorderPricingTiersRes
 */
export const ReorderPricingTiersRes = new ReorderPricingTiersRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPricingTiersForCompanyReq$Type extends MessageType<ListPricingTiersForCompanyReq> {
    constructor() {
        super("rd.api.company.ListPricingTiersForCompanyReq", []);
    }
    create(value?: PartialMessage<ListPricingTiersForCompanyReq>): ListPricingTiersForCompanyReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListPricingTiersForCompanyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPricingTiersForCompanyReq): ListPricingTiersForCompanyReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListPricingTiersForCompanyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListPricingTiersForCompanyReq
 */
export const ListPricingTiersForCompanyReq = new ListPricingTiersForCompanyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPricingTiersForCompanyRes$Type extends MessageType<ListPricingTiersForCompanyRes> {
    constructor() {
        super("rd.api.company.ListPricingTiersForCompanyRes", [
            { no: 1, name: "tiers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PricingTiers_Tier },
            { no: 2, name: "default_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListPricingTiersForCompanyRes>): ListPricingTiersForCompanyRes {
        const message = { tiers: [], defaultIdString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListPricingTiersForCompanyRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPricingTiersForCompanyRes): ListPricingTiersForCompanyRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.company.PricingTiers.Tier tiers */ 1:
                    message.tiers.push(PricingTiers_Tier.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string default_id_string */ 2:
                    message.defaultIdString = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPricingTiersForCompanyRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.company.PricingTiers.Tier tiers = 1; */
        for (let i = 0; i < message.tiers.length; i++)
            PricingTiers_Tier.internalBinaryWrite(message.tiers[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string default_id_string = 2; */
        if (message.defaultIdString !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.defaultIdString);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListPricingTiersForCompanyRes
 */
export const ListPricingTiersForCompanyRes = new ListPricingTiersForCompanyRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCustomersForTierReq$Type extends MessageType<ListCustomersForTierReq> {
    constructor() {
        super("rd.api.company.ListCustomersForTierReq", [
            { no: 1, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListCustomersForTierReq>): ListCustomersForTierReq {
        const message = { idString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListCustomersForTierReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCustomersForTierReq): ListCustomersForTierReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_string */ 1:
                    message.idString = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListCustomersForTierReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_string = 1; */
        if (message.idString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idString);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListCustomersForTierReq
 */
export const ListCustomersForTierReq = new ListCustomersForTierReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCustomersForTierRes$Type extends MessageType<ListCustomersForTierRes> {
    constructor() {
        super("rd.api.company.ListCustomersForTierRes", [
            { no: 1, name: "customers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Customer }
        ]);
    }
    create(value?: PartialMessage<ListCustomersForTierRes>): ListCustomersForTierRes {
        const message = { customers: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListCustomersForTierRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCustomersForTierRes): ListCustomersForTierRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.customer.Customer customers */ 1:
                    message.customers.push(Customer.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListCustomersForTierRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.customer.Customer customers = 1; */
        for (let i = 0; i < message.customers.length; i++)
            Customer.internalBinaryWrite(message.customers[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListCustomersForTierRes
 */
export const ListCustomersForTierRes = new ListCustomersForTierRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateFinancingTermReq$Type extends MessageType<CreateFinancingTermReq> {
    constructor() {
        super("rd.api.company.CreateFinancingTermReq", [
            { no: 1, name: "is_default", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "reference_date", kind: "enum", T: () => ["rd.company.FinancingTerms.Term.ReferenceDate", FinancingTerms_Term_ReferenceDate, "REFERENCE_DATE_"], options: { "rd.validator.require": true } },
            { no: 3, name: "due_days", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "discount_reference_days", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "discount_rate", kind: "message", T: () => Decimal },
            { no: 6, name: "is_early_payment_discount_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateFinancingTermReq>): CreateFinancingTermReq {
        const message = { isDefault: false, referenceDate: 0, dueDays: 0, discountReferenceDays: 0, isEarlyPaymentDiscountEnabled: false, name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateFinancingTermReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateFinancingTermReq): CreateFinancingTermReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool is_default */ 1:
                    message.isDefault = reader.bool();
                    break;
                case /* rd.company.FinancingTerms.Term.ReferenceDate reference_date */ 2:
                    message.referenceDate = reader.int32();
                    break;
                case /* int32 due_days */ 3:
                    message.dueDays = reader.int32();
                    break;
                case /* int32 discount_reference_days */ 4:
                    message.discountReferenceDays = reader.int32();
                    break;
                case /* rd.proto.decimal.Decimal discount_rate */ 5:
                    message.discountRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.discountRate);
                    break;
                case /* bool is_early_payment_discount_enabled */ 6:
                    message.isEarlyPaymentDiscountEnabled = reader.bool();
                    break;
                case /* string name */ 7:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateFinancingTermReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool is_default = 1; */
        if (message.isDefault !== false)
            writer.tag(1, WireType.Varint).bool(message.isDefault);
        /* rd.company.FinancingTerms.Term.ReferenceDate reference_date = 2; */
        if (message.referenceDate !== 0)
            writer.tag(2, WireType.Varint).int32(message.referenceDate);
        /* int32 due_days = 3; */
        if (message.dueDays !== 0)
            writer.tag(3, WireType.Varint).int32(message.dueDays);
        /* int32 discount_reference_days = 4; */
        if (message.discountReferenceDays !== 0)
            writer.tag(4, WireType.Varint).int32(message.discountReferenceDays);
        /* rd.proto.decimal.Decimal discount_rate = 5; */
        if (message.discountRate)
            Decimal.internalBinaryWrite(message.discountRate, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bool is_early_payment_discount_enabled = 6; */
        if (message.isEarlyPaymentDiscountEnabled !== false)
            writer.tag(6, WireType.Varint).bool(message.isEarlyPaymentDiscountEnabled);
        /* string name = 7; */
        if (message.name !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.CreateFinancingTermReq
 */
export const CreateFinancingTermReq = new CreateFinancingTermReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateFinancingTermRes$Type extends MessageType<CreateFinancingTermRes> {
    constructor() {
        super("rd.api.company.CreateFinancingTermRes", [
            { no: 1, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateFinancingTermRes>): CreateFinancingTermRes {
        const message = { idString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateFinancingTermRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateFinancingTermRes): CreateFinancingTermRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_string */ 1:
                    message.idString = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateFinancingTermRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_string = 1; */
        if (message.idString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idString);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.CreateFinancingTermRes
 */
export const CreateFinancingTermRes = new CreateFinancingTermRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateFinancingTermReq$Type extends MessageType<UpdateFinancingTermReq> {
    constructor() {
        super("rd.api.company.UpdateFinancingTermReq", [
            { no: 1, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 2, name: "is_default", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "reference_date", kind: "enum", T: () => ["rd.company.FinancingTerms.Term.ReferenceDate", FinancingTerms_Term_ReferenceDate, "REFERENCE_DATE_"], options: { "rd.validator.require": true } },
            { no: 4, name: "due_days", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "discount_days", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "discount_rate", kind: "message", T: () => Decimal },
            { no: 7, name: "is_early_payment_discount_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateFinancingTermReq>): UpdateFinancingTermReq {
        const message = { idString: "", isDefault: false, referenceDate: 0, dueDays: 0, discountDays: 0, isEarlyPaymentDiscountEnabled: false, name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateFinancingTermReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateFinancingTermReq): UpdateFinancingTermReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_string */ 1:
                    message.idString = reader.string();
                    break;
                case /* bool is_default */ 2:
                    message.isDefault = reader.bool();
                    break;
                case /* rd.company.FinancingTerms.Term.ReferenceDate reference_date */ 3:
                    message.referenceDate = reader.int32();
                    break;
                case /* int32 due_days */ 4:
                    message.dueDays = reader.int32();
                    break;
                case /* int32 discount_days */ 5:
                    message.discountDays = reader.int32();
                    break;
                case /* rd.proto.decimal.Decimal discount_rate */ 6:
                    message.discountRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.discountRate);
                    break;
                case /* bool is_early_payment_discount_enabled */ 7:
                    message.isEarlyPaymentDiscountEnabled = reader.bool();
                    break;
                case /* string name */ 8:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateFinancingTermReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_string = 1; */
        if (message.idString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idString);
        /* bool is_default = 2; */
        if (message.isDefault !== false)
            writer.tag(2, WireType.Varint).bool(message.isDefault);
        /* rd.company.FinancingTerms.Term.ReferenceDate reference_date = 3; */
        if (message.referenceDate !== 0)
            writer.tag(3, WireType.Varint).int32(message.referenceDate);
        /* int32 due_days = 4; */
        if (message.dueDays !== 0)
            writer.tag(4, WireType.Varint).int32(message.dueDays);
        /* int32 discount_days = 5; */
        if (message.discountDays !== 0)
            writer.tag(5, WireType.Varint).int32(message.discountDays);
        /* rd.proto.decimal.Decimal discount_rate = 6; */
        if (message.discountRate)
            Decimal.internalBinaryWrite(message.discountRate, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bool is_early_payment_discount_enabled = 7; */
        if (message.isEarlyPaymentDiscountEnabled !== false)
            writer.tag(7, WireType.Varint).bool(message.isEarlyPaymentDiscountEnabled);
        /* string name = 8; */
        if (message.name !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateFinancingTermReq
 */
export const UpdateFinancingTermReq = new UpdateFinancingTermReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateFinancingTermRes$Type extends MessageType<UpdateFinancingTermRes> {
    constructor() {
        super("rd.api.company.UpdateFinancingTermRes", []);
    }
    create(value?: PartialMessage<UpdateFinancingTermRes>): UpdateFinancingTermRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateFinancingTermRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateFinancingTermRes): UpdateFinancingTermRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateFinancingTermRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateFinancingTermRes
 */
export const UpdateFinancingTermRes = new UpdateFinancingTermRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteFinancingTermReq$Type extends MessageType<DeleteFinancingTermReq> {
    constructor() {
        super("rd.api.company.DeleteFinancingTermReq", [
            { no: 1, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<DeleteFinancingTermReq>): DeleteFinancingTermReq {
        const message = { idString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteFinancingTermReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteFinancingTermReq): DeleteFinancingTermReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_string */ 1:
                    message.idString = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteFinancingTermReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_string = 1; */
        if (message.idString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idString);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.DeleteFinancingTermReq
 */
export const DeleteFinancingTermReq = new DeleteFinancingTermReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteFinancingTermRes$Type extends MessageType<DeleteFinancingTermRes> {
    constructor() {
        super("rd.api.company.DeleteFinancingTermRes", []);
    }
    create(value?: PartialMessage<DeleteFinancingTermRes>): DeleteFinancingTermRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteFinancingTermRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteFinancingTermRes): DeleteFinancingTermRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteFinancingTermRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.DeleteFinancingTermRes
 */
export const DeleteFinancingTermRes = new DeleteFinancingTermRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteFinancingTermError$Type extends MessageType<DeleteFinancingTermError> {
    constructor() {
        super("rd.api.company.DeleteFinancingTermError", [
            { no: 1, name: "customers_with_balance", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Customer }
        ]);
    }
    create(value?: PartialMessage<DeleteFinancingTermError>): DeleteFinancingTermError {
        const message = { customersWithBalance: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteFinancingTermError>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteFinancingTermError): DeleteFinancingTermError {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.customer.Customer customers_with_balance */ 1:
                    message.customersWithBalance.push(Customer.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteFinancingTermError, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.customer.Customer customers_with_balance = 1; */
        for (let i = 0; i < message.customersWithBalance.length; i++)
            Customer.internalBinaryWrite(message.customersWithBalance[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.DeleteFinancingTermError
 */
export const DeleteFinancingTermError = new DeleteFinancingTermError$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReorderFinancingTermsReq$Type extends MessageType<ReorderFinancingTermsReq> {
    constructor() {
        super("rd.api.company.ReorderFinancingTermsReq", [
            { no: 1, name: "id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<ReorderFinancingTermsReq>): ReorderFinancingTermsReq {
        const message = { idStrings: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReorderFinancingTermsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReorderFinancingTermsReq): ReorderFinancingTermsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string id_strings */ 1:
                    message.idStrings.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReorderFinancingTermsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string id_strings = 1; */
        for (let i = 0; i < message.idStrings.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.idStrings[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ReorderFinancingTermsReq
 */
export const ReorderFinancingTermsReq = new ReorderFinancingTermsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReorderFinancingTermsRes$Type extends MessageType<ReorderFinancingTermsRes> {
    constructor() {
        super("rd.api.company.ReorderFinancingTermsRes", []);
    }
    create(value?: PartialMessage<ReorderFinancingTermsRes>): ReorderFinancingTermsRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReorderFinancingTermsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReorderFinancingTermsRes): ReorderFinancingTermsRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ReorderFinancingTermsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ReorderFinancingTermsRes
 */
export const ReorderFinancingTermsRes = new ReorderFinancingTermsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListFinancingTermsForCompanyReq$Type extends MessageType<ListFinancingTermsForCompanyReq> {
    constructor() {
        super("rd.api.company.ListFinancingTermsForCompanyReq", []);
    }
    create(value?: PartialMessage<ListFinancingTermsForCompanyReq>): ListFinancingTermsForCompanyReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListFinancingTermsForCompanyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListFinancingTermsForCompanyReq): ListFinancingTermsForCompanyReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListFinancingTermsForCompanyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListFinancingTermsForCompanyReq
 */
export const ListFinancingTermsForCompanyReq = new ListFinancingTermsForCompanyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListFinancingTermsForCompanyRes$Type extends MessageType<ListFinancingTermsForCompanyRes> {
    constructor() {
        super("rd.api.company.ListFinancingTermsForCompanyRes", [
            { no: 1, name: "terms", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FinancingTerms_Term },
            { no: 2, name: "names_by_id_string", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 3, name: "default_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListFinancingTermsForCompanyRes>): ListFinancingTermsForCompanyRes {
        const message = { terms: [], namesByIdString: {}, defaultIdString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListFinancingTermsForCompanyRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListFinancingTermsForCompanyRes): ListFinancingTermsForCompanyRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.company.FinancingTerms.Term terms */ 1:
                    message.terms.push(FinancingTerms_Term.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* map<string, string> names_by_id_string */ 2:
                    this.binaryReadMap2(message.namesByIdString, reader, options);
                    break;
                case /* string default_id_string */ 3:
                    message.defaultIdString = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: ListFinancingTermsForCompanyRes["namesByIdString"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ListFinancingTermsForCompanyRes["namesByIdString"] | undefined, val: ListFinancingTermsForCompanyRes["namesByIdString"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.company.ListFinancingTermsForCompanyRes.names_by_id_string");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: ListFinancingTermsForCompanyRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.company.FinancingTerms.Term terms = 1; */
        for (let i = 0; i < message.terms.length; i++)
            FinancingTerms_Term.internalBinaryWrite(message.terms[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* map<string, string> names_by_id_string = 2; */
        for (let k of Object.keys(message.namesByIdString))
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.namesByIdString[k]).join();
        /* string default_id_string = 3; */
        if (message.defaultIdString !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.defaultIdString);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListFinancingTermsForCompanyRes
 */
export const ListFinancingTermsForCompanyRes = new ListFinancingTermsForCompanyRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCustomersForTermReq$Type extends MessageType<ListCustomersForTermReq> {
    constructor() {
        super("rd.api.company.ListCustomersForTermReq", [
            { no: 1, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListCustomersForTermReq>): ListCustomersForTermReq {
        const message = { idString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListCustomersForTermReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCustomersForTermReq): ListCustomersForTermReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_string */ 1:
                    message.idString = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListCustomersForTermReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_string = 1; */
        if (message.idString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idString);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListCustomersForTermReq
 */
export const ListCustomersForTermReq = new ListCustomersForTermReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCustomersForTermRes$Type extends MessageType<ListCustomersForTermRes> {
    constructor() {
        super("rd.api.company.ListCustomersForTermRes", [
            { no: 1, name: "customers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Customer }
        ]);
    }
    create(value?: PartialMessage<ListCustomersForTermRes>): ListCustomersForTermRes {
        const message = { customers: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListCustomersForTermRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCustomersForTermRes): ListCustomersForTermRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.customer.Customer customers */ 1:
                    message.customers.push(Customer.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListCustomersForTermRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.customer.Customer customers = 1; */
        for (let i = 0; i < message.customers.length; i++)
            Customer.internalBinaryWrite(message.customers[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListCustomersForTermRes
 */
export const ListCustomersForTermRes = new ListCustomersForTermRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCardSurchargeRateReq$Type extends MessageType<UpdateCardSurchargeRateReq> {
    constructor() {
        super("rd.api.company.UpdateCardSurchargeRateReq", [
            { no: 1, name: "rate", kind: "message", T: () => Decimal }
        ]);
    }
    create(value?: PartialMessage<UpdateCardSurchargeRateReq>): UpdateCardSurchargeRateReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCardSurchargeRateReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCardSurchargeRateReq): UpdateCardSurchargeRateReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.decimal.Decimal rate */ 1:
                    message.rate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.rate);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCardSurchargeRateReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.decimal.Decimal rate = 1; */
        if (message.rate)
            Decimal.internalBinaryWrite(message.rate, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCardSurchargeRateReq
 */
export const UpdateCardSurchargeRateReq = new UpdateCardSurchargeRateReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCardSurchargeRateRes$Type extends MessageType<UpdateCardSurchargeRateRes> {
    constructor() {
        super("rd.api.company.UpdateCardSurchargeRateRes", []);
    }
    create(value?: PartialMessage<UpdateCardSurchargeRateRes>): UpdateCardSurchargeRateRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCardSurchargeRateRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCardSurchargeRateRes): UpdateCardSurchargeRateRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateCardSurchargeRateRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateCardSurchargeRateRes
 */
export const UpdateCardSurchargeRateRes = new UpdateCardSurchargeRateRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPermissionsGroupsReq$Type extends MessageType<GetPermissionsGroupsReq> {
    constructor() {
        super("rd.api.company.GetPermissionsGroupsReq", []);
    }
    create(value?: PartialMessage<GetPermissionsGroupsReq>): GetPermissionsGroupsReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPermissionsGroupsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPermissionsGroupsReq): GetPermissionsGroupsReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetPermissionsGroupsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.GetPermissionsGroupsReq
 */
export const GetPermissionsGroupsReq = new GetPermissionsGroupsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPermissionsGroupsRes$Type extends MessageType<GetPermissionsGroupsRes> {
    constructor() {
        super("rd.api.company.GetPermissionsGroupsRes", [
            { no: 1, name: "category_groups", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => GetPermissionsGroupsRes_CategoryGroups } }
        ]);
    }
    create(value?: PartialMessage<GetPermissionsGroupsRes>): GetPermissionsGroupsRes {
        const message = { categoryGroups: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPermissionsGroupsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPermissionsGroupsRes): GetPermissionsGroupsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, rd.api.company.GetPermissionsGroupsRes.CategoryGroups> category_groups */ 1:
                    this.binaryReadMap1(message.categoryGroups, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: GetPermissionsGroupsRes["categoryGroups"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetPermissionsGroupsRes["categoryGroups"] | undefined, val: GetPermissionsGroupsRes["categoryGroups"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = GetPermissionsGroupsRes_CategoryGroups.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.company.GetPermissionsGroupsRes.category_groups");
            }
        }
        map[key ?? ""] = val ?? GetPermissionsGroupsRes_CategoryGroups.create();
    }
    internalBinaryWrite(message: GetPermissionsGroupsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, rd.api.company.GetPermissionsGroupsRes.CategoryGroups> category_groups = 1; */
        for (let k of Object.keys(message.categoryGroups)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            GetPermissionsGroupsRes_CategoryGroups.internalBinaryWrite(message.categoryGroups[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.GetPermissionsGroupsRes
 */
export const GetPermissionsGroupsRes = new GetPermissionsGroupsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPermissionsGroupsRes_Group$Type extends MessageType<GetPermissionsGroupsRes_Group> {
    constructor() {
        super("rd.api.company.GetPermissionsGroupsRes.Group", [
            { no: 1, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "position", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetPermissionsGroupsRes_Group>): GetPermissionsGroupsRes_Group {
        const message = { idString: "", description: "", position: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPermissionsGroupsRes_Group>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPermissionsGroupsRes_Group): GetPermissionsGroupsRes_Group {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_string */ 1:
                    message.idString = reader.string();
                    break;
                case /* string description */ 2:
                    message.description = reader.string();
                    break;
                case /* int32 position */ 3:
                    message.position = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPermissionsGroupsRes_Group, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_string = 1; */
        if (message.idString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idString);
        /* string description = 2; */
        if (message.description !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.description);
        /* int32 position = 3; */
        if (message.position !== 0)
            writer.tag(3, WireType.Varint).int32(message.position);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.GetPermissionsGroupsRes.Group
 */
export const GetPermissionsGroupsRes_Group = new GetPermissionsGroupsRes_Group$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPermissionsGroupsRes_CategoryGroups$Type extends MessageType<GetPermissionsGroupsRes_CategoryGroups> {
    constructor() {
        super("rd.api.company.GetPermissionsGroupsRes.CategoryGroups", [
            { no: 1, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GetPermissionsGroupsRes_Group }
        ]);
    }
    create(value?: PartialMessage<GetPermissionsGroupsRes_CategoryGroups>): GetPermissionsGroupsRes_CategoryGroups {
        const message = { category: "", groups: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPermissionsGroupsRes_CategoryGroups>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPermissionsGroupsRes_CategoryGroups): GetPermissionsGroupsRes_CategoryGroups {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string category */ 1:
                    message.category = reader.string();
                    break;
                case /* repeated rd.api.company.GetPermissionsGroupsRes.Group groups */ 2:
                    message.groups.push(GetPermissionsGroupsRes_Group.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPermissionsGroupsRes_CategoryGroups, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string category = 1; */
        if (message.category !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.category);
        /* repeated rd.api.company.GetPermissionsGroupsRes.Group groups = 2; */
        for (let i = 0; i < message.groups.length; i++)
            GetPermissionsGroupsRes_Group.internalBinaryWrite(message.groups[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.GetPermissionsGroupsRes.CategoryGroups
 */
export const GetPermissionsGroupsRes_CategoryGroups = new GetPermissionsGroupsRes_CategoryGroups$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateFinanceChargeSettingReq$Type extends MessageType<CreateFinanceChargeSettingReq> {
    constructor() {
        super("rd.api.company.CreateFinanceChargeSettingReq", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 2, name: "percentage_fee", kind: "message", T: () => Decimal, options: { "rd.validator.require": true } },
            { no: 3, name: "min_assessment_balance", kind: "message", T: () => Money },
            { no: 4, name: "min_charge_fee", kind: "message", T: () => Money },
            { no: 5, name: "grace_period_days", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "is_compounded", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "is_default", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CreateFinanceChargeSettingReq>): CreateFinanceChargeSettingReq {
        const message = { name: "", gracePeriodDays: 0, isCompounded: false, isDefault: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateFinanceChargeSettingReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateFinanceChargeSettingReq): CreateFinanceChargeSettingReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* rd.proto.decimal.Decimal percentage_fee */ 2:
                    message.percentageFee = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.percentageFee);
                    break;
                case /* rd.proto.money.Money min_assessment_balance */ 3:
                    message.minAssessmentBalance = Money.internalBinaryRead(reader, reader.uint32(), options, message.minAssessmentBalance);
                    break;
                case /* rd.proto.money.Money min_charge_fee */ 4:
                    message.minChargeFee = Money.internalBinaryRead(reader, reader.uint32(), options, message.minChargeFee);
                    break;
                case /* int32 grace_period_days */ 5:
                    message.gracePeriodDays = reader.int32();
                    break;
                case /* bool is_compounded */ 6:
                    message.isCompounded = reader.bool();
                    break;
                case /* bool is_default */ 7:
                    message.isDefault = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateFinanceChargeSettingReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* rd.proto.decimal.Decimal percentage_fee = 2; */
        if (message.percentageFee)
            Decimal.internalBinaryWrite(message.percentageFee, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money min_assessment_balance = 3; */
        if (message.minAssessmentBalance)
            Money.internalBinaryWrite(message.minAssessmentBalance, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money min_charge_fee = 4; */
        if (message.minChargeFee)
            Money.internalBinaryWrite(message.minChargeFee, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int32 grace_period_days = 5; */
        if (message.gracePeriodDays !== 0)
            writer.tag(5, WireType.Varint).int32(message.gracePeriodDays);
        /* bool is_compounded = 6; */
        if (message.isCompounded !== false)
            writer.tag(6, WireType.Varint).bool(message.isCompounded);
        /* bool is_default = 7; */
        if (message.isDefault !== false)
            writer.tag(7, WireType.Varint).bool(message.isDefault);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.CreateFinanceChargeSettingReq
 */
export const CreateFinanceChargeSettingReq = new CreateFinanceChargeSettingReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateFinanceChargeSettingRes$Type extends MessageType<CreateFinanceChargeSettingRes> {
    constructor() {
        super("rd.api.company.CreateFinanceChargeSettingRes", [
            { no: 1, name: "id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<CreateFinanceChargeSettingRes>): CreateFinanceChargeSettingRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateFinanceChargeSettingRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateFinanceChargeSettingRes): CreateFinanceChargeSettingRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateFinanceChargeSettingRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.CreateFinanceChargeSettingRes
 */
export const CreateFinanceChargeSettingRes = new CreateFinanceChargeSettingRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateFinanceChargeSettingReq$Type extends MessageType<UpdateFinanceChargeSettingReq> {
    constructor() {
        super("rd.api.company.UpdateFinanceChargeSettingReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "percentage_fee", kind: "message", T: () => Decimal },
            { no: 4, name: "min_assessment_balance", kind: "message", T: () => Money },
            { no: 5, name: "min_charge_fee", kind: "message", T: () => Money },
            { no: 6, name: "grace_period_days", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "is_compounded", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "is_default", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "position", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateFinanceChargeSettingReq>): UpdateFinanceChargeSettingReq {
        const message = { name: "", gracePeriodDays: 0, isCompounded: false, isDefault: false, position: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateFinanceChargeSettingReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateFinanceChargeSettingReq): UpdateFinanceChargeSettingReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* rd.proto.decimal.Decimal percentage_fee */ 3:
                    message.percentageFee = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.percentageFee);
                    break;
                case /* rd.proto.money.Money min_assessment_balance */ 4:
                    message.minAssessmentBalance = Money.internalBinaryRead(reader, reader.uint32(), options, message.minAssessmentBalance);
                    break;
                case /* rd.proto.money.Money min_charge_fee */ 5:
                    message.minChargeFee = Money.internalBinaryRead(reader, reader.uint32(), options, message.minChargeFee);
                    break;
                case /* int32 grace_period_days */ 6:
                    message.gracePeriodDays = reader.int32();
                    break;
                case /* bool is_compounded */ 7:
                    message.isCompounded = reader.bool();
                    break;
                case /* bool is_default */ 8:
                    message.isDefault = reader.bool();
                    break;
                case /* int32 position */ 9:
                    message.position = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateFinanceChargeSettingReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* rd.proto.decimal.Decimal percentage_fee = 3; */
        if (message.percentageFee)
            Decimal.internalBinaryWrite(message.percentageFee, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money min_assessment_balance = 4; */
        if (message.minAssessmentBalance)
            Money.internalBinaryWrite(message.minAssessmentBalance, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money min_charge_fee = 5; */
        if (message.minChargeFee)
            Money.internalBinaryWrite(message.minChargeFee, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* int32 grace_period_days = 6; */
        if (message.gracePeriodDays !== 0)
            writer.tag(6, WireType.Varint).int32(message.gracePeriodDays);
        /* bool is_compounded = 7; */
        if (message.isCompounded !== false)
            writer.tag(7, WireType.Varint).bool(message.isCompounded);
        /* bool is_default = 8; */
        if (message.isDefault !== false)
            writer.tag(8, WireType.Varint).bool(message.isDefault);
        /* int32 position = 9; */
        if (message.position !== 0)
            writer.tag(9, WireType.Varint).int32(message.position);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateFinanceChargeSettingReq
 */
export const UpdateFinanceChargeSettingReq = new UpdateFinanceChargeSettingReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateFinanceChargeSettingRes$Type extends MessageType<UpdateFinanceChargeSettingRes> {
    constructor() {
        super("rd.api.company.UpdateFinanceChargeSettingRes", []);
    }
    create(value?: PartialMessage<UpdateFinanceChargeSettingRes>): UpdateFinanceChargeSettingRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateFinanceChargeSettingRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateFinanceChargeSettingRes): UpdateFinanceChargeSettingRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateFinanceChargeSettingRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateFinanceChargeSettingRes
 */
export const UpdateFinanceChargeSettingRes = new UpdateFinanceChargeSettingRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListActiveFinanceChargeSettingsForCompanyReq$Type extends MessageType<ListActiveFinanceChargeSettingsForCompanyReq> {
    constructor() {
        super("rd.api.company.ListActiveFinanceChargeSettingsForCompanyReq", []);
    }
    create(value?: PartialMessage<ListActiveFinanceChargeSettingsForCompanyReq>): ListActiveFinanceChargeSettingsForCompanyReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListActiveFinanceChargeSettingsForCompanyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListActiveFinanceChargeSettingsForCompanyReq): ListActiveFinanceChargeSettingsForCompanyReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListActiveFinanceChargeSettingsForCompanyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListActiveFinanceChargeSettingsForCompanyReq
 */
export const ListActiveFinanceChargeSettingsForCompanyReq = new ListActiveFinanceChargeSettingsForCompanyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListActiveFinanceChargeSettingsForCompanyRes$Type extends MessageType<ListActiveFinanceChargeSettingsForCompanyRes> {
    constructor() {
        super("rd.api.company.ListActiveFinanceChargeSettingsForCompanyRes", [
            { no: 1, name: "finance_charge_settings", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FinanceChargeSetting }
        ]);
    }
    create(value?: PartialMessage<ListActiveFinanceChargeSettingsForCompanyRes>): ListActiveFinanceChargeSettingsForCompanyRes {
        const message = { financeChargeSettings: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListActiveFinanceChargeSettingsForCompanyRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListActiveFinanceChargeSettingsForCompanyRes): ListActiveFinanceChargeSettingsForCompanyRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.company.FinanceChargeSetting finance_charge_settings */ 1:
                    message.financeChargeSettings.push(FinanceChargeSetting.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListActiveFinanceChargeSettingsForCompanyRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.company.FinanceChargeSetting finance_charge_settings = 1; */
        for (let i = 0; i < message.financeChargeSettings.length; i++)
            FinanceChargeSetting.internalBinaryWrite(message.financeChargeSettings[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListActiveFinanceChargeSettingsForCompanyRes
 */
export const ListActiveFinanceChargeSettingsForCompanyRes = new ListActiveFinanceChargeSettingsForCompanyRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeactivateFinanceChargeSettingReq$Type extends MessageType<DeactivateFinanceChargeSettingReq> {
    constructor() {
        super("rd.api.company.DeactivateFinanceChargeSettingReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<DeactivateFinanceChargeSettingReq>): DeactivateFinanceChargeSettingReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeactivateFinanceChargeSettingReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeactivateFinanceChargeSettingReq): DeactivateFinanceChargeSettingReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeactivateFinanceChargeSettingReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.DeactivateFinanceChargeSettingReq
 */
export const DeactivateFinanceChargeSettingReq = new DeactivateFinanceChargeSettingReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeactivateFinanceChargeSettingRes$Type extends MessageType<DeactivateFinanceChargeSettingRes> {
    constructor() {
        super("rd.api.company.DeactivateFinanceChargeSettingRes", []);
    }
    create(value?: PartialMessage<DeactivateFinanceChargeSettingRes>): DeactivateFinanceChargeSettingRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeactivateFinanceChargeSettingRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeactivateFinanceChargeSettingRes): DeactivateFinanceChargeSettingRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeactivateFinanceChargeSettingRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.DeactivateFinanceChargeSettingRes
 */
export const DeactivateFinanceChargeSettingRes = new DeactivateFinanceChargeSettingRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReorderFinanceChargeSettingsReq$Type extends MessageType<ReorderFinanceChargeSettingsReq> {
    constructor() {
        super("rd.api.company.ReorderFinanceChargeSettingsReq", [
            { no: 1, name: "ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<ReorderFinanceChargeSettingsReq>): ReorderFinanceChargeSettingsReq {
        const message = { ids: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReorderFinanceChargeSettingsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReorderFinanceChargeSettingsReq): ReorderFinanceChargeSettingsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.proto.uuid.UUID ids */ 1:
                    message.ids.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReorderFinanceChargeSettingsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.proto.uuid.UUID ids = 1; */
        for (let i = 0; i < message.ids.length; i++)
            UUID.internalBinaryWrite(message.ids[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ReorderFinanceChargeSettingsReq
 */
export const ReorderFinanceChargeSettingsReq = new ReorderFinanceChargeSettingsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReorderFinanceChargeSettingsRes$Type extends MessageType<ReorderFinanceChargeSettingsRes> {
    constructor() {
        super("rd.api.company.ReorderFinanceChargeSettingsRes", []);
    }
    create(value?: PartialMessage<ReorderFinanceChargeSettingsRes>): ReorderFinanceChargeSettingsRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReorderFinanceChargeSettingsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReorderFinanceChargeSettingsRes): ReorderFinanceChargeSettingsRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ReorderFinanceChargeSettingsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ReorderFinanceChargeSettingsRes
 */
export const ReorderFinanceChargeSettingsRes = new ReorderFinanceChargeSettingsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCustomersForFinanceChargeSettingReq$Type extends MessageType<ListCustomersForFinanceChargeSettingReq> {
    constructor() {
        super("rd.api.company.ListCustomersForFinanceChargeSettingReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<ListCustomersForFinanceChargeSettingReq>): ListCustomersForFinanceChargeSettingReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListCustomersForFinanceChargeSettingReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCustomersForFinanceChargeSettingReq): ListCustomersForFinanceChargeSettingReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListCustomersForFinanceChargeSettingReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListCustomersForFinanceChargeSettingReq
 */
export const ListCustomersForFinanceChargeSettingReq = new ListCustomersForFinanceChargeSettingReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCustomersForFinanceChargeSettingRes$Type extends MessageType<ListCustomersForFinanceChargeSettingRes> {
    constructor() {
        super("rd.api.company.ListCustomersForFinanceChargeSettingRes", [
            { no: 1, name: "customers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Customer }
        ]);
    }
    create(value?: PartialMessage<ListCustomersForFinanceChargeSettingRes>): ListCustomersForFinanceChargeSettingRes {
        const message = { customers: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListCustomersForFinanceChargeSettingRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCustomersForFinanceChargeSettingRes): ListCustomersForFinanceChargeSettingRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.customer.Customer customers */ 1:
                    message.customers.push(Customer.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListCustomersForFinanceChargeSettingRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.customer.Customer customers = 1; */
        for (let i = 0; i < message.customers.length; i++)
            Customer.internalBinaryWrite(message.customers[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.ListCustomersForFinanceChargeSettingRes
 */
export const ListCustomersForFinanceChargeSettingRes = new ListCustomersForFinanceChargeSettingRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLocationGoogleReviewUrlReq$Type extends MessageType<UpdateLocationGoogleReviewUrlReq> {
    constructor() {
        super("rd.api.company.UpdateLocationGoogleReviewUrlReq", [
            { no: 1, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "google_review_url", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.is_url": true, "rd.validator.url_scheme": "https" } }
        ]);
    }
    create(value?: PartialMessage<UpdateLocationGoogleReviewUrlReq>): UpdateLocationGoogleReviewUrlReq {
        const message = { googleReviewUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateLocationGoogleReviewUrlReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateLocationGoogleReviewUrlReq): UpdateLocationGoogleReviewUrlReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID location_id */ 1:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* string google_review_url */ 2:
                    message.googleReviewUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateLocationGoogleReviewUrlReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID location_id = 1; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string google_review_url = 2; */
        if (message.googleReviewUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.googleReviewUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateLocationGoogleReviewUrlReq
 */
export const UpdateLocationGoogleReviewUrlReq = new UpdateLocationGoogleReviewUrlReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLocationGoogleReviewUrlRes$Type extends MessageType<UpdateLocationGoogleReviewUrlRes> {
    constructor() {
        super("rd.api.company.UpdateLocationGoogleReviewUrlRes", []);
    }
    create(value?: PartialMessage<UpdateLocationGoogleReviewUrlRes>): UpdateLocationGoogleReviewUrlRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateLocationGoogleReviewUrlRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateLocationGoogleReviewUrlRes): UpdateLocationGoogleReviewUrlRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateLocationGoogleReviewUrlRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateLocationGoogleReviewUrlRes
 */
export const UpdateLocationGoogleReviewUrlRes = new UpdateLocationGoogleReviewUrlRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLocationBMOutletNumberReq$Type extends MessageType<UpdateLocationBMOutletNumberReq> {
    constructor() {
        super("rd.api.company.UpdateLocationBMOutletNumberReq", [
            { no: 1, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "bm_outlet_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateLocationBMOutletNumberReq>): UpdateLocationBMOutletNumberReq {
        const message = { bmOutletNumber: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateLocationBMOutletNumberReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateLocationBMOutletNumberReq): UpdateLocationBMOutletNumberReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID location_id */ 1:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* string bm_outlet_number */ 2:
                    message.bmOutletNumber = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateLocationBMOutletNumberReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID location_id = 1; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string bm_outlet_number = 2; */
        if (message.bmOutletNumber !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.bmOutletNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateLocationBMOutletNumberReq
 */
export const UpdateLocationBMOutletNumberReq = new UpdateLocationBMOutletNumberReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLocationBMOutletNumberRes$Type extends MessageType<UpdateLocationBMOutletNumberRes> {
    constructor() {
        super("rd.api.company.UpdateLocationBMOutletNumberRes", []);
    }
    create(value?: PartialMessage<UpdateLocationBMOutletNumberRes>): UpdateLocationBMOutletNumberRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateLocationBMOutletNumberRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateLocationBMOutletNumberRes): UpdateLocationBMOutletNumberRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateLocationBMOutletNumberRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateLocationBMOutletNumberRes
 */
export const UpdateLocationBMOutletNumberRes = new UpdateLocationBMOutletNumberRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLocationCashFloatReq$Type extends MessageType<UpdateLocationCashFloatReq> {
    constructor() {
        super("rd.api.company.UpdateLocationCashFloatReq", [
            { no: 1, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "cash_float", kind: "message", T: () => Money, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<UpdateLocationCashFloatReq>): UpdateLocationCashFloatReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateLocationCashFloatReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateLocationCashFloatReq): UpdateLocationCashFloatReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID location_id */ 1:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* rd.proto.money.Money cash_float */ 2:
                    message.cashFloat = Money.internalBinaryRead(reader, reader.uint32(), options, message.cashFloat);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateLocationCashFloatReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID location_id = 1; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money cash_float = 2; */
        if (message.cashFloat)
            Money.internalBinaryWrite(message.cashFloat, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateLocationCashFloatReq
 */
export const UpdateLocationCashFloatReq = new UpdateLocationCashFloatReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLocationCashFloatRes$Type extends MessageType<UpdateLocationCashFloatRes> {
    constructor() {
        super("rd.api.company.UpdateLocationCashFloatRes", []);
    }
    create(value?: PartialMessage<UpdateLocationCashFloatRes>): UpdateLocationCashFloatRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateLocationCashFloatRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateLocationCashFloatRes): UpdateLocationCashFloatRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateLocationCashFloatRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateLocationCashFloatRes
 */
export const UpdateLocationCashFloatRes = new UpdateLocationCashFloatRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLocationLogoImageUrlReq$Type extends MessageType<UpdateLocationLogoImageUrlReq> {
    constructor() {
        super("rd.api.company.UpdateLocationLogoImageUrlReq", [
            { no: 1, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "logo_image_url", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true, "rd.validator.is_url": true, "rd.validator.url_scheme": "https" } }
        ]);
    }
    create(value?: PartialMessage<UpdateLocationLogoImageUrlReq>): UpdateLocationLogoImageUrlReq {
        const message = { logoImageUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateLocationLogoImageUrlReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateLocationLogoImageUrlReq): UpdateLocationLogoImageUrlReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID location_id */ 1:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* string logo_image_url */ 2:
                    message.logoImageUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateLocationLogoImageUrlReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID location_id = 1; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string logo_image_url = 2; */
        if (message.logoImageUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.logoImageUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateLocationLogoImageUrlReq
 */
export const UpdateLocationLogoImageUrlReq = new UpdateLocationLogoImageUrlReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLocationLogoImageUrlRes$Type extends MessageType<UpdateLocationLogoImageUrlRes> {
    constructor() {
        super("rd.api.company.UpdateLocationLogoImageUrlRes", []);
    }
    create(value?: PartialMessage<UpdateLocationLogoImageUrlRes>): UpdateLocationLogoImageUrlRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateLocationLogoImageUrlRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateLocationLogoImageUrlRes): UpdateLocationLogoImageUrlRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateLocationLogoImageUrlRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateLocationLogoImageUrlRes
 */
export const UpdateLocationLogoImageUrlRes = new UpdateLocationLogoImageUrlRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLocationQuickbooksClassReq$Type extends MessageType<UpdateLocationQuickbooksClassReq> {
    constructor() {
        super("rd.api.company.UpdateLocationQuickbooksClassReq", [
            { no: 1, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "quickbooks_class", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateLocationQuickbooksClassReq>): UpdateLocationQuickbooksClassReq {
        const message = { quickbooksClass: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateLocationQuickbooksClassReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateLocationQuickbooksClassReq): UpdateLocationQuickbooksClassReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID location_id */ 1:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* string quickbooks_class */ 2:
                    message.quickbooksClass = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateLocationQuickbooksClassReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID location_id = 1; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string quickbooks_class = 2; */
        if (message.quickbooksClass !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.quickbooksClass);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateLocationQuickbooksClassReq
 */
export const UpdateLocationQuickbooksClassReq = new UpdateLocationQuickbooksClassReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLocationQuickbooksClassRes$Type extends MessageType<UpdateLocationQuickbooksClassRes> {
    constructor() {
        super("rd.api.company.UpdateLocationQuickbooksClassRes", []);
    }
    create(value?: PartialMessage<UpdateLocationQuickbooksClassRes>): UpdateLocationQuickbooksClassRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateLocationQuickbooksClassRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateLocationQuickbooksClassRes): UpdateLocationQuickbooksClassRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateLocationQuickbooksClassRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateLocationQuickbooksClassRes
 */
export const UpdateLocationQuickbooksClassRes = new UpdateLocationQuickbooksClassRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLocationStatementMessageReq$Type extends MessageType<UpdateLocationStatementMessageReq> {
    constructor() {
        super("rd.api.company.UpdateLocationStatementMessageReq", [
            { no: 1, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "statement_message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateLocationStatementMessageReq>): UpdateLocationStatementMessageReq {
        const message = { statementMessage: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateLocationStatementMessageReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateLocationStatementMessageReq): UpdateLocationStatementMessageReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID location_id */ 1:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* string statement_message */ 2:
                    message.statementMessage = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateLocationStatementMessageReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID location_id = 1; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string statement_message = 2; */
        if (message.statementMessage !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.statementMessage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateLocationStatementMessageReq
 */
export const UpdateLocationStatementMessageReq = new UpdateLocationStatementMessageReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLocationStatementMessageRes$Type extends MessageType<UpdateLocationStatementMessageRes> {
    constructor() {
        super("rd.api.company.UpdateLocationStatementMessageRes", []);
    }
    create(value?: PartialMessage<UpdateLocationStatementMessageRes>): UpdateLocationStatementMessageRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateLocationStatementMessageRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateLocationStatementMessageRes): UpdateLocationStatementMessageRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateLocationStatementMessageRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.company.UpdateLocationStatementMessageRes
 */
export const UpdateLocationStatementMessageRes = new UpdateLocationStatementMessageRes$Type();
/**
 * @generated ServiceType for protobuf service rd.api.company.CompanyService
 */
export const CompanyService = new ServiceType("rd.api.company.CompanyService", [
    { name: "GetLocation", options: {}, I: GetLocationReq, O: GetLocationRes },
    { name: "CreateLocation", options: {}, I: CreateLocationReq, O: CreateLocationRes },
    { name: "UpdateLocation", options: {}, I: UpdateLocationReq, O: UpdateLocationRes },
    { name: "ListLocationsForCompany", options: {}, I: ListLocationsForCompanyReq, O: ListLocationsForCompanyRes },
    { name: "RegisterCardReaderForLocation", options: {}, I: RegisterCardReaderForLocationReq, O: RegisterCardReaderForLocationRes },
    { name: "ListCardReadersForLocation", options: {}, I: ListCardReadersForLocationReq, O: ListCardReadersForLocationRes },
    { name: "ClearCardReader", options: {}, I: ClearCardReaderReq, O: ClearCardReaderRes },
    { name: "UpdateLocationGoogleReviewUrl", options: {}, I: UpdateLocationGoogleReviewUrlReq, O: UpdateLocationGoogleReviewUrlRes },
    { name: "UpdateLocationBMOutletNumber", options: {}, I: UpdateLocationBMOutletNumberReq, O: UpdateLocationBMOutletNumberRes },
    { name: "UpdateLocationCashFloat", options: {}, I: UpdateLocationCashFloatReq, O: UpdateLocationCashFloatRes },
    { name: "UpdateLocationLogoImageUrl", options: {}, I: UpdateLocationLogoImageUrlReq, O: UpdateLocationLogoImageUrlRes },
    { name: "UpdateLocationQuickbooksClass", options: {}, I: UpdateLocationQuickbooksClassReq, O: UpdateLocationQuickbooksClassRes },
    { name: "UpdateLocationStatementMessage", options: {}, I: UpdateLocationStatementMessageReq, O: UpdateLocationStatementMessageRes },
    { name: "GetStaff", options: {}, I: GetStaffReq, O: GetStaffRes },
    { name: "CreateStaff", options: {}, I: CreateStaffReq, O: CreateStaffRes },
    { name: "UpdateStaff", options: {}, I: UpdateStaffReq, O: UpdateStaffRes },
    { name: "ListStaffsForCompany", options: {}, I: ListStaffsForCompanyReq, O: ListStaffsForCompanyRes },
    { name: "DeactivateStaff", options: {}, I: DeactivateStaffReq, O: DeactivateStaffRes },
    { name: "ReactivateStaff", options: {}, I: ReactivateStaffReq, O: ReactivateStaffRes },
    { name: "DeactivateStaffWebAuthnKeys", options: {}, I: DeactivateStaffWebAuthnKeysReq, O: DeactivateStaffWebAuthnKeysRes },
    { name: "ListAuthorizedEndpoints", options: {}, I: ListAuthorizedEndpointsReq, O: ListAuthorizedEndpointsRes },
    { name: "UpdateStaffPermissions", options: {}, I: UpdateStaffPermissionsReq, O: UpdateStaffPermissionsRes },
    { name: "GetCompanyForSubdomain", options: {}, I: GetCompanyForSubdomainReq, O: GetCompanyForSubdomainRes },
    { name: "GetCompany", options: {}, I: GetCompanyReq, O: GetCompanyRes },
    { name: "UpdateCompanyStatementSettings", options: {}, I: UpdateCompanyStatementSettingsReq, O: UpdateCompanyStatementSettingsRes },
    { name: "UpdateCompanyGeneralSettings", options: {}, I: UpdateCompanyGeneralSettingsReq, O: UpdateCompanyGeneralSettingsRes },
    { name: "UpdateCompanySecuritySettings", options: {}, I: UpdateCompanySecuritySettingsReq, O: UpdateCompanySecuritySettingsRes },
    { name: "UpdateCompanyGeneralCommunicationsSettings", options: {}, I: UpdateCompanyGeneralCommunicationsSettingsReq, O: UpdateCompanyGeneralCommunicationsSettingsRes },
    { name: "UpdateCompanyShouldAutoSubmitContractorRewards", options: {}, I: UpdateCompanyShouldAutoSubmitContractorRewardsReq, O: UpdateCompanyShouldAutoSubmitContractorRewardsRes },
    { name: "UpdateCompanyContractorRewardsSettings", options: {}, I: UpdateCompanyContractorRewardsSettingsReq, O: UpdateCompanyContractorRewardsSettingsRes },
    { name: "UpdateCompanyIsDiscountShownToCustomers", options: {}, I: UpdateCompanyIsDiscountShownToCustomersReq, O: UpdateCompanyIsDiscountShownToCustomersRes },
    { name: "UpdateCompanyIsPriceShownOnWillCall", options: {}, I: UpdateCompanyIsPriceShownOnWillCallReq, O: UpdateCompanyIsPriceShownOnWillCallRes },
    { name: "UpdateCompanyIsColorFormulaHidden", options: {}, I: UpdateCompanyIsColorFormulaHiddenReq, O: UpdateCompanyIsColorFormulaHiddenRes },
    { name: "UpdateCompanyIsFinancingTermsShownOnInvoice", options: {}, I: UpdateCompanyIsFinancingTermsShownOnInvoiceReq, O: UpdateCompanyIsFinancingTermsShownOnInvoiceRes },
    { name: "UpdateCompanyIsCardSurchargeTaxExempt", options: {}, I: UpdateCompanyIsCardSurchargeTaxExemptReq, O: UpdateCompanyIsCardSurchargeTaxExemptRes },
    { name: "UpdateCompanyIsLoyaltyProgramEnabled", options: {}, I: UpdateCompanyIsLoyaltyProgramEnabledReq, O: UpdateCompanyIsLoyaltyProgramEnabledRes },
    { name: "UpdateCompanyLoyaltyProgramPolicy", options: {}, I: UpdateCompanyLoyaltyProgramPolicyReq, O: UpdateCompanyLoyaltyProgramPolicyRes },
    { name: "UpdateCompanyShouldAutoSignOutAfterSaleSettings", options: {}, I: UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq, O: UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes },
    { name: "CreateCustomTaxRate", options: {}, I: CreateCustomTaxRateReq, O: CreateCustomTaxRateRes },
    { name: "UpdateCustomTaxRate", options: {}, I: UpdateCustomTaxRateReq, O: UpdateCustomTaxRateRes },
    { name: "ReorderCustomTaxRates", options: {}, I: ReorderCustomTaxRatesReq, O: ReorderCustomTaxRatesRes },
    { name: "DeactivateCustomTaxRate", options: {}, I: DeactivateCustomTaxRateReq, O: DeactivateCustomTaxRateRes },
    { name: "ListTaxRatesForCompany", options: {}, I: ListTaxRatesForCompanyReq, O: ListTaxRatesForCompanyRes },
    { name: "GetTaxRate", options: {}, I: GetTaxRateReq, O: GetTaxRateRes },
    { name: "GetStripeOnboardingURL", options: {}, I: GetStripeOnboardingURLReq, O: GetStripeOnboardingURLRes },
    { name: "GetStripeCustomOnboardingURL", options: {}, I: GetStripeOnboardingURLReq, O: GetStripeOnboardingURLRes },
    { name: "GetStripeLoginURL", options: {}, I: GetStripeLoginURLReq, O: GetStripeLoginURLRes },
    { name: "CreateAdditionalFee", options: {}, I: CreateAdditionalFeeReq, O: CreateAdditionalFeeRes },
    { name: "UpdateAdditionalFee", options: {}, I: UpdateAdditionalFeeReq, O: UpdateAdditionalFeeRes },
    { name: "DeleteAdditionalFee", options: {}, I: DeleteAdditionalFeeReq, O: DeleteAdditionalFeeRes },
    { name: "ReorderAdditionalFees", options: {}, I: ReorderAdditionalFeesReq, O: ReorderAdditionalFeesRes },
    { name: "ListAdditionalFeesForCompany", options: {}, I: ListAdditionalFeesForCompanyReq, O: ListAdditionalFeesForCompanyRes },
    { name: "ListProductsForFee", options: {}, I: ListProductsForFeeReq, O: ListProductsForFeeRes },
    { name: "CreateProductCategoryV2", options: {}, I: CreateProductCategoryV2Req, O: CreateProductCategoryV2Res },
    { name: "UpdateProductCategoryV2", options: {}, I: UpdateProductCategoryV2Req, O: UpdateProductCategoryV2Res },
    { name: "ListProductCategoriesForCompanyV2", options: {}, I: ListProductCategoriesForCompanyV2Req, O: ListProductCategoriesForCompanyV2Res },
    { name: "ListProductsForCategoryV2", options: {}, I: ListProductsForCategoryV2Req, O: ListProductsForCategoryV2Res },
    { name: "DeactivateProductCategoryV2", options: {}, I: DeactivateProductCategoryV2Req, O: DeactivateProductCategoryV2Res },
    { name: "CreateFinancingTerm", options: {}, I: CreateFinancingTermReq, O: CreateFinancingTermRes },
    { name: "UpdateFinancingTerm", options: {}, I: UpdateFinancingTermReq, O: UpdateFinancingTermRes },
    { name: "DeleteFinancingTerm", options: {}, I: DeleteFinancingTermReq, O: DeleteFinancingTermRes },
    { name: "ReorderFinancingTerms", options: {}, I: ReorderFinancingTermsReq, O: ReorderFinancingTermsRes },
    { name: "ListFinancingTermsForCompany", options: {}, I: ListFinancingTermsForCompanyReq, O: ListFinancingTermsForCompanyRes },
    { name: "ListCustomersForTerm", options: {}, I: ListCustomersForTermReq, O: ListCustomersForTermRes },
    { name: "CreatePricingTier", options: {}, I: CreatePricingTierReq, O: CreatePricingTierRes },
    { name: "UpdatePricingTier", options: {}, I: UpdatePricingTierReq, O: UpdatePricingTierRes },
    { name: "DeletePricingTier", options: {}, I: DeletePricingTierReq, O: DeletePricingTierRes },
    { name: "ReorderPricingTiers", options: {}, I: ReorderPricingTiersReq, O: ReorderPricingTiersRes },
    { name: "ListPricingTiersForCompany", options: {}, I: ListPricingTiersForCompanyReq, O: ListPricingTiersForCompanyRes },
    { name: "ListCustomersForTier", options: {}, I: ListCustomersForTierReq, O: ListCustomersForTierRes },
    { name: "GetCurrentUser", options: {}, I: GetCurrentUserReq, O: GetCurrentUserRes },
    { name: "UpdateCardSurchargeRate", options: {}, I: UpdateCardSurchargeRateReq, O: UpdateCardSurchargeRateRes },
    { name: "GetPermissionsGroups", options: {}, I: GetPermissionsGroupsReq, O: GetPermissionsGroupsRes },
    { name: "CreateFinanceChargeSetting", options: {}, I: CreateFinanceChargeSettingReq, O: CreateFinanceChargeSettingRes },
    { name: "UpdateFinanceChargeSetting", options: {}, I: UpdateFinanceChargeSettingReq, O: UpdateFinanceChargeSettingRes },
    { name: "ListActiveFinanceChargeSettingsForCompany", options: {}, I: ListActiveFinanceChargeSettingsForCompanyReq, O: ListActiveFinanceChargeSettingsForCompanyRes },
    { name: "ListCustomersForFinanceChargeSetting", options: {}, I: ListCustomersForFinanceChargeSettingReq, O: ListCustomersForFinanceChargeSettingRes },
    { name: "DeactivateFinanceChargeSetting", options: {}, I: DeactivateFinanceChargeSettingReq, O: DeactivateFinanceChargeSettingRes },
    { name: "ReorderFinanceChargeSettings", options: {}, I: ReorderFinanceChargeSettingsReq, O: ReorderFinanceChargeSettingsRes }
]);
