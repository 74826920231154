/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "api/sale/service.proto" (package "rd.api.sale", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Customer } from "../../customer/models_pb";
import { Sale_Return } from "../../txn/models_pb";
import { FractionalMoney } from "../../proto/money/models_pb";
import { TimestampRange } from "../../proto/timestamprange/models_pb";
import { LedgerEntry_Payment_Method } from "../../ledger/models_pb";
import { PaymentMetadata_PresentCard } from "../../txn/models_pb";
import { PaymentMetadata_SavedCard } from "../../txn/models_pb";
import { ManuallyEnteredCard } from "../../payments/models_pb";
import { Product as Product$2 } from "../product/models_pb";
import { Product as Product$ } from "../../catalog/models_pb";
import { PurchaseMethod } from "../../txn/models_pb";
import { Inventories } from "../../product/models_pb";
import { Contacts_Contact } from "../../customer/models_pb";
import { LedgerEntry } from "../../ledger/models_pb";
import { TintColor } from "../../tintcolor/models_pb";
import { Product } from "../../product/models_pb";
import { JobSite } from "../../customer/models_pb";
import { PurchaseOrder } from "../../customer/models_pb";
import { Location } from "../../company/models_pb";
import { Sale } from "../../txn/models_pb";
import { RuleInformation } from "../../price/models_pb";
import { TaxRate } from "../../company/models_pb";
import { Money } from "../../proto/money/models_pb";
import { SaleProduct } from "../../txn/models_pb";
import { Decimal } from "../../proto/decimal/models_pb";
import { PaymentMetadata } from "../../txn/models_pb";
import { Address } from "../../proto/address/models_pb";
import { Sale_Status } from "../../txn/models_pb";
import { Timestamp } from "../../google/protobuf/timestamp_pb";
import { UUID } from "../../proto/uuid/models_pb";
/**
 * @generated from protobuf message rd.api.sale.UpsertSaleV3Req
 */
export interface UpsertSaleV3Req {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 2;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_job_site_id = 3;
     */
    customerJobSiteId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_purchase_order_id = 4;
     */
    customerPurchaseOrderId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 5;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp quote_expires_at = 6;
     */
    quoteExpiresAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.txn.Sale.Status status = 7;
     */
    status: Sale_Status;
    /**
     * @generated from protobuf field: string notes = 8;
     */
    notes: string;
    /**
     * @generated from protobuf field: rd.proto.address.Address customer_job_site_address = 9;
     */
    customerJobSiteAddress?: Address;
    /**
     * @generated from protobuf field: string customer_purchase_order_name = 10;
     */
    customerPurchaseOrderName: string;
    /**
     * @generated from protobuf field: repeated string customer_contact_id_strings = 11;
     */
    customerContactIdStrings: string[];
    /**
     * @generated from protobuf field: repeated string additional_emails = 12;
     */
    additionalEmails: string[];
    /**
     * @generated from protobuf field: string customer_contact_id_string = 13;
     */
    customerContactIdString: string;
    /**
     * @generated from protobuf field: repeated rd.txn.PaymentMetadata payments = 14;
     */
    payments: PaymentMetadata[];
    /**
     * @generated from protobuf field: string stripe_reader_id = 15;
     */
    stripeReaderId: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID tax_rate_id = 16;
     */
    taxRateId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal card_surcharge_rate = 17;
     */
    cardSurchargeRate?: Decimal;
    /**
     * @generated from protobuf field: string idempotency_key = 18;
     */
    idempotencyKey: string;
    /**
     * @generated from protobuf field: repeated rd.txn.SaleProduct productsV2 = 19;
     */
    productsV2: SaleProduct[]; // TODO (SP REFACTOR): make this required
    /**
     * @generated from protobuf field: string internal_notes = 20;
     */
    internalNotes: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID printer_id = 21;
     */
    printerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.address.Address address = 22;
     */
    address?: Address;
}
/**
 * @generated from protobuf message rd.api.sale.UpsertSaleV3Res
 */
export interface UpsertSaleV3Res {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: bool email_error = 2;
     */
    emailError: boolean;
    /**
     * @generated from protobuf field: string signature_url = 3;
     */
    signatureUrl: string;
}
/**
 * @generated from protobuf message rd.api.sale.GetCartPricesReq
 */
export interface GetCartPricesReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 1;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: repeated rd.api.sale.GetCartPricesReq.CartProduct cart = 2;
     */
    cart: GetCartPricesReq_CartProduct[];
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 3;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 4;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: string stripe_reader_id = 5;
     */
    stripeReaderId: string;
}
/**
 * @generated from protobuf message rd.api.sale.GetCartPricesReq.CartProduct
 */
export interface GetCartPricesReq_CartProduct {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID product_id = 1;
     */
    productId?: UUID;
    /**
     * @generated from protobuf field: int32 quantity = 2;
     */
    quantity: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money product_edited_price = 3;
     */
    productEditedPrice?: Money;
    /**
     * @generated from protobuf field: int32 quantity_returned = 4;
     */
    quantityReturned: number;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 5;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.sale.GetCartPricesRes
 */
export interface GetCartPricesRes {
    /**
     * @generated from protobuf field: rd.proto.money.Money subtotal = 1;
     */
    subtotal?: Money;
    /**
     * @generated from protobuf field: map<string, rd.api.sale.GetCartPricesRes.AdditionalFeeTotal> additional_fees_by_fee_id = 2;
     */
    additionalFeesByFeeId: {
        [key: string]: GetCartPricesRes_AdditionalFeeTotal;
    };
    /**
     * @generated from protobuf field: rd.proto.money.Money sales_tax_total = 3;
     */
    salesTaxTotal?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 4;
     */
    total?: Money;
    /**
     * @generated from protobuf field: map<string, rd.api.sale.GetCartPricesRes.ProductPriceInformation> prices_by_product_id = 5;
     */
    pricesByProductId: {
        [key: string]: GetCartPricesRes_ProductPriceInformation;
    };
    /**
     * @generated from protobuf field: rd.proto.money.Money refunded_total = 6;
     */
    refundedTotal?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money adjusted_total = 7;
     */
    adjustedTotal?: Money;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal card_surcharge_rate = 8;
     */
    cardSurchargeRate?: Decimal;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge = 9;
     */
    cardSurcharge?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge_tax = 10;
     */
    cardSurchargeTax?: Money;
}
/**
 * @generated from protobuf message rd.api.sale.GetCartPricesRes.AdditionalFeeTotal
 */
export interface GetCartPricesRes_AdditionalFeeTotal {
    /**
     * @generated from protobuf field: string fee_id = 1;
     */
    feeId: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money fee = 3;
     */
    fee?: Money;
}
/**
 * @generated from protobuf message rd.api.sale.GetCartPricesRes.ProductPriceInformation
 */
export interface GetCartPricesRes_ProductPriceInformation {
    /**
     * @generated from protobuf field: string product_id_string = 1;
     */
    productIdString: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money unit_price = 2;
     */
    unitPrice?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money total_price = 3;
     */
    totalPrice?: Money;
}
/**
 * @generated from protobuf message rd.api.sale.SyncCartPricesReq
 */
export interface SyncCartPricesReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 1;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: repeated rd.api.sale.SyncCartPricesReq.CartProduct cart = 2;
     */
    cart: SyncCartPricesReq_CartProduct[];
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 3;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 4;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: string stripe_reader_id = 5;
     */
    stripeReaderId: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID tax_rate_id = 7;
     */
    taxRateId?: UUID;
}
/**
 * @generated from protobuf message rd.api.sale.SyncCartPricesReq.CartProduct
 */
export interface SyncCartPricesReq_CartProduct {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID product_id = 1;
     */
    productId?: UUID;
    /**
     * @generated from protobuf field: int32 quantity = 2;
     */
    quantity: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money product_edited_price = 3;
     */
    productEditedPrice?: Money;
    /**
     * @generated from protobuf field: int32 quantity_returned = 4;
     */
    quantityReturned: number;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 5;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.sale.SyncCartPricesRes
 */
export interface SyncCartPricesRes {
    /**
     * @generated from protobuf field: rd.proto.money.Money subtotal = 1;
     */
    subtotal?: Money;
    /**
     * @generated from protobuf field: map<string, rd.api.sale.SyncCartPricesRes.AdditionalFeeTotal> additional_fees_by_fee_id = 2;
     */
    additionalFeesByFeeId: {
        [key: string]: SyncCartPricesRes_AdditionalFeeTotal;
    };
    /**
     * @generated from protobuf field: rd.proto.money.Money sales_tax_total = 3;
     */
    salesTaxTotal?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 4;
     */
    total?: Money;
    /**
     * @generated from protobuf field: map<string, rd.api.sale.SyncCartPricesRes.ProductPriceInformation> prices_by_product_id = 5;
     */
    pricesByProductId: {
        [key: string]: SyncCartPricesRes_ProductPriceInformation;
    };
    /**
     * @generated from protobuf field: rd.proto.money.Money refunded_total = 6;
     */
    refundedTotal?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money adjusted_total = 7;
     */
    adjustedTotal?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money amount_to_refund = 8;
     */
    amountToRefund?: Money;
    /**
     * @generated from protobuf field: rd.company.TaxRate tax_rate = 9;
     */
    taxRate?: TaxRate;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal card_surcharge_rate = 10;
     */
    cardSurchargeRate?: Decimal;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge = 11;
     */
    cardSurcharge?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge_tax = 12;
     */
    cardSurchargeTax?: Money;
}
/**
 * @generated from protobuf message rd.api.sale.SyncCartPricesRes.AdditionalFeeTotal
 */
export interface SyncCartPricesRes_AdditionalFeeTotal {
    /**
     * @generated from protobuf field: string fee_id = 1;
     */
    feeId: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money fee = 3;
     */
    fee?: Money;
}
/**
 * @generated from protobuf message rd.api.sale.SyncCartPricesRes.ProductPriceInformation
 */
export interface SyncCartPricesRes_ProductPriceInformation {
    /**
     * @generated from protobuf field: string product_id_string = 1;
     */
    productIdString: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money unit_price = 2;
     */
    unitPrice?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money total_price = 3;
     */
    totalPrice?: Money;
    /**
     * @generated from protobuf field: rd.price.RuleInformation rule_information = 4;
     */
    ruleInformation?: RuleInformation;
}
/**
 * @generated from protobuf message rd.api.sale.GetSaleReq
 */
export interface GetSaleReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.sale.GetSaleRes
 */
export interface GetSaleRes {
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 1;
     */
    sale?: Sale;
    /**
     * @generated from protobuf field: rd.company.Location location = 2;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.customer.PurchaseOrder customer_purchase_order = 3;
     */
    customerPurchaseOrder?: PurchaseOrder;
    /**
     * @generated from protobuf field: rd.customer.JobSite customer_job_site = 4;
     */
    customerJobSite?: JobSite;
    /**
     * @generated from protobuf field: map<string, rd.product.Product> products_by_id = 5;
     */
    productsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: map<string, rd.tintcolor.TintColor> tint_colors_by_id = 6;
     */
    tintColorsById: {
        [key: string]: TintColor;
    };
    /**
     * @generated from protobuf field: repeated rd.ledger.LedgerEntry payment_ledger_entries = 7;
     */
    paymentLedgerEntries: LedgerEntry[];
    /**
     * @generated from protobuf field: rd.customer.Contacts.Contact customer_contact = 9;
     */
    customerContact?: Contacts_Contact;
    /**
     * @generated from protobuf field: map<string, rd.product.Inventories> inventories_by_product_id = 10;
     */
    inventoriesByProductId: {
        [key: string]: Inventories;
    };
    /**
     * @generated from protobuf field: map<string, rd.ledger.LedgerEntry> bill_refund_ledger_entry_by_payment_id = 11;
     */
    billRefundLedgerEntryByPaymentId: {
        [key: string]: LedgerEntry;
    };
    /**
     * @generated from protobuf field: repeated rd.txn.PurchaseMethod purchase_methods = 12;
     */
    purchaseMethods: PurchaseMethod[];
    /**
     * @generated from protobuf field: repeated rd.txn.SaleProduct sale_products = 13;
     */
    saleProducts: SaleProduct[];
    /**
     * @generated from protobuf field: map<string, rd.catalog.Product> catalog_products_by_id = 14;
     */
    catalogProductsById: {
        [key: string]: Product$;
    };
    /**
     * @generated from protobuf field: map<string, rd.api.product.Product> api_products_by_id = 15;
     */
    apiProductsById: {
        [key: string]: Product$2;
    };
}
/**
 * @generated from protobuf message rd.api.sale.GetSaleCountsByStatusReq
 */
export interface GetSaleCountsByStatusReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 1;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 2;
     */
    customerId?: UUID;
}
/**
 * @generated from protobuf message rd.api.sale.GetSaleCountsByStatusRes
 */
export interface GetSaleCountsByStatusRes {
    /**
     * @generated from protobuf field: map<int32, int32> sale_counts_by_status = 1;
     */
    saleCountsByStatus: {
        [key: number]: number;
    };
}
/**
 * @generated from protobuf message rd.api.sale.GetScannedSaleReq
 */
export interface GetScannedSaleReq {
    /**
     * @generated from protobuf field: string identifier = 1;
     */
    identifier: string;
}
/**
 * @generated from protobuf message rd.api.sale.GetScannedSaleRes
 */
export interface GetScannedSaleRes {
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 1;
     */
    sale?: Sale;
}
/**
 * @generated from protobuf message rd.api.sale.UpdateSoldSaleProductReq
 */
export interface UpdateSoldSaleProductReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 1;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: rd.api.sale.UpdateSoldSaleProductReq.Product product = 2;
     */
    product?: UpdateSoldSaleProductReq_Product;
}
/**
 * @generated from protobuf message rd.api.sale.UpdateSoldSaleProductReq.Product
 */
export interface UpdateSoldSaleProductReq_Product {
    /**
     * @generated from protobuf field: int32 position = 1;
     */
    position: number;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID tint_color_id = 2;
     */
    tintColorId?: UUID;
    /**
     * @generated from protobuf field: string custom_tint_color = 3;
     */
    customTintColor: string;
    /**
     * @generated from protobuf field: string notes = 4;
     */
    notes: string;
}
/**
 * @generated from protobuf message rd.api.sale.UpdateSoldSaleProductRes
 */
export interface UpdateSoldSaleProductRes {
}
/**
 * @generated from protobuf message rd.api.sale.UpdateSoldSaleDetailsReq
 */
export interface UpdateSoldSaleDetailsReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: string sale_notes = 2;
     */
    saleNotes: string;
    /**
     * @generated from protobuf field: string customer_contact_id_string = 4;
     */
    customerContactIdString: string;
    /**
     * @generated from protobuf field: string purchase_order_name = 5;
     */
    purchaseOrderName: string;
    /**
     * @generated from protobuf field: rd.proto.address.Address job_site_address = 6;
     */
    jobSiteAddress?: Address;
    /**
     * @generated from protobuf field: string internal_notes = 7;
     */
    internalNotes: string;
    /**
     * @generated from protobuf field: rd.proto.address.Address address = 8;
     */
    address?: Address;
}
/**
 * @generated from protobuf message rd.api.sale.UpdateSoldSaleDetailsRes
 */
export interface UpdateSoldSaleDetailsRes {
}
/**
 * @generated from protobuf message rd.api.sale.EmailSalePDFReq
 */
export interface EmailSalePDFReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: repeated string customer_contact_id_strings = 2;
     */
    customerContactIdStrings: string[];
    /**
     * @generated from protobuf field: repeated string additional_emails = 3;
     */
    additionalEmails: string[];
    /**
     * @generated from protobuf field: bool is_return = 4;
     */
    isReturn: boolean;
    /**
     * @generated from protobuf field: rd.api.sale.ShowPricesOption show_prices = 5;
     */
    showPrices: ShowPricesOption;
}
/**
 * @generated from protobuf message rd.api.sale.EmailSalePDFRes
 */
export interface EmailSalePDFRes {
}
/**
 * @generated from protobuf message rd.api.sale.GetSalePDFReq
 */
export interface GetSalePDFReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: rd.api.sale.ShowPricesOption show_prices = 2;
     */
    showPrices: ShowPricesOption;
}
/**
 * @generated from protobuf message rd.api.sale.GetSalePDFRes
 */
export interface GetSalePDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.api.sale.AuthorizeCardPaymentReq
 */
export interface AuthorizeCardPaymentReq {
    /**
     * @generated from protobuf field: rd.proto.money.Money amount = 2;
     */
    amount?: Money; // positive
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 3;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: bool should_save_card = 4;
     */
    shouldSaveCard: boolean;
    /**
     * @generated from protobuf field: rd.payments.ManuallyEnteredCard manually_entered_card = 5;
     */
    manuallyEnteredCard?: ManuallyEnteredCard;
    /**
     * @generated from protobuf field: rd.txn.PaymentMetadata.SavedCard saved_card = 6;
     */
    savedCard?: PaymentMetadata_SavedCard;
    /**
     * @generated from protobuf field: rd.txn.PaymentMetadata.PresentCard present_card = 7;
     */
    presentCard?: PaymentMetadata_PresentCard;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 8;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: string card_nickname = 9;
     */
    cardNickname: string;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.Payment.Method payment_method = 10;
     */
    paymentMethod: LedgerEntry_Payment_Method;
}
/**
 * @generated from protobuf message rd.api.sale.AuthorizeCardPaymentRes
 */
export interface AuthorizeCardPaymentRes {
    /**
     * @generated from protobuf field: string stripe_payment_intent_id = 1;
     */
    stripePaymentIntentId: string;
}
/**
 * @generated from protobuf message rd.api.sale.SetCardReaderDisplayReq
 */
export interface SetCardReaderDisplayReq {
    /**
     * @generated from protobuf field: string stripe_reader_id = 1;
     */
    stripeReaderId: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money sales_tax = 2;
     */
    salesTax?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money sale_total = 3;
     */
    saleTotal?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge = 4;
     */
    cardSurcharge?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge_tax = 5;
     */
    cardSurchargeTax?: Money;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal card_surcharge_rate = 6;
     */
    cardSurchargeRate?: Decimal;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal tax_rate = 7;
     */
    taxRate?: Decimal;
    /**
     * @generated from protobuf field: repeated rd.api.sale.SetCardReaderDisplayReq.CartProduct cart = 8;
     */
    cart: SetCardReaderDisplayReq_CartProduct[];
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 9;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 10;
     */
    saleId?: UUID;
}
/**
 * @generated from protobuf message rd.api.sale.SetCardReaderDisplayReq.CartProduct
 */
export interface SetCardReaderDisplayReq_CartProduct {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID product_id = 1;
     */
    productId?: UUID;
    /**
     * @generated from protobuf field: int32 quantity = 2;
     */
    quantity: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money product_edited_price = 3;
     */
    productEditedPrice?: Money;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 4;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.sale.SetCardReaderDisplayRes
 */
export interface SetCardReaderDisplayRes {
}
/**
 * @generated from protobuf message rd.api.sale.CanEditPriceDuringSaleReq
 */
export interface CanEditPriceDuringSaleReq {
}
/**
 * @generated from protobuf message rd.api.sale.CanEditPriceDuringSaleRes
 */
export interface CanEditPriceDuringSaleRes {
}
/**
 * @generated from protobuf message rd.api.sale.DownloadItemizedSalesCSVReq
 */
export interface DownloadItemizedSalesCSVReq {
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange timestamp_range = 1;
     */
    timestampRange?: TimestampRange;
}
/**
 * @generated from protobuf message rd.api.sale.DownloadItemizedSalesCSVRes
 */
export interface DownloadItemizedSalesCSVRes {
    /**
     * @generated from protobuf field: bytes csv_bytes = 1;
     */
    csvBytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.api.sale.UpdateSaleProductCostReq
 */
export interface UpdateSaleProductCostReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_product_id = 1;
     */
    saleProductId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney cost = 2;
     */
    cost?: FractionalMoney;
}
/**
 * @generated from protobuf message rd.api.sale.UpdateSaleProductCostRes
 */
export interface UpdateSaleProductCostRes {
}
/**
 * @generated from protobuf message rd.api.sale.DuplicateSaleReq
 */
export interface DuplicateSaleReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.sale.DuplicateSaleRes
 */
export interface DuplicateSaleRes {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.sale.UpdateUnpaidChargeAccountSaleDateReq
 */
export interface UpdateUnpaidChargeAccountSaleDateReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timestamp = 2;
     */
    timestamp?: Timestamp;
}
/**
 * @generated from protobuf message rd.api.sale.UpdateUnpaidChargeAccountSaleDateRes
 */
export interface UpdateUnpaidChargeAccountSaleDateRes {
}
// Returns

/**
 * @generated from protobuf message rd.api.sale.ReturnSaleReq
 */
export interface ReturnSaleReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 1;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: string notes = 2;
     */
    notes: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID returned_location_id = 3;
     */
    returnedLocationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.address.Address customer_job_site_address = 6;
     */
    customerJobSiteAddress?: Address;
    /**
     * @generated from protobuf field: string customer_purchase_order_name = 7;
     */
    customerPurchaseOrderName: string;
    /**
     * @generated from protobuf field: string customer_contact_id_string = 8;
     */
    customerContactIdString: string;
    /**
     * @generated from protobuf field: map<string, rd.api.sale.ReturnSaleReq.ReturnProduct> return_products_by_id = 9;
     */
    returnProductsById: {
        [key: string]: ReturnSaleReq_ReturnProduct;
    };
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID printer_id = 10;
     */
    printerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.address.Address address = 11;
     */
    address?: Address;
}
/**
 * @generated from protobuf message rd.api.sale.ReturnSaleReq.ReturnProduct
 */
export interface ReturnSaleReq_ReturnProduct {
    /**
     * @generated from protobuf field: int32 quantityToReturn = 1;
     */
    quantityToReturn: number;
}
/**
 * @generated from protobuf message rd.api.sale.ReturnSaleRes
 */
export interface ReturnSaleRes {
    /**
     * @generated from protobuf field: string return_identifier = 1;
     */
    returnIdentifier: string;
}
/**
 * @generated from protobuf message rd.api.sale.GetReturnReq
 */
export interface GetReturnReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 1;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: string return_identifier = 2;
     */
    returnIdentifier: string;
}
/**
 * @generated from protobuf message rd.api.sale.GetReturnRes
 */
export interface GetReturnRes {
    /**
     * @generated from protobuf field: rd.txn.Sale.Return return = 1;
     */
    return?: Sale_Return;
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 2;
     */
    sale?: Sale;
    /**
     * @generated from protobuf field: map<string, rd.product.Product> products_by_id = 3;
     */
    productsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: map<string, rd.catalog.Product> catalog_products_by_id = 4;
     */
    catalogProductsById: {
        [key: string]: Product$;
    };
    /**
     * @generated from protobuf field: map<string, rd.tintcolor.TintColor> tint_colors_by_id = 5;
     */
    tintColorsById: {
        [key: string]: TintColor;
    };
    /**
     * @generated from protobuf field: rd.company.Location location = 6;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.customer.PurchaseOrder customer_purchase_order = 7;
     */
    customerPurchaseOrder?: PurchaseOrder;
    /**
     * @generated from protobuf field: rd.customer.JobSite customer_job_site = 8;
     */
    customerJobSite?: JobSite;
    /**
     * @generated from protobuf field: rd.customer.Contacts.Contact customer_contact = 9;
     */
    customerContact?: Contacts_Contact;
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 10;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.company.TaxRate tax_rate = 11;
     */
    taxRate?: TaxRate;
    /**
     * @generated from protobuf field: string return_method = 12;
     */
    returnMethod: string;
}
/**
 * @generated from protobuf message rd.api.sale.GetReturnPDFReq
 */
export interface GetReturnPDFReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 1;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: string return_identifier = 2;
     */
    returnIdentifier: string;
}
/**
 * @generated from protobuf message rd.api.sale.GetReturnPDFRes
 */
export interface GetReturnPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.api.sale.UpdateReturnDetailsReq
 */
export interface UpdateReturnDetailsReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 1;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: string return_identifier = 2;
     */
    returnIdentifier: string;
    /**
     * @generated from protobuf field: string notes = 3;
     */
    notes: string;
    /**
     * @generated from protobuf field: string customer_contact_id_string = 4;
     */
    customerContactIdString: string;
    /**
     * @generated from protobuf field: string purchase_order = 5;
     */
    purchaseOrder: string;
    /**
     * @generated from protobuf field: rd.proto.address.Address job_site_address = 6;
     */
    jobSiteAddress?: Address;
    /**
     * @generated from protobuf field: rd.proto.address.Address address = 7;
     */
    address?: Address;
}
/**
 * @generated from protobuf message rd.api.sale.UpdateReturnDetailsRes
 */
export interface UpdateReturnDetailsRes {
}
/**
 * @generated from protobuf message rd.api.sale.EmailReturnPDFReq
 */
export interface EmailReturnPDFReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 1;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: string return_identifier = 2;
     */
    returnIdentifier: string;
    /**
     * @generated from protobuf field: repeated string customer_contact_id_strings = 3;
     */
    customerContactIdStrings: string[];
    /**
     * @generated from protobuf field: repeated string additional_emails = 4;
     */
    additionalEmails: string[];
}
/**
 * @generated from protobuf message rd.api.sale.EmailReturnPDFRes
 */
export interface EmailReturnPDFRes {
}
/**
 * @generated from protobuf message rd.api.sale.UpdateSaleProductReturnedReq
 */
export interface UpdateSaleProductReturnedReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 1;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: string return_identifier = 2;
     */
    returnIdentifier: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_product_id = 3;
     */
    saleProductId?: UUID;
    /**
     * @generated from protobuf field: rd.api.sale.UpdateSaleProductReturnedReq.Product product = 4;
     */
    product?: UpdateSaleProductReturnedReq_Product;
}
/**
 * @generated from protobuf message rd.api.sale.UpdateSaleProductReturnedReq.Product
 */
export interface UpdateSaleProductReturnedReq_Product {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID tint_color_id = 2;
     */
    tintColorId?: UUID;
    /**
     * @generated from protobuf field: string custom_tint_color = 3;
     */
    customTintColor: string;
    /**
     * @generated from protobuf field: string notes = 4;
     */
    notes: string;
}
/**
 * @generated from protobuf message rd.api.sale.UpdateSaleProductReturnedRes
 */
export interface UpdateSaleProductReturnedRes {
}
/**
 * @generated from protobuf message rd.api.sale.GetScannedReturnReq
 */
export interface GetScannedReturnReq {
    /**
     * @generated from protobuf field: string identifier = 1;
     */
    identifier: string;
}
/**
 * @generated from protobuf message rd.api.sale.GetScannedReturnRes
 */
export interface GetScannedReturnRes {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 1;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: string return_identifier = 2;
     */
    returnIdentifier: string;
}
/**
 * @generated from protobuf message rd.api.sale.UpdateSaleProductReturnedCostReq
 */
export interface UpdateSaleProductReturnedCostReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 1;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: string return_identifier = 2;
     */
    returnIdentifier: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_product_id = 3;
     */
    saleProductId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney cost = 4;
     */
    cost?: FractionalMoney;
}
/**
 * @generated from protobuf message rd.api.sale.UpdateSaleProductReturnedCostRes
 */
export interface UpdateSaleProductReturnedCostRes {
}
/**
 * @generated from protobuf message rd.api.sale.CalculateReturnTotalsReq
 */
export interface CalculateReturnTotalsReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 1;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: map<string, int32> quantities_to_return_by_id = 2;
     */
    quantitiesToReturnById: {
        [key: string]: number;
    };
}
/**
 * @generated from protobuf message rd.api.sale.CalculateReturnTotalsRes
 */
export interface CalculateReturnTotalsRes {
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 1;
     */
    total?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money subtotal = 2;
     */
    subtotal?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money sales_tax_total = 3;
     */
    salesTaxTotal?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge = 4;
     */
    cardSurcharge?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge_tax = 5;
     */
    cardSurchargeTax?: Money;
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> additional_fees_by_name = 6;
     */
    additionalFeesByName: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: rd.company.TaxRate tax_rate = 7;
     */
    taxRate?: TaxRate;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal card_surcharge_rate = 8;
     */
    cardSurchargeRate?: Decimal;
    /**
     * @generated from protobuf field: rd.proto.money.Money early_payment_discount_reversal = 9;
     */
    earlyPaymentDiscountReversal?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money final_total = 10;
     */
    finalTotal?: Money;
}
/**
 * @generated from protobuf enum rd.api.sale.ShowPricesOption
 */
export enum ShowPricesOption {
    /**
     * @generated from protobuf enum value: SHOW_PRICES_OPTION_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: SHOW_PRICES_OPTION_SHOW = 1;
     */
    SHOW = 1,
    /**
     * @generated from protobuf enum value: SHOW_PRICES_OPTION_HIDE = 2;
     */
    HIDE = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class UpsertSaleV3Req$Type extends MessageType<UpsertSaleV3Req> {
    constructor() {
        super("rd.api.sale.UpsertSaleV3Req", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "customer_id", kind: "message", T: () => UUID },
            { no: 3, name: "customer_job_site_id", kind: "message", T: () => UUID },
            { no: 4, name: "customer_purchase_order_id", kind: "message", T: () => UUID },
            { no: 5, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 6, name: "quote_expires_at", kind: "message", T: () => Timestamp },
            { no: 7, name: "status", kind: "enum", T: () => ["rd.txn.Sale.Status", Sale_Status, "STATUS_"], options: { "rd.validator.require": true } },
            { no: 8, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "customer_job_site_address", kind: "message", T: () => Address },
            { no: 10, name: "customer_purchase_order_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "customer_contact_id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "additional_emails", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "customer_contact_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "payments", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PaymentMetadata },
            { no: 15, name: "stripe_reader_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "tax_rate_id", kind: "message", T: () => UUID },
            { no: 17, name: "card_surcharge_rate", kind: "message", T: () => Decimal },
            { no: 18, name: "idempotency_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "productsV2", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SaleProduct },
            { no: 20, name: "internal_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "printer_id", kind: "message", T: () => UUID },
            { no: 22, name: "address", kind: "message", T: () => Address }
        ]);
    }
    create(value?: PartialMessage<UpsertSaleV3Req>): UpsertSaleV3Req {
        const message = { status: 0, notes: "", customerPurchaseOrderName: "", customerContactIdStrings: [], additionalEmails: [], customerContactIdString: "", payments: [], stripeReaderId: "", idempotencyKey: "", productsV2: [], internalNotes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpsertSaleV3Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpsertSaleV3Req): UpsertSaleV3Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 2:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.proto.uuid.UUID customer_job_site_id */ 3:
                    message.customerJobSiteId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerJobSiteId);
                    break;
                case /* rd.proto.uuid.UUID customer_purchase_order_id */ 4:
                    message.customerPurchaseOrderId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerPurchaseOrderId);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 5:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* google.protobuf.Timestamp quote_expires_at */ 6:
                    message.quoteExpiresAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.quoteExpiresAt);
                    break;
                case /* rd.txn.Sale.Status status */ 7:
                    message.status = reader.int32();
                    break;
                case /* string notes */ 8:
                    message.notes = reader.string();
                    break;
                case /* rd.proto.address.Address customer_job_site_address */ 9:
                    message.customerJobSiteAddress = Address.internalBinaryRead(reader, reader.uint32(), options, message.customerJobSiteAddress);
                    break;
                case /* string customer_purchase_order_name */ 10:
                    message.customerPurchaseOrderName = reader.string();
                    break;
                case /* repeated string customer_contact_id_strings */ 11:
                    message.customerContactIdStrings.push(reader.string());
                    break;
                case /* repeated string additional_emails */ 12:
                    message.additionalEmails.push(reader.string());
                    break;
                case /* string customer_contact_id_string */ 13:
                    message.customerContactIdString = reader.string();
                    break;
                case /* repeated rd.txn.PaymentMetadata payments */ 14:
                    message.payments.push(PaymentMetadata.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string stripe_reader_id */ 15:
                    message.stripeReaderId = reader.string();
                    break;
                case /* rd.proto.uuid.UUID tax_rate_id */ 16:
                    message.taxRateId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.taxRateId);
                    break;
                case /* rd.proto.decimal.Decimal card_surcharge_rate */ 17:
                    message.cardSurchargeRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeRate);
                    break;
                case /* string idempotency_key */ 18:
                    message.idempotencyKey = reader.string();
                    break;
                case /* repeated rd.txn.SaleProduct productsV2 */ 19:
                    message.productsV2.push(SaleProduct.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string internal_notes */ 20:
                    message.internalNotes = reader.string();
                    break;
                case /* rd.proto.uuid.UUID printer_id */ 21:
                    message.printerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.printerId);
                    break;
                case /* rd.proto.address.Address address */ 22:
                    message.address = Address.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpsertSaleV3Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_id = 2; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_job_site_id = 3; */
        if (message.customerJobSiteId)
            UUID.internalBinaryWrite(message.customerJobSiteId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_purchase_order_id = 4; */
        if (message.customerPurchaseOrderId)
            UUID.internalBinaryWrite(message.customerPurchaseOrderId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 5; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp quote_expires_at = 6; */
        if (message.quoteExpiresAt)
            Timestamp.internalBinaryWrite(message.quoteExpiresAt, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.Sale.Status status = 7; */
        if (message.status !== 0)
            writer.tag(7, WireType.Varint).int32(message.status);
        /* string notes = 8; */
        if (message.notes !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.notes);
        /* rd.proto.address.Address customer_job_site_address = 9; */
        if (message.customerJobSiteAddress)
            Address.internalBinaryWrite(message.customerJobSiteAddress, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* string customer_purchase_order_name = 10; */
        if (message.customerPurchaseOrderName !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.customerPurchaseOrderName);
        /* repeated string customer_contact_id_strings = 11; */
        for (let i = 0; i < message.customerContactIdStrings.length; i++)
            writer.tag(11, WireType.LengthDelimited).string(message.customerContactIdStrings[i]);
        /* repeated string additional_emails = 12; */
        for (let i = 0; i < message.additionalEmails.length; i++)
            writer.tag(12, WireType.LengthDelimited).string(message.additionalEmails[i]);
        /* string customer_contact_id_string = 13; */
        if (message.customerContactIdString !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.customerContactIdString);
        /* repeated rd.txn.PaymentMetadata payments = 14; */
        for (let i = 0; i < message.payments.length; i++)
            PaymentMetadata.internalBinaryWrite(message.payments[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* string stripe_reader_id = 15; */
        if (message.stripeReaderId !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.stripeReaderId);
        /* rd.proto.uuid.UUID tax_rate_id = 16; */
        if (message.taxRateId)
            UUID.internalBinaryWrite(message.taxRateId, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal card_surcharge_rate = 17; */
        if (message.cardSurchargeRate)
            Decimal.internalBinaryWrite(message.cardSurchargeRate, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* string idempotency_key = 18; */
        if (message.idempotencyKey !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.idempotencyKey);
        /* repeated rd.txn.SaleProduct productsV2 = 19; */
        for (let i = 0; i < message.productsV2.length; i++)
            SaleProduct.internalBinaryWrite(message.productsV2[i], writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* string internal_notes = 20; */
        if (message.internalNotes !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.internalNotes);
        /* rd.proto.uuid.UUID printer_id = 21; */
        if (message.printerId)
            UUID.internalBinaryWrite(message.printerId, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.address.Address address = 22; */
        if (message.address)
            Address.internalBinaryWrite(message.address, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.UpsertSaleV3Req
 */
export const UpsertSaleV3Req = new UpsertSaleV3Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpsertSaleV3Res$Type extends MessageType<UpsertSaleV3Res> {
    constructor() {
        super("rd.api.sale.UpsertSaleV3Res", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "email_error", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "signature_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpsertSaleV3Res>): UpsertSaleV3Res {
        const message = { emailError: false, signatureUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpsertSaleV3Res>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpsertSaleV3Res): UpsertSaleV3Res {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* bool email_error */ 2:
                    message.emailError = reader.bool();
                    break;
                case /* string signature_url */ 3:
                    message.signatureUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpsertSaleV3Res, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool email_error = 2; */
        if (message.emailError !== false)
            writer.tag(2, WireType.Varint).bool(message.emailError);
        /* string signature_url = 3; */
        if (message.signatureUrl !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.signatureUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.UpsertSaleV3Res
 */
export const UpsertSaleV3Res = new UpsertSaleV3Res$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCartPricesReq$Type extends MessageType<GetCartPricesReq> {
    constructor() {
        super("rd.api.sale.GetCartPricesReq", [
            { no: 1, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "cart", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GetCartPricesReq_CartProduct },
            { no: 3, name: "customer_id", kind: "message", T: () => UUID },
            { no: 4, name: "sale_id", kind: "message", T: () => UUID },
            { no: 5, name: "stripe_reader_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCartPricesReq>): GetCartPricesReq {
        const message = { cart: [], stripeReaderId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCartPricesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCartPricesReq): GetCartPricesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID location_id */ 1:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* repeated rd.api.sale.GetCartPricesReq.CartProduct cart */ 2:
                    message.cart.push(GetCartPricesReq_CartProduct.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 3:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.proto.uuid.UUID sale_id */ 4:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* string stripe_reader_id */ 5:
                    message.stripeReaderId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCartPricesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID location_id = 1; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.api.sale.GetCartPricesReq.CartProduct cart = 2; */
        for (let i = 0; i < message.cart.length; i++)
            GetCartPricesReq_CartProduct.internalBinaryWrite(message.cart[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_id = 3; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID sale_id = 4; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string stripe_reader_id = 5; */
        if (message.stripeReaderId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.stripeReaderId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.GetCartPricesReq
 */
export const GetCartPricesReq = new GetCartPricesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCartPricesReq_CartProduct$Type extends MessageType<GetCartPricesReq_CartProduct> {
    constructor() {
        super("rd.api.sale.GetCartPricesReq.CartProduct", [
            { no: 1, name: "product_id", kind: "message", T: () => UUID },
            { no: 2, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "product_edited_price", kind: "message", T: () => Money },
            { no: 4, name: "quantity_returned", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<GetCartPricesReq_CartProduct>): GetCartPricesReq_CartProduct {
        const message = { quantity: 0, quantityReturned: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCartPricesReq_CartProduct>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCartPricesReq_CartProduct): GetCartPricesReq_CartProduct {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID product_id */ 1:
                    message.productId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.productId);
                    break;
                case /* int32 quantity */ 2:
                    message.quantity = reader.int32();
                    break;
                case /* rd.proto.money.Money product_edited_price */ 3:
                    message.productEditedPrice = Money.internalBinaryRead(reader, reader.uint32(), options, message.productEditedPrice);
                    break;
                case /* int32 quantity_returned */ 4:
                    message.quantityReturned = reader.int32();
                    break;
                case /* rd.proto.uuid.UUID id */ 5:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCartPricesReq_CartProduct, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID product_id = 1; */
        if (message.productId)
            UUID.internalBinaryWrite(message.productId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 quantity = 2; */
        if (message.quantity !== 0)
            writer.tag(2, WireType.Varint).int32(message.quantity);
        /* rd.proto.money.Money product_edited_price = 3; */
        if (message.productEditedPrice)
            Money.internalBinaryWrite(message.productEditedPrice, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int32 quantity_returned = 4; */
        if (message.quantityReturned !== 0)
            writer.tag(4, WireType.Varint).int32(message.quantityReturned);
        /* rd.proto.uuid.UUID id = 5; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.GetCartPricesReq.CartProduct
 */
export const GetCartPricesReq_CartProduct = new GetCartPricesReq_CartProduct$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCartPricesRes$Type extends MessageType<GetCartPricesRes> {
    constructor() {
        super("rd.api.sale.GetCartPricesRes", [
            { no: 1, name: "subtotal", kind: "message", T: () => Money },
            { no: 2, name: "additional_fees_by_fee_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => GetCartPricesRes_AdditionalFeeTotal } },
            { no: 3, name: "sales_tax_total", kind: "message", T: () => Money },
            { no: 4, name: "total", kind: "message", T: () => Money },
            { no: 5, name: "prices_by_product_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => GetCartPricesRes_ProductPriceInformation } },
            { no: 6, name: "refunded_total", kind: "message", T: () => Money },
            { no: 7, name: "adjusted_total", kind: "message", T: () => Money },
            { no: 8, name: "card_surcharge_rate", kind: "message", T: () => Decimal },
            { no: 9, name: "card_surcharge", kind: "message", T: () => Money },
            { no: 10, name: "card_surcharge_tax", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<GetCartPricesRes>): GetCartPricesRes {
        const message = { additionalFeesByFeeId: {}, pricesByProductId: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCartPricesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCartPricesRes): GetCartPricesRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money subtotal */ 1:
                    message.subtotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.subtotal);
                    break;
                case /* map<string, rd.api.sale.GetCartPricesRes.AdditionalFeeTotal> additional_fees_by_fee_id */ 2:
                    this.binaryReadMap2(message.additionalFeesByFeeId, reader, options);
                    break;
                case /* rd.proto.money.Money sales_tax_total */ 3:
                    message.salesTaxTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.salesTaxTotal);
                    break;
                case /* rd.proto.money.Money total */ 4:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* map<string, rd.api.sale.GetCartPricesRes.ProductPriceInformation> prices_by_product_id */ 5:
                    this.binaryReadMap5(message.pricesByProductId, reader, options);
                    break;
                case /* rd.proto.money.Money refunded_total */ 6:
                    message.refundedTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.refundedTotal);
                    break;
                case /* rd.proto.money.Money adjusted_total */ 7:
                    message.adjustedTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.adjustedTotal);
                    break;
                case /* rd.proto.decimal.Decimal card_surcharge_rate */ 8:
                    message.cardSurchargeRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeRate);
                    break;
                case /* rd.proto.money.Money card_surcharge */ 9:
                    message.cardSurcharge = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurcharge);
                    break;
                case /* rd.proto.money.Money card_surcharge_tax */ 10:
                    message.cardSurchargeTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeTax);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: GetCartPricesRes["additionalFeesByFeeId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetCartPricesRes["additionalFeesByFeeId"] | undefined, val: GetCartPricesRes["additionalFeesByFeeId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = GetCartPricesRes_AdditionalFeeTotal.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.sale.GetCartPricesRes.additional_fees_by_fee_id");
            }
        }
        map[key ?? ""] = val ?? GetCartPricesRes_AdditionalFeeTotal.create();
    }
    private binaryReadMap5(map: GetCartPricesRes["pricesByProductId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetCartPricesRes["pricesByProductId"] | undefined, val: GetCartPricesRes["pricesByProductId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = GetCartPricesRes_ProductPriceInformation.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.sale.GetCartPricesRes.prices_by_product_id");
            }
        }
        map[key ?? ""] = val ?? GetCartPricesRes_ProductPriceInformation.create();
    }
    internalBinaryWrite(message: GetCartPricesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money subtotal = 1; */
        if (message.subtotal)
            Money.internalBinaryWrite(message.subtotal, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.api.sale.GetCartPricesRes.AdditionalFeeTotal> additional_fees_by_fee_id = 2; */
        for (let k of Object.keys(message.additionalFeesByFeeId)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            GetCartPricesRes_AdditionalFeeTotal.internalBinaryWrite(message.additionalFeesByFeeId[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money sales_tax_total = 3; */
        if (message.salesTaxTotal)
            Money.internalBinaryWrite(message.salesTaxTotal, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total = 4; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.api.sale.GetCartPricesRes.ProductPriceInformation> prices_by_product_id = 5; */
        for (let k of Object.keys(message.pricesByProductId)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            GetCartPricesRes_ProductPriceInformation.internalBinaryWrite(message.pricesByProductId[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money refunded_total = 6; */
        if (message.refundedTotal)
            Money.internalBinaryWrite(message.refundedTotal, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money adjusted_total = 7; */
        if (message.adjustedTotal)
            Money.internalBinaryWrite(message.adjustedTotal, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal card_surcharge_rate = 8; */
        if (message.cardSurchargeRate)
            Decimal.internalBinaryWrite(message.cardSurchargeRate, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge = 9; */
        if (message.cardSurcharge)
            Money.internalBinaryWrite(message.cardSurcharge, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge_tax = 10; */
        if (message.cardSurchargeTax)
            Money.internalBinaryWrite(message.cardSurchargeTax, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.GetCartPricesRes
 */
export const GetCartPricesRes = new GetCartPricesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCartPricesRes_AdditionalFeeTotal$Type extends MessageType<GetCartPricesRes_AdditionalFeeTotal> {
    constructor() {
        super("rd.api.sale.GetCartPricesRes.AdditionalFeeTotal", [
            { no: 1, name: "fee_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "fee", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<GetCartPricesRes_AdditionalFeeTotal>): GetCartPricesRes_AdditionalFeeTotal {
        const message = { feeId: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCartPricesRes_AdditionalFeeTotal>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCartPricesRes_AdditionalFeeTotal): GetCartPricesRes_AdditionalFeeTotal {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string fee_id */ 1:
                    message.feeId = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* rd.proto.money.Money fee */ 3:
                    message.fee = Money.internalBinaryRead(reader, reader.uint32(), options, message.fee);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCartPricesRes_AdditionalFeeTotal, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string fee_id = 1; */
        if (message.feeId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.feeId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* rd.proto.money.Money fee = 3; */
        if (message.fee)
            Money.internalBinaryWrite(message.fee, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.GetCartPricesRes.AdditionalFeeTotal
 */
export const GetCartPricesRes_AdditionalFeeTotal = new GetCartPricesRes_AdditionalFeeTotal$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCartPricesRes_ProductPriceInformation$Type extends MessageType<GetCartPricesRes_ProductPriceInformation> {
    constructor() {
        super("rd.api.sale.GetCartPricesRes.ProductPriceInformation", [
            { no: 1, name: "product_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "unit_price", kind: "message", T: () => Money },
            { no: 3, name: "total_price", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<GetCartPricesRes_ProductPriceInformation>): GetCartPricesRes_ProductPriceInformation {
        const message = { productIdString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCartPricesRes_ProductPriceInformation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCartPricesRes_ProductPriceInformation): GetCartPricesRes_ProductPriceInformation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string product_id_string */ 1:
                    message.productIdString = reader.string();
                    break;
                case /* rd.proto.money.Money unit_price */ 2:
                    message.unitPrice = Money.internalBinaryRead(reader, reader.uint32(), options, message.unitPrice);
                    break;
                case /* rd.proto.money.Money total_price */ 3:
                    message.totalPrice = Money.internalBinaryRead(reader, reader.uint32(), options, message.totalPrice);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCartPricesRes_ProductPriceInformation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string product_id_string = 1; */
        if (message.productIdString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.productIdString);
        /* rd.proto.money.Money unit_price = 2; */
        if (message.unitPrice)
            Money.internalBinaryWrite(message.unitPrice, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total_price = 3; */
        if (message.totalPrice)
            Money.internalBinaryWrite(message.totalPrice, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.GetCartPricesRes.ProductPriceInformation
 */
export const GetCartPricesRes_ProductPriceInformation = new GetCartPricesRes_ProductPriceInformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncCartPricesReq$Type extends MessageType<SyncCartPricesReq> {
    constructor() {
        super("rd.api.sale.SyncCartPricesReq", [
            { no: 1, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "cart", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SyncCartPricesReq_CartProduct },
            { no: 3, name: "customer_id", kind: "message", T: () => UUID },
            { no: 4, name: "sale_id", kind: "message", T: () => UUID },
            { no: 5, name: "stripe_reader_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "tax_rate_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<SyncCartPricesReq>): SyncCartPricesReq {
        const message = { cart: [], stripeReaderId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SyncCartPricesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncCartPricesReq): SyncCartPricesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID location_id */ 1:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* repeated rd.api.sale.SyncCartPricesReq.CartProduct cart */ 2:
                    message.cart.push(SyncCartPricesReq_CartProduct.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 3:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.proto.uuid.UUID sale_id */ 4:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* string stripe_reader_id */ 5:
                    message.stripeReaderId = reader.string();
                    break;
                case /* rd.proto.uuid.UUID tax_rate_id */ 7:
                    message.taxRateId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.taxRateId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncCartPricesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID location_id = 1; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.api.sale.SyncCartPricesReq.CartProduct cart = 2; */
        for (let i = 0; i < message.cart.length; i++)
            SyncCartPricesReq_CartProduct.internalBinaryWrite(message.cart[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_id = 3; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID sale_id = 4; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string stripe_reader_id = 5; */
        if (message.stripeReaderId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.stripeReaderId);
        /* rd.proto.uuid.UUID tax_rate_id = 7; */
        if (message.taxRateId)
            UUID.internalBinaryWrite(message.taxRateId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.SyncCartPricesReq
 */
export const SyncCartPricesReq = new SyncCartPricesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncCartPricesReq_CartProduct$Type extends MessageType<SyncCartPricesReq_CartProduct> {
    constructor() {
        super("rd.api.sale.SyncCartPricesReq.CartProduct", [
            { no: 1, name: "product_id", kind: "message", T: () => UUID },
            { no: 2, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "product_edited_price", kind: "message", T: () => Money },
            { no: 4, name: "quantity_returned", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<SyncCartPricesReq_CartProduct>): SyncCartPricesReq_CartProduct {
        const message = { quantity: 0, quantityReturned: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SyncCartPricesReq_CartProduct>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncCartPricesReq_CartProduct): SyncCartPricesReq_CartProduct {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID product_id */ 1:
                    message.productId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.productId);
                    break;
                case /* int32 quantity */ 2:
                    message.quantity = reader.int32();
                    break;
                case /* rd.proto.money.Money product_edited_price */ 3:
                    message.productEditedPrice = Money.internalBinaryRead(reader, reader.uint32(), options, message.productEditedPrice);
                    break;
                case /* int32 quantity_returned */ 4:
                    message.quantityReturned = reader.int32();
                    break;
                case /* rd.proto.uuid.UUID id */ 5:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncCartPricesReq_CartProduct, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID product_id = 1; */
        if (message.productId)
            UUID.internalBinaryWrite(message.productId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 quantity = 2; */
        if (message.quantity !== 0)
            writer.tag(2, WireType.Varint).int32(message.quantity);
        /* rd.proto.money.Money product_edited_price = 3; */
        if (message.productEditedPrice)
            Money.internalBinaryWrite(message.productEditedPrice, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int32 quantity_returned = 4; */
        if (message.quantityReturned !== 0)
            writer.tag(4, WireType.Varint).int32(message.quantityReturned);
        /* rd.proto.uuid.UUID id = 5; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.SyncCartPricesReq.CartProduct
 */
export const SyncCartPricesReq_CartProduct = new SyncCartPricesReq_CartProduct$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncCartPricesRes$Type extends MessageType<SyncCartPricesRes> {
    constructor() {
        super("rd.api.sale.SyncCartPricesRes", [
            { no: 1, name: "subtotal", kind: "message", T: () => Money },
            { no: 2, name: "additional_fees_by_fee_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => SyncCartPricesRes_AdditionalFeeTotal } },
            { no: 3, name: "sales_tax_total", kind: "message", T: () => Money },
            { no: 4, name: "total", kind: "message", T: () => Money },
            { no: 5, name: "prices_by_product_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => SyncCartPricesRes_ProductPriceInformation } },
            { no: 6, name: "refunded_total", kind: "message", T: () => Money },
            { no: 7, name: "adjusted_total", kind: "message", T: () => Money },
            { no: 8, name: "amount_to_refund", kind: "message", T: () => Money },
            { no: 9, name: "tax_rate", kind: "message", T: () => TaxRate },
            { no: 10, name: "card_surcharge_rate", kind: "message", T: () => Decimal },
            { no: 11, name: "card_surcharge", kind: "message", T: () => Money },
            { no: 12, name: "card_surcharge_tax", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<SyncCartPricesRes>): SyncCartPricesRes {
        const message = { additionalFeesByFeeId: {}, pricesByProductId: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SyncCartPricesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncCartPricesRes): SyncCartPricesRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money subtotal */ 1:
                    message.subtotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.subtotal);
                    break;
                case /* map<string, rd.api.sale.SyncCartPricesRes.AdditionalFeeTotal> additional_fees_by_fee_id */ 2:
                    this.binaryReadMap2(message.additionalFeesByFeeId, reader, options);
                    break;
                case /* rd.proto.money.Money sales_tax_total */ 3:
                    message.salesTaxTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.salesTaxTotal);
                    break;
                case /* rd.proto.money.Money total */ 4:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* map<string, rd.api.sale.SyncCartPricesRes.ProductPriceInformation> prices_by_product_id */ 5:
                    this.binaryReadMap5(message.pricesByProductId, reader, options);
                    break;
                case /* rd.proto.money.Money refunded_total */ 6:
                    message.refundedTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.refundedTotal);
                    break;
                case /* rd.proto.money.Money adjusted_total */ 7:
                    message.adjustedTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.adjustedTotal);
                    break;
                case /* rd.proto.money.Money amount_to_refund */ 8:
                    message.amountToRefund = Money.internalBinaryRead(reader, reader.uint32(), options, message.amountToRefund);
                    break;
                case /* rd.company.TaxRate tax_rate */ 9:
                    message.taxRate = TaxRate.internalBinaryRead(reader, reader.uint32(), options, message.taxRate);
                    break;
                case /* rd.proto.decimal.Decimal card_surcharge_rate */ 10:
                    message.cardSurchargeRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeRate);
                    break;
                case /* rd.proto.money.Money card_surcharge */ 11:
                    message.cardSurcharge = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurcharge);
                    break;
                case /* rd.proto.money.Money card_surcharge_tax */ 12:
                    message.cardSurchargeTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeTax);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: SyncCartPricesRes["additionalFeesByFeeId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SyncCartPricesRes["additionalFeesByFeeId"] | undefined, val: SyncCartPricesRes["additionalFeesByFeeId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = SyncCartPricesRes_AdditionalFeeTotal.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.sale.SyncCartPricesRes.additional_fees_by_fee_id");
            }
        }
        map[key ?? ""] = val ?? SyncCartPricesRes_AdditionalFeeTotal.create();
    }
    private binaryReadMap5(map: SyncCartPricesRes["pricesByProductId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SyncCartPricesRes["pricesByProductId"] | undefined, val: SyncCartPricesRes["pricesByProductId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = SyncCartPricesRes_ProductPriceInformation.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.sale.SyncCartPricesRes.prices_by_product_id");
            }
        }
        map[key ?? ""] = val ?? SyncCartPricesRes_ProductPriceInformation.create();
    }
    internalBinaryWrite(message: SyncCartPricesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money subtotal = 1; */
        if (message.subtotal)
            Money.internalBinaryWrite(message.subtotal, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.api.sale.SyncCartPricesRes.AdditionalFeeTotal> additional_fees_by_fee_id = 2; */
        for (let k of Object.keys(message.additionalFeesByFeeId)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            SyncCartPricesRes_AdditionalFeeTotal.internalBinaryWrite(message.additionalFeesByFeeId[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money sales_tax_total = 3; */
        if (message.salesTaxTotal)
            Money.internalBinaryWrite(message.salesTaxTotal, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total = 4; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.api.sale.SyncCartPricesRes.ProductPriceInformation> prices_by_product_id = 5; */
        for (let k of Object.keys(message.pricesByProductId)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            SyncCartPricesRes_ProductPriceInformation.internalBinaryWrite(message.pricesByProductId[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money refunded_total = 6; */
        if (message.refundedTotal)
            Money.internalBinaryWrite(message.refundedTotal, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money adjusted_total = 7; */
        if (message.adjustedTotal)
            Money.internalBinaryWrite(message.adjustedTotal, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money amount_to_refund = 8; */
        if (message.amountToRefund)
            Money.internalBinaryWrite(message.amountToRefund, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.TaxRate tax_rate = 9; */
        if (message.taxRate)
            TaxRate.internalBinaryWrite(message.taxRate, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal card_surcharge_rate = 10; */
        if (message.cardSurchargeRate)
            Decimal.internalBinaryWrite(message.cardSurchargeRate, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge = 11; */
        if (message.cardSurcharge)
            Money.internalBinaryWrite(message.cardSurcharge, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge_tax = 12; */
        if (message.cardSurchargeTax)
            Money.internalBinaryWrite(message.cardSurchargeTax, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.SyncCartPricesRes
 */
export const SyncCartPricesRes = new SyncCartPricesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncCartPricesRes_AdditionalFeeTotal$Type extends MessageType<SyncCartPricesRes_AdditionalFeeTotal> {
    constructor() {
        super("rd.api.sale.SyncCartPricesRes.AdditionalFeeTotal", [
            { no: 1, name: "fee_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "fee", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<SyncCartPricesRes_AdditionalFeeTotal>): SyncCartPricesRes_AdditionalFeeTotal {
        const message = { feeId: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SyncCartPricesRes_AdditionalFeeTotal>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncCartPricesRes_AdditionalFeeTotal): SyncCartPricesRes_AdditionalFeeTotal {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string fee_id */ 1:
                    message.feeId = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* rd.proto.money.Money fee */ 3:
                    message.fee = Money.internalBinaryRead(reader, reader.uint32(), options, message.fee);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncCartPricesRes_AdditionalFeeTotal, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string fee_id = 1; */
        if (message.feeId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.feeId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* rd.proto.money.Money fee = 3; */
        if (message.fee)
            Money.internalBinaryWrite(message.fee, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.SyncCartPricesRes.AdditionalFeeTotal
 */
export const SyncCartPricesRes_AdditionalFeeTotal = new SyncCartPricesRes_AdditionalFeeTotal$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncCartPricesRes_ProductPriceInformation$Type extends MessageType<SyncCartPricesRes_ProductPriceInformation> {
    constructor() {
        super("rd.api.sale.SyncCartPricesRes.ProductPriceInformation", [
            { no: 1, name: "product_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "unit_price", kind: "message", T: () => Money },
            { no: 3, name: "total_price", kind: "message", T: () => Money },
            { no: 4, name: "rule_information", kind: "message", T: () => RuleInformation }
        ]);
    }
    create(value?: PartialMessage<SyncCartPricesRes_ProductPriceInformation>): SyncCartPricesRes_ProductPriceInformation {
        const message = { productIdString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SyncCartPricesRes_ProductPriceInformation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncCartPricesRes_ProductPriceInformation): SyncCartPricesRes_ProductPriceInformation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string product_id_string */ 1:
                    message.productIdString = reader.string();
                    break;
                case /* rd.proto.money.Money unit_price */ 2:
                    message.unitPrice = Money.internalBinaryRead(reader, reader.uint32(), options, message.unitPrice);
                    break;
                case /* rd.proto.money.Money total_price */ 3:
                    message.totalPrice = Money.internalBinaryRead(reader, reader.uint32(), options, message.totalPrice);
                    break;
                case /* rd.price.RuleInformation rule_information */ 4:
                    message.ruleInformation = RuleInformation.internalBinaryRead(reader, reader.uint32(), options, message.ruleInformation);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncCartPricesRes_ProductPriceInformation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string product_id_string = 1; */
        if (message.productIdString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.productIdString);
        /* rd.proto.money.Money unit_price = 2; */
        if (message.unitPrice)
            Money.internalBinaryWrite(message.unitPrice, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total_price = 3; */
        if (message.totalPrice)
            Money.internalBinaryWrite(message.totalPrice, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.price.RuleInformation rule_information = 4; */
        if (message.ruleInformation)
            RuleInformation.internalBinaryWrite(message.ruleInformation, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.SyncCartPricesRes.ProductPriceInformation
 */
export const SyncCartPricesRes_ProductPriceInformation = new SyncCartPricesRes_ProductPriceInformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSaleReq$Type extends MessageType<GetSaleReq> {
    constructor() {
        super("rd.api.sale.GetSaleReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetSaleReq>): GetSaleReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSaleReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSaleReq): GetSaleReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSaleReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.GetSaleReq
 */
export const GetSaleReq = new GetSaleReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSaleRes$Type extends MessageType<GetSaleRes> {
    constructor() {
        super("rd.api.sale.GetSaleRes", [
            { no: 1, name: "sale", kind: "message", T: () => Sale },
            { no: 2, name: "location", kind: "message", T: () => Location },
            { no: 3, name: "customer_purchase_order", kind: "message", T: () => PurchaseOrder },
            { no: 4, name: "customer_job_site", kind: "message", T: () => JobSite },
            { no: 5, name: "products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 6, name: "tint_colors_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => TintColor } },
            { no: 7, name: "payment_ledger_entries", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LedgerEntry },
            { no: 9, name: "customer_contact", kind: "message", T: () => Contacts_Contact },
            { no: 10, name: "inventories_by_product_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Inventories } },
            { no: 11, name: "bill_refund_ledger_entry_by_payment_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => LedgerEntry } },
            { no: 12, name: "purchase_methods", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PurchaseMethod },
            { no: 13, name: "sale_products", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SaleProduct },
            { no: 14, name: "catalog_products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product$ } },
            { no: 15, name: "api_products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product$2 } }
        ]);
    }
    create(value?: PartialMessage<GetSaleRes>): GetSaleRes {
        const message = { productsById: {}, tintColorsById: {}, paymentLedgerEntries: [], inventoriesByProductId: {}, billRefundLedgerEntryByPaymentId: {}, purchaseMethods: [], saleProducts: [], catalogProductsById: {}, apiProductsById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSaleRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSaleRes): GetSaleRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.Sale sale */ 1:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* rd.company.Location location */ 2:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.customer.PurchaseOrder customer_purchase_order */ 3:
                    message.customerPurchaseOrder = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options, message.customerPurchaseOrder);
                    break;
                case /* rd.customer.JobSite customer_job_site */ 4:
                    message.customerJobSite = JobSite.internalBinaryRead(reader, reader.uint32(), options, message.customerJobSite);
                    break;
                case /* map<string, rd.product.Product> products_by_id */ 5:
                    this.binaryReadMap5(message.productsById, reader, options);
                    break;
                case /* map<string, rd.tintcolor.TintColor> tint_colors_by_id */ 6:
                    this.binaryReadMap6(message.tintColorsById, reader, options);
                    break;
                case /* repeated rd.ledger.LedgerEntry payment_ledger_entries */ 7:
                    message.paymentLedgerEntries.push(LedgerEntry.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.customer.Contacts.Contact customer_contact */ 9:
                    message.customerContact = Contacts_Contact.internalBinaryRead(reader, reader.uint32(), options, message.customerContact);
                    break;
                case /* map<string, rd.product.Inventories> inventories_by_product_id */ 10:
                    this.binaryReadMap10(message.inventoriesByProductId, reader, options);
                    break;
                case /* map<string, rd.ledger.LedgerEntry> bill_refund_ledger_entry_by_payment_id */ 11:
                    this.binaryReadMap11(message.billRefundLedgerEntryByPaymentId, reader, options);
                    break;
                case /* repeated rd.txn.PurchaseMethod purchase_methods */ 12:
                    message.purchaseMethods.push(PurchaseMethod.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.txn.SaleProduct sale_products */ 13:
                    message.saleProducts.push(SaleProduct.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* map<string, rd.catalog.Product> catalog_products_by_id */ 14:
                    this.binaryReadMap14(message.catalogProductsById, reader, options);
                    break;
                case /* map<string, rd.api.product.Product> api_products_by_id */ 15:
                    this.binaryReadMap15(message.apiProductsById, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap5(map: GetSaleRes["productsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetSaleRes["productsById"] | undefined, val: GetSaleRes["productsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.sale.GetSaleRes.products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    private binaryReadMap6(map: GetSaleRes["tintColorsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetSaleRes["tintColorsById"] | undefined, val: GetSaleRes["tintColorsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = TintColor.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.sale.GetSaleRes.tint_colors_by_id");
            }
        }
        map[key ?? ""] = val ?? TintColor.create();
    }
    private binaryReadMap10(map: GetSaleRes["inventoriesByProductId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetSaleRes["inventoriesByProductId"] | undefined, val: GetSaleRes["inventoriesByProductId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Inventories.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.sale.GetSaleRes.inventories_by_product_id");
            }
        }
        map[key ?? ""] = val ?? Inventories.create();
    }
    private binaryReadMap11(map: GetSaleRes["billRefundLedgerEntryByPaymentId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetSaleRes["billRefundLedgerEntryByPaymentId"] | undefined, val: GetSaleRes["billRefundLedgerEntryByPaymentId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = LedgerEntry.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.sale.GetSaleRes.bill_refund_ledger_entry_by_payment_id");
            }
        }
        map[key ?? ""] = val ?? LedgerEntry.create();
    }
    private binaryReadMap14(map: GetSaleRes["catalogProductsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetSaleRes["catalogProductsById"] | undefined, val: GetSaleRes["catalogProductsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product$.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.sale.GetSaleRes.catalog_products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product$.create();
    }
    private binaryReadMap15(map: GetSaleRes["apiProductsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetSaleRes["apiProductsById"] | undefined, val: GetSaleRes["apiProductsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product$2.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.sale.GetSaleRes.api_products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product$2.create();
    }
    internalBinaryWrite(message: GetSaleRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.Sale sale = 1; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 2; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.PurchaseOrder customer_purchase_order = 3; */
        if (message.customerPurchaseOrder)
            PurchaseOrder.internalBinaryWrite(message.customerPurchaseOrder, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.JobSite customer_job_site = 4; */
        if (message.customerJobSite)
            JobSite.internalBinaryWrite(message.customerJobSite, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.product.Product> products_by_id = 5; */
        for (let k of Object.keys(message.productsById)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.productsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.tintcolor.TintColor> tint_colors_by_id = 6; */
        for (let k of Object.keys(message.tintColorsById)) {
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            TintColor.internalBinaryWrite(message.tintColorsById[k], writer, options);
            writer.join().join();
        }
        /* repeated rd.ledger.LedgerEntry payment_ledger_entries = 7; */
        for (let i = 0; i < message.paymentLedgerEntries.length; i++)
            LedgerEntry.internalBinaryWrite(message.paymentLedgerEntries[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Contacts.Contact customer_contact = 9; */
        if (message.customerContact)
            Contacts_Contact.internalBinaryWrite(message.customerContact, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.product.Inventories> inventories_by_product_id = 10; */
        for (let k of Object.keys(message.inventoriesByProductId)) {
            writer.tag(10, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Inventories.internalBinaryWrite(message.inventoriesByProductId[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.ledger.LedgerEntry> bill_refund_ledger_entry_by_payment_id = 11; */
        for (let k of Object.keys(message.billRefundLedgerEntryByPaymentId)) {
            writer.tag(11, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            LedgerEntry.internalBinaryWrite(message.billRefundLedgerEntryByPaymentId[k], writer, options);
            writer.join().join();
        }
        /* repeated rd.txn.PurchaseMethod purchase_methods = 12; */
        for (let i = 0; i < message.purchaseMethods.length; i++)
            PurchaseMethod.internalBinaryWrite(message.purchaseMethods[i], writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.SaleProduct sale_products = 13; */
        for (let i = 0; i < message.saleProducts.length; i++)
            SaleProduct.internalBinaryWrite(message.saleProducts[i], writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.catalog.Product> catalog_products_by_id = 14; */
        for (let k of Object.keys(message.catalogProductsById)) {
            writer.tag(14, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product$.internalBinaryWrite(message.catalogProductsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.api.product.Product> api_products_by_id = 15; */
        for (let k of Object.keys(message.apiProductsById)) {
            writer.tag(15, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product$2.internalBinaryWrite(message.apiProductsById[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.GetSaleRes
 */
export const GetSaleRes = new GetSaleRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSaleCountsByStatusReq$Type extends MessageType<GetSaleCountsByStatusReq> {
    constructor() {
        super("rd.api.sale.GetSaleCountsByStatusReq", [
            { no: 1, name: "location_id", kind: "message", T: () => UUID },
            { no: 2, name: "customer_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<GetSaleCountsByStatusReq>): GetSaleCountsByStatusReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSaleCountsByStatusReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSaleCountsByStatusReq): GetSaleCountsByStatusReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID location_id */ 1:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 2:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSaleCountsByStatusReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID location_id = 1; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_id = 2; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.GetSaleCountsByStatusReq
 */
export const GetSaleCountsByStatusReq = new GetSaleCountsByStatusReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSaleCountsByStatusRes$Type extends MessageType<GetSaleCountsByStatusRes> {
    constructor() {
        super("rd.api.sale.GetSaleCountsByStatusRes", [
            { no: 1, name: "sale_counts_by_status", kind: "map", K: 5 /*ScalarType.INT32*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } }
        ]);
    }
    create(value?: PartialMessage<GetSaleCountsByStatusRes>): GetSaleCountsByStatusRes {
        const message = { saleCountsByStatus: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSaleCountsByStatusRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSaleCountsByStatusRes): GetSaleCountsByStatusRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<int32, int32> sale_counts_by_status */ 1:
                    this.binaryReadMap1(message.saleCountsByStatus, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: GetSaleCountsByStatusRes["saleCountsByStatus"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetSaleCountsByStatusRes["saleCountsByStatus"] | undefined, val: GetSaleCountsByStatusRes["saleCountsByStatus"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int32();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.sale.GetSaleCountsByStatusRes.sale_counts_by_status");
            }
        }
        map[key ?? 0] = val ?? 0;
    }
    internalBinaryWrite(message: GetSaleCountsByStatusRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<int32, int32> sale_counts_by_status = 1; */
        for (let k of Object.keys(message.saleCountsByStatus))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int32(parseInt(k)).tag(2, WireType.Varint).int32(message.saleCountsByStatus[k as any]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.GetSaleCountsByStatusRes
 */
export const GetSaleCountsByStatusRes = new GetSaleCountsByStatusRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetScannedSaleReq$Type extends MessageType<GetScannedSaleReq> {
    constructor() {
        super("rd.api.sale.GetScannedSaleReq", [
            { no: 1, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetScannedSaleReq>): GetScannedSaleReq {
        const message = { identifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetScannedSaleReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetScannedSaleReq): GetScannedSaleReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string identifier */ 1:
                    message.identifier = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetScannedSaleReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string identifier = 1; */
        if (message.identifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.identifier);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.GetScannedSaleReq
 */
export const GetScannedSaleReq = new GetScannedSaleReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetScannedSaleRes$Type extends MessageType<GetScannedSaleRes> {
    constructor() {
        super("rd.api.sale.GetScannedSaleRes", [
            { no: 1, name: "sale", kind: "message", T: () => Sale }
        ]);
    }
    create(value?: PartialMessage<GetScannedSaleRes>): GetScannedSaleRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetScannedSaleRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetScannedSaleRes): GetScannedSaleRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.Sale sale */ 1:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetScannedSaleRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.Sale sale = 1; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.GetScannedSaleRes
 */
export const GetScannedSaleRes = new GetScannedSaleRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSoldSaleProductReq$Type extends MessageType<UpdateSoldSaleProductReq> {
    constructor() {
        super("rd.api.sale.UpdateSoldSaleProductReq", [
            { no: 1, name: "sale_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "product", kind: "message", T: () => UpdateSoldSaleProductReq_Product, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<UpdateSoldSaleProductReq>): UpdateSoldSaleProductReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateSoldSaleProductReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateSoldSaleProductReq): UpdateSoldSaleProductReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID sale_id */ 1:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* rd.api.sale.UpdateSoldSaleProductReq.Product product */ 2:
                    message.product = UpdateSoldSaleProductReq_Product.internalBinaryRead(reader, reader.uint32(), options, message.product);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateSoldSaleProductReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID sale_id = 1; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.api.sale.UpdateSoldSaleProductReq.Product product = 2; */
        if (message.product)
            UpdateSoldSaleProductReq_Product.internalBinaryWrite(message.product, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.UpdateSoldSaleProductReq
 */
export const UpdateSoldSaleProductReq = new UpdateSoldSaleProductReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSoldSaleProductReq_Product$Type extends MessageType<UpdateSoldSaleProductReq_Product> {
    constructor() {
        super("rd.api.sale.UpdateSoldSaleProductReq.Product", [
            { no: 1, name: "position", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "rd.validator.require": true } },
            { no: 2, name: "tint_color_id", kind: "message", T: () => UUID },
            { no: 3, name: "custom_tint_color", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateSoldSaleProductReq_Product>): UpdateSoldSaleProductReq_Product {
        const message = { position: 0, customTintColor: "", notes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateSoldSaleProductReq_Product>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateSoldSaleProductReq_Product): UpdateSoldSaleProductReq_Product {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 position */ 1:
                    message.position = reader.int32();
                    break;
                case /* rd.proto.uuid.UUID tint_color_id */ 2:
                    message.tintColorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.tintColorId);
                    break;
                case /* string custom_tint_color */ 3:
                    message.customTintColor = reader.string();
                    break;
                case /* string notes */ 4:
                    message.notes = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateSoldSaleProductReq_Product, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 position = 1; */
        if (message.position !== 0)
            writer.tag(1, WireType.Varint).int32(message.position);
        /* rd.proto.uuid.UUID tint_color_id = 2; */
        if (message.tintColorId)
            UUID.internalBinaryWrite(message.tintColorId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string custom_tint_color = 3; */
        if (message.customTintColor !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customTintColor);
        /* string notes = 4; */
        if (message.notes !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.notes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.UpdateSoldSaleProductReq.Product
 */
export const UpdateSoldSaleProductReq_Product = new UpdateSoldSaleProductReq_Product$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSoldSaleProductRes$Type extends MessageType<UpdateSoldSaleProductRes> {
    constructor() {
        super("rd.api.sale.UpdateSoldSaleProductRes", []);
    }
    create(value?: PartialMessage<UpdateSoldSaleProductRes>): UpdateSoldSaleProductRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateSoldSaleProductRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateSoldSaleProductRes): UpdateSoldSaleProductRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateSoldSaleProductRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.UpdateSoldSaleProductRes
 */
export const UpdateSoldSaleProductRes = new UpdateSoldSaleProductRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSoldSaleDetailsReq$Type extends MessageType<UpdateSoldSaleDetailsReq> {
    constructor() {
        super("rd.api.sale.UpdateSoldSaleDetailsReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "sale_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "customer_contact_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "purchase_order_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "job_site_address", kind: "message", T: () => Address },
            { no: 7, name: "internal_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "address", kind: "message", T: () => Address }
        ]);
    }
    create(value?: PartialMessage<UpdateSoldSaleDetailsReq>): UpdateSoldSaleDetailsReq {
        const message = { saleNotes: "", customerContactIdString: "", purchaseOrderName: "", internalNotes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateSoldSaleDetailsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateSoldSaleDetailsReq): UpdateSoldSaleDetailsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* string sale_notes */ 2:
                    message.saleNotes = reader.string();
                    break;
                case /* string customer_contact_id_string */ 4:
                    message.customerContactIdString = reader.string();
                    break;
                case /* string purchase_order_name */ 5:
                    message.purchaseOrderName = reader.string();
                    break;
                case /* rd.proto.address.Address job_site_address */ 6:
                    message.jobSiteAddress = Address.internalBinaryRead(reader, reader.uint32(), options, message.jobSiteAddress);
                    break;
                case /* string internal_notes */ 7:
                    message.internalNotes = reader.string();
                    break;
                case /* rd.proto.address.Address address */ 8:
                    message.address = Address.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateSoldSaleDetailsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string sale_notes = 2; */
        if (message.saleNotes !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.saleNotes);
        /* string customer_contact_id_string = 4; */
        if (message.customerContactIdString !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.customerContactIdString);
        /* string purchase_order_name = 5; */
        if (message.purchaseOrderName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.purchaseOrderName);
        /* rd.proto.address.Address job_site_address = 6; */
        if (message.jobSiteAddress)
            Address.internalBinaryWrite(message.jobSiteAddress, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string internal_notes = 7; */
        if (message.internalNotes !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.internalNotes);
        /* rd.proto.address.Address address = 8; */
        if (message.address)
            Address.internalBinaryWrite(message.address, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.UpdateSoldSaleDetailsReq
 */
export const UpdateSoldSaleDetailsReq = new UpdateSoldSaleDetailsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSoldSaleDetailsRes$Type extends MessageType<UpdateSoldSaleDetailsRes> {
    constructor() {
        super("rd.api.sale.UpdateSoldSaleDetailsRes", []);
    }
    create(value?: PartialMessage<UpdateSoldSaleDetailsRes>): UpdateSoldSaleDetailsRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateSoldSaleDetailsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateSoldSaleDetailsRes): UpdateSoldSaleDetailsRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateSoldSaleDetailsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.UpdateSoldSaleDetailsRes
 */
export const UpdateSoldSaleDetailsRes = new UpdateSoldSaleDetailsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmailSalePDFReq$Type extends MessageType<EmailSalePDFReq> {
    constructor() {
        super("rd.api.sale.EmailSalePDFReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "customer_contact_id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "additional_emails", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "is_return", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "show_prices", kind: "enum", T: () => ["rd.api.sale.ShowPricesOption", ShowPricesOption, "SHOW_PRICES_OPTION_"] }
        ]);
    }
    create(value?: PartialMessage<EmailSalePDFReq>): EmailSalePDFReq {
        const message = { customerContactIdStrings: [], additionalEmails: [], isReturn: false, showPrices: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EmailSalePDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EmailSalePDFReq): EmailSalePDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* repeated string customer_contact_id_strings */ 2:
                    message.customerContactIdStrings.push(reader.string());
                    break;
                case /* repeated string additional_emails */ 3:
                    message.additionalEmails.push(reader.string());
                    break;
                case /* bool is_return */ 4:
                    message.isReturn = reader.bool();
                    break;
                case /* rd.api.sale.ShowPricesOption show_prices */ 5:
                    message.showPrices = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EmailSalePDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated string customer_contact_id_strings = 2; */
        for (let i = 0; i < message.customerContactIdStrings.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.customerContactIdStrings[i]);
        /* repeated string additional_emails = 3; */
        for (let i = 0; i < message.additionalEmails.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.additionalEmails[i]);
        /* bool is_return = 4; */
        if (message.isReturn !== false)
            writer.tag(4, WireType.Varint).bool(message.isReturn);
        /* rd.api.sale.ShowPricesOption show_prices = 5; */
        if (message.showPrices !== 0)
            writer.tag(5, WireType.Varint).int32(message.showPrices);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.EmailSalePDFReq
 */
export const EmailSalePDFReq = new EmailSalePDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmailSalePDFRes$Type extends MessageType<EmailSalePDFRes> {
    constructor() {
        super("rd.api.sale.EmailSalePDFRes", []);
    }
    create(value?: PartialMessage<EmailSalePDFRes>): EmailSalePDFRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EmailSalePDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EmailSalePDFRes): EmailSalePDFRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EmailSalePDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.EmailSalePDFRes
 */
export const EmailSalePDFRes = new EmailSalePDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalePDFReq$Type extends MessageType<GetSalePDFReq> {
    constructor() {
        super("rd.api.sale.GetSalePDFReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "show_prices", kind: "enum", T: () => ["rd.api.sale.ShowPricesOption", ShowPricesOption, "SHOW_PRICES_OPTION_"] }
        ]);
    }
    create(value?: PartialMessage<GetSalePDFReq>): GetSalePDFReq {
        const message = { showPrices: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalePDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalePDFReq): GetSalePDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* rd.api.sale.ShowPricesOption show_prices */ 2:
                    message.showPrices = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalePDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.api.sale.ShowPricesOption show_prices = 2; */
        if (message.showPrices !== 0)
            writer.tag(2, WireType.Varint).int32(message.showPrices);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.GetSalePDFReq
 */
export const GetSalePDFReq = new GetSalePDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalePDFRes$Type extends MessageType<GetSalePDFRes> {
    constructor() {
        super("rd.api.sale.GetSalePDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSalePDFRes>): GetSalePDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalePDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalePDFRes): GetSalePDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalePDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.GetSalePDFRes
 */
export const GetSalePDFRes = new GetSalePDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthorizeCardPaymentReq$Type extends MessageType<AuthorizeCardPaymentReq> {
    constructor() {
        super("rd.api.sale.AuthorizeCardPaymentReq", [
            { no: 2, name: "amount", kind: "message", T: () => Money, options: { "rd.validator.require": true } },
            { no: 3, name: "customer_id", kind: "message", T: () => UUID },
            { no: 4, name: "should_save_card", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "manually_entered_card", kind: "message", T: () => ManuallyEnteredCard },
            { no: 6, name: "saved_card", kind: "message", T: () => PaymentMetadata_SavedCard },
            { no: 7, name: "present_card", kind: "message", T: () => PaymentMetadata_PresentCard },
            { no: 8, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 9, name: "card_nickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "payment_method", kind: "enum", T: () => ["rd.ledger.LedgerEntry.Payment.Method", LedgerEntry_Payment_Method, "METHOD_"], options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<AuthorizeCardPaymentReq>): AuthorizeCardPaymentReq {
        const message = { shouldSaveCard: false, cardNickname: "", paymentMethod: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthorizeCardPaymentReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthorizeCardPaymentReq): AuthorizeCardPaymentReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money amount */ 2:
                    message.amount = Money.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 3:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* bool should_save_card */ 4:
                    message.shouldSaveCard = reader.bool();
                    break;
                case /* rd.payments.ManuallyEnteredCard manually_entered_card */ 5:
                    message.manuallyEnteredCard = ManuallyEnteredCard.internalBinaryRead(reader, reader.uint32(), options, message.manuallyEnteredCard);
                    break;
                case /* rd.txn.PaymentMetadata.SavedCard saved_card */ 6:
                    message.savedCard = PaymentMetadata_SavedCard.internalBinaryRead(reader, reader.uint32(), options, message.savedCard);
                    break;
                case /* rd.txn.PaymentMetadata.PresentCard present_card */ 7:
                    message.presentCard = PaymentMetadata_PresentCard.internalBinaryRead(reader, reader.uint32(), options, message.presentCard);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 8:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* string card_nickname */ 9:
                    message.cardNickname = reader.string();
                    break;
                case /* rd.ledger.LedgerEntry.Payment.Method payment_method */ 10:
                    message.paymentMethod = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthorizeCardPaymentReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money amount = 2; */
        if (message.amount)
            Money.internalBinaryWrite(message.amount, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_id = 3; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bool should_save_card = 4; */
        if (message.shouldSaveCard !== false)
            writer.tag(4, WireType.Varint).bool(message.shouldSaveCard);
        /* rd.payments.ManuallyEnteredCard manually_entered_card = 5; */
        if (message.manuallyEnteredCard)
            ManuallyEnteredCard.internalBinaryWrite(message.manuallyEnteredCard, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.PaymentMetadata.SavedCard saved_card = 6; */
        if (message.savedCard)
            PaymentMetadata_SavedCard.internalBinaryWrite(message.savedCard, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.PaymentMetadata.PresentCard present_card = 7; */
        if (message.presentCard)
            PaymentMetadata_PresentCard.internalBinaryWrite(message.presentCard, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 8; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* string card_nickname = 9; */
        if (message.cardNickname !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.cardNickname);
        /* rd.ledger.LedgerEntry.Payment.Method payment_method = 10; */
        if (message.paymentMethod !== 0)
            writer.tag(10, WireType.Varint).int32(message.paymentMethod);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.AuthorizeCardPaymentReq
 */
export const AuthorizeCardPaymentReq = new AuthorizeCardPaymentReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthorizeCardPaymentRes$Type extends MessageType<AuthorizeCardPaymentRes> {
    constructor() {
        super("rd.api.sale.AuthorizeCardPaymentRes", [
            { no: 1, name: "stripe_payment_intent_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthorizeCardPaymentRes>): AuthorizeCardPaymentRes {
        const message = { stripePaymentIntentId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthorizeCardPaymentRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthorizeCardPaymentRes): AuthorizeCardPaymentRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_payment_intent_id */ 1:
                    message.stripePaymentIntentId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthorizeCardPaymentRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_payment_intent_id = 1; */
        if (message.stripePaymentIntentId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripePaymentIntentId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.AuthorizeCardPaymentRes
 */
export const AuthorizeCardPaymentRes = new AuthorizeCardPaymentRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetCardReaderDisplayReq$Type extends MessageType<SetCardReaderDisplayReq> {
    constructor() {
        super("rd.api.sale.SetCardReaderDisplayReq", [
            { no: 1, name: "stripe_reader_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 2, name: "sales_tax", kind: "message", T: () => Money },
            { no: 3, name: "sale_total", kind: "message", T: () => Money },
            { no: 4, name: "card_surcharge", kind: "message", T: () => Money },
            { no: 5, name: "card_surcharge_tax", kind: "message", T: () => Money },
            { no: 6, name: "card_surcharge_rate", kind: "message", T: () => Decimal },
            { no: 7, name: "tax_rate", kind: "message", T: () => Decimal },
            { no: 8, name: "cart", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SetCardReaderDisplayReq_CartProduct },
            { no: 9, name: "customer_id", kind: "message", T: () => UUID },
            { no: 10, name: "sale_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<SetCardReaderDisplayReq>): SetCardReaderDisplayReq {
        const message = { stripeReaderId: "", cart: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetCardReaderDisplayReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetCardReaderDisplayReq): SetCardReaderDisplayReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_reader_id */ 1:
                    message.stripeReaderId = reader.string();
                    break;
                case /* rd.proto.money.Money sales_tax */ 2:
                    message.salesTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.salesTax);
                    break;
                case /* rd.proto.money.Money sale_total */ 3:
                    message.saleTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.saleTotal);
                    break;
                case /* rd.proto.money.Money card_surcharge */ 4:
                    message.cardSurcharge = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurcharge);
                    break;
                case /* rd.proto.money.Money card_surcharge_tax */ 5:
                    message.cardSurchargeTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeTax);
                    break;
                case /* rd.proto.decimal.Decimal card_surcharge_rate */ 6:
                    message.cardSurchargeRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeRate);
                    break;
                case /* rd.proto.decimal.Decimal tax_rate */ 7:
                    message.taxRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.taxRate);
                    break;
                case /* repeated rd.api.sale.SetCardReaderDisplayReq.CartProduct cart */ 8:
                    message.cart.push(SetCardReaderDisplayReq_CartProduct.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 9:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.proto.uuid.UUID sale_id */ 10:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetCardReaderDisplayReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_reader_id = 1; */
        if (message.stripeReaderId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripeReaderId);
        /* rd.proto.money.Money sales_tax = 2; */
        if (message.salesTax)
            Money.internalBinaryWrite(message.salesTax, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money sale_total = 3; */
        if (message.saleTotal)
            Money.internalBinaryWrite(message.saleTotal, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge = 4; */
        if (message.cardSurcharge)
            Money.internalBinaryWrite(message.cardSurcharge, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge_tax = 5; */
        if (message.cardSurchargeTax)
            Money.internalBinaryWrite(message.cardSurchargeTax, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal card_surcharge_rate = 6; */
        if (message.cardSurchargeRate)
            Decimal.internalBinaryWrite(message.cardSurchargeRate, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal tax_rate = 7; */
        if (message.taxRate)
            Decimal.internalBinaryWrite(message.taxRate, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.api.sale.SetCardReaderDisplayReq.CartProduct cart = 8; */
        for (let i = 0; i < message.cart.length; i++)
            SetCardReaderDisplayReq_CartProduct.internalBinaryWrite(message.cart[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_id = 9; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID sale_id = 10; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.SetCardReaderDisplayReq
 */
export const SetCardReaderDisplayReq = new SetCardReaderDisplayReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetCardReaderDisplayReq_CartProduct$Type extends MessageType<SetCardReaderDisplayReq_CartProduct> {
    constructor() {
        super("rd.api.sale.SetCardReaderDisplayReq.CartProduct", [
            { no: 1, name: "product_id", kind: "message", T: () => UUID },
            { no: 2, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "product_edited_price", kind: "message", T: () => Money },
            { no: 4, name: "id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<SetCardReaderDisplayReq_CartProduct>): SetCardReaderDisplayReq_CartProduct {
        const message = { quantity: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetCardReaderDisplayReq_CartProduct>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetCardReaderDisplayReq_CartProduct): SetCardReaderDisplayReq_CartProduct {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID product_id */ 1:
                    message.productId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.productId);
                    break;
                case /* int32 quantity */ 2:
                    message.quantity = reader.int32();
                    break;
                case /* rd.proto.money.Money product_edited_price */ 3:
                    message.productEditedPrice = Money.internalBinaryRead(reader, reader.uint32(), options, message.productEditedPrice);
                    break;
                case /* rd.proto.uuid.UUID id */ 4:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetCardReaderDisplayReq_CartProduct, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID product_id = 1; */
        if (message.productId)
            UUID.internalBinaryWrite(message.productId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 quantity = 2; */
        if (message.quantity !== 0)
            writer.tag(2, WireType.Varint).int32(message.quantity);
        /* rd.proto.money.Money product_edited_price = 3; */
        if (message.productEditedPrice)
            Money.internalBinaryWrite(message.productEditedPrice, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID id = 4; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.SetCardReaderDisplayReq.CartProduct
 */
export const SetCardReaderDisplayReq_CartProduct = new SetCardReaderDisplayReq_CartProduct$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetCardReaderDisplayRes$Type extends MessageType<SetCardReaderDisplayRes> {
    constructor() {
        super("rd.api.sale.SetCardReaderDisplayRes", []);
    }
    create(value?: PartialMessage<SetCardReaderDisplayRes>): SetCardReaderDisplayRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetCardReaderDisplayRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetCardReaderDisplayRes): SetCardReaderDisplayRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SetCardReaderDisplayRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.SetCardReaderDisplayRes
 */
export const SetCardReaderDisplayRes = new SetCardReaderDisplayRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CanEditPriceDuringSaleReq$Type extends MessageType<CanEditPriceDuringSaleReq> {
    constructor() {
        super("rd.api.sale.CanEditPriceDuringSaleReq", []);
    }
    create(value?: PartialMessage<CanEditPriceDuringSaleReq>): CanEditPriceDuringSaleReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CanEditPriceDuringSaleReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CanEditPriceDuringSaleReq): CanEditPriceDuringSaleReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CanEditPriceDuringSaleReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.CanEditPriceDuringSaleReq
 */
export const CanEditPriceDuringSaleReq = new CanEditPriceDuringSaleReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CanEditPriceDuringSaleRes$Type extends MessageType<CanEditPriceDuringSaleRes> {
    constructor() {
        super("rd.api.sale.CanEditPriceDuringSaleRes", []);
    }
    create(value?: PartialMessage<CanEditPriceDuringSaleRes>): CanEditPriceDuringSaleRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CanEditPriceDuringSaleRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CanEditPriceDuringSaleRes): CanEditPriceDuringSaleRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CanEditPriceDuringSaleRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.CanEditPriceDuringSaleRes
 */
export const CanEditPriceDuringSaleRes = new CanEditPriceDuringSaleRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DownloadItemizedSalesCSVReq$Type extends MessageType<DownloadItemizedSalesCSVReq> {
    constructor() {
        super("rd.api.sale.DownloadItemizedSalesCSVReq", [
            { no: 1, name: "timestamp_range", kind: "message", T: () => TimestampRange, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<DownloadItemizedSalesCSVReq>): DownloadItemizedSalesCSVReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DownloadItemizedSalesCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DownloadItemizedSalesCSVReq): DownloadItemizedSalesCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.timestamprange.TimestampRange timestamp_range */ 1:
                    message.timestampRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.timestampRange);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DownloadItemizedSalesCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.timestamprange.TimestampRange timestamp_range = 1; */
        if (message.timestampRange)
            TimestampRange.internalBinaryWrite(message.timestampRange, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.DownloadItemizedSalesCSVReq
 */
export const DownloadItemizedSalesCSVReq = new DownloadItemizedSalesCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DownloadItemizedSalesCSVRes$Type extends MessageType<DownloadItemizedSalesCSVRes> {
    constructor() {
        super("rd.api.sale.DownloadItemizedSalesCSVRes", [
            { no: 1, name: "csv_bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<DownloadItemizedSalesCSVRes>): DownloadItemizedSalesCSVRes {
        const message = { csvBytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DownloadItemizedSalesCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DownloadItemizedSalesCSVRes): DownloadItemizedSalesCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes csv_bytes */ 1:
                    message.csvBytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DownloadItemizedSalesCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes csv_bytes = 1; */
        if (message.csvBytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.csvBytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.DownloadItemizedSalesCSVRes
 */
export const DownloadItemizedSalesCSVRes = new DownloadItemizedSalesCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSaleProductCostReq$Type extends MessageType<UpdateSaleProductCostReq> {
    constructor() {
        super("rd.api.sale.UpdateSaleProductCostReq", [
            { no: 1, name: "sale_product_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "cost", kind: "message", T: () => FractionalMoney, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<UpdateSaleProductCostReq>): UpdateSaleProductCostReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateSaleProductCostReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateSaleProductCostReq): UpdateSaleProductCostReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID sale_product_id */ 1:
                    message.saleProductId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleProductId);
                    break;
                case /* rd.proto.money.FractionalMoney cost */ 2:
                    message.cost = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.cost);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateSaleProductCostReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID sale_product_id = 1; */
        if (message.saleProductId)
            UUID.internalBinaryWrite(message.saleProductId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.FractionalMoney cost = 2; */
        if (message.cost)
            FractionalMoney.internalBinaryWrite(message.cost, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.UpdateSaleProductCostReq
 */
export const UpdateSaleProductCostReq = new UpdateSaleProductCostReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSaleProductCostRes$Type extends MessageType<UpdateSaleProductCostRes> {
    constructor() {
        super("rd.api.sale.UpdateSaleProductCostRes", []);
    }
    create(value?: PartialMessage<UpdateSaleProductCostRes>): UpdateSaleProductCostRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateSaleProductCostRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateSaleProductCostRes): UpdateSaleProductCostRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateSaleProductCostRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.UpdateSaleProductCostRes
 */
export const UpdateSaleProductCostRes = new UpdateSaleProductCostRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DuplicateSaleReq$Type extends MessageType<DuplicateSaleReq> {
    constructor() {
        super("rd.api.sale.DuplicateSaleReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<DuplicateSaleReq>): DuplicateSaleReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DuplicateSaleReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DuplicateSaleReq): DuplicateSaleReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DuplicateSaleReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.DuplicateSaleReq
 */
export const DuplicateSaleReq = new DuplicateSaleReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DuplicateSaleRes$Type extends MessageType<DuplicateSaleRes> {
    constructor() {
        super("rd.api.sale.DuplicateSaleRes", [
            { no: 1, name: "id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<DuplicateSaleRes>): DuplicateSaleRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DuplicateSaleRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DuplicateSaleRes): DuplicateSaleRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DuplicateSaleRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.DuplicateSaleRes
 */
export const DuplicateSaleRes = new DuplicateSaleRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUnpaidChargeAccountSaleDateReq$Type extends MessageType<UpdateUnpaidChargeAccountSaleDateReq> {
    constructor() {
        super("rd.api.sale.UpdateUnpaidChargeAccountSaleDateReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "timestamp", kind: "message", T: () => Timestamp, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<UpdateUnpaidChargeAccountSaleDateReq>): UpdateUnpaidChargeAccountSaleDateReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateUnpaidChargeAccountSaleDateReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUnpaidChargeAccountSaleDateReq): UpdateUnpaidChargeAccountSaleDateReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp timestamp */ 2:
                    message.timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timestamp);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateUnpaidChargeAccountSaleDateReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp timestamp = 2; */
        if (message.timestamp)
            Timestamp.internalBinaryWrite(message.timestamp, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.UpdateUnpaidChargeAccountSaleDateReq
 */
export const UpdateUnpaidChargeAccountSaleDateReq = new UpdateUnpaidChargeAccountSaleDateReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUnpaidChargeAccountSaleDateRes$Type extends MessageType<UpdateUnpaidChargeAccountSaleDateRes> {
    constructor() {
        super("rd.api.sale.UpdateUnpaidChargeAccountSaleDateRes", []);
    }
    create(value?: PartialMessage<UpdateUnpaidChargeAccountSaleDateRes>): UpdateUnpaidChargeAccountSaleDateRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateUnpaidChargeAccountSaleDateRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUnpaidChargeAccountSaleDateRes): UpdateUnpaidChargeAccountSaleDateRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateUnpaidChargeAccountSaleDateRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.UpdateUnpaidChargeAccountSaleDateRes
 */
export const UpdateUnpaidChargeAccountSaleDateRes = new UpdateUnpaidChargeAccountSaleDateRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReturnSaleReq$Type extends MessageType<ReturnSaleReq> {
    constructor() {
        super("rd.api.sale.ReturnSaleReq", [
            { no: 1, name: "sale_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 3, name: "returned_location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 6, name: "customer_job_site_address", kind: "message", T: () => Address },
            { no: 7, name: "customer_purchase_order_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "customer_contact_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "return_products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => ReturnSaleReq_ReturnProduct }, options: { "rd.validator.require": true } },
            { no: 10, name: "printer_id", kind: "message", T: () => UUID },
            { no: 11, name: "address", kind: "message", T: () => Address }
        ]);
    }
    create(value?: PartialMessage<ReturnSaleReq>): ReturnSaleReq {
        const message = { notes: "", customerPurchaseOrderName: "", customerContactIdString: "", returnProductsById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReturnSaleReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReturnSaleReq): ReturnSaleReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID sale_id */ 1:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* string notes */ 2:
                    message.notes = reader.string();
                    break;
                case /* rd.proto.uuid.UUID returned_location_id */ 3:
                    message.returnedLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.returnedLocationId);
                    break;
                case /* rd.proto.address.Address customer_job_site_address */ 6:
                    message.customerJobSiteAddress = Address.internalBinaryRead(reader, reader.uint32(), options, message.customerJobSiteAddress);
                    break;
                case /* string customer_purchase_order_name */ 7:
                    message.customerPurchaseOrderName = reader.string();
                    break;
                case /* string customer_contact_id_string */ 8:
                    message.customerContactIdString = reader.string();
                    break;
                case /* map<string, rd.api.sale.ReturnSaleReq.ReturnProduct> return_products_by_id */ 9:
                    this.binaryReadMap9(message.returnProductsById, reader, options);
                    break;
                case /* rd.proto.uuid.UUID printer_id */ 10:
                    message.printerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.printerId);
                    break;
                case /* rd.proto.address.Address address */ 11:
                    message.address = Address.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap9(map: ReturnSaleReq["returnProductsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ReturnSaleReq["returnProductsById"] | undefined, val: ReturnSaleReq["returnProductsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = ReturnSaleReq_ReturnProduct.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.sale.ReturnSaleReq.return_products_by_id");
            }
        }
        map[key ?? ""] = val ?? ReturnSaleReq_ReturnProduct.create();
    }
    internalBinaryWrite(message: ReturnSaleReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID sale_id = 1; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string notes = 2; */
        if (message.notes !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.notes);
        /* rd.proto.uuid.UUID returned_location_id = 3; */
        if (message.returnedLocationId)
            UUID.internalBinaryWrite(message.returnedLocationId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.address.Address customer_job_site_address = 6; */
        if (message.customerJobSiteAddress)
            Address.internalBinaryWrite(message.customerJobSiteAddress, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string customer_purchase_order_name = 7; */
        if (message.customerPurchaseOrderName !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.customerPurchaseOrderName);
        /* string customer_contact_id_string = 8; */
        if (message.customerContactIdString !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.customerContactIdString);
        /* map<string, rd.api.sale.ReturnSaleReq.ReturnProduct> return_products_by_id = 9; */
        for (let k of Object.keys(message.returnProductsById)) {
            writer.tag(9, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            ReturnSaleReq_ReturnProduct.internalBinaryWrite(message.returnProductsById[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.uuid.UUID printer_id = 10; */
        if (message.printerId)
            UUID.internalBinaryWrite(message.printerId, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.address.Address address = 11; */
        if (message.address)
            Address.internalBinaryWrite(message.address, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.ReturnSaleReq
 */
export const ReturnSaleReq = new ReturnSaleReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReturnSaleReq_ReturnProduct$Type extends MessageType<ReturnSaleReq_ReturnProduct> {
    constructor() {
        super("rd.api.sale.ReturnSaleReq.ReturnProduct", [
            { no: 1, name: "quantityToReturn", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ReturnSaleReq_ReturnProduct>): ReturnSaleReq_ReturnProduct {
        const message = { quantityToReturn: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReturnSaleReq_ReturnProduct>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReturnSaleReq_ReturnProduct): ReturnSaleReq_ReturnProduct {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 quantityToReturn */ 1:
                    message.quantityToReturn = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReturnSaleReq_ReturnProduct, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 quantityToReturn = 1; */
        if (message.quantityToReturn !== 0)
            writer.tag(1, WireType.Varint).int32(message.quantityToReturn);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.ReturnSaleReq.ReturnProduct
 */
export const ReturnSaleReq_ReturnProduct = new ReturnSaleReq_ReturnProduct$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReturnSaleRes$Type extends MessageType<ReturnSaleRes> {
    constructor() {
        super("rd.api.sale.ReturnSaleRes", [
            { no: 1, name: "return_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReturnSaleRes>): ReturnSaleRes {
        const message = { returnIdentifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReturnSaleRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReturnSaleRes): ReturnSaleRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string return_identifier */ 1:
                    message.returnIdentifier = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReturnSaleRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string return_identifier = 1; */
        if (message.returnIdentifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.returnIdentifier);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.ReturnSaleRes
 */
export const ReturnSaleRes = new ReturnSaleRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetReturnReq$Type extends MessageType<GetReturnReq> {
    constructor() {
        super("rd.api.sale.GetReturnReq", [
            { no: 1, name: "sale_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "return_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetReturnReq>): GetReturnReq {
        const message = { returnIdentifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetReturnReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetReturnReq): GetReturnReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID sale_id */ 1:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* string return_identifier */ 2:
                    message.returnIdentifier = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetReturnReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID sale_id = 1; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string return_identifier = 2; */
        if (message.returnIdentifier !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.returnIdentifier);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.GetReturnReq
 */
export const GetReturnReq = new GetReturnReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetReturnRes$Type extends MessageType<GetReturnRes> {
    constructor() {
        super("rd.api.sale.GetReturnRes", [
            { no: 1, name: "return", kind: "message", T: () => Sale_Return },
            { no: 2, name: "sale", kind: "message", T: () => Sale },
            { no: 3, name: "products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 4, name: "catalog_products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product$ } },
            { no: 5, name: "tint_colors_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => TintColor } },
            { no: 6, name: "location", kind: "message", T: () => Location },
            { no: 7, name: "customer_purchase_order", kind: "message", T: () => PurchaseOrder },
            { no: 8, name: "customer_job_site", kind: "message", T: () => JobSite },
            { no: 9, name: "customer_contact", kind: "message", T: () => Contacts_Contact },
            { no: 10, name: "customer", kind: "message", T: () => Customer },
            { no: 11, name: "tax_rate", kind: "message", T: () => TaxRate },
            { no: 12, name: "return_method", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetReturnRes>): GetReturnRes {
        const message = { productsById: {}, catalogProductsById: {}, tintColorsById: {}, returnMethod: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetReturnRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetReturnRes): GetReturnRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.Sale.Return return */ 1:
                    message.return = Sale_Return.internalBinaryRead(reader, reader.uint32(), options, message.return);
                    break;
                case /* rd.txn.Sale sale */ 2:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* map<string, rd.product.Product> products_by_id */ 3:
                    this.binaryReadMap3(message.productsById, reader, options);
                    break;
                case /* map<string, rd.catalog.Product> catalog_products_by_id */ 4:
                    this.binaryReadMap4(message.catalogProductsById, reader, options);
                    break;
                case /* map<string, rd.tintcolor.TintColor> tint_colors_by_id */ 5:
                    this.binaryReadMap5(message.tintColorsById, reader, options);
                    break;
                case /* rd.company.Location location */ 6:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.customer.PurchaseOrder customer_purchase_order */ 7:
                    message.customerPurchaseOrder = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options, message.customerPurchaseOrder);
                    break;
                case /* rd.customer.JobSite customer_job_site */ 8:
                    message.customerJobSite = JobSite.internalBinaryRead(reader, reader.uint32(), options, message.customerJobSite);
                    break;
                case /* rd.customer.Contacts.Contact customer_contact */ 9:
                    message.customerContact = Contacts_Contact.internalBinaryRead(reader, reader.uint32(), options, message.customerContact);
                    break;
                case /* rd.customer.Customer customer */ 10:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.company.TaxRate tax_rate */ 11:
                    message.taxRate = TaxRate.internalBinaryRead(reader, reader.uint32(), options, message.taxRate);
                    break;
                case /* string return_method */ 12:
                    message.returnMethod = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: GetReturnRes["productsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetReturnRes["productsById"] | undefined, val: GetReturnRes["productsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.sale.GetReturnRes.products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    private binaryReadMap4(map: GetReturnRes["catalogProductsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetReturnRes["catalogProductsById"] | undefined, val: GetReturnRes["catalogProductsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product$.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.sale.GetReturnRes.catalog_products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product$.create();
    }
    private binaryReadMap5(map: GetReturnRes["tintColorsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetReturnRes["tintColorsById"] | undefined, val: GetReturnRes["tintColorsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = TintColor.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.sale.GetReturnRes.tint_colors_by_id");
            }
        }
        map[key ?? ""] = val ?? TintColor.create();
    }
    internalBinaryWrite(message: GetReturnRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.Sale.Return return = 1; */
        if (message.return)
            Sale_Return.internalBinaryWrite(message.return, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.Sale sale = 2; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.product.Product> products_by_id = 3; */
        for (let k of Object.keys(message.productsById)) {
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.productsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.catalog.Product> catalog_products_by_id = 4; */
        for (let k of Object.keys(message.catalogProductsById)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product$.internalBinaryWrite(message.catalogProductsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.tintcolor.TintColor> tint_colors_by_id = 5; */
        for (let k of Object.keys(message.tintColorsById)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            TintColor.internalBinaryWrite(message.tintColorsById[k], writer, options);
            writer.join().join();
        }
        /* rd.company.Location location = 6; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.PurchaseOrder customer_purchase_order = 7; */
        if (message.customerPurchaseOrder)
            PurchaseOrder.internalBinaryWrite(message.customerPurchaseOrder, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.JobSite customer_job_site = 8; */
        if (message.customerJobSite)
            JobSite.internalBinaryWrite(message.customerJobSite, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Contacts.Contact customer_contact = 9; */
        if (message.customerContact)
            Contacts_Contact.internalBinaryWrite(message.customerContact, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Customer customer = 10; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.TaxRate tax_rate = 11; */
        if (message.taxRate)
            TaxRate.internalBinaryWrite(message.taxRate, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* string return_method = 12; */
        if (message.returnMethod !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.returnMethod);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.GetReturnRes
 */
export const GetReturnRes = new GetReturnRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetReturnPDFReq$Type extends MessageType<GetReturnPDFReq> {
    constructor() {
        super("rd.api.sale.GetReturnPDFReq", [
            { no: 1, name: "sale_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "return_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetReturnPDFReq>): GetReturnPDFReq {
        const message = { returnIdentifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetReturnPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetReturnPDFReq): GetReturnPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID sale_id */ 1:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* string return_identifier */ 2:
                    message.returnIdentifier = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetReturnPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID sale_id = 1; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string return_identifier = 2; */
        if (message.returnIdentifier !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.returnIdentifier);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.GetReturnPDFReq
 */
export const GetReturnPDFReq = new GetReturnPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetReturnPDFRes$Type extends MessageType<GetReturnPDFRes> {
    constructor() {
        super("rd.api.sale.GetReturnPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetReturnPDFRes>): GetReturnPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetReturnPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetReturnPDFRes): GetReturnPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetReturnPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.GetReturnPDFRes
 */
export const GetReturnPDFRes = new GetReturnPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateReturnDetailsReq$Type extends MessageType<UpdateReturnDetailsReq> {
    constructor() {
        super("rd.api.sale.UpdateReturnDetailsReq", [
            { no: 1, name: "sale_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "return_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 3, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 4, name: "customer_contact_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "purchase_order", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "job_site_address", kind: "message", T: () => Address },
            { no: 7, name: "address", kind: "message", T: () => Address }
        ]);
    }
    create(value?: PartialMessage<UpdateReturnDetailsReq>): UpdateReturnDetailsReq {
        const message = { returnIdentifier: "", notes: "", customerContactIdString: "", purchaseOrder: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateReturnDetailsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateReturnDetailsReq): UpdateReturnDetailsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID sale_id */ 1:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* string return_identifier */ 2:
                    message.returnIdentifier = reader.string();
                    break;
                case /* string notes */ 3:
                    message.notes = reader.string();
                    break;
                case /* string customer_contact_id_string */ 4:
                    message.customerContactIdString = reader.string();
                    break;
                case /* string purchase_order */ 5:
                    message.purchaseOrder = reader.string();
                    break;
                case /* rd.proto.address.Address job_site_address */ 6:
                    message.jobSiteAddress = Address.internalBinaryRead(reader, reader.uint32(), options, message.jobSiteAddress);
                    break;
                case /* rd.proto.address.Address address */ 7:
                    message.address = Address.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateReturnDetailsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID sale_id = 1; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string return_identifier = 2; */
        if (message.returnIdentifier !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.returnIdentifier);
        /* string notes = 3; */
        if (message.notes !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.notes);
        /* string customer_contact_id_string = 4; */
        if (message.customerContactIdString !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.customerContactIdString);
        /* string purchase_order = 5; */
        if (message.purchaseOrder !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.purchaseOrder);
        /* rd.proto.address.Address job_site_address = 6; */
        if (message.jobSiteAddress)
            Address.internalBinaryWrite(message.jobSiteAddress, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.address.Address address = 7; */
        if (message.address)
            Address.internalBinaryWrite(message.address, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.UpdateReturnDetailsReq
 */
export const UpdateReturnDetailsReq = new UpdateReturnDetailsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateReturnDetailsRes$Type extends MessageType<UpdateReturnDetailsRes> {
    constructor() {
        super("rd.api.sale.UpdateReturnDetailsRes", []);
    }
    create(value?: PartialMessage<UpdateReturnDetailsRes>): UpdateReturnDetailsRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateReturnDetailsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateReturnDetailsRes): UpdateReturnDetailsRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateReturnDetailsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.UpdateReturnDetailsRes
 */
export const UpdateReturnDetailsRes = new UpdateReturnDetailsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmailReturnPDFReq$Type extends MessageType<EmailReturnPDFReq> {
    constructor() {
        super("rd.api.sale.EmailReturnPDFReq", [
            { no: 1, name: "sale_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "return_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 3, name: "customer_contact_id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "additional_emails", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EmailReturnPDFReq>): EmailReturnPDFReq {
        const message = { returnIdentifier: "", customerContactIdStrings: [], additionalEmails: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EmailReturnPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EmailReturnPDFReq): EmailReturnPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID sale_id */ 1:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* string return_identifier */ 2:
                    message.returnIdentifier = reader.string();
                    break;
                case /* repeated string customer_contact_id_strings */ 3:
                    message.customerContactIdStrings.push(reader.string());
                    break;
                case /* repeated string additional_emails */ 4:
                    message.additionalEmails.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EmailReturnPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID sale_id = 1; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string return_identifier = 2; */
        if (message.returnIdentifier !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.returnIdentifier);
        /* repeated string customer_contact_id_strings = 3; */
        for (let i = 0; i < message.customerContactIdStrings.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.customerContactIdStrings[i]);
        /* repeated string additional_emails = 4; */
        for (let i = 0; i < message.additionalEmails.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.additionalEmails[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.EmailReturnPDFReq
 */
export const EmailReturnPDFReq = new EmailReturnPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmailReturnPDFRes$Type extends MessageType<EmailReturnPDFRes> {
    constructor() {
        super("rd.api.sale.EmailReturnPDFRes", []);
    }
    create(value?: PartialMessage<EmailReturnPDFRes>): EmailReturnPDFRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EmailReturnPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EmailReturnPDFRes): EmailReturnPDFRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EmailReturnPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.EmailReturnPDFRes
 */
export const EmailReturnPDFRes = new EmailReturnPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSaleProductReturnedReq$Type extends MessageType<UpdateSaleProductReturnedReq> {
    constructor() {
        super("rd.api.sale.UpdateSaleProductReturnedReq", [
            { no: 1, name: "sale_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "return_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 3, name: "sale_product_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 4, name: "product", kind: "message", T: () => UpdateSaleProductReturnedReq_Product }
        ]);
    }
    create(value?: PartialMessage<UpdateSaleProductReturnedReq>): UpdateSaleProductReturnedReq {
        const message = { returnIdentifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateSaleProductReturnedReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateSaleProductReturnedReq): UpdateSaleProductReturnedReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID sale_id */ 1:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* string return_identifier */ 2:
                    message.returnIdentifier = reader.string();
                    break;
                case /* rd.proto.uuid.UUID sale_product_id */ 3:
                    message.saleProductId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleProductId);
                    break;
                case /* rd.api.sale.UpdateSaleProductReturnedReq.Product product */ 4:
                    message.product = UpdateSaleProductReturnedReq_Product.internalBinaryRead(reader, reader.uint32(), options, message.product);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateSaleProductReturnedReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID sale_id = 1; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string return_identifier = 2; */
        if (message.returnIdentifier !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.returnIdentifier);
        /* rd.proto.uuid.UUID sale_product_id = 3; */
        if (message.saleProductId)
            UUID.internalBinaryWrite(message.saleProductId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.api.sale.UpdateSaleProductReturnedReq.Product product = 4; */
        if (message.product)
            UpdateSaleProductReturnedReq_Product.internalBinaryWrite(message.product, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.UpdateSaleProductReturnedReq
 */
export const UpdateSaleProductReturnedReq = new UpdateSaleProductReturnedReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSaleProductReturnedReq_Product$Type extends MessageType<UpdateSaleProductReturnedReq_Product> {
    constructor() {
        super("rd.api.sale.UpdateSaleProductReturnedReq.Product", [
            { no: 2, name: "tint_color_id", kind: "message", T: () => UUID },
            { no: 3, name: "custom_tint_color", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateSaleProductReturnedReq_Product>): UpdateSaleProductReturnedReq_Product {
        const message = { customTintColor: "", notes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateSaleProductReturnedReq_Product>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateSaleProductReturnedReq_Product): UpdateSaleProductReturnedReq_Product {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID tint_color_id */ 2:
                    message.tintColorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.tintColorId);
                    break;
                case /* string custom_tint_color */ 3:
                    message.customTintColor = reader.string();
                    break;
                case /* string notes */ 4:
                    message.notes = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateSaleProductReturnedReq_Product, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID tint_color_id = 2; */
        if (message.tintColorId)
            UUID.internalBinaryWrite(message.tintColorId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string custom_tint_color = 3; */
        if (message.customTintColor !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customTintColor);
        /* string notes = 4; */
        if (message.notes !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.notes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.UpdateSaleProductReturnedReq.Product
 */
export const UpdateSaleProductReturnedReq_Product = new UpdateSaleProductReturnedReq_Product$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSaleProductReturnedRes$Type extends MessageType<UpdateSaleProductReturnedRes> {
    constructor() {
        super("rd.api.sale.UpdateSaleProductReturnedRes", []);
    }
    create(value?: PartialMessage<UpdateSaleProductReturnedRes>): UpdateSaleProductReturnedRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateSaleProductReturnedRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateSaleProductReturnedRes): UpdateSaleProductReturnedRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateSaleProductReturnedRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.UpdateSaleProductReturnedRes
 */
export const UpdateSaleProductReturnedRes = new UpdateSaleProductReturnedRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetScannedReturnReq$Type extends MessageType<GetScannedReturnReq> {
    constructor() {
        super("rd.api.sale.GetScannedReturnReq", [
            { no: 1, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetScannedReturnReq>): GetScannedReturnReq {
        const message = { identifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetScannedReturnReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetScannedReturnReq): GetScannedReturnReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string identifier */ 1:
                    message.identifier = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetScannedReturnReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string identifier = 1; */
        if (message.identifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.identifier);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.GetScannedReturnReq
 */
export const GetScannedReturnReq = new GetScannedReturnReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetScannedReturnRes$Type extends MessageType<GetScannedReturnRes> {
    constructor() {
        super("rd.api.sale.GetScannedReturnRes", [
            { no: 1, name: "sale_id", kind: "message", T: () => UUID },
            { no: 2, name: "return_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetScannedReturnRes>): GetScannedReturnRes {
        const message = { returnIdentifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetScannedReturnRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetScannedReturnRes): GetScannedReturnRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID sale_id */ 1:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* string return_identifier */ 2:
                    message.returnIdentifier = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetScannedReturnRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID sale_id = 1; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string return_identifier = 2; */
        if (message.returnIdentifier !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.returnIdentifier);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.GetScannedReturnRes
 */
export const GetScannedReturnRes = new GetScannedReturnRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSaleProductReturnedCostReq$Type extends MessageType<UpdateSaleProductReturnedCostReq> {
    constructor() {
        super("rd.api.sale.UpdateSaleProductReturnedCostReq", [
            { no: 1, name: "sale_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "return_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 3, name: "sale_product_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 4, name: "cost", kind: "message", T: () => FractionalMoney, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<UpdateSaleProductReturnedCostReq>): UpdateSaleProductReturnedCostReq {
        const message = { returnIdentifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateSaleProductReturnedCostReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateSaleProductReturnedCostReq): UpdateSaleProductReturnedCostReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID sale_id */ 1:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* string return_identifier */ 2:
                    message.returnIdentifier = reader.string();
                    break;
                case /* rd.proto.uuid.UUID sale_product_id */ 3:
                    message.saleProductId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleProductId);
                    break;
                case /* rd.proto.money.FractionalMoney cost */ 4:
                    message.cost = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.cost);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateSaleProductReturnedCostReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID sale_id = 1; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string return_identifier = 2; */
        if (message.returnIdentifier !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.returnIdentifier);
        /* rd.proto.uuid.UUID sale_product_id = 3; */
        if (message.saleProductId)
            UUID.internalBinaryWrite(message.saleProductId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.FractionalMoney cost = 4; */
        if (message.cost)
            FractionalMoney.internalBinaryWrite(message.cost, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.UpdateSaleProductReturnedCostReq
 */
export const UpdateSaleProductReturnedCostReq = new UpdateSaleProductReturnedCostReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSaleProductReturnedCostRes$Type extends MessageType<UpdateSaleProductReturnedCostRes> {
    constructor() {
        super("rd.api.sale.UpdateSaleProductReturnedCostRes", []);
    }
    create(value?: PartialMessage<UpdateSaleProductReturnedCostRes>): UpdateSaleProductReturnedCostRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateSaleProductReturnedCostRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateSaleProductReturnedCostRes): UpdateSaleProductReturnedCostRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateSaleProductReturnedCostRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.UpdateSaleProductReturnedCostRes
 */
export const UpdateSaleProductReturnedCostRes = new UpdateSaleProductReturnedCostRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CalculateReturnTotalsReq$Type extends MessageType<CalculateReturnTotalsReq> {
    constructor() {
        super("rd.api.sale.CalculateReturnTotalsReq", [
            { no: 1, name: "sale_id", kind: "message", T: () => UUID },
            { no: 2, name: "quantities_to_return_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } }
        ]);
    }
    create(value?: PartialMessage<CalculateReturnTotalsReq>): CalculateReturnTotalsReq {
        const message = { quantitiesToReturnById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CalculateReturnTotalsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CalculateReturnTotalsReq): CalculateReturnTotalsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID sale_id */ 1:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* map<string, int32> quantities_to_return_by_id */ 2:
                    this.binaryReadMap2(message.quantitiesToReturnById, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: CalculateReturnTotalsReq["quantitiesToReturnById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CalculateReturnTotalsReq["quantitiesToReturnById"] | undefined, val: CalculateReturnTotalsReq["quantitiesToReturnById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.sale.CalculateReturnTotalsReq.quantities_to_return_by_id");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: CalculateReturnTotalsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID sale_id = 1; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* map<string, int32> quantities_to_return_by_id = 2; */
        for (let k of Object.keys(message.quantitiesToReturnById))
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).int32(message.quantitiesToReturnById[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.CalculateReturnTotalsReq
 */
export const CalculateReturnTotalsReq = new CalculateReturnTotalsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CalculateReturnTotalsRes$Type extends MessageType<CalculateReturnTotalsRes> {
    constructor() {
        super("rd.api.sale.CalculateReturnTotalsRes", [
            { no: 1, name: "total", kind: "message", T: () => Money },
            { no: 2, name: "subtotal", kind: "message", T: () => Money },
            { no: 3, name: "sales_tax_total", kind: "message", T: () => Money },
            { no: 4, name: "card_surcharge", kind: "message", T: () => Money },
            { no: 5, name: "card_surcharge_tax", kind: "message", T: () => Money },
            { no: 6, name: "additional_fees_by_name", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 7, name: "tax_rate", kind: "message", T: () => TaxRate },
            { no: 8, name: "card_surcharge_rate", kind: "message", T: () => Decimal },
            { no: 9, name: "early_payment_discount_reversal", kind: "message", T: () => Money },
            { no: 10, name: "final_total", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<CalculateReturnTotalsRes>): CalculateReturnTotalsRes {
        const message = { additionalFeesByName: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CalculateReturnTotalsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CalculateReturnTotalsRes): CalculateReturnTotalsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money total */ 1:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* rd.proto.money.Money subtotal */ 2:
                    message.subtotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.subtotal);
                    break;
                case /* rd.proto.money.Money sales_tax_total */ 3:
                    message.salesTaxTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.salesTaxTotal);
                    break;
                case /* rd.proto.money.Money card_surcharge */ 4:
                    message.cardSurcharge = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurcharge);
                    break;
                case /* rd.proto.money.Money card_surcharge_tax */ 5:
                    message.cardSurchargeTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeTax);
                    break;
                case /* map<string, rd.proto.money.Money> additional_fees_by_name */ 6:
                    this.binaryReadMap6(message.additionalFeesByName, reader, options);
                    break;
                case /* rd.company.TaxRate tax_rate */ 7:
                    message.taxRate = TaxRate.internalBinaryRead(reader, reader.uint32(), options, message.taxRate);
                    break;
                case /* rd.proto.decimal.Decimal card_surcharge_rate */ 8:
                    message.cardSurchargeRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeRate);
                    break;
                case /* rd.proto.money.Money early_payment_discount_reversal */ 9:
                    message.earlyPaymentDiscountReversal = Money.internalBinaryRead(reader, reader.uint32(), options, message.earlyPaymentDiscountReversal);
                    break;
                case /* rd.proto.money.Money final_total */ 10:
                    message.finalTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.finalTotal);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap6(map: CalculateReturnTotalsRes["additionalFeesByName"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CalculateReturnTotalsRes["additionalFeesByName"] | undefined, val: CalculateReturnTotalsRes["additionalFeesByName"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.sale.CalculateReturnTotalsRes.additional_fees_by_name");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    internalBinaryWrite(message: CalculateReturnTotalsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money total = 1; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money subtotal = 2; */
        if (message.subtotal)
            Money.internalBinaryWrite(message.subtotal, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money sales_tax_total = 3; */
        if (message.salesTaxTotal)
            Money.internalBinaryWrite(message.salesTaxTotal, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge = 4; */
        if (message.cardSurcharge)
            Money.internalBinaryWrite(message.cardSurcharge, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge_tax = 5; */
        if (message.cardSurchargeTax)
            Money.internalBinaryWrite(message.cardSurchargeTax, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.proto.money.Money> additional_fees_by_name = 6; */
        for (let k of Object.keys(message.additionalFeesByName)) {
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.additionalFeesByName[k], writer, options);
            writer.join().join();
        }
        /* rd.company.TaxRate tax_rate = 7; */
        if (message.taxRate)
            TaxRate.internalBinaryWrite(message.taxRate, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal card_surcharge_rate = 8; */
        if (message.cardSurchargeRate)
            Decimal.internalBinaryWrite(message.cardSurchargeRate, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money early_payment_discount_reversal = 9; */
        if (message.earlyPaymentDiscountReversal)
            Money.internalBinaryWrite(message.earlyPaymentDiscountReversal, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money final_total = 10; */
        if (message.finalTotal)
            Money.internalBinaryWrite(message.finalTotal, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.sale.CalculateReturnTotalsRes
 */
export const CalculateReturnTotalsRes = new CalculateReturnTotalsRes$Type();
/**
 * @generated ServiceType for protobuf service rd.api.sale.SaleService
 */
export const SaleService = new ServiceType("rd.api.sale.SaleService", [
    { name: "GetSaleCountsByStatus", options: {}, I: GetSaleCountsByStatusReq, O: GetSaleCountsByStatusRes },
    { name: "GetSale", options: {}, I: GetSaleReq, O: GetSaleRes },
    { name: "GetScannedSale", options: {}, I: GetScannedSaleReq, O: GetScannedSaleRes },
    { name: "UpsertSaleV3", options: {}, I: UpsertSaleV3Req, O: UpsertSaleV3Res },
    { name: "SyncCartPrices", options: {}, I: SyncCartPricesReq, O: SyncCartPricesRes },
    { name: "GetCartPrices", options: {}, I: GetCartPricesReq, O: GetCartPricesRes },
    { name: "UpdateSoldSaleProduct", options: {}, I: UpdateSoldSaleProductReq, O: UpdateSoldSaleProductRes },
    { name: "UpdateSoldSaleDetails", options: {}, I: UpdateSoldSaleDetailsReq, O: UpdateSoldSaleDetailsRes },
    { name: "EmailSalePDF", options: {}, I: EmailSalePDFReq, O: EmailSalePDFRes },
    { name: "GetSalePDF", options: {}, I: GetSalePDFReq, O: GetSalePDFRes },
    { name: "AuthorizeCardPayment", options: {}, I: AuthorizeCardPaymentReq, O: AuthorizeCardPaymentRes },
    { name: "SetCardReaderDisplay", options: {}, I: SetCardReaderDisplayReq, O: SetCardReaderDisplayRes },
    { name: "CanEditPriceDuringSale", options: {}, I: CanEditPriceDuringSaleReq, O: CanEditPriceDuringSaleRes },
    { name: "DownloadItemizedSalesCSV", options: {}, I: DownloadItemizedSalesCSVReq, O: DownloadItemizedSalesCSVRes },
    { name: "UpdateSaleProductCost", options: {}, I: UpdateSaleProductCostReq, O: UpdateSaleProductCostRes },
    { name: "DuplicateSale", options: {}, I: DuplicateSaleReq, O: DuplicateSaleRes },
    { name: "UpdateUnpaidChargeAccountSaleDate", options: {}, I: UpdateUnpaidChargeAccountSaleDateReq, O: UpdateUnpaidChargeAccountSaleDateRes },
    { name: "GetReturn", options: {}, I: GetReturnReq, O: GetReturnRes },
    { name: "ReturnSale", options: {}, I: ReturnSaleReq, O: ReturnSaleRes },
    { name: "GetReturnPDF", options: {}, I: GetReturnPDFReq, O: GetReturnPDFRes },
    { name: "UpdateReturnDetails", options: {}, I: UpdateReturnDetailsReq, O: UpdateReturnDetailsRes },
    { name: "EmailReturnPDF", options: {}, I: EmailReturnPDFReq, O: EmailReturnPDFRes },
    { name: "UpdateSaleProductReturned", options: {}, I: UpdateSaleProductReturnedReq, O: UpdateSaleProductReturnedRes },
    { name: "GetScannedReturn", options: {}, I: GetScannedReturnReq, O: GetScannedReturnRes },
    { name: "UpdateSaleProductReturnedCost", options: {}, I: UpdateSaleProductReturnedCostReq, O: UpdateSaleProductReturnedCostRes },
    { name: "CalculateReturnTotals", options: {}, I: CalculateReturnTotalsReq, O: CalculateReturnTotalsRes }
]);
