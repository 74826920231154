/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "customer/models.proto" (package "rd.customer", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Money } from "../proto/money/models_pb";
import { Address } from "../proto/address/models_pb";
import { User } from "../user/models_pb";
import { Timestamp } from "../google/protobuf/timestamp_pb";
import { UUID } from "../proto/uuid/models_pb";
/**
 * Customer represents a person or business buying materials from a Company.
 *
 * @generated from protobuf message rd.customer.Customer
 */
export interface Customer {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 4;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.user.User updated_by = 5;
     */
    updatedBy?: User;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 6;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: string pricing_tier_id_string = 7;
     */
    pricingTierIdString: string;
    /**
     * @generated from protobuf field: string financing_term_id_string = 8;
     */
    financingTermIdString: string;
    /**
     * @generated from protobuf field: string name = 10;
     */
    name: string;
    /**
     * @generated from protobuf field: string identifier = 11;
     */
    identifier: string;
    /**
     * @generated from protobuf field: rd.proto.address.Address address = 12;
     */
    address?: Address;
    /**
     * @generated from protobuf field: string eco_fee_exempt_number = 13;
     */
    ecoFeeExemptNumber: string;
    /**
     * @generated from protobuf field: string sales_tax_exempt_number = 14;
     */
    salesTaxExemptNumber: string;
    /**
     * @generated from protobuf field: bool should_require_po = 15;
     */
    shouldRequirePo: boolean;
    /**
     * @generated from protobuf field: bool should_print_invoices_and_receipts = 17;
     */
    shouldPrintInvoicesAndReceipts: boolean;
    /**
     * @generated from protobuf field: bool should_autopay_statement_balances = 19;
     */
    shouldAutopayStatementBalances: boolean;
    /**
     * @generated from protobuf field: bool should_mail_statements = 20;
     */
    shouldMailStatements: boolean;
    /**
     * @generated from protobuf field: bool is_active = 21;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: bool is_on_credit_hold = 22;
     */
    isOnCreditHold: boolean;
    /**
     * @generated from protobuf field: rd.proto.money.Money credit_limit = 23;
     */
    creditLimit?: Money;
    /**
     * @generated from protobuf field: string notes = 24;
     */
    notes: string;
    /**
     * @generated from protobuf field: string stripe_customer_id = 25;
     */
    stripeCustomerId: string;
    /**
     * @generated from protobuf field: bool should_require_customer_contact = 26;
     */
    shouldRequireCustomerContact: boolean;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID rep_staff_id = 27;
     */
    repStaffId?: UUID;
    /**
     * @generated from protobuf field: string bm_pax_id = 28;
     */
    bmPaxId: string;
    /**
     * @generated from protobuf field: bool should_require_receipt_signature = 29;
     */
    shouldRequireReceiptSignature: boolean;
    /**
     * optional; defines the default custom tax rate for the customer if present
     *
     * @generated from protobuf field: rd.proto.uuid.UUID tax_rate_id = 31;
     */
    taxRateId?: UUID;
    /**
     * @generated from protobuf field: bool should_require_sale_notes = 32;
     */
    shouldRequireSaleNotes: boolean;
    /**
     * @generated from protobuf field: string single_use_tax_number = 33;
     */
    singleUseTaxNumber: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID finance_charge_setting_id = 34;
     */
    financeChargeSettingId?: UUID;
    /**
     * @generated from protobuf field: string sales_reminder = 35;
     */
    salesReminder: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID primary_location_id = 36;
     */
    primaryLocationId?: UUID;
    /**
     * @generated from protobuf field: rd.customer.Customer.Job job = 37;
     */
    job?: Customer_Job;
    /**
     * @generated from protobuf field: bool should_require_job = 39;
     */
    shouldRequireJob: boolean;
    /**
     * @generated from protobuf field: bool should_receive_job_statements = 40;
     */
    shouldReceiveJobStatements: boolean;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID tag_ids = 41;
     */
    tagIds: UUID[];
    /**
     * @generated from protobuf field: bool should_require_internal_sale_notes = 42;
     */
    shouldRequireInternalSaleNotes: boolean;
    /**
     * @generated from protobuf field: rd.customer.Customer.StatementInvoicesFormatType statement_invoices_format = 43;
     */
    statementInvoicesFormat: Customer_StatementInvoicesFormatType;
    /**
     * @generated from protobuf field: rd.customer.Customer.StatementType statement_type = 44;
     */
    statementType: Customer_StatementType;
}
/**
 * @generated from protobuf message rd.customer.Customer.Job
 */
export interface Customer_Job {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID parent_id = 1;
     */
    parentId?: UUID;
    /**
     * @generated from protobuf field: bool should_receive_job_statements = 4;
     */
    shouldReceiveJobStatements: boolean;
}
/**
 * @generated from protobuf enum rd.customer.Customer.StatementInvoicesFormatType
 */
export enum Customer_StatementInvoicesFormatType {
    /**
     * @generated from protobuf enum value: STATEMENT_INVOICES_FORMAT_TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: STATEMENT_INVOICES_FORMAT_TYPE_NONE = 1;
     */
    NONE = 1,
    /**
     * @generated from protobuf enum value: STATEMENT_INVOICES_FORMAT_TYPE_CONDENSED = 2;
     */
    CONDENSED = 2,
    /**
     * @generated from protobuf enum value: STATEMENT_INVOICES_FORMAT_TYPE_FULL_PAGE = 3;
     */
    FULL_PAGE = 3
}
/**
 * @generated from protobuf enum rd.customer.Customer.StatementType
 */
export enum Customer_StatementType {
    /**
     * @generated from protobuf enum value: STATEMENT_TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: STATEMENT_TYPE_OPEN_ITEM = 1;
     */
    OPEN_ITEM = 1,
    /**
     * @generated from protobuf enum value: STATEMENT_TYPE_BALANCE_FORWARD = 2;
     */
    BALANCE_FORWARD = 2
}
/**
 * CustomerLog represents a change of a Customer.
 *
 * @generated from protobuf message rd.customer.CustomerLog
 */
export interface CustomerLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 3;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 4;
     */
    customer?: Customer;
}
/**
 * Contacts represents the contact details for a customer's contacts.
 * For retail customers, they will usually have just one contact (themselves).
 * For business customers, they will usually have multiple contacts.
 * Contacts are sanitized for the cxa app so that sensitive fields are removed
 * from the response before sending to the customer app frontend.
 *
 * @generated from protobuf message rd.customer.Contacts
 */
export interface Contacts {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID; // customer id
    /**
     * @generated from protobuf field: string primary_id_string = 2;
     */
    primaryIdString: string; // id of primary contact
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 3;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 4;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: map<string, rd.customer.Contacts.Contact> contacts_by_id = 5;
     */
    contactsById: {
        [key: string]: Contacts_Contact;
    };
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 6;
     */
    companyId?: UUID;
}
/**
 * @generated from protobuf message rd.customer.Contacts.Contact
 */
export interface Contacts_Contact {
    /**
     * @generated from protobuf field: string id_string = 1;
     */
    idString: string;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: string phone = 4;
     */
    phone: string;
    /**
     * @generated from protobuf field: string email = 5;
     */
    email: string;
    /**
     * @generated from protobuf field: string notes = 6;
     */
    notes: string;
    /**
     * @generated from protobuf field: bool should_email_invoices = 7;
     */
    shouldEmailInvoices: boolean;
    /**
     * @generated from protobuf field: bool should_email_statements = 8;
     */
    shouldEmailStatements: boolean;
    /**
     * @generated from protobuf field: bool is_active = 10;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: int32 position = 11;
     */
    position: number;
}
/**
 * JobSite represents the location of a customer's job site.
 *
 * @generated from protobuf message rd.customer.JobSite
 */
export interface JobSite {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 4;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.address.Address address = 5;
     */
    address?: Address;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 6;
     */
    companyId?: UUID;
}
/**
 * PurchaseOrder (PO for short) represents a human-readable identifier customers
 * use to organize their orders. Often, they use line 1 of a job site address.
 *
 * @generated from protobuf message rd.customer.PurchaseOrder
 */
export interface PurchaseOrder {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 4;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: string name = 5;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 6;
     */
    companyId?: UUID;
}
/**
 * Interaction represents a note logged by a staff during customer interactions
 *
 * @generated from protobuf message rd.customer.Interaction
 */
export interface Interaction {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 3;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 4;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: string notes = 5;
     */
    notes: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 6;
     */
    companyId?: UUID;
}
// @generated message type with reflection information, may provide speed optimized methods
class Customer$Type extends MessageType<Customer> {
    constructor() {
        super("rd.customer.Customer", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "created_by", kind: "message", T: () => User },
            { no: 5, name: "updated_by", kind: "message", T: () => User },
            { no: 6, name: "company_id", kind: "message", T: () => UUID },
            { no: 7, name: "pricing_tier_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "financing_term_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "address", kind: "message", T: () => Address },
            { no: 13, name: "eco_fee_exempt_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "sales_tax_exempt_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "should_require_po", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "should_print_invoices_and_receipts", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "should_autopay_statement_balances", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 20, name: "should_mail_statements", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 21, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 22, name: "is_on_credit_hold", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 23, name: "credit_limit", kind: "message", T: () => Money },
            { no: 24, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "stripe_customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "should_require_customer_contact", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 27, name: "rep_staff_id", kind: "message", T: () => UUID },
            { no: 28, name: "bm_pax_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "should_require_receipt_signature", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 31, name: "tax_rate_id", kind: "message", T: () => UUID },
            { no: 32, name: "should_require_sale_notes", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 33, name: "single_use_tax_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 34, name: "finance_charge_setting_id", kind: "message", T: () => UUID },
            { no: 35, name: "sales_reminder", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 36, name: "primary_location_id", kind: "message", T: () => UUID },
            { no: 37, name: "job", kind: "message", T: () => Customer_Job },
            { no: 39, name: "should_require_job", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 40, name: "should_receive_job_statements", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 41, name: "tag_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 42, name: "should_require_internal_sale_notes", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 43, name: "statement_invoices_format", kind: "enum", T: () => ["rd.customer.Customer.StatementInvoicesFormatType", Customer_StatementInvoicesFormatType, "STATEMENT_INVOICES_FORMAT_TYPE_"] },
            { no: 44, name: "statement_type", kind: "enum", T: () => ["rd.customer.Customer.StatementType", Customer_StatementType, "STATEMENT_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<Customer>): Customer {
        const message = { pricingTierIdString: "", financingTermIdString: "", name: "", identifier: "", ecoFeeExemptNumber: "", salesTaxExemptNumber: "", shouldRequirePo: false, shouldPrintInvoicesAndReceipts: false, shouldAutopayStatementBalances: false, shouldMailStatements: false, isActive: false, isOnCreditHold: false, notes: "", stripeCustomerId: "", shouldRequireCustomerContact: false, bmPaxId: "", shouldRequireReceiptSignature: false, shouldRequireSaleNotes: false, singleUseTaxNumber: "", salesReminder: "", shouldRequireJob: false, shouldReceiveJobStatements: false, tagIds: [], shouldRequireInternalSaleNotes: false, statementInvoicesFormat: 0, statementType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Customer>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Customer): Customer {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.user.User created_by */ 4:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.user.User updated_by */ 5:
                    message.updatedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.updatedBy);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 6:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* string pricing_tier_id_string */ 7:
                    message.pricingTierIdString = reader.string();
                    break;
                case /* string financing_term_id_string */ 8:
                    message.financingTermIdString = reader.string();
                    break;
                case /* string name */ 10:
                    message.name = reader.string();
                    break;
                case /* string identifier */ 11:
                    message.identifier = reader.string();
                    break;
                case /* rd.proto.address.Address address */ 12:
                    message.address = Address.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                case /* string eco_fee_exempt_number */ 13:
                    message.ecoFeeExemptNumber = reader.string();
                    break;
                case /* string sales_tax_exempt_number */ 14:
                    message.salesTaxExemptNumber = reader.string();
                    break;
                case /* bool should_require_po */ 15:
                    message.shouldRequirePo = reader.bool();
                    break;
                case /* bool should_print_invoices_and_receipts */ 17:
                    message.shouldPrintInvoicesAndReceipts = reader.bool();
                    break;
                case /* bool should_autopay_statement_balances */ 19:
                    message.shouldAutopayStatementBalances = reader.bool();
                    break;
                case /* bool should_mail_statements */ 20:
                    message.shouldMailStatements = reader.bool();
                    break;
                case /* bool is_active */ 21:
                    message.isActive = reader.bool();
                    break;
                case /* bool is_on_credit_hold */ 22:
                    message.isOnCreditHold = reader.bool();
                    break;
                case /* rd.proto.money.Money credit_limit */ 23:
                    message.creditLimit = Money.internalBinaryRead(reader, reader.uint32(), options, message.creditLimit);
                    break;
                case /* string notes */ 24:
                    message.notes = reader.string();
                    break;
                case /* string stripe_customer_id */ 25:
                    message.stripeCustomerId = reader.string();
                    break;
                case /* bool should_require_customer_contact */ 26:
                    message.shouldRequireCustomerContact = reader.bool();
                    break;
                case /* rd.proto.uuid.UUID rep_staff_id */ 27:
                    message.repStaffId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.repStaffId);
                    break;
                case /* string bm_pax_id */ 28:
                    message.bmPaxId = reader.string();
                    break;
                case /* bool should_require_receipt_signature */ 29:
                    message.shouldRequireReceiptSignature = reader.bool();
                    break;
                case /* rd.proto.uuid.UUID tax_rate_id */ 31:
                    message.taxRateId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.taxRateId);
                    break;
                case /* bool should_require_sale_notes */ 32:
                    message.shouldRequireSaleNotes = reader.bool();
                    break;
                case /* string single_use_tax_number */ 33:
                    message.singleUseTaxNumber = reader.string();
                    break;
                case /* rd.proto.uuid.UUID finance_charge_setting_id */ 34:
                    message.financeChargeSettingId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.financeChargeSettingId);
                    break;
                case /* string sales_reminder */ 35:
                    message.salesReminder = reader.string();
                    break;
                case /* rd.proto.uuid.UUID primary_location_id */ 36:
                    message.primaryLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.primaryLocationId);
                    break;
                case /* rd.customer.Customer.Job job */ 37:
                    message.job = Customer_Job.internalBinaryRead(reader, reader.uint32(), options, message.job);
                    break;
                case /* bool should_require_job */ 39:
                    message.shouldRequireJob = reader.bool();
                    break;
                case /* bool should_receive_job_statements */ 40:
                    message.shouldReceiveJobStatements = reader.bool();
                    break;
                case /* repeated rd.proto.uuid.UUID tag_ids */ 41:
                    message.tagIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool should_require_internal_sale_notes */ 42:
                    message.shouldRequireInternalSaleNotes = reader.bool();
                    break;
                case /* rd.customer.Customer.StatementInvoicesFormatType statement_invoices_format */ 43:
                    message.statementInvoicesFormat = reader.int32();
                    break;
                case /* rd.customer.Customer.StatementType statement_type */ 44:
                    message.statementType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Customer, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 4; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User updated_by = 5; */
        if (message.updatedBy)
            User.internalBinaryWrite(message.updatedBy, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 6; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string pricing_tier_id_string = 7; */
        if (message.pricingTierIdString !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.pricingTierIdString);
        /* string financing_term_id_string = 8; */
        if (message.financingTermIdString !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.financingTermIdString);
        /* string name = 10; */
        if (message.name !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.name);
        /* string identifier = 11; */
        if (message.identifier !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.identifier);
        /* rd.proto.address.Address address = 12; */
        if (message.address)
            Address.internalBinaryWrite(message.address, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* string eco_fee_exempt_number = 13; */
        if (message.ecoFeeExemptNumber !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.ecoFeeExemptNumber);
        /* string sales_tax_exempt_number = 14; */
        if (message.salesTaxExemptNumber !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.salesTaxExemptNumber);
        /* bool should_require_po = 15; */
        if (message.shouldRequirePo !== false)
            writer.tag(15, WireType.Varint).bool(message.shouldRequirePo);
        /* bool should_print_invoices_and_receipts = 17; */
        if (message.shouldPrintInvoicesAndReceipts !== false)
            writer.tag(17, WireType.Varint).bool(message.shouldPrintInvoicesAndReceipts);
        /* bool should_autopay_statement_balances = 19; */
        if (message.shouldAutopayStatementBalances !== false)
            writer.tag(19, WireType.Varint).bool(message.shouldAutopayStatementBalances);
        /* bool should_mail_statements = 20; */
        if (message.shouldMailStatements !== false)
            writer.tag(20, WireType.Varint).bool(message.shouldMailStatements);
        /* bool is_active = 21; */
        if (message.isActive !== false)
            writer.tag(21, WireType.Varint).bool(message.isActive);
        /* bool is_on_credit_hold = 22; */
        if (message.isOnCreditHold !== false)
            writer.tag(22, WireType.Varint).bool(message.isOnCreditHold);
        /* rd.proto.money.Money credit_limit = 23; */
        if (message.creditLimit)
            Money.internalBinaryWrite(message.creditLimit, writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* string notes = 24; */
        if (message.notes !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.notes);
        /* string stripe_customer_id = 25; */
        if (message.stripeCustomerId !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.stripeCustomerId);
        /* bool should_require_customer_contact = 26; */
        if (message.shouldRequireCustomerContact !== false)
            writer.tag(26, WireType.Varint).bool(message.shouldRequireCustomerContact);
        /* rd.proto.uuid.UUID rep_staff_id = 27; */
        if (message.repStaffId)
            UUID.internalBinaryWrite(message.repStaffId, writer.tag(27, WireType.LengthDelimited).fork(), options).join();
        /* string bm_pax_id = 28; */
        if (message.bmPaxId !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.bmPaxId);
        /* bool should_require_receipt_signature = 29; */
        if (message.shouldRequireReceiptSignature !== false)
            writer.tag(29, WireType.Varint).bool(message.shouldRequireReceiptSignature);
        /* rd.proto.uuid.UUID tax_rate_id = 31; */
        if (message.taxRateId)
            UUID.internalBinaryWrite(message.taxRateId, writer.tag(31, WireType.LengthDelimited).fork(), options).join();
        /* bool should_require_sale_notes = 32; */
        if (message.shouldRequireSaleNotes !== false)
            writer.tag(32, WireType.Varint).bool(message.shouldRequireSaleNotes);
        /* string single_use_tax_number = 33; */
        if (message.singleUseTaxNumber !== "")
            writer.tag(33, WireType.LengthDelimited).string(message.singleUseTaxNumber);
        /* rd.proto.uuid.UUID finance_charge_setting_id = 34; */
        if (message.financeChargeSettingId)
            UUID.internalBinaryWrite(message.financeChargeSettingId, writer.tag(34, WireType.LengthDelimited).fork(), options).join();
        /* string sales_reminder = 35; */
        if (message.salesReminder !== "")
            writer.tag(35, WireType.LengthDelimited).string(message.salesReminder);
        /* rd.proto.uuid.UUID primary_location_id = 36; */
        if (message.primaryLocationId)
            UUID.internalBinaryWrite(message.primaryLocationId, writer.tag(36, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Customer.Job job = 37; */
        if (message.job)
            Customer_Job.internalBinaryWrite(message.job, writer.tag(37, WireType.LengthDelimited).fork(), options).join();
        /* bool should_require_job = 39; */
        if (message.shouldRequireJob !== false)
            writer.tag(39, WireType.Varint).bool(message.shouldRequireJob);
        /* bool should_receive_job_statements = 40; */
        if (message.shouldReceiveJobStatements !== false)
            writer.tag(40, WireType.Varint).bool(message.shouldReceiveJobStatements);
        /* repeated rd.proto.uuid.UUID tag_ids = 41; */
        for (let i = 0; i < message.tagIds.length; i++)
            UUID.internalBinaryWrite(message.tagIds[i], writer.tag(41, WireType.LengthDelimited).fork(), options).join();
        /* bool should_require_internal_sale_notes = 42; */
        if (message.shouldRequireInternalSaleNotes !== false)
            writer.tag(42, WireType.Varint).bool(message.shouldRequireInternalSaleNotes);
        /* rd.customer.Customer.StatementInvoicesFormatType statement_invoices_format = 43; */
        if (message.statementInvoicesFormat !== 0)
            writer.tag(43, WireType.Varint).int32(message.statementInvoicesFormat);
        /* rd.customer.Customer.StatementType statement_type = 44; */
        if (message.statementType !== 0)
            writer.tag(44, WireType.Varint).int32(message.statementType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.customer.Customer
 */
export const Customer = new Customer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Customer_Job$Type extends MessageType<Customer_Job> {
    constructor() {
        super("rd.customer.Customer.Job", [
            { no: 1, name: "parent_id", kind: "message", T: () => UUID },
            { no: 4, name: "should_receive_job_statements", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Customer_Job>): Customer_Job {
        const message = { shouldReceiveJobStatements: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Customer_Job>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Customer_Job): Customer_Job {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID parent_id */ 1:
                    message.parentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.parentId);
                    break;
                case /* bool should_receive_job_statements */ 4:
                    message.shouldReceiveJobStatements = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Customer_Job, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID parent_id = 1; */
        if (message.parentId)
            UUID.internalBinaryWrite(message.parentId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool should_receive_job_statements = 4; */
        if (message.shouldReceiveJobStatements !== false)
            writer.tag(4, WireType.Varint).bool(message.shouldReceiveJobStatements);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.customer.Customer.Job
 */
export const Customer_Job = new Customer_Job$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerLog$Type extends MessageType<CustomerLog> {
    constructor() {
        super("rd.customer.CustomerLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "created_by", kind: "message", T: () => User },
            { no: 4, name: "customer", kind: "message", T: () => Customer }
        ]);
    }
    create(value?: PartialMessage<CustomerLog>): CustomerLog {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CustomerLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomerLog): CustomerLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.user.User created_by */ 3:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.customer.Customer customer */ 4:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomerLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 3; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Customer customer = 4; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.customer.CustomerLog
 */
export const CustomerLog = new CustomerLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Contacts$Type extends MessageType<Contacts> {
    constructor() {
        super("rd.customer.Contacts", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "primary_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 5, name: "contacts_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Contacts_Contact } },
            { no: 6, name: "company_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Contacts>): Contacts {
        const message = { primaryIdString: "", contactsById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Contacts>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Contacts): Contacts {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* string primary_id_string */ 2:
                    message.primaryIdString = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 3:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 4:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* map<string, rd.customer.Contacts.Contact> contacts_by_id */ 5:
                    this.binaryReadMap5(message.contactsById, reader, options);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 6:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap5(map: Contacts["contactsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Contacts["contactsById"] | undefined, val: Contacts["contactsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Contacts_Contact.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.customer.Contacts.contacts_by_id");
            }
        }
        map[key ?? ""] = val ?? Contacts_Contact.create();
    }
    internalBinaryWrite(message: Contacts, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string primary_id_string = 2; */
        if (message.primaryIdString !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.primaryIdString);
        /* google.protobuf.Timestamp created_at = 3; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 4; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.customer.Contacts.Contact> contacts_by_id = 5; */
        for (let k of Object.keys(message.contactsById)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Contacts_Contact.internalBinaryWrite(message.contactsById[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.uuid.UUID company_id = 6; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.customer.Contacts
 */
export const Contacts = new Contacts$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Contacts_Contact$Type extends MessageType<Contacts_Contact> {
    constructor() {
        super("rd.customer.Contacts.Contact", [
            { no: 1, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.sanitize.log": true } },
            { no: 5, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.sanitize.log": true } },
            { no: 6, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.sanitize.log": true, "rd.sanitize.cxa": true } },
            { no: 7, name: "should_email_invoices", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "should_email_statements", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "position", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Contacts_Contact>): Contacts_Contact {
        const message = { idString: "", name: "", phone: "", email: "", notes: "", shouldEmailInvoices: false, shouldEmailStatements: false, isActive: false, position: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Contacts_Contact>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Contacts_Contact): Contacts_Contact {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_string */ 1:
                    message.idString = reader.string();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string phone */ 4:
                    message.phone = reader.string();
                    break;
                case /* string email */ 5:
                    message.email = reader.string();
                    break;
                case /* string notes */ 6:
                    message.notes = reader.string();
                    break;
                case /* bool should_email_invoices */ 7:
                    message.shouldEmailInvoices = reader.bool();
                    break;
                case /* bool should_email_statements */ 8:
                    message.shouldEmailStatements = reader.bool();
                    break;
                case /* bool is_active */ 10:
                    message.isActive = reader.bool();
                    break;
                case /* int32 position */ 11:
                    message.position = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Contacts_Contact, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_string = 1; */
        if (message.idString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idString);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string phone = 4; */
        if (message.phone !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.phone);
        /* string email = 5; */
        if (message.email !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.email);
        /* string notes = 6; */
        if (message.notes !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.notes);
        /* bool should_email_invoices = 7; */
        if (message.shouldEmailInvoices !== false)
            writer.tag(7, WireType.Varint).bool(message.shouldEmailInvoices);
        /* bool should_email_statements = 8; */
        if (message.shouldEmailStatements !== false)
            writer.tag(8, WireType.Varint).bool(message.shouldEmailStatements);
        /* bool is_active = 10; */
        if (message.isActive !== false)
            writer.tag(10, WireType.Varint).bool(message.isActive);
        /* int32 position = 11; */
        if (message.position !== 0)
            writer.tag(11, WireType.Varint).int32(message.position);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.customer.Contacts.Contact
 */
export const Contacts_Contact = new Contacts_Contact$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JobSite$Type extends MessageType<JobSite> {
    constructor() {
        super("rd.customer.JobSite", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "customer_id", kind: "message", T: () => UUID },
            { no: 5, name: "address", kind: "message", T: () => Address },
            { no: 6, name: "company_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<JobSite>): JobSite {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<JobSite>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JobSite): JobSite {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 4:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.proto.address.Address address */ 5:
                    message.address = Address.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 6:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JobSite, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_id = 4; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.address.Address address = 5; */
        if (message.address)
            Address.internalBinaryWrite(message.address, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 6; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.customer.JobSite
 */
export const JobSite = new JobSite$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PurchaseOrder$Type extends MessageType<PurchaseOrder> {
    constructor() {
        super("rd.customer.PurchaseOrder", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "customer_id", kind: "message", T: () => UUID },
            { no: 5, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "company_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<PurchaseOrder>): PurchaseOrder {
        const message = { name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PurchaseOrder>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PurchaseOrder): PurchaseOrder {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 4:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* string name */ 5:
                    message.name = reader.string();
                    break;
                case /* rd.proto.uuid.UUID company_id */ 6:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PurchaseOrder, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_id = 4; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string name = 5; */
        if (message.name !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.name);
        /* rd.proto.uuid.UUID company_id = 6; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.customer.PurchaseOrder
 */
export const PurchaseOrder = new PurchaseOrder$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Interaction$Type extends MessageType<Interaction> {
    constructor() {
        super("rd.customer.Interaction", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "created_by", kind: "message", T: () => User },
            { no: 4, name: "customer_id", kind: "message", T: () => UUID },
            { no: 5, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "company_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Interaction>): Interaction {
        const message = { notes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Interaction>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Interaction): Interaction {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.user.User created_by */ 3:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 4:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* string notes */ 5:
                    message.notes = reader.string();
                    break;
                case /* rd.proto.uuid.UUID company_id */ 6:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Interaction, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 3; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_id = 4; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string notes = 5; */
        if (message.notes !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.notes);
        /* rd.proto.uuid.UUID company_id = 6; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.customer.Interaction
 */
export const Interaction = new Interaction$Type();
