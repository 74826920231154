/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "search/service/service.proto" (package "rd.search.service", syntax proto3)
// tslint:disable
// @ts-nocheck
import { GetCustomerAgingCSVRes } from "../../api/analytics/service_pb";
import { GetCustomerAgingCSVReq } from "../../api/analytics/service_pb";
import { GetCustomerAgingPDFRes } from "../../api/analytics/service_pb";
import { GetCustomerAgingPDFReq } from "../../api/analytics/service_pb";
import { GetCustomerAgingRes } from "../../api/analytics/service_pb";
import { GetCustomerAgingReq } from "../../api/analytics/service_pb";
import { GetSalesByDayCSVRes } from "../../api/analytics/service_pb";
import { GetSalesByDayCSVReq } from "../../api/analytics/service_pb";
import { GetSalesByDayPDFRes } from "../../api/analytics/service_pb";
import { GetSalesByDayPDFReq } from "../../api/analytics/service_pb";
import { GetSalesByDayRes } from "../../api/analytics/service_pb";
import { GetSalesByDayReq } from "../../api/analytics/service_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Report as Report$ } from "../../analyticsv2/reportbuilder/models_pb";
import { ReportParams } from "../../analyticsv2/reportbuilder/models_pb";
import { InteractionsSortBy } from "../../analyticsv2/models_pb";
import { InteractionsByStaffSortBy } from "../../analyticsv2/models_pb";
import { TransferDiscrepanciesSortBy } from "../../analyticsv2/models_pb";
import { InventoryByProductSortBy } from "../../analyticsv2/models_pb";
import { InventoryByDepartmentSortBy } from "../../analyticsv2/models_pb";
import { JobAgingSortBy } from "../../analyticsv2/models_pb";
import { AdditionalFeesSortBy } from "../../analyticsv2/models_pb";
import { AccountPaymentAdjustmentsAdjustmentType } from "../../analyticsv2/models_pb";
import { AccountPaymentAdjustmentsSortBy } from "../../analyticsv2/models_pb";
import { PurchaseJournalSortBy } from "../../analyticsv2/models_pb";
import { AgingDetailStatus } from "../../analyticsv2/models_pb";
import { AgingDetailTransactionType } from "../../analyticsv2/models_pb";
import { AgingDetailSortBy } from "../../analyticsv2/models_pb";
import { NewAccountsOpenedSortBy } from "../../analyticsv2/models_pb";
import { ProfitAndLoss } from "../../analyticsv2/models_pb";
import { PriceEditsSortBy } from "../../analyticsv2/models_pb";
import { SalesByRepSortBy } from "../../analyticsv2/models_pb";
import { SalesByLocationSortBy } from "../../analyticsv2/models_pb";
import { SalesByProductSortBy } from "../../analyticsv2/models_pb";
import { SalesByJobSortBy } from "../../analyticsv2/models_pb";
import { SalesByCustomerSortBy } from "../../analyticsv2/models_pb";
import { SoldProductsSortBy } from "../../analyticsv2/models_pb";
import { PaymentsByDayTransactionType } from "../../analyticsv2/models_pb";
import { PaymentsByDayMethod } from "../../analyticsv2/models_pb";
import { PaymentsByDaySortBy } from "../../analyticsv2/models_pb";
import { SalesPurchaseMethod } from "../../analyticsv2/models_pb";
import { SalesTransactionType } from "../../analyticsv2/models_pb";
import { SalesSortBy } from "../../analyticsv2/models_pb";
import { Report } from "../../analytics/models_pb";
import { CashRegisterTransactionType } from "../../analyticsv2/models_pb";
import { Filters } from "../../analyticsv2/models_pb";
import { SortOrder as SortOrder$ } from "../../analyticsv2/models_pb";
import { CashRegisterSortBy } from "../../analyticsv2/models_pb";
import { Tag_Color } from "../../tag/models_pb";
import { Tag_Type } from "../../tag/models_pb";
import { TierRule } from "../../price/models_pb";
import { Custom } from "../../price/models_pb";
import { InventoryChange } from "../../product/models_pb";
import { ExternalTransaction } from "../../accounting/models_pb";
import { ExternalTransaction_PaymentMethod } from "../../accounting/models_pb";
import { ExternalTransaction_Type } from "../../accounting/models_pb";
import { InventoryCountProduct_Status } from "../../product/models_pb";
import { Account } from "../../coa/models_pb";
import { FinancialTransaction } from "../../payments/models_pb";
import { FinancialTransaction_Status } from "../../payments/models_pb";
import { FinancialTransaction_Type } from "../../payments/models_pb";
import { CloseOut } from "../../txn/models_pb";
import { PettyCash } from "../../txn/models_pb";
import { Update } from "../cache/models_pb";
import { Transfer } from "../../txn/models_pb";
import { Transfer_Status } from "../../txn/models_pb";
import { Timestamp } from "../../google/protobuf/timestamp_pb";
import { Sale_Return } from "../../txn/models_pb";
import { SaleProduct } from "../../txn/models_pb";
import { PurchaseMethod } from "../../txn/models_pb";
import { TintColor } from "../../tintcolor/models_pb";
import { Sale_Status } from "../../txn/models_pb";
import { LabelTemplate } from "../../product/models_pb";
import { Tier } from "../../price/models_pb";
import { ProductCategory } from "../../company/models_pb";
import { Product as Product$ } from "../../catalog/models_pb";
import { ProductSalesAnalytics } from "../../txn/models_pb";
import { AdditionalFees_Fee } from "../../company/models_pb";
import { Inventories } from "../../product/models_pb";
import { PricingTiers } from "../../company/models_pb";
import { Product_TaxStatus } from "../../product/models_pb";
import { OrderShipment_Status } from "../../txn/models_pb";
import { OrderShipment } from "../../txn/models_pb";
import { Product } from "../../product/models_pb";
import { Vendor } from "../../vendors/models_pb";
import { Order } from "../../txn/models_pb";
import { Order_Status } from "../../txn/models_pb";
import { Contacts_Contact } from "../../customer/models_pb";
import { Tag } from "../../tag/models_pb";
import { FinanceChargeSetting } from "../../company/models_pb";
import { CustomerSalesAnalytics } from "../../txn/models_pb";
import { Staff } from "../../company/models_pb";
import { Interaction } from "../../customer/models_pb";
import { CustomerBalance } from "../../txn/models_pb";
import { PricingTiers_Tier } from "../../company/models_pb";
import { FinancingTerms_Term } from "../../company/models_pb";
import { Contacts } from "../../customer/models_pb";
import { Card } from "../../payments/models_pb";
import { Customer } from "../../customer/models_pb";
import { Location } from "../../company/models_pb";
import { PurchaseOrder } from "../../customer/models_pb";
import { Money } from "../../proto/money/models_pb";
import { Sale } from "../../txn/models_pb";
import { LedgerEntry } from "../../ledger/models_pb";
import { BillPayment } from "../../txn/models_pb";
import { LedgerEntry_Type } from "../../ledger/models_pb";
import { PurchaseMethod_Method } from "../../txn/models_pb";
import { UUID } from "../../proto/uuid/models_pb";
import { TimestampRange } from "../../proto/timestamprange/models_pb";
/**
 * @generated from protobuf message rd.search.service.SearchBillPaymentsReq
 */
export interface SearchBillPaymentsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange created_at_range = 2;
     */
    createdAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID customer_ids = 3;
     */
    customerIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID created_by_ids = 4;
     */
    createdByIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID location_ids = 5;
     */
    locationIds: UUID[];
    /**
     * @generated from protobuf field: string cursor = 6;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 7;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 8;
     */
    limit: number;
    /**
     * @generated from protobuf field: repeated rd.txn.PurchaseMethod.Method purchase_methods = 9;
     */
    purchaseMethods: PurchaseMethod_Method[];
    /**
     * @generated from protobuf field: repeated rd.ledger.LedgerEntry.Type bill_payment_types = 12;
     */
    billPaymentTypes: LedgerEntry_Type[];
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchBillPaymentsReq.CreditFilter credit_filters = 11;
     */
    creditFilters: SearchBillPaymentsReq_CreditFilter[];
}
/**
 * @generated from protobuf enum rd.search.service.SearchBillPaymentsReq.CreditFilter
 */
export enum SearchBillPaymentsReq_CreditFilter {
    /**
     * @generated from protobuf enum value: NO_CREDIT = 0;
     */
    NO_CREDIT = 0,
    /**
     * @generated from protobuf enum value: ADDS_CREDIT = 1;
     */
    ADDS_CREDIT = 1,
    /**
     * @generated from protobuf enum value: USES_CREDIT = 2;
     */
    USES_CREDIT = 2
}
/**
 * @generated from protobuf message rd.search.service.SearchBillPaymentsRes
 */
export interface SearchBillPaymentsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchBillPaymentsRes.Result results = 1;
     */
    results: SearchBillPaymentsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchBillPaymentsRes.Result
 */
export interface SearchBillPaymentsRes_Result {
    /**
     * @generated from protobuf field: rd.txn.BillPayment bill_payment = 1;
     */
    billPayment?: BillPayment;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry payment_ledger_entry = 2;
     */
    paymentLedgerEntry?: LedgerEntry;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry write_off_ledger_entry = 3;
     */
    writeOffLedgerEntry?: LedgerEntry;
    /**
     * @generated from protobuf field: map<string, rd.txn.Sale> sales_by_id = 4;
     */
    salesById: {
        [key: string]: Sale;
    };
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> allocations_by_sale_id = 5;
     */
    allocationsBySaleId: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> totals_by_sale_id = 6;
     */
    totalsBySaleId: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: map<string, rd.customer.PurchaseOrder> purchase_orders_by_sale_id = 7;
     */
    purchaseOrdersBySaleId: {
        [key: string]: PurchaseOrder;
    };
    /**
     * @generated from protobuf field: map<string, rd.company.Location> locations_by_sale_id = 9;
     */
    locationsBySaleId: {
        [key: string]: Location;
    };
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 10;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.payments.Card payment_card = 11;
     */
    paymentCard?: Card;
    /**
     * @generated from protobuf field: string payment_method = 12;
     */
    paymentMethod: string;
    /**
     * @generated from protobuf field: map<string, rd.customer.Customer> customers_by_sale_id = 13;
     */
    customersBySaleId: {
        [key: string]: Customer;
    };
}
/**
 * @generated from protobuf message rd.search.service.SearchRefundedBillPaymentsReq
 */
export interface SearchRefundedBillPaymentsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange created_at_range = 2;
     */
    createdAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID customer_ids = 3;
     */
    customerIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID created_by_ids = 4;
     */
    createdByIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID location_ids = 5;
     */
    locationIds: UUID[];
    /**
     * @generated from protobuf field: string cursor = 6;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 7;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 8;
     */
    limit: number;
}
/**
 * @generated from protobuf message rd.search.service.SearchRefundedBillPaymentsRes
 */
export interface SearchRefundedBillPaymentsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchRefundedBillPaymentsRes.Result results = 1;
     */
    results: SearchRefundedBillPaymentsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchRefundedBillPaymentsRes.Result
 */
export interface SearchRefundedBillPaymentsRes_Result {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID payment_ledger_entry_id = 1;
     */
    paymentLedgerEntryId?: UUID;
    /**
     * @generated from protobuf field: rd.txn.BillPayment bill_payment = 2;
     */
    billPayment?: BillPayment;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry payment_ledger_entry = 3;
     */
    paymentLedgerEntry?: LedgerEntry;
    /**
     * @generated from protobuf field: map<string, rd.txn.Sale> sales_by_id = 4;
     */
    salesById: {
        [key: string]: Sale;
    };
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> allocations_by_sale_id = 5;
     */
    allocationsBySaleId: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> totals_by_sale_id = 6;
     */
    totalsBySaleId: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: map<string, rd.customer.PurchaseOrder> purchase_orders_by_sale_id = 7;
     */
    purchaseOrdersBySaleId: {
        [key: string]: PurchaseOrder;
    };
    /**
     * @generated from protobuf field: map<string, rd.company.Location> locations_by_sale_id = 9;
     */
    locationsBySaleId: {
        [key: string]: Location;
    };
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 10;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.payments.Card payment_card = 11;
     */
    paymentCard?: Card;
    /**
     * @generated from protobuf field: string payment_method = 12;
     */
    paymentMethod: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchCustomersV2Req
 */
export interface SearchCustomersV2Req {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: repeated string financing_term_id_strings = 3;
     */
    financingTermIdStrings: string[];
    /**
     * @generated from protobuf field: repeated string pricing_tier_id_strings = 4;
     */
    pricingTierIdStrings: string[];
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_on_credit_hold_filter = 5;
     */
    isOnCreditHoldFilter: BoolFilter;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID rep_staff_ids = 6;
     */
    repStaffIds: UUID[];
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_active_filter = 7;
     */
    isActiveFilter: BoolFilter;
    /**
     * @generated from protobuf field: rd.search.service.SortOrder sort_order = 9;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: string cursor = 10;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 11;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 12;
     */
    limit: number;
    /**
     * @generated from protobuf field: rd.search.service.OrderCustomersBy order_by = 13;
     */
    orderBy: OrderCustomersBy;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID finance_charge_settings_ids = 14;
     */
    financeChargeSettingsIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID tag_ids = 15;
     */
    tagIds: UUID[];
}
/**
 * @generated from protobuf message rd.search.service.SearchCustomersV2Res
 */
export interface SearchCustomersV2Res {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchCustomersV2Res.Result results = 1;
     */
    results: SearchCustomersV2Res_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchCustomersV2Res.Result
 */
export interface SearchCustomersV2Res_Result {
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 1;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.customer.Contacts contacts = 2;
     */
    contacts?: Contacts;
    /**
     * @generated from protobuf field: rd.company.FinancingTerms.Term financing_term = 4;
     */
    financingTerm?: FinancingTerms_Term;
    /**
     * @generated from protobuf field: rd.company.PricingTiers.Tier pricing_tier = 5;
     */
    pricingTier?: PricingTiers_Tier;
    /**
     * @generated from protobuf field: rd.txn.CustomerBalance balance = 6;
     */
    balance?: CustomerBalance;
    /**
     * @generated from protobuf field: rd.proto.money.Money total_balance = 7;
     */
    totalBalance?: Money;
    /**
     * @generated from protobuf field: string financing_term_name = 8;
     */
    financingTermName: string;
    /**
     * @generated from protobuf field: rd.customer.Interaction last_interaction = 10;
     */
    lastInteraction?: Interaction;
    /**
     * @generated from protobuf field: rd.company.Staff rep_staff = 11;
     */
    repStaff?: Staff;
    /**
     * @generated from protobuf field: rd.txn.CustomerSalesAnalytics analytics = 13;
     */
    analytics?: CustomerSalesAnalytics;
    /**
     * @generated from protobuf field: rd.company.FinanceChargeSetting finance_charge_setting = 14;
     */
    financeChargeSetting?: FinanceChargeSetting;
    /**
     * @generated from protobuf field: repeated rd.tag.Tag tags = 15;
     */
    tags: Tag[];
    /**
     * @generated from protobuf field: rd.company.Location primary_location = 16;
     */
    primaryLocation?: Location;
}
/**
 * @generated from protobuf message rd.search.service.BulkExportCustomersReq
 */
export interface BulkExportCustomersReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: repeated string financing_term_id_strings = 3;
     */
    financingTermIdStrings: string[];
    /**
     * @generated from protobuf field: repeated string pricing_tier_id_strings = 4;
     */
    pricingTierIdStrings: string[];
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_on_credit_hold_filter = 5;
     */
    isOnCreditHoldFilter: BoolFilter;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID rep_staff_ids = 6;
     */
    repStaffIds: UUID[];
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_active_filter = 7;
     */
    isActiveFilter: BoolFilter;
    /**
     * @generated from protobuf field: rd.search.service.OrderCustomersBy order_by = 8;
     */
    orderBy: OrderCustomersBy;
    /**
     * @generated from protobuf field: rd.search.service.SortOrder sort_order = 9;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: bool is_template = 10;
     */
    isTemplate: boolean;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID finance_charge_setting_ids = 11;
     */
    financeChargeSettingIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID tag_ids = 12;
     */
    tagIds: UUID[];
}
/**
 * @generated from protobuf message rd.search.service.BulkExportCustomersRes
 */
export interface BulkExportCustomersRes {
    /**
     * @generated from protobuf field: bytes excel_bytes = 1;
     */
    excelBytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.SearchCustomerContactsReq
 */
export interface SearchCustomerContactsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID customer_ids = 2;
     */
    customerIds: UUID[];
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter should_email_invoices_filter = 3;
     */
    shouldEmailInvoicesFilter: BoolFilter;
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter should_email_statements_filter = 4;
     */
    shouldEmailStatementsFilter: BoolFilter;
    /**
     * @generated from protobuf field: rd.search.service.OrderCustomerContactsBy order_by = 5;
     */
    orderBy: OrderCustomerContactsBy;
    /**
     * @generated from protobuf field: rd.search.service.SortOrder sort_order = 6;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: string cursor = 7;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 8;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 9;
     */
    limit: number;
}
/**
 * @generated from protobuf message rd.search.service.SearchCustomerContactsRes
 */
export interface SearchCustomerContactsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchCustomerContactsRes.Result results = 1;
     */
    results: SearchCustomerContactsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchCustomerContactsRes.Result
 */
export interface SearchCustomerContactsRes_Result {
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 1;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.customer.Contacts.Contact contact = 2;
     */
    contact?: Contacts_Contact;
}
/**
 * @generated from protobuf message rd.search.service.BulkExportCustomerContactsReq
 */
export interface BulkExportCustomerContactsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID customer_ids = 2;
     */
    customerIds: UUID[];
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter should_email_invoices_filter = 3;
     */
    shouldEmailInvoicesFilter: BoolFilter;
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter should_email_statements_filter = 4;
     */
    shouldEmailStatementsFilter: BoolFilter;
    /**
     * @generated from protobuf field: rd.search.service.OrderCustomerContactsBy order_by = 5;
     */
    orderBy: OrderCustomerContactsBy;
    /**
     * @generated from protobuf field: rd.search.service.SortOrder sort_order = 6;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: bool is_template = 7;
     */
    isTemplate: boolean;
}
/**
 * @generated from protobuf message rd.search.service.BulkExportCustomerContactsRes
 */
export interface BulkExportCustomerContactsRes {
    /**
     * @generated from protobuf field: bytes excel_bytes = 1;
     */
    excelBytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.SearchOrdersReq
 */
export interface SearchOrdersReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange created_at_range = 2;
     */
    createdAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange ordered_at_range = 3;
     */
    orderedAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange received_at_range = 4;
     */
    receivedAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange voided_at_range = 5;
     */
    voidedAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID location_ids = 6;
     */
    locationIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.txn.Order.Status statuses = 7;
     */
    statuses: Order_Status[];
    /**
     * @generated from protobuf field: string cursor = 8;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 9;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 10;
     */
    limit: number;
}
/**
 * @generated from protobuf message rd.search.service.SearchOrdersRes
 */
export interface SearchOrdersRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchOrdersRes.Result results = 1;
     */
    results: SearchOrdersRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchOrdersRes.Result
 */
export interface SearchOrdersRes_Result {
    /**
     * @generated from protobuf field: rd.txn.Order order = 1;
     */
    order?: Order;
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 2;
     */
    vendor?: Vendor;
    /**
     * @generated from protobuf field: rd.company.Location location = 3;
     */
    location?: Location;
    /**
     * @generated from protobuf field: map<string, rd.product.Product> products_by_id = 4;
     */
    productsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: map<string, rd.product.Product> matched_products_by_id = 5;
     */
    matchedProductsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 6;
     */
    total?: Money;
    /**
     * @generated from protobuf field: repeated rd.txn.OrderShipment order_shipments = 7;
     */
    orderShipments: OrderShipment[];
}
/**
 * @generated from protobuf message rd.search.service.SearchOrderShipmentsReq
 */
export interface SearchOrderShipmentsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID location_ids = 3;
     */
    locationIds: UUID[];
    /**
     * @generated from protobuf field: string cursor = 4;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 5;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 6;
     */
    limit: number;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange timestamp_range = 11;
     */
    timestampRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.txn.OrderShipment.Status status = 12;
     */
    status: OrderShipment_Status;
}
/**
 * @generated from protobuf message rd.search.service.SearchOrderShipmentsRes
 */
export interface SearchOrderShipmentsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchOrderShipmentsRes.Result results = 1;
     */
    results: SearchOrderShipmentsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchOrderShipmentsRes.Result
 */
export interface SearchOrderShipmentsRes_Result {
    /**
     * @generated from protobuf field: rd.txn.OrderShipment order_shipment = 1;
     */
    orderShipment?: OrderShipment;
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 2;
     */
    vendor?: Vendor;
    /**
     * @generated from protobuf field: rd.company.Location location = 3;
     */
    location?: Location;
    /**
     * @generated from protobuf field: map<string, rd.product.Product> products_by_id = 4;
     */
    productsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: map<string, rd.product.Product> matched_products_by_id = 5;
     */
    matchedProductsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 6;
     */
    total?: Money;
}
/**
 * @generated from protobuf message rd.search.service.SearchProductsV2Req
 */
export interface SearchProductsV2Req {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID inv_sort_location_id = 2;
     */
    invSortLocationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID current_location_id = 3;
     */
    currentLocationId?: UUID;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID vendor_ids = 5;
     */
    vendorIds: UUID[];
    /**
     * @generated from protobuf field: repeated string additional_fee_id_strings = 6;
     */
    additionalFeeIdStrings: string[];
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_active_filter = 7;
     */
    isActiveFilter: BoolFilter;
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_tintable_filter = 9;
     */
    isTintableFilter: BoolFilter;
    /**
     * @generated from protobuf field: string cursor = 10;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 11;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 12;
     */
    limit: number;
    /**
     * @generated from protobuf field: rd.search.service.SearchProductsV2Req.OrderBy order_by = 13;
     */
    orderBy: SearchProductsV2Req_OrderBy;
    /**
     * @generated from protobuf field: rd.search.service.SortOrder sort_order = 14;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: repeated rd.product.Product.TaxStatus tax_statuses = 15;
     */
    taxStatuses: Product_TaxStatus[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID department_ids = 16;
     */
    departmentIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID class_ids = 17;
     */
    classIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID fineline_ids = 18;
     */
    finelineIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID tag_ids = 19;
     */
    tagIds: UUID[];
}
/**
 * @generated from protobuf enum rd.search.service.SearchProductsV2Req.OrderBy
 */
export enum SearchProductsV2Req_OrderBy {
    /**
     * @generated from protobuf enum value: ORDER_BY_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: ORDER_BY_NAME = 1;
     */
    NAME = 1,
    /**
     * @generated from protobuf enum value: ORDER_BY_INVENTORY_LOCATION_ID = 2;
     */
    INVENTORY_LOCATION_ID = 2,
    /**
     * @generated from protobuf enum value: ORDER_BY_TARGET = 3;
     */
    TARGET = 3,
    /**
     * @generated from protobuf enum value: ORDER_BY_CURRENT = 4;
     */
    CURRENT = 4,
    /**
     * @generated from protobuf enum value: ORDER_BY_REORDER = 5;
     */
    REORDER = 5,
    /**
     * @generated from protobuf enum value: ORDER_BY_DELTA = 6;
     */
    DELTA = 6,
    /**
     * @generated from protobuf enum value: ORDER_BY_UNITS_SOLD_YTD = 7;
     */
    UNITS_SOLD_YTD = 7,
    /**
     * @generated from protobuf enum value: ORDER_BY_UNITS_VS_LAST_YEAR = 8;
     */
    UNITS_VS_LAST_YEAR = 8,
    /**
     * @generated from protobuf enum value: ORDER_BY_MARGIN = 9;
     */
    MARGIN = 9,
    /**
     * @generated from protobuf enum value: ORDER_BY_UNITS_LAST_12MO = 10;
     */
    UNITS_LAST_12MO = 10,
    /**
     * @generated from protobuf enum value: ORDER_BY_UNITS_LAST_3MO = 11;
     */
    UNITS_LAST_3MO = 11,
    /**
     * @generated from protobuf enum value: ORDER_BY_UNITS_LAST_1MO = 12;
     */
    UNITS_LAST_1MO = 12,
    /**
     * @generated from protobuf enum value: ORDER_BY_LAST_SALE = 13;
     */
    LAST_SALE = 13,
    /**
     * @generated from protobuf enum value: ORDER_BY_TURNS = 14;
     */
    TURNS = 14
}
/**
 * @generated from protobuf message rd.search.service.SearchProductsV2Res
 */
export interface SearchProductsV2Res {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchProductsV2Res.Result results = 1;
     */
    results: SearchProductsV2Res_Result[];
    /**
     * @generated from protobuf field: map<string, rd.company.Location> locations_by_id = 2;
     */
    locationsById: {
        [key: string]: Location;
    };
    /**
     * @generated from protobuf field: rd.company.PricingTiers pricing_tiers = 3;
     */
    pricingTiers?: PricingTiers;
    /**
     * @generated from protobuf field: string cursor = 4;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchProductsV2Res.Result
 */
export interface SearchProductsV2Res_Result {
    /**
     * @generated from protobuf field: rd.product.Product product = 1;
     */
    product?: Product;
    /**
     * @generated from protobuf field: rd.product.Product matched_price_product = 2;
     */
    matchedPriceProduct?: Product;
    /**
     * @generated from protobuf field: rd.product.Product matched_cost_product = 3;
     */
    matchedCostProduct?: Product;
    /**
     * @generated from protobuf field: rd.product.Inventories inventories = 4;
     */
    inventories?: Inventories;
    /**
     * @generated from protobuf field: rd.vendors.Vendor primary_cost_vendor = 5;
     */
    primaryCostVendor?: Vendor;
    /**
     * @generated from protobuf field: rd.company.AdditionalFees.Fee additional_fee = 7;
     */
    additionalFee?: AdditionalFees_Fee;
    /**
     * @generated from protobuf field: int32 delta = 9;
     */
    delta: number;
    /**
     * @generated from protobuf field: rd.txn.ProductSalesAnalytics analytics = 10;
     */
    analytics?: ProductSalesAnalytics;
    /**
     * @generated from protobuf field: rd.catalog.Product catalog_product = 11;
     */
    catalogProduct?: Product$;
    /**
     * @generated from protobuf field: rd.company.ProductCategory department = 12;
     */
    department?: ProductCategory;
    /**
     * @generated from protobuf field: rd.company.ProductCategory class = 13;
     */
    class?: ProductCategory;
    /**
     * @generated from protobuf field: rd.company.ProductCategory fineline = 14;
     */
    fineline?: ProductCategory;
    /**
     * @generated from protobuf field: repeated rd.price.Tier tier_prices = 15;
     */
    tierPrices: Tier[];
    /**
     * @generated from protobuf field: repeated rd.tag.Tag tags = 16;
     */
    tags: Tag[];
}
/**
 * @generated from protobuf message rd.search.service.BulkExportProductsReq
 */
export interface BulkExportProductsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID inv_sort_location_id = 2;
     */
    invSortLocationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID current_location_id = 3;
     */
    currentLocationId?: UUID;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID vendor_ids = 5;
     */
    vendorIds: UUID[];
    /**
     * @generated from protobuf field: repeated string additional_fee_id_strings = 6;
     */
    additionalFeeIdStrings: string[];
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_active_filter = 7;
     */
    isActiveFilter: BoolFilter;
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_tintable_filter = 8;
     */
    isTintableFilter: BoolFilter;
    /**
     * @generated from protobuf field: rd.search.service.SearchProductOrderBy order_by = 9;
     */
    orderBy: SearchProductOrderBy;
    /**
     * @generated from protobuf field: rd.search.service.SortOrder sort_order = 10;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: repeated rd.product.Product.TaxStatus tax_statuses = 11;
     */
    taxStatuses: Product_TaxStatus[];
    /**
     * @generated from protobuf field: bool is_template = 16;
     */
    isTemplate: boolean;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID department_ids = 17;
     */
    departmentIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID class_ids = 18;
     */
    classIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID fineline_ids = 19;
     */
    finelineIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID product_ids = 20;
     */
    productIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID tag_ids = 21;
     */
    tagIds: UUID[];
}
/**
 * @generated from protobuf message rd.search.service.BulkExportProductsRes
 */
export interface BulkExportProductsRes {
    /**
     * @generated from protobuf field: bytes excel_bytes = 1;
     */
    excelBytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.BulkExportProductLabelsReq
 */
export interface BulkExportProductLabelsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID inv_sort_location_id = 2;
     */
    invSortLocationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID current_location_id = 3;
     */
    currentLocationId?: UUID;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID vendor_ids = 4;
     */
    vendorIds: UUID[];
    /**
     * @generated from protobuf field: repeated string additional_fee_id_strings = 5;
     */
    additionalFeeIdStrings: string[];
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_active_filter = 6;
     */
    isActiveFilter: BoolFilter;
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_tintable_filter = 7;
     */
    isTintableFilter: BoolFilter;
    /**
     * @generated from protobuf field: rd.search.service.SearchProductOrderBy order_by = 8;
     */
    orderBy: SearchProductOrderBy;
    /**
     * @generated from protobuf field: rd.search.service.SortOrder sort_order = 9;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: repeated rd.product.Product.TaxStatus tax_statuses = 10;
     */
    taxStatuses: Product_TaxStatus[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID department_ids = 11;
     */
    departmentIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID class_ids = 12;
     */
    classIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID fineline_ids = 13;
     */
    finelineIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID tag_ids = 14;
     */
    tagIds: UUID[];
    /**
     * @generated from protobuf field: rd.product.LabelTemplate template = 15;
     */
    template: LabelTemplate;
    /**
     * @generated from protobuf field: int32 rotation_degrees = 16;
     */
    rotationDegrees: number;
}
/**
 * @generated from protobuf message rd.search.service.BulkExportProductLabelsRes
 */
export interface BulkExportProductLabelsRes {
    /**
     * @generated from protobuf field: bytes pdf_bytes = 1;
     */
    pdfBytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.SearchSalesV2Req
 */
export interface SearchSalesV2Req {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange created_at_range = 2;
     */
    createdAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange quoted_at_range = 3;
     */
    quotedAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange will_called_at_range = 4;
     */
    willCalledAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange sold_at_range = 5;
     */
    soldAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange voided_at_range = 6;
     */
    voidedAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID created_by_ids = 7;
     */
    createdByIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID quoted_by_ids = 8;
     */
    quotedByIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID will_called_by_ids = 9;
     */
    willCalledByIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID sold_by_ids = 10;
     */
    soldByIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID voided_by_ids = 11;
     */
    voidedByIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID customer_ids = 12;
     */
    customerIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID location_ids = 13;
     */
    locationIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.txn.Sale.Status statuses = 14;
     */
    statuses: Sale_Status[];
    /**
     * @generated from protobuf field: string cursor = 15;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 16;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 17;
     */
    limit: number;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID purchase_order_ids = 18;
     */
    purchaseOrderIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID tint_color_ids = 19;
     */
    tintColorIds: UUID[];
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter contains_non_taxable_products_filter = 20;
     */
    containsNonTaxableProductsFilter: BoolFilter;
    /**
     * @generated from protobuf field: rd.txn.PurchaseMethod.Method purchase_method = 21;
     */
    purchaseMethod: PurchaseMethod_Method;
}
/**
 * @generated from protobuf message rd.search.service.SearchSalesV2Res
 */
export interface SearchSalesV2Res {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchSalesV2Res.Result results = 1;
     */
    results: SearchSalesV2Res_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchSalesV2Res.Result
 */
export interface SearchSalesV2Res_Result {
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 1;
     */
    sale?: Sale;
    /**
     * @generated from protobuf field: rd.company.Location location = 2;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 3;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.customer.PurchaseOrder purchase_order = 4;
     */
    purchaseOrder?: PurchaseOrder;
    /**
     * @generated from protobuf field: map<string, rd.product.Product> products_by_id = 6;
     */
    productsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: map<string, rd.product.Product> matched_products_by_id = 7;
     */
    matchedProductsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: map<string, rd.tintcolor.TintColor> tint_colors_by_id = 8;
     */
    tintColorsById: {
        [key: string]: TintColor;
    };
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 9;
     */
    total?: Money;
    /**
     * @generated from protobuf field: rd.customer.Contacts.Contact customer_contact = 10;
     */
    customerContact?: Contacts_Contact;
    /**
     * @generated from protobuf field: repeated rd.txn.PurchaseMethod purchase_methods = 11;
     */
    purchaseMethods: PurchaseMethod[];
    /**
     * @generated from protobuf field: repeated rd.txn.SaleProduct sale_products = 12;
     */
    saleProducts: SaleProduct[];
}
/**
 * @generated from protobuf message rd.search.service.SearchReturnsReq
 */
export interface SearchReturnsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange created_at_range = 2;
     */
    createdAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID created_by_ids = 3;
     */
    createdByIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID customer_ids = 4;
     */
    customerIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID location_ids = 5;
     */
    locationIds: UUID[];
    /**
     * @generated from protobuf field: string cursor = 15;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 16;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 17;
     */
    limit: number;
}
/**
 * @generated from protobuf message rd.search.service.SearchReturnsRes
 */
export interface SearchReturnsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchReturnsRes.Result results = 1;
     */
    results: SearchReturnsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchReturnsRes.Result
 */
export interface SearchReturnsRes_Result {
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry return_ledger_entry = 15;
     */
    returnLedgerEntry?: LedgerEntry;
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 2;
     */
    sale?: Sale;
    /**
     * @generated from protobuf field: rd.company.Location location = 3;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 4;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.customer.PurchaseOrder purchase_order = 5;
     */
    purchaseOrder?: PurchaseOrder;
    /**
     * @generated from protobuf field: map<string, rd.product.Product> products_by_id = 7;
     */
    productsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: map<string, rd.product.Product> matched_products_by_id = 8;
     */
    matchedProductsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: map<string, rd.tintcolor.TintColor> tint_colors_by_id = 9;
     */
    tintColorsById: {
        [key: string]: TintColor;
    };
    /**
     * @generated from protobuf field: rd.customer.Contacts.Contact customer_contact = 10;
     */
    customerContact?: Contacts_Contact;
    /**
     * @generated from protobuf field: rd.txn.Sale.Return sale_return = 13;
     */
    saleReturn?: Sale_Return;
    /**
     * @generated from protobuf field: string return_method = 14;
     */
    returnMethod: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchOutstandingSalesReq
 */
export interface SearchOutstandingSalesReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 2;
     */
    customerId?: UUID;
}
/**
 * @generated from protobuf message rd.search.service.SearchOutstandingSalesRes
 */
export interface SearchOutstandingSalesRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchOutstandingSalesRes.Result results = 1;
     */
    results: SearchOutstandingSalesRes_Result[];
}
/**
 * @generated from protobuf message rd.search.service.SearchOutstandingSalesRes.Result
 */
export interface SearchOutstandingSalesRes_Result {
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 1;
     */
    sale?: Sale;
    /**
     * @generated from protobuf field: rd.proto.money.Money sale_balance = 2;
     */
    saleBalance?: Money;
    /**
     * @generated from protobuf field: rd.company.Location location = 3;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 4;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.customer.PurchaseOrder purchase_order = 5;
     */
    purchaseOrder?: PurchaseOrder;
    /**
     * @generated from protobuf field: rd.customer.Contacts.Contact customer_contact = 7;
     */
    customerContact?: Contacts_Contact;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp potential_discount_expires_at = 8;
     */
    potentialDiscountExpiresAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.money.Money discount_amount = 9;
     */
    discountAmount?: Money;
}
/**
 * @generated from protobuf message rd.search.service.SearchTintColorsReq
 */
export interface SearchTintColorsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchTintColorsRes
 */
export interface SearchTintColorsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchTintColorsRes.Result results = 2;
     */
    results: SearchTintColorsRes_Result[];
}
/**
 * @generated from protobuf message rd.search.service.SearchTintColorsRes.Result
 */
export interface SearchTintColorsRes_Result {
    /**
     * @generated from protobuf field: rd.tintcolor.TintColor tint_color = 1;
     */
    tintColor?: TintColor;
}
/**
 * @generated from protobuf message rd.search.service.SearchTransfersReq
 */
export interface SearchTransfersReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange created_at_range = 2;
     */
    createdAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange sent_at_range = 3;
     */
    sentAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange received_at_range = 4;
     */
    receivedAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange voided_at_range = 5;
     */
    voidedAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID from_location_ids = 6;
     */
    fromLocationIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID to_location_ids = 7;
     */
    toLocationIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.txn.Transfer.Status statuses = 8;
     */
    statuses: Transfer_Status[];
    /**
     * @generated from protobuf field: string cursor = 9;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 10;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 11;
     */
    limit: number;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange requested_at_range = 12;
     */
    requestedAtRange?: TimestampRange;
}
/**
 * @generated from protobuf message rd.search.service.SearchTransfersRes
 */
export interface SearchTransfersRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchTransfersRes.Result results = 1;
     */
    results: SearchTransfersRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchTransfersRes.Result
 */
export interface SearchTransfersRes_Result {
    /**
     * @generated from protobuf field: rd.txn.Transfer transfer = 1;
     */
    transfer?: Transfer;
    /**
     * @generated from protobuf field: rd.company.Location from_location = 2;
     */
    fromLocation?: Location;
    /**
     * @generated from protobuf field: rd.company.Location to_location = 3;
     */
    toLocation?: Location;
    /**
     * @generated from protobuf field: map<string, rd.product.Product> products_by_id = 4;
     */
    productsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: map<string, rd.product.Product> matched_products_by_id = 5;
     */
    matchedProductsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 6;
     */
    total?: Money;
}
/**
 * @generated from protobuf message rd.search.service.SearchVendorsReq
 */
export interface SearchVendorsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_active_filter = 2;
     */
    isActiveFilter: BoolFilter;
    /**
     * @generated from protobuf field: string cursor = 3;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 4;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 5;
     */
    limit: number;
}
/**
 * @generated from protobuf message rd.search.service.SearchVendorsRes
 */
export interface SearchVendorsRes {
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchVendorsRes.Result results = 3;
     */
    results: SearchVendorsRes_Result[];
}
/**
 * @generated from protobuf message rd.search.service.SearchVendorsRes.Result
 */
export interface SearchVendorsRes_Result {
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 1;
     */
    vendor?: Vendor;
}
/**
 * @generated from protobuf message rd.search.service.BulkExportVendorsReq
 */
export interface BulkExportVendorsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_active_filter = 2;
     */
    isActiveFilter: BoolFilter;
    /**
     * @generated from protobuf field: bool is_template = 3;
     */
    isTemplate: boolean;
}
/**
 * @generated from protobuf message rd.search.service.BulkExportVendorsRes
 */
export interface BulkExportVendorsRes {
    /**
     * @generated from protobuf field: bytes excel_bytes = 1;
     */
    excelBytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.UpdateRecordReq
 */
export interface UpdateRecordReq {
    /**
     * @generated from protobuf field: rd.search.cache.Update update = 6;
     */
    update?: Update;
}
/**
 * @generated from protobuf message rd.search.service.UpdateRecordRes
 */
export interface UpdateRecordRes {
}
/**
 * @generated from protobuf message rd.search.service.ReloadReq
 */
export interface ReloadReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 1;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: bool full_reload = 2;
     */
    fullReload: boolean;
    /**
     * @generated from protobuf field: repeated string table_names = 3;
     */
    tableNames: string[];
}
/**
 * @generated from protobuf message rd.search.service.ReloadRes
 */
export interface ReloadRes {
}
/**
 * @generated from protobuf message rd.search.service.SearchCashTransactionsReq
 */
export interface SearchCashTransactionsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID location_ids = 2;
     */
    locationIds: UUID[];
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange created_at_range = 3;
     */
    createdAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchCashTransactionsReq.Type types = 4;
     */
    types: SearchCashTransactionsReq_Type[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID created_by_ids = 5;
     */
    createdByIds: UUID[];
    /**
     * @generated from protobuf field: string cursor = 6;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 7;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 8;
     */
    limit: number;
}
/**
 * @generated from protobuf enum rd.search.service.SearchCashTransactionsReq.Type
 */
export enum SearchCashTransactionsReq_Type {
    /**
     * @generated from protobuf enum value: TYPE_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: TYPE_SALE = 1;
     */
    SALE = 1,
    /**
     * @generated from protobuf enum value: TYPE_BILL_PAYMENT = 2;
     */
    BILL_PAYMENT = 2,
    /**
     * @generated from protobuf enum value: TYPE_REFUND = 3;
     */
    REFUND = 3,
    /**
     * @generated from protobuf enum value: TYPE_PETTY_CASH = 4;
     */
    PETTY_CASH = 4,
    /**
     * @generated from protobuf enum value: TYPE_CLOSE_OUT = 5;
     */
    CLOSE_OUT = 5
}
/**
 * @generated from protobuf message rd.search.service.SearchCashTransactionsRes
 */
export interface SearchCashTransactionsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchCashTransactionsRes.Result results = 1;
     */
    results: SearchCashTransactionsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchCashTransactionsRes.Result
 */
export interface SearchCashTransactionsRes_Result {
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry ledger_entry = 1;
     */
    ledgerEntry?: LedgerEntry;
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 2;
     */
    sale?: Sale;
    /**
     * @generated from protobuf field: rd.txn.BillPayment bill_payment = 3;
     */
    billPayment?: BillPayment;
    /**
     * @generated from protobuf field: rd.txn.PettyCash petty_cash = 4;
     */
    pettyCash?: PettyCash;
    /**
     * @generated from protobuf field: rd.txn.CloseOut close_out = 5;
     */
    closeOut?: CloseOut;
    /**
     * @generated from protobuf field: rd.txn.Sale.Return sale_return = 6;
     */
    saleReturn?: Sale_Return;
}
/**
 * @generated from protobuf message rd.search.service.SearchFinancialTransactionsReq
 */
export interface SearchFinancialTransactionsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID vendor_ids = 2;
     */
    vendorIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.payments.FinancialTransaction.Type types = 3;
     */
    types: FinancialTransaction_Type[];
    /**
     * @generated from protobuf field: repeated rd.payments.FinancialTransaction.Status statuses = 4;
     */
    statuses: FinancialTransaction_Status[];
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange range = 5;
     */
    range?: TimestampRange;
    /**
     * @generated from protobuf field: string cursor = 6;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 7;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 8;
     */
    limit: number;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID chart_of_account_ids = 9;
     */
    chartOfAccountIds: UUID[];
}
/**
 * @generated from protobuf message rd.search.service.SearchFinancialTransactionsRes
 */
export interface SearchFinancialTransactionsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchFinancialTransactionsRes.Result results = 1;
     */
    results: SearchFinancialTransactionsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchFinancialTransactionsRes.Result
 */
export interface SearchFinancialTransactionsRes_Result {
    /**
     * @generated from protobuf field: rd.payments.FinancialTransaction financial_transaction = 1;
     */
    financialTransaction?: FinancialTransaction;
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 2;
     */
    vendor?: Vendor;
    /**
     * @generated from protobuf field: map<string, rd.coa.Account> accounts_by_id = 5;
     */
    accountsById: {
        [key: string]: Account;
    };
    /**
     * @generated from protobuf field: map<string, rd.search.service.SearchFinancialTransactionsRes.Result.OrderShipmentData> order_shipment_data_by_id = 6;
     */
    orderShipmentDataById: {
        [key: string]: SearchFinancialTransactionsRes_Result_OrderShipmentData;
    };
    /**
     * @generated from protobuf field: rd.company.Location location = 7;
     */
    location?: Location;
}
/**
 * @generated from protobuf message rd.search.service.SearchFinancialTransactionsRes.Result.OrderShipmentData
 */
export interface SearchFinancialTransactionsRes_Result_OrderShipmentData {
    /**
     * @generated from protobuf field: rd.txn.OrderShipment order_shipment = 1;
     */
    orderShipment?: OrderShipment;
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 2;
     */
    vendor?: Vendor;
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 3;
     */
    total?: Money;
}
/**
 * @generated from protobuf message rd.search.service.SearchInventoryCountProductsReq
 */
export interface SearchInventoryCountProductsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID inventory_location_id = 2;
     */
    inventoryLocationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID inventory_count_id = 3;
     */
    inventoryCountId?: UUID;
    /**
     * @generated from protobuf field: rd.product.InventoryCountProduct.Status product_status = 4;
     */
    productStatus: InventoryCountProduct_Status;
    /**
     * @generated from protobuf field: string cursor = 5;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 6;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 7;
     */
    limit: number;
    /**
     * @generated from protobuf field: rd.search.service.SearchInventoryCountProductsReq.OrderBy order_by = 13;
     */
    orderBy: SearchInventoryCountProductsReq_OrderBy;
    /**
     * @generated from protobuf field: rd.search.service.SortOrder sort_order = 14;
     */
    sortOrder: SortOrder;
}
/**
 * @generated from protobuf enum rd.search.service.SearchInventoryCountProductsReq.OrderBy
 */
export enum SearchInventoryCountProductsReq_OrderBy {
    /**
     * @generated from protobuf enum value: ORDER_BY_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: ORDER_BY_NAME = 1;
     */
    NAME = 1,
    /**
     * @generated from protobuf enum value: ORDER_BY_ON_HAND = 2;
     */
    ON_HAND = 2,
    /**
     * @generated from protobuf enum value: ORDER_BY_VARIANCE = 3;
     */
    VARIANCE = 3,
    /**
     * @generated from protobuf enum value: ORDER_BY_DOLLAR_VARIANCE = 4;
     */
    DOLLAR_VARIANCE = 4
}
/**
 * @generated from protobuf message rd.search.service.SearchInventoryCountProductsRes
 */
export interface SearchInventoryCountProductsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchInventoryCountProductsRes.Result results = 1;
     */
    results: SearchInventoryCountProductsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchInventoryCountProductsRes.Result
 */
export interface SearchInventoryCountProductsRes_Result {
    /**
     * @generated from protobuf field: rd.product.Product product = 1;
     */
    product?: Product;
    /**
     * @generated from protobuf field: int32 on_hand = 2;
     */
    onHand: number;
    /**
     * @generated from protobuf field: int32 counted = 3;
     */
    counted: number;
    /**
     * @generated from protobuf field: int32 variance = 4;
     */
    variance: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money dollar_variance = 5;
     */
    dollarVariance?: Money;
}
/**
 * @generated from protobuf message rd.search.service.BulkExportInventoryCountProductsReq
 */
export interface BulkExportInventoryCountProductsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID inventory_location_id = 2;
     */
    inventoryLocationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID inventory_count_id = 3;
     */
    inventoryCountId?: UUID;
    /**
     * @generated from protobuf field: rd.product.InventoryCountProduct.Status product_status = 4;
     */
    productStatus: InventoryCountProduct_Status;
    /**
     * @generated from protobuf field: string cursor = 5;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 6;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 7;
     */
    limit: number;
    /**
     * @generated from protobuf field: rd.search.service.BulkExportInventoryCountProductsReq.OrderBy order_by = 13;
     */
    orderBy: BulkExportInventoryCountProductsReq_OrderBy;
    /**
     * @generated from protobuf field: rd.search.service.SortOrder sort_order = 14;
     */
    sortOrder: SortOrder;
}
/**
 * @generated from protobuf enum rd.search.service.BulkExportInventoryCountProductsReq.OrderBy
 */
export enum BulkExportInventoryCountProductsReq_OrderBy {
    /**
     * @generated from protobuf enum value: ORDER_BY_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: ORDER_BY_NAME = 1;
     */
    NAME = 1,
    /**
     * @generated from protobuf enum value: ORDER_BY_ON_HAND = 2;
     */
    ON_HAND = 2,
    /**
     * @generated from protobuf enum value: ORDER_BY_VARIANCE = 3;
     */
    VARIANCE = 3,
    /**
     * @generated from protobuf enum value: ORDER_BY_DOLLAR_VARIANCE = 4;
     */
    DOLLAR_VARIANCE = 4
}
/**
 * @generated from protobuf message rd.search.service.BulkExportInventoryCountProductsRes
 */
export interface BulkExportInventoryCountProductsRes {
    /**
     * @generated from protobuf field: bytes excel_bytes = 1;
     */
    excelBytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.SearchExcludedInventoryCountProductsReq
 */
export interface SearchExcludedInventoryCountProductsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 2;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID inventory_count_id = 3;
     */
    inventoryCountId?: UUID;
    /**
     * @generated from protobuf field: string cursor = 4;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 5;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 6;
     */
    limit: number;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID department_id = 7;
     */
    departmentId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID vendor_id = 8;
     */
    vendorId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID class_id = 9;
     */
    classId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID fineline_id = 10;
     */
    finelineId?: UUID;
}
/**
 * @generated from protobuf message rd.search.service.SearchExcludedInventoryCountProductsRes
 */
export interface SearchExcludedInventoryCountProductsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchExcludedInventoryCountProductsRes.Result results = 1;
     */
    results: SearchExcludedInventoryCountProductsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchExcludedInventoryCountProductsRes.Result
 */
export interface SearchExcludedInventoryCountProductsRes_Result {
    /**
     * @generated from protobuf field: rd.product.Product product = 1;
     */
    product?: Product;
    /**
     * @generated from protobuf field: repeated rd.price.Tier tier_prices = 2;
     */
    tierPrices: Tier[];
}
/**
 * @generated from protobuf message rd.search.service.SearchExternalTransactionsReq
 */
export interface SearchExternalTransactionsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange transacted_at_range = 2;
     */
    transactedAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID location_ids = 3;
     */
    locationIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID customer_ids = 4;
     */
    customerIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID vendor_ids = 5;
     */
    vendorIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID chart_of_account_ids = 6;
     */
    chartOfAccountIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.accounting.ExternalTransaction.Type types = 7;
     */
    types: ExternalTransaction_Type[];
    /**
     * @generated from protobuf field: repeated rd.accounting.ExternalTransaction.PaymentMethod payment_methods = 8;
     */
    paymentMethods: ExternalTransaction_PaymentMethod[];
    /**
     * @generated from protobuf field: string cursor = 9;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 10;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 11;
     */
    limit: number;
}
/**
 * @generated from protobuf message rd.search.service.SearchExternalTransactionsRes
 */
export interface SearchExternalTransactionsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchExternalTransactionsRes.Result results = 1;
     */
    results: SearchExternalTransactionsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchExternalTransactionsRes.Result
 */
export interface SearchExternalTransactionsRes_Result {
    /**
     * @generated from protobuf field: rd.accounting.ExternalTransaction external_transaction = 1;
     */
    externalTransaction?: ExternalTransaction;
    /**
     * @generated from protobuf field: rd.company.Location location = 2;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 3;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 4;
     */
    vendor?: Vendor;
    /**
     * @generated from protobuf field: map<string, rd.coa.Account> accounts_by_id = 7;
     */
    accountsById: {
        [key: string]: Account;
    };
    /**
     * @generated from protobuf field: map<string, rd.search.service.SearchExternalTransactionsRes.Result.OrderShipmentData> order_shipment_data_by_id = 8;
     */
    orderShipmentDataById: {
        [key: string]: SearchExternalTransactionsRes_Result_OrderShipmentData;
    };
}
/**
 * @generated from protobuf message rd.search.service.SearchExternalTransactionsRes.Result.OrderShipmentData
 */
export interface SearchExternalTransactionsRes_Result_OrderShipmentData {
    /**
     * @generated from protobuf field: rd.txn.OrderShipment order_shipment = 1;
     */
    orderShipment?: OrderShipment;
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 2;
     */
    vendor?: Vendor;
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 3;
     */
    total?: Money;
}
/**
 * @generated from protobuf message rd.search.service.SearchOrderShipmentsForReconciliationReq
 */
export interface SearchOrderShipmentsForReconciliationReq {
    /**
     * @generated from protobuf field: string cursor = 1;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 2;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 3;
     */
    limit: number;
    /**
     * @generated from protobuf field: string query = 4;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID transaction_id = 5;
     */
    transactionId?: UUID;
    /**
     * @generated from protobuf field: string stripe_transaction_id = 6;
     */
    stripeTransactionId: string; // if present, will be used to generate a transaction id uuid
}
/**
 * @generated from protobuf message rd.search.service.SearchOrderShipmentsForReconciliationRes
 */
export interface SearchOrderShipmentsForReconciliationRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchOrderShipmentsForReconciliationRes.Result results = 1;
     */
    results: SearchOrderShipmentsForReconciliationRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchOrderShipmentsForReconciliationRes.Result
 */
export interface SearchOrderShipmentsForReconciliationRes_Result {
    /**
     * @generated from protobuf field: rd.txn.OrderShipment order_shipment = 1;
     */
    orderShipment?: OrderShipment;
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 2;
     */
    vendor?: Vendor;
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 3;
     */
    total?: Money;
}
/**
 * @generated from protobuf message rd.search.service.SearchInventoryChangesReq
 */
export interface SearchInventoryChangesReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID product_id = 2;
     */
    productId?: UUID;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID staff_ids = 4;
     */
    staffIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchInventoryChangesReq.Type types = 5;
     */
    types: SearchInventoryChangesReq_Type[];
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange range = 6;
     */
    range?: TimestampRange;
    /**
     * @generated from protobuf field: string cursor = 7;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 8;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 9;
     */
    limit: number;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID location_ids = 10;
     */
    locationIds: UUID[];
}
/**
 * @generated from protobuf enum rd.search.service.SearchInventoryChangesReq.Type
 */
export enum SearchInventoryChangesReq_Type {
    /**
     * @generated from protobuf enum value: TYPE_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: TYPE_SALE = 1;
     */
    SALE = 1,
    /**
     * @generated from protobuf enum value: TYPE_RETURN = 2;
     */
    RETURN = 2,
    /**
     * @generated from protobuf enum value: TYPE_ORDER_SHIPMENT = 3;
     */
    ORDER_SHIPMENT = 3,
    /**
     * @generated from protobuf enum value: TYPE_TRANSFER = 4;
     */
    TRANSFER = 4,
    /**
     * @generated from protobuf enum value: TYPE_COUNT = 5;
     */
    COUNT = 5,
    /**
     * @generated from protobuf enum value: TYPE_MANUAL_EDIT = 6;
     */
    MANUAL_EDIT = 6,
    /**
     * @generated from protobuf enum value: TYPE_ORDER_ORDERED = 7;
     */
    ORDER_ORDERED = 7,
    /**
     * @generated from protobuf enum value: TYPE_SALE_WILL_CALL = 8;
     */
    SALE_WILL_CALL = 8
}
/**
 * @generated from protobuf message rd.search.service.SearchInventoryChangesRes
 */
export interface SearchInventoryChangesRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchInventoryChangesRes.Result results = 1;
     */
    results: SearchInventoryChangesRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchInventoryChangesRes.Result
 */
export interface SearchInventoryChangesRes_Result {
    /**
     * @generated from protobuf field: rd.product.InventoryChange inventory_change = 1;
     */
    inventoryChange?: InventoryChange;
}
/**
 * @generated from protobuf message rd.search.service.SearchCustomPriceRulesReq
 */
export interface SearchCustomPriceRulesReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 3;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 4;
     */
    limit: number;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID customer_ids = 5;
     */
    customerIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID department_ids = 6;
     */
    departmentIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID vendor_ids = 7;
     */
    vendorIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID class_ids = 8;
     */
    classIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID fineline_ids = 9;
     */
    finelineIds: UUID[];
    /**
     * @generated from protobuf field: rd.search.service.CustomPriceRuleSortBy sort_by = 10;
     */
    sortBy: CustomPriceRuleSortBy;
    /**
     * @generated from protobuf field: rd.search.service.SortOrder sort_order = 11;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: rd.search.service.CustomPriceRuleType type = 12;
     */
    type: CustomPriceRuleType;
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_active_filter = 13;
     */
    isActiveFilter: BoolFilter;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID job_ids = 14;
     */
    jobIds: UUID[];
}
/**
 * @generated from protobuf message rd.search.service.SearchCustomPriceRulesRes
 */
export interface SearchCustomPriceRulesRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchCustomPriceRulesRes.Result results = 1;
     */
    results: SearchCustomPriceRulesRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchCustomPriceRulesRes.Result
 */
export interface SearchCustomPriceRulesRes_Result {
    /**
     * @generated from protobuf field: rd.price.Custom custom = 1;
     */
    custom?: Custom;
    /**
     * @generated from protobuf field: int32 product_count = 10;
     */
    productCount: number;
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 11;
     */
    customer?: Customer; // for jobs, this is the parent customer
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 12;
     */
    vendor?: Vendor;
    /**
     * @generated from protobuf field: rd.company.ProductCategory department = 13;
     */
    department?: ProductCategory;
    /**
     * @generated from protobuf field: rd.company.ProductCategory class = 14;
     */
    class?: ProductCategory;
    /**
     * @generated from protobuf field: rd.company.ProductCategory fineline = 15;
     */
    fineline?: ProductCategory;
    /**
     * @generated from protobuf field: rd.product.Product product = 16;
     */
    product?: Product;
    /**
     * @generated from protobuf field: repeated rd.price.Tier tier_prices = 17;
     */
    tierPrices: Tier[];
    /**
     * @generated from protobuf field: rd.customer.Customer job = 18;
     */
    job?: Customer;
}
/**
 * @generated from protobuf message rd.search.service.BulkExportCustomPriceRulesReq
 */
export interface BulkExportCustomPriceRulesReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID customer_ids = 2;
     */
    customerIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID department_ids = 3;
     */
    departmentIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID vendor_ids = 4;
     */
    vendorIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID class_ids = 5;
     */
    classIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID fineline_ids = 6;
     */
    finelineIds: UUID[];
    /**
     * @generated from protobuf field: rd.search.service.CustomPriceRuleSortBy sort_by = 7;
     */
    sortBy: CustomPriceRuleSortBy;
    /**
     * @generated from protobuf field: rd.search.service.SortOrder sort_order = 8;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: rd.search.service.CustomPriceRuleType type = 9;
     */
    type: CustomPriceRuleType;
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_active_filter = 10;
     */
    isActiveFilter: BoolFilter;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID job_ids = 11;
     */
    jobIds: UUID[];
    /**
     * @generated from protobuf field: bool is_template = 12;
     */
    isTemplate: boolean;
}
/**
 * @generated from protobuf message rd.search.service.BulkExportCustomPriceRulesRes
 */
export interface BulkExportCustomPriceRulesRes {
    /**
     * @generated from protobuf field: bytes excel_bytes = 1;
     */
    excelBytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.SearchPriceTierRulesReq
 */
export interface SearchPriceTierRulesReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 3;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 4;
     */
    limit: number;
    /**
     * @generated from protobuf field: string pricing_tier_id_string = 5;
     */
    pricingTierIdString: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchPriceTierRulesRes
 */
export interface SearchPriceTierRulesRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchPriceTierRulesRes.Result results = 1;
     */
    results: SearchPriceTierRulesRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchPriceTierRulesRes.Result
 */
export interface SearchPriceTierRulesRes_Result {
    /**
     * @generated from protobuf field: rd.price.TierRule tier_rule = 1;
     */
    tierRule?: TierRule;
    /**
     * @generated from protobuf field: int32 product_count = 2;
     */
    productCount: number;
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 3;
     */
    vendor?: Vendor;
    /**
     * @generated from protobuf field: rd.company.ProductCategory department = 4;
     */
    department?: ProductCategory;
    /**
     * @generated from protobuf field: rd.company.ProductCategory class = 5;
     */
    class?: ProductCategory;
    /**
     * @generated from protobuf field: rd.company.ProductCategory fineline = 6;
     */
    fineline?: ProductCategory;
    /**
     * @generated from protobuf field: rd.product.Product product = 7;
     */
    product?: Product;
    /**
     * @generated from protobuf field: repeated rd.price.Tier tier_prices = 17;
     */
    tierPrices: Tier[];
}
/**
 * @generated from protobuf message rd.search.service.SearchJobsReq
 */
export interface SearchJobsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 3;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 4;
     */
    limit: number;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 5;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: rd.search.service.OrderJobsBy order_by = 6;
     */
    orderBy: OrderJobsBy;
    /**
     * @generated from protobuf field: rd.search.service.SortOrder sort_order = 7;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_active_filter = 8;
     */
    isActiveFilter: BoolFilter;
}
/**
 * @generated from protobuf message rd.search.service.SearchJobsRes
 */
export interface SearchJobsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchJobsRes.Result results = 1;
     */
    results: SearchJobsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchJobsRes.Result
 */
export interface SearchJobsRes_Result {
    /**
     * @generated from protobuf field: rd.customer.Customer job = 1;
     */
    job?: Customer;
    /**
     * @generated from protobuf field: rd.proto.money.Money total_balance = 7;
     */
    totalBalance?: Money;
}
/**
 * @generated from protobuf message rd.search.service.SearchTagsReq
 */
export interface SearchTagsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 3;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 4;
     */
    limit: number;
    /**
     * @generated from protobuf field: rd.search.service.SearchTagsReq.OrderBy order_by = 5;
     */
    orderBy: SearchTagsReq_OrderBy;
    /**
     * @generated from protobuf field: rd.search.service.SortOrder sort_order = 6;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: repeated rd.tag.Tag.Type types = 7;
     */
    types: Tag_Type[];
    /**
     * @generated from protobuf field: repeated rd.tag.Tag.Color colors = 8;
     */
    colors: Tag_Color[];
}
/**
 * @generated from protobuf enum rd.search.service.SearchTagsReq.OrderBy
 */
export enum SearchTagsReq_OrderBy {
    /**
     * @generated from protobuf enum value: ORDER_BY_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: ORDER_BY_NAME = 1;
     */
    NAME = 1
}
/**
 * @generated from protobuf message rd.search.service.SearchTagsRes
 */
export interface SearchTagsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchTagsRes.Result results = 1;
     */
    results: SearchTagsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchTagsRes.Result
 */
export interface SearchTagsRes_Result {
    /**
     * @generated from protobuf field: rd.tag.Tag tag = 1;
     */
    tag?: Tag;
    /**
     * @generated from protobuf field: int32 tagged_count = 2;
     */
    taggedCount: number;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchSalesReq
 */
export interface CxaSearchSalesReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 2;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID location_ids = 3;
     */
    locationIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.txn.Sale.Status statuses = 4;
     */
    statuses: Sale_Status[];
    /**
     * @generated from protobuf field: string cursor = 5;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 6;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 7;
     */
    limit: number;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchSalesRes
 */
export interface CxaSearchSalesRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.CxaSearchSalesRes.Result results = 1;
     */
    results: CxaSearchSalesRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchSalesRes.Result
 */
export interface CxaSearchSalesRes_Result {
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 1;
     */
    sale?: Sale;
    /**
     * @generated from protobuf field: rd.company.Location location = 2;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.customer.PurchaseOrder purchase_order = 3;
     */
    purchaseOrder?: PurchaseOrder;
    /**
     * @generated from protobuf field: map<string, rd.product.Product> products_by_id = 5;
     */
    productsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: map<string, rd.product.Product> matched_products_by_id = 6;
     */
    matchedProductsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: map<string, rd.tintcolor.TintColor> tint_colors_by_id = 7;
     */
    tintColorsById: {
        [key: string]: TintColor;
    };
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 8;
     */
    total?: Money;
    /**
     * @generated from protobuf field: repeated rd.txn.PurchaseMethod purchase_methods = 9;
     */
    purchaseMethods: PurchaseMethod[];
    /**
     * @generated from protobuf field: string customer_contact_name = 10;
     */
    customerContactName: string;
    /**
     * @generated from protobuf field: string customer_identifier = 11;
     */
    customerIdentifier: string;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchOutstandingSalesReq
 */
export interface CxaSearchOutstandingSalesReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchOutstandingSalesRes
 */
export interface CxaSearchOutstandingSalesRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.CxaSearchOutstandingSalesRes.Result results = 1;
     */
    results: CxaSearchOutstandingSalesRes_Result[];
    /**
     * @generated from protobuf field: rd.txn.CustomerBalance customer_balance = 2;
     */
    customerBalance?: CustomerBalance;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchOutstandingSalesRes.Result
 */
export interface CxaSearchOutstandingSalesRes_Result {
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 1;
     */
    sale?: Sale;
    /**
     * @generated from protobuf field: rd.proto.money.Money sale_balance = 2;
     */
    saleBalance?: Money;
    /**
     * @generated from protobuf field: rd.company.Location location = 3;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.customer.PurchaseOrder purchase_order = 4;
     */
    purchaseOrder?: PurchaseOrder;
    /**
     * @generated from protobuf field: string customer_contact_name = 6;
     */
    customerContactName: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money potential_discount_amount = 7;
     */
    potentialDiscountAmount?: Money;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchReturnsReq
 */
export interface CxaSearchReturnsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 3;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 4;
     */
    limit: number;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchReturnsRes
 */
export interface CxaSearchReturnsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.CxaSearchReturnsRes.Result results = 1;
     */
    results: CxaSearchReturnsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchReturnsRes.Result
 */
export interface CxaSearchReturnsRes_Result {
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 1;
     */
    sale?: Sale;
    /**
     * @generated from protobuf field: rd.txn.Sale.Return sale_return = 2;
     */
    saleReturn?: Sale_Return;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry return_ledger_entry = 3;
     */
    returnLedgerEntry?: LedgerEntry;
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 4;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.company.Location location = 5;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.customer.PurchaseOrder purchase_order = 7;
     */
    purchaseOrder?: PurchaseOrder;
    /**
     * @generated from protobuf field: rd.customer.Contacts.Contact customer_contact = 8;
     */
    customerContact?: Contacts_Contact;
    /**
     * @generated from protobuf field: string return_method = 9;
     */
    returnMethod: string;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchJobsReq
 */
export interface CxaSearchJobsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 3;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 4;
     */
    limit: number;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchJobsRes
 */
export interface CxaSearchJobsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.CxaSearchJobsRes.Result results = 1;
     */
    results: CxaSearchJobsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchJobsRes.Result
 */
export interface CxaSearchJobsRes_Result {
    /**
     * @generated from protobuf field: rd.customer.Customer job = 1;
     */
    job?: Customer;
    /**
     * @generated from protobuf field: rd.proto.money.Money total_balance = 2;
     */
    totalBalance?: Money;
}
/**
 * Cash Register Report
 *
 * @generated from protobuf message rd.search.service.GetCashRegisterReq
 */
export interface GetCashRegisterReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.CashRegisterSortBy sort_by = 1;
     */
    sortBy: CashRegisterSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.CashRegisterTransactionType transaction_types = 4;
     */
    transactionTypes: CashRegisterTransactionType[];
}
/**
 * @generated from protobuf message rd.search.service.GetCashRegisterRes
 */
export interface GetCashRegisterRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetCashRegisterPDFReq
 */
export interface GetCashRegisterPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.CashRegisterSortBy sort_by = 1;
     */
    sortBy: CashRegisterSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.CashRegisterTransactionType transaction_types = 4;
     */
    transactionTypes: CashRegisterTransactionType[];
}
/**
 * @generated from protobuf message rd.search.service.GetCashRegisterPDFRes
 */
export interface GetCashRegisterPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetCashRegisterCSVReq
 */
export interface GetCashRegisterCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.CashRegisterSortBy sort_by = 1;
     */
    sortBy: CashRegisterSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.CashRegisterTransactionType transaction_types = 4;
     */
    transactionTypes: CashRegisterTransactionType[];
}
/**
 * @generated from protobuf message rd.search.service.GetCashRegisterCSVRes
 */
export interface GetCashRegisterCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Sales
 *
 * @generated from protobuf message rd.search.service.GetSalesReq
 */
export interface GetSalesReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesSortBy sort_by = 2;
     */
    sortBy: SalesSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.SalesTransactionType transaction_types = 4;
     */
    transactionTypes: SalesTransactionType[];
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.SalesPurchaseMethod purchase_methods = 5;
     */
    purchaseMethods: SalesPurchaseMethod[];
}
/**
 * @generated from protobuf message rd.search.service.GetSalesRes
 */
export interface GetSalesRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesPDFReq
 */
export interface GetSalesPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesSortBy sort_by = 2;
     */
    sortBy: SalesSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.SalesTransactionType transaction_types = 4;
     */
    transactionTypes: SalesTransactionType[];
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.SalesPurchaseMethod purchase_methods = 5;
     */
    purchaseMethods: SalesPurchaseMethod[];
}
/**
 * @generated from protobuf message rd.search.service.GetSalesPDFRes
 */
export interface GetSalesPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesCSVReq
 */
export interface GetSalesCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesSortBy sort_by = 2;
     */
    sortBy: SalesSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.SalesTransactionType transaction_types = 4;
     */
    transactionTypes: SalesTransactionType[];
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.SalesPurchaseMethod purchase_methods = 5;
     */
    purchaseMethods: SalesPurchaseMethod[];
}
/**
 * @generated from protobuf message rd.search.service.GetSalesCSVRes
 */
export interface GetSalesCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Payments
 *
 * @generated from protobuf message rd.search.service.GetPaymentsReq
 */
export interface GetPaymentsReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.PaymentsByDaySortBy sort_by = 2;
     */
    sortBy: PaymentsByDaySortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.PaymentsByDayMethod method_of_tenders = 4;
     */
    methodOfTenders: PaymentsByDayMethod[];
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.PaymentsByDayTransactionType transaction_types = 5;
     */
    transactionTypes: PaymentsByDayTransactionType[];
}
/**
 * @generated from protobuf message rd.search.service.GetPaymentsRes
 */
export interface GetPaymentsRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetPaymentsPDFReq
 */
export interface GetPaymentsPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.PaymentsByDaySortBy sort_by = 2;
     */
    sortBy: PaymentsByDaySortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.PaymentsByDayMethod method_of_tenders = 4;
     */
    methodOfTenders: PaymentsByDayMethod[];
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.PaymentsByDayTransactionType transaction_types = 5;
     */
    transactionTypes: PaymentsByDayTransactionType[];
}
/**
 * @generated from protobuf message rd.search.service.GetPaymentsPDFRes
 */
export interface GetPaymentsPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetPaymentsCSVReq
 */
export interface GetPaymentsCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.PaymentsByDaySortBy sort_by = 2;
     */
    sortBy: PaymentsByDaySortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.PaymentsByDayMethod method_of_tenders = 4;
     */
    methodOfTenders: PaymentsByDayMethod[];
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.PaymentsByDayTransactionType transaction_types = 5;
     */
    transactionTypes: PaymentsByDayTransactionType[];
}
/**
 * @generated from protobuf message rd.search.service.GetPaymentsCSVRes
 */
export interface GetPaymentsCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Sold Products
 *
 * @generated from protobuf message rd.search.service.GetSoldProductsReq
 */
export interface GetSoldProductsReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.SoldProductsSortBy sort_by = 2;
     */
    sortBy: SoldProductsSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetSoldProductsRes
 */
export interface GetSoldProductsRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetSoldProductsPDFReq
 */
export interface GetSoldProductsPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.SoldProductsSortBy sort_by = 2;
     */
    sortBy: SoldProductsSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetSoldProductsPDFRes
 */
export interface GetSoldProductsPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetSoldProductsCSVReq
 */
export interface GetSoldProductsCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.SoldProductsSortBy sort_by = 2;
     */
    sortBy: SoldProductsSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetSoldProductsCSVRes
 */
export interface GetSoldProductsCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Sales by Customer
 *
 * @generated from protobuf message rd.search.service.GetSalesByCustomerReq
 */
export interface GetSalesByCustomerReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByCustomerSortBy sort_by = 1;
     */
    sortBy: SalesByCustomerSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByCustomerRes
 */
export interface GetSalesByCustomerRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByCustomerPDFReq
 */
export interface GetSalesByCustomerPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByCustomerSortBy sort_by = 1;
     */
    sortBy: SalesByCustomerSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByCustomerPDFRes
 */
export interface GetSalesByCustomerPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByCustomerCSVReq
 */
export interface GetSalesByCustomerCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByCustomerSortBy sort_by = 1;
     */
    sortBy: SalesByCustomerSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByCustomerCSVRes
 */
export interface GetSalesByCustomerCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Sales by Job
 *
 * @generated from protobuf message rd.search.service.GetSalesByJobReq
 */
export interface GetSalesByJobReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByJobSortBy sort_by = 1;
     */
    sortBy: SalesByJobSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByJobRes
 */
export interface GetSalesByJobRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByJobPDFReq
 */
export interface GetSalesByJobPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByJobSortBy sort_by = 1;
     */
    sortBy: SalesByJobSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByJobPDFRes
 */
export interface GetSalesByJobPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByJobCSVReq
 */
export interface GetSalesByJobCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByJobSortBy sort_by = 1;
     */
    sortBy: SalesByJobSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByJobCSVRes
 */
export interface GetSalesByJobCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Sales by Product
 *
 * @generated from protobuf message rd.search.service.GetSalesByProductReq
 */
export interface GetSalesByProductReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByProductSortBy sort_by = 2;
     */
    sortBy: SalesByProductSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByProductRes
 */
export interface GetSalesByProductRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByProductPDFReq
 */
export interface GetSalesByProductPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByProductSortBy sort_by = 2;
     */
    sortBy: SalesByProductSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByProductPDFRes
 */
export interface GetSalesByProductPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByProductCSVReq
 */
export interface GetSalesByProductCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByProductSortBy sort_by = 2;
     */
    sortBy: SalesByProductSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByProductCSVRes
 */
export interface GetSalesByProductCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Sales by Location
 *
 * @generated from protobuf message rd.search.service.GetSalesByLocationReq
 */
export interface GetSalesByLocationReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByLocationSortBy sort_by = 2;
     */
    sortBy: SalesByLocationSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByLocationRes
 */
export interface GetSalesByLocationRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByLocationPDFReq
 */
export interface GetSalesByLocationPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByLocationSortBy sort_by = 2;
     */
    sortBy: SalesByLocationSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByLocationPDFRes
 */
export interface GetSalesByLocationPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByLocationCSVReq
 */
export interface GetSalesByLocationCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByLocationSortBy sort_by = 2;
     */
    sortBy: SalesByLocationSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByLocationCSVRes
 */
export interface GetSalesByLocationCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Sales by Rep
 *
 * @generated from protobuf message rd.search.service.GetSalesByRepReq
 */
export interface GetSalesByRepReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByRepSortBy sort_by = 2;
     */
    sortBy: SalesByRepSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByRepRes
 */
export interface GetSalesByRepRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByRepPDFReq
 */
export interface GetSalesByRepPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByRepSortBy sort_by = 2;
     */
    sortBy: SalesByRepSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByRepPDFRes
 */
export interface GetSalesByRepPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByRepCSVReq
 */
export interface GetSalesByRepCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByRepSortBy sort_by = 2;
     */
    sortBy: SalesByRepSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetSalesByRepCSVRes
 */
export interface GetSalesByRepCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Price Edits
 *
 * @generated from protobuf message rd.search.service.GetPriceEditsReq
 */
export interface GetPriceEditsReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.PriceEditsSortBy sort_by = 1;
     */
    sortBy: PriceEditsSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.search.service.GetPriceEditsRes
 */
export interface GetPriceEditsRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetPriceEditsPDFReq
 */
export interface GetPriceEditsPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.PriceEditsSortBy sort_by = 1;
     */
    sortBy: PriceEditsSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.search.service.GetPriceEditsPDFRes
 */
export interface GetPriceEditsPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetPriceEditsCSVReq
 */
export interface GetPriceEditsCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.PriceEditsSortBy sort_by = 1;
     */
    sortBy: PriceEditsSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.search.service.GetPriceEditsCSVRes
 */
export interface GetPriceEditsCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetProfitAndLossReq
 */
export interface GetProfitAndLossReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.search.service.GetProfitAndLossRes
 */
export interface GetProfitAndLossRes {
    /**
     * @generated from protobuf field: rd.analyticsv2.ProfitAndLoss profit_and_loss = 1;
     */
    profitAndLoss?: ProfitAndLoss;
}
/**
 * @generated from protobuf message rd.search.service.GetProfitAndLossPDFReq
 */
export interface GetProfitAndLossPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: bool hide_product_categories = 2;
     */
    hideProductCategories: boolean;
}
/**
 * @generated from protobuf message rd.search.service.GetProfitAndLossPDFRes
 */
export interface GetProfitAndLossPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * New Accounts Opened
 *
 * @generated from protobuf message rd.search.service.GetNewAccountsOpenedReq
 */
export interface GetNewAccountsOpenedReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.NewAccountsOpenedSortBy sort_by = 1;
     */
    sortBy: NewAccountsOpenedSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.search.service.GetNewAccountsOpenedRes
 */
export interface GetNewAccountsOpenedRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetNewAccountsOpenedPDFReq
 */
export interface GetNewAccountsOpenedPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.NewAccountsOpenedSortBy sort_by = 1;
     */
    sortBy: NewAccountsOpenedSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.search.service.GetNewAccountsOpenedPDFRes
 */
export interface GetNewAccountsOpenedPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetNewAccountsOpenedCSVReq
 */
export interface GetNewAccountsOpenedCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.NewAccountsOpenedSortBy sort_by = 1;
     */
    sortBy: NewAccountsOpenedSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.search.service.GetNewAccountsOpenedCSVRes
 */
export interface GetNewAccountsOpenedCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Aging Detail
 *
 * @generated from protobuf message rd.search.service.GetAgingDetailReq
 */
export interface GetAgingDetailReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.AgingDetailSortBy sort_by = 2;
     */
    sortBy: AgingDetailSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.AgingDetailTransactionType transaction_types = 4;
     */
    transactionTypes: AgingDetailTransactionType[];
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.AgingDetailStatus statuses = 5;
     */
    statuses: AgingDetailStatus[];
}
/**
 * @generated from protobuf message rd.search.service.GetAgingDetailRes
 */
export interface GetAgingDetailRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetAgingDetailPDFReq
 */
export interface GetAgingDetailPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.AgingDetailSortBy sort_by = 2;
     */
    sortBy: AgingDetailSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.AgingDetailTransactionType transaction_types = 4;
     */
    transactionTypes: AgingDetailTransactionType[];
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.AgingDetailStatus statuses = 5;
     */
    statuses: AgingDetailStatus[];
}
/**
 * @generated from protobuf message rd.search.service.GetAgingDetailPDFRes
 */
export interface GetAgingDetailPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetAgingDetailCSVReq
 */
export interface GetAgingDetailCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.AgingDetailSortBy sort_by = 2;
     */
    sortBy: AgingDetailSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.AgingDetailTransactionType transaction_types = 4;
     */
    transactionTypes: AgingDetailTransactionType[];
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.AgingDetailStatus statuses = 5;
     */
    statuses: AgingDetailStatus[];
}
/**
 * @generated from protobuf message rd.search.service.GetAgingDetailCSVRes
 */
export interface GetAgingDetailCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetPurchaseJournalReq
 */
export interface GetPurchaseJournalReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.PurchaseJournalSortBy sort_by = 2;
     */
    sortBy: PurchaseJournalSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetPurchaseJournalRes
 */
export interface GetPurchaseJournalRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetPurchaseJournalPDFReq
 */
export interface GetPurchaseJournalPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.PurchaseJournalSortBy sort_by = 2;
     */
    sortBy: PurchaseJournalSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetPurchaseJournalPDFRes
 */
export interface GetPurchaseJournalPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetPurchaseJournalCSVReq
 */
export interface GetPurchaseJournalCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.PurchaseJournalSortBy sort_by = 2;
     */
    sortBy: PurchaseJournalSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetPurchaseJournalCSVRes
 */
export interface GetPurchaseJournalCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetAccountPaymentAdjustmentsReq
 */
export interface GetAccountPaymentAdjustmentsReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.AccountPaymentAdjustmentsSortBy sort_by = 2;
     */
    sortBy: AccountPaymentAdjustmentsSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.AccountPaymentAdjustmentsAdjustmentType adjustment_types = 4;
     */
    adjustmentTypes: AccountPaymentAdjustmentsAdjustmentType[];
}
/**
 * @generated from protobuf message rd.search.service.GetAccountPaymentAdjustmentsRes
 */
export interface GetAccountPaymentAdjustmentsRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetAccountPaymentAdjustmentsPDFReq
 */
export interface GetAccountPaymentAdjustmentsPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.AccountPaymentAdjustmentsSortBy sort_by = 2;
     */
    sortBy: AccountPaymentAdjustmentsSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.AccountPaymentAdjustmentsAdjustmentType adjustment_types = 4;
     */
    adjustmentTypes: AccountPaymentAdjustmentsAdjustmentType[];
}
/**
 * @generated from protobuf message rd.search.service.GetAccountPaymentAdjustmentsPDFRes
 */
export interface GetAccountPaymentAdjustmentsPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetAccountPaymentAdjustmentsCSVReq
 */
export interface GetAccountPaymentAdjustmentsCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.AccountPaymentAdjustmentsSortBy sort_by = 2;
     */
    sortBy: AccountPaymentAdjustmentsSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.AccountPaymentAdjustmentsAdjustmentType adjustment_types = 4;
     */
    adjustmentTypes: AccountPaymentAdjustmentsAdjustmentType[];
}
/**
 * @generated from protobuf message rd.search.service.GetAccountPaymentAdjustmentsCSVRes
 */
export interface GetAccountPaymentAdjustmentsCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
// Additional Fees 

/**
 * @generated from protobuf message rd.search.service.GetAdditionalFeesReq
 */
export interface GetAdditionalFeesReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.AdditionalFeesSortBy sort_by = 2;
     */
    sortBy: AdditionalFeesSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetAdditionalFeesRes
 */
export interface GetAdditionalFeesRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetAdditionalFeesPDFReq
 */
export interface GetAdditionalFeesPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.AdditionalFeesSortBy sort_by = 2;
     */
    sortBy: AdditionalFeesSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetAdditionalFeesPDFRes
 */
export interface GetAdditionalFeesPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetAdditionalFeesCSVReq
 */
export interface GetAdditionalFeesCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.AdditionalFeesSortBy sort_by = 2;
     */
    sortBy: AdditionalFeesSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetAdditionalFeesCSVRes
 */
export interface GetAdditionalFeesCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Aging by Job
 *
 * @generated from protobuf message rd.search.service.GetAgingByJobReq
 */
export interface GetAgingByJobReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.JobAgingSortBy sort_by = 1;
     */
    sortBy: JobAgingSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.search.service.GetAgingByJobRes
 */
export interface GetAgingByJobRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetAgingByJobPDFReq
 */
export interface GetAgingByJobPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.JobAgingSortBy sort_by = 1;
     */
    sortBy: JobAgingSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.search.service.GetAgingByJobPDFRes
 */
export interface GetAgingByJobPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetAgingByJobCSVReq
 */
export interface GetAgingByJobCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.JobAgingSortBy sort_by = 1;
     */
    sortBy: JobAgingSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.search.service.GetAgingByJobCSVRes
 */
export interface GetAgingByJobCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Inventory By Department
 *
 * @generated from protobuf message rd.search.service.GetInventoryByDepartmentReq
 */
export interface GetInventoryByDepartmentReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.InventoryByDepartmentSortBy sort_by = 2;
     */
    sortBy: InventoryByDepartmentSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetInventoryByDepartmentRes
 */
export interface GetInventoryByDepartmentRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetInventoryByDepartmentPDFReq
 */
export interface GetInventoryByDepartmentPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.InventoryByDepartmentSortBy sort_by = 2;
     */
    sortBy: InventoryByDepartmentSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetInventoryByDepartmentPDFRes
 */
export interface GetInventoryByDepartmentPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetInventoryByDepartmentCSVReq
 */
export interface GetInventoryByDepartmentCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.InventoryByDepartmentSortBy sort_by = 2;
     */
    sortBy: InventoryByDepartmentSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetInventoryByDepartmentCSVRes
 */
export interface GetInventoryByDepartmentCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Inventory By Product
 *
 * @generated from protobuf message rd.search.service.GetInventoryByProductReq
 */
export interface GetInventoryByProductReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.InventoryByProductSortBy sort_by = 2;
     */
    sortBy: InventoryByProductSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetInventoryByProductRes
 */
export interface GetInventoryByProductRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetInventoryByProductPDFReq
 */
export interface GetInventoryByProductPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.InventoryByProductSortBy sort_by = 2;
     */
    sortBy: InventoryByProductSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetInventoryByProductPDFRes
 */
export interface GetInventoryByProductPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetInventoryByProductCSVReq
 */
export interface GetInventoryByProductCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.InventoryByProductSortBy sort_by = 2;
     */
    sortBy: InventoryByProductSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetInventoryByProductCSVRes
 */
export interface GetInventoryByProductCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Transfer Discrepancies
 *
 * @generated from protobuf message rd.search.service.GetTransferDiscrepanciesReq
 */
export interface GetTransferDiscrepanciesReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.TransferDiscrepanciesSortBy sort_by = 2;
     */
    sortBy: TransferDiscrepanciesSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetTransferDiscrepanciesRes
 */
export interface GetTransferDiscrepanciesRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetTransferDiscrepanciesPDFReq
 */
export interface GetTransferDiscrepanciesPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.TransferDiscrepanciesSortBy sort_by = 2;
     */
    sortBy: TransferDiscrepanciesSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetTransferDiscrepanciesPDFRes
 */
export interface GetTransferDiscrepanciesPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetTransferDiscrepanciesCSVReq
 */
export interface GetTransferDiscrepanciesCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.TransferDiscrepanciesSortBy sort_by = 2;
     */
    sortBy: TransferDiscrepanciesSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetTransferDiscrepanciesCSVRes
 */
export interface GetTransferDiscrepanciesCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Interactions By Staff
 *
 * @generated from protobuf message rd.search.service.GetInteractionsByStaffReq
 */
export interface GetInteractionsByStaffReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.InteractionsByStaffSortBy sort_by = 2;
     */
    sortBy: InteractionsByStaffSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetInteractionsByStaffRes
 */
export interface GetInteractionsByStaffRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetInteractionsByStaffPDFReq
 */
export interface GetInteractionsByStaffPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.InteractionsByStaffSortBy sort_by = 2;
     */
    sortBy: InteractionsByStaffSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetInteractionsByStaffPDFRes
 */
export interface GetInteractionsByStaffPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetInteractionsByStaffCSVReq
 */
export interface GetInteractionsByStaffCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.InteractionsByStaffSortBy sort_by = 2;
     */
    sortBy: InteractionsByStaffSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetInteractionsByStaffCSVRes
 */
export interface GetInteractionsByStaffCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Interactions
 *
 * @generated from protobuf message rd.search.service.GetInteractionsReq
 */
export interface GetInteractionsReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.InteractionsSortBy sort_by = 2;
     */
    sortBy: InteractionsSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetInteractionsRes
 */
export interface GetInteractionsRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.search.service.GetInteractionsPDFReq
 */
export interface GetInteractionsPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.InteractionsSortBy sort_by = 2;
     */
    sortBy: InteractionsSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetInteractionsPDFRes
 */
export interface GetInteractionsPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetInteractionsCSVReq
 */
export interface GetInteractionsCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 1;
     */
    filters?: Filters;
    /**
     * @generated from protobuf field: rd.analyticsv2.InteractionsSortBy sort_by = 2;
     */
    sortBy: InteractionsSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder$;
}
/**
 * @generated from protobuf message rd.search.service.GetInteractionsCSVRes
 */
export interface GetInteractionsCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.GetReportBuilderReq
 */
export interface GetReportBuilderReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.reportbuilder.ReportParams params = 1;
     */
    params?: ReportParams;
}
/**
 * @generated from protobuf message rd.search.service.GetReportBuilderRes
 */
export interface GetReportBuilderRes {
    /**
     * @generated from protobuf field: rd.analyticsv2.reportbuilder.Report report = 1;
     */
    report?: Report$;
    /**
     * @generated from protobuf field: rd.analyticsv2.reportbuilder.Report compare_report = 2;
     */
    compareReport?: Report$;
}
/**
 * @generated from protobuf enum rd.search.service.SortOrder
 */
export enum SortOrder {
    /**
     * @generated from protobuf enum value: SORT_ORDER_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: SORT_ORDER_DESC = 1;
     */
    DESC = 1,
    /**
     * @generated from protobuf enum value: SORT_ORDER_ASC = 2;
     */
    ASC = 2
}
/**
 * @generated from protobuf enum rd.search.service.BoolFilter
 */
export enum BoolFilter {
    /**
     * @generated from protobuf enum value: BOOL_FILTER_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: BOOL_FILTER_FALSE = 1;
     */
    FALSE = 1,
    /**
     * @generated from protobuf enum value: BOOL_FILTER_TRUE = 2;
     */
    TRUE = 2
}
/**
 * @generated from protobuf enum rd.search.service.OrderCustomersBy
 */
export enum OrderCustomersBy {
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_NAME = 1;
     */
    NAME = 1,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_CREDIT_LIMIT = 2;
     */
    CREDIT_LIMIT = 2,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_TOTAL_BALANCE = 3;
     */
    TOTAL_BALANCE = 3,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_CURRENT_BALANCE = 4;
     */
    CURRENT_BALANCE = 4,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_LATE_1_TO_30_BALANCE = 5;
     */
    LATE_1_TO_30_BALANCE = 5,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_LATE_31_TO_60_BALANCE = 6;
     */
    LATE_31_TO_60_BALANCE = 6,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_LATE_61_PLUS_BALANCE = 7;
     */
    LATE_61_PLUS_BALANCE = 7,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_CREDITS_BALANCE = 8;
     */
    CREDITS_BALANCE = 8,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_FINANCING_CHARGES_BALANCE = 9;
     */
    FINANCING_CHARGES_BALANCE = 9,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_SALES_YTD = 10;
     */
    SALES_YTD = 10,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_SALES_VS_LAST_YEAR = 11;
     */
    SALES_VS_LAST_YEAR = 11,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_MARGIN_YTD = 12;
     */
    MARGIN_YTD = 12,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_SALES_LAST_12MO = 13;
     */
    SALES_LAST_12MO = 13,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_SALES_LAST_3MO = 14;
     */
    SALES_LAST_3MO = 14,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_SALES_LAST_1MO = 15;
     */
    SALES_LAST_1MO = 15,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_LAST_SALE = 16;
     */
    LAST_SALE = 16,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_LAST_INTERACTION_DATE = 17;
     */
    LAST_INTERACTION_DATE = 17
}
/**
 * @generated from protobuf enum rd.search.service.OrderCustomerContactsBy
 */
export enum OrderCustomerContactsBy {
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMER_CONTACTS_BY_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMER_CONTACTS_BY_CUSTOMER_IDENTIFIER = 1;
     */
    CUSTOMER_IDENTIFIER = 1,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMER_CONTACTS_BY_CUSTOMER_NAME = 2;
     */
    CUSTOMER_NAME = 2,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMER_CONTACTS_BY_CONTACT_NAME = 3;
     */
    CONTACT_NAME = 3
}
/**
 * @generated from protobuf enum rd.search.service.SearchProductOrderBy
 */
export enum SearchProductOrderBy {
    /**
     * @generated from protobuf enum value: ORDER_BY_NONE = 0;
     */
    ORDER_BY_NONE = 0,
    /**
     * @generated from protobuf enum value: ORDER_BY_NAME = 1;
     */
    ORDER_BY_NAME = 1,
    /**
     * @generated from protobuf enum value: ORDER_BY_INVENTORY_LOCATION_ID = 2;
     */
    ORDER_BY_INVENTORY_LOCATION_ID = 2,
    /**
     * @generated from protobuf enum value: ORDER_BY_TARGET = 3;
     */
    ORDER_BY_TARGET = 3,
    /**
     * @generated from protobuf enum value: ORDER_BY_CURRENT = 4;
     */
    ORDER_BY_CURRENT = 4,
    /**
     * @generated from protobuf enum value: ORDER_BY_REORDER = 5;
     */
    ORDER_BY_REORDER = 5,
    /**
     * @generated from protobuf enum value: ORDER_BY_DELTA = 6;
     */
    ORDER_BY_DELTA = 6,
    /**
     * @generated from protobuf enum value: ORDER_BY_UNITS_SOLD_YTD = 7;
     */
    ORDER_BY_UNITS_SOLD_YTD = 7,
    /**
     * @generated from protobuf enum value: ORDER_BY_UNITS_VS_LAST_YEAR = 8;
     */
    ORDER_BY_UNITS_VS_LAST_YEAR = 8,
    /**
     * @generated from protobuf enum value: ORDER_BY_MARGIN = 9;
     */
    ORDER_BY_MARGIN = 9,
    /**
     * @generated from protobuf enum value: ORDER_BY_UNITS_LAST_12MO = 10;
     */
    ORDER_BY_UNITS_LAST_12MO = 10,
    /**
     * @generated from protobuf enum value: ORDER_BY_UNITS_LAST_3MO = 11;
     */
    ORDER_BY_UNITS_LAST_3MO = 11,
    /**
     * @generated from protobuf enum value: ORDER_BY_UNITS_LAST_1MO = 12;
     */
    ORDER_BY_UNITS_LAST_1MO = 12,
    /**
     * @generated from protobuf enum value: ORDER_BY_LAST_SALE = 13;
     */
    ORDER_BY_LAST_SALE = 13,
    /**
     * @generated from protobuf enum value: ORDER_BY_TURNS = 14;
     */
    ORDER_BY_TURNS = 14
}
/**
 * @generated from protobuf enum rd.search.service.CustomPriceRuleSortBy
 */
export enum CustomPriceRuleSortBy {
    /**
     * @generated from protobuf enum value: CUSTOM_PRICE_RULE_SORT_BY_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: CUSTOM_PRICE_RULE_SORT_BY_EXPIRES = 1;
     */
    EXPIRES = 1,
    /**
     * @generated from protobuf enum value: CUSTOM_PRICE_RULE_SORT_BY_NAME = 2;
     */
    NAME = 2
}
/**
 * @generated from protobuf enum rd.search.service.CustomPriceRuleType
 */
export enum CustomPriceRuleType {
    /**
     * @generated from protobuf enum value: CUSTOM_PRICE_RULE_TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: CUSTOM_PRICE_RULE_TYPE_CUSTOM = 1;
     */
    CUSTOM = 1,
    /**
     * @generated from protobuf enum value: CUSTOM_PRICE_RULE_TYPE_PROMOTION = 2;
     */
    PROMOTION = 2
}
/**
 * @generated from protobuf enum rd.search.service.OrderJobsBy
 */
export enum OrderJobsBy {
    /**
     * @generated from protobuf enum value: ORDER_JOBS_BY_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: ORDER_JOBS_BY_IDENTIFIER = 1;
     */
    IDENTIFIER = 1,
    /**
     * @generated from protobuf enum value: ORDER_JOBS_BY_NAME = 2;
     */
    NAME = 2,
    /**
     * @generated from protobuf enum value: ORDER_JOBS_BY_TOTAL_BALANCE = 3;
     */
    TOTAL_BALANCE = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class SearchBillPaymentsReq$Type extends MessageType<SearchBillPaymentsReq> {
    constructor() {
        super("rd.search.service.SearchBillPaymentsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "created_at_range", kind: "message", T: () => TimestampRange },
            { no: 3, name: "customer_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 4, name: "created_by_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 5, name: "location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 6, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "purchase_methods", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.txn.PurchaseMethod.Method", PurchaseMethod_Method, "METHOD_"] },
            { no: 12, name: "bill_payment_types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.ledger.LedgerEntry.Type", LedgerEntry_Type, "TYPE_"] },
            { no: 11, name: "credit_filters", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.search.service.SearchBillPaymentsReq.CreditFilter", SearchBillPaymentsReq_CreditFilter] }
        ]);
    }
    create(value?: PartialMessage<SearchBillPaymentsReq>): SearchBillPaymentsReq {
        const message = { query: "", customerIds: [], createdByIds: [], locationIds: [], cursor: "", offset: 0, limit: 0, purchaseMethods: [], billPaymentTypes: [], creditFilters: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchBillPaymentsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchBillPaymentsReq): SearchBillPaymentsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.timestamprange.TimestampRange created_at_range */ 2:
                    message.createdAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.createdAtRange);
                    break;
                case /* repeated rd.proto.uuid.UUID customer_ids */ 3:
                    message.customerIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID created_by_ids */ 4:
                    message.createdByIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID location_ids */ 5:
                    message.locationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 6:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 7:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 8:
                    message.limit = reader.int32();
                    break;
                case /* repeated rd.txn.PurchaseMethod.Method purchase_methods */ 9:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.purchaseMethods.push(reader.int32());
                    else
                        message.purchaseMethods.push(reader.int32());
                    break;
                case /* repeated rd.ledger.LedgerEntry.Type bill_payment_types */ 12:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.billPaymentTypes.push(reader.int32());
                    else
                        message.billPaymentTypes.push(reader.int32());
                    break;
                case /* repeated rd.search.service.SearchBillPaymentsReq.CreditFilter credit_filters */ 11:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.creditFilters.push(reader.int32());
                    else
                        message.creditFilters.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchBillPaymentsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.timestamprange.TimestampRange created_at_range = 2; */
        if (message.createdAtRange)
            TimestampRange.internalBinaryWrite(message.createdAtRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID customer_ids = 3; */
        for (let i = 0; i < message.customerIds.length; i++)
            UUID.internalBinaryWrite(message.customerIds[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID created_by_ids = 4; */
        for (let i = 0; i < message.createdByIds.length; i++)
            UUID.internalBinaryWrite(message.createdByIds[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID location_ids = 5; */
        for (let i = 0; i < message.locationIds.length; i++)
            UUID.internalBinaryWrite(message.locationIds[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 6; */
        if (message.cursor !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 7; */
        if (message.offset !== 0)
            writer.tag(7, WireType.Varint).int32(message.offset);
        /* int32 limit = 8; */
        if (message.limit !== 0)
            writer.tag(8, WireType.Varint).int32(message.limit);
        /* repeated rd.txn.PurchaseMethod.Method purchase_methods = 9; */
        if (message.purchaseMethods.length) {
            writer.tag(9, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.purchaseMethods.length; i++)
                writer.int32(message.purchaseMethods[i]);
            writer.join();
        }
        /* repeated rd.ledger.LedgerEntry.Type bill_payment_types = 12; */
        if (message.billPaymentTypes.length) {
            writer.tag(12, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.billPaymentTypes.length; i++)
                writer.int32(message.billPaymentTypes[i]);
            writer.join();
        }
        /* repeated rd.search.service.SearchBillPaymentsReq.CreditFilter credit_filters = 11; */
        if (message.creditFilters.length) {
            writer.tag(11, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.creditFilters.length; i++)
                writer.int32(message.creditFilters[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchBillPaymentsReq
 */
export const SearchBillPaymentsReq = new SearchBillPaymentsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchBillPaymentsRes$Type extends MessageType<SearchBillPaymentsRes> {
    constructor() {
        super("rd.search.service.SearchBillPaymentsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchBillPaymentsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchBillPaymentsRes>): SearchBillPaymentsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchBillPaymentsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchBillPaymentsRes): SearchBillPaymentsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchBillPaymentsRes.Result results */ 1:
                    message.results.push(SearchBillPaymentsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchBillPaymentsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchBillPaymentsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchBillPaymentsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchBillPaymentsRes
 */
export const SearchBillPaymentsRes = new SearchBillPaymentsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchBillPaymentsRes_Result$Type extends MessageType<SearchBillPaymentsRes_Result> {
    constructor() {
        super("rd.search.service.SearchBillPaymentsRes.Result", [
            { no: 1, name: "bill_payment", kind: "message", T: () => BillPayment },
            { no: 2, name: "payment_ledger_entry", kind: "message", T: () => LedgerEntry },
            { no: 3, name: "write_off_ledger_entry", kind: "message", T: () => LedgerEntry },
            { no: 4, name: "sales_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Sale } },
            { no: 5, name: "allocations_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 6, name: "totals_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 7, name: "purchase_orders_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => PurchaseOrder } },
            { no: 9, name: "locations_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Location } },
            { no: 10, name: "customer", kind: "message", T: () => Customer },
            { no: 11, name: "payment_card", kind: "message", T: () => Card },
            { no: 12, name: "payment_method", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "customers_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Customer } }
        ]);
    }
    create(value?: PartialMessage<SearchBillPaymentsRes_Result>): SearchBillPaymentsRes_Result {
        const message = { salesById: {}, allocationsBySaleId: {}, totalsBySaleId: {}, purchaseOrdersBySaleId: {}, locationsBySaleId: {}, paymentMethod: "", customersBySaleId: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchBillPaymentsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchBillPaymentsRes_Result): SearchBillPaymentsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.BillPayment bill_payment */ 1:
                    message.billPayment = BillPayment.internalBinaryRead(reader, reader.uint32(), options, message.billPayment);
                    break;
                case /* rd.ledger.LedgerEntry payment_ledger_entry */ 2:
                    message.paymentLedgerEntry = LedgerEntry.internalBinaryRead(reader, reader.uint32(), options, message.paymentLedgerEntry);
                    break;
                case /* rd.ledger.LedgerEntry write_off_ledger_entry */ 3:
                    message.writeOffLedgerEntry = LedgerEntry.internalBinaryRead(reader, reader.uint32(), options, message.writeOffLedgerEntry);
                    break;
                case /* map<string, rd.txn.Sale> sales_by_id */ 4:
                    this.binaryReadMap4(message.salesById, reader, options);
                    break;
                case /* map<string, rd.proto.money.Money> allocations_by_sale_id */ 5:
                    this.binaryReadMap5(message.allocationsBySaleId, reader, options);
                    break;
                case /* map<string, rd.proto.money.Money> totals_by_sale_id */ 6:
                    this.binaryReadMap6(message.totalsBySaleId, reader, options);
                    break;
                case /* map<string, rd.customer.PurchaseOrder> purchase_orders_by_sale_id */ 7:
                    this.binaryReadMap7(message.purchaseOrdersBySaleId, reader, options);
                    break;
                case /* map<string, rd.company.Location> locations_by_sale_id */ 9:
                    this.binaryReadMap9(message.locationsBySaleId, reader, options);
                    break;
                case /* rd.customer.Customer customer */ 10:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.payments.Card payment_card */ 11:
                    message.paymentCard = Card.internalBinaryRead(reader, reader.uint32(), options, message.paymentCard);
                    break;
                case /* string payment_method */ 12:
                    message.paymentMethod = reader.string();
                    break;
                case /* map<string, rd.customer.Customer> customers_by_sale_id */ 13:
                    this.binaryReadMap13(message.customersBySaleId, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: SearchBillPaymentsRes_Result["salesById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchBillPaymentsRes_Result["salesById"] | undefined, val: SearchBillPaymentsRes_Result["salesById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Sale.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchBillPaymentsRes.Result.sales_by_id");
            }
        }
        map[key ?? ""] = val ?? Sale.create();
    }
    private binaryReadMap5(map: SearchBillPaymentsRes_Result["allocationsBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchBillPaymentsRes_Result["allocationsBySaleId"] | undefined, val: SearchBillPaymentsRes_Result["allocationsBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchBillPaymentsRes.Result.allocations_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap6(map: SearchBillPaymentsRes_Result["totalsBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchBillPaymentsRes_Result["totalsBySaleId"] | undefined, val: SearchBillPaymentsRes_Result["totalsBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchBillPaymentsRes.Result.totals_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap7(map: SearchBillPaymentsRes_Result["purchaseOrdersBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchBillPaymentsRes_Result["purchaseOrdersBySaleId"] | undefined, val: SearchBillPaymentsRes_Result["purchaseOrdersBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchBillPaymentsRes.Result.purchase_orders_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? PurchaseOrder.create();
    }
    private binaryReadMap9(map: SearchBillPaymentsRes_Result["locationsBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchBillPaymentsRes_Result["locationsBySaleId"] | undefined, val: SearchBillPaymentsRes_Result["locationsBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Location.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchBillPaymentsRes.Result.locations_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Location.create();
    }
    private binaryReadMap13(map: SearchBillPaymentsRes_Result["customersBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchBillPaymentsRes_Result["customersBySaleId"] | undefined, val: SearchBillPaymentsRes_Result["customersBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Customer.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchBillPaymentsRes.Result.customers_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Customer.create();
    }
    internalBinaryWrite(message: SearchBillPaymentsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.BillPayment bill_payment = 1; */
        if (message.billPayment)
            BillPayment.internalBinaryWrite(message.billPayment, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry payment_ledger_entry = 2; */
        if (message.paymentLedgerEntry)
            LedgerEntry.internalBinaryWrite(message.paymentLedgerEntry, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry write_off_ledger_entry = 3; */
        if (message.writeOffLedgerEntry)
            LedgerEntry.internalBinaryWrite(message.writeOffLedgerEntry, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.txn.Sale> sales_by_id = 4; */
        for (let k of Object.keys(message.salesById)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Sale.internalBinaryWrite(message.salesById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.proto.money.Money> allocations_by_sale_id = 5; */
        for (let k of Object.keys(message.allocationsBySaleId)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.allocationsBySaleId[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.proto.money.Money> totals_by_sale_id = 6; */
        for (let k of Object.keys(message.totalsBySaleId)) {
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.totalsBySaleId[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.customer.PurchaseOrder> purchase_orders_by_sale_id = 7; */
        for (let k of Object.keys(message.purchaseOrdersBySaleId)) {
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            PurchaseOrder.internalBinaryWrite(message.purchaseOrdersBySaleId[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.company.Location> locations_by_sale_id = 9; */
        for (let k of Object.keys(message.locationsBySaleId)) {
            writer.tag(9, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Location.internalBinaryWrite(message.locationsBySaleId[k], writer, options);
            writer.join().join();
        }
        /* rd.customer.Customer customer = 10; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.Card payment_card = 11; */
        if (message.paymentCard)
            Card.internalBinaryWrite(message.paymentCard, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* string payment_method = 12; */
        if (message.paymentMethod !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.paymentMethod);
        /* map<string, rd.customer.Customer> customers_by_sale_id = 13; */
        for (let k of Object.keys(message.customersBySaleId)) {
            writer.tag(13, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Customer.internalBinaryWrite(message.customersBySaleId[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchBillPaymentsRes.Result
 */
export const SearchBillPaymentsRes_Result = new SearchBillPaymentsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchRefundedBillPaymentsReq$Type extends MessageType<SearchRefundedBillPaymentsReq> {
    constructor() {
        super("rd.search.service.SearchRefundedBillPaymentsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "created_at_range", kind: "message", T: () => TimestampRange },
            { no: 3, name: "customer_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 4, name: "created_by_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 5, name: "location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 6, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchRefundedBillPaymentsReq>): SearchRefundedBillPaymentsReq {
        const message = { query: "", customerIds: [], createdByIds: [], locationIds: [], cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchRefundedBillPaymentsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchRefundedBillPaymentsReq): SearchRefundedBillPaymentsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.timestamprange.TimestampRange created_at_range */ 2:
                    message.createdAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.createdAtRange);
                    break;
                case /* repeated rd.proto.uuid.UUID customer_ids */ 3:
                    message.customerIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID created_by_ids */ 4:
                    message.createdByIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID location_ids */ 5:
                    message.locationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 6:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 7:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 8:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchRefundedBillPaymentsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.timestamprange.TimestampRange created_at_range = 2; */
        if (message.createdAtRange)
            TimestampRange.internalBinaryWrite(message.createdAtRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID customer_ids = 3; */
        for (let i = 0; i < message.customerIds.length; i++)
            UUID.internalBinaryWrite(message.customerIds[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID created_by_ids = 4; */
        for (let i = 0; i < message.createdByIds.length; i++)
            UUID.internalBinaryWrite(message.createdByIds[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID location_ids = 5; */
        for (let i = 0; i < message.locationIds.length; i++)
            UUID.internalBinaryWrite(message.locationIds[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 6; */
        if (message.cursor !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 7; */
        if (message.offset !== 0)
            writer.tag(7, WireType.Varint).int32(message.offset);
        /* int32 limit = 8; */
        if (message.limit !== 0)
            writer.tag(8, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchRefundedBillPaymentsReq
 */
export const SearchRefundedBillPaymentsReq = new SearchRefundedBillPaymentsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchRefundedBillPaymentsRes$Type extends MessageType<SearchRefundedBillPaymentsRes> {
    constructor() {
        super("rd.search.service.SearchRefundedBillPaymentsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchRefundedBillPaymentsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchRefundedBillPaymentsRes>): SearchRefundedBillPaymentsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchRefundedBillPaymentsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchRefundedBillPaymentsRes): SearchRefundedBillPaymentsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchRefundedBillPaymentsRes.Result results */ 1:
                    message.results.push(SearchRefundedBillPaymentsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchRefundedBillPaymentsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchRefundedBillPaymentsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchRefundedBillPaymentsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchRefundedBillPaymentsRes
 */
export const SearchRefundedBillPaymentsRes = new SearchRefundedBillPaymentsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchRefundedBillPaymentsRes_Result$Type extends MessageType<SearchRefundedBillPaymentsRes_Result> {
    constructor() {
        super("rd.search.service.SearchRefundedBillPaymentsRes.Result", [
            { no: 1, name: "payment_ledger_entry_id", kind: "message", T: () => UUID },
            { no: 2, name: "bill_payment", kind: "message", T: () => BillPayment },
            { no: 3, name: "payment_ledger_entry", kind: "message", T: () => LedgerEntry },
            { no: 4, name: "sales_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Sale } },
            { no: 5, name: "allocations_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 6, name: "totals_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 7, name: "purchase_orders_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => PurchaseOrder } },
            { no: 9, name: "locations_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Location } },
            { no: 10, name: "customer", kind: "message", T: () => Customer },
            { no: 11, name: "payment_card", kind: "message", T: () => Card },
            { no: 12, name: "payment_method", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchRefundedBillPaymentsRes_Result>): SearchRefundedBillPaymentsRes_Result {
        const message = { salesById: {}, allocationsBySaleId: {}, totalsBySaleId: {}, purchaseOrdersBySaleId: {}, locationsBySaleId: {}, paymentMethod: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchRefundedBillPaymentsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchRefundedBillPaymentsRes_Result): SearchRefundedBillPaymentsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID payment_ledger_entry_id */ 1:
                    message.paymentLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.paymentLedgerEntryId);
                    break;
                case /* rd.txn.BillPayment bill_payment */ 2:
                    message.billPayment = BillPayment.internalBinaryRead(reader, reader.uint32(), options, message.billPayment);
                    break;
                case /* rd.ledger.LedgerEntry payment_ledger_entry */ 3:
                    message.paymentLedgerEntry = LedgerEntry.internalBinaryRead(reader, reader.uint32(), options, message.paymentLedgerEntry);
                    break;
                case /* map<string, rd.txn.Sale> sales_by_id */ 4:
                    this.binaryReadMap4(message.salesById, reader, options);
                    break;
                case /* map<string, rd.proto.money.Money> allocations_by_sale_id */ 5:
                    this.binaryReadMap5(message.allocationsBySaleId, reader, options);
                    break;
                case /* map<string, rd.proto.money.Money> totals_by_sale_id */ 6:
                    this.binaryReadMap6(message.totalsBySaleId, reader, options);
                    break;
                case /* map<string, rd.customer.PurchaseOrder> purchase_orders_by_sale_id */ 7:
                    this.binaryReadMap7(message.purchaseOrdersBySaleId, reader, options);
                    break;
                case /* map<string, rd.company.Location> locations_by_sale_id */ 9:
                    this.binaryReadMap9(message.locationsBySaleId, reader, options);
                    break;
                case /* rd.customer.Customer customer */ 10:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.payments.Card payment_card */ 11:
                    message.paymentCard = Card.internalBinaryRead(reader, reader.uint32(), options, message.paymentCard);
                    break;
                case /* string payment_method */ 12:
                    message.paymentMethod = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: SearchRefundedBillPaymentsRes_Result["salesById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchRefundedBillPaymentsRes_Result["salesById"] | undefined, val: SearchRefundedBillPaymentsRes_Result["salesById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Sale.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchRefundedBillPaymentsRes.Result.sales_by_id");
            }
        }
        map[key ?? ""] = val ?? Sale.create();
    }
    private binaryReadMap5(map: SearchRefundedBillPaymentsRes_Result["allocationsBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchRefundedBillPaymentsRes_Result["allocationsBySaleId"] | undefined, val: SearchRefundedBillPaymentsRes_Result["allocationsBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchRefundedBillPaymentsRes.Result.allocations_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap6(map: SearchRefundedBillPaymentsRes_Result["totalsBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchRefundedBillPaymentsRes_Result["totalsBySaleId"] | undefined, val: SearchRefundedBillPaymentsRes_Result["totalsBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchRefundedBillPaymentsRes.Result.totals_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap7(map: SearchRefundedBillPaymentsRes_Result["purchaseOrdersBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchRefundedBillPaymentsRes_Result["purchaseOrdersBySaleId"] | undefined, val: SearchRefundedBillPaymentsRes_Result["purchaseOrdersBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchRefundedBillPaymentsRes.Result.purchase_orders_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? PurchaseOrder.create();
    }
    private binaryReadMap9(map: SearchRefundedBillPaymentsRes_Result["locationsBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchRefundedBillPaymentsRes_Result["locationsBySaleId"] | undefined, val: SearchRefundedBillPaymentsRes_Result["locationsBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Location.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchRefundedBillPaymentsRes.Result.locations_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Location.create();
    }
    internalBinaryWrite(message: SearchRefundedBillPaymentsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID payment_ledger_entry_id = 1; */
        if (message.paymentLedgerEntryId)
            UUID.internalBinaryWrite(message.paymentLedgerEntryId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.BillPayment bill_payment = 2; */
        if (message.billPayment)
            BillPayment.internalBinaryWrite(message.billPayment, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry payment_ledger_entry = 3; */
        if (message.paymentLedgerEntry)
            LedgerEntry.internalBinaryWrite(message.paymentLedgerEntry, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.txn.Sale> sales_by_id = 4; */
        for (let k of Object.keys(message.salesById)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Sale.internalBinaryWrite(message.salesById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.proto.money.Money> allocations_by_sale_id = 5; */
        for (let k of Object.keys(message.allocationsBySaleId)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.allocationsBySaleId[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.proto.money.Money> totals_by_sale_id = 6; */
        for (let k of Object.keys(message.totalsBySaleId)) {
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.totalsBySaleId[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.customer.PurchaseOrder> purchase_orders_by_sale_id = 7; */
        for (let k of Object.keys(message.purchaseOrdersBySaleId)) {
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            PurchaseOrder.internalBinaryWrite(message.purchaseOrdersBySaleId[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.company.Location> locations_by_sale_id = 9; */
        for (let k of Object.keys(message.locationsBySaleId)) {
            writer.tag(9, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Location.internalBinaryWrite(message.locationsBySaleId[k], writer, options);
            writer.join().join();
        }
        /* rd.customer.Customer customer = 10; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.Card payment_card = 11; */
        if (message.paymentCard)
            Card.internalBinaryWrite(message.paymentCard, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* string payment_method = 12; */
        if (message.paymentMethod !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.paymentMethod);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchRefundedBillPaymentsRes.Result
 */
export const SearchRefundedBillPaymentsRes_Result = new SearchRefundedBillPaymentsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCustomersV2Req$Type extends MessageType<SearchCustomersV2Req> {
    constructor() {
        super("rd.search.service.SearchCustomersV2Req", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "financing_term_id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "pricing_tier_id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "is_on_credit_hold_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 6, name: "rep_staff_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 7, name: "is_active_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 9, name: "sort_order", kind: "enum", T: () => ["rd.search.service.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 10, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "order_by", kind: "enum", T: () => ["rd.search.service.OrderCustomersBy", OrderCustomersBy, "ORDER_CUSTOMERS_BY_"] },
            { no: 14, name: "finance_charge_settings_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 15, name: "tag_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<SearchCustomersV2Req>): SearchCustomersV2Req {
        const message = { query: "", financingTermIdStrings: [], pricingTierIdStrings: [], isOnCreditHoldFilter: 0, repStaffIds: [], isActiveFilter: 0, sortOrder: 0, cursor: "", offset: 0, limit: 0, orderBy: 0, financeChargeSettingsIds: [], tagIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCustomersV2Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCustomersV2Req): SearchCustomersV2Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* repeated string financing_term_id_strings */ 3:
                    message.financingTermIdStrings.push(reader.string());
                    break;
                case /* repeated string pricing_tier_id_strings */ 4:
                    message.pricingTierIdStrings.push(reader.string());
                    break;
                case /* rd.search.service.BoolFilter is_on_credit_hold_filter */ 5:
                    message.isOnCreditHoldFilter = reader.int32();
                    break;
                case /* repeated rd.proto.uuid.UUID rep_staff_ids */ 6:
                    message.repStaffIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.search.service.BoolFilter is_active_filter */ 7:
                    message.isActiveFilter = reader.int32();
                    break;
                case /* rd.search.service.SortOrder sort_order */ 9:
                    message.sortOrder = reader.int32();
                    break;
                case /* string cursor */ 10:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 11:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 12:
                    message.limit = reader.int32();
                    break;
                case /* rd.search.service.OrderCustomersBy order_by */ 13:
                    message.orderBy = reader.int32();
                    break;
                case /* repeated rd.proto.uuid.UUID finance_charge_settings_ids */ 14:
                    message.financeChargeSettingsIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID tag_ids */ 15:
                    message.tagIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCustomersV2Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* repeated string financing_term_id_strings = 3; */
        for (let i = 0; i < message.financingTermIdStrings.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.financingTermIdStrings[i]);
        /* repeated string pricing_tier_id_strings = 4; */
        for (let i = 0; i < message.pricingTierIdStrings.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.pricingTierIdStrings[i]);
        /* rd.search.service.BoolFilter is_on_credit_hold_filter = 5; */
        if (message.isOnCreditHoldFilter !== 0)
            writer.tag(5, WireType.Varint).int32(message.isOnCreditHoldFilter);
        /* repeated rd.proto.uuid.UUID rep_staff_ids = 6; */
        for (let i = 0; i < message.repStaffIds.length; i++)
            UUID.internalBinaryWrite(message.repStaffIds[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.search.service.BoolFilter is_active_filter = 7; */
        if (message.isActiveFilter !== 0)
            writer.tag(7, WireType.Varint).int32(message.isActiveFilter);
        /* rd.search.service.SortOrder sort_order = 9; */
        if (message.sortOrder !== 0)
            writer.tag(9, WireType.Varint).int32(message.sortOrder);
        /* string cursor = 10; */
        if (message.cursor !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 11; */
        if (message.offset !== 0)
            writer.tag(11, WireType.Varint).int32(message.offset);
        /* int32 limit = 12; */
        if (message.limit !== 0)
            writer.tag(12, WireType.Varint).int32(message.limit);
        /* rd.search.service.OrderCustomersBy order_by = 13; */
        if (message.orderBy !== 0)
            writer.tag(13, WireType.Varint).int32(message.orderBy);
        /* repeated rd.proto.uuid.UUID finance_charge_settings_ids = 14; */
        for (let i = 0; i < message.financeChargeSettingsIds.length; i++)
            UUID.internalBinaryWrite(message.financeChargeSettingsIds[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID tag_ids = 15; */
        for (let i = 0; i < message.tagIds.length; i++)
            UUID.internalBinaryWrite(message.tagIds[i], writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCustomersV2Req
 */
export const SearchCustomersV2Req = new SearchCustomersV2Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCustomersV2Res$Type extends MessageType<SearchCustomersV2Res> {
    constructor() {
        super("rd.search.service.SearchCustomersV2Res", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchCustomersV2Res_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchCustomersV2Res>): SearchCustomersV2Res {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCustomersV2Res>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCustomersV2Res): SearchCustomersV2Res {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchCustomersV2Res.Result results */ 1:
                    message.results.push(SearchCustomersV2Res_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCustomersV2Res, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchCustomersV2Res.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchCustomersV2Res_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCustomersV2Res
 */
export const SearchCustomersV2Res = new SearchCustomersV2Res$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCustomersV2Res_Result$Type extends MessageType<SearchCustomersV2Res_Result> {
    constructor() {
        super("rd.search.service.SearchCustomersV2Res.Result", [
            { no: 1, name: "customer", kind: "message", T: () => Customer },
            { no: 2, name: "contacts", kind: "message", T: () => Contacts },
            { no: 4, name: "financing_term", kind: "message", T: () => FinancingTerms_Term },
            { no: 5, name: "pricing_tier", kind: "message", T: () => PricingTiers_Tier },
            { no: 6, name: "balance", kind: "message", T: () => CustomerBalance },
            { no: 7, name: "total_balance", kind: "message", T: () => Money },
            { no: 8, name: "financing_term_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "last_interaction", kind: "message", T: () => Interaction },
            { no: 11, name: "rep_staff", kind: "message", T: () => Staff },
            { no: 13, name: "analytics", kind: "message", T: () => CustomerSalesAnalytics },
            { no: 14, name: "finance_charge_setting", kind: "message", T: () => FinanceChargeSetting },
            { no: 15, name: "tags", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Tag },
            { no: 16, name: "primary_location", kind: "message", T: () => Location }
        ]);
    }
    create(value?: PartialMessage<SearchCustomersV2Res_Result>): SearchCustomersV2Res_Result {
        const message = { financingTermName: "", tags: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCustomersV2Res_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCustomersV2Res_Result): SearchCustomersV2Res_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.customer.Customer customer */ 1:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.customer.Contacts contacts */ 2:
                    message.contacts = Contacts.internalBinaryRead(reader, reader.uint32(), options, message.contacts);
                    break;
                case /* rd.company.FinancingTerms.Term financing_term */ 4:
                    message.financingTerm = FinancingTerms_Term.internalBinaryRead(reader, reader.uint32(), options, message.financingTerm);
                    break;
                case /* rd.company.PricingTiers.Tier pricing_tier */ 5:
                    message.pricingTier = PricingTiers_Tier.internalBinaryRead(reader, reader.uint32(), options, message.pricingTier);
                    break;
                case /* rd.txn.CustomerBalance balance */ 6:
                    message.balance = CustomerBalance.internalBinaryRead(reader, reader.uint32(), options, message.balance);
                    break;
                case /* rd.proto.money.Money total_balance */ 7:
                    message.totalBalance = Money.internalBinaryRead(reader, reader.uint32(), options, message.totalBalance);
                    break;
                case /* string financing_term_name */ 8:
                    message.financingTermName = reader.string();
                    break;
                case /* rd.customer.Interaction last_interaction */ 10:
                    message.lastInteraction = Interaction.internalBinaryRead(reader, reader.uint32(), options, message.lastInteraction);
                    break;
                case /* rd.company.Staff rep_staff */ 11:
                    message.repStaff = Staff.internalBinaryRead(reader, reader.uint32(), options, message.repStaff);
                    break;
                case /* rd.txn.CustomerSalesAnalytics analytics */ 13:
                    message.analytics = CustomerSalesAnalytics.internalBinaryRead(reader, reader.uint32(), options, message.analytics);
                    break;
                case /* rd.company.FinanceChargeSetting finance_charge_setting */ 14:
                    message.financeChargeSetting = FinanceChargeSetting.internalBinaryRead(reader, reader.uint32(), options, message.financeChargeSetting);
                    break;
                case /* repeated rd.tag.Tag tags */ 15:
                    message.tags.push(Tag.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.company.Location primary_location */ 16:
                    message.primaryLocation = Location.internalBinaryRead(reader, reader.uint32(), options, message.primaryLocation);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCustomersV2Res_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.customer.Customer customer = 1; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Contacts contacts = 2; */
        if (message.contacts)
            Contacts.internalBinaryWrite(message.contacts, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.FinancingTerms.Term financing_term = 4; */
        if (message.financingTerm)
            FinancingTerms_Term.internalBinaryWrite(message.financingTerm, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.PricingTiers.Tier pricing_tier = 5; */
        if (message.pricingTier)
            PricingTiers_Tier.internalBinaryWrite(message.pricingTier, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.CustomerBalance balance = 6; */
        if (message.balance)
            CustomerBalance.internalBinaryWrite(message.balance, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total_balance = 7; */
        if (message.totalBalance)
            Money.internalBinaryWrite(message.totalBalance, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string financing_term_name = 8; */
        if (message.financingTermName !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.financingTermName);
        /* rd.customer.Interaction last_interaction = 10; */
        if (message.lastInteraction)
            Interaction.internalBinaryWrite(message.lastInteraction, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Staff rep_staff = 11; */
        if (message.repStaff)
            Staff.internalBinaryWrite(message.repStaff, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.CustomerSalesAnalytics analytics = 13; */
        if (message.analytics)
            CustomerSalesAnalytics.internalBinaryWrite(message.analytics, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.FinanceChargeSetting finance_charge_setting = 14; */
        if (message.financeChargeSetting)
            FinanceChargeSetting.internalBinaryWrite(message.financeChargeSetting, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.tag.Tag tags = 15; */
        for (let i = 0; i < message.tags.length; i++)
            Tag.internalBinaryWrite(message.tags[i], writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location primary_location = 16; */
        if (message.primaryLocation)
            Location.internalBinaryWrite(message.primaryLocation, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCustomersV2Res.Result
 */
export const SearchCustomersV2Res_Result = new SearchCustomersV2Res_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkExportCustomersReq$Type extends MessageType<BulkExportCustomersReq> {
    constructor() {
        super("rd.search.service.BulkExportCustomersReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "financing_term_id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "pricing_tier_id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "is_on_credit_hold_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 6, name: "rep_staff_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 7, name: "is_active_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 8, name: "order_by", kind: "enum", T: () => ["rd.search.service.OrderCustomersBy", OrderCustomersBy, "ORDER_CUSTOMERS_BY_"] },
            { no: 9, name: "sort_order", kind: "enum", T: () => ["rd.search.service.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 10, name: "is_template", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "finance_charge_setting_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 12, name: "tag_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<BulkExportCustomersReq>): BulkExportCustomersReq {
        const message = { query: "", financingTermIdStrings: [], pricingTierIdStrings: [], isOnCreditHoldFilter: 0, repStaffIds: [], isActiveFilter: 0, orderBy: 0, sortOrder: 0, isTemplate: false, financeChargeSettingIds: [], tagIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkExportCustomersReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkExportCustomersReq): BulkExportCustomersReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* repeated string financing_term_id_strings */ 3:
                    message.financingTermIdStrings.push(reader.string());
                    break;
                case /* repeated string pricing_tier_id_strings */ 4:
                    message.pricingTierIdStrings.push(reader.string());
                    break;
                case /* rd.search.service.BoolFilter is_on_credit_hold_filter */ 5:
                    message.isOnCreditHoldFilter = reader.int32();
                    break;
                case /* repeated rd.proto.uuid.UUID rep_staff_ids */ 6:
                    message.repStaffIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.search.service.BoolFilter is_active_filter */ 7:
                    message.isActiveFilter = reader.int32();
                    break;
                case /* rd.search.service.OrderCustomersBy order_by */ 8:
                    message.orderBy = reader.int32();
                    break;
                case /* rd.search.service.SortOrder sort_order */ 9:
                    message.sortOrder = reader.int32();
                    break;
                case /* bool is_template */ 10:
                    message.isTemplate = reader.bool();
                    break;
                case /* repeated rd.proto.uuid.UUID finance_charge_setting_ids */ 11:
                    message.financeChargeSettingIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID tag_ids */ 12:
                    message.tagIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkExportCustomersReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* repeated string financing_term_id_strings = 3; */
        for (let i = 0; i < message.financingTermIdStrings.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.financingTermIdStrings[i]);
        /* repeated string pricing_tier_id_strings = 4; */
        for (let i = 0; i < message.pricingTierIdStrings.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.pricingTierIdStrings[i]);
        /* rd.search.service.BoolFilter is_on_credit_hold_filter = 5; */
        if (message.isOnCreditHoldFilter !== 0)
            writer.tag(5, WireType.Varint).int32(message.isOnCreditHoldFilter);
        /* repeated rd.proto.uuid.UUID rep_staff_ids = 6; */
        for (let i = 0; i < message.repStaffIds.length; i++)
            UUID.internalBinaryWrite(message.repStaffIds[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.search.service.BoolFilter is_active_filter = 7; */
        if (message.isActiveFilter !== 0)
            writer.tag(7, WireType.Varint).int32(message.isActiveFilter);
        /* rd.search.service.OrderCustomersBy order_by = 8; */
        if (message.orderBy !== 0)
            writer.tag(8, WireType.Varint).int32(message.orderBy);
        /* rd.search.service.SortOrder sort_order = 9; */
        if (message.sortOrder !== 0)
            writer.tag(9, WireType.Varint).int32(message.sortOrder);
        /* bool is_template = 10; */
        if (message.isTemplate !== false)
            writer.tag(10, WireType.Varint).bool(message.isTemplate);
        /* repeated rd.proto.uuid.UUID finance_charge_setting_ids = 11; */
        for (let i = 0; i < message.financeChargeSettingIds.length; i++)
            UUID.internalBinaryWrite(message.financeChargeSettingIds[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID tag_ids = 12; */
        for (let i = 0; i < message.tagIds.length; i++)
            UUID.internalBinaryWrite(message.tagIds[i], writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.BulkExportCustomersReq
 */
export const BulkExportCustomersReq = new BulkExportCustomersReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkExportCustomersRes$Type extends MessageType<BulkExportCustomersRes> {
    constructor() {
        super("rd.search.service.BulkExportCustomersRes", [
            { no: 1, name: "excel_bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<BulkExportCustomersRes>): BulkExportCustomersRes {
        const message = { excelBytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkExportCustomersRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkExportCustomersRes): BulkExportCustomersRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes excel_bytes */ 1:
                    message.excelBytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkExportCustomersRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes excel_bytes = 1; */
        if (message.excelBytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.excelBytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.BulkExportCustomersRes
 */
export const BulkExportCustomersRes = new BulkExportCustomersRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCustomerContactsReq$Type extends MessageType<SearchCustomerContactsReq> {
    constructor() {
        super("rd.search.service.SearchCustomerContactsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 3, name: "should_email_invoices_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 4, name: "should_email_statements_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 5, name: "order_by", kind: "enum", T: () => ["rd.search.service.OrderCustomerContactsBy", OrderCustomerContactsBy, "ORDER_CUSTOMER_CONTACTS_BY_"] },
            { no: 6, name: "sort_order", kind: "enum", T: () => ["rd.search.service.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 7, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchCustomerContactsReq>): SearchCustomerContactsReq {
        const message = { query: "", customerIds: [], shouldEmailInvoicesFilter: 0, shouldEmailStatementsFilter: 0, orderBy: 0, sortOrder: 0, cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCustomerContactsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCustomerContactsReq): SearchCustomerContactsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* repeated rd.proto.uuid.UUID customer_ids */ 2:
                    message.customerIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.search.service.BoolFilter should_email_invoices_filter */ 3:
                    message.shouldEmailInvoicesFilter = reader.int32();
                    break;
                case /* rd.search.service.BoolFilter should_email_statements_filter */ 4:
                    message.shouldEmailStatementsFilter = reader.int32();
                    break;
                case /* rd.search.service.OrderCustomerContactsBy order_by */ 5:
                    message.orderBy = reader.int32();
                    break;
                case /* rd.search.service.SortOrder sort_order */ 6:
                    message.sortOrder = reader.int32();
                    break;
                case /* string cursor */ 7:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 8:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 9:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCustomerContactsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* repeated rd.proto.uuid.UUID customer_ids = 2; */
        for (let i = 0; i < message.customerIds.length; i++)
            UUID.internalBinaryWrite(message.customerIds[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.search.service.BoolFilter should_email_invoices_filter = 3; */
        if (message.shouldEmailInvoicesFilter !== 0)
            writer.tag(3, WireType.Varint).int32(message.shouldEmailInvoicesFilter);
        /* rd.search.service.BoolFilter should_email_statements_filter = 4; */
        if (message.shouldEmailStatementsFilter !== 0)
            writer.tag(4, WireType.Varint).int32(message.shouldEmailStatementsFilter);
        /* rd.search.service.OrderCustomerContactsBy order_by = 5; */
        if (message.orderBy !== 0)
            writer.tag(5, WireType.Varint).int32(message.orderBy);
        /* rd.search.service.SortOrder sort_order = 6; */
        if (message.sortOrder !== 0)
            writer.tag(6, WireType.Varint).int32(message.sortOrder);
        /* string cursor = 7; */
        if (message.cursor !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 8; */
        if (message.offset !== 0)
            writer.tag(8, WireType.Varint).int32(message.offset);
        /* int32 limit = 9; */
        if (message.limit !== 0)
            writer.tag(9, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCustomerContactsReq
 */
export const SearchCustomerContactsReq = new SearchCustomerContactsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCustomerContactsRes$Type extends MessageType<SearchCustomerContactsRes> {
    constructor() {
        super("rd.search.service.SearchCustomerContactsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchCustomerContactsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchCustomerContactsRes>): SearchCustomerContactsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCustomerContactsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCustomerContactsRes): SearchCustomerContactsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchCustomerContactsRes.Result results */ 1:
                    message.results.push(SearchCustomerContactsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCustomerContactsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchCustomerContactsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchCustomerContactsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCustomerContactsRes
 */
export const SearchCustomerContactsRes = new SearchCustomerContactsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCustomerContactsRes_Result$Type extends MessageType<SearchCustomerContactsRes_Result> {
    constructor() {
        super("rd.search.service.SearchCustomerContactsRes.Result", [
            { no: 1, name: "customer", kind: "message", T: () => Customer },
            { no: 2, name: "contact", kind: "message", T: () => Contacts_Contact }
        ]);
    }
    create(value?: PartialMessage<SearchCustomerContactsRes_Result>): SearchCustomerContactsRes_Result {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCustomerContactsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCustomerContactsRes_Result): SearchCustomerContactsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.customer.Customer customer */ 1:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.customer.Contacts.Contact contact */ 2:
                    message.contact = Contacts_Contact.internalBinaryRead(reader, reader.uint32(), options, message.contact);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCustomerContactsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.customer.Customer customer = 1; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Contacts.Contact contact = 2; */
        if (message.contact)
            Contacts_Contact.internalBinaryWrite(message.contact, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCustomerContactsRes.Result
 */
export const SearchCustomerContactsRes_Result = new SearchCustomerContactsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkExportCustomerContactsReq$Type extends MessageType<BulkExportCustomerContactsReq> {
    constructor() {
        super("rd.search.service.BulkExportCustomerContactsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 3, name: "should_email_invoices_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 4, name: "should_email_statements_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 5, name: "order_by", kind: "enum", T: () => ["rd.search.service.OrderCustomerContactsBy", OrderCustomerContactsBy, "ORDER_CUSTOMER_CONTACTS_BY_"] },
            { no: 6, name: "sort_order", kind: "enum", T: () => ["rd.search.service.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 7, name: "is_template", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<BulkExportCustomerContactsReq>): BulkExportCustomerContactsReq {
        const message = { query: "", customerIds: [], shouldEmailInvoicesFilter: 0, shouldEmailStatementsFilter: 0, orderBy: 0, sortOrder: 0, isTemplate: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkExportCustomerContactsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkExportCustomerContactsReq): BulkExportCustomerContactsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* repeated rd.proto.uuid.UUID customer_ids */ 2:
                    message.customerIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.search.service.BoolFilter should_email_invoices_filter */ 3:
                    message.shouldEmailInvoicesFilter = reader.int32();
                    break;
                case /* rd.search.service.BoolFilter should_email_statements_filter */ 4:
                    message.shouldEmailStatementsFilter = reader.int32();
                    break;
                case /* rd.search.service.OrderCustomerContactsBy order_by */ 5:
                    message.orderBy = reader.int32();
                    break;
                case /* rd.search.service.SortOrder sort_order */ 6:
                    message.sortOrder = reader.int32();
                    break;
                case /* bool is_template */ 7:
                    message.isTemplate = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkExportCustomerContactsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* repeated rd.proto.uuid.UUID customer_ids = 2; */
        for (let i = 0; i < message.customerIds.length; i++)
            UUID.internalBinaryWrite(message.customerIds[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.search.service.BoolFilter should_email_invoices_filter = 3; */
        if (message.shouldEmailInvoicesFilter !== 0)
            writer.tag(3, WireType.Varint).int32(message.shouldEmailInvoicesFilter);
        /* rd.search.service.BoolFilter should_email_statements_filter = 4; */
        if (message.shouldEmailStatementsFilter !== 0)
            writer.tag(4, WireType.Varint).int32(message.shouldEmailStatementsFilter);
        /* rd.search.service.OrderCustomerContactsBy order_by = 5; */
        if (message.orderBy !== 0)
            writer.tag(5, WireType.Varint).int32(message.orderBy);
        /* rd.search.service.SortOrder sort_order = 6; */
        if (message.sortOrder !== 0)
            writer.tag(6, WireType.Varint).int32(message.sortOrder);
        /* bool is_template = 7; */
        if (message.isTemplate !== false)
            writer.tag(7, WireType.Varint).bool(message.isTemplate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.BulkExportCustomerContactsReq
 */
export const BulkExportCustomerContactsReq = new BulkExportCustomerContactsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkExportCustomerContactsRes$Type extends MessageType<BulkExportCustomerContactsRes> {
    constructor() {
        super("rd.search.service.BulkExportCustomerContactsRes", [
            { no: 1, name: "excel_bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<BulkExportCustomerContactsRes>): BulkExportCustomerContactsRes {
        const message = { excelBytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkExportCustomerContactsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkExportCustomerContactsRes): BulkExportCustomerContactsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes excel_bytes */ 1:
                    message.excelBytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkExportCustomerContactsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes excel_bytes = 1; */
        if (message.excelBytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.excelBytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.BulkExportCustomerContactsRes
 */
export const BulkExportCustomerContactsRes = new BulkExportCustomerContactsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOrdersReq$Type extends MessageType<SearchOrdersReq> {
    constructor() {
        super("rd.search.service.SearchOrdersReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "created_at_range", kind: "message", T: () => TimestampRange },
            { no: 3, name: "ordered_at_range", kind: "message", T: () => TimestampRange },
            { no: 4, name: "received_at_range", kind: "message", T: () => TimestampRange },
            { no: 5, name: "voided_at_range", kind: "message", T: () => TimestampRange },
            { no: 6, name: "location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 7, name: "statuses", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.txn.Order.Status", Order_Status, "STATUS_"] },
            { no: 8, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchOrdersReq>): SearchOrdersReq {
        const message = { query: "", locationIds: [], statuses: [], cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOrdersReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOrdersReq): SearchOrdersReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.timestamprange.TimestampRange created_at_range */ 2:
                    message.createdAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.createdAtRange);
                    break;
                case /* rd.proto.timestamprange.TimestampRange ordered_at_range */ 3:
                    message.orderedAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.orderedAtRange);
                    break;
                case /* rd.proto.timestamprange.TimestampRange received_at_range */ 4:
                    message.receivedAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.receivedAtRange);
                    break;
                case /* rd.proto.timestamprange.TimestampRange voided_at_range */ 5:
                    message.voidedAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.voidedAtRange);
                    break;
                case /* repeated rd.proto.uuid.UUID location_ids */ 6:
                    message.locationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.txn.Order.Status statuses */ 7:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.statuses.push(reader.int32());
                    else
                        message.statuses.push(reader.int32());
                    break;
                case /* string cursor */ 8:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 9:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 10:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOrdersReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.timestamprange.TimestampRange created_at_range = 2; */
        if (message.createdAtRange)
            TimestampRange.internalBinaryWrite(message.createdAtRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange ordered_at_range = 3; */
        if (message.orderedAtRange)
            TimestampRange.internalBinaryWrite(message.orderedAtRange, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange received_at_range = 4; */
        if (message.receivedAtRange)
            TimestampRange.internalBinaryWrite(message.receivedAtRange, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange voided_at_range = 5; */
        if (message.voidedAtRange)
            TimestampRange.internalBinaryWrite(message.voidedAtRange, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID location_ids = 6; */
        for (let i = 0; i < message.locationIds.length; i++)
            UUID.internalBinaryWrite(message.locationIds[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.Order.Status statuses = 7; */
        if (message.statuses.length) {
            writer.tag(7, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.statuses.length; i++)
                writer.int32(message.statuses[i]);
            writer.join();
        }
        /* string cursor = 8; */
        if (message.cursor !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 9; */
        if (message.offset !== 0)
            writer.tag(9, WireType.Varint).int32(message.offset);
        /* int32 limit = 10; */
        if (message.limit !== 0)
            writer.tag(10, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOrdersReq
 */
export const SearchOrdersReq = new SearchOrdersReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOrdersRes$Type extends MessageType<SearchOrdersRes> {
    constructor() {
        super("rd.search.service.SearchOrdersRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchOrdersRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchOrdersRes>): SearchOrdersRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOrdersRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOrdersRes): SearchOrdersRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchOrdersRes.Result results */ 1:
                    message.results.push(SearchOrdersRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOrdersRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchOrdersRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchOrdersRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOrdersRes
 */
export const SearchOrdersRes = new SearchOrdersRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOrdersRes_Result$Type extends MessageType<SearchOrdersRes_Result> {
    constructor() {
        super("rd.search.service.SearchOrdersRes.Result", [
            { no: 1, name: "order", kind: "message", T: () => Order },
            { no: 2, name: "vendor", kind: "message", T: () => Vendor },
            { no: 3, name: "location", kind: "message", T: () => Location },
            { no: 4, name: "products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 5, name: "matched_products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 6, name: "total", kind: "message", T: () => Money },
            { no: 7, name: "order_shipments", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrderShipment }
        ]);
    }
    create(value?: PartialMessage<SearchOrdersRes_Result>): SearchOrdersRes_Result {
        const message = { productsById: {}, matchedProductsById: {}, orderShipments: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOrdersRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOrdersRes_Result): SearchOrdersRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.Order order */ 1:
                    message.order = Order.internalBinaryRead(reader, reader.uint32(), options, message.order);
                    break;
                case /* rd.vendors.Vendor vendor */ 2:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* rd.company.Location location */ 3:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* map<string, rd.product.Product> products_by_id */ 4:
                    this.binaryReadMap4(message.productsById, reader, options);
                    break;
                case /* map<string, rd.product.Product> matched_products_by_id */ 5:
                    this.binaryReadMap5(message.matchedProductsById, reader, options);
                    break;
                case /* rd.proto.money.Money total */ 6:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* repeated rd.txn.OrderShipment order_shipments */ 7:
                    message.orderShipments.push(OrderShipment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: SearchOrdersRes_Result["productsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchOrdersRes_Result["productsById"] | undefined, val: SearchOrdersRes_Result["productsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchOrdersRes.Result.products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    private binaryReadMap5(map: SearchOrdersRes_Result["matchedProductsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchOrdersRes_Result["matchedProductsById"] | undefined, val: SearchOrdersRes_Result["matchedProductsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchOrdersRes.Result.matched_products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    internalBinaryWrite(message: SearchOrdersRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.Order order = 1; */
        if (message.order)
            Order.internalBinaryWrite(message.order, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Vendor vendor = 2; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 3; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.product.Product> products_by_id = 4; */
        for (let k of Object.keys(message.productsById)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.productsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.product.Product> matched_products_by_id = 5; */
        for (let k of Object.keys(message.matchedProductsById)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.matchedProductsById[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money total = 6; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.OrderShipment order_shipments = 7; */
        for (let i = 0; i < message.orderShipments.length; i++)
            OrderShipment.internalBinaryWrite(message.orderShipments[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOrdersRes.Result
 */
export const SearchOrdersRes_Result = new SearchOrdersRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOrderShipmentsReq$Type extends MessageType<SearchOrderShipmentsReq> {
    constructor() {
        super("rd.search.service.SearchOrderShipmentsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 4, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "timestamp_range", kind: "message", T: () => TimestampRange },
            { no: 12, name: "status", kind: "enum", T: () => ["rd.txn.OrderShipment.Status", OrderShipment_Status, "STATUS_"] }
        ]);
    }
    create(value?: PartialMessage<SearchOrderShipmentsReq>): SearchOrderShipmentsReq {
        const message = { query: "", locationIds: [], cursor: "", offset: 0, limit: 0, status: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOrderShipmentsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOrderShipmentsReq): SearchOrderShipmentsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* repeated rd.proto.uuid.UUID location_ids */ 3:
                    message.locationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 4:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 5:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 6:
                    message.limit = reader.int32();
                    break;
                case /* rd.proto.timestamprange.TimestampRange timestamp_range */ 11:
                    message.timestampRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.timestampRange);
                    break;
                case /* rd.txn.OrderShipment.Status status */ 12:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOrderShipmentsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* repeated rd.proto.uuid.UUID location_ids = 3; */
        for (let i = 0; i < message.locationIds.length; i++)
            UUID.internalBinaryWrite(message.locationIds[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 4; */
        if (message.cursor !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 5; */
        if (message.offset !== 0)
            writer.tag(5, WireType.Varint).int32(message.offset);
        /* int32 limit = 6; */
        if (message.limit !== 0)
            writer.tag(6, WireType.Varint).int32(message.limit);
        /* rd.proto.timestamprange.TimestampRange timestamp_range = 11; */
        if (message.timestampRange)
            TimestampRange.internalBinaryWrite(message.timestampRange, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.OrderShipment.Status status = 12; */
        if (message.status !== 0)
            writer.tag(12, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOrderShipmentsReq
 */
export const SearchOrderShipmentsReq = new SearchOrderShipmentsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOrderShipmentsRes$Type extends MessageType<SearchOrderShipmentsRes> {
    constructor() {
        super("rd.search.service.SearchOrderShipmentsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchOrderShipmentsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchOrderShipmentsRes>): SearchOrderShipmentsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOrderShipmentsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOrderShipmentsRes): SearchOrderShipmentsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchOrderShipmentsRes.Result results */ 1:
                    message.results.push(SearchOrderShipmentsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOrderShipmentsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchOrderShipmentsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchOrderShipmentsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOrderShipmentsRes
 */
export const SearchOrderShipmentsRes = new SearchOrderShipmentsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOrderShipmentsRes_Result$Type extends MessageType<SearchOrderShipmentsRes_Result> {
    constructor() {
        super("rd.search.service.SearchOrderShipmentsRes.Result", [
            { no: 1, name: "order_shipment", kind: "message", T: () => OrderShipment },
            { no: 2, name: "vendor", kind: "message", T: () => Vendor },
            { no: 3, name: "location", kind: "message", T: () => Location },
            { no: 4, name: "products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 5, name: "matched_products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 6, name: "total", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<SearchOrderShipmentsRes_Result>): SearchOrderShipmentsRes_Result {
        const message = { productsById: {}, matchedProductsById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOrderShipmentsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOrderShipmentsRes_Result): SearchOrderShipmentsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.OrderShipment order_shipment */ 1:
                    message.orderShipment = OrderShipment.internalBinaryRead(reader, reader.uint32(), options, message.orderShipment);
                    break;
                case /* rd.vendors.Vendor vendor */ 2:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* rd.company.Location location */ 3:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* map<string, rd.product.Product> products_by_id */ 4:
                    this.binaryReadMap4(message.productsById, reader, options);
                    break;
                case /* map<string, rd.product.Product> matched_products_by_id */ 5:
                    this.binaryReadMap5(message.matchedProductsById, reader, options);
                    break;
                case /* rd.proto.money.Money total */ 6:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: SearchOrderShipmentsRes_Result["productsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchOrderShipmentsRes_Result["productsById"] | undefined, val: SearchOrderShipmentsRes_Result["productsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchOrderShipmentsRes.Result.products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    private binaryReadMap5(map: SearchOrderShipmentsRes_Result["matchedProductsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchOrderShipmentsRes_Result["matchedProductsById"] | undefined, val: SearchOrderShipmentsRes_Result["matchedProductsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchOrderShipmentsRes.Result.matched_products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    internalBinaryWrite(message: SearchOrderShipmentsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.OrderShipment order_shipment = 1; */
        if (message.orderShipment)
            OrderShipment.internalBinaryWrite(message.orderShipment, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Vendor vendor = 2; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 3; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.product.Product> products_by_id = 4; */
        for (let k of Object.keys(message.productsById)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.productsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.product.Product> matched_products_by_id = 5; */
        for (let k of Object.keys(message.matchedProductsById)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.matchedProductsById[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money total = 6; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOrderShipmentsRes.Result
 */
export const SearchOrderShipmentsRes_Result = new SearchOrderShipmentsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchProductsV2Req$Type extends MessageType<SearchProductsV2Req> {
    constructor() {
        super("rd.search.service.SearchProductsV2Req", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "inv_sort_location_id", kind: "message", T: () => UUID },
            { no: 3, name: "current_location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 5, name: "vendor_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 6, name: "additional_fee_id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "is_active_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 9, name: "is_tintable_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 10, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "order_by", kind: "enum", T: () => ["rd.search.service.SearchProductsV2Req.OrderBy", SearchProductsV2Req_OrderBy, "ORDER_BY_"] },
            { no: 14, name: "sort_order", kind: "enum", T: () => ["rd.search.service.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 15, name: "tax_statuses", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.product.Product.TaxStatus", Product_TaxStatus, "TAX_STATUS_"] },
            { no: 16, name: "department_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 17, name: "class_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 18, name: "fineline_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 19, name: "tag_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<SearchProductsV2Req>): SearchProductsV2Req {
        const message = { query: "", vendorIds: [], additionalFeeIdStrings: [], isActiveFilter: 0, isTintableFilter: 0, cursor: "", offset: 0, limit: 0, orderBy: 0, sortOrder: 0, taxStatuses: [], departmentIds: [], classIds: [], finelineIds: [], tagIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchProductsV2Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchProductsV2Req): SearchProductsV2Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.uuid.UUID inv_sort_location_id */ 2:
                    message.invSortLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.invSortLocationId);
                    break;
                case /* rd.proto.uuid.UUID current_location_id */ 3:
                    message.currentLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.currentLocationId);
                    break;
                case /* repeated rd.proto.uuid.UUID vendor_ids */ 5:
                    message.vendorIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string additional_fee_id_strings */ 6:
                    message.additionalFeeIdStrings.push(reader.string());
                    break;
                case /* rd.search.service.BoolFilter is_active_filter */ 7:
                    message.isActiveFilter = reader.int32();
                    break;
                case /* rd.search.service.BoolFilter is_tintable_filter */ 9:
                    message.isTintableFilter = reader.int32();
                    break;
                case /* string cursor */ 10:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 11:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 12:
                    message.limit = reader.int32();
                    break;
                case /* rd.search.service.SearchProductsV2Req.OrderBy order_by */ 13:
                    message.orderBy = reader.int32();
                    break;
                case /* rd.search.service.SortOrder sort_order */ 14:
                    message.sortOrder = reader.int32();
                    break;
                case /* repeated rd.product.Product.TaxStatus tax_statuses */ 15:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.taxStatuses.push(reader.int32());
                    else
                        message.taxStatuses.push(reader.int32());
                    break;
                case /* repeated rd.proto.uuid.UUID department_ids */ 16:
                    message.departmentIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID class_ids */ 17:
                    message.classIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID fineline_ids */ 18:
                    message.finelineIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID tag_ids */ 19:
                    message.tagIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchProductsV2Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.uuid.UUID inv_sort_location_id = 2; */
        if (message.invSortLocationId)
            UUID.internalBinaryWrite(message.invSortLocationId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID current_location_id = 3; */
        if (message.currentLocationId)
            UUID.internalBinaryWrite(message.currentLocationId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID vendor_ids = 5; */
        for (let i = 0; i < message.vendorIds.length; i++)
            UUID.internalBinaryWrite(message.vendorIds[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated string additional_fee_id_strings = 6; */
        for (let i = 0; i < message.additionalFeeIdStrings.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.additionalFeeIdStrings[i]);
        /* rd.search.service.BoolFilter is_active_filter = 7; */
        if (message.isActiveFilter !== 0)
            writer.tag(7, WireType.Varint).int32(message.isActiveFilter);
        /* rd.search.service.BoolFilter is_tintable_filter = 9; */
        if (message.isTintableFilter !== 0)
            writer.tag(9, WireType.Varint).int32(message.isTintableFilter);
        /* string cursor = 10; */
        if (message.cursor !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 11; */
        if (message.offset !== 0)
            writer.tag(11, WireType.Varint).int32(message.offset);
        /* int32 limit = 12; */
        if (message.limit !== 0)
            writer.tag(12, WireType.Varint).int32(message.limit);
        /* rd.search.service.SearchProductsV2Req.OrderBy order_by = 13; */
        if (message.orderBy !== 0)
            writer.tag(13, WireType.Varint).int32(message.orderBy);
        /* rd.search.service.SortOrder sort_order = 14; */
        if (message.sortOrder !== 0)
            writer.tag(14, WireType.Varint).int32(message.sortOrder);
        /* repeated rd.product.Product.TaxStatus tax_statuses = 15; */
        if (message.taxStatuses.length) {
            writer.tag(15, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.taxStatuses.length; i++)
                writer.int32(message.taxStatuses[i]);
            writer.join();
        }
        /* repeated rd.proto.uuid.UUID department_ids = 16; */
        for (let i = 0; i < message.departmentIds.length; i++)
            UUID.internalBinaryWrite(message.departmentIds[i], writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID class_ids = 17; */
        for (let i = 0; i < message.classIds.length; i++)
            UUID.internalBinaryWrite(message.classIds[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID fineline_ids = 18; */
        for (let i = 0; i < message.finelineIds.length; i++)
            UUID.internalBinaryWrite(message.finelineIds[i], writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID tag_ids = 19; */
        for (let i = 0; i < message.tagIds.length; i++)
            UUID.internalBinaryWrite(message.tagIds[i], writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchProductsV2Req
 */
export const SearchProductsV2Req = new SearchProductsV2Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchProductsV2Res$Type extends MessageType<SearchProductsV2Res> {
    constructor() {
        super("rd.search.service.SearchProductsV2Res", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchProductsV2Res_Result },
            { no: 2, name: "locations_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Location } },
            { no: 3, name: "pricing_tiers", kind: "message", T: () => PricingTiers },
            { no: 4, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchProductsV2Res>): SearchProductsV2Res {
        const message = { results: [], locationsById: {}, cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchProductsV2Res>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchProductsV2Res): SearchProductsV2Res {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchProductsV2Res.Result results */ 1:
                    message.results.push(SearchProductsV2Res_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* map<string, rd.company.Location> locations_by_id */ 2:
                    this.binaryReadMap2(message.locationsById, reader, options);
                    break;
                case /* rd.company.PricingTiers pricing_tiers */ 3:
                    message.pricingTiers = PricingTiers.internalBinaryRead(reader, reader.uint32(), options, message.pricingTiers);
                    break;
                case /* string cursor */ 4:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: SearchProductsV2Res["locationsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchProductsV2Res["locationsById"] | undefined, val: SearchProductsV2Res["locationsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Location.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchProductsV2Res.locations_by_id");
            }
        }
        map[key ?? ""] = val ?? Location.create();
    }
    internalBinaryWrite(message: SearchProductsV2Res, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchProductsV2Res.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchProductsV2Res_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.company.Location> locations_by_id = 2; */
        for (let k of Object.keys(message.locationsById)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Location.internalBinaryWrite(message.locationsById[k], writer, options);
            writer.join().join();
        }
        /* rd.company.PricingTiers pricing_tiers = 3; */
        if (message.pricingTiers)
            PricingTiers.internalBinaryWrite(message.pricingTiers, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 4; */
        if (message.cursor !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchProductsV2Res
 */
export const SearchProductsV2Res = new SearchProductsV2Res$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchProductsV2Res_Result$Type extends MessageType<SearchProductsV2Res_Result> {
    constructor() {
        super("rd.search.service.SearchProductsV2Res.Result", [
            { no: 1, name: "product", kind: "message", T: () => Product },
            { no: 2, name: "matched_price_product", kind: "message", T: () => Product },
            { no: 3, name: "matched_cost_product", kind: "message", T: () => Product },
            { no: 4, name: "inventories", kind: "message", T: () => Inventories },
            { no: 5, name: "primary_cost_vendor", kind: "message", T: () => Vendor },
            { no: 7, name: "additional_fee", kind: "message", T: () => AdditionalFees_Fee },
            { no: 9, name: "delta", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "analytics", kind: "message", T: () => ProductSalesAnalytics },
            { no: 11, name: "catalog_product", kind: "message", T: () => Product$ },
            { no: 12, name: "department", kind: "message", T: () => ProductCategory },
            { no: 13, name: "class", kind: "message", T: () => ProductCategory },
            { no: 14, name: "fineline", kind: "message", T: () => ProductCategory },
            { no: 15, name: "tier_prices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Tier },
            { no: 16, name: "tags", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Tag }
        ]);
    }
    create(value?: PartialMessage<SearchProductsV2Res_Result>): SearchProductsV2Res_Result {
        const message = { delta: 0, tierPrices: [], tags: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchProductsV2Res_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchProductsV2Res_Result): SearchProductsV2Res_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.product.Product product */ 1:
                    message.product = Product.internalBinaryRead(reader, reader.uint32(), options, message.product);
                    break;
                case /* rd.product.Product matched_price_product */ 2:
                    message.matchedPriceProduct = Product.internalBinaryRead(reader, reader.uint32(), options, message.matchedPriceProduct);
                    break;
                case /* rd.product.Product matched_cost_product */ 3:
                    message.matchedCostProduct = Product.internalBinaryRead(reader, reader.uint32(), options, message.matchedCostProduct);
                    break;
                case /* rd.product.Inventories inventories */ 4:
                    message.inventories = Inventories.internalBinaryRead(reader, reader.uint32(), options, message.inventories);
                    break;
                case /* rd.vendors.Vendor primary_cost_vendor */ 5:
                    message.primaryCostVendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.primaryCostVendor);
                    break;
                case /* rd.company.AdditionalFees.Fee additional_fee */ 7:
                    message.additionalFee = AdditionalFees_Fee.internalBinaryRead(reader, reader.uint32(), options, message.additionalFee);
                    break;
                case /* int32 delta */ 9:
                    message.delta = reader.int32();
                    break;
                case /* rd.txn.ProductSalesAnalytics analytics */ 10:
                    message.analytics = ProductSalesAnalytics.internalBinaryRead(reader, reader.uint32(), options, message.analytics);
                    break;
                case /* rd.catalog.Product catalog_product */ 11:
                    message.catalogProduct = Product$.internalBinaryRead(reader, reader.uint32(), options, message.catalogProduct);
                    break;
                case /* rd.company.ProductCategory department */ 12:
                    message.department = ProductCategory.internalBinaryRead(reader, reader.uint32(), options, message.department);
                    break;
                case /* rd.company.ProductCategory class */ 13:
                    message.class = ProductCategory.internalBinaryRead(reader, reader.uint32(), options, message.class);
                    break;
                case /* rd.company.ProductCategory fineline */ 14:
                    message.fineline = ProductCategory.internalBinaryRead(reader, reader.uint32(), options, message.fineline);
                    break;
                case /* repeated rd.price.Tier tier_prices */ 15:
                    message.tierPrices.push(Tier.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.tag.Tag tags */ 16:
                    message.tags.push(Tag.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchProductsV2Res_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.product.Product product = 1; */
        if (message.product)
            Product.internalBinaryWrite(message.product, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.product.Product matched_price_product = 2; */
        if (message.matchedPriceProduct)
            Product.internalBinaryWrite(message.matchedPriceProduct, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.product.Product matched_cost_product = 3; */
        if (message.matchedCostProduct)
            Product.internalBinaryWrite(message.matchedCostProduct, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.product.Inventories inventories = 4; */
        if (message.inventories)
            Inventories.internalBinaryWrite(message.inventories, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Vendor primary_cost_vendor = 5; */
        if (message.primaryCostVendor)
            Vendor.internalBinaryWrite(message.primaryCostVendor, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.AdditionalFees.Fee additional_fee = 7; */
        if (message.additionalFee)
            AdditionalFees_Fee.internalBinaryWrite(message.additionalFee, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* int32 delta = 9; */
        if (message.delta !== 0)
            writer.tag(9, WireType.Varint).int32(message.delta);
        /* rd.txn.ProductSalesAnalytics analytics = 10; */
        if (message.analytics)
            ProductSalesAnalytics.internalBinaryWrite(message.analytics, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.catalog.Product catalog_product = 11; */
        if (message.catalogProduct)
            Product$.internalBinaryWrite(message.catalogProduct, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.ProductCategory department = 12; */
        if (message.department)
            ProductCategory.internalBinaryWrite(message.department, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.ProductCategory class = 13; */
        if (message.class)
            ProductCategory.internalBinaryWrite(message.class, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.ProductCategory fineline = 14; */
        if (message.fineline)
            ProductCategory.internalBinaryWrite(message.fineline, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.price.Tier tier_prices = 15; */
        for (let i = 0; i < message.tierPrices.length; i++)
            Tier.internalBinaryWrite(message.tierPrices[i], writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.tag.Tag tags = 16; */
        for (let i = 0; i < message.tags.length; i++)
            Tag.internalBinaryWrite(message.tags[i], writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchProductsV2Res.Result
 */
export const SearchProductsV2Res_Result = new SearchProductsV2Res_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkExportProductsReq$Type extends MessageType<BulkExportProductsReq> {
    constructor() {
        super("rd.search.service.BulkExportProductsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "inv_sort_location_id", kind: "message", T: () => UUID },
            { no: 3, name: "current_location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 5, name: "vendor_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 6, name: "additional_fee_id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "is_active_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 8, name: "is_tintable_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 9, name: "order_by", kind: "enum", T: () => ["rd.search.service.SearchProductOrderBy", SearchProductOrderBy] },
            { no: 10, name: "sort_order", kind: "enum", T: () => ["rd.search.service.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 11, name: "tax_statuses", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.product.Product.TaxStatus", Product_TaxStatus, "TAX_STATUS_"] },
            { no: 16, name: "is_template", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "department_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 18, name: "class_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 19, name: "fineline_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 20, name: "product_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 21, name: "tag_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<BulkExportProductsReq>): BulkExportProductsReq {
        const message = { query: "", vendorIds: [], additionalFeeIdStrings: [], isActiveFilter: 0, isTintableFilter: 0, orderBy: 0, sortOrder: 0, taxStatuses: [], isTemplate: false, departmentIds: [], classIds: [], finelineIds: [], productIds: [], tagIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkExportProductsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkExportProductsReq): BulkExportProductsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.uuid.UUID inv_sort_location_id */ 2:
                    message.invSortLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.invSortLocationId);
                    break;
                case /* rd.proto.uuid.UUID current_location_id */ 3:
                    message.currentLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.currentLocationId);
                    break;
                case /* repeated rd.proto.uuid.UUID vendor_ids */ 5:
                    message.vendorIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string additional_fee_id_strings */ 6:
                    message.additionalFeeIdStrings.push(reader.string());
                    break;
                case /* rd.search.service.BoolFilter is_active_filter */ 7:
                    message.isActiveFilter = reader.int32();
                    break;
                case /* rd.search.service.BoolFilter is_tintable_filter */ 8:
                    message.isTintableFilter = reader.int32();
                    break;
                case /* rd.search.service.SearchProductOrderBy order_by */ 9:
                    message.orderBy = reader.int32();
                    break;
                case /* rd.search.service.SortOrder sort_order */ 10:
                    message.sortOrder = reader.int32();
                    break;
                case /* repeated rd.product.Product.TaxStatus tax_statuses */ 11:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.taxStatuses.push(reader.int32());
                    else
                        message.taxStatuses.push(reader.int32());
                    break;
                case /* bool is_template */ 16:
                    message.isTemplate = reader.bool();
                    break;
                case /* repeated rd.proto.uuid.UUID department_ids */ 17:
                    message.departmentIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID class_ids */ 18:
                    message.classIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID fineline_ids */ 19:
                    message.finelineIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID product_ids */ 20:
                    message.productIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID tag_ids */ 21:
                    message.tagIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkExportProductsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.uuid.UUID inv_sort_location_id = 2; */
        if (message.invSortLocationId)
            UUID.internalBinaryWrite(message.invSortLocationId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID current_location_id = 3; */
        if (message.currentLocationId)
            UUID.internalBinaryWrite(message.currentLocationId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID vendor_ids = 5; */
        for (let i = 0; i < message.vendorIds.length; i++)
            UUID.internalBinaryWrite(message.vendorIds[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated string additional_fee_id_strings = 6; */
        for (let i = 0; i < message.additionalFeeIdStrings.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.additionalFeeIdStrings[i]);
        /* rd.search.service.BoolFilter is_active_filter = 7; */
        if (message.isActiveFilter !== 0)
            writer.tag(7, WireType.Varint).int32(message.isActiveFilter);
        /* rd.search.service.BoolFilter is_tintable_filter = 8; */
        if (message.isTintableFilter !== 0)
            writer.tag(8, WireType.Varint).int32(message.isTintableFilter);
        /* rd.search.service.SearchProductOrderBy order_by = 9; */
        if (message.orderBy !== 0)
            writer.tag(9, WireType.Varint).int32(message.orderBy);
        /* rd.search.service.SortOrder sort_order = 10; */
        if (message.sortOrder !== 0)
            writer.tag(10, WireType.Varint).int32(message.sortOrder);
        /* repeated rd.product.Product.TaxStatus tax_statuses = 11; */
        if (message.taxStatuses.length) {
            writer.tag(11, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.taxStatuses.length; i++)
                writer.int32(message.taxStatuses[i]);
            writer.join();
        }
        /* bool is_template = 16; */
        if (message.isTemplate !== false)
            writer.tag(16, WireType.Varint).bool(message.isTemplate);
        /* repeated rd.proto.uuid.UUID department_ids = 17; */
        for (let i = 0; i < message.departmentIds.length; i++)
            UUID.internalBinaryWrite(message.departmentIds[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID class_ids = 18; */
        for (let i = 0; i < message.classIds.length; i++)
            UUID.internalBinaryWrite(message.classIds[i], writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID fineline_ids = 19; */
        for (let i = 0; i < message.finelineIds.length; i++)
            UUID.internalBinaryWrite(message.finelineIds[i], writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID product_ids = 20; */
        for (let i = 0; i < message.productIds.length; i++)
            UUID.internalBinaryWrite(message.productIds[i], writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID tag_ids = 21; */
        for (let i = 0; i < message.tagIds.length; i++)
            UUID.internalBinaryWrite(message.tagIds[i], writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.BulkExportProductsReq
 */
export const BulkExportProductsReq = new BulkExportProductsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkExportProductsRes$Type extends MessageType<BulkExportProductsRes> {
    constructor() {
        super("rd.search.service.BulkExportProductsRes", [
            { no: 1, name: "excel_bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<BulkExportProductsRes>): BulkExportProductsRes {
        const message = { excelBytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkExportProductsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkExportProductsRes): BulkExportProductsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes excel_bytes */ 1:
                    message.excelBytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkExportProductsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes excel_bytes = 1; */
        if (message.excelBytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.excelBytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.BulkExportProductsRes
 */
export const BulkExportProductsRes = new BulkExportProductsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkExportProductLabelsReq$Type extends MessageType<BulkExportProductLabelsReq> {
    constructor() {
        super("rd.search.service.BulkExportProductLabelsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "inv_sort_location_id", kind: "message", T: () => UUID },
            { no: 3, name: "current_location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 4, name: "vendor_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 5, name: "additional_fee_id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "is_active_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 7, name: "is_tintable_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 8, name: "order_by", kind: "enum", T: () => ["rd.search.service.SearchProductOrderBy", SearchProductOrderBy] },
            { no: 9, name: "sort_order", kind: "enum", T: () => ["rd.search.service.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 10, name: "tax_statuses", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.product.Product.TaxStatus", Product_TaxStatus, "TAX_STATUS_"] },
            { no: 11, name: "department_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 12, name: "class_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 13, name: "fineline_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 14, name: "tag_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 15, name: "template", kind: "enum", T: () => ["rd.product.LabelTemplate", LabelTemplate, "LABEL_TEMPLATE_"] },
            { no: 16, name: "rotation_degrees", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<BulkExportProductLabelsReq>): BulkExportProductLabelsReq {
        const message = { query: "", vendorIds: [], additionalFeeIdStrings: [], isActiveFilter: 0, isTintableFilter: 0, orderBy: 0, sortOrder: 0, taxStatuses: [], departmentIds: [], classIds: [], finelineIds: [], tagIds: [], template: 0, rotationDegrees: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkExportProductLabelsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkExportProductLabelsReq): BulkExportProductLabelsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.uuid.UUID inv_sort_location_id */ 2:
                    message.invSortLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.invSortLocationId);
                    break;
                case /* rd.proto.uuid.UUID current_location_id */ 3:
                    message.currentLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.currentLocationId);
                    break;
                case /* repeated rd.proto.uuid.UUID vendor_ids */ 4:
                    message.vendorIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string additional_fee_id_strings */ 5:
                    message.additionalFeeIdStrings.push(reader.string());
                    break;
                case /* rd.search.service.BoolFilter is_active_filter */ 6:
                    message.isActiveFilter = reader.int32();
                    break;
                case /* rd.search.service.BoolFilter is_tintable_filter */ 7:
                    message.isTintableFilter = reader.int32();
                    break;
                case /* rd.search.service.SearchProductOrderBy order_by */ 8:
                    message.orderBy = reader.int32();
                    break;
                case /* rd.search.service.SortOrder sort_order */ 9:
                    message.sortOrder = reader.int32();
                    break;
                case /* repeated rd.product.Product.TaxStatus tax_statuses */ 10:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.taxStatuses.push(reader.int32());
                    else
                        message.taxStatuses.push(reader.int32());
                    break;
                case /* repeated rd.proto.uuid.UUID department_ids */ 11:
                    message.departmentIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID class_ids */ 12:
                    message.classIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID fineline_ids */ 13:
                    message.finelineIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID tag_ids */ 14:
                    message.tagIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.product.LabelTemplate template */ 15:
                    message.template = reader.int32();
                    break;
                case /* int32 rotation_degrees */ 16:
                    message.rotationDegrees = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkExportProductLabelsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.uuid.UUID inv_sort_location_id = 2; */
        if (message.invSortLocationId)
            UUID.internalBinaryWrite(message.invSortLocationId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID current_location_id = 3; */
        if (message.currentLocationId)
            UUID.internalBinaryWrite(message.currentLocationId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID vendor_ids = 4; */
        for (let i = 0; i < message.vendorIds.length; i++)
            UUID.internalBinaryWrite(message.vendorIds[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated string additional_fee_id_strings = 5; */
        for (let i = 0; i < message.additionalFeeIdStrings.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.additionalFeeIdStrings[i]);
        /* rd.search.service.BoolFilter is_active_filter = 6; */
        if (message.isActiveFilter !== 0)
            writer.tag(6, WireType.Varint).int32(message.isActiveFilter);
        /* rd.search.service.BoolFilter is_tintable_filter = 7; */
        if (message.isTintableFilter !== 0)
            writer.tag(7, WireType.Varint).int32(message.isTintableFilter);
        /* rd.search.service.SearchProductOrderBy order_by = 8; */
        if (message.orderBy !== 0)
            writer.tag(8, WireType.Varint).int32(message.orderBy);
        /* rd.search.service.SortOrder sort_order = 9; */
        if (message.sortOrder !== 0)
            writer.tag(9, WireType.Varint).int32(message.sortOrder);
        /* repeated rd.product.Product.TaxStatus tax_statuses = 10; */
        if (message.taxStatuses.length) {
            writer.tag(10, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.taxStatuses.length; i++)
                writer.int32(message.taxStatuses[i]);
            writer.join();
        }
        /* repeated rd.proto.uuid.UUID department_ids = 11; */
        for (let i = 0; i < message.departmentIds.length; i++)
            UUID.internalBinaryWrite(message.departmentIds[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID class_ids = 12; */
        for (let i = 0; i < message.classIds.length; i++)
            UUID.internalBinaryWrite(message.classIds[i], writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID fineline_ids = 13; */
        for (let i = 0; i < message.finelineIds.length; i++)
            UUID.internalBinaryWrite(message.finelineIds[i], writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID tag_ids = 14; */
        for (let i = 0; i < message.tagIds.length; i++)
            UUID.internalBinaryWrite(message.tagIds[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* rd.product.LabelTemplate template = 15; */
        if (message.template !== 0)
            writer.tag(15, WireType.Varint).int32(message.template);
        /* int32 rotation_degrees = 16; */
        if (message.rotationDegrees !== 0)
            writer.tag(16, WireType.Varint).int32(message.rotationDegrees);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.BulkExportProductLabelsReq
 */
export const BulkExportProductLabelsReq = new BulkExportProductLabelsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkExportProductLabelsRes$Type extends MessageType<BulkExportProductLabelsRes> {
    constructor() {
        super("rd.search.service.BulkExportProductLabelsRes", [
            { no: 1, name: "pdf_bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<BulkExportProductLabelsRes>): BulkExportProductLabelsRes {
        const message = { pdfBytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkExportProductLabelsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkExportProductLabelsRes): BulkExportProductLabelsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes pdf_bytes */ 1:
                    message.pdfBytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkExportProductLabelsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes pdf_bytes = 1; */
        if (message.pdfBytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.pdfBytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.BulkExportProductLabelsRes
 */
export const BulkExportProductLabelsRes = new BulkExportProductLabelsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchSalesV2Req$Type extends MessageType<SearchSalesV2Req> {
    constructor() {
        super("rd.search.service.SearchSalesV2Req", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "created_at_range", kind: "message", T: () => TimestampRange },
            { no: 3, name: "quoted_at_range", kind: "message", T: () => TimestampRange },
            { no: 4, name: "will_called_at_range", kind: "message", T: () => TimestampRange },
            { no: 5, name: "sold_at_range", kind: "message", T: () => TimestampRange },
            { no: 6, name: "voided_at_range", kind: "message", T: () => TimestampRange },
            { no: 7, name: "created_by_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 8, name: "quoted_by_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 9, name: "will_called_by_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 10, name: "sold_by_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 11, name: "voided_by_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 12, name: "customer_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 13, name: "location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 14, name: "statuses", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.txn.Sale.Status", Sale_Status, "STATUS_"] },
            { no: 15, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "purchase_order_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 19, name: "tint_color_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 20, name: "contains_non_taxable_products_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 21, name: "purchase_method", kind: "enum", T: () => ["rd.txn.PurchaseMethod.Method", PurchaseMethod_Method, "METHOD_"] }
        ]);
    }
    create(value?: PartialMessage<SearchSalesV2Req>): SearchSalesV2Req {
        const message = { query: "", createdByIds: [], quotedByIds: [], willCalledByIds: [], soldByIds: [], voidedByIds: [], customerIds: [], locationIds: [], statuses: [], cursor: "", offset: 0, limit: 0, purchaseOrderIds: [], tintColorIds: [], containsNonTaxableProductsFilter: 0, purchaseMethod: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchSalesV2Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchSalesV2Req): SearchSalesV2Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.timestamprange.TimestampRange created_at_range */ 2:
                    message.createdAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.createdAtRange);
                    break;
                case /* rd.proto.timestamprange.TimestampRange quoted_at_range */ 3:
                    message.quotedAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.quotedAtRange);
                    break;
                case /* rd.proto.timestamprange.TimestampRange will_called_at_range */ 4:
                    message.willCalledAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.willCalledAtRange);
                    break;
                case /* rd.proto.timestamprange.TimestampRange sold_at_range */ 5:
                    message.soldAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.soldAtRange);
                    break;
                case /* rd.proto.timestamprange.TimestampRange voided_at_range */ 6:
                    message.voidedAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.voidedAtRange);
                    break;
                case /* repeated rd.proto.uuid.UUID created_by_ids */ 7:
                    message.createdByIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID quoted_by_ids */ 8:
                    message.quotedByIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID will_called_by_ids */ 9:
                    message.willCalledByIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID sold_by_ids */ 10:
                    message.soldByIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID voided_by_ids */ 11:
                    message.voidedByIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID customer_ids */ 12:
                    message.customerIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID location_ids */ 13:
                    message.locationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.txn.Sale.Status statuses */ 14:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.statuses.push(reader.int32());
                    else
                        message.statuses.push(reader.int32());
                    break;
                case /* string cursor */ 15:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 16:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 17:
                    message.limit = reader.int32();
                    break;
                case /* repeated rd.proto.uuid.UUID purchase_order_ids */ 18:
                    message.purchaseOrderIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID tint_color_ids */ 19:
                    message.tintColorIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.search.service.BoolFilter contains_non_taxable_products_filter */ 20:
                    message.containsNonTaxableProductsFilter = reader.int32();
                    break;
                case /* rd.txn.PurchaseMethod.Method purchase_method */ 21:
                    message.purchaseMethod = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchSalesV2Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.timestamprange.TimestampRange created_at_range = 2; */
        if (message.createdAtRange)
            TimestampRange.internalBinaryWrite(message.createdAtRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange quoted_at_range = 3; */
        if (message.quotedAtRange)
            TimestampRange.internalBinaryWrite(message.quotedAtRange, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange will_called_at_range = 4; */
        if (message.willCalledAtRange)
            TimestampRange.internalBinaryWrite(message.willCalledAtRange, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange sold_at_range = 5; */
        if (message.soldAtRange)
            TimestampRange.internalBinaryWrite(message.soldAtRange, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange voided_at_range = 6; */
        if (message.voidedAtRange)
            TimestampRange.internalBinaryWrite(message.voidedAtRange, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID created_by_ids = 7; */
        for (let i = 0; i < message.createdByIds.length; i++)
            UUID.internalBinaryWrite(message.createdByIds[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID quoted_by_ids = 8; */
        for (let i = 0; i < message.quotedByIds.length; i++)
            UUID.internalBinaryWrite(message.quotedByIds[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID will_called_by_ids = 9; */
        for (let i = 0; i < message.willCalledByIds.length; i++)
            UUID.internalBinaryWrite(message.willCalledByIds[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID sold_by_ids = 10; */
        for (let i = 0; i < message.soldByIds.length; i++)
            UUID.internalBinaryWrite(message.soldByIds[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID voided_by_ids = 11; */
        for (let i = 0; i < message.voidedByIds.length; i++)
            UUID.internalBinaryWrite(message.voidedByIds[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID customer_ids = 12; */
        for (let i = 0; i < message.customerIds.length; i++)
            UUID.internalBinaryWrite(message.customerIds[i], writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID location_ids = 13; */
        for (let i = 0; i < message.locationIds.length; i++)
            UUID.internalBinaryWrite(message.locationIds[i], writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.Sale.Status statuses = 14; */
        if (message.statuses.length) {
            writer.tag(14, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.statuses.length; i++)
                writer.int32(message.statuses[i]);
            writer.join();
        }
        /* string cursor = 15; */
        if (message.cursor !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 16; */
        if (message.offset !== 0)
            writer.tag(16, WireType.Varint).int32(message.offset);
        /* int32 limit = 17; */
        if (message.limit !== 0)
            writer.tag(17, WireType.Varint).int32(message.limit);
        /* repeated rd.proto.uuid.UUID purchase_order_ids = 18; */
        for (let i = 0; i < message.purchaseOrderIds.length; i++)
            UUID.internalBinaryWrite(message.purchaseOrderIds[i], writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID tint_color_ids = 19; */
        for (let i = 0; i < message.tintColorIds.length; i++)
            UUID.internalBinaryWrite(message.tintColorIds[i], writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* rd.search.service.BoolFilter contains_non_taxable_products_filter = 20; */
        if (message.containsNonTaxableProductsFilter !== 0)
            writer.tag(20, WireType.Varint).int32(message.containsNonTaxableProductsFilter);
        /* rd.txn.PurchaseMethod.Method purchase_method = 21; */
        if (message.purchaseMethod !== 0)
            writer.tag(21, WireType.Varint).int32(message.purchaseMethod);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchSalesV2Req
 */
export const SearchSalesV2Req = new SearchSalesV2Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchSalesV2Res$Type extends MessageType<SearchSalesV2Res> {
    constructor() {
        super("rd.search.service.SearchSalesV2Res", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchSalesV2Res_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchSalesV2Res>): SearchSalesV2Res {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchSalesV2Res>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchSalesV2Res): SearchSalesV2Res {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchSalesV2Res.Result results */ 1:
                    message.results.push(SearchSalesV2Res_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchSalesV2Res, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchSalesV2Res.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchSalesV2Res_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchSalesV2Res
 */
export const SearchSalesV2Res = new SearchSalesV2Res$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchSalesV2Res_Result$Type extends MessageType<SearchSalesV2Res_Result> {
    constructor() {
        super("rd.search.service.SearchSalesV2Res.Result", [
            { no: 1, name: "sale", kind: "message", T: () => Sale },
            { no: 2, name: "location", kind: "message", T: () => Location },
            { no: 3, name: "customer", kind: "message", T: () => Customer },
            { no: 4, name: "purchase_order", kind: "message", T: () => PurchaseOrder },
            { no: 6, name: "products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 7, name: "matched_products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 8, name: "tint_colors_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => TintColor } },
            { no: 9, name: "total", kind: "message", T: () => Money },
            { no: 10, name: "customer_contact", kind: "message", T: () => Contacts_Contact },
            { no: 11, name: "purchase_methods", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PurchaseMethod },
            { no: 12, name: "sale_products", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SaleProduct }
        ]);
    }
    create(value?: PartialMessage<SearchSalesV2Res_Result>): SearchSalesV2Res_Result {
        const message = { productsById: {}, matchedProductsById: {}, tintColorsById: {}, purchaseMethods: [], saleProducts: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchSalesV2Res_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchSalesV2Res_Result): SearchSalesV2Res_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.Sale sale */ 1:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* rd.company.Location location */ 2:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.customer.Customer customer */ 3:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.customer.PurchaseOrder purchase_order */ 4:
                    message.purchaseOrder = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options, message.purchaseOrder);
                    break;
                case /* map<string, rd.product.Product> products_by_id */ 6:
                    this.binaryReadMap6(message.productsById, reader, options);
                    break;
                case /* map<string, rd.product.Product> matched_products_by_id */ 7:
                    this.binaryReadMap7(message.matchedProductsById, reader, options);
                    break;
                case /* map<string, rd.tintcolor.TintColor> tint_colors_by_id */ 8:
                    this.binaryReadMap8(message.tintColorsById, reader, options);
                    break;
                case /* rd.proto.money.Money total */ 9:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* rd.customer.Contacts.Contact customer_contact */ 10:
                    message.customerContact = Contacts_Contact.internalBinaryRead(reader, reader.uint32(), options, message.customerContact);
                    break;
                case /* repeated rd.txn.PurchaseMethod purchase_methods */ 11:
                    message.purchaseMethods.push(PurchaseMethod.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.txn.SaleProduct sale_products */ 12:
                    message.saleProducts.push(SaleProduct.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap6(map: SearchSalesV2Res_Result["productsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchSalesV2Res_Result["productsById"] | undefined, val: SearchSalesV2Res_Result["productsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchSalesV2Res.Result.products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    private binaryReadMap7(map: SearchSalesV2Res_Result["matchedProductsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchSalesV2Res_Result["matchedProductsById"] | undefined, val: SearchSalesV2Res_Result["matchedProductsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchSalesV2Res.Result.matched_products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    private binaryReadMap8(map: SearchSalesV2Res_Result["tintColorsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchSalesV2Res_Result["tintColorsById"] | undefined, val: SearchSalesV2Res_Result["tintColorsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = TintColor.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchSalesV2Res.Result.tint_colors_by_id");
            }
        }
        map[key ?? ""] = val ?? TintColor.create();
    }
    internalBinaryWrite(message: SearchSalesV2Res_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.Sale sale = 1; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 2; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Customer customer = 3; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.PurchaseOrder purchase_order = 4; */
        if (message.purchaseOrder)
            PurchaseOrder.internalBinaryWrite(message.purchaseOrder, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.product.Product> products_by_id = 6; */
        for (let k of Object.keys(message.productsById)) {
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.productsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.product.Product> matched_products_by_id = 7; */
        for (let k of Object.keys(message.matchedProductsById)) {
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.matchedProductsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.tintcolor.TintColor> tint_colors_by_id = 8; */
        for (let k of Object.keys(message.tintColorsById)) {
            writer.tag(8, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            TintColor.internalBinaryWrite(message.tintColorsById[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money total = 9; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Contacts.Contact customer_contact = 10; */
        if (message.customerContact)
            Contacts_Contact.internalBinaryWrite(message.customerContact, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.PurchaseMethod purchase_methods = 11; */
        for (let i = 0; i < message.purchaseMethods.length; i++)
            PurchaseMethod.internalBinaryWrite(message.purchaseMethods[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.SaleProduct sale_products = 12; */
        for (let i = 0; i < message.saleProducts.length; i++)
            SaleProduct.internalBinaryWrite(message.saleProducts[i], writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchSalesV2Res.Result
 */
export const SearchSalesV2Res_Result = new SearchSalesV2Res_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchReturnsReq$Type extends MessageType<SearchReturnsReq> {
    constructor() {
        super("rd.search.service.SearchReturnsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "created_at_range", kind: "message", T: () => TimestampRange },
            { no: 3, name: "created_by_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 4, name: "customer_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 5, name: "location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 15, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchReturnsReq>): SearchReturnsReq {
        const message = { query: "", createdByIds: [], customerIds: [], locationIds: [], cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchReturnsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchReturnsReq): SearchReturnsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.timestamprange.TimestampRange created_at_range */ 2:
                    message.createdAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.createdAtRange);
                    break;
                case /* repeated rd.proto.uuid.UUID created_by_ids */ 3:
                    message.createdByIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID customer_ids */ 4:
                    message.customerIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID location_ids */ 5:
                    message.locationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 15:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 16:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 17:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchReturnsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.timestamprange.TimestampRange created_at_range = 2; */
        if (message.createdAtRange)
            TimestampRange.internalBinaryWrite(message.createdAtRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID created_by_ids = 3; */
        for (let i = 0; i < message.createdByIds.length; i++)
            UUID.internalBinaryWrite(message.createdByIds[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID customer_ids = 4; */
        for (let i = 0; i < message.customerIds.length; i++)
            UUID.internalBinaryWrite(message.customerIds[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID location_ids = 5; */
        for (let i = 0; i < message.locationIds.length; i++)
            UUID.internalBinaryWrite(message.locationIds[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 15; */
        if (message.cursor !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 16; */
        if (message.offset !== 0)
            writer.tag(16, WireType.Varint).int32(message.offset);
        /* int32 limit = 17; */
        if (message.limit !== 0)
            writer.tag(17, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchReturnsReq
 */
export const SearchReturnsReq = new SearchReturnsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchReturnsRes$Type extends MessageType<SearchReturnsRes> {
    constructor() {
        super("rd.search.service.SearchReturnsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchReturnsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchReturnsRes>): SearchReturnsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchReturnsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchReturnsRes): SearchReturnsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchReturnsRes.Result results */ 1:
                    message.results.push(SearchReturnsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchReturnsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchReturnsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchReturnsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchReturnsRes
 */
export const SearchReturnsRes = new SearchReturnsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchReturnsRes_Result$Type extends MessageType<SearchReturnsRes_Result> {
    constructor() {
        super("rd.search.service.SearchReturnsRes.Result", [
            { no: 15, name: "return_ledger_entry", kind: "message", T: () => LedgerEntry },
            { no: 2, name: "sale", kind: "message", T: () => Sale },
            { no: 3, name: "location", kind: "message", T: () => Location },
            { no: 4, name: "customer", kind: "message", T: () => Customer },
            { no: 5, name: "purchase_order", kind: "message", T: () => PurchaseOrder },
            { no: 7, name: "products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 8, name: "matched_products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 9, name: "tint_colors_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => TintColor } },
            { no: 10, name: "customer_contact", kind: "message", T: () => Contacts_Contact },
            { no: 13, name: "sale_return", kind: "message", T: () => Sale_Return },
            { no: 14, name: "return_method", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchReturnsRes_Result>): SearchReturnsRes_Result {
        const message = { productsById: {}, matchedProductsById: {}, tintColorsById: {}, returnMethod: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchReturnsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchReturnsRes_Result): SearchReturnsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.ledger.LedgerEntry return_ledger_entry */ 15:
                    message.returnLedgerEntry = LedgerEntry.internalBinaryRead(reader, reader.uint32(), options, message.returnLedgerEntry);
                    break;
                case /* rd.txn.Sale sale */ 2:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* rd.company.Location location */ 3:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.customer.Customer customer */ 4:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.customer.PurchaseOrder purchase_order */ 5:
                    message.purchaseOrder = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options, message.purchaseOrder);
                    break;
                case /* map<string, rd.product.Product> products_by_id */ 7:
                    this.binaryReadMap7(message.productsById, reader, options);
                    break;
                case /* map<string, rd.product.Product> matched_products_by_id */ 8:
                    this.binaryReadMap8(message.matchedProductsById, reader, options);
                    break;
                case /* map<string, rd.tintcolor.TintColor> tint_colors_by_id */ 9:
                    this.binaryReadMap9(message.tintColorsById, reader, options);
                    break;
                case /* rd.customer.Contacts.Contact customer_contact */ 10:
                    message.customerContact = Contacts_Contact.internalBinaryRead(reader, reader.uint32(), options, message.customerContact);
                    break;
                case /* rd.txn.Sale.Return sale_return */ 13:
                    message.saleReturn = Sale_Return.internalBinaryRead(reader, reader.uint32(), options, message.saleReturn);
                    break;
                case /* string return_method */ 14:
                    message.returnMethod = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap7(map: SearchReturnsRes_Result["productsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchReturnsRes_Result["productsById"] | undefined, val: SearchReturnsRes_Result["productsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchReturnsRes.Result.products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    private binaryReadMap8(map: SearchReturnsRes_Result["matchedProductsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchReturnsRes_Result["matchedProductsById"] | undefined, val: SearchReturnsRes_Result["matchedProductsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchReturnsRes.Result.matched_products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    private binaryReadMap9(map: SearchReturnsRes_Result["tintColorsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchReturnsRes_Result["tintColorsById"] | undefined, val: SearchReturnsRes_Result["tintColorsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = TintColor.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchReturnsRes.Result.tint_colors_by_id");
            }
        }
        map[key ?? ""] = val ?? TintColor.create();
    }
    internalBinaryWrite(message: SearchReturnsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.ledger.LedgerEntry return_ledger_entry = 15; */
        if (message.returnLedgerEntry)
            LedgerEntry.internalBinaryWrite(message.returnLedgerEntry, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.Sale sale = 2; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 3; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Customer customer = 4; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.PurchaseOrder purchase_order = 5; */
        if (message.purchaseOrder)
            PurchaseOrder.internalBinaryWrite(message.purchaseOrder, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.product.Product> products_by_id = 7; */
        for (let k of Object.keys(message.productsById)) {
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.productsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.product.Product> matched_products_by_id = 8; */
        for (let k of Object.keys(message.matchedProductsById)) {
            writer.tag(8, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.matchedProductsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.tintcolor.TintColor> tint_colors_by_id = 9; */
        for (let k of Object.keys(message.tintColorsById)) {
            writer.tag(9, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            TintColor.internalBinaryWrite(message.tintColorsById[k], writer, options);
            writer.join().join();
        }
        /* rd.customer.Contacts.Contact customer_contact = 10; */
        if (message.customerContact)
            Contacts_Contact.internalBinaryWrite(message.customerContact, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.Sale.Return sale_return = 13; */
        if (message.saleReturn)
            Sale_Return.internalBinaryWrite(message.saleReturn, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* string return_method = 14; */
        if (message.returnMethod !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.returnMethod);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchReturnsRes.Result
 */
export const SearchReturnsRes_Result = new SearchReturnsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOutstandingSalesReq$Type extends MessageType<SearchOutstandingSalesReq> {
    constructor() {
        super("rd.search.service.SearchOutstandingSalesReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<SearchOutstandingSalesReq>): SearchOutstandingSalesReq {
        const message = { query: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOutstandingSalesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOutstandingSalesReq): SearchOutstandingSalesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 2:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOutstandingSalesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.uuid.UUID customer_id = 2; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOutstandingSalesReq
 */
export const SearchOutstandingSalesReq = new SearchOutstandingSalesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOutstandingSalesRes$Type extends MessageType<SearchOutstandingSalesRes> {
    constructor() {
        super("rd.search.service.SearchOutstandingSalesRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchOutstandingSalesRes_Result }
        ]);
    }
    create(value?: PartialMessage<SearchOutstandingSalesRes>): SearchOutstandingSalesRes {
        const message = { results: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOutstandingSalesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOutstandingSalesRes): SearchOutstandingSalesRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchOutstandingSalesRes.Result results */ 1:
                    message.results.push(SearchOutstandingSalesRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOutstandingSalesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchOutstandingSalesRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchOutstandingSalesRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOutstandingSalesRes
 */
export const SearchOutstandingSalesRes = new SearchOutstandingSalesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOutstandingSalesRes_Result$Type extends MessageType<SearchOutstandingSalesRes_Result> {
    constructor() {
        super("rd.search.service.SearchOutstandingSalesRes.Result", [
            { no: 1, name: "sale", kind: "message", T: () => Sale },
            { no: 2, name: "sale_balance", kind: "message", T: () => Money },
            { no: 3, name: "location", kind: "message", T: () => Location },
            { no: 4, name: "customer", kind: "message", T: () => Customer },
            { no: 5, name: "purchase_order", kind: "message", T: () => PurchaseOrder },
            { no: 7, name: "customer_contact", kind: "message", T: () => Contacts_Contact },
            { no: 8, name: "potential_discount_expires_at", kind: "message", T: () => Timestamp },
            { no: 9, name: "discount_amount", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<SearchOutstandingSalesRes_Result>): SearchOutstandingSalesRes_Result {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOutstandingSalesRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOutstandingSalesRes_Result): SearchOutstandingSalesRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.Sale sale */ 1:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* rd.proto.money.Money sale_balance */ 2:
                    message.saleBalance = Money.internalBinaryRead(reader, reader.uint32(), options, message.saleBalance);
                    break;
                case /* rd.company.Location location */ 3:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.customer.Customer customer */ 4:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.customer.PurchaseOrder purchase_order */ 5:
                    message.purchaseOrder = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options, message.purchaseOrder);
                    break;
                case /* rd.customer.Contacts.Contact customer_contact */ 7:
                    message.customerContact = Contacts_Contact.internalBinaryRead(reader, reader.uint32(), options, message.customerContact);
                    break;
                case /* google.protobuf.Timestamp potential_discount_expires_at */ 8:
                    message.potentialDiscountExpiresAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.potentialDiscountExpiresAt);
                    break;
                case /* rd.proto.money.Money discount_amount */ 9:
                    message.discountAmount = Money.internalBinaryRead(reader, reader.uint32(), options, message.discountAmount);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOutstandingSalesRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.Sale sale = 1; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money sale_balance = 2; */
        if (message.saleBalance)
            Money.internalBinaryWrite(message.saleBalance, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 3; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Customer customer = 4; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.PurchaseOrder purchase_order = 5; */
        if (message.purchaseOrder)
            PurchaseOrder.internalBinaryWrite(message.purchaseOrder, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Contacts.Contact customer_contact = 7; */
        if (message.customerContact)
            Contacts_Contact.internalBinaryWrite(message.customerContact, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp potential_discount_expires_at = 8; */
        if (message.potentialDiscountExpiresAt)
            Timestamp.internalBinaryWrite(message.potentialDiscountExpiresAt, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money discount_amount = 9; */
        if (message.discountAmount)
            Money.internalBinaryWrite(message.discountAmount, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOutstandingSalesRes.Result
 */
export const SearchOutstandingSalesRes_Result = new SearchOutstandingSalesRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchTintColorsReq$Type extends MessageType<SearchTintColorsReq> {
    constructor() {
        super("rd.search.service.SearchTintColorsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchTintColorsReq>): SearchTintColorsReq {
        const message = { query: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchTintColorsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchTintColorsReq): SearchTintColorsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchTintColorsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchTintColorsReq
 */
export const SearchTintColorsReq = new SearchTintColorsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchTintColorsRes$Type extends MessageType<SearchTintColorsRes> {
    constructor() {
        super("rd.search.service.SearchTintColorsRes", [
            { no: 2, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchTintColorsRes_Result }
        ]);
    }
    create(value?: PartialMessage<SearchTintColorsRes>): SearchTintColorsRes {
        const message = { results: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchTintColorsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchTintColorsRes): SearchTintColorsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchTintColorsRes.Result results */ 2:
                    message.results.push(SearchTintColorsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchTintColorsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchTintColorsRes.Result results = 2; */
        for (let i = 0; i < message.results.length; i++)
            SearchTintColorsRes_Result.internalBinaryWrite(message.results[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchTintColorsRes
 */
export const SearchTintColorsRes = new SearchTintColorsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchTintColorsRes_Result$Type extends MessageType<SearchTintColorsRes_Result> {
    constructor() {
        super("rd.search.service.SearchTintColorsRes.Result", [
            { no: 1, name: "tint_color", kind: "message", T: () => TintColor }
        ]);
    }
    create(value?: PartialMessage<SearchTintColorsRes_Result>): SearchTintColorsRes_Result {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchTintColorsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchTintColorsRes_Result): SearchTintColorsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.tintcolor.TintColor tint_color */ 1:
                    message.tintColor = TintColor.internalBinaryRead(reader, reader.uint32(), options, message.tintColor);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchTintColorsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.tintcolor.TintColor tint_color = 1; */
        if (message.tintColor)
            TintColor.internalBinaryWrite(message.tintColor, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchTintColorsRes.Result
 */
export const SearchTintColorsRes_Result = new SearchTintColorsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchTransfersReq$Type extends MessageType<SearchTransfersReq> {
    constructor() {
        super("rd.search.service.SearchTransfersReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "created_at_range", kind: "message", T: () => TimestampRange },
            { no: 3, name: "sent_at_range", kind: "message", T: () => TimestampRange },
            { no: 4, name: "received_at_range", kind: "message", T: () => TimestampRange },
            { no: 5, name: "voided_at_range", kind: "message", T: () => TimestampRange },
            { no: 6, name: "from_location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 7, name: "to_location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 8, name: "statuses", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.txn.Transfer.Status", Transfer_Status, "STATUS_"] },
            { no: 9, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "requested_at_range", kind: "message", T: () => TimestampRange }
        ]);
    }
    create(value?: PartialMessage<SearchTransfersReq>): SearchTransfersReq {
        const message = { query: "", fromLocationIds: [], toLocationIds: [], statuses: [], cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchTransfersReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchTransfersReq): SearchTransfersReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.timestamprange.TimestampRange created_at_range */ 2:
                    message.createdAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.createdAtRange);
                    break;
                case /* rd.proto.timestamprange.TimestampRange sent_at_range */ 3:
                    message.sentAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.sentAtRange);
                    break;
                case /* rd.proto.timestamprange.TimestampRange received_at_range */ 4:
                    message.receivedAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.receivedAtRange);
                    break;
                case /* rd.proto.timestamprange.TimestampRange voided_at_range */ 5:
                    message.voidedAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.voidedAtRange);
                    break;
                case /* repeated rd.proto.uuid.UUID from_location_ids */ 6:
                    message.fromLocationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID to_location_ids */ 7:
                    message.toLocationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.txn.Transfer.Status statuses */ 8:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.statuses.push(reader.int32());
                    else
                        message.statuses.push(reader.int32());
                    break;
                case /* string cursor */ 9:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 10:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 11:
                    message.limit = reader.int32();
                    break;
                case /* rd.proto.timestamprange.TimestampRange requested_at_range */ 12:
                    message.requestedAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.requestedAtRange);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchTransfersReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.timestamprange.TimestampRange created_at_range = 2; */
        if (message.createdAtRange)
            TimestampRange.internalBinaryWrite(message.createdAtRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange sent_at_range = 3; */
        if (message.sentAtRange)
            TimestampRange.internalBinaryWrite(message.sentAtRange, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange received_at_range = 4; */
        if (message.receivedAtRange)
            TimestampRange.internalBinaryWrite(message.receivedAtRange, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange voided_at_range = 5; */
        if (message.voidedAtRange)
            TimestampRange.internalBinaryWrite(message.voidedAtRange, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID from_location_ids = 6; */
        for (let i = 0; i < message.fromLocationIds.length; i++)
            UUID.internalBinaryWrite(message.fromLocationIds[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID to_location_ids = 7; */
        for (let i = 0; i < message.toLocationIds.length; i++)
            UUID.internalBinaryWrite(message.toLocationIds[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.Transfer.Status statuses = 8; */
        if (message.statuses.length) {
            writer.tag(8, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.statuses.length; i++)
                writer.int32(message.statuses[i]);
            writer.join();
        }
        /* string cursor = 9; */
        if (message.cursor !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 10; */
        if (message.offset !== 0)
            writer.tag(10, WireType.Varint).int32(message.offset);
        /* int32 limit = 11; */
        if (message.limit !== 0)
            writer.tag(11, WireType.Varint).int32(message.limit);
        /* rd.proto.timestamprange.TimestampRange requested_at_range = 12; */
        if (message.requestedAtRange)
            TimestampRange.internalBinaryWrite(message.requestedAtRange, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchTransfersReq
 */
export const SearchTransfersReq = new SearchTransfersReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchTransfersRes$Type extends MessageType<SearchTransfersRes> {
    constructor() {
        super("rd.search.service.SearchTransfersRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchTransfersRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchTransfersRes>): SearchTransfersRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchTransfersRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchTransfersRes): SearchTransfersRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchTransfersRes.Result results */ 1:
                    message.results.push(SearchTransfersRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchTransfersRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchTransfersRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchTransfersRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchTransfersRes
 */
export const SearchTransfersRes = new SearchTransfersRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchTransfersRes_Result$Type extends MessageType<SearchTransfersRes_Result> {
    constructor() {
        super("rd.search.service.SearchTransfersRes.Result", [
            { no: 1, name: "transfer", kind: "message", T: () => Transfer },
            { no: 2, name: "from_location", kind: "message", T: () => Location },
            { no: 3, name: "to_location", kind: "message", T: () => Location },
            { no: 4, name: "products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 5, name: "matched_products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 6, name: "total", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<SearchTransfersRes_Result>): SearchTransfersRes_Result {
        const message = { productsById: {}, matchedProductsById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchTransfersRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchTransfersRes_Result): SearchTransfersRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.Transfer transfer */ 1:
                    message.transfer = Transfer.internalBinaryRead(reader, reader.uint32(), options, message.transfer);
                    break;
                case /* rd.company.Location from_location */ 2:
                    message.fromLocation = Location.internalBinaryRead(reader, reader.uint32(), options, message.fromLocation);
                    break;
                case /* rd.company.Location to_location */ 3:
                    message.toLocation = Location.internalBinaryRead(reader, reader.uint32(), options, message.toLocation);
                    break;
                case /* map<string, rd.product.Product> products_by_id */ 4:
                    this.binaryReadMap4(message.productsById, reader, options);
                    break;
                case /* map<string, rd.product.Product> matched_products_by_id */ 5:
                    this.binaryReadMap5(message.matchedProductsById, reader, options);
                    break;
                case /* rd.proto.money.Money total */ 6:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: SearchTransfersRes_Result["productsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchTransfersRes_Result["productsById"] | undefined, val: SearchTransfersRes_Result["productsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchTransfersRes.Result.products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    private binaryReadMap5(map: SearchTransfersRes_Result["matchedProductsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchTransfersRes_Result["matchedProductsById"] | undefined, val: SearchTransfersRes_Result["matchedProductsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchTransfersRes.Result.matched_products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    internalBinaryWrite(message: SearchTransfersRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.Transfer transfer = 1; */
        if (message.transfer)
            Transfer.internalBinaryWrite(message.transfer, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location from_location = 2; */
        if (message.fromLocation)
            Location.internalBinaryWrite(message.fromLocation, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location to_location = 3; */
        if (message.toLocation)
            Location.internalBinaryWrite(message.toLocation, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.product.Product> products_by_id = 4; */
        for (let k of Object.keys(message.productsById)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.productsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.product.Product> matched_products_by_id = 5; */
        for (let k of Object.keys(message.matchedProductsById)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.matchedProductsById[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money total = 6; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchTransfersRes.Result
 */
export const SearchTransfersRes_Result = new SearchTransfersRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchVendorsReq$Type extends MessageType<SearchVendorsReq> {
    constructor() {
        super("rd.search.service.SearchVendorsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "is_active_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 3, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchVendorsReq>): SearchVendorsReq {
        const message = { query: "", isActiveFilter: 0, cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchVendorsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchVendorsReq): SearchVendorsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.search.service.BoolFilter is_active_filter */ 2:
                    message.isActiveFilter = reader.int32();
                    break;
                case /* string cursor */ 3:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 4:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 5:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchVendorsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.search.service.BoolFilter is_active_filter = 2; */
        if (message.isActiveFilter !== 0)
            writer.tag(2, WireType.Varint).int32(message.isActiveFilter);
        /* string cursor = 3; */
        if (message.cursor !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 4; */
        if (message.offset !== 0)
            writer.tag(4, WireType.Varint).int32(message.offset);
        /* int32 limit = 5; */
        if (message.limit !== 0)
            writer.tag(5, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchVendorsReq
 */
export const SearchVendorsReq = new SearchVendorsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchVendorsRes$Type extends MessageType<SearchVendorsRes> {
    constructor() {
        super("rd.search.service.SearchVendorsRes", [
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchVendorsRes_Result }
        ]);
    }
    create(value?: PartialMessage<SearchVendorsRes>): SearchVendorsRes {
        const message = { cursor: "", results: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchVendorsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchVendorsRes): SearchVendorsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                case /* repeated rd.search.service.SearchVendorsRes.Result results */ 3:
                    message.results.push(SearchVendorsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchVendorsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        /* repeated rd.search.service.SearchVendorsRes.Result results = 3; */
        for (let i = 0; i < message.results.length; i++)
            SearchVendorsRes_Result.internalBinaryWrite(message.results[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchVendorsRes
 */
export const SearchVendorsRes = new SearchVendorsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchVendorsRes_Result$Type extends MessageType<SearchVendorsRes_Result> {
    constructor() {
        super("rd.search.service.SearchVendorsRes.Result", [
            { no: 1, name: "vendor", kind: "message", T: () => Vendor }
        ]);
    }
    create(value?: PartialMessage<SearchVendorsRes_Result>): SearchVendorsRes_Result {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchVendorsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchVendorsRes_Result): SearchVendorsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.vendors.Vendor vendor */ 1:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchVendorsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.vendors.Vendor vendor = 1; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchVendorsRes.Result
 */
export const SearchVendorsRes_Result = new SearchVendorsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkExportVendorsReq$Type extends MessageType<BulkExportVendorsReq> {
    constructor() {
        super("rd.search.service.BulkExportVendorsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "is_active_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 3, name: "is_template", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<BulkExportVendorsReq>): BulkExportVendorsReq {
        const message = { query: "", isActiveFilter: 0, isTemplate: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkExportVendorsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkExportVendorsReq): BulkExportVendorsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.search.service.BoolFilter is_active_filter */ 2:
                    message.isActiveFilter = reader.int32();
                    break;
                case /* bool is_template */ 3:
                    message.isTemplate = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkExportVendorsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.search.service.BoolFilter is_active_filter = 2; */
        if (message.isActiveFilter !== 0)
            writer.tag(2, WireType.Varint).int32(message.isActiveFilter);
        /* bool is_template = 3; */
        if (message.isTemplate !== false)
            writer.tag(3, WireType.Varint).bool(message.isTemplate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.BulkExportVendorsReq
 */
export const BulkExportVendorsReq = new BulkExportVendorsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkExportVendorsRes$Type extends MessageType<BulkExportVendorsRes> {
    constructor() {
        super("rd.search.service.BulkExportVendorsRes", [
            { no: 1, name: "excel_bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<BulkExportVendorsRes>): BulkExportVendorsRes {
        const message = { excelBytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkExportVendorsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkExportVendorsRes): BulkExportVendorsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes excel_bytes */ 1:
                    message.excelBytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkExportVendorsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes excel_bytes = 1; */
        if (message.excelBytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.excelBytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.BulkExportVendorsRes
 */
export const BulkExportVendorsRes = new BulkExportVendorsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRecordReq$Type extends MessageType<UpdateRecordReq> {
    constructor() {
        super("rd.search.service.UpdateRecordReq", [
            { no: 6, name: "update", kind: "message", T: () => Update }
        ]);
    }
    create(value?: PartialMessage<UpdateRecordReq>): UpdateRecordReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateRecordReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateRecordReq): UpdateRecordReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.search.cache.Update update */ 6:
                    message.update = Update.internalBinaryRead(reader, reader.uint32(), options, message.update);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateRecordReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.search.cache.Update update = 6; */
        if (message.update)
            Update.internalBinaryWrite(message.update, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.UpdateRecordReq
 */
export const UpdateRecordReq = new UpdateRecordReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRecordRes$Type extends MessageType<UpdateRecordRes> {
    constructor() {
        super("rd.search.service.UpdateRecordRes", []);
    }
    create(value?: PartialMessage<UpdateRecordRes>): UpdateRecordRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateRecordRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateRecordRes): UpdateRecordRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateRecordRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.UpdateRecordRes
 */
export const UpdateRecordRes = new UpdateRecordRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReloadReq$Type extends MessageType<ReloadReq> {
    constructor() {
        super("rd.search.service.ReloadReq", [
            { no: 1, name: "company_id", kind: "message", T: () => UUID },
            { no: 2, name: "full_reload", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "table_names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReloadReq>): ReloadReq {
        const message = { fullReload: false, tableNames: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReloadReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReloadReq): ReloadReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID company_id */ 1:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* bool full_reload */ 2:
                    message.fullReload = reader.bool();
                    break;
                case /* repeated string table_names */ 3:
                    message.tableNames.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReloadReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID company_id = 1; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool full_reload = 2; */
        if (message.fullReload !== false)
            writer.tag(2, WireType.Varint).bool(message.fullReload);
        /* repeated string table_names = 3; */
        for (let i = 0; i < message.tableNames.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.tableNames[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.ReloadReq
 */
export const ReloadReq = new ReloadReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReloadRes$Type extends MessageType<ReloadRes> {
    constructor() {
        super("rd.search.service.ReloadRes", []);
    }
    create(value?: PartialMessage<ReloadRes>): ReloadRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReloadRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReloadRes): ReloadRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ReloadRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.ReloadRes
 */
export const ReloadRes = new ReloadRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCashTransactionsReq$Type extends MessageType<SearchCashTransactionsReq> {
    constructor() {
        super("rd.search.service.SearchCashTransactionsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 3, name: "created_at_range", kind: "message", T: () => TimestampRange },
            { no: 4, name: "types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.search.service.SearchCashTransactionsReq.Type", SearchCashTransactionsReq_Type, "TYPE_"] },
            { no: 5, name: "created_by_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 6, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchCashTransactionsReq>): SearchCashTransactionsReq {
        const message = { query: "", locationIds: [], types: [], createdByIds: [], cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCashTransactionsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCashTransactionsReq): SearchCashTransactionsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* repeated rd.proto.uuid.UUID location_ids */ 2:
                    message.locationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.timestamprange.TimestampRange created_at_range */ 3:
                    message.createdAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.createdAtRange);
                    break;
                case /* repeated rd.search.service.SearchCashTransactionsReq.Type types */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.types.push(reader.int32());
                    else
                        message.types.push(reader.int32());
                    break;
                case /* repeated rd.proto.uuid.UUID created_by_ids */ 5:
                    message.createdByIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 6:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 7:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 8:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCashTransactionsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* repeated rd.proto.uuid.UUID location_ids = 2; */
        for (let i = 0; i < message.locationIds.length; i++)
            UUID.internalBinaryWrite(message.locationIds[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange created_at_range = 3; */
        if (message.createdAtRange)
            TimestampRange.internalBinaryWrite(message.createdAtRange, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.search.service.SearchCashTransactionsReq.Type types = 4; */
        if (message.types.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.types.length; i++)
                writer.int32(message.types[i]);
            writer.join();
        }
        /* repeated rd.proto.uuid.UUID created_by_ids = 5; */
        for (let i = 0; i < message.createdByIds.length; i++)
            UUID.internalBinaryWrite(message.createdByIds[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 6; */
        if (message.cursor !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 7; */
        if (message.offset !== 0)
            writer.tag(7, WireType.Varint).int32(message.offset);
        /* int32 limit = 8; */
        if (message.limit !== 0)
            writer.tag(8, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCashTransactionsReq
 */
export const SearchCashTransactionsReq = new SearchCashTransactionsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCashTransactionsRes$Type extends MessageType<SearchCashTransactionsRes> {
    constructor() {
        super("rd.search.service.SearchCashTransactionsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchCashTransactionsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchCashTransactionsRes>): SearchCashTransactionsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCashTransactionsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCashTransactionsRes): SearchCashTransactionsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchCashTransactionsRes.Result results */ 1:
                    message.results.push(SearchCashTransactionsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCashTransactionsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchCashTransactionsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchCashTransactionsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCashTransactionsRes
 */
export const SearchCashTransactionsRes = new SearchCashTransactionsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCashTransactionsRes_Result$Type extends MessageType<SearchCashTransactionsRes_Result> {
    constructor() {
        super("rd.search.service.SearchCashTransactionsRes.Result", [
            { no: 1, name: "ledger_entry", kind: "message", T: () => LedgerEntry },
            { no: 2, name: "sale", kind: "message", T: () => Sale },
            { no: 3, name: "bill_payment", kind: "message", T: () => BillPayment },
            { no: 4, name: "petty_cash", kind: "message", T: () => PettyCash },
            { no: 5, name: "close_out", kind: "message", T: () => CloseOut },
            { no: 6, name: "sale_return", kind: "message", T: () => Sale_Return }
        ]);
    }
    create(value?: PartialMessage<SearchCashTransactionsRes_Result>): SearchCashTransactionsRes_Result {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCashTransactionsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCashTransactionsRes_Result): SearchCashTransactionsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.ledger.LedgerEntry ledger_entry */ 1:
                    message.ledgerEntry = LedgerEntry.internalBinaryRead(reader, reader.uint32(), options, message.ledgerEntry);
                    break;
                case /* rd.txn.Sale sale */ 2:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* rd.txn.BillPayment bill_payment */ 3:
                    message.billPayment = BillPayment.internalBinaryRead(reader, reader.uint32(), options, message.billPayment);
                    break;
                case /* rd.txn.PettyCash petty_cash */ 4:
                    message.pettyCash = PettyCash.internalBinaryRead(reader, reader.uint32(), options, message.pettyCash);
                    break;
                case /* rd.txn.CloseOut close_out */ 5:
                    message.closeOut = CloseOut.internalBinaryRead(reader, reader.uint32(), options, message.closeOut);
                    break;
                case /* rd.txn.Sale.Return sale_return */ 6:
                    message.saleReturn = Sale_Return.internalBinaryRead(reader, reader.uint32(), options, message.saleReturn);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCashTransactionsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.ledger.LedgerEntry ledger_entry = 1; */
        if (message.ledgerEntry)
            LedgerEntry.internalBinaryWrite(message.ledgerEntry, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.Sale sale = 2; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.BillPayment bill_payment = 3; */
        if (message.billPayment)
            BillPayment.internalBinaryWrite(message.billPayment, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.PettyCash petty_cash = 4; */
        if (message.pettyCash)
            PettyCash.internalBinaryWrite(message.pettyCash, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.CloseOut close_out = 5; */
        if (message.closeOut)
            CloseOut.internalBinaryWrite(message.closeOut, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.Sale.Return sale_return = 6; */
        if (message.saleReturn)
            Sale_Return.internalBinaryWrite(message.saleReturn, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCashTransactionsRes.Result
 */
export const SearchCashTransactionsRes_Result = new SearchCashTransactionsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchFinancialTransactionsReq$Type extends MessageType<SearchFinancialTransactionsReq> {
    constructor() {
        super("rd.search.service.SearchFinancialTransactionsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "vendor_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 3, name: "types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.payments.FinancialTransaction.Type", FinancialTransaction_Type, "TYPE_"] },
            { no: 4, name: "statuses", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.payments.FinancialTransaction.Status", FinancialTransaction_Status, "STATUS_"] },
            { no: 5, name: "range", kind: "message", T: () => TimestampRange },
            { no: 6, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "chart_of_account_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<SearchFinancialTransactionsReq>): SearchFinancialTransactionsReq {
        const message = { query: "", vendorIds: [], types: [], statuses: [], cursor: "", offset: 0, limit: 0, chartOfAccountIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchFinancialTransactionsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchFinancialTransactionsReq): SearchFinancialTransactionsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* repeated rd.proto.uuid.UUID vendor_ids */ 2:
                    message.vendorIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.payments.FinancialTransaction.Type types */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.types.push(reader.int32());
                    else
                        message.types.push(reader.int32());
                    break;
                case /* repeated rd.payments.FinancialTransaction.Status statuses */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.statuses.push(reader.int32());
                    else
                        message.statuses.push(reader.int32());
                    break;
                case /* rd.proto.timestamprange.TimestampRange range */ 5:
                    message.range = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.range);
                    break;
                case /* string cursor */ 6:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 7:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 8:
                    message.limit = reader.int32();
                    break;
                case /* repeated rd.proto.uuid.UUID chart_of_account_ids */ 9:
                    message.chartOfAccountIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchFinancialTransactionsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* repeated rd.proto.uuid.UUID vendor_ids = 2; */
        for (let i = 0; i < message.vendorIds.length; i++)
            UUID.internalBinaryWrite(message.vendorIds[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.payments.FinancialTransaction.Type types = 3; */
        if (message.types.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.types.length; i++)
                writer.int32(message.types[i]);
            writer.join();
        }
        /* repeated rd.payments.FinancialTransaction.Status statuses = 4; */
        if (message.statuses.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.statuses.length; i++)
                writer.int32(message.statuses[i]);
            writer.join();
        }
        /* rd.proto.timestamprange.TimestampRange range = 5; */
        if (message.range)
            TimestampRange.internalBinaryWrite(message.range, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 6; */
        if (message.cursor !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 7; */
        if (message.offset !== 0)
            writer.tag(7, WireType.Varint).int32(message.offset);
        /* int32 limit = 8; */
        if (message.limit !== 0)
            writer.tag(8, WireType.Varint).int32(message.limit);
        /* repeated rd.proto.uuid.UUID chart_of_account_ids = 9; */
        for (let i = 0; i < message.chartOfAccountIds.length; i++)
            UUID.internalBinaryWrite(message.chartOfAccountIds[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchFinancialTransactionsReq
 */
export const SearchFinancialTransactionsReq = new SearchFinancialTransactionsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchFinancialTransactionsRes$Type extends MessageType<SearchFinancialTransactionsRes> {
    constructor() {
        super("rd.search.service.SearchFinancialTransactionsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchFinancialTransactionsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchFinancialTransactionsRes>): SearchFinancialTransactionsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchFinancialTransactionsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchFinancialTransactionsRes): SearchFinancialTransactionsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchFinancialTransactionsRes.Result results */ 1:
                    message.results.push(SearchFinancialTransactionsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchFinancialTransactionsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchFinancialTransactionsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchFinancialTransactionsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchFinancialTransactionsRes
 */
export const SearchFinancialTransactionsRes = new SearchFinancialTransactionsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchFinancialTransactionsRes_Result$Type extends MessageType<SearchFinancialTransactionsRes_Result> {
    constructor() {
        super("rd.search.service.SearchFinancialTransactionsRes.Result", [
            { no: 1, name: "financial_transaction", kind: "message", T: () => FinancialTransaction },
            { no: 2, name: "vendor", kind: "message", T: () => Vendor },
            { no: 5, name: "accounts_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Account } },
            { no: 6, name: "order_shipment_data_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => SearchFinancialTransactionsRes_Result_OrderShipmentData } },
            { no: 7, name: "location", kind: "message", T: () => Location }
        ]);
    }
    create(value?: PartialMessage<SearchFinancialTransactionsRes_Result>): SearchFinancialTransactionsRes_Result {
        const message = { accountsById: {}, orderShipmentDataById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchFinancialTransactionsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchFinancialTransactionsRes_Result): SearchFinancialTransactionsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.payments.FinancialTransaction financial_transaction */ 1:
                    message.financialTransaction = FinancialTransaction.internalBinaryRead(reader, reader.uint32(), options, message.financialTransaction);
                    break;
                case /* rd.vendors.Vendor vendor */ 2:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* map<string, rd.coa.Account> accounts_by_id */ 5:
                    this.binaryReadMap5(message.accountsById, reader, options);
                    break;
                case /* map<string, rd.search.service.SearchFinancialTransactionsRes.Result.OrderShipmentData> order_shipment_data_by_id */ 6:
                    this.binaryReadMap6(message.orderShipmentDataById, reader, options);
                    break;
                case /* rd.company.Location location */ 7:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap5(map: SearchFinancialTransactionsRes_Result["accountsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchFinancialTransactionsRes_Result["accountsById"] | undefined, val: SearchFinancialTransactionsRes_Result["accountsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Account.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchFinancialTransactionsRes.Result.accounts_by_id");
            }
        }
        map[key ?? ""] = val ?? Account.create();
    }
    private binaryReadMap6(map: SearchFinancialTransactionsRes_Result["orderShipmentDataById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchFinancialTransactionsRes_Result["orderShipmentDataById"] | undefined, val: SearchFinancialTransactionsRes_Result["orderShipmentDataById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = SearchFinancialTransactionsRes_Result_OrderShipmentData.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchFinancialTransactionsRes.Result.order_shipment_data_by_id");
            }
        }
        map[key ?? ""] = val ?? SearchFinancialTransactionsRes_Result_OrderShipmentData.create();
    }
    internalBinaryWrite(message: SearchFinancialTransactionsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.payments.FinancialTransaction financial_transaction = 1; */
        if (message.financialTransaction)
            FinancialTransaction.internalBinaryWrite(message.financialTransaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Vendor vendor = 2; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.coa.Account> accounts_by_id = 5; */
        for (let k of Object.keys(message.accountsById)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Account.internalBinaryWrite(message.accountsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.search.service.SearchFinancialTransactionsRes.Result.OrderShipmentData> order_shipment_data_by_id = 6; */
        for (let k of Object.keys(message.orderShipmentDataById)) {
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            SearchFinancialTransactionsRes_Result_OrderShipmentData.internalBinaryWrite(message.orderShipmentDataById[k], writer, options);
            writer.join().join();
        }
        /* rd.company.Location location = 7; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchFinancialTransactionsRes.Result
 */
export const SearchFinancialTransactionsRes_Result = new SearchFinancialTransactionsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchFinancialTransactionsRes_Result_OrderShipmentData$Type extends MessageType<SearchFinancialTransactionsRes_Result_OrderShipmentData> {
    constructor() {
        super("rd.search.service.SearchFinancialTransactionsRes.Result.OrderShipmentData", [
            { no: 1, name: "order_shipment", kind: "message", T: () => OrderShipment },
            { no: 2, name: "vendor", kind: "message", T: () => Vendor },
            { no: 3, name: "total", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<SearchFinancialTransactionsRes_Result_OrderShipmentData>): SearchFinancialTransactionsRes_Result_OrderShipmentData {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchFinancialTransactionsRes_Result_OrderShipmentData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchFinancialTransactionsRes_Result_OrderShipmentData): SearchFinancialTransactionsRes_Result_OrderShipmentData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.OrderShipment order_shipment */ 1:
                    message.orderShipment = OrderShipment.internalBinaryRead(reader, reader.uint32(), options, message.orderShipment);
                    break;
                case /* rd.vendors.Vendor vendor */ 2:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* rd.proto.money.Money total */ 3:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchFinancialTransactionsRes_Result_OrderShipmentData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.OrderShipment order_shipment = 1; */
        if (message.orderShipment)
            OrderShipment.internalBinaryWrite(message.orderShipment, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Vendor vendor = 2; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total = 3; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchFinancialTransactionsRes.Result.OrderShipmentData
 */
export const SearchFinancialTransactionsRes_Result_OrderShipmentData = new SearchFinancialTransactionsRes_Result_OrderShipmentData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchInventoryCountProductsReq$Type extends MessageType<SearchInventoryCountProductsReq> {
    constructor() {
        super("rd.search.service.SearchInventoryCountProductsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "inventory_location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 3, name: "inventory_count_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 4, name: "product_status", kind: "enum", T: () => ["rd.product.InventoryCountProduct.Status", InventoryCountProduct_Status], options: { "rd.validator.require": true } },
            { no: 5, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "order_by", kind: "enum", T: () => ["rd.search.service.SearchInventoryCountProductsReq.OrderBy", SearchInventoryCountProductsReq_OrderBy, "ORDER_BY_"] },
            { no: 14, name: "sort_order", kind: "enum", T: () => ["rd.search.service.SortOrder", SortOrder, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<SearchInventoryCountProductsReq>): SearchInventoryCountProductsReq {
        const message = { query: "", productStatus: 0, cursor: "", offset: 0, limit: 0, orderBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchInventoryCountProductsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchInventoryCountProductsReq): SearchInventoryCountProductsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.uuid.UUID inventory_location_id */ 2:
                    message.inventoryLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.inventoryLocationId);
                    break;
                case /* rd.proto.uuid.UUID inventory_count_id */ 3:
                    message.inventoryCountId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.inventoryCountId);
                    break;
                case /* rd.product.InventoryCountProduct.Status product_status */ 4:
                    message.productStatus = reader.int32();
                    break;
                case /* string cursor */ 5:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 6:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 7:
                    message.limit = reader.int32();
                    break;
                case /* rd.search.service.SearchInventoryCountProductsReq.OrderBy order_by */ 13:
                    message.orderBy = reader.int32();
                    break;
                case /* rd.search.service.SortOrder sort_order */ 14:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchInventoryCountProductsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.uuid.UUID inventory_location_id = 2; */
        if (message.inventoryLocationId)
            UUID.internalBinaryWrite(message.inventoryLocationId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID inventory_count_id = 3; */
        if (message.inventoryCountId)
            UUID.internalBinaryWrite(message.inventoryCountId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.product.InventoryCountProduct.Status product_status = 4; */
        if (message.productStatus !== 0)
            writer.tag(4, WireType.Varint).int32(message.productStatus);
        /* string cursor = 5; */
        if (message.cursor !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 6; */
        if (message.offset !== 0)
            writer.tag(6, WireType.Varint).int32(message.offset);
        /* int32 limit = 7; */
        if (message.limit !== 0)
            writer.tag(7, WireType.Varint).int32(message.limit);
        /* rd.search.service.SearchInventoryCountProductsReq.OrderBy order_by = 13; */
        if (message.orderBy !== 0)
            writer.tag(13, WireType.Varint).int32(message.orderBy);
        /* rd.search.service.SortOrder sort_order = 14; */
        if (message.sortOrder !== 0)
            writer.tag(14, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchInventoryCountProductsReq
 */
export const SearchInventoryCountProductsReq = new SearchInventoryCountProductsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchInventoryCountProductsRes$Type extends MessageType<SearchInventoryCountProductsRes> {
    constructor() {
        super("rd.search.service.SearchInventoryCountProductsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchInventoryCountProductsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchInventoryCountProductsRes>): SearchInventoryCountProductsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchInventoryCountProductsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchInventoryCountProductsRes): SearchInventoryCountProductsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchInventoryCountProductsRes.Result results */ 1:
                    message.results.push(SearchInventoryCountProductsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchInventoryCountProductsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchInventoryCountProductsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchInventoryCountProductsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchInventoryCountProductsRes
 */
export const SearchInventoryCountProductsRes = new SearchInventoryCountProductsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchInventoryCountProductsRes_Result$Type extends MessageType<SearchInventoryCountProductsRes_Result> {
    constructor() {
        super("rd.search.service.SearchInventoryCountProductsRes.Result", [
            { no: 1, name: "product", kind: "message", T: () => Product },
            { no: 2, name: "on_hand", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "counted", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "variance", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "dollar_variance", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<SearchInventoryCountProductsRes_Result>): SearchInventoryCountProductsRes_Result {
        const message = { onHand: 0, counted: 0, variance: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchInventoryCountProductsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchInventoryCountProductsRes_Result): SearchInventoryCountProductsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.product.Product product */ 1:
                    message.product = Product.internalBinaryRead(reader, reader.uint32(), options, message.product);
                    break;
                case /* int32 on_hand */ 2:
                    message.onHand = reader.int32();
                    break;
                case /* int32 counted */ 3:
                    message.counted = reader.int32();
                    break;
                case /* int32 variance */ 4:
                    message.variance = reader.int32();
                    break;
                case /* rd.proto.money.Money dollar_variance */ 5:
                    message.dollarVariance = Money.internalBinaryRead(reader, reader.uint32(), options, message.dollarVariance);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchInventoryCountProductsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.product.Product product = 1; */
        if (message.product)
            Product.internalBinaryWrite(message.product, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 on_hand = 2; */
        if (message.onHand !== 0)
            writer.tag(2, WireType.Varint).int32(message.onHand);
        /* int32 counted = 3; */
        if (message.counted !== 0)
            writer.tag(3, WireType.Varint).int32(message.counted);
        /* int32 variance = 4; */
        if (message.variance !== 0)
            writer.tag(4, WireType.Varint).int32(message.variance);
        /* rd.proto.money.Money dollar_variance = 5; */
        if (message.dollarVariance)
            Money.internalBinaryWrite(message.dollarVariance, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchInventoryCountProductsRes.Result
 */
export const SearchInventoryCountProductsRes_Result = new SearchInventoryCountProductsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkExportInventoryCountProductsReq$Type extends MessageType<BulkExportInventoryCountProductsReq> {
    constructor() {
        super("rd.search.service.BulkExportInventoryCountProductsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "inventory_location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 3, name: "inventory_count_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 4, name: "product_status", kind: "enum", T: () => ["rd.product.InventoryCountProduct.Status", InventoryCountProduct_Status], options: { "rd.validator.require": true } },
            { no: 5, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "order_by", kind: "enum", T: () => ["rd.search.service.BulkExportInventoryCountProductsReq.OrderBy", BulkExportInventoryCountProductsReq_OrderBy, "ORDER_BY_"] },
            { no: 14, name: "sort_order", kind: "enum", T: () => ["rd.search.service.SortOrder", SortOrder, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<BulkExportInventoryCountProductsReq>): BulkExportInventoryCountProductsReq {
        const message = { query: "", productStatus: 0, cursor: "", offset: 0, limit: 0, orderBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkExportInventoryCountProductsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkExportInventoryCountProductsReq): BulkExportInventoryCountProductsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.uuid.UUID inventory_location_id */ 2:
                    message.inventoryLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.inventoryLocationId);
                    break;
                case /* rd.proto.uuid.UUID inventory_count_id */ 3:
                    message.inventoryCountId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.inventoryCountId);
                    break;
                case /* rd.product.InventoryCountProduct.Status product_status */ 4:
                    message.productStatus = reader.int32();
                    break;
                case /* string cursor */ 5:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 6:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 7:
                    message.limit = reader.int32();
                    break;
                case /* rd.search.service.BulkExportInventoryCountProductsReq.OrderBy order_by */ 13:
                    message.orderBy = reader.int32();
                    break;
                case /* rd.search.service.SortOrder sort_order */ 14:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkExportInventoryCountProductsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.uuid.UUID inventory_location_id = 2; */
        if (message.inventoryLocationId)
            UUID.internalBinaryWrite(message.inventoryLocationId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID inventory_count_id = 3; */
        if (message.inventoryCountId)
            UUID.internalBinaryWrite(message.inventoryCountId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.product.InventoryCountProduct.Status product_status = 4; */
        if (message.productStatus !== 0)
            writer.tag(4, WireType.Varint).int32(message.productStatus);
        /* string cursor = 5; */
        if (message.cursor !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 6; */
        if (message.offset !== 0)
            writer.tag(6, WireType.Varint).int32(message.offset);
        /* int32 limit = 7; */
        if (message.limit !== 0)
            writer.tag(7, WireType.Varint).int32(message.limit);
        /* rd.search.service.BulkExportInventoryCountProductsReq.OrderBy order_by = 13; */
        if (message.orderBy !== 0)
            writer.tag(13, WireType.Varint).int32(message.orderBy);
        /* rd.search.service.SortOrder sort_order = 14; */
        if (message.sortOrder !== 0)
            writer.tag(14, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.BulkExportInventoryCountProductsReq
 */
export const BulkExportInventoryCountProductsReq = new BulkExportInventoryCountProductsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkExportInventoryCountProductsRes$Type extends MessageType<BulkExportInventoryCountProductsRes> {
    constructor() {
        super("rd.search.service.BulkExportInventoryCountProductsRes", [
            { no: 1, name: "excel_bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<BulkExportInventoryCountProductsRes>): BulkExportInventoryCountProductsRes {
        const message = { excelBytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkExportInventoryCountProductsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkExportInventoryCountProductsRes): BulkExportInventoryCountProductsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes excel_bytes */ 1:
                    message.excelBytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkExportInventoryCountProductsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes excel_bytes = 1; */
        if (message.excelBytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.excelBytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.BulkExportInventoryCountProductsRes
 */
export const BulkExportInventoryCountProductsRes = new BulkExportInventoryCountProductsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchExcludedInventoryCountProductsReq$Type extends MessageType<SearchExcludedInventoryCountProductsReq> {
    constructor() {
        super("rd.search.service.SearchExcludedInventoryCountProductsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 3, name: "inventory_count_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 4, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "department_id", kind: "message", T: () => UUID },
            { no: 8, name: "vendor_id", kind: "message", T: () => UUID },
            { no: 9, name: "class_id", kind: "message", T: () => UUID },
            { no: 10, name: "fineline_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<SearchExcludedInventoryCountProductsReq>): SearchExcludedInventoryCountProductsReq {
        const message = { query: "", cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchExcludedInventoryCountProductsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchExcludedInventoryCountProductsReq): SearchExcludedInventoryCountProductsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.uuid.UUID location_id */ 2:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* rd.proto.uuid.UUID inventory_count_id */ 3:
                    message.inventoryCountId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.inventoryCountId);
                    break;
                case /* string cursor */ 4:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 5:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 6:
                    message.limit = reader.int32();
                    break;
                case /* rd.proto.uuid.UUID department_id */ 7:
                    message.departmentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.departmentId);
                    break;
                case /* rd.proto.uuid.UUID vendor_id */ 8:
                    message.vendorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.vendorId);
                    break;
                case /* rd.proto.uuid.UUID class_id */ 9:
                    message.classId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.classId);
                    break;
                case /* rd.proto.uuid.UUID fineline_id */ 10:
                    message.finelineId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.finelineId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchExcludedInventoryCountProductsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.uuid.UUID location_id = 2; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID inventory_count_id = 3; */
        if (message.inventoryCountId)
            UUID.internalBinaryWrite(message.inventoryCountId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 4; */
        if (message.cursor !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 5; */
        if (message.offset !== 0)
            writer.tag(5, WireType.Varint).int32(message.offset);
        /* int32 limit = 6; */
        if (message.limit !== 0)
            writer.tag(6, WireType.Varint).int32(message.limit);
        /* rd.proto.uuid.UUID department_id = 7; */
        if (message.departmentId)
            UUID.internalBinaryWrite(message.departmentId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID vendor_id = 8; */
        if (message.vendorId)
            UUID.internalBinaryWrite(message.vendorId, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID class_id = 9; */
        if (message.classId)
            UUID.internalBinaryWrite(message.classId, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID fineline_id = 10; */
        if (message.finelineId)
            UUID.internalBinaryWrite(message.finelineId, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchExcludedInventoryCountProductsReq
 */
export const SearchExcludedInventoryCountProductsReq = new SearchExcludedInventoryCountProductsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchExcludedInventoryCountProductsRes$Type extends MessageType<SearchExcludedInventoryCountProductsRes> {
    constructor() {
        super("rd.search.service.SearchExcludedInventoryCountProductsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchExcludedInventoryCountProductsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchExcludedInventoryCountProductsRes>): SearchExcludedInventoryCountProductsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchExcludedInventoryCountProductsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchExcludedInventoryCountProductsRes): SearchExcludedInventoryCountProductsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchExcludedInventoryCountProductsRes.Result results */ 1:
                    message.results.push(SearchExcludedInventoryCountProductsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchExcludedInventoryCountProductsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchExcludedInventoryCountProductsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchExcludedInventoryCountProductsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchExcludedInventoryCountProductsRes
 */
export const SearchExcludedInventoryCountProductsRes = new SearchExcludedInventoryCountProductsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchExcludedInventoryCountProductsRes_Result$Type extends MessageType<SearchExcludedInventoryCountProductsRes_Result> {
    constructor() {
        super("rd.search.service.SearchExcludedInventoryCountProductsRes.Result", [
            { no: 1, name: "product", kind: "message", T: () => Product },
            { no: 2, name: "tier_prices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Tier }
        ]);
    }
    create(value?: PartialMessage<SearchExcludedInventoryCountProductsRes_Result>): SearchExcludedInventoryCountProductsRes_Result {
        const message = { tierPrices: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchExcludedInventoryCountProductsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchExcludedInventoryCountProductsRes_Result): SearchExcludedInventoryCountProductsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.product.Product product */ 1:
                    message.product = Product.internalBinaryRead(reader, reader.uint32(), options, message.product);
                    break;
                case /* repeated rd.price.Tier tier_prices */ 2:
                    message.tierPrices.push(Tier.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchExcludedInventoryCountProductsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.product.Product product = 1; */
        if (message.product)
            Product.internalBinaryWrite(message.product, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.price.Tier tier_prices = 2; */
        for (let i = 0; i < message.tierPrices.length; i++)
            Tier.internalBinaryWrite(message.tierPrices[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchExcludedInventoryCountProductsRes.Result
 */
export const SearchExcludedInventoryCountProductsRes_Result = new SearchExcludedInventoryCountProductsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchExternalTransactionsReq$Type extends MessageType<SearchExternalTransactionsReq> {
    constructor() {
        super("rd.search.service.SearchExternalTransactionsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "transacted_at_range", kind: "message", T: () => TimestampRange },
            { no: 3, name: "location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 4, name: "customer_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 5, name: "vendor_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 6, name: "chart_of_account_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 7, name: "types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.accounting.ExternalTransaction.Type", ExternalTransaction_Type, "TYPE_"] },
            { no: 8, name: "payment_methods", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.accounting.ExternalTransaction.PaymentMethod", ExternalTransaction_PaymentMethod, "PAYMENT_METHOD_"] },
            { no: 9, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchExternalTransactionsReq>): SearchExternalTransactionsReq {
        const message = { query: "", locationIds: [], customerIds: [], vendorIds: [], chartOfAccountIds: [], types: [], paymentMethods: [], cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchExternalTransactionsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchExternalTransactionsReq): SearchExternalTransactionsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.timestamprange.TimestampRange transacted_at_range */ 2:
                    message.transactedAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.transactedAtRange);
                    break;
                case /* repeated rd.proto.uuid.UUID location_ids */ 3:
                    message.locationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID customer_ids */ 4:
                    message.customerIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID vendor_ids */ 5:
                    message.vendorIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID chart_of_account_ids */ 6:
                    message.chartOfAccountIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.accounting.ExternalTransaction.Type types */ 7:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.types.push(reader.int32());
                    else
                        message.types.push(reader.int32());
                    break;
                case /* repeated rd.accounting.ExternalTransaction.PaymentMethod payment_methods */ 8:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.paymentMethods.push(reader.int32());
                    else
                        message.paymentMethods.push(reader.int32());
                    break;
                case /* string cursor */ 9:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 10:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 11:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchExternalTransactionsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.timestamprange.TimestampRange transacted_at_range = 2; */
        if (message.transactedAtRange)
            TimestampRange.internalBinaryWrite(message.transactedAtRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID location_ids = 3; */
        for (let i = 0; i < message.locationIds.length; i++)
            UUID.internalBinaryWrite(message.locationIds[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID customer_ids = 4; */
        for (let i = 0; i < message.customerIds.length; i++)
            UUID.internalBinaryWrite(message.customerIds[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID vendor_ids = 5; */
        for (let i = 0; i < message.vendorIds.length; i++)
            UUID.internalBinaryWrite(message.vendorIds[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID chart_of_account_ids = 6; */
        for (let i = 0; i < message.chartOfAccountIds.length; i++)
            UUID.internalBinaryWrite(message.chartOfAccountIds[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.accounting.ExternalTransaction.Type types = 7; */
        if (message.types.length) {
            writer.tag(7, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.types.length; i++)
                writer.int32(message.types[i]);
            writer.join();
        }
        /* repeated rd.accounting.ExternalTransaction.PaymentMethod payment_methods = 8; */
        if (message.paymentMethods.length) {
            writer.tag(8, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.paymentMethods.length; i++)
                writer.int32(message.paymentMethods[i]);
            writer.join();
        }
        /* string cursor = 9; */
        if (message.cursor !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 10; */
        if (message.offset !== 0)
            writer.tag(10, WireType.Varint).int32(message.offset);
        /* int32 limit = 11; */
        if (message.limit !== 0)
            writer.tag(11, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchExternalTransactionsReq
 */
export const SearchExternalTransactionsReq = new SearchExternalTransactionsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchExternalTransactionsRes$Type extends MessageType<SearchExternalTransactionsRes> {
    constructor() {
        super("rd.search.service.SearchExternalTransactionsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchExternalTransactionsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchExternalTransactionsRes>): SearchExternalTransactionsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchExternalTransactionsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchExternalTransactionsRes): SearchExternalTransactionsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchExternalTransactionsRes.Result results */ 1:
                    message.results.push(SearchExternalTransactionsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchExternalTransactionsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchExternalTransactionsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchExternalTransactionsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchExternalTransactionsRes
 */
export const SearchExternalTransactionsRes = new SearchExternalTransactionsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchExternalTransactionsRes_Result$Type extends MessageType<SearchExternalTransactionsRes_Result> {
    constructor() {
        super("rd.search.service.SearchExternalTransactionsRes.Result", [
            { no: 1, name: "external_transaction", kind: "message", T: () => ExternalTransaction },
            { no: 2, name: "location", kind: "message", T: () => Location },
            { no: 3, name: "customer", kind: "message", T: () => Customer },
            { no: 4, name: "vendor", kind: "message", T: () => Vendor },
            { no: 7, name: "accounts_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Account } },
            { no: 8, name: "order_shipment_data_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => SearchExternalTransactionsRes_Result_OrderShipmentData } }
        ]);
    }
    create(value?: PartialMessage<SearchExternalTransactionsRes_Result>): SearchExternalTransactionsRes_Result {
        const message = { accountsById: {}, orderShipmentDataById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchExternalTransactionsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchExternalTransactionsRes_Result): SearchExternalTransactionsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.accounting.ExternalTransaction external_transaction */ 1:
                    message.externalTransaction = ExternalTransaction.internalBinaryRead(reader, reader.uint32(), options, message.externalTransaction);
                    break;
                case /* rd.company.Location location */ 2:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.customer.Customer customer */ 3:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.vendors.Vendor vendor */ 4:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* map<string, rd.coa.Account> accounts_by_id */ 7:
                    this.binaryReadMap7(message.accountsById, reader, options);
                    break;
                case /* map<string, rd.search.service.SearchExternalTransactionsRes.Result.OrderShipmentData> order_shipment_data_by_id */ 8:
                    this.binaryReadMap8(message.orderShipmentDataById, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap7(map: SearchExternalTransactionsRes_Result["accountsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchExternalTransactionsRes_Result["accountsById"] | undefined, val: SearchExternalTransactionsRes_Result["accountsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Account.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchExternalTransactionsRes.Result.accounts_by_id");
            }
        }
        map[key ?? ""] = val ?? Account.create();
    }
    private binaryReadMap8(map: SearchExternalTransactionsRes_Result["orderShipmentDataById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchExternalTransactionsRes_Result["orderShipmentDataById"] | undefined, val: SearchExternalTransactionsRes_Result["orderShipmentDataById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = SearchExternalTransactionsRes_Result_OrderShipmentData.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchExternalTransactionsRes.Result.order_shipment_data_by_id");
            }
        }
        map[key ?? ""] = val ?? SearchExternalTransactionsRes_Result_OrderShipmentData.create();
    }
    internalBinaryWrite(message: SearchExternalTransactionsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.accounting.ExternalTransaction external_transaction = 1; */
        if (message.externalTransaction)
            ExternalTransaction.internalBinaryWrite(message.externalTransaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 2; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Customer customer = 3; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Vendor vendor = 4; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.coa.Account> accounts_by_id = 7; */
        for (let k of Object.keys(message.accountsById)) {
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Account.internalBinaryWrite(message.accountsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.search.service.SearchExternalTransactionsRes.Result.OrderShipmentData> order_shipment_data_by_id = 8; */
        for (let k of Object.keys(message.orderShipmentDataById)) {
            writer.tag(8, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            SearchExternalTransactionsRes_Result_OrderShipmentData.internalBinaryWrite(message.orderShipmentDataById[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchExternalTransactionsRes.Result
 */
export const SearchExternalTransactionsRes_Result = new SearchExternalTransactionsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchExternalTransactionsRes_Result_OrderShipmentData$Type extends MessageType<SearchExternalTransactionsRes_Result_OrderShipmentData> {
    constructor() {
        super("rd.search.service.SearchExternalTransactionsRes.Result.OrderShipmentData", [
            { no: 1, name: "order_shipment", kind: "message", T: () => OrderShipment },
            { no: 2, name: "vendor", kind: "message", T: () => Vendor },
            { no: 3, name: "total", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<SearchExternalTransactionsRes_Result_OrderShipmentData>): SearchExternalTransactionsRes_Result_OrderShipmentData {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchExternalTransactionsRes_Result_OrderShipmentData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchExternalTransactionsRes_Result_OrderShipmentData): SearchExternalTransactionsRes_Result_OrderShipmentData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.OrderShipment order_shipment */ 1:
                    message.orderShipment = OrderShipment.internalBinaryRead(reader, reader.uint32(), options, message.orderShipment);
                    break;
                case /* rd.vendors.Vendor vendor */ 2:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* rd.proto.money.Money total */ 3:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchExternalTransactionsRes_Result_OrderShipmentData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.OrderShipment order_shipment = 1; */
        if (message.orderShipment)
            OrderShipment.internalBinaryWrite(message.orderShipment, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Vendor vendor = 2; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total = 3; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchExternalTransactionsRes.Result.OrderShipmentData
 */
export const SearchExternalTransactionsRes_Result_OrderShipmentData = new SearchExternalTransactionsRes_Result_OrderShipmentData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOrderShipmentsForReconciliationReq$Type extends MessageType<SearchOrderShipmentsForReconciliationReq> {
    constructor() {
        super("rd.search.service.SearchOrderShipmentsForReconciliationReq", [
            { no: 1, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "transaction_id", kind: "message", T: () => UUID },
            { no: 6, name: "stripe_transaction_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchOrderShipmentsForReconciliationReq>): SearchOrderShipmentsForReconciliationReq {
        const message = { cursor: "", offset: 0, limit: 0, query: "", stripeTransactionId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOrderShipmentsForReconciliationReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOrderShipmentsForReconciliationReq): SearchOrderShipmentsForReconciliationReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string cursor */ 1:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 2:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 3:
                    message.limit = reader.int32();
                    break;
                case /* string query */ 4:
                    message.query = reader.string();
                    break;
                case /* rd.proto.uuid.UUID transaction_id */ 5:
                    message.transactionId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.transactionId);
                    break;
                case /* string stripe_transaction_id */ 6:
                    message.stripeTransactionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOrderShipmentsForReconciliationReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string cursor = 1; */
        if (message.cursor !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 2; */
        if (message.offset !== 0)
            writer.tag(2, WireType.Varint).int32(message.offset);
        /* int32 limit = 3; */
        if (message.limit !== 0)
            writer.tag(3, WireType.Varint).int32(message.limit);
        /* string query = 4; */
        if (message.query !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.query);
        /* rd.proto.uuid.UUID transaction_id = 5; */
        if (message.transactionId)
            UUID.internalBinaryWrite(message.transactionId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string stripe_transaction_id = 6; */
        if (message.stripeTransactionId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.stripeTransactionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOrderShipmentsForReconciliationReq
 */
export const SearchOrderShipmentsForReconciliationReq = new SearchOrderShipmentsForReconciliationReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOrderShipmentsForReconciliationRes$Type extends MessageType<SearchOrderShipmentsForReconciliationRes> {
    constructor() {
        super("rd.search.service.SearchOrderShipmentsForReconciliationRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchOrderShipmentsForReconciliationRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchOrderShipmentsForReconciliationRes>): SearchOrderShipmentsForReconciliationRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOrderShipmentsForReconciliationRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOrderShipmentsForReconciliationRes): SearchOrderShipmentsForReconciliationRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchOrderShipmentsForReconciliationRes.Result results */ 1:
                    message.results.push(SearchOrderShipmentsForReconciliationRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOrderShipmentsForReconciliationRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchOrderShipmentsForReconciliationRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchOrderShipmentsForReconciliationRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOrderShipmentsForReconciliationRes
 */
export const SearchOrderShipmentsForReconciliationRes = new SearchOrderShipmentsForReconciliationRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOrderShipmentsForReconciliationRes_Result$Type extends MessageType<SearchOrderShipmentsForReconciliationRes_Result> {
    constructor() {
        super("rd.search.service.SearchOrderShipmentsForReconciliationRes.Result", [
            { no: 1, name: "order_shipment", kind: "message", T: () => OrderShipment },
            { no: 2, name: "vendor", kind: "message", T: () => Vendor },
            { no: 3, name: "total", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<SearchOrderShipmentsForReconciliationRes_Result>): SearchOrderShipmentsForReconciliationRes_Result {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOrderShipmentsForReconciliationRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOrderShipmentsForReconciliationRes_Result): SearchOrderShipmentsForReconciliationRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.OrderShipment order_shipment */ 1:
                    message.orderShipment = OrderShipment.internalBinaryRead(reader, reader.uint32(), options, message.orderShipment);
                    break;
                case /* rd.vendors.Vendor vendor */ 2:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* rd.proto.money.Money total */ 3:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOrderShipmentsForReconciliationRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.OrderShipment order_shipment = 1; */
        if (message.orderShipment)
            OrderShipment.internalBinaryWrite(message.orderShipment, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Vendor vendor = 2; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total = 3; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOrderShipmentsForReconciliationRes.Result
 */
export const SearchOrderShipmentsForReconciliationRes_Result = new SearchOrderShipmentsForReconciliationRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchInventoryChangesReq$Type extends MessageType<SearchInventoryChangesReq> {
    constructor() {
        super("rd.search.service.SearchInventoryChangesReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "product_id", kind: "message", T: () => UUID },
            { no: 4, name: "staff_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 5, name: "types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.search.service.SearchInventoryChangesReq.Type", SearchInventoryChangesReq_Type, "TYPE_"] },
            { no: 6, name: "range", kind: "message", T: () => TimestampRange },
            { no: 7, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<SearchInventoryChangesReq>): SearchInventoryChangesReq {
        const message = { query: "", staffIds: [], types: [], cursor: "", offset: 0, limit: 0, locationIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchInventoryChangesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchInventoryChangesReq): SearchInventoryChangesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.uuid.UUID product_id */ 2:
                    message.productId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.productId);
                    break;
                case /* repeated rd.proto.uuid.UUID staff_ids */ 4:
                    message.staffIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.search.service.SearchInventoryChangesReq.Type types */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.types.push(reader.int32());
                    else
                        message.types.push(reader.int32());
                    break;
                case /* rd.proto.timestamprange.TimestampRange range */ 6:
                    message.range = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.range);
                    break;
                case /* string cursor */ 7:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 8:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 9:
                    message.limit = reader.int32();
                    break;
                case /* repeated rd.proto.uuid.UUID location_ids */ 10:
                    message.locationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchInventoryChangesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.uuid.UUID product_id = 2; */
        if (message.productId)
            UUID.internalBinaryWrite(message.productId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID staff_ids = 4; */
        for (let i = 0; i < message.staffIds.length; i++)
            UUID.internalBinaryWrite(message.staffIds[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.search.service.SearchInventoryChangesReq.Type types = 5; */
        if (message.types.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.types.length; i++)
                writer.int32(message.types[i]);
            writer.join();
        }
        /* rd.proto.timestamprange.TimestampRange range = 6; */
        if (message.range)
            TimestampRange.internalBinaryWrite(message.range, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 7; */
        if (message.cursor !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 8; */
        if (message.offset !== 0)
            writer.tag(8, WireType.Varint).int32(message.offset);
        /* int32 limit = 9; */
        if (message.limit !== 0)
            writer.tag(9, WireType.Varint).int32(message.limit);
        /* repeated rd.proto.uuid.UUID location_ids = 10; */
        for (let i = 0; i < message.locationIds.length; i++)
            UUID.internalBinaryWrite(message.locationIds[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchInventoryChangesReq
 */
export const SearchInventoryChangesReq = new SearchInventoryChangesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchInventoryChangesRes$Type extends MessageType<SearchInventoryChangesRes> {
    constructor() {
        super("rd.search.service.SearchInventoryChangesRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchInventoryChangesRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchInventoryChangesRes>): SearchInventoryChangesRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchInventoryChangesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchInventoryChangesRes): SearchInventoryChangesRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchInventoryChangesRes.Result results */ 1:
                    message.results.push(SearchInventoryChangesRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchInventoryChangesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchInventoryChangesRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchInventoryChangesRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchInventoryChangesRes
 */
export const SearchInventoryChangesRes = new SearchInventoryChangesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchInventoryChangesRes_Result$Type extends MessageType<SearchInventoryChangesRes_Result> {
    constructor() {
        super("rd.search.service.SearchInventoryChangesRes.Result", [
            { no: 1, name: "inventory_change", kind: "message", T: () => InventoryChange }
        ]);
    }
    create(value?: PartialMessage<SearchInventoryChangesRes_Result>): SearchInventoryChangesRes_Result {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchInventoryChangesRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchInventoryChangesRes_Result): SearchInventoryChangesRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.product.InventoryChange inventory_change */ 1:
                    message.inventoryChange = InventoryChange.internalBinaryRead(reader, reader.uint32(), options, message.inventoryChange);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchInventoryChangesRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.product.InventoryChange inventory_change = 1; */
        if (message.inventoryChange)
            InventoryChange.internalBinaryWrite(message.inventoryChange, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchInventoryChangesRes.Result
 */
export const SearchInventoryChangesRes_Result = new SearchInventoryChangesRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCustomPriceRulesReq$Type extends MessageType<SearchCustomPriceRulesReq> {
    constructor() {
        super("rd.search.service.SearchCustomPriceRulesReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "customer_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 6, name: "department_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 7, name: "vendor_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 8, name: "class_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 9, name: "fineline_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 10, name: "sort_by", kind: "enum", T: () => ["rd.search.service.CustomPriceRuleSortBy", CustomPriceRuleSortBy, "CUSTOM_PRICE_RULE_SORT_BY_"] },
            { no: 11, name: "sort_order", kind: "enum", T: () => ["rd.search.service.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 12, name: "type", kind: "enum", T: () => ["rd.search.service.CustomPriceRuleType", CustomPriceRuleType, "CUSTOM_PRICE_RULE_TYPE_"] },
            { no: 13, name: "is_active_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 14, name: "job_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<SearchCustomPriceRulesReq>): SearchCustomPriceRulesReq {
        const message = { query: "", cursor: "", offset: 0, limit: 0, customerIds: [], departmentIds: [], vendorIds: [], classIds: [], finelineIds: [], sortBy: 0, sortOrder: 0, type: 0, isActiveFilter: 0, jobIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCustomPriceRulesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCustomPriceRulesReq): SearchCustomPriceRulesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 3:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 4:
                    message.limit = reader.int32();
                    break;
                case /* repeated rd.proto.uuid.UUID customer_ids */ 5:
                    message.customerIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID department_ids */ 6:
                    message.departmentIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID vendor_ids */ 7:
                    message.vendorIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID class_ids */ 8:
                    message.classIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID fineline_ids */ 9:
                    message.finelineIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.search.service.CustomPriceRuleSortBy sort_by */ 10:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.search.service.SortOrder sort_order */ 11:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.search.service.CustomPriceRuleType type */ 12:
                    message.type = reader.int32();
                    break;
                case /* rd.search.service.BoolFilter is_active_filter */ 13:
                    message.isActiveFilter = reader.int32();
                    break;
                case /* repeated rd.proto.uuid.UUID job_ids */ 14:
                    message.jobIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCustomPriceRulesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 3; */
        if (message.offset !== 0)
            writer.tag(3, WireType.Varint).int32(message.offset);
        /* int32 limit = 4; */
        if (message.limit !== 0)
            writer.tag(4, WireType.Varint).int32(message.limit);
        /* repeated rd.proto.uuid.UUID customer_ids = 5; */
        for (let i = 0; i < message.customerIds.length; i++)
            UUID.internalBinaryWrite(message.customerIds[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID department_ids = 6; */
        for (let i = 0; i < message.departmentIds.length; i++)
            UUID.internalBinaryWrite(message.departmentIds[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID vendor_ids = 7; */
        for (let i = 0; i < message.vendorIds.length; i++)
            UUID.internalBinaryWrite(message.vendorIds[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID class_ids = 8; */
        for (let i = 0; i < message.classIds.length; i++)
            UUID.internalBinaryWrite(message.classIds[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID fineline_ids = 9; */
        for (let i = 0; i < message.finelineIds.length; i++)
            UUID.internalBinaryWrite(message.finelineIds[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.search.service.CustomPriceRuleSortBy sort_by = 10; */
        if (message.sortBy !== 0)
            writer.tag(10, WireType.Varint).int32(message.sortBy);
        /* rd.search.service.SortOrder sort_order = 11; */
        if (message.sortOrder !== 0)
            writer.tag(11, WireType.Varint).int32(message.sortOrder);
        /* rd.search.service.CustomPriceRuleType type = 12; */
        if (message.type !== 0)
            writer.tag(12, WireType.Varint).int32(message.type);
        /* rd.search.service.BoolFilter is_active_filter = 13; */
        if (message.isActiveFilter !== 0)
            writer.tag(13, WireType.Varint).int32(message.isActiveFilter);
        /* repeated rd.proto.uuid.UUID job_ids = 14; */
        for (let i = 0; i < message.jobIds.length; i++)
            UUID.internalBinaryWrite(message.jobIds[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCustomPriceRulesReq
 */
export const SearchCustomPriceRulesReq = new SearchCustomPriceRulesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCustomPriceRulesRes$Type extends MessageType<SearchCustomPriceRulesRes> {
    constructor() {
        super("rd.search.service.SearchCustomPriceRulesRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchCustomPriceRulesRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchCustomPriceRulesRes>): SearchCustomPriceRulesRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCustomPriceRulesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCustomPriceRulesRes): SearchCustomPriceRulesRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchCustomPriceRulesRes.Result results */ 1:
                    message.results.push(SearchCustomPriceRulesRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCustomPriceRulesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchCustomPriceRulesRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchCustomPriceRulesRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCustomPriceRulesRes
 */
export const SearchCustomPriceRulesRes = new SearchCustomPriceRulesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCustomPriceRulesRes_Result$Type extends MessageType<SearchCustomPriceRulesRes_Result> {
    constructor() {
        super("rd.search.service.SearchCustomPriceRulesRes.Result", [
            { no: 1, name: "custom", kind: "message", T: () => Custom },
            { no: 10, name: "product_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "customer", kind: "message", T: () => Customer },
            { no: 12, name: "vendor", kind: "message", T: () => Vendor },
            { no: 13, name: "department", kind: "message", T: () => ProductCategory },
            { no: 14, name: "class", kind: "message", T: () => ProductCategory },
            { no: 15, name: "fineline", kind: "message", T: () => ProductCategory },
            { no: 16, name: "product", kind: "message", T: () => Product },
            { no: 17, name: "tier_prices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Tier },
            { no: 18, name: "job", kind: "message", T: () => Customer }
        ]);
    }
    create(value?: PartialMessage<SearchCustomPriceRulesRes_Result>): SearchCustomPriceRulesRes_Result {
        const message = { productCount: 0, tierPrices: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCustomPriceRulesRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCustomPriceRulesRes_Result): SearchCustomPriceRulesRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.price.Custom custom */ 1:
                    message.custom = Custom.internalBinaryRead(reader, reader.uint32(), options, message.custom);
                    break;
                case /* int32 product_count */ 10:
                    message.productCount = reader.int32();
                    break;
                case /* rd.customer.Customer customer */ 11:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.vendors.Vendor vendor */ 12:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* rd.company.ProductCategory department */ 13:
                    message.department = ProductCategory.internalBinaryRead(reader, reader.uint32(), options, message.department);
                    break;
                case /* rd.company.ProductCategory class */ 14:
                    message.class = ProductCategory.internalBinaryRead(reader, reader.uint32(), options, message.class);
                    break;
                case /* rd.company.ProductCategory fineline */ 15:
                    message.fineline = ProductCategory.internalBinaryRead(reader, reader.uint32(), options, message.fineline);
                    break;
                case /* rd.product.Product product */ 16:
                    message.product = Product.internalBinaryRead(reader, reader.uint32(), options, message.product);
                    break;
                case /* repeated rd.price.Tier tier_prices */ 17:
                    message.tierPrices.push(Tier.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.customer.Customer job */ 18:
                    message.job = Customer.internalBinaryRead(reader, reader.uint32(), options, message.job);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCustomPriceRulesRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.price.Custom custom = 1; */
        if (message.custom)
            Custom.internalBinaryWrite(message.custom, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 product_count = 10; */
        if (message.productCount !== 0)
            writer.tag(10, WireType.Varint).int32(message.productCount);
        /* rd.customer.Customer customer = 11; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Vendor vendor = 12; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.ProductCategory department = 13; */
        if (message.department)
            ProductCategory.internalBinaryWrite(message.department, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.ProductCategory class = 14; */
        if (message.class)
            ProductCategory.internalBinaryWrite(message.class, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.ProductCategory fineline = 15; */
        if (message.fineline)
            ProductCategory.internalBinaryWrite(message.fineline, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* rd.product.Product product = 16; */
        if (message.product)
            Product.internalBinaryWrite(message.product, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.price.Tier tier_prices = 17; */
        for (let i = 0; i < message.tierPrices.length; i++)
            Tier.internalBinaryWrite(message.tierPrices[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Customer job = 18; */
        if (message.job)
            Customer.internalBinaryWrite(message.job, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCustomPriceRulesRes.Result
 */
export const SearchCustomPriceRulesRes_Result = new SearchCustomPriceRulesRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkExportCustomPriceRulesReq$Type extends MessageType<BulkExportCustomPriceRulesReq> {
    constructor() {
        super("rd.search.service.BulkExportCustomPriceRulesReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 3, name: "department_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 4, name: "vendor_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 5, name: "class_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 6, name: "fineline_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 7, name: "sort_by", kind: "enum", T: () => ["rd.search.service.CustomPriceRuleSortBy", CustomPriceRuleSortBy, "CUSTOM_PRICE_RULE_SORT_BY_"] },
            { no: 8, name: "sort_order", kind: "enum", T: () => ["rd.search.service.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 9, name: "type", kind: "enum", T: () => ["rd.search.service.CustomPriceRuleType", CustomPriceRuleType, "CUSTOM_PRICE_RULE_TYPE_"] },
            { no: 10, name: "is_active_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 11, name: "job_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 12, name: "is_template", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<BulkExportCustomPriceRulesReq>): BulkExportCustomPriceRulesReq {
        const message = { query: "", customerIds: [], departmentIds: [], vendorIds: [], classIds: [], finelineIds: [], sortBy: 0, sortOrder: 0, type: 0, isActiveFilter: 0, jobIds: [], isTemplate: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkExportCustomPriceRulesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkExportCustomPriceRulesReq): BulkExportCustomPriceRulesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* repeated rd.proto.uuid.UUID customer_ids */ 2:
                    message.customerIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID department_ids */ 3:
                    message.departmentIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID vendor_ids */ 4:
                    message.vendorIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID class_ids */ 5:
                    message.classIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID fineline_ids */ 6:
                    message.finelineIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.search.service.CustomPriceRuleSortBy sort_by */ 7:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.search.service.SortOrder sort_order */ 8:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.search.service.CustomPriceRuleType type */ 9:
                    message.type = reader.int32();
                    break;
                case /* rd.search.service.BoolFilter is_active_filter */ 10:
                    message.isActiveFilter = reader.int32();
                    break;
                case /* repeated rd.proto.uuid.UUID job_ids */ 11:
                    message.jobIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool is_template */ 12:
                    message.isTemplate = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkExportCustomPriceRulesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* repeated rd.proto.uuid.UUID customer_ids = 2; */
        for (let i = 0; i < message.customerIds.length; i++)
            UUID.internalBinaryWrite(message.customerIds[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID department_ids = 3; */
        for (let i = 0; i < message.departmentIds.length; i++)
            UUID.internalBinaryWrite(message.departmentIds[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID vendor_ids = 4; */
        for (let i = 0; i < message.vendorIds.length; i++)
            UUID.internalBinaryWrite(message.vendorIds[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID class_ids = 5; */
        for (let i = 0; i < message.classIds.length; i++)
            UUID.internalBinaryWrite(message.classIds[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID fineline_ids = 6; */
        for (let i = 0; i < message.finelineIds.length; i++)
            UUID.internalBinaryWrite(message.finelineIds[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.search.service.CustomPriceRuleSortBy sort_by = 7; */
        if (message.sortBy !== 0)
            writer.tag(7, WireType.Varint).int32(message.sortBy);
        /* rd.search.service.SortOrder sort_order = 8; */
        if (message.sortOrder !== 0)
            writer.tag(8, WireType.Varint).int32(message.sortOrder);
        /* rd.search.service.CustomPriceRuleType type = 9; */
        if (message.type !== 0)
            writer.tag(9, WireType.Varint).int32(message.type);
        /* rd.search.service.BoolFilter is_active_filter = 10; */
        if (message.isActiveFilter !== 0)
            writer.tag(10, WireType.Varint).int32(message.isActiveFilter);
        /* repeated rd.proto.uuid.UUID job_ids = 11; */
        for (let i = 0; i < message.jobIds.length; i++)
            UUID.internalBinaryWrite(message.jobIds[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* bool is_template = 12; */
        if (message.isTemplate !== false)
            writer.tag(12, WireType.Varint).bool(message.isTemplate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.BulkExportCustomPriceRulesReq
 */
export const BulkExportCustomPriceRulesReq = new BulkExportCustomPriceRulesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkExportCustomPriceRulesRes$Type extends MessageType<BulkExportCustomPriceRulesRes> {
    constructor() {
        super("rd.search.service.BulkExportCustomPriceRulesRes", [
            { no: 1, name: "excel_bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<BulkExportCustomPriceRulesRes>): BulkExportCustomPriceRulesRes {
        const message = { excelBytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkExportCustomPriceRulesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkExportCustomPriceRulesRes): BulkExportCustomPriceRulesRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes excel_bytes */ 1:
                    message.excelBytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkExportCustomPriceRulesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes excel_bytes = 1; */
        if (message.excelBytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.excelBytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.BulkExportCustomPriceRulesRes
 */
export const BulkExportCustomPriceRulesRes = new BulkExportCustomPriceRulesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchPriceTierRulesReq$Type extends MessageType<SearchPriceTierRulesReq> {
    constructor() {
        super("rd.search.service.SearchPriceTierRulesReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "pricing_tier_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchPriceTierRulesReq>): SearchPriceTierRulesReq {
        const message = { query: "", cursor: "", offset: 0, limit: 0, pricingTierIdString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchPriceTierRulesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchPriceTierRulesReq): SearchPriceTierRulesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 3:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 4:
                    message.limit = reader.int32();
                    break;
                case /* string pricing_tier_id_string */ 5:
                    message.pricingTierIdString = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchPriceTierRulesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 3; */
        if (message.offset !== 0)
            writer.tag(3, WireType.Varint).int32(message.offset);
        /* int32 limit = 4; */
        if (message.limit !== 0)
            writer.tag(4, WireType.Varint).int32(message.limit);
        /* string pricing_tier_id_string = 5; */
        if (message.pricingTierIdString !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.pricingTierIdString);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchPriceTierRulesReq
 */
export const SearchPriceTierRulesReq = new SearchPriceTierRulesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchPriceTierRulesRes$Type extends MessageType<SearchPriceTierRulesRes> {
    constructor() {
        super("rd.search.service.SearchPriceTierRulesRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchPriceTierRulesRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchPriceTierRulesRes>): SearchPriceTierRulesRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchPriceTierRulesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchPriceTierRulesRes): SearchPriceTierRulesRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchPriceTierRulesRes.Result results */ 1:
                    message.results.push(SearchPriceTierRulesRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchPriceTierRulesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchPriceTierRulesRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchPriceTierRulesRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchPriceTierRulesRes
 */
export const SearchPriceTierRulesRes = new SearchPriceTierRulesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchPriceTierRulesRes_Result$Type extends MessageType<SearchPriceTierRulesRes_Result> {
    constructor() {
        super("rd.search.service.SearchPriceTierRulesRes.Result", [
            { no: 1, name: "tier_rule", kind: "message", T: () => TierRule },
            { no: 2, name: "product_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "vendor", kind: "message", T: () => Vendor },
            { no: 4, name: "department", kind: "message", T: () => ProductCategory },
            { no: 5, name: "class", kind: "message", T: () => ProductCategory },
            { no: 6, name: "fineline", kind: "message", T: () => ProductCategory },
            { no: 7, name: "product", kind: "message", T: () => Product },
            { no: 17, name: "tier_prices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Tier }
        ]);
    }
    create(value?: PartialMessage<SearchPriceTierRulesRes_Result>): SearchPriceTierRulesRes_Result {
        const message = { productCount: 0, tierPrices: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchPriceTierRulesRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchPriceTierRulesRes_Result): SearchPriceTierRulesRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.price.TierRule tier_rule */ 1:
                    message.tierRule = TierRule.internalBinaryRead(reader, reader.uint32(), options, message.tierRule);
                    break;
                case /* int32 product_count */ 2:
                    message.productCount = reader.int32();
                    break;
                case /* rd.vendors.Vendor vendor */ 3:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* rd.company.ProductCategory department */ 4:
                    message.department = ProductCategory.internalBinaryRead(reader, reader.uint32(), options, message.department);
                    break;
                case /* rd.company.ProductCategory class */ 5:
                    message.class = ProductCategory.internalBinaryRead(reader, reader.uint32(), options, message.class);
                    break;
                case /* rd.company.ProductCategory fineline */ 6:
                    message.fineline = ProductCategory.internalBinaryRead(reader, reader.uint32(), options, message.fineline);
                    break;
                case /* rd.product.Product product */ 7:
                    message.product = Product.internalBinaryRead(reader, reader.uint32(), options, message.product);
                    break;
                case /* repeated rd.price.Tier tier_prices */ 17:
                    message.tierPrices.push(Tier.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchPriceTierRulesRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.price.TierRule tier_rule = 1; */
        if (message.tierRule)
            TierRule.internalBinaryWrite(message.tierRule, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 product_count = 2; */
        if (message.productCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.productCount);
        /* rd.vendors.Vendor vendor = 3; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.ProductCategory department = 4; */
        if (message.department)
            ProductCategory.internalBinaryWrite(message.department, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.ProductCategory class = 5; */
        if (message.class)
            ProductCategory.internalBinaryWrite(message.class, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.ProductCategory fineline = 6; */
        if (message.fineline)
            ProductCategory.internalBinaryWrite(message.fineline, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.product.Product product = 7; */
        if (message.product)
            Product.internalBinaryWrite(message.product, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.price.Tier tier_prices = 17; */
        for (let i = 0; i < message.tierPrices.length; i++)
            Tier.internalBinaryWrite(message.tierPrices[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchPriceTierRulesRes.Result
 */
export const SearchPriceTierRulesRes_Result = new SearchPriceTierRulesRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchJobsReq$Type extends MessageType<SearchJobsReq> {
    constructor() {
        super("rd.search.service.SearchJobsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "customer_id", kind: "message", T: () => UUID },
            { no: 6, name: "order_by", kind: "enum", T: () => ["rd.search.service.OrderJobsBy", OrderJobsBy, "ORDER_JOBS_BY_"] },
            { no: 7, name: "sort_order", kind: "enum", T: () => ["rd.search.service.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 8, name: "is_active_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] }
        ]);
    }
    create(value?: PartialMessage<SearchJobsReq>): SearchJobsReq {
        const message = { query: "", cursor: "", offset: 0, limit: 0, orderBy: 0, sortOrder: 0, isActiveFilter: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchJobsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchJobsReq): SearchJobsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 3:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 4:
                    message.limit = reader.int32();
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 5:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.search.service.OrderJobsBy order_by */ 6:
                    message.orderBy = reader.int32();
                    break;
                case /* rd.search.service.SortOrder sort_order */ 7:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.search.service.BoolFilter is_active_filter */ 8:
                    message.isActiveFilter = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchJobsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 3; */
        if (message.offset !== 0)
            writer.tag(3, WireType.Varint).int32(message.offset);
        /* int32 limit = 4; */
        if (message.limit !== 0)
            writer.tag(4, WireType.Varint).int32(message.limit);
        /* rd.proto.uuid.UUID customer_id = 5; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.search.service.OrderJobsBy order_by = 6; */
        if (message.orderBy !== 0)
            writer.tag(6, WireType.Varint).int32(message.orderBy);
        /* rd.search.service.SortOrder sort_order = 7; */
        if (message.sortOrder !== 0)
            writer.tag(7, WireType.Varint).int32(message.sortOrder);
        /* rd.search.service.BoolFilter is_active_filter = 8; */
        if (message.isActiveFilter !== 0)
            writer.tag(8, WireType.Varint).int32(message.isActiveFilter);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchJobsReq
 */
export const SearchJobsReq = new SearchJobsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchJobsRes$Type extends MessageType<SearchJobsRes> {
    constructor() {
        super("rd.search.service.SearchJobsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchJobsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchJobsRes>): SearchJobsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchJobsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchJobsRes): SearchJobsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchJobsRes.Result results */ 1:
                    message.results.push(SearchJobsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchJobsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchJobsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchJobsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchJobsRes
 */
export const SearchJobsRes = new SearchJobsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchJobsRes_Result$Type extends MessageType<SearchJobsRes_Result> {
    constructor() {
        super("rd.search.service.SearchJobsRes.Result", [
            { no: 1, name: "job", kind: "message", T: () => Customer },
            { no: 7, name: "total_balance", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<SearchJobsRes_Result>): SearchJobsRes_Result {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchJobsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchJobsRes_Result): SearchJobsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.customer.Customer job */ 1:
                    message.job = Customer.internalBinaryRead(reader, reader.uint32(), options, message.job);
                    break;
                case /* rd.proto.money.Money total_balance */ 7:
                    message.totalBalance = Money.internalBinaryRead(reader, reader.uint32(), options, message.totalBalance);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchJobsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.customer.Customer job = 1; */
        if (message.job)
            Customer.internalBinaryWrite(message.job, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total_balance = 7; */
        if (message.totalBalance)
            Money.internalBinaryWrite(message.totalBalance, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchJobsRes.Result
 */
export const SearchJobsRes_Result = new SearchJobsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchTagsReq$Type extends MessageType<SearchTagsReq> {
    constructor() {
        super("rd.search.service.SearchTagsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "order_by", kind: "enum", T: () => ["rd.search.service.SearchTagsReq.OrderBy", SearchTagsReq_OrderBy, "ORDER_BY_"] },
            { no: 6, name: "sort_order", kind: "enum", T: () => ["rd.search.service.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 7, name: "types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.tag.Tag.Type", Tag_Type, "TYPE_"] },
            { no: 8, name: "colors", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.tag.Tag.Color", Tag_Color, "COLOR_"] }
        ]);
    }
    create(value?: PartialMessage<SearchTagsReq>): SearchTagsReq {
        const message = { query: "", cursor: "", offset: 0, limit: 0, orderBy: 0, sortOrder: 0, types: [], colors: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchTagsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchTagsReq): SearchTagsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 3:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 4:
                    message.limit = reader.int32();
                    break;
                case /* rd.search.service.SearchTagsReq.OrderBy order_by */ 5:
                    message.orderBy = reader.int32();
                    break;
                case /* rd.search.service.SortOrder sort_order */ 6:
                    message.sortOrder = reader.int32();
                    break;
                case /* repeated rd.tag.Tag.Type types */ 7:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.types.push(reader.int32());
                    else
                        message.types.push(reader.int32());
                    break;
                case /* repeated rd.tag.Tag.Color colors */ 8:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.colors.push(reader.int32());
                    else
                        message.colors.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchTagsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 3; */
        if (message.offset !== 0)
            writer.tag(3, WireType.Varint).int32(message.offset);
        /* int32 limit = 4; */
        if (message.limit !== 0)
            writer.tag(4, WireType.Varint).int32(message.limit);
        /* rd.search.service.SearchTagsReq.OrderBy order_by = 5; */
        if (message.orderBy !== 0)
            writer.tag(5, WireType.Varint).int32(message.orderBy);
        /* rd.search.service.SortOrder sort_order = 6; */
        if (message.sortOrder !== 0)
            writer.tag(6, WireType.Varint).int32(message.sortOrder);
        /* repeated rd.tag.Tag.Type types = 7; */
        if (message.types.length) {
            writer.tag(7, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.types.length; i++)
                writer.int32(message.types[i]);
            writer.join();
        }
        /* repeated rd.tag.Tag.Color colors = 8; */
        if (message.colors.length) {
            writer.tag(8, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.colors.length; i++)
                writer.int32(message.colors[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchTagsReq
 */
export const SearchTagsReq = new SearchTagsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchTagsRes$Type extends MessageType<SearchTagsRes> {
    constructor() {
        super("rd.search.service.SearchTagsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchTagsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchTagsRes>): SearchTagsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchTagsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchTagsRes): SearchTagsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchTagsRes.Result results */ 1:
                    message.results.push(SearchTagsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchTagsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchTagsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchTagsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchTagsRes
 */
export const SearchTagsRes = new SearchTagsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchTagsRes_Result$Type extends MessageType<SearchTagsRes_Result> {
    constructor() {
        super("rd.search.service.SearchTagsRes.Result", [
            { no: 1, name: "tag", kind: "message", T: () => Tag },
            { no: 2, name: "tagged_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchTagsRes_Result>): SearchTagsRes_Result {
        const message = { taggedCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchTagsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchTagsRes_Result): SearchTagsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.tag.Tag tag */ 1:
                    message.tag = Tag.internalBinaryRead(reader, reader.uint32(), options, message.tag);
                    break;
                case /* int32 tagged_count */ 2:
                    message.taggedCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchTagsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.tag.Tag tag = 1; */
        if (message.tag)
            Tag.internalBinaryWrite(message.tag, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 tagged_count = 2; */
        if (message.taggedCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.taggedCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchTagsRes.Result
 */
export const SearchTagsRes_Result = new SearchTagsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchSalesReq$Type extends MessageType<CxaSearchSalesReq> {
    constructor() {
        super("rd.search.service.CxaSearchSalesReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "message", T: () => UUID },
            { no: 3, name: "location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 4, name: "statuses", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.txn.Sale.Status", Sale_Status, "STATUS_"] },
            { no: 5, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CxaSearchSalesReq>): CxaSearchSalesReq {
        const message = { query: "", locationIds: [], statuses: [], cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchSalesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchSalesReq): CxaSearchSalesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 2:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* repeated rd.proto.uuid.UUID location_ids */ 3:
                    message.locationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.txn.Sale.Status statuses */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.statuses.push(reader.int32());
                    else
                        message.statuses.push(reader.int32());
                    break;
                case /* string cursor */ 5:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 6:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 7:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CxaSearchSalesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.uuid.UUID customer_id = 2; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID location_ids = 3; */
        for (let i = 0; i < message.locationIds.length; i++)
            UUID.internalBinaryWrite(message.locationIds[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.Sale.Status statuses = 4; */
        if (message.statuses.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.statuses.length; i++)
                writer.int32(message.statuses[i]);
            writer.join();
        }
        /* string cursor = 5; */
        if (message.cursor !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 6; */
        if (message.offset !== 0)
            writer.tag(6, WireType.Varint).int32(message.offset);
        /* int32 limit = 7; */
        if (message.limit !== 0)
            writer.tag(7, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchSalesReq
 */
export const CxaSearchSalesReq = new CxaSearchSalesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchSalesRes$Type extends MessageType<CxaSearchSalesRes> {
    constructor() {
        super("rd.search.service.CxaSearchSalesRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CxaSearchSalesRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CxaSearchSalesRes>): CxaSearchSalesRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchSalesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchSalesRes): CxaSearchSalesRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.CxaSearchSalesRes.Result results */ 1:
                    message.results.push(CxaSearchSalesRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CxaSearchSalesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.CxaSearchSalesRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            CxaSearchSalesRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchSalesRes
 */
export const CxaSearchSalesRes = new CxaSearchSalesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchSalesRes_Result$Type extends MessageType<CxaSearchSalesRes_Result> {
    constructor() {
        super("rd.search.service.CxaSearchSalesRes.Result", [
            { no: 1, name: "sale", kind: "message", T: () => Sale },
            { no: 2, name: "location", kind: "message", T: () => Location },
            { no: 3, name: "purchase_order", kind: "message", T: () => PurchaseOrder },
            { no: 5, name: "products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 6, name: "matched_products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 7, name: "tint_colors_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => TintColor } },
            { no: 8, name: "total", kind: "message", T: () => Money },
            { no: 9, name: "purchase_methods", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PurchaseMethod },
            { no: 10, name: "customer_contact_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "customer_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CxaSearchSalesRes_Result>): CxaSearchSalesRes_Result {
        const message = { productsById: {}, matchedProductsById: {}, tintColorsById: {}, purchaseMethods: [], customerContactName: "", customerIdentifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchSalesRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchSalesRes_Result): CxaSearchSalesRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.Sale sale */ 1:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* rd.company.Location location */ 2:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.customer.PurchaseOrder purchase_order */ 3:
                    message.purchaseOrder = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options, message.purchaseOrder);
                    break;
                case /* map<string, rd.product.Product> products_by_id */ 5:
                    this.binaryReadMap5(message.productsById, reader, options);
                    break;
                case /* map<string, rd.product.Product> matched_products_by_id */ 6:
                    this.binaryReadMap6(message.matchedProductsById, reader, options);
                    break;
                case /* map<string, rd.tintcolor.TintColor> tint_colors_by_id */ 7:
                    this.binaryReadMap7(message.tintColorsById, reader, options);
                    break;
                case /* rd.proto.money.Money total */ 8:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* repeated rd.txn.PurchaseMethod purchase_methods */ 9:
                    message.purchaseMethods.push(PurchaseMethod.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string customer_contact_name */ 10:
                    message.customerContactName = reader.string();
                    break;
                case /* string customer_identifier */ 11:
                    message.customerIdentifier = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap5(map: CxaSearchSalesRes_Result["productsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CxaSearchSalesRes_Result["productsById"] | undefined, val: CxaSearchSalesRes_Result["productsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.CxaSearchSalesRes.Result.products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    private binaryReadMap6(map: CxaSearchSalesRes_Result["matchedProductsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CxaSearchSalesRes_Result["matchedProductsById"] | undefined, val: CxaSearchSalesRes_Result["matchedProductsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.CxaSearchSalesRes.Result.matched_products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    private binaryReadMap7(map: CxaSearchSalesRes_Result["tintColorsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CxaSearchSalesRes_Result["tintColorsById"] | undefined, val: CxaSearchSalesRes_Result["tintColorsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = TintColor.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.CxaSearchSalesRes.Result.tint_colors_by_id");
            }
        }
        map[key ?? ""] = val ?? TintColor.create();
    }
    internalBinaryWrite(message: CxaSearchSalesRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.Sale sale = 1; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 2; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.PurchaseOrder purchase_order = 3; */
        if (message.purchaseOrder)
            PurchaseOrder.internalBinaryWrite(message.purchaseOrder, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.product.Product> products_by_id = 5; */
        for (let k of Object.keys(message.productsById)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.productsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.product.Product> matched_products_by_id = 6; */
        for (let k of Object.keys(message.matchedProductsById)) {
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.matchedProductsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.tintcolor.TintColor> tint_colors_by_id = 7; */
        for (let k of Object.keys(message.tintColorsById)) {
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            TintColor.internalBinaryWrite(message.tintColorsById[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money total = 8; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.PurchaseMethod purchase_methods = 9; */
        for (let i = 0; i < message.purchaseMethods.length; i++)
            PurchaseMethod.internalBinaryWrite(message.purchaseMethods[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* string customer_contact_name = 10; */
        if (message.customerContactName !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.customerContactName);
        /* string customer_identifier = 11; */
        if (message.customerIdentifier !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.customerIdentifier);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchSalesRes.Result
 */
export const CxaSearchSalesRes_Result = new CxaSearchSalesRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchOutstandingSalesReq$Type extends MessageType<CxaSearchOutstandingSalesReq> {
    constructor() {
        super("rd.search.service.CxaSearchOutstandingSalesReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CxaSearchOutstandingSalesReq>): CxaSearchOutstandingSalesReq {
        const message = { query: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchOutstandingSalesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchOutstandingSalesReq): CxaSearchOutstandingSalesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CxaSearchOutstandingSalesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchOutstandingSalesReq
 */
export const CxaSearchOutstandingSalesReq = new CxaSearchOutstandingSalesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchOutstandingSalesRes$Type extends MessageType<CxaSearchOutstandingSalesRes> {
    constructor() {
        super("rd.search.service.CxaSearchOutstandingSalesRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CxaSearchOutstandingSalesRes_Result },
            { no: 2, name: "customer_balance", kind: "message", T: () => CustomerBalance }
        ]);
    }
    create(value?: PartialMessage<CxaSearchOutstandingSalesRes>): CxaSearchOutstandingSalesRes {
        const message = { results: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchOutstandingSalesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchOutstandingSalesRes): CxaSearchOutstandingSalesRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.CxaSearchOutstandingSalesRes.Result results */ 1:
                    message.results.push(CxaSearchOutstandingSalesRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.txn.CustomerBalance customer_balance */ 2:
                    message.customerBalance = CustomerBalance.internalBinaryRead(reader, reader.uint32(), options, message.customerBalance);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CxaSearchOutstandingSalesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.CxaSearchOutstandingSalesRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            CxaSearchOutstandingSalesRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.CustomerBalance customer_balance = 2; */
        if (message.customerBalance)
            CustomerBalance.internalBinaryWrite(message.customerBalance, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchOutstandingSalesRes
 */
export const CxaSearchOutstandingSalesRes = new CxaSearchOutstandingSalesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchOutstandingSalesRes_Result$Type extends MessageType<CxaSearchOutstandingSalesRes_Result> {
    constructor() {
        super("rd.search.service.CxaSearchOutstandingSalesRes.Result", [
            { no: 1, name: "sale", kind: "message", T: () => Sale },
            { no: 2, name: "sale_balance", kind: "message", T: () => Money },
            { no: 3, name: "location", kind: "message", T: () => Location },
            { no: 4, name: "purchase_order", kind: "message", T: () => PurchaseOrder },
            { no: 6, name: "customer_contact_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "potential_discount_amount", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<CxaSearchOutstandingSalesRes_Result>): CxaSearchOutstandingSalesRes_Result {
        const message = { customerContactName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchOutstandingSalesRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchOutstandingSalesRes_Result): CxaSearchOutstandingSalesRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.Sale sale */ 1:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* rd.proto.money.Money sale_balance */ 2:
                    message.saleBalance = Money.internalBinaryRead(reader, reader.uint32(), options, message.saleBalance);
                    break;
                case /* rd.company.Location location */ 3:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.customer.PurchaseOrder purchase_order */ 4:
                    message.purchaseOrder = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options, message.purchaseOrder);
                    break;
                case /* string customer_contact_name */ 6:
                    message.customerContactName = reader.string();
                    break;
                case /* rd.proto.money.Money potential_discount_amount */ 7:
                    message.potentialDiscountAmount = Money.internalBinaryRead(reader, reader.uint32(), options, message.potentialDiscountAmount);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CxaSearchOutstandingSalesRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.Sale sale = 1; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money sale_balance = 2; */
        if (message.saleBalance)
            Money.internalBinaryWrite(message.saleBalance, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 3; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.PurchaseOrder purchase_order = 4; */
        if (message.purchaseOrder)
            PurchaseOrder.internalBinaryWrite(message.purchaseOrder, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string customer_contact_name = 6; */
        if (message.customerContactName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.customerContactName);
        /* rd.proto.money.Money potential_discount_amount = 7; */
        if (message.potentialDiscountAmount)
            Money.internalBinaryWrite(message.potentialDiscountAmount, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchOutstandingSalesRes.Result
 */
export const CxaSearchOutstandingSalesRes_Result = new CxaSearchOutstandingSalesRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchReturnsReq$Type extends MessageType<CxaSearchReturnsReq> {
    constructor() {
        super("rd.search.service.CxaSearchReturnsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CxaSearchReturnsReq>): CxaSearchReturnsReq {
        const message = { query: "", cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchReturnsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchReturnsReq): CxaSearchReturnsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 3:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 4:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CxaSearchReturnsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 3; */
        if (message.offset !== 0)
            writer.tag(3, WireType.Varint).int32(message.offset);
        /* int32 limit = 4; */
        if (message.limit !== 0)
            writer.tag(4, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchReturnsReq
 */
export const CxaSearchReturnsReq = new CxaSearchReturnsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchReturnsRes$Type extends MessageType<CxaSearchReturnsRes> {
    constructor() {
        super("rd.search.service.CxaSearchReturnsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CxaSearchReturnsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CxaSearchReturnsRes>): CxaSearchReturnsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchReturnsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchReturnsRes): CxaSearchReturnsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.CxaSearchReturnsRes.Result results */ 1:
                    message.results.push(CxaSearchReturnsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CxaSearchReturnsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.CxaSearchReturnsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            CxaSearchReturnsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchReturnsRes
 */
export const CxaSearchReturnsRes = new CxaSearchReturnsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchReturnsRes_Result$Type extends MessageType<CxaSearchReturnsRes_Result> {
    constructor() {
        super("rd.search.service.CxaSearchReturnsRes.Result", [
            { no: 1, name: "sale", kind: "message", T: () => Sale },
            { no: 2, name: "sale_return", kind: "message", T: () => Sale_Return },
            { no: 3, name: "return_ledger_entry", kind: "message", T: () => LedgerEntry },
            { no: 4, name: "customer", kind: "message", T: () => Customer },
            { no: 5, name: "location", kind: "message", T: () => Location },
            { no: 7, name: "purchase_order", kind: "message", T: () => PurchaseOrder },
            { no: 8, name: "customer_contact", kind: "message", T: () => Contacts_Contact },
            { no: 9, name: "return_method", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CxaSearchReturnsRes_Result>): CxaSearchReturnsRes_Result {
        const message = { returnMethod: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchReturnsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchReturnsRes_Result): CxaSearchReturnsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.Sale sale */ 1:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* rd.txn.Sale.Return sale_return */ 2:
                    message.saleReturn = Sale_Return.internalBinaryRead(reader, reader.uint32(), options, message.saleReturn);
                    break;
                case /* rd.ledger.LedgerEntry return_ledger_entry */ 3:
                    message.returnLedgerEntry = LedgerEntry.internalBinaryRead(reader, reader.uint32(), options, message.returnLedgerEntry);
                    break;
                case /* rd.customer.Customer customer */ 4:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.company.Location location */ 5:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.customer.PurchaseOrder purchase_order */ 7:
                    message.purchaseOrder = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options, message.purchaseOrder);
                    break;
                case /* rd.customer.Contacts.Contact customer_contact */ 8:
                    message.customerContact = Contacts_Contact.internalBinaryRead(reader, reader.uint32(), options, message.customerContact);
                    break;
                case /* string return_method */ 9:
                    message.returnMethod = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CxaSearchReturnsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.Sale sale = 1; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.Sale.Return sale_return = 2; */
        if (message.saleReturn)
            Sale_Return.internalBinaryWrite(message.saleReturn, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry return_ledger_entry = 3; */
        if (message.returnLedgerEntry)
            LedgerEntry.internalBinaryWrite(message.returnLedgerEntry, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Customer customer = 4; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 5; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.PurchaseOrder purchase_order = 7; */
        if (message.purchaseOrder)
            PurchaseOrder.internalBinaryWrite(message.purchaseOrder, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Contacts.Contact customer_contact = 8; */
        if (message.customerContact)
            Contacts_Contact.internalBinaryWrite(message.customerContact, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* string return_method = 9; */
        if (message.returnMethod !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.returnMethod);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchReturnsRes.Result
 */
export const CxaSearchReturnsRes_Result = new CxaSearchReturnsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchJobsReq$Type extends MessageType<CxaSearchJobsReq> {
    constructor() {
        super("rd.search.service.CxaSearchJobsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CxaSearchJobsReq>): CxaSearchJobsReq {
        const message = { query: "", cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchJobsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchJobsReq): CxaSearchJobsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 3:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 4:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CxaSearchJobsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 3; */
        if (message.offset !== 0)
            writer.tag(3, WireType.Varint).int32(message.offset);
        /* int32 limit = 4; */
        if (message.limit !== 0)
            writer.tag(4, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchJobsReq
 */
export const CxaSearchJobsReq = new CxaSearchJobsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchJobsRes$Type extends MessageType<CxaSearchJobsRes> {
    constructor() {
        super("rd.search.service.CxaSearchJobsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CxaSearchJobsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CxaSearchJobsRes>): CxaSearchJobsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchJobsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchJobsRes): CxaSearchJobsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.CxaSearchJobsRes.Result results */ 1:
                    message.results.push(CxaSearchJobsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CxaSearchJobsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.CxaSearchJobsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            CxaSearchJobsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchJobsRes
 */
export const CxaSearchJobsRes = new CxaSearchJobsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchJobsRes_Result$Type extends MessageType<CxaSearchJobsRes_Result> {
    constructor() {
        super("rd.search.service.CxaSearchJobsRes.Result", [
            { no: 1, name: "job", kind: "message", T: () => Customer },
            { no: 2, name: "total_balance", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<CxaSearchJobsRes_Result>): CxaSearchJobsRes_Result {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchJobsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchJobsRes_Result): CxaSearchJobsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.customer.Customer job */ 1:
                    message.job = Customer.internalBinaryRead(reader, reader.uint32(), options, message.job);
                    break;
                case /* rd.proto.money.Money total_balance */ 2:
                    message.totalBalance = Money.internalBinaryRead(reader, reader.uint32(), options, message.totalBalance);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CxaSearchJobsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.customer.Customer job = 1; */
        if (message.job)
            Customer.internalBinaryWrite(message.job, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total_balance = 2; */
        if (message.totalBalance)
            Money.internalBinaryWrite(message.totalBalance, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchJobsRes.Result
 */
export const CxaSearchJobsRes_Result = new CxaSearchJobsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCashRegisterReq$Type extends MessageType<GetCashRegisterReq> {
    constructor() {
        super("rd.search.service.GetCashRegisterReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.CashRegisterSortBy", CashRegisterSortBy, "CASH_REGISTER_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 4, name: "transaction_types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.CashRegisterTransactionType", CashRegisterTransactionType, "CASH_REGISTER_TRANSACTION_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<GetCashRegisterReq>): GetCashRegisterReq {
        const message = { sortBy: 0, sortOrder: 0, transactionTypes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCashRegisterReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCashRegisterReq): GetCashRegisterReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.CashRegisterSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* repeated rd.analyticsv2.CashRegisterTransactionType transaction_types */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.transactionTypes.push(reader.int32());
                    else
                        message.transactionTypes.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCashRegisterReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.CashRegisterSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.analyticsv2.CashRegisterTransactionType transaction_types = 4; */
        if (message.transactionTypes.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.transactionTypes.length; i++)
                writer.int32(message.transactionTypes[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetCashRegisterReq
 */
export const GetCashRegisterReq = new GetCashRegisterReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCashRegisterRes$Type extends MessageType<GetCashRegisterRes> {
    constructor() {
        super("rd.search.service.GetCashRegisterRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetCashRegisterRes>): GetCashRegisterRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCashRegisterRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCashRegisterRes): GetCashRegisterRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCashRegisterRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetCashRegisterRes
 */
export const GetCashRegisterRes = new GetCashRegisterRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCashRegisterPDFReq$Type extends MessageType<GetCashRegisterPDFReq> {
    constructor() {
        super("rd.search.service.GetCashRegisterPDFReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.CashRegisterSortBy", CashRegisterSortBy, "CASH_REGISTER_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 4, name: "transaction_types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.CashRegisterTransactionType", CashRegisterTransactionType, "CASH_REGISTER_TRANSACTION_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<GetCashRegisterPDFReq>): GetCashRegisterPDFReq {
        const message = { sortBy: 0, sortOrder: 0, transactionTypes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCashRegisterPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCashRegisterPDFReq): GetCashRegisterPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.CashRegisterSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* repeated rd.analyticsv2.CashRegisterTransactionType transaction_types */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.transactionTypes.push(reader.int32());
                    else
                        message.transactionTypes.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCashRegisterPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.CashRegisterSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.analyticsv2.CashRegisterTransactionType transaction_types = 4; */
        if (message.transactionTypes.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.transactionTypes.length; i++)
                writer.int32(message.transactionTypes[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetCashRegisterPDFReq
 */
export const GetCashRegisterPDFReq = new GetCashRegisterPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCashRegisterPDFRes$Type extends MessageType<GetCashRegisterPDFRes> {
    constructor() {
        super("rd.search.service.GetCashRegisterPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetCashRegisterPDFRes>): GetCashRegisterPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCashRegisterPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCashRegisterPDFRes): GetCashRegisterPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCashRegisterPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetCashRegisterPDFRes
 */
export const GetCashRegisterPDFRes = new GetCashRegisterPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCashRegisterCSVReq$Type extends MessageType<GetCashRegisterCSVReq> {
    constructor() {
        super("rd.search.service.GetCashRegisterCSVReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.CashRegisterSortBy", CashRegisterSortBy, "CASH_REGISTER_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 4, name: "transaction_types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.CashRegisterTransactionType", CashRegisterTransactionType, "CASH_REGISTER_TRANSACTION_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<GetCashRegisterCSVReq>): GetCashRegisterCSVReq {
        const message = { sortBy: 0, sortOrder: 0, transactionTypes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCashRegisterCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCashRegisterCSVReq): GetCashRegisterCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.CashRegisterSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* repeated rd.analyticsv2.CashRegisterTransactionType transaction_types */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.transactionTypes.push(reader.int32());
                    else
                        message.transactionTypes.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCashRegisterCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.CashRegisterSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.analyticsv2.CashRegisterTransactionType transaction_types = 4; */
        if (message.transactionTypes.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.transactionTypes.length; i++)
                writer.int32(message.transactionTypes[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetCashRegisterCSVReq
 */
export const GetCashRegisterCSVReq = new GetCashRegisterCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCashRegisterCSVRes$Type extends MessageType<GetCashRegisterCSVRes> {
    constructor() {
        super("rd.search.service.GetCashRegisterCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetCashRegisterCSVRes>): GetCashRegisterCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCashRegisterCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCashRegisterCSVRes): GetCashRegisterCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCashRegisterCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetCashRegisterCSVRes
 */
export const GetCashRegisterCSVRes = new GetCashRegisterCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesReq$Type extends MessageType<GetSalesReq> {
    constructor() {
        super("rd.search.service.GetSalesReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesSortBy", SalesSortBy, "SALES_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 4, name: "transaction_types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.SalesTransactionType", SalesTransactionType, "SALES_TRANSACTION_TYPE_"] },
            { no: 5, name: "purchase_methods", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.SalesPurchaseMethod", SalesPurchaseMethod, "SALES_PURCHASE_METHOD_"] }
        ]);
    }
    create(value?: PartialMessage<GetSalesReq>): GetSalesReq {
        const message = { sortBy: 0, sortOrder: 0, transactionTypes: [], purchaseMethods: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesReq): GetSalesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.SalesSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                case /* repeated rd.analyticsv2.SalesTransactionType transaction_types */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.transactionTypes.push(reader.int32());
                    else
                        message.transactionTypes.push(reader.int32());
                    break;
                case /* repeated rd.analyticsv2.SalesPurchaseMethod purchase_methods */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.purchaseMethods.push(reader.int32());
                    else
                        message.purchaseMethods.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.SalesSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        /* repeated rd.analyticsv2.SalesTransactionType transaction_types = 4; */
        if (message.transactionTypes.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.transactionTypes.length; i++)
                writer.int32(message.transactionTypes[i]);
            writer.join();
        }
        /* repeated rd.analyticsv2.SalesPurchaseMethod purchase_methods = 5; */
        if (message.purchaseMethods.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.purchaseMethods.length; i++)
                writer.int32(message.purchaseMethods[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesReq
 */
export const GetSalesReq = new GetSalesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesRes$Type extends MessageType<GetSalesRes> {
    constructor() {
        super("rd.search.service.GetSalesRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetSalesRes>): GetSalesRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesRes): GetSalesRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesRes
 */
export const GetSalesRes = new GetSalesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesPDFReq$Type extends MessageType<GetSalesPDFReq> {
    constructor() {
        super("rd.search.service.GetSalesPDFReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesSortBy", SalesSortBy, "SALES_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 4, name: "transaction_types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.SalesTransactionType", SalesTransactionType, "SALES_TRANSACTION_TYPE_"] },
            { no: 5, name: "purchase_methods", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.SalesPurchaseMethod", SalesPurchaseMethod, "SALES_PURCHASE_METHOD_"] }
        ]);
    }
    create(value?: PartialMessage<GetSalesPDFReq>): GetSalesPDFReq {
        const message = { sortBy: 0, sortOrder: 0, transactionTypes: [], purchaseMethods: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesPDFReq): GetSalesPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.SalesSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                case /* repeated rd.analyticsv2.SalesTransactionType transaction_types */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.transactionTypes.push(reader.int32());
                    else
                        message.transactionTypes.push(reader.int32());
                    break;
                case /* repeated rd.analyticsv2.SalesPurchaseMethod purchase_methods */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.purchaseMethods.push(reader.int32());
                    else
                        message.purchaseMethods.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.SalesSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        /* repeated rd.analyticsv2.SalesTransactionType transaction_types = 4; */
        if (message.transactionTypes.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.transactionTypes.length; i++)
                writer.int32(message.transactionTypes[i]);
            writer.join();
        }
        /* repeated rd.analyticsv2.SalesPurchaseMethod purchase_methods = 5; */
        if (message.purchaseMethods.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.purchaseMethods.length; i++)
                writer.int32(message.purchaseMethods[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesPDFReq
 */
export const GetSalesPDFReq = new GetSalesPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesPDFRes$Type extends MessageType<GetSalesPDFRes> {
    constructor() {
        super("rd.search.service.GetSalesPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSalesPDFRes>): GetSalesPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesPDFRes): GetSalesPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesPDFRes
 */
export const GetSalesPDFRes = new GetSalesPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesCSVReq$Type extends MessageType<GetSalesCSVReq> {
    constructor() {
        super("rd.search.service.GetSalesCSVReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesSortBy", SalesSortBy, "SALES_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 4, name: "transaction_types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.SalesTransactionType", SalesTransactionType, "SALES_TRANSACTION_TYPE_"] },
            { no: 5, name: "purchase_methods", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.SalesPurchaseMethod", SalesPurchaseMethod, "SALES_PURCHASE_METHOD_"] }
        ]);
    }
    create(value?: PartialMessage<GetSalesCSVReq>): GetSalesCSVReq {
        const message = { sortBy: 0, sortOrder: 0, transactionTypes: [], purchaseMethods: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesCSVReq): GetSalesCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.SalesSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                case /* repeated rd.analyticsv2.SalesTransactionType transaction_types */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.transactionTypes.push(reader.int32());
                    else
                        message.transactionTypes.push(reader.int32());
                    break;
                case /* repeated rd.analyticsv2.SalesPurchaseMethod purchase_methods */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.purchaseMethods.push(reader.int32());
                    else
                        message.purchaseMethods.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.SalesSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        /* repeated rd.analyticsv2.SalesTransactionType transaction_types = 4; */
        if (message.transactionTypes.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.transactionTypes.length; i++)
                writer.int32(message.transactionTypes[i]);
            writer.join();
        }
        /* repeated rd.analyticsv2.SalesPurchaseMethod purchase_methods = 5; */
        if (message.purchaseMethods.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.purchaseMethods.length; i++)
                writer.int32(message.purchaseMethods[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesCSVReq
 */
export const GetSalesCSVReq = new GetSalesCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesCSVRes$Type extends MessageType<GetSalesCSVRes> {
    constructor() {
        super("rd.search.service.GetSalesCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSalesCSVRes>): GetSalesCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesCSVRes): GetSalesCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesCSVRes
 */
export const GetSalesCSVRes = new GetSalesCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPaymentsReq$Type extends MessageType<GetPaymentsReq> {
    constructor() {
        super("rd.search.service.GetPaymentsReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.PaymentsByDaySortBy", PaymentsByDaySortBy, "PAYMENTS_BY_DAY_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 4, name: "method_of_tenders", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.PaymentsByDayMethod", PaymentsByDayMethod, "PAYMENTS_BY_DAY_METHOD_"] },
            { no: 5, name: "transaction_types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.PaymentsByDayTransactionType", PaymentsByDayTransactionType] }
        ]);
    }
    create(value?: PartialMessage<GetPaymentsReq>): GetPaymentsReq {
        const message = { sortBy: 0, sortOrder: 0, methodOfTenders: [], transactionTypes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPaymentsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPaymentsReq): GetPaymentsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.PaymentsByDaySortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                case /* repeated rd.analyticsv2.PaymentsByDayMethod method_of_tenders */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.methodOfTenders.push(reader.int32());
                    else
                        message.methodOfTenders.push(reader.int32());
                    break;
                case /* repeated rd.analyticsv2.PaymentsByDayTransactionType transaction_types */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.transactionTypes.push(reader.int32());
                    else
                        message.transactionTypes.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPaymentsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.PaymentsByDaySortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        /* repeated rd.analyticsv2.PaymentsByDayMethod method_of_tenders = 4; */
        if (message.methodOfTenders.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.methodOfTenders.length; i++)
                writer.int32(message.methodOfTenders[i]);
            writer.join();
        }
        /* repeated rd.analyticsv2.PaymentsByDayTransactionType transaction_types = 5; */
        if (message.transactionTypes.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.transactionTypes.length; i++)
                writer.int32(message.transactionTypes[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetPaymentsReq
 */
export const GetPaymentsReq = new GetPaymentsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPaymentsRes$Type extends MessageType<GetPaymentsRes> {
    constructor() {
        super("rd.search.service.GetPaymentsRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetPaymentsRes>): GetPaymentsRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPaymentsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPaymentsRes): GetPaymentsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPaymentsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetPaymentsRes
 */
export const GetPaymentsRes = new GetPaymentsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPaymentsPDFReq$Type extends MessageType<GetPaymentsPDFReq> {
    constructor() {
        super("rd.search.service.GetPaymentsPDFReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.PaymentsByDaySortBy", PaymentsByDaySortBy, "PAYMENTS_BY_DAY_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 4, name: "method_of_tenders", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.PaymentsByDayMethod", PaymentsByDayMethod, "PAYMENTS_BY_DAY_METHOD_"] },
            { no: 5, name: "transaction_types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.PaymentsByDayTransactionType", PaymentsByDayTransactionType] }
        ]);
    }
    create(value?: PartialMessage<GetPaymentsPDFReq>): GetPaymentsPDFReq {
        const message = { sortBy: 0, sortOrder: 0, methodOfTenders: [], transactionTypes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPaymentsPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPaymentsPDFReq): GetPaymentsPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.PaymentsByDaySortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                case /* repeated rd.analyticsv2.PaymentsByDayMethod method_of_tenders */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.methodOfTenders.push(reader.int32());
                    else
                        message.methodOfTenders.push(reader.int32());
                    break;
                case /* repeated rd.analyticsv2.PaymentsByDayTransactionType transaction_types */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.transactionTypes.push(reader.int32());
                    else
                        message.transactionTypes.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPaymentsPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.PaymentsByDaySortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        /* repeated rd.analyticsv2.PaymentsByDayMethod method_of_tenders = 4; */
        if (message.methodOfTenders.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.methodOfTenders.length; i++)
                writer.int32(message.methodOfTenders[i]);
            writer.join();
        }
        /* repeated rd.analyticsv2.PaymentsByDayTransactionType transaction_types = 5; */
        if (message.transactionTypes.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.transactionTypes.length; i++)
                writer.int32(message.transactionTypes[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetPaymentsPDFReq
 */
export const GetPaymentsPDFReq = new GetPaymentsPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPaymentsPDFRes$Type extends MessageType<GetPaymentsPDFRes> {
    constructor() {
        super("rd.search.service.GetPaymentsPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetPaymentsPDFRes>): GetPaymentsPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPaymentsPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPaymentsPDFRes): GetPaymentsPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPaymentsPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetPaymentsPDFRes
 */
export const GetPaymentsPDFRes = new GetPaymentsPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPaymentsCSVReq$Type extends MessageType<GetPaymentsCSVReq> {
    constructor() {
        super("rd.search.service.GetPaymentsCSVReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.PaymentsByDaySortBy", PaymentsByDaySortBy, "PAYMENTS_BY_DAY_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 4, name: "method_of_tenders", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.PaymentsByDayMethod", PaymentsByDayMethod, "PAYMENTS_BY_DAY_METHOD_"] },
            { no: 5, name: "transaction_types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.PaymentsByDayTransactionType", PaymentsByDayTransactionType] }
        ]);
    }
    create(value?: PartialMessage<GetPaymentsCSVReq>): GetPaymentsCSVReq {
        const message = { sortBy: 0, sortOrder: 0, methodOfTenders: [], transactionTypes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPaymentsCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPaymentsCSVReq): GetPaymentsCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.PaymentsByDaySortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                case /* repeated rd.analyticsv2.PaymentsByDayMethod method_of_tenders */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.methodOfTenders.push(reader.int32());
                    else
                        message.methodOfTenders.push(reader.int32());
                    break;
                case /* repeated rd.analyticsv2.PaymentsByDayTransactionType transaction_types */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.transactionTypes.push(reader.int32());
                    else
                        message.transactionTypes.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPaymentsCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.PaymentsByDaySortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        /* repeated rd.analyticsv2.PaymentsByDayMethod method_of_tenders = 4; */
        if (message.methodOfTenders.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.methodOfTenders.length; i++)
                writer.int32(message.methodOfTenders[i]);
            writer.join();
        }
        /* repeated rd.analyticsv2.PaymentsByDayTransactionType transaction_types = 5; */
        if (message.transactionTypes.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.transactionTypes.length; i++)
                writer.int32(message.transactionTypes[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetPaymentsCSVReq
 */
export const GetPaymentsCSVReq = new GetPaymentsCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPaymentsCSVRes$Type extends MessageType<GetPaymentsCSVRes> {
    constructor() {
        super("rd.search.service.GetPaymentsCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetPaymentsCSVRes>): GetPaymentsCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPaymentsCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPaymentsCSVRes): GetPaymentsCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPaymentsCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetPaymentsCSVRes
 */
export const GetPaymentsCSVRes = new GetPaymentsCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSoldProductsReq$Type extends MessageType<GetSoldProductsReq> {
    constructor() {
        super("rd.search.service.GetSoldProductsReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SoldProductsSortBy", SoldProductsSortBy, "SOLD_PRODUCTS_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetSoldProductsReq>): GetSoldProductsReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSoldProductsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSoldProductsReq): GetSoldProductsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.SoldProductsSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSoldProductsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.SoldProductsSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSoldProductsReq
 */
export const GetSoldProductsReq = new GetSoldProductsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSoldProductsRes$Type extends MessageType<GetSoldProductsRes> {
    constructor() {
        super("rd.search.service.GetSoldProductsRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetSoldProductsRes>): GetSoldProductsRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSoldProductsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSoldProductsRes): GetSoldProductsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSoldProductsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSoldProductsRes
 */
export const GetSoldProductsRes = new GetSoldProductsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSoldProductsPDFReq$Type extends MessageType<GetSoldProductsPDFReq> {
    constructor() {
        super("rd.search.service.GetSoldProductsPDFReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SoldProductsSortBy", SoldProductsSortBy, "SOLD_PRODUCTS_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetSoldProductsPDFReq>): GetSoldProductsPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSoldProductsPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSoldProductsPDFReq): GetSoldProductsPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.SoldProductsSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSoldProductsPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.SoldProductsSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSoldProductsPDFReq
 */
export const GetSoldProductsPDFReq = new GetSoldProductsPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSoldProductsPDFRes$Type extends MessageType<GetSoldProductsPDFRes> {
    constructor() {
        super("rd.search.service.GetSoldProductsPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSoldProductsPDFRes>): GetSoldProductsPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSoldProductsPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSoldProductsPDFRes): GetSoldProductsPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSoldProductsPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSoldProductsPDFRes
 */
export const GetSoldProductsPDFRes = new GetSoldProductsPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSoldProductsCSVReq$Type extends MessageType<GetSoldProductsCSVReq> {
    constructor() {
        super("rd.search.service.GetSoldProductsCSVReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SoldProductsSortBy", SoldProductsSortBy, "SOLD_PRODUCTS_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetSoldProductsCSVReq>): GetSoldProductsCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSoldProductsCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSoldProductsCSVReq): GetSoldProductsCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.SoldProductsSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSoldProductsCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.SoldProductsSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSoldProductsCSVReq
 */
export const GetSoldProductsCSVReq = new GetSoldProductsCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSoldProductsCSVRes$Type extends MessageType<GetSoldProductsCSVRes> {
    constructor() {
        super("rd.search.service.GetSoldProductsCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSoldProductsCSVRes>): GetSoldProductsCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSoldProductsCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSoldProductsCSVRes): GetSoldProductsCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSoldProductsCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSoldProductsCSVRes
 */
export const GetSoldProductsCSVRes = new GetSoldProductsCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByCustomerReq$Type extends MessageType<GetSalesByCustomerReq> {
    constructor() {
        super("rd.search.service.GetSalesByCustomerReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByCustomerSortBy", SalesByCustomerSortBy, "SALES_BY_CUSTOMER_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetSalesByCustomerReq>): GetSalesByCustomerReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByCustomerReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByCustomerReq): GetSalesByCustomerReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.SalesByCustomerSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByCustomerReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.SalesByCustomerSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByCustomerReq
 */
export const GetSalesByCustomerReq = new GetSalesByCustomerReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByCustomerRes$Type extends MessageType<GetSalesByCustomerRes> {
    constructor() {
        super("rd.search.service.GetSalesByCustomerRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetSalesByCustomerRes>): GetSalesByCustomerRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByCustomerRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByCustomerRes): GetSalesByCustomerRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByCustomerRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByCustomerRes
 */
export const GetSalesByCustomerRes = new GetSalesByCustomerRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByCustomerPDFReq$Type extends MessageType<GetSalesByCustomerPDFReq> {
    constructor() {
        super("rd.search.service.GetSalesByCustomerPDFReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByCustomerSortBy", SalesByCustomerSortBy, "SALES_BY_CUSTOMER_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetSalesByCustomerPDFReq>): GetSalesByCustomerPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByCustomerPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByCustomerPDFReq): GetSalesByCustomerPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.SalesByCustomerSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByCustomerPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.SalesByCustomerSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByCustomerPDFReq
 */
export const GetSalesByCustomerPDFReq = new GetSalesByCustomerPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByCustomerPDFRes$Type extends MessageType<GetSalesByCustomerPDFRes> {
    constructor() {
        super("rd.search.service.GetSalesByCustomerPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSalesByCustomerPDFRes>): GetSalesByCustomerPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByCustomerPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByCustomerPDFRes): GetSalesByCustomerPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByCustomerPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByCustomerPDFRes
 */
export const GetSalesByCustomerPDFRes = new GetSalesByCustomerPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByCustomerCSVReq$Type extends MessageType<GetSalesByCustomerCSVReq> {
    constructor() {
        super("rd.search.service.GetSalesByCustomerCSVReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByCustomerSortBy", SalesByCustomerSortBy, "SALES_BY_CUSTOMER_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetSalesByCustomerCSVReq>): GetSalesByCustomerCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByCustomerCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByCustomerCSVReq): GetSalesByCustomerCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.SalesByCustomerSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByCustomerCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.SalesByCustomerSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByCustomerCSVReq
 */
export const GetSalesByCustomerCSVReq = new GetSalesByCustomerCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByCustomerCSVRes$Type extends MessageType<GetSalesByCustomerCSVRes> {
    constructor() {
        super("rd.search.service.GetSalesByCustomerCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSalesByCustomerCSVRes>): GetSalesByCustomerCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByCustomerCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByCustomerCSVRes): GetSalesByCustomerCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByCustomerCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByCustomerCSVRes
 */
export const GetSalesByCustomerCSVRes = new GetSalesByCustomerCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByJobReq$Type extends MessageType<GetSalesByJobReq> {
    constructor() {
        super("rd.search.service.GetSalesByJobReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByJobSortBy", SalesByJobSortBy, "SALES_BY_JOB_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetSalesByJobReq>): GetSalesByJobReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByJobReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByJobReq): GetSalesByJobReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.SalesByJobSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByJobReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.SalesByJobSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByJobReq
 */
export const GetSalesByJobReq = new GetSalesByJobReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByJobRes$Type extends MessageType<GetSalesByJobRes> {
    constructor() {
        super("rd.search.service.GetSalesByJobRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetSalesByJobRes>): GetSalesByJobRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByJobRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByJobRes): GetSalesByJobRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByJobRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByJobRes
 */
export const GetSalesByJobRes = new GetSalesByJobRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByJobPDFReq$Type extends MessageType<GetSalesByJobPDFReq> {
    constructor() {
        super("rd.search.service.GetSalesByJobPDFReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByJobSortBy", SalesByJobSortBy, "SALES_BY_JOB_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetSalesByJobPDFReq>): GetSalesByJobPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByJobPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByJobPDFReq): GetSalesByJobPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.SalesByJobSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByJobPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.SalesByJobSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByJobPDFReq
 */
export const GetSalesByJobPDFReq = new GetSalesByJobPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByJobPDFRes$Type extends MessageType<GetSalesByJobPDFRes> {
    constructor() {
        super("rd.search.service.GetSalesByJobPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSalesByJobPDFRes>): GetSalesByJobPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByJobPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByJobPDFRes): GetSalesByJobPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByJobPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByJobPDFRes
 */
export const GetSalesByJobPDFRes = new GetSalesByJobPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByJobCSVReq$Type extends MessageType<GetSalesByJobCSVReq> {
    constructor() {
        super("rd.search.service.GetSalesByJobCSVReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByJobSortBy", SalesByJobSortBy, "SALES_BY_JOB_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetSalesByJobCSVReq>): GetSalesByJobCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByJobCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByJobCSVReq): GetSalesByJobCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.SalesByJobSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByJobCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.SalesByJobSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByJobCSVReq
 */
export const GetSalesByJobCSVReq = new GetSalesByJobCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByJobCSVRes$Type extends MessageType<GetSalesByJobCSVRes> {
    constructor() {
        super("rd.search.service.GetSalesByJobCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSalesByJobCSVRes>): GetSalesByJobCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByJobCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByJobCSVRes): GetSalesByJobCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByJobCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByJobCSVRes
 */
export const GetSalesByJobCSVRes = new GetSalesByJobCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByProductReq$Type extends MessageType<GetSalesByProductReq> {
    constructor() {
        super("rd.search.service.GetSalesByProductReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByProductSortBy", SalesByProductSortBy, "SALES_BY_PRODUCT_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetSalesByProductReq>): GetSalesByProductReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByProductReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByProductReq): GetSalesByProductReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.SalesByProductSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByProductReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.SalesByProductSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByProductReq
 */
export const GetSalesByProductReq = new GetSalesByProductReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByProductRes$Type extends MessageType<GetSalesByProductRes> {
    constructor() {
        super("rd.search.service.GetSalesByProductRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetSalesByProductRes>): GetSalesByProductRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByProductRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByProductRes): GetSalesByProductRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByProductRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByProductRes
 */
export const GetSalesByProductRes = new GetSalesByProductRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByProductPDFReq$Type extends MessageType<GetSalesByProductPDFReq> {
    constructor() {
        super("rd.search.service.GetSalesByProductPDFReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByProductSortBy", SalesByProductSortBy, "SALES_BY_PRODUCT_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetSalesByProductPDFReq>): GetSalesByProductPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByProductPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByProductPDFReq): GetSalesByProductPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.SalesByProductSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByProductPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.SalesByProductSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByProductPDFReq
 */
export const GetSalesByProductPDFReq = new GetSalesByProductPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByProductPDFRes$Type extends MessageType<GetSalesByProductPDFRes> {
    constructor() {
        super("rd.search.service.GetSalesByProductPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSalesByProductPDFRes>): GetSalesByProductPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByProductPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByProductPDFRes): GetSalesByProductPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByProductPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByProductPDFRes
 */
export const GetSalesByProductPDFRes = new GetSalesByProductPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByProductCSVReq$Type extends MessageType<GetSalesByProductCSVReq> {
    constructor() {
        super("rd.search.service.GetSalesByProductCSVReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByProductSortBy", SalesByProductSortBy, "SALES_BY_PRODUCT_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetSalesByProductCSVReq>): GetSalesByProductCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByProductCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByProductCSVReq): GetSalesByProductCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.SalesByProductSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByProductCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.SalesByProductSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByProductCSVReq
 */
export const GetSalesByProductCSVReq = new GetSalesByProductCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByProductCSVRes$Type extends MessageType<GetSalesByProductCSVRes> {
    constructor() {
        super("rd.search.service.GetSalesByProductCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSalesByProductCSVRes>): GetSalesByProductCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByProductCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByProductCSVRes): GetSalesByProductCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByProductCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByProductCSVRes
 */
export const GetSalesByProductCSVRes = new GetSalesByProductCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByLocationReq$Type extends MessageType<GetSalesByLocationReq> {
    constructor() {
        super("rd.search.service.GetSalesByLocationReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByLocationSortBy", SalesByLocationSortBy, "SALES_BY_LOCATION_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetSalesByLocationReq>): GetSalesByLocationReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByLocationReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByLocationReq): GetSalesByLocationReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.SalesByLocationSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByLocationReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.SalesByLocationSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByLocationReq
 */
export const GetSalesByLocationReq = new GetSalesByLocationReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByLocationRes$Type extends MessageType<GetSalesByLocationRes> {
    constructor() {
        super("rd.search.service.GetSalesByLocationRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetSalesByLocationRes>): GetSalesByLocationRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByLocationRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByLocationRes): GetSalesByLocationRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByLocationRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByLocationRes
 */
export const GetSalesByLocationRes = new GetSalesByLocationRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByLocationPDFReq$Type extends MessageType<GetSalesByLocationPDFReq> {
    constructor() {
        super("rd.search.service.GetSalesByLocationPDFReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByLocationSortBy", SalesByLocationSortBy, "SALES_BY_LOCATION_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetSalesByLocationPDFReq>): GetSalesByLocationPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByLocationPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByLocationPDFReq): GetSalesByLocationPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.SalesByLocationSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByLocationPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.SalesByLocationSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByLocationPDFReq
 */
export const GetSalesByLocationPDFReq = new GetSalesByLocationPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByLocationPDFRes$Type extends MessageType<GetSalesByLocationPDFRes> {
    constructor() {
        super("rd.search.service.GetSalesByLocationPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSalesByLocationPDFRes>): GetSalesByLocationPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByLocationPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByLocationPDFRes): GetSalesByLocationPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByLocationPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByLocationPDFRes
 */
export const GetSalesByLocationPDFRes = new GetSalesByLocationPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByLocationCSVReq$Type extends MessageType<GetSalesByLocationCSVReq> {
    constructor() {
        super("rd.search.service.GetSalesByLocationCSVReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByLocationSortBy", SalesByLocationSortBy, "SALES_BY_LOCATION_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetSalesByLocationCSVReq>): GetSalesByLocationCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByLocationCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByLocationCSVReq): GetSalesByLocationCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.SalesByLocationSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByLocationCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.SalesByLocationSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByLocationCSVReq
 */
export const GetSalesByLocationCSVReq = new GetSalesByLocationCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByLocationCSVRes$Type extends MessageType<GetSalesByLocationCSVRes> {
    constructor() {
        super("rd.search.service.GetSalesByLocationCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSalesByLocationCSVRes>): GetSalesByLocationCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByLocationCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByLocationCSVRes): GetSalesByLocationCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByLocationCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByLocationCSVRes
 */
export const GetSalesByLocationCSVRes = new GetSalesByLocationCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByRepReq$Type extends MessageType<GetSalesByRepReq> {
    constructor() {
        super("rd.search.service.GetSalesByRepReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByRepSortBy", SalesByRepSortBy, "SALES_BY_REP_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetSalesByRepReq>): GetSalesByRepReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByRepReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByRepReq): GetSalesByRepReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.SalesByRepSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByRepReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.SalesByRepSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByRepReq
 */
export const GetSalesByRepReq = new GetSalesByRepReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByRepRes$Type extends MessageType<GetSalesByRepRes> {
    constructor() {
        super("rd.search.service.GetSalesByRepRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetSalesByRepRes>): GetSalesByRepRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByRepRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByRepRes): GetSalesByRepRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByRepRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByRepRes
 */
export const GetSalesByRepRes = new GetSalesByRepRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByRepPDFReq$Type extends MessageType<GetSalesByRepPDFReq> {
    constructor() {
        super("rd.search.service.GetSalesByRepPDFReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByRepSortBy", SalesByRepSortBy, "SALES_BY_REP_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetSalesByRepPDFReq>): GetSalesByRepPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByRepPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByRepPDFReq): GetSalesByRepPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.SalesByRepSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByRepPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.SalesByRepSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByRepPDFReq
 */
export const GetSalesByRepPDFReq = new GetSalesByRepPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByRepPDFRes$Type extends MessageType<GetSalesByRepPDFRes> {
    constructor() {
        super("rd.search.service.GetSalesByRepPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSalesByRepPDFRes>): GetSalesByRepPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByRepPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByRepPDFRes): GetSalesByRepPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByRepPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByRepPDFRes
 */
export const GetSalesByRepPDFRes = new GetSalesByRepPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByRepCSVReq$Type extends MessageType<GetSalesByRepCSVReq> {
    constructor() {
        super("rd.search.service.GetSalesByRepCSVReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByRepSortBy", SalesByRepSortBy, "SALES_BY_REP_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetSalesByRepCSVReq>): GetSalesByRepCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByRepCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByRepCSVReq): GetSalesByRepCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.SalesByRepSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByRepCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.SalesByRepSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByRepCSVReq
 */
export const GetSalesByRepCSVReq = new GetSalesByRepCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByRepCSVRes$Type extends MessageType<GetSalesByRepCSVRes> {
    constructor() {
        super("rd.search.service.GetSalesByRepCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSalesByRepCSVRes>): GetSalesByRepCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByRepCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByRepCSVRes): GetSalesByRepCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByRepCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetSalesByRepCSVRes
 */
export const GetSalesByRepCSVRes = new GetSalesByRepCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPriceEditsReq$Type extends MessageType<GetPriceEditsReq> {
    constructor() {
        super("rd.search.service.GetPriceEditsReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.PriceEditsSortBy", PriceEditsSortBy, "PRICE_EDITS_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetPriceEditsReq>): GetPriceEditsReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPriceEditsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPriceEditsReq): GetPriceEditsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.PriceEditsSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPriceEditsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.PriceEditsSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetPriceEditsReq
 */
export const GetPriceEditsReq = new GetPriceEditsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPriceEditsRes$Type extends MessageType<GetPriceEditsRes> {
    constructor() {
        super("rd.search.service.GetPriceEditsRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetPriceEditsRes>): GetPriceEditsRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPriceEditsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPriceEditsRes): GetPriceEditsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPriceEditsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetPriceEditsRes
 */
export const GetPriceEditsRes = new GetPriceEditsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPriceEditsPDFReq$Type extends MessageType<GetPriceEditsPDFReq> {
    constructor() {
        super("rd.search.service.GetPriceEditsPDFReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.PriceEditsSortBy", PriceEditsSortBy, "PRICE_EDITS_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetPriceEditsPDFReq>): GetPriceEditsPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPriceEditsPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPriceEditsPDFReq): GetPriceEditsPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.PriceEditsSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPriceEditsPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.PriceEditsSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetPriceEditsPDFReq
 */
export const GetPriceEditsPDFReq = new GetPriceEditsPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPriceEditsPDFRes$Type extends MessageType<GetPriceEditsPDFRes> {
    constructor() {
        super("rd.search.service.GetPriceEditsPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetPriceEditsPDFRes>): GetPriceEditsPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPriceEditsPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPriceEditsPDFRes): GetPriceEditsPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPriceEditsPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetPriceEditsPDFRes
 */
export const GetPriceEditsPDFRes = new GetPriceEditsPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPriceEditsCSVReq$Type extends MessageType<GetPriceEditsCSVReq> {
    constructor() {
        super("rd.search.service.GetPriceEditsCSVReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.PriceEditsSortBy", PriceEditsSortBy, "PRICE_EDITS_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetPriceEditsCSVReq>): GetPriceEditsCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPriceEditsCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPriceEditsCSVReq): GetPriceEditsCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.PriceEditsSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPriceEditsCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.PriceEditsSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetPriceEditsCSVReq
 */
export const GetPriceEditsCSVReq = new GetPriceEditsCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPriceEditsCSVRes$Type extends MessageType<GetPriceEditsCSVRes> {
    constructor() {
        super("rd.search.service.GetPriceEditsCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetPriceEditsCSVRes>): GetPriceEditsCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPriceEditsCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPriceEditsCSVRes): GetPriceEditsCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPriceEditsCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetPriceEditsCSVRes
 */
export const GetPriceEditsCSVRes = new GetPriceEditsCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProfitAndLossReq$Type extends MessageType<GetProfitAndLossReq> {
    constructor() {
        super("rd.search.service.GetProfitAndLossReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetProfitAndLossReq>): GetProfitAndLossReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProfitAndLossReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProfitAndLossReq): GetProfitAndLossReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProfitAndLossReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetProfitAndLossReq
 */
export const GetProfitAndLossReq = new GetProfitAndLossReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProfitAndLossRes$Type extends MessageType<GetProfitAndLossRes> {
    constructor() {
        super("rd.search.service.GetProfitAndLossRes", [
            { no: 1, name: "profit_and_loss", kind: "message", T: () => ProfitAndLoss }
        ]);
    }
    create(value?: PartialMessage<GetProfitAndLossRes>): GetProfitAndLossRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProfitAndLossRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProfitAndLossRes): GetProfitAndLossRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.ProfitAndLoss profit_and_loss */ 1:
                    message.profitAndLoss = ProfitAndLoss.internalBinaryRead(reader, reader.uint32(), options, message.profitAndLoss);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProfitAndLossRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.ProfitAndLoss profit_and_loss = 1; */
        if (message.profitAndLoss)
            ProfitAndLoss.internalBinaryWrite(message.profitAndLoss, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetProfitAndLossRes
 */
export const GetProfitAndLossRes = new GetProfitAndLossRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProfitAndLossPDFReq$Type extends MessageType<GetProfitAndLossPDFReq> {
    constructor() {
        super("rd.search.service.GetProfitAndLossPDFReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "hide_product_categories", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetProfitAndLossPDFReq>): GetProfitAndLossPDFReq {
        const message = { hideProductCategories: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProfitAndLossPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProfitAndLossPDFReq): GetProfitAndLossPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* bool hide_product_categories */ 2:
                    message.hideProductCategories = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProfitAndLossPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool hide_product_categories = 2; */
        if (message.hideProductCategories !== false)
            writer.tag(2, WireType.Varint).bool(message.hideProductCategories);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetProfitAndLossPDFReq
 */
export const GetProfitAndLossPDFReq = new GetProfitAndLossPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProfitAndLossPDFRes$Type extends MessageType<GetProfitAndLossPDFRes> {
    constructor() {
        super("rd.search.service.GetProfitAndLossPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetProfitAndLossPDFRes>): GetProfitAndLossPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProfitAndLossPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProfitAndLossPDFRes): GetProfitAndLossPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProfitAndLossPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetProfitAndLossPDFRes
 */
export const GetProfitAndLossPDFRes = new GetProfitAndLossPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNewAccountsOpenedReq$Type extends MessageType<GetNewAccountsOpenedReq> {
    constructor() {
        super("rd.search.service.GetNewAccountsOpenedReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.NewAccountsOpenedSortBy", NewAccountsOpenedSortBy, "NEW_ACCOUNTS_OPENED_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetNewAccountsOpenedReq>): GetNewAccountsOpenedReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNewAccountsOpenedReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNewAccountsOpenedReq): GetNewAccountsOpenedReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.NewAccountsOpenedSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNewAccountsOpenedReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.NewAccountsOpenedSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetNewAccountsOpenedReq
 */
export const GetNewAccountsOpenedReq = new GetNewAccountsOpenedReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNewAccountsOpenedRes$Type extends MessageType<GetNewAccountsOpenedRes> {
    constructor() {
        super("rd.search.service.GetNewAccountsOpenedRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetNewAccountsOpenedRes>): GetNewAccountsOpenedRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNewAccountsOpenedRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNewAccountsOpenedRes): GetNewAccountsOpenedRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNewAccountsOpenedRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetNewAccountsOpenedRes
 */
export const GetNewAccountsOpenedRes = new GetNewAccountsOpenedRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNewAccountsOpenedPDFReq$Type extends MessageType<GetNewAccountsOpenedPDFReq> {
    constructor() {
        super("rd.search.service.GetNewAccountsOpenedPDFReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.NewAccountsOpenedSortBy", NewAccountsOpenedSortBy, "NEW_ACCOUNTS_OPENED_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetNewAccountsOpenedPDFReq>): GetNewAccountsOpenedPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNewAccountsOpenedPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNewAccountsOpenedPDFReq): GetNewAccountsOpenedPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.NewAccountsOpenedSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNewAccountsOpenedPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.NewAccountsOpenedSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetNewAccountsOpenedPDFReq
 */
export const GetNewAccountsOpenedPDFReq = new GetNewAccountsOpenedPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNewAccountsOpenedPDFRes$Type extends MessageType<GetNewAccountsOpenedPDFRes> {
    constructor() {
        super("rd.search.service.GetNewAccountsOpenedPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetNewAccountsOpenedPDFRes>): GetNewAccountsOpenedPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNewAccountsOpenedPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNewAccountsOpenedPDFRes): GetNewAccountsOpenedPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNewAccountsOpenedPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetNewAccountsOpenedPDFRes
 */
export const GetNewAccountsOpenedPDFRes = new GetNewAccountsOpenedPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNewAccountsOpenedCSVReq$Type extends MessageType<GetNewAccountsOpenedCSVReq> {
    constructor() {
        super("rd.search.service.GetNewAccountsOpenedCSVReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.NewAccountsOpenedSortBy", NewAccountsOpenedSortBy, "NEW_ACCOUNTS_OPENED_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetNewAccountsOpenedCSVReq>): GetNewAccountsOpenedCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNewAccountsOpenedCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNewAccountsOpenedCSVReq): GetNewAccountsOpenedCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.NewAccountsOpenedSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNewAccountsOpenedCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.NewAccountsOpenedSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetNewAccountsOpenedCSVReq
 */
export const GetNewAccountsOpenedCSVReq = new GetNewAccountsOpenedCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNewAccountsOpenedCSVRes$Type extends MessageType<GetNewAccountsOpenedCSVRes> {
    constructor() {
        super("rd.search.service.GetNewAccountsOpenedCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetNewAccountsOpenedCSVRes>): GetNewAccountsOpenedCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNewAccountsOpenedCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNewAccountsOpenedCSVRes): GetNewAccountsOpenedCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNewAccountsOpenedCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetNewAccountsOpenedCSVRes
 */
export const GetNewAccountsOpenedCSVRes = new GetNewAccountsOpenedCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgingDetailReq$Type extends MessageType<GetAgingDetailReq> {
    constructor() {
        super("rd.search.service.GetAgingDetailReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.AgingDetailSortBy", AgingDetailSortBy, "AGING_DETAIL_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 4, name: "transaction_types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.AgingDetailTransactionType", AgingDetailTransactionType, "AGING_DETAIL_TRANSACTION_TYPE_"] },
            { no: 5, name: "statuses", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.AgingDetailStatus", AgingDetailStatus, "AGING_DETAIL_STATUS_"] }
        ]);
    }
    create(value?: PartialMessage<GetAgingDetailReq>): GetAgingDetailReq {
        const message = { sortBy: 0, sortOrder: 0, transactionTypes: [], statuses: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAgingDetailReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgingDetailReq): GetAgingDetailReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.AgingDetailSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                case /* repeated rd.analyticsv2.AgingDetailTransactionType transaction_types */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.transactionTypes.push(reader.int32());
                    else
                        message.transactionTypes.push(reader.int32());
                    break;
                case /* repeated rd.analyticsv2.AgingDetailStatus statuses */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.statuses.push(reader.int32());
                    else
                        message.statuses.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgingDetailReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.AgingDetailSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        /* repeated rd.analyticsv2.AgingDetailTransactionType transaction_types = 4; */
        if (message.transactionTypes.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.transactionTypes.length; i++)
                writer.int32(message.transactionTypes[i]);
            writer.join();
        }
        /* repeated rd.analyticsv2.AgingDetailStatus statuses = 5; */
        if (message.statuses.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.statuses.length; i++)
                writer.int32(message.statuses[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAgingDetailReq
 */
export const GetAgingDetailReq = new GetAgingDetailReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgingDetailRes$Type extends MessageType<GetAgingDetailRes> {
    constructor() {
        super("rd.search.service.GetAgingDetailRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetAgingDetailRes>): GetAgingDetailRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAgingDetailRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgingDetailRes): GetAgingDetailRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgingDetailRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAgingDetailRes
 */
export const GetAgingDetailRes = new GetAgingDetailRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgingDetailPDFReq$Type extends MessageType<GetAgingDetailPDFReq> {
    constructor() {
        super("rd.search.service.GetAgingDetailPDFReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.AgingDetailSortBy", AgingDetailSortBy, "AGING_DETAIL_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 4, name: "transaction_types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.AgingDetailTransactionType", AgingDetailTransactionType, "AGING_DETAIL_TRANSACTION_TYPE_"] },
            { no: 5, name: "statuses", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.AgingDetailStatus", AgingDetailStatus, "AGING_DETAIL_STATUS_"] }
        ]);
    }
    create(value?: PartialMessage<GetAgingDetailPDFReq>): GetAgingDetailPDFReq {
        const message = { sortBy: 0, sortOrder: 0, transactionTypes: [], statuses: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAgingDetailPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgingDetailPDFReq): GetAgingDetailPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.AgingDetailSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                case /* repeated rd.analyticsv2.AgingDetailTransactionType transaction_types */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.transactionTypes.push(reader.int32());
                    else
                        message.transactionTypes.push(reader.int32());
                    break;
                case /* repeated rd.analyticsv2.AgingDetailStatus statuses */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.statuses.push(reader.int32());
                    else
                        message.statuses.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgingDetailPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.AgingDetailSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        /* repeated rd.analyticsv2.AgingDetailTransactionType transaction_types = 4; */
        if (message.transactionTypes.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.transactionTypes.length; i++)
                writer.int32(message.transactionTypes[i]);
            writer.join();
        }
        /* repeated rd.analyticsv2.AgingDetailStatus statuses = 5; */
        if (message.statuses.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.statuses.length; i++)
                writer.int32(message.statuses[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAgingDetailPDFReq
 */
export const GetAgingDetailPDFReq = new GetAgingDetailPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgingDetailPDFRes$Type extends MessageType<GetAgingDetailPDFRes> {
    constructor() {
        super("rd.search.service.GetAgingDetailPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetAgingDetailPDFRes>): GetAgingDetailPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAgingDetailPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgingDetailPDFRes): GetAgingDetailPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgingDetailPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAgingDetailPDFRes
 */
export const GetAgingDetailPDFRes = new GetAgingDetailPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgingDetailCSVReq$Type extends MessageType<GetAgingDetailCSVReq> {
    constructor() {
        super("rd.search.service.GetAgingDetailCSVReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.AgingDetailSortBy", AgingDetailSortBy, "AGING_DETAIL_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 4, name: "transaction_types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.AgingDetailTransactionType", AgingDetailTransactionType, "AGING_DETAIL_TRANSACTION_TYPE_"] },
            { no: 5, name: "statuses", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.AgingDetailStatus", AgingDetailStatus, "AGING_DETAIL_STATUS_"] }
        ]);
    }
    create(value?: PartialMessage<GetAgingDetailCSVReq>): GetAgingDetailCSVReq {
        const message = { sortBy: 0, sortOrder: 0, transactionTypes: [], statuses: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAgingDetailCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgingDetailCSVReq): GetAgingDetailCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.AgingDetailSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                case /* repeated rd.analyticsv2.AgingDetailTransactionType transaction_types */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.transactionTypes.push(reader.int32());
                    else
                        message.transactionTypes.push(reader.int32());
                    break;
                case /* repeated rd.analyticsv2.AgingDetailStatus statuses */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.statuses.push(reader.int32());
                    else
                        message.statuses.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgingDetailCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.AgingDetailSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        /* repeated rd.analyticsv2.AgingDetailTransactionType transaction_types = 4; */
        if (message.transactionTypes.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.transactionTypes.length; i++)
                writer.int32(message.transactionTypes[i]);
            writer.join();
        }
        /* repeated rd.analyticsv2.AgingDetailStatus statuses = 5; */
        if (message.statuses.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.statuses.length; i++)
                writer.int32(message.statuses[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAgingDetailCSVReq
 */
export const GetAgingDetailCSVReq = new GetAgingDetailCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgingDetailCSVRes$Type extends MessageType<GetAgingDetailCSVRes> {
    constructor() {
        super("rd.search.service.GetAgingDetailCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetAgingDetailCSVRes>): GetAgingDetailCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAgingDetailCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgingDetailCSVRes): GetAgingDetailCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgingDetailCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAgingDetailCSVRes
 */
export const GetAgingDetailCSVRes = new GetAgingDetailCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPurchaseJournalReq$Type extends MessageType<GetPurchaseJournalReq> {
    constructor() {
        super("rd.search.service.GetPurchaseJournalReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.PurchaseJournalSortBy", PurchaseJournalSortBy, "PURCHASE_JOURNAL_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetPurchaseJournalReq>): GetPurchaseJournalReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPurchaseJournalReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPurchaseJournalReq): GetPurchaseJournalReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.PurchaseJournalSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPurchaseJournalReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.PurchaseJournalSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetPurchaseJournalReq
 */
export const GetPurchaseJournalReq = new GetPurchaseJournalReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPurchaseJournalRes$Type extends MessageType<GetPurchaseJournalRes> {
    constructor() {
        super("rd.search.service.GetPurchaseJournalRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetPurchaseJournalRes>): GetPurchaseJournalRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPurchaseJournalRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPurchaseJournalRes): GetPurchaseJournalRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPurchaseJournalRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetPurchaseJournalRes
 */
export const GetPurchaseJournalRes = new GetPurchaseJournalRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPurchaseJournalPDFReq$Type extends MessageType<GetPurchaseJournalPDFReq> {
    constructor() {
        super("rd.search.service.GetPurchaseJournalPDFReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.PurchaseJournalSortBy", PurchaseJournalSortBy, "PURCHASE_JOURNAL_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetPurchaseJournalPDFReq>): GetPurchaseJournalPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPurchaseJournalPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPurchaseJournalPDFReq): GetPurchaseJournalPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.PurchaseJournalSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPurchaseJournalPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.PurchaseJournalSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetPurchaseJournalPDFReq
 */
export const GetPurchaseJournalPDFReq = new GetPurchaseJournalPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPurchaseJournalPDFRes$Type extends MessageType<GetPurchaseJournalPDFRes> {
    constructor() {
        super("rd.search.service.GetPurchaseJournalPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetPurchaseJournalPDFRes>): GetPurchaseJournalPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPurchaseJournalPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPurchaseJournalPDFRes): GetPurchaseJournalPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPurchaseJournalPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetPurchaseJournalPDFRes
 */
export const GetPurchaseJournalPDFRes = new GetPurchaseJournalPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPurchaseJournalCSVReq$Type extends MessageType<GetPurchaseJournalCSVReq> {
    constructor() {
        super("rd.search.service.GetPurchaseJournalCSVReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.PurchaseJournalSortBy", PurchaseJournalSortBy, "PURCHASE_JOURNAL_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetPurchaseJournalCSVReq>): GetPurchaseJournalCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPurchaseJournalCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPurchaseJournalCSVReq): GetPurchaseJournalCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.PurchaseJournalSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPurchaseJournalCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.PurchaseJournalSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetPurchaseJournalCSVReq
 */
export const GetPurchaseJournalCSVReq = new GetPurchaseJournalCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPurchaseJournalCSVRes$Type extends MessageType<GetPurchaseJournalCSVRes> {
    constructor() {
        super("rd.search.service.GetPurchaseJournalCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetPurchaseJournalCSVRes>): GetPurchaseJournalCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPurchaseJournalCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPurchaseJournalCSVRes): GetPurchaseJournalCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPurchaseJournalCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetPurchaseJournalCSVRes
 */
export const GetPurchaseJournalCSVRes = new GetPurchaseJournalCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAccountPaymentAdjustmentsReq$Type extends MessageType<GetAccountPaymentAdjustmentsReq> {
    constructor() {
        super("rd.search.service.GetAccountPaymentAdjustmentsReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.AccountPaymentAdjustmentsSortBy", AccountPaymentAdjustmentsSortBy, "ACCOUNT_PAYMENT_ADJUSTMENTS_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 4, name: "adjustment_types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.AccountPaymentAdjustmentsAdjustmentType", AccountPaymentAdjustmentsAdjustmentType, "ACCOUNT_PAYMENT_ADJUSTMENTS_ADJUSTMENT_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<GetAccountPaymentAdjustmentsReq>): GetAccountPaymentAdjustmentsReq {
        const message = { sortBy: 0, sortOrder: 0, adjustmentTypes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAccountPaymentAdjustmentsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAccountPaymentAdjustmentsReq): GetAccountPaymentAdjustmentsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.AccountPaymentAdjustmentsSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                case /* repeated rd.analyticsv2.AccountPaymentAdjustmentsAdjustmentType adjustment_types */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.adjustmentTypes.push(reader.int32());
                    else
                        message.adjustmentTypes.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAccountPaymentAdjustmentsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.AccountPaymentAdjustmentsSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        /* repeated rd.analyticsv2.AccountPaymentAdjustmentsAdjustmentType adjustment_types = 4; */
        if (message.adjustmentTypes.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.adjustmentTypes.length; i++)
                writer.int32(message.adjustmentTypes[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAccountPaymentAdjustmentsReq
 */
export const GetAccountPaymentAdjustmentsReq = new GetAccountPaymentAdjustmentsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAccountPaymentAdjustmentsRes$Type extends MessageType<GetAccountPaymentAdjustmentsRes> {
    constructor() {
        super("rd.search.service.GetAccountPaymentAdjustmentsRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetAccountPaymentAdjustmentsRes>): GetAccountPaymentAdjustmentsRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAccountPaymentAdjustmentsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAccountPaymentAdjustmentsRes): GetAccountPaymentAdjustmentsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAccountPaymentAdjustmentsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAccountPaymentAdjustmentsRes
 */
export const GetAccountPaymentAdjustmentsRes = new GetAccountPaymentAdjustmentsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAccountPaymentAdjustmentsPDFReq$Type extends MessageType<GetAccountPaymentAdjustmentsPDFReq> {
    constructor() {
        super("rd.search.service.GetAccountPaymentAdjustmentsPDFReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.AccountPaymentAdjustmentsSortBy", AccountPaymentAdjustmentsSortBy, "ACCOUNT_PAYMENT_ADJUSTMENTS_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 4, name: "adjustment_types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.AccountPaymentAdjustmentsAdjustmentType", AccountPaymentAdjustmentsAdjustmentType, "ACCOUNT_PAYMENT_ADJUSTMENTS_ADJUSTMENT_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<GetAccountPaymentAdjustmentsPDFReq>): GetAccountPaymentAdjustmentsPDFReq {
        const message = { sortBy: 0, sortOrder: 0, adjustmentTypes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAccountPaymentAdjustmentsPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAccountPaymentAdjustmentsPDFReq): GetAccountPaymentAdjustmentsPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.AccountPaymentAdjustmentsSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                case /* repeated rd.analyticsv2.AccountPaymentAdjustmentsAdjustmentType adjustment_types */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.adjustmentTypes.push(reader.int32());
                    else
                        message.adjustmentTypes.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAccountPaymentAdjustmentsPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.AccountPaymentAdjustmentsSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        /* repeated rd.analyticsv2.AccountPaymentAdjustmentsAdjustmentType adjustment_types = 4; */
        if (message.adjustmentTypes.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.adjustmentTypes.length; i++)
                writer.int32(message.adjustmentTypes[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAccountPaymentAdjustmentsPDFReq
 */
export const GetAccountPaymentAdjustmentsPDFReq = new GetAccountPaymentAdjustmentsPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAccountPaymentAdjustmentsPDFRes$Type extends MessageType<GetAccountPaymentAdjustmentsPDFRes> {
    constructor() {
        super("rd.search.service.GetAccountPaymentAdjustmentsPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetAccountPaymentAdjustmentsPDFRes>): GetAccountPaymentAdjustmentsPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAccountPaymentAdjustmentsPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAccountPaymentAdjustmentsPDFRes): GetAccountPaymentAdjustmentsPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAccountPaymentAdjustmentsPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAccountPaymentAdjustmentsPDFRes
 */
export const GetAccountPaymentAdjustmentsPDFRes = new GetAccountPaymentAdjustmentsPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAccountPaymentAdjustmentsCSVReq$Type extends MessageType<GetAccountPaymentAdjustmentsCSVReq> {
    constructor() {
        super("rd.search.service.GetAccountPaymentAdjustmentsCSVReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.AccountPaymentAdjustmentsSortBy", AccountPaymentAdjustmentsSortBy, "ACCOUNT_PAYMENT_ADJUSTMENTS_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 4, name: "adjustment_types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.AccountPaymentAdjustmentsAdjustmentType", AccountPaymentAdjustmentsAdjustmentType, "ACCOUNT_PAYMENT_ADJUSTMENTS_ADJUSTMENT_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<GetAccountPaymentAdjustmentsCSVReq>): GetAccountPaymentAdjustmentsCSVReq {
        const message = { sortBy: 0, sortOrder: 0, adjustmentTypes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAccountPaymentAdjustmentsCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAccountPaymentAdjustmentsCSVReq): GetAccountPaymentAdjustmentsCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.AccountPaymentAdjustmentsSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                case /* repeated rd.analyticsv2.AccountPaymentAdjustmentsAdjustmentType adjustment_types */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.adjustmentTypes.push(reader.int32());
                    else
                        message.adjustmentTypes.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAccountPaymentAdjustmentsCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.AccountPaymentAdjustmentsSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        /* repeated rd.analyticsv2.AccountPaymentAdjustmentsAdjustmentType adjustment_types = 4; */
        if (message.adjustmentTypes.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.adjustmentTypes.length; i++)
                writer.int32(message.adjustmentTypes[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAccountPaymentAdjustmentsCSVReq
 */
export const GetAccountPaymentAdjustmentsCSVReq = new GetAccountPaymentAdjustmentsCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAccountPaymentAdjustmentsCSVRes$Type extends MessageType<GetAccountPaymentAdjustmentsCSVRes> {
    constructor() {
        super("rd.search.service.GetAccountPaymentAdjustmentsCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetAccountPaymentAdjustmentsCSVRes>): GetAccountPaymentAdjustmentsCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAccountPaymentAdjustmentsCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAccountPaymentAdjustmentsCSVRes): GetAccountPaymentAdjustmentsCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAccountPaymentAdjustmentsCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAccountPaymentAdjustmentsCSVRes
 */
export const GetAccountPaymentAdjustmentsCSVRes = new GetAccountPaymentAdjustmentsCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAdditionalFeesReq$Type extends MessageType<GetAdditionalFeesReq> {
    constructor() {
        super("rd.search.service.GetAdditionalFeesReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.AdditionalFeesSortBy", AdditionalFeesSortBy, "ADDITIONAL_FEES_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetAdditionalFeesReq>): GetAdditionalFeesReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAdditionalFeesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAdditionalFeesReq): GetAdditionalFeesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.AdditionalFeesSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAdditionalFeesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.AdditionalFeesSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAdditionalFeesReq
 */
export const GetAdditionalFeesReq = new GetAdditionalFeesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAdditionalFeesRes$Type extends MessageType<GetAdditionalFeesRes> {
    constructor() {
        super("rd.search.service.GetAdditionalFeesRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetAdditionalFeesRes>): GetAdditionalFeesRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAdditionalFeesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAdditionalFeesRes): GetAdditionalFeesRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAdditionalFeesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAdditionalFeesRes
 */
export const GetAdditionalFeesRes = new GetAdditionalFeesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAdditionalFeesPDFReq$Type extends MessageType<GetAdditionalFeesPDFReq> {
    constructor() {
        super("rd.search.service.GetAdditionalFeesPDFReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.AdditionalFeesSortBy", AdditionalFeesSortBy, "ADDITIONAL_FEES_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetAdditionalFeesPDFReq>): GetAdditionalFeesPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAdditionalFeesPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAdditionalFeesPDFReq): GetAdditionalFeesPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.AdditionalFeesSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAdditionalFeesPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.AdditionalFeesSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAdditionalFeesPDFReq
 */
export const GetAdditionalFeesPDFReq = new GetAdditionalFeesPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAdditionalFeesPDFRes$Type extends MessageType<GetAdditionalFeesPDFRes> {
    constructor() {
        super("rd.search.service.GetAdditionalFeesPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetAdditionalFeesPDFRes>): GetAdditionalFeesPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAdditionalFeesPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAdditionalFeesPDFRes): GetAdditionalFeesPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAdditionalFeesPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAdditionalFeesPDFRes
 */
export const GetAdditionalFeesPDFRes = new GetAdditionalFeesPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAdditionalFeesCSVReq$Type extends MessageType<GetAdditionalFeesCSVReq> {
    constructor() {
        super("rd.search.service.GetAdditionalFeesCSVReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.AdditionalFeesSortBy", AdditionalFeesSortBy, "ADDITIONAL_FEES_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetAdditionalFeesCSVReq>): GetAdditionalFeesCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAdditionalFeesCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAdditionalFeesCSVReq): GetAdditionalFeesCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.AdditionalFeesSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAdditionalFeesCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.AdditionalFeesSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAdditionalFeesCSVReq
 */
export const GetAdditionalFeesCSVReq = new GetAdditionalFeesCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAdditionalFeesCSVRes$Type extends MessageType<GetAdditionalFeesCSVRes> {
    constructor() {
        super("rd.search.service.GetAdditionalFeesCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetAdditionalFeesCSVRes>): GetAdditionalFeesCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAdditionalFeesCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAdditionalFeesCSVRes): GetAdditionalFeesCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAdditionalFeesCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAdditionalFeesCSVRes
 */
export const GetAdditionalFeesCSVRes = new GetAdditionalFeesCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgingByJobReq$Type extends MessageType<GetAgingByJobReq> {
    constructor() {
        super("rd.search.service.GetAgingByJobReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.JobAgingSortBy", JobAgingSortBy, "JOB_AGING_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetAgingByJobReq>): GetAgingByJobReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAgingByJobReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgingByJobReq): GetAgingByJobReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.JobAgingSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgingByJobReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.JobAgingSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAgingByJobReq
 */
export const GetAgingByJobReq = new GetAgingByJobReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgingByJobRes$Type extends MessageType<GetAgingByJobRes> {
    constructor() {
        super("rd.search.service.GetAgingByJobRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetAgingByJobRes>): GetAgingByJobRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAgingByJobRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgingByJobRes): GetAgingByJobRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgingByJobRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAgingByJobRes
 */
export const GetAgingByJobRes = new GetAgingByJobRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgingByJobPDFReq$Type extends MessageType<GetAgingByJobPDFReq> {
    constructor() {
        super("rd.search.service.GetAgingByJobPDFReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.JobAgingSortBy", JobAgingSortBy, "JOB_AGING_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetAgingByJobPDFReq>): GetAgingByJobPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAgingByJobPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgingByJobPDFReq): GetAgingByJobPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.JobAgingSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgingByJobPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.JobAgingSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAgingByJobPDFReq
 */
export const GetAgingByJobPDFReq = new GetAgingByJobPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgingByJobPDFRes$Type extends MessageType<GetAgingByJobPDFRes> {
    constructor() {
        super("rd.search.service.GetAgingByJobPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetAgingByJobPDFRes>): GetAgingByJobPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAgingByJobPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgingByJobPDFRes): GetAgingByJobPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgingByJobPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAgingByJobPDFRes
 */
export const GetAgingByJobPDFRes = new GetAgingByJobPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgingByJobCSVReq$Type extends MessageType<GetAgingByJobCSVReq> {
    constructor() {
        super("rd.search.service.GetAgingByJobCSVReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.JobAgingSortBy", JobAgingSortBy, "JOB_AGING_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetAgingByJobCSVReq>): GetAgingByJobCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAgingByJobCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgingByJobCSVReq): GetAgingByJobCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.JobAgingSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgingByJobCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.JobAgingSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAgingByJobCSVReq
 */
export const GetAgingByJobCSVReq = new GetAgingByJobCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgingByJobCSVRes$Type extends MessageType<GetAgingByJobCSVRes> {
    constructor() {
        super("rd.search.service.GetAgingByJobCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetAgingByJobCSVRes>): GetAgingByJobCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAgingByJobCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgingByJobCSVRes): GetAgingByJobCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgingByJobCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetAgingByJobCSVRes
 */
export const GetAgingByJobCSVRes = new GetAgingByJobCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInventoryByDepartmentReq$Type extends MessageType<GetInventoryByDepartmentReq> {
    constructor() {
        super("rd.search.service.GetInventoryByDepartmentReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.InventoryByDepartmentSortBy", InventoryByDepartmentSortBy, "INVENTORY_BY_DEPARTMENT_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetInventoryByDepartmentReq>): GetInventoryByDepartmentReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInventoryByDepartmentReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInventoryByDepartmentReq): GetInventoryByDepartmentReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.InventoryByDepartmentSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInventoryByDepartmentReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.InventoryByDepartmentSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInventoryByDepartmentReq
 */
export const GetInventoryByDepartmentReq = new GetInventoryByDepartmentReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInventoryByDepartmentRes$Type extends MessageType<GetInventoryByDepartmentRes> {
    constructor() {
        super("rd.search.service.GetInventoryByDepartmentRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetInventoryByDepartmentRes>): GetInventoryByDepartmentRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInventoryByDepartmentRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInventoryByDepartmentRes): GetInventoryByDepartmentRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInventoryByDepartmentRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInventoryByDepartmentRes
 */
export const GetInventoryByDepartmentRes = new GetInventoryByDepartmentRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInventoryByDepartmentPDFReq$Type extends MessageType<GetInventoryByDepartmentPDFReq> {
    constructor() {
        super("rd.search.service.GetInventoryByDepartmentPDFReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.InventoryByDepartmentSortBy", InventoryByDepartmentSortBy, "INVENTORY_BY_DEPARTMENT_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetInventoryByDepartmentPDFReq>): GetInventoryByDepartmentPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInventoryByDepartmentPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInventoryByDepartmentPDFReq): GetInventoryByDepartmentPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.InventoryByDepartmentSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInventoryByDepartmentPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.InventoryByDepartmentSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInventoryByDepartmentPDFReq
 */
export const GetInventoryByDepartmentPDFReq = new GetInventoryByDepartmentPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInventoryByDepartmentPDFRes$Type extends MessageType<GetInventoryByDepartmentPDFRes> {
    constructor() {
        super("rd.search.service.GetInventoryByDepartmentPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetInventoryByDepartmentPDFRes>): GetInventoryByDepartmentPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInventoryByDepartmentPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInventoryByDepartmentPDFRes): GetInventoryByDepartmentPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInventoryByDepartmentPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInventoryByDepartmentPDFRes
 */
export const GetInventoryByDepartmentPDFRes = new GetInventoryByDepartmentPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInventoryByDepartmentCSVReq$Type extends MessageType<GetInventoryByDepartmentCSVReq> {
    constructor() {
        super("rd.search.service.GetInventoryByDepartmentCSVReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.InventoryByDepartmentSortBy", InventoryByDepartmentSortBy, "INVENTORY_BY_DEPARTMENT_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetInventoryByDepartmentCSVReq>): GetInventoryByDepartmentCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInventoryByDepartmentCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInventoryByDepartmentCSVReq): GetInventoryByDepartmentCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.InventoryByDepartmentSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInventoryByDepartmentCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.InventoryByDepartmentSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInventoryByDepartmentCSVReq
 */
export const GetInventoryByDepartmentCSVReq = new GetInventoryByDepartmentCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInventoryByDepartmentCSVRes$Type extends MessageType<GetInventoryByDepartmentCSVRes> {
    constructor() {
        super("rd.search.service.GetInventoryByDepartmentCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetInventoryByDepartmentCSVRes>): GetInventoryByDepartmentCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInventoryByDepartmentCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInventoryByDepartmentCSVRes): GetInventoryByDepartmentCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInventoryByDepartmentCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInventoryByDepartmentCSVRes
 */
export const GetInventoryByDepartmentCSVRes = new GetInventoryByDepartmentCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInventoryByProductReq$Type extends MessageType<GetInventoryByProductReq> {
    constructor() {
        super("rd.search.service.GetInventoryByProductReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.InventoryByProductSortBy", InventoryByProductSortBy, "INVENTORY_BY_PRODUCT_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetInventoryByProductReq>): GetInventoryByProductReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInventoryByProductReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInventoryByProductReq): GetInventoryByProductReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.InventoryByProductSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInventoryByProductReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.InventoryByProductSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInventoryByProductReq
 */
export const GetInventoryByProductReq = new GetInventoryByProductReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInventoryByProductRes$Type extends MessageType<GetInventoryByProductRes> {
    constructor() {
        super("rd.search.service.GetInventoryByProductRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetInventoryByProductRes>): GetInventoryByProductRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInventoryByProductRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInventoryByProductRes): GetInventoryByProductRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInventoryByProductRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInventoryByProductRes
 */
export const GetInventoryByProductRes = new GetInventoryByProductRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInventoryByProductPDFReq$Type extends MessageType<GetInventoryByProductPDFReq> {
    constructor() {
        super("rd.search.service.GetInventoryByProductPDFReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.InventoryByProductSortBy", InventoryByProductSortBy, "INVENTORY_BY_PRODUCT_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetInventoryByProductPDFReq>): GetInventoryByProductPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInventoryByProductPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInventoryByProductPDFReq): GetInventoryByProductPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.InventoryByProductSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInventoryByProductPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.InventoryByProductSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInventoryByProductPDFReq
 */
export const GetInventoryByProductPDFReq = new GetInventoryByProductPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInventoryByProductPDFRes$Type extends MessageType<GetInventoryByProductPDFRes> {
    constructor() {
        super("rd.search.service.GetInventoryByProductPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetInventoryByProductPDFRes>): GetInventoryByProductPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInventoryByProductPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInventoryByProductPDFRes): GetInventoryByProductPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInventoryByProductPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInventoryByProductPDFRes
 */
export const GetInventoryByProductPDFRes = new GetInventoryByProductPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInventoryByProductCSVReq$Type extends MessageType<GetInventoryByProductCSVReq> {
    constructor() {
        super("rd.search.service.GetInventoryByProductCSVReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.InventoryByProductSortBy", InventoryByProductSortBy, "INVENTORY_BY_PRODUCT_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetInventoryByProductCSVReq>): GetInventoryByProductCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInventoryByProductCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInventoryByProductCSVReq): GetInventoryByProductCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.InventoryByProductSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInventoryByProductCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.InventoryByProductSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInventoryByProductCSVReq
 */
export const GetInventoryByProductCSVReq = new GetInventoryByProductCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInventoryByProductCSVRes$Type extends MessageType<GetInventoryByProductCSVRes> {
    constructor() {
        super("rd.search.service.GetInventoryByProductCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetInventoryByProductCSVRes>): GetInventoryByProductCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInventoryByProductCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInventoryByProductCSVRes): GetInventoryByProductCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInventoryByProductCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInventoryByProductCSVRes
 */
export const GetInventoryByProductCSVRes = new GetInventoryByProductCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTransferDiscrepanciesReq$Type extends MessageType<GetTransferDiscrepanciesReq> {
    constructor() {
        super("rd.search.service.GetTransferDiscrepanciesReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.TransferDiscrepanciesSortBy", TransferDiscrepanciesSortBy, "TRANSFER_DISCREPANCIES_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetTransferDiscrepanciesReq>): GetTransferDiscrepanciesReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTransferDiscrepanciesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTransferDiscrepanciesReq): GetTransferDiscrepanciesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.TransferDiscrepanciesSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTransferDiscrepanciesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.TransferDiscrepanciesSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetTransferDiscrepanciesReq
 */
export const GetTransferDiscrepanciesReq = new GetTransferDiscrepanciesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTransferDiscrepanciesRes$Type extends MessageType<GetTransferDiscrepanciesRes> {
    constructor() {
        super("rd.search.service.GetTransferDiscrepanciesRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetTransferDiscrepanciesRes>): GetTransferDiscrepanciesRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTransferDiscrepanciesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTransferDiscrepanciesRes): GetTransferDiscrepanciesRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTransferDiscrepanciesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetTransferDiscrepanciesRes
 */
export const GetTransferDiscrepanciesRes = new GetTransferDiscrepanciesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTransferDiscrepanciesPDFReq$Type extends MessageType<GetTransferDiscrepanciesPDFReq> {
    constructor() {
        super("rd.search.service.GetTransferDiscrepanciesPDFReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.TransferDiscrepanciesSortBy", TransferDiscrepanciesSortBy, "TRANSFER_DISCREPANCIES_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetTransferDiscrepanciesPDFReq>): GetTransferDiscrepanciesPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTransferDiscrepanciesPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTransferDiscrepanciesPDFReq): GetTransferDiscrepanciesPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.TransferDiscrepanciesSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTransferDiscrepanciesPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.TransferDiscrepanciesSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetTransferDiscrepanciesPDFReq
 */
export const GetTransferDiscrepanciesPDFReq = new GetTransferDiscrepanciesPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTransferDiscrepanciesPDFRes$Type extends MessageType<GetTransferDiscrepanciesPDFRes> {
    constructor() {
        super("rd.search.service.GetTransferDiscrepanciesPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetTransferDiscrepanciesPDFRes>): GetTransferDiscrepanciesPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTransferDiscrepanciesPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTransferDiscrepanciesPDFRes): GetTransferDiscrepanciesPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTransferDiscrepanciesPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetTransferDiscrepanciesPDFRes
 */
export const GetTransferDiscrepanciesPDFRes = new GetTransferDiscrepanciesPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTransferDiscrepanciesCSVReq$Type extends MessageType<GetTransferDiscrepanciesCSVReq> {
    constructor() {
        super("rd.search.service.GetTransferDiscrepanciesCSVReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.TransferDiscrepanciesSortBy", TransferDiscrepanciesSortBy, "TRANSFER_DISCREPANCIES_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetTransferDiscrepanciesCSVReq>): GetTransferDiscrepanciesCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTransferDiscrepanciesCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTransferDiscrepanciesCSVReq): GetTransferDiscrepanciesCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.TransferDiscrepanciesSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTransferDiscrepanciesCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.TransferDiscrepanciesSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetTransferDiscrepanciesCSVReq
 */
export const GetTransferDiscrepanciesCSVReq = new GetTransferDiscrepanciesCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTransferDiscrepanciesCSVRes$Type extends MessageType<GetTransferDiscrepanciesCSVRes> {
    constructor() {
        super("rd.search.service.GetTransferDiscrepanciesCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetTransferDiscrepanciesCSVRes>): GetTransferDiscrepanciesCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTransferDiscrepanciesCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTransferDiscrepanciesCSVRes): GetTransferDiscrepanciesCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTransferDiscrepanciesCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetTransferDiscrepanciesCSVRes
 */
export const GetTransferDiscrepanciesCSVRes = new GetTransferDiscrepanciesCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInteractionsByStaffReq$Type extends MessageType<GetInteractionsByStaffReq> {
    constructor() {
        super("rd.search.service.GetInteractionsByStaffReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.InteractionsByStaffSortBy", InteractionsByStaffSortBy, "INTERACTIONS_BY_STAFF_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetInteractionsByStaffReq>): GetInteractionsByStaffReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInteractionsByStaffReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInteractionsByStaffReq): GetInteractionsByStaffReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.InteractionsByStaffSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInteractionsByStaffReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.InteractionsByStaffSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInteractionsByStaffReq
 */
export const GetInteractionsByStaffReq = new GetInteractionsByStaffReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInteractionsByStaffRes$Type extends MessageType<GetInteractionsByStaffRes> {
    constructor() {
        super("rd.search.service.GetInteractionsByStaffRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetInteractionsByStaffRes>): GetInteractionsByStaffRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInteractionsByStaffRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInteractionsByStaffRes): GetInteractionsByStaffRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInteractionsByStaffRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInteractionsByStaffRes
 */
export const GetInteractionsByStaffRes = new GetInteractionsByStaffRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInteractionsByStaffPDFReq$Type extends MessageType<GetInteractionsByStaffPDFReq> {
    constructor() {
        super("rd.search.service.GetInteractionsByStaffPDFReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.InteractionsByStaffSortBy", InteractionsByStaffSortBy, "INTERACTIONS_BY_STAFF_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetInteractionsByStaffPDFReq>): GetInteractionsByStaffPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInteractionsByStaffPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInteractionsByStaffPDFReq): GetInteractionsByStaffPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.InteractionsByStaffSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInteractionsByStaffPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.InteractionsByStaffSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInteractionsByStaffPDFReq
 */
export const GetInteractionsByStaffPDFReq = new GetInteractionsByStaffPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInteractionsByStaffPDFRes$Type extends MessageType<GetInteractionsByStaffPDFRes> {
    constructor() {
        super("rd.search.service.GetInteractionsByStaffPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetInteractionsByStaffPDFRes>): GetInteractionsByStaffPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInteractionsByStaffPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInteractionsByStaffPDFRes): GetInteractionsByStaffPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInteractionsByStaffPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInteractionsByStaffPDFRes
 */
export const GetInteractionsByStaffPDFRes = new GetInteractionsByStaffPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInteractionsByStaffCSVReq$Type extends MessageType<GetInteractionsByStaffCSVReq> {
    constructor() {
        super("rd.search.service.GetInteractionsByStaffCSVReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.InteractionsByStaffSortBy", InteractionsByStaffSortBy, "INTERACTIONS_BY_STAFF_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetInteractionsByStaffCSVReq>): GetInteractionsByStaffCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInteractionsByStaffCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInteractionsByStaffCSVReq): GetInteractionsByStaffCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.InteractionsByStaffSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInteractionsByStaffCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.InteractionsByStaffSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInteractionsByStaffCSVReq
 */
export const GetInteractionsByStaffCSVReq = new GetInteractionsByStaffCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInteractionsByStaffCSVRes$Type extends MessageType<GetInteractionsByStaffCSVRes> {
    constructor() {
        super("rd.search.service.GetInteractionsByStaffCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetInteractionsByStaffCSVRes>): GetInteractionsByStaffCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInteractionsByStaffCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInteractionsByStaffCSVRes): GetInteractionsByStaffCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInteractionsByStaffCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInteractionsByStaffCSVRes
 */
export const GetInteractionsByStaffCSVRes = new GetInteractionsByStaffCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInteractionsReq$Type extends MessageType<GetInteractionsReq> {
    constructor() {
        super("rd.search.service.GetInteractionsReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.InteractionsSortBy", InteractionsSortBy, "INTERACTIONS_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetInteractionsReq>): GetInteractionsReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInteractionsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInteractionsReq): GetInteractionsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.InteractionsSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInteractionsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.InteractionsSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInteractionsReq
 */
export const GetInteractionsReq = new GetInteractionsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInteractionsRes$Type extends MessageType<GetInteractionsRes> {
    constructor() {
        super("rd.search.service.GetInteractionsRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetInteractionsRes>): GetInteractionsRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInteractionsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInteractionsRes): GetInteractionsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInteractionsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInteractionsRes
 */
export const GetInteractionsRes = new GetInteractionsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInteractionsPDFReq$Type extends MessageType<GetInteractionsPDFReq> {
    constructor() {
        super("rd.search.service.GetInteractionsPDFReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.InteractionsSortBy", InteractionsSortBy, "INTERACTIONS_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetInteractionsPDFReq>): GetInteractionsPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInteractionsPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInteractionsPDFReq): GetInteractionsPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.InteractionsSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInteractionsPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.InteractionsSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInteractionsPDFReq
 */
export const GetInteractionsPDFReq = new GetInteractionsPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInteractionsPDFRes$Type extends MessageType<GetInteractionsPDFRes> {
    constructor() {
        super("rd.search.service.GetInteractionsPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetInteractionsPDFRes>): GetInteractionsPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInteractionsPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInteractionsPDFRes): GetInteractionsPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInteractionsPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInteractionsPDFRes
 */
export const GetInteractionsPDFRes = new GetInteractionsPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInteractionsCSVReq$Type extends MessageType<GetInteractionsCSVReq> {
    constructor() {
        super("rd.search.service.GetInteractionsCSVReq", [
            { no: 1, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } },
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.InteractionsSortBy", InteractionsSortBy, "INTERACTIONS_SORT_BY_"] },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<GetInteractionsCSVReq>): GetInteractionsCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInteractionsCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInteractionsCSVReq): GetInteractionsCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 1:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* rd.analyticsv2.InteractionsSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInteractionsCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 1; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.InteractionsSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInteractionsCSVReq
 */
export const GetInteractionsCSVReq = new GetInteractionsCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInteractionsCSVRes$Type extends MessageType<GetInteractionsCSVRes> {
    constructor() {
        super("rd.search.service.GetInteractionsCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetInteractionsCSVRes>): GetInteractionsCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInteractionsCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInteractionsCSVRes): GetInteractionsCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInteractionsCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetInteractionsCSVRes
 */
export const GetInteractionsCSVRes = new GetInteractionsCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetReportBuilderReq$Type extends MessageType<GetReportBuilderReq> {
    constructor() {
        super("rd.search.service.GetReportBuilderReq", [
            { no: 1, name: "params", kind: "message", T: () => ReportParams }
        ]);
    }
    create(value?: PartialMessage<GetReportBuilderReq>): GetReportBuilderReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetReportBuilderReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetReportBuilderReq): GetReportBuilderReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.reportbuilder.ReportParams params */ 1:
                    message.params = ReportParams.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetReportBuilderReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.reportbuilder.ReportParams params = 1; */
        if (message.params)
            ReportParams.internalBinaryWrite(message.params, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetReportBuilderReq
 */
export const GetReportBuilderReq = new GetReportBuilderReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetReportBuilderRes$Type extends MessageType<GetReportBuilderRes> {
    constructor() {
        super("rd.search.service.GetReportBuilderRes", [
            { no: 1, name: "report", kind: "message", T: () => Report$ },
            { no: 2, name: "compare_report", kind: "message", T: () => Report$ }
        ]);
    }
    create(value?: PartialMessage<GetReportBuilderRes>): GetReportBuilderRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetReportBuilderRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetReportBuilderRes): GetReportBuilderRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.reportbuilder.Report report */ 1:
                    message.report = Report$.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                case /* rd.analyticsv2.reportbuilder.Report compare_report */ 2:
                    message.compareReport = Report$.internalBinaryRead(reader, reader.uint32(), options, message.compareReport);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetReportBuilderRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.reportbuilder.Report report = 1; */
        if (message.report)
            Report$.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.reportbuilder.Report compare_report = 2; */
        if (message.compareReport)
            Report$.internalBinaryWrite(message.compareReport, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.GetReportBuilderRes
 */
export const GetReportBuilderRes = new GetReportBuilderRes$Type();
/**
 * @generated ServiceType for protobuf service rd.search.service.SearchService
 */
export const SearchService = new ServiceType("rd.search.service.SearchService", [
    { name: "SearchBillPayments", options: {}, I: SearchBillPaymentsReq, O: SearchBillPaymentsRes },
    { name: "SearchRefundedBillPayments", options: {}, I: SearchRefundedBillPaymentsReq, O: SearchRefundedBillPaymentsRes },
    { name: "SearchCustomersV2", options: {}, I: SearchCustomersV2Req, O: SearchCustomersV2Res },
    { name: "SearchCustomerContacts", options: {}, I: SearchCustomerContactsReq, O: SearchCustomerContactsRes },
    { name: "SearchExternalTransactions", options: {}, I: SearchExternalTransactionsReq, O: SearchExternalTransactionsRes },
    { name: "SearchInventoryCountProducts", options: {}, I: SearchInventoryCountProductsReq, O: SearchInventoryCountProductsRes },
    { name: "SearchExcludedInventoryCountProducts", options: {}, I: SearchExcludedInventoryCountProductsReq, O: SearchExcludedInventoryCountProductsRes },
    { name: "SearchOrders", options: {}, I: SearchOrdersReq, O: SearchOrdersRes },
    { name: "SearchOrderShipments", options: {}, I: SearchOrderShipmentsReq, O: SearchOrderShipmentsRes },
    { name: "SearchProductsV2", options: {}, I: SearchProductsV2Req, O: SearchProductsV2Res },
    { name: "SearchSalesV2", options: {}, I: SearchSalesV2Req, O: SearchSalesV2Res },
    { name: "SearchReturns", options: {}, I: SearchReturnsReq, O: SearchReturnsRes },
    { name: "SearchOutstandingSales", options: {}, I: SearchOutstandingSalesReq, O: SearchOutstandingSalesRes },
    { name: "SearchTintColors", options: {}, I: SearchTintColorsReq, O: SearchTintColorsRes },
    { name: "SearchTransfers", options: {}, I: SearchTransfersReq, O: SearchTransfersRes },
    { name: "SearchVendors", options: {}, I: SearchVendorsReq, O: SearchVendorsRes },
    { name: "SearchCashTransactions", options: {}, I: SearchCashTransactionsReq, O: SearchCashTransactionsRes },
    { name: "SearchFinancialTransactions", options: {}, I: SearchFinancialTransactionsReq, O: SearchFinancialTransactionsRes },
    { name: "UpdateRecord", options: {}, I: UpdateRecordReq, O: UpdateRecordRes },
    { name: "Reload", options: {}, I: ReloadReq, O: ReloadRes },
    { name: "SearchOrderShipmentsForReconciliation", options: {}, I: SearchOrderShipmentsForReconciliationReq, O: SearchOrderShipmentsForReconciliationRes },
    { name: "SearchInventoryChanges", options: {}, I: SearchInventoryChangesReq, O: SearchInventoryChangesRes },
    { name: "SearchCustomPriceRules", options: {}, I: SearchCustomPriceRulesReq, O: SearchCustomPriceRulesRes },
    { name: "SearchPriceTierRules", options: {}, I: SearchPriceTierRulesReq, O: SearchPriceTierRulesRes },
    { name: "SearchJobs", options: {}, I: SearchJobsReq, O: SearchJobsRes },
    { name: "SearchTags", options: {}, I: SearchTagsReq, O: SearchTagsRes }
]);
/**
 * @generated ServiceType for protobuf service rd.search.service.BulkService
 */
export const BulkService = new ServiceType("rd.search.service.BulkService", [
    { name: "BulkExportCustomers", options: {}, I: BulkExportCustomersReq, O: BulkExportCustomersRes },
    { name: "BulkExportCustomerContacts", options: {}, I: BulkExportCustomerContactsReq, O: BulkExportCustomerContactsRes },
    { name: "BulkExportProducts", options: {}, I: BulkExportProductsReq, O: BulkExportProductsRes },
    { name: "BulkExportProductLabels", options: {}, I: BulkExportProductLabelsReq, O: BulkExportProductLabelsRes },
    { name: "BulkExportVendors", options: {}, I: BulkExportVendorsReq, O: BulkExportVendorsRes },
    { name: "BulkExportInventoryCountProducts", options: {}, I: BulkExportInventoryCountProductsReq, O: BulkExportInventoryCountProductsRes },
    { name: "BulkExportCustomPriceRules", options: {}, I: BulkExportCustomPriceRulesReq, O: BulkExportCustomPriceRulesRes }
]);
/**
 * @generated ServiceType for protobuf service rd.search.service.SearchalyticsService
 */
export const SearchalyticsService = new ServiceType("rd.search.service.SearchalyticsService", [
    { name: "GetSalesByDay", options: {}, I: GetSalesByDayReq, O: GetSalesByDayRes },
    { name: "GetSalesByDayPDF", options: {}, I: GetSalesByDayPDFReq, O: GetSalesByDayPDFRes },
    { name: "GetSalesByDayCSV", options: {}, I: GetSalesByDayCSVReq, O: GetSalesByDayCSVRes },
    { name: "GetCashRegister", options: {}, I: GetCashRegisterReq, O: GetCashRegisterRes },
    { name: "GetCashRegisterPDF", options: {}, I: GetCashRegisterPDFReq, O: GetCashRegisterPDFRes },
    { name: "GetCashRegisterCSV", options: {}, I: GetCashRegisterCSVReq, O: GetCashRegisterCSVRes },
    { name: "GetSales", options: {}, I: GetSalesReq, O: GetSalesRes },
    { name: "GetSalesPDF", options: {}, I: GetSalesPDFReq, O: GetSalesPDFRes },
    { name: "GetSalesCSV", options: {}, I: GetSalesCSVReq, O: GetSalesCSVRes },
    { name: "GetPayments", options: {}, I: GetPaymentsReq, O: GetPaymentsRes },
    { name: "GetPaymentsPDF", options: {}, I: GetPaymentsPDFReq, O: GetPaymentsPDFRes },
    { name: "GetPaymentsCSV", options: {}, I: GetPaymentsCSVReq, O: GetPaymentsCSVRes },
    { name: "GetSoldProducts", options: {}, I: GetSoldProductsReq, O: GetSoldProductsRes },
    { name: "GetSoldProductsPDF", options: {}, I: GetSoldProductsPDFReq, O: GetSoldProductsPDFRes },
    { name: "GetSoldProductsCSV", options: {}, I: GetSoldProductsCSVReq, O: GetSoldProductsCSVRes },
    { name: "GetSalesByCustomer", options: {}, I: GetSalesByCustomerReq, O: GetSalesByCustomerRes },
    { name: "GetSalesByCustomerPDF", options: {}, I: GetSalesByCustomerPDFReq, O: GetSalesByCustomerPDFRes },
    { name: "GetSalesByCustomerCSV", options: {}, I: GetSalesByCustomerCSVReq, O: GetSalesByCustomerCSVRes },
    { name: "GetSalesByJob", options: {}, I: GetSalesByJobReq, O: GetSalesByJobRes },
    { name: "GetSalesByJobPDF", options: {}, I: GetSalesByJobPDFReq, O: GetSalesByJobPDFRes },
    { name: "GetSalesByJobCSV", options: {}, I: GetSalesByJobCSVReq, O: GetSalesByJobCSVRes },
    { name: "GetSalesByProduct", options: {}, I: GetSalesByProductReq, O: GetSalesByProductRes },
    { name: "GetSalesByProductPDF", options: {}, I: GetSalesByProductPDFReq, O: GetSalesByProductPDFRes },
    { name: "GetSalesByProductCSV", options: {}, I: GetSalesByProductCSVReq, O: GetSalesByProductCSVRes },
    { name: "GetSalesByLocation", options: {}, I: GetSalesByLocationReq, O: GetSalesByLocationRes },
    { name: "GetSalesByLocationPDF", options: {}, I: GetSalesByLocationPDFReq, O: GetSalesByLocationPDFRes },
    { name: "GetSalesByLocationCSV", options: {}, I: GetSalesByLocationCSVReq, O: GetSalesByLocationCSVRes },
    { name: "GetSalesByRep", options: {}, I: GetSalesByRepReq, O: GetSalesByRepRes },
    { name: "GetSalesByRepPDF", options: {}, I: GetSalesByRepPDFReq, O: GetSalesByRepPDFRes },
    { name: "GetSalesByRepCSV", options: {}, I: GetSalesByRepCSVReq, O: GetSalesByRepCSVRes },
    { name: "GetCustomerAging", options: {}, I: GetCustomerAgingReq, O: GetCustomerAgingRes },
    { name: "GetCustomerAgingPDF", options: {}, I: GetCustomerAgingPDFReq, O: GetCustomerAgingPDFRes },
    { name: "GetCustomerAgingCSV", options: {}, I: GetCustomerAgingCSVReq, O: GetCustomerAgingCSVRes },
    { name: "GetAgingByJob", options: {}, I: GetAgingByJobReq, O: GetAgingByJobRes },
    { name: "GetAgingByJobPDF", options: {}, I: GetAgingByJobPDFReq, O: GetAgingByJobPDFRes },
    { name: "GetAgingByJobCSV", options: {}, I: GetAgingByJobCSVReq, O: GetAgingByJobCSVRes },
    { name: "GetProfitAndLoss", options: {}, I: GetProfitAndLossReq, O: GetProfitAndLossRes },
    { name: "GetProfitAndLossPDF", options: {}, I: GetProfitAndLossPDFReq, O: GetProfitAndLossPDFRes },
    { name: "GetNewAccountsOpened", options: {}, I: GetNewAccountsOpenedReq, O: GetNewAccountsOpenedRes },
    { name: "GetNewAccountsOpenedPDF", options: {}, I: GetNewAccountsOpenedPDFReq, O: GetNewAccountsOpenedPDFRes },
    { name: "GetNewAccountsOpenedCSV", options: {}, I: GetNewAccountsOpenedCSVReq, O: GetNewAccountsOpenedCSVRes },
    { name: "GetAgingDetail", options: {}, I: GetAgingDetailReq, O: GetAgingDetailRes },
    { name: "GetAgingDetailPDF", options: {}, I: GetAgingDetailPDFReq, O: GetAgingDetailPDFRes },
    { name: "GetAgingDetailCSV", options: {}, I: GetAgingDetailCSVReq, O: GetAgingDetailCSVRes },
    { name: "GetPriceEdits", options: {}, I: GetPriceEditsReq, O: GetPriceEditsRes },
    { name: "GetPriceEditsPDF", options: {}, I: GetPriceEditsPDFReq, O: GetPriceEditsPDFRes },
    { name: "GetPriceEditsCSV", options: {}, I: GetPriceEditsCSVReq, O: GetPriceEditsCSVRes },
    { name: "GetPurchaseJournal", options: {}, I: GetPurchaseJournalReq, O: GetPurchaseJournalRes },
    { name: "GetPurchaseJournalPDF", options: {}, I: GetPurchaseJournalPDFReq, O: GetPurchaseJournalPDFRes },
    { name: "GetPurchaseJournalCSV", options: {}, I: GetPurchaseJournalCSVReq, O: GetPurchaseJournalCSVRes },
    { name: "GetAccountPaymentAdjustments", options: {}, I: GetAccountPaymentAdjustmentsReq, O: GetAccountPaymentAdjustmentsRes },
    { name: "GetAccountPaymentAdjustmentsPDF", options: {}, I: GetAccountPaymentAdjustmentsPDFReq, O: GetAccountPaymentAdjustmentsPDFRes },
    { name: "GetAccountPaymentAdjustmentsCSV", options: {}, I: GetAccountPaymentAdjustmentsCSVReq, O: GetAccountPaymentAdjustmentsCSVRes },
    { name: "GetAdditionalFees", options: {}, I: GetAdditionalFeesReq, O: GetAdditionalFeesRes },
    { name: "GetAdditionalFeesPDF", options: {}, I: GetAdditionalFeesPDFReq, O: GetAdditionalFeesPDFRes },
    { name: "GetAdditionalFeesCSV", options: {}, I: GetAdditionalFeesCSVReq, O: GetAdditionalFeesCSVRes },
    { name: "GetInventoryByDepartment", options: {}, I: GetInventoryByDepartmentReq, O: GetInventoryByDepartmentRes },
    { name: "GetInventoryByDepartmentPDF", options: {}, I: GetInventoryByDepartmentPDFReq, O: GetInventoryByDepartmentPDFRes },
    { name: "GetInventoryByDepartmentCSV", options: {}, I: GetInventoryByDepartmentCSVReq, O: GetInventoryByDepartmentCSVRes },
    { name: "GetInventoryByProduct", options: {}, I: GetInventoryByProductReq, O: GetInventoryByProductRes },
    { name: "GetInventoryByProductPDF", options: {}, I: GetInventoryByProductPDFReq, O: GetInventoryByProductPDFRes },
    { name: "GetInventoryByProductCSV", options: {}, I: GetInventoryByProductCSVReq, O: GetInventoryByProductCSVRes },
    { name: "GetTransferDiscrepancies", options: {}, I: GetTransferDiscrepanciesReq, O: GetTransferDiscrepanciesRes },
    { name: "GetTransferDiscrepanciesPDF", options: {}, I: GetTransferDiscrepanciesPDFReq, O: GetTransferDiscrepanciesPDFRes },
    { name: "GetTransferDiscrepanciesCSV", options: {}, I: GetTransferDiscrepanciesCSVReq, O: GetTransferDiscrepanciesCSVRes },
    { name: "GetInteractionsByStaff", options: {}, I: GetInteractionsByStaffReq, O: GetInteractionsByStaffRes },
    { name: "GetInteractionsByStaffPDF", options: {}, I: GetInteractionsByStaffPDFReq, O: GetInteractionsByStaffPDFRes },
    { name: "GetInteractionsByStaffCSV", options: {}, I: GetInteractionsByStaffCSVReq, O: GetInteractionsByStaffCSVRes },
    { name: "GetInteractions", options: {}, I: GetInteractionsReq, O: GetInteractionsRes },
    { name: "GetInteractionsPDF", options: {}, I: GetInteractionsPDFReq, O: GetInteractionsPDFRes },
    { name: "GetInteractionsCSV", options: {}, I: GetInteractionsCSVReq, O: GetInteractionsCSVRes },
    { name: "GetReportBuilder", options: {}, I: GetReportBuilderReq, O: GetReportBuilderRes }
]);
/**
 * @generated ServiceType for protobuf service rd.search.service.CxaSearchService
 */
export const CxaSearchService = new ServiceType("rd.search.service.CxaSearchService", [
    { name: "CxaSearchSales", options: {}, I: CxaSearchSalesReq, O: CxaSearchSalesRes },
    { name: "CxaSearchOutstandingSales", options: {}, I: CxaSearchOutstandingSalesReq, O: CxaSearchOutstandingSalesRes },
    { name: "CxaSearchReturns", options: {}, I: CxaSearchReturnsReq, O: CxaSearchReturnsRes },
    { name: "CxaSearchJobs", options: {}, I: CxaSearchJobsReq, O: CxaSearchJobsRes }
]);
