/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "analyticsv2/models.proto" (package "rd.analyticsv2", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../google/protobuf/timestamp_pb";
import { Decimal } from "../proto/decimal/models_pb";
import { Money } from "../proto/money/models_pb";
import { UUID } from "../proto/uuid/models_pb";
import { TimestampRange } from "../proto/timestamprange/models_pb";
/**
 * Filters represents a set of filters that can be applied to an analytics report
 * Convenience helpers exist across the codebase that should be updated when new filters are added
 *
 * @generated from protobuf message rd.analyticsv2.Filters
 */
export interface Filters {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter timestamp_range_filter = 1;
     */
    timestampRangeFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter clerk_filter = 2;
     */
    clerkFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter rep_filter = 3;
     */
    repFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter location_filter = 4;
     */
    locationFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter product_filter = 5;
     */
    productFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter customer_filter = 6;
     */
    customerFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter vendor_filter = 7;
     */
    vendorFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter department_filter = 8;
     */
    departmentFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter class_filter = 9;
     */
    classFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter fineline_filter = 10;
     */
    finelineFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter account_filter = 11;
     */
    accountFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter terms_filter = 12;
     */
    termsFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter product_tag_filter = 13;
     */
    productTagFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter customer_tag_filter = 14;
     */
    customerTagFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter job_filter = 15;
     */
    jobFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter secondary_location_filter = 16;
     */
    secondaryLocationFilter?: Filter;
}
/**
 * @generated from protobuf message rd.analyticsv2.Filter
 */
export interface Filter {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter.Type type = 1;
     */
    type: Filter_Type;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter.RangeType range_type = 2;
     */
    rangeType: Filter_RangeType;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange client_timestamp_range = 3;
     */
    clientTimestampRange?: TimestampRange;
    /**
     * @generated from protobuf field: string client_timezone = 4;
     */
    clientTimezone: string;
    /**
     * ID
     *
     * @generated from protobuf field: repeated rd.proto.uuid.UUID ids = 5;
     */
    ids: UUID[];
}
/**
 * @generated from protobuf enum rd.analyticsv2.Filter.Type
 */
export enum Filter_Type {
    /**
     * @generated from protobuf enum value: TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: TYPE_TIMESTAMP_RANGE = 1;
     */
    TIMESTAMP_RANGE = 1,
    /**
     * @generated from protobuf enum value: TYPE_ID = 2;
     */
    ID = 2
}
/**
 * @generated from protobuf enum rd.analyticsv2.Filter.RangeType
 */
export enum Filter_RangeType {
    /**
     * @generated from protobuf enum value: RANGE_TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: RANGE_TYPE_FULL = 1;
     */
    FULL = 1,
    /**
     * @generated from protobuf enum value: RANGE_TYPE_START_ONLY = 2;
     */
    START_ONLY = 2,
    /**
     * @generated from protobuf enum value: RANGE_TYPE_END_ONLY = 3;
     */
    END_ONLY = 3
}
/**
 * ProfitAndLoss represents the key closing information for a
 * given day.
 *
 * @generated from protobuf message rd.analyticsv2.ProfitAndLoss
 */
export interface ProfitAndLoss {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 1;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 2;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange timestamp_range = 3;
     */
    timestampRange?: TimestampRange;
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.ProfitAndLoss.ProductCategoryRevenue category_revenues = 4;
     */
    categoryRevenues: ProfitAndLoss_ProductCategoryRevenue[];
    /**
     * @generated from protobuf field: rd.analyticsv2.ProfitAndLoss.TransactionRevenue sales_revenue = 5;
     */
    salesRevenue?: ProfitAndLoss_TransactionRevenue;
    /**
     * @generated from protobuf field: rd.analyticsv2.ProfitAndLoss.TransactionRevenue returns_revenue = 6;
     */
    returnsRevenue?: ProfitAndLoss_TransactionRevenue;
    /**
     * @generated from protobuf field: rd.analyticsv2.ProfitAndLoss.TransactionRevenue bill_payments_revenue = 7;
     */
    billPaymentsRevenue?: ProfitAndLoss_TransactionRevenue;
    /**
     * @generated from protobuf field: rd.analyticsv2.ProfitAndLoss.TransactionRevenue totals_by_payment_method = 8;
     */
    totalsByPaymentMethod?: ProfitAndLoss_TransactionRevenue;
    /**
     * @generated from protobuf field: rd.proto.money.Money bank_deposit = 9;
     */
    bankDeposit?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money revenue = 10;
     */
    revenue?: Money;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal gross_profit_margin = 11;
     */
    grossProfitMargin?: Decimal; // as XX% rather than .XX
    /**
     * @generated from protobuf field: rd.proto.money.Money card_fees = 12;
     */
    cardFees?: Money;
    /**
     * @generated from protobuf field: string ledger_entries_hash = 13;
     */
    ledgerEntriesHash: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money write_offs = 14;
     */
    writeOffs?: Money;
    /**
     * @generated from protobuf field: int32 count_write_offs = 15;
     */
    countWriteOffs: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money petty_cash = 16;
     */
    pettyCash?: Money;
    /**
     * @generated from protobuf field: int32 count_petty_cash = 17;
     */
    countPettyCash: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money close_outs = 18;
     */
    closeOuts?: Money;
    /**
     * @generated from protobuf field: int32 count_close_outs = 19;
     */
    countCloseOuts: number;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 20;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 21;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 22;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID rep_staff_id = 23;
     */
    repStaffId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.money.Money petty_and_close_outs = 24;
     */
    pettyAndCloseOuts?: Money;
    /**
     * @generated from protobuf field: int32 count_petty_and_close_outs = 25;
     */
    countPettyAndCloseOuts: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money adjusted_cash_total = 26;
     */
    adjustedCashTotal?: Money;
    /**
     * @generated from protobuf field: int32 count_adjusted_cash_total = 27;
     */
    countAdjustedCashTotal: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money adjusted_card_total = 28;
     */
    adjustedCardTotal?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money petty_and_close_outs_minus_fees = 29;
     */
    pettyAndCloseOutsMinusFees?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money adjusted_total_revenue = 30;
     */
    adjustedTotalRevenue?: Money;
    /**
     * @generated from protobuf field: int32 count_adjusted_total_revenue = 31;
     */
    countAdjustedTotalRevenue: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money cogs = 32;
     */
    cogs?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money sales_tax = 33;
     */
    salesTax?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money additional_fees = 34;
     */
    additionalFees?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money gross_profit = 35;
     */
    grossProfit?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money discounts = 36;
     */
    discounts?: Money;
    /**
     * @generated from protobuf field: int32 count_discounts = 37;
     */
    countDiscounts: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money adjusted_account_total = 38;
     */
    adjustedAccountTotal?: Money;
}
/**
 * @generated from protobuf message rd.analyticsv2.ProfitAndLoss.ProductCategoryRevenue
 */
export interface ProfitAndLoss_ProductCategoryRevenue {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money revenue = 2;
     */
    revenue?: Money;
    /**
     * @generated from protobuf field: string id_string = 3;
     */
    idString: string;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal gross_profit_margin = 4;
     */
    grossProfitMargin?: Decimal; // as XX% rather than .XX
    /**
     * @generated from protobuf field: rd.proto.money.Money gross_profit = 5;
     */
    grossProfit?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money cogs = 6;
     */
    cogs?: Money;
}
/**
 * @generated from protobuf message rd.analyticsv2.ProfitAndLoss.TransactionRevenue
 */
export interface ProfitAndLoss_TransactionRevenue {
    /**
     * @generated from protobuf field: rd.proto.money.Money cash = 1;
     */
    cash?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money check = 2;
     */
    check?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money card = 3;
     */
    card?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money account = 4;
     */
    account?: Money;
    /**
     * @generated from protobuf field: int32 count_cash = 5;
     */
    countCash: number;
    /**
     * @generated from protobuf field: int32 count_check = 6;
     */
    countCheck: number;
    /**
     * @generated from protobuf field: int32 count_card = 7;
     */
    countCard: number;
    /**
     * @generated from protobuf field: int32 count_account = 8;
     */
    countAccount: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 9;
     */
    total?: Money;
    /**
     * @generated from protobuf field: int32 count_total = 10;
     */
    countTotal: number;
}
/**
 * @generated from protobuf enum rd.analyticsv2.SalesByDaySortBy
 */
export enum SalesByDaySortBy {
    /**
     * @generated from protobuf enum value: SALES_BY_DAY_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: SALES_BY_DAY_SORT_BY_DATE = 1;
     */
    DATE = 1,
    /**
     * @generated from protobuf enum value: SALES_BY_DAY_SORT_BY_SALES_COUNT = 2;
     */
    SALES_COUNT = 2,
    /**
     * @generated from protobuf enum value: SALES_BY_DAY_SORT_BY_SALES_TOTAL = 3;
     */
    SALES_TOTAL = 3,
    /**
     * @generated from protobuf enum value: SALES_BY_DAY_SORT_BY_RETURNS_COUNT = 4;
     */
    RETURNS_COUNT = 4,
    /**
     * @generated from protobuf enum value: SALES_BY_DAY_SORT_BY_RETURNS_TOTAL = 5;
     */
    RETURNS_TOTAL = 5,
    /**
     * @generated from protobuf enum value: SALES_BY_DAY_SORT_BY_REVENUE = 6;
     */
    REVENUE = 6,
    /**
     * @generated from protobuf enum value: SALES_BY_DAY_SORT_BY_COGS = 7;
     */
    COGS = 7,
    /**
     * @generated from protobuf enum value: SALES_BY_DAY_SORT_BY_GROSS_PROFIT = 8;
     */
    GROSS_PROFIT = 8,
    /**
     * @generated from protobuf enum value: SALES_BY_DAY_SORT_BY_GROSS_MARGIN = 9;
     */
    GROSS_MARGIN = 9
}
/**
 * @generated from protobuf enum rd.analyticsv2.WriteOffsByDaySortBy
 */
export enum WriteOffsByDaySortBy {
    /**
     * @generated from protobuf enum value: WRITE_OFFS_BY_DAY_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: WRITE_OFFS_BY_DAY_SORT_BY_ID = 1;
     */
    ID = 1,
    /**
     * @generated from protobuf enum value: WRITE_OFFS_BY_DAY_SORT_BY_DATE_TIME = 2;
     */
    DATE_TIME = 2,
    /**
     * @generated from protobuf enum value: WRITE_OFFS_BY_DAY_SORT_BY_LOCATION = 3;
     */
    LOCATION = 3,
    /**
     * @generated from protobuf enum value: WRITE_OFFS_BY_DAY_SORT_BY_CLERK = 4;
     */
    CLERK = 4,
    /**
     * @generated from protobuf enum value: WRITE_OFFS_BY_DAY_SORT_BY_CUSTOMER_NAME = 5;
     */
    CUSTOMER_NAME = 5,
    /**
     * @generated from protobuf enum value: WRITE_OFFS_BY_DAY_SORT_BY_AMOUNT = 6;
     */
    AMOUNT = 6,
    /**
     * @generated from protobuf enum value: WRITE_OFFS_BY_DAY_SORT_BY_INVOICE_ALLOCATION = 7;
     */
    INVOICE_ALLOCATION = 7,
    /**
     * @generated from protobuf enum value: WRITE_OFFS_BY_DAY_SORT_BY_FINANCE_CHARGE_ALLOCATION = 8;
     */
    FINANCE_CHARGE_ALLOCATION = 8
}
/**
 * @generated from protobuf enum rd.analyticsv2.PaymentsByDaySortBy
 */
export enum PaymentsByDaySortBy {
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_SORT_BY_DATE_TIME = 1;
     */
    DATE_TIME = 1,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_SORT_BY_LOCATION = 2;
     */
    LOCATION = 2,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_SORT_BY_PAYMENT_METHOD = 3;
     */
    PAYMENT_METHOD = 3,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_SORT_BY_TRANSACTION_TYPE = 4;
     */
    TRANSACTION_TYPE = 4,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_SORT_BY_CLERK = 5;
     */
    CLERK = 5,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_SORT_BY_CUSTOMER_NAME = 6;
     */
    CUSTOMER_NAME = 6,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_SORT_BY_AMOUNT = 7;
     */
    AMOUNT = 7,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_SORT_BY_CARD_FEES = 8;
     */
    CARD_FEES = 8,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_SORT_BY_TXN_ID = 9;
     */
    TXN_ID = 9,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_SORT_BY_CUSTOMER_ID = 10;
     */
    CUSTOMER_ID = 10
}
/**
 * @generated from protobuf enum rd.analyticsv2.PaymentsByDayTransactionType
 */
export enum PaymentsByDayTransactionType {
    /**
     * @generated from protobuf enum value: TRANSACTION_TYPE_UNDEFINED = 0;
     */
    TRANSACTION_TYPE_UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: TRANSACTION_TYPE_SALE = 1;
     */
    TRANSACTION_TYPE_SALE = 1,
    /**
     * @generated from protobuf enum value: TRANSACTION_TYPE_RETURN = 2;
     */
    TRANSACTION_TYPE_RETURN = 2,
    /**
     * @generated from protobuf enum value: TRANSACTION_TYPE_BILL_PAYMENT = 3;
     */
    TRANSACTION_TYPE_BILL_PAYMENT = 3,
    /**
     * @generated from protobuf enum value: TRANSACTION_TYPE_BILL_PAYMENT_REFUND = 4;
     */
    TRANSACTION_TYPE_BILL_PAYMENT_REFUND = 4
}
/**
 * @generated from protobuf enum rd.analyticsv2.PaymentsByDayMethod
 */
export enum PaymentsByDayMethod {
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_METHOD_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_METHOD_CASH = 1;
     */
    CASH = 1,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_METHOD_CARD = 2;
     */
    CARD = 2,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_METHOD_CHECK = 3;
     */
    CHECK = 3
}
/**
 * SortOrder represents the different ways a sort can be ordered, i.e.
 * ascending or descending
 *
 * @generated from protobuf enum rd.analyticsv2.SortOrder
 */
export enum SortOrder {
    /**
     * @generated from protobuf enum value: SORT_ORDER_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: SORT_ORDER_ASC = 1;
     */
    ASC = 1,
    /**
     * @generated from protobuf enum value: SORT_ORDER_DESC = 2;
     */
    DESC = 2
}
/**
 * @generated from protobuf enum rd.analyticsv2.SalesByCustomerSortBy
 */
export enum SalesByCustomerSortBy {
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_NAME = 1;
     */
    NAME = 1,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_IDENTIFIER = 2;
     */
    IDENTIFIER = 2,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_LAST_SALE_DATE = 3;
     */
    LAST_SALE_DATE = 3,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_SALES_COUNT = 4;
     */
    SALES_COUNT = 4,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_SALES_TOTAL = 5;
     */
    SALES_TOTAL = 5,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_AVG_SALE_VALUE = 6;
     */
    AVG_SALE_VALUE = 6,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_RETURNS_COUNT = 7;
     */
    RETURNS_COUNT = 7,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_RETURNS_TOTAL = 8;
     */
    RETURNS_TOTAL = 8,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_REVENUE = 9;
     */
    REVENUE = 9,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_COGS = 10;
     */
    COGS = 10,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_GROSS_PROFIT = 11;
     */
    GROSS_PROFIT = 11,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_GROSS_MARGIN = 12;
     */
    GROSS_MARGIN = 12
}
/**
 * @generated from protobuf enum rd.analyticsv2.SalesByJobSortBy
 */
export enum SalesByJobSortBy {
    /**
     * @generated from protobuf enum value: SALES_BY_JOB_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: SALES_BY_JOB_SORT_BY_CUSTOMER_NAME = 1;
     */
    CUSTOMER_NAME = 1,
    /**
     * @generated from protobuf enum value: SALES_BY_JOB_SORT_BY_CUSTOMER_IDENTIFIER = 2;
     */
    CUSTOMER_IDENTIFIER = 2,
    /**
     * @generated from protobuf enum value: SALES_BY_JOB_SORT_BY_JOB_NAME = 3;
     */
    JOB_NAME = 3,
    /**
     * @generated from protobuf enum value: SALES_BY_JOB_SORT_BY_JOB_IDENTIFIER = 4;
     */
    JOB_IDENTIFIER = 4,
    /**
     * @generated from protobuf enum value: SALES_BY_JOB_SORT_BY_LAST_SALE_DATE = 5;
     */
    LAST_SALE_DATE = 5,
    /**
     * @generated from protobuf enum value: SALES_BY_JOB_SORT_BY_SALES_COUNT = 6;
     */
    SALES_COUNT = 6,
    /**
     * @generated from protobuf enum value: SALES_BY_JOB_SORT_BY_SALES_TOTAL = 7;
     */
    SALES_TOTAL = 7,
    /**
     * @generated from protobuf enum value: SALES_BY_JOB_SORT_BY_AVG_SALE_VALUE = 8;
     */
    AVG_SALE_VALUE = 8,
    /**
     * @generated from protobuf enum value: SALES_BY_JOB_SORT_BY_RETURNS_COUNT = 9;
     */
    RETURNS_COUNT = 9,
    /**
     * @generated from protobuf enum value: SALES_BY_JOB_SORT_BY_RETURNS_TOTAL = 10;
     */
    RETURNS_TOTAL = 10,
    /**
     * @generated from protobuf enum value: SALES_BY_JOB_SORT_BY_REVENUE = 11;
     */
    REVENUE = 11,
    /**
     * @generated from protobuf enum value: SALES_BY_JOB_SORT_BY_COGS = 12;
     */
    COGS = 12,
    /**
     * @generated from protobuf enum value: SALES_BY_JOB_SORT_BY_GROSS_PROFIT = 13;
     */
    GROSS_PROFIT = 13,
    /**
     * @generated from protobuf enum value: SALES_BY_JOB_SORT_BY_GROSS_MARGIN = 14;
     */
    GROSS_MARGIN = 14
}
/**
 * @generated from protobuf enum rd.analyticsv2.SalesSortBy
 */
export enum SalesSortBy {
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_TRANSACTION_ID = 1;
     */
    TRANSACTION_ID = 1,
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_DATE_TIME = 2;
     */
    DATE_TIME = 2,
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_TRANSACTION_TYPE = 3;
     */
    TRANSACTION_TYPE = 3,
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_PAYMENT_METHOD = 4;
     */
    PAYMENT_METHOD = 4,
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_LOCATION = 5;
     */
    LOCATION = 5,
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_CLERK = 6;
     */
    CLERK = 6,
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_CUSTOMER_ID = 7;
     */
    CUSTOMER_ID = 7,
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_CUSTOMER_NAME = 8;
     */
    CUSTOMER_NAME = 8,
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_CUSTOMER_TIER = 9;
     */
    CUSTOMER_TIER = 9,
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_SALES_REP = 10;
     */
    SALES_REP = 10,
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_PO = 11;
     */
    PO = 11,
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_LINE_ITEMS = 12;
     */
    LINE_ITEMS = 12,
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_REVENUE = 13;
     */
    REVENUE = 13,
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_COGS = 14;
     */
    COGS = 14,
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_GROSS_PROFIT = 15;
     */
    GROSS_PROFIT = 15,
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_GROSS_MARGIN = 16;
     */
    GROSS_MARGIN = 16,
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_SALES_TAX = 17;
     */
    SALES_TAX = 17,
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_ADDITIONAL_FEES = 18;
     */
    ADDITIONAL_FEES = 18,
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_CARD_FEES = 19;
     */
    CARD_FEES = 19,
    /**
     * @generated from protobuf enum value: SALES_SORT_BY_CARD_SURCHARGE = 20;
     */
    CARD_SURCHARGE = 20
}
/**
 * @generated from protobuf enum rd.analyticsv2.SalesTransactionType
 */
export enum SalesTransactionType {
    /**
     * @generated from protobuf enum value: SALES_TRANSACTION_TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: SALES_TRANSACTION_TYPE_SALE = 1;
     */
    SALE = 1,
    /**
     * @generated from protobuf enum value: SALES_TRANSACTION_TYPE_RETURN = 2;
     */
    RETURN = 2
}
/**
 * @generated from protobuf enum rd.analyticsv2.SalesPurchaseMethod
 */
export enum SalesPurchaseMethod {
    /**
     * @generated from protobuf enum value: SALES_PURCHASE_METHOD_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: SALES_PURCHASE_METHOD_ACCOUNT = 1;
     */
    ACCOUNT = 1,
    /**
     * @generated from protobuf enum value: SALES_PURCHASE_METHOD_CARD = 2;
     */
    CARD = 2,
    /**
     * @generated from protobuf enum value: SALES_PURCHASE_METHOD_CHECK = 3;
     */
    CHECK = 3,
    /**
     * @generated from protobuf enum value: SALES_PURCHASE_METHOD_CASH = 4;
     */
    CASH = 4
}
/**
 * @generated from protobuf enum rd.analyticsv2.SalesByProductSortBy
 */
export enum SalesByProductSortBy {
    /**
     * @generated from protobuf enum value: SALES_BY_PRODUCT_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: SALES_BY_PRODUCT_SORT_BY_ID = 1;
     */
    ID = 1,
    /**
     * @generated from protobuf enum value: SALES_BY_PRODUCT_SORT_BY_NAME = 2;
     */
    NAME = 2,
    /**
     * @generated from protobuf enum value: SALES_BY_PRODUCT_SORT_BY_DEPARTMENT = 3;
     */
    DEPARTMENT = 3,
    /**
     * @generated from protobuf enum value: SALES_BY_PRODUCT_SORT_BY_CLASS = 4;
     */
    CLASS = 4,
    /**
     * @generated from protobuf enum value: SALES_BY_PRODUCT_SORT_BY_FINELINE = 5;
     */
    FINELINE = 5,
    /**
     * @generated from protobuf enum value: SALES_BY_PRODUCT_SORT_BY_VENDOR = 6;
     */
    VENDOR = 6,
    /**
     * @generated from protobuf enum value: SALES_BY_PRODUCT_SORT_BY_LAST_SALE_DATE = 7;
     */
    LAST_SALE_DATE = 7,
    /**
     * @generated from protobuf enum value: SALES_BY_PRODUCT_SORT_BY_UNITS_SOLD = 8;
     */
    UNITS_SOLD = 8,
    /**
     * @generated from protobuf enum value: SALES_BY_PRODUCT_SORT_BY_SALES_TOTAL = 9;
     */
    SALES_TOTAL = 9,
    /**
     * @generated from protobuf enum value: SALES_BY_PRODUCT_SORT_BY_UNITS_RETURNED = 10;
     */
    UNITS_RETURNED = 10,
    /**
     * @generated from protobuf enum value: SALES_BY_PRODUCT_SORT_BY_RETURNS_TOTAL = 11;
     */
    RETURNS_TOTAL = 11,
    /**
     * @generated from protobuf enum value: SALES_BY_PRODUCT_SORT_BY_REVENUE = 12;
     */
    REVENUE = 12,
    /**
     * @generated from protobuf enum value: SALES_BY_PRODUCT_SORT_BY_COGS = 13;
     */
    COGS = 13,
    /**
     * @generated from protobuf enum value: SALES_BY_PRODUCT_SORT_BY_GROSS_PROFIT = 14;
     */
    GROSS_PROFIT = 14,
    /**
     * @generated from protobuf enum value: SALES_BY_PRODUCT_SORT_BY_GROSS_MARGIN = 15;
     */
    GROSS_MARGIN = 15
}
/**
 * @generated from protobuf enum rd.analyticsv2.SoldProductsSortBy
 */
export enum SoldProductsSortBy {
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_ID = 1;
     */
    ID = 1,
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_PRODUCT_ID = 2;
     */
    PRODUCT_ID = 2,
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_DATE_TIME = 3;
     */
    DATE_TIME = 3,
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_NAME = 4;
     */
    NAME = 4,
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_DEPARTMENT = 5;
     */
    DEPARTMENT = 5,
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_CLASS = 6;
     */
    CLASS = 6,
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_FINELINE = 7;
     */
    FINELINE = 7,
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_VENDOR = 8;
     */
    VENDOR = 8,
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_TAX_STATUS = 9;
     */
    TAX_STATUS = 9,
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_TINT_NAME = 10;
     */
    TINT_NAME = 10,
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_TINT_CODE = 11;
     */
    TINT_CODE = 11,
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_TINT_COMPANY = 12;
     */
    TINT_COMPANY = 12,
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_CUSTOM_TINT = 13;
     */
    CUSTOM_TINT = 13,
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_NOTES = 14;
     */
    NOTES = 14,
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_UNITS = 15;
     */
    UNITS = 15,
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_PRICE = 16;
     */
    PRICE = 16,
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_REVENUE = 17;
     */
    REVENUE = 17,
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_COGS = 18;
     */
    COGS = 18,
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_GROSS_PROFIT = 19;
     */
    GROSS_PROFIT = 19,
    /**
     * @generated from protobuf enum value: SOLD_PRODUCTS_SORT_BY_GROSS_MARGIN = 20;
     */
    GROSS_MARGIN = 20
}
/**
 * @generated from protobuf enum rd.analyticsv2.CashRegisterSortBy
 */
export enum CashRegisterSortBy {
    /**
     * @generated from protobuf enum value: CASH_REGISTER_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: CASH_REGISTER_SORT_BY_TYPE = 1;
     */
    TYPE = 1,
    /**
     * @generated from protobuf enum value: CASH_REGISTER_SORT_BY_AMOUNT = 2;
     */
    AMOUNT = 2,
    /**
     * @generated from protobuf enum value: CASH_REGISTER_SORT_BY_LOCATION = 3;
     */
    LOCATION = 3,
    /**
     * @generated from protobuf enum value: CASH_REGISTER_SORT_BY_CREATED_BY = 4;
     */
    CREATED_BY = 4,
    /**
     * @generated from protobuf enum value: CASH_REGISTER_SORT_BY_CREATED_AT = 5;
     */
    CREATED_AT = 5,
    /**
     * @generated from protobuf enum value: CASH_REGISTER_SORT_BY_IDENTIFIER = 6;
     */
    IDENTIFIER = 6
}
/**
 * @generated from protobuf enum rd.analyticsv2.CashRegisterTransactionType
 */
export enum CashRegisterTransactionType {
    /**
     * @generated from protobuf enum value: CASH_REGISTER_TRANSACTION_TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: CASH_REGISTER_TRANSACTION_TYPE_SALE = 1;
     */
    SALE = 1,
    /**
     * @generated from protobuf enum value: CASH_REGISTER_TRANSACTION_TYPE_RETURN = 2;
     */
    RETURN = 2,
    /**
     * @generated from protobuf enum value: CASH_REGISTER_TRANSACTION_TYPE_BILL_PAYMENT = 3;
     */
    BILL_PAYMENT = 3,
    /**
     * @generated from protobuf enum value: CASH_REGISTER_TRANSACTION_TYPE_BILL_REFUND = 4;
     */
    BILL_REFUND = 4,
    /**
     * @generated from protobuf enum value: CASH_REGISTER_TRANSACTION_TYPE_PETTY_CASH_DEPOSIT = 5;
     */
    PETTY_CASH_DEPOSIT = 5,
    /**
     * @generated from protobuf enum value: CASH_REGISTER_TRANSACTION_TYPE_PETTY_CASH_WITHDRAWAL = 6;
     */
    PETTY_CASH_WITHDRAWAL = 6,
    /**
     * @generated from protobuf enum value: CASH_REGISTER_TRANSACTION_TYPE_CLOSE_OUT_OVER = 7;
     */
    CLOSE_OUT_OVER = 7,
    /**
     * @generated from protobuf enum value: CASH_REGISTER_TRANSACTION_TYPE_CLOSE_OUT_SHORT = 8;
     */
    CLOSE_OUT_SHORT = 8
}
/**
 * @generated from protobuf enum rd.analyticsv2.PriceEditsSortBy
 */
export enum PriceEditsSortBy {
    /**
     * @generated from protobuf enum value: PRICE_EDITS_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: PRICE_EDITS_SORT_BY_TIME = 1;
     */
    TIME = 1,
    /**
     * @generated from protobuf enum value: PRICE_EDITS_SORT_BY_IMPACT = 3;
     */
    IMPACT = 3,
    /**
     * @generated from protobuf enum value: PRICE_EDITS_SORT_BY_MARGIN = 4;
     */
    MARGIN = 4
}
/**
 * @generated from protobuf enum rd.analyticsv2.ProfitAndLossPaymentMethod
 */
export enum ProfitAndLossPaymentMethod {
    /**
     * @generated from protobuf enum value: PROFIT_AND_LOSS_PAYMENT_METHOD_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: PROFIT_AND_LOSS_PAYMENT_METHOD_CASH = 1;
     */
    CASH = 1,
    /**
     * @generated from protobuf enum value: PROFIT_AND_LOSS_PAYMENT_METHOD_CARD = 2;
     */
    CARD = 2,
    /**
     * @generated from protobuf enum value: PROFIT_AND_LOSS_PAYMENT_METHOD_CHECK = 3;
     */
    CHECK = 3,
    /**
     * @generated from protobuf enum value: PROFIT_AND_LOSS_PAYMENT_METHOD_ACCOUNT = 4;
     */
    ACCOUNT = 4
}
/**
 * @generated from protobuf enum rd.analyticsv2.ProfitAndLossTransactionType
 */
export enum ProfitAndLossTransactionType {
    /**
     * @generated from protobuf enum value: PROFIT_AND_LOSS_TRANSACTION_TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: PROFIT_AND_LOSS_TRANSACTION_TYPE_SALE = 1;
     */
    SALE = 1,
    /**
     * @generated from protobuf enum value: PROFIT_AND_LOSS_TRANSACTION_TYPE_RETURN = 2;
     */
    RETURN = 2,
    /**
     * @generated from protobuf enum value: PROFIT_AND_LOSS_TRANSACTION_TYPE_BILL_PAYMENT = 3;
     */
    BILL_PAYMENT = 3,
    /**
     * @generated from protobuf enum value: PROFIT_AND_LOSS_TRANSACTION_TYPE_ADJUSTMENT = 4;
     */
    ADJUSTMENT = 4
}
/**
 * @generated from protobuf enum rd.analyticsv2.NewAccountsOpenedSortBy
 */
export enum NewAccountsOpenedSortBy {
    /**
     * @generated from protobuf enum value: NEW_ACCOUNTS_OPENED_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: NEW_ACCOUNTS_OPENED_SORT_BY_TIME_OPENED = 1;
     */
    TIME_OPENED = 1,
    /**
     * @generated from protobuf enum value: NEW_ACCOUNTS_OPENED_SORT_BY_CREDIT_LIMIT = 2;
     */
    CREDIT_LIMIT = 2
}
/**
 * @generated from protobuf enum rd.analyticsv2.AgingDetailTransactionType
 */
export enum AgingDetailTransactionType {
    /**
     * @generated from protobuf enum value: AGING_DETAIL_TRANSACTION_TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: AGING_DETAIL_TRANSACTION_TYPE_INVOICE = 1;
     */
    INVOICE = 1,
    /**
     * @generated from protobuf enum value: AGING_DETAIL_TRANSACTION_TYPE_FINANCE_CHARGE = 2;
     */
    FINANCE_CHARGE = 2,
    /**
     * @generated from protobuf enum value: AGING_DETAIL_TRANSACTION_TYPE_CREDITS = 3;
     */
    CREDITS = 3
}
/**
 * @generated from protobuf enum rd.analyticsv2.AgingDetailStatus
 */
export enum AgingDetailStatus {
    /**
     * @generated from protobuf enum value: AGING_DETAIL_STATUS_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: AGING_DETAIL_STATUS_CURRENT = 1;
     */
    CURRENT = 1,
    /**
     * @generated from protobuf enum value: AGING_DETAIL_STATUS_LATE = 2;
     */
    LATE = 2
}
/**
 * @generated from protobuf enum rd.analyticsv2.AgingDetailSortBy
 */
export enum AgingDetailSortBy {
    /**
     * @generated from protobuf enum value: AGING_DETAIL_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: AGING_DETAIL_SORT_BY_CUSTOMER_IDENTIFIER = 1;
     */
    CUSTOMER_IDENTIFIER = 1,
    /**
     * @generated from protobuf enum value: AGING_DETAIL_SORT_BY_CUSTOMER_NAME = 2;
     */
    CUSTOMER_NAME = 2,
    /**
     * @generated from protobuf enum value: AGING_DETAIL_SORT_BY_TRANSACTION_DATE = 3;
     */
    TRANSACTION_DATE = 3,
    /**
     * @generated from protobuf enum value: AGING_DETAIL_SORT_BY_TRANSACTION_DUE_DATE = 4;
     */
    TRANSACTION_DUE_DATE = 4,
    /**
     * @generated from protobuf enum value: AGING_DETAIL_SORT_BY_TRANSACTION_TYPE = 5;
     */
    TRANSACTION_TYPE = 5,
    /**
     * @generated from protobuf enum value: AGING_DETAIL_SORT_BY_TOTAL_AMOUNT = 6;
     */
    TOTAL_AMOUNT = 6,
    /**
     * @generated from protobuf enum value: AGING_DETAIL_SORT_BY_AMOUNT_OUTSTANDING = 7;
     */
    AMOUNT_OUTSTANDING = 7,
    /**
     * @generated from protobuf enum value: AGING_DETAIL_SORT_BY_TERMS = 8;
     */
    TERMS = 8,
    /**
     * @generated from protobuf enum value: AGING_DETAIL_SORT_BY_STATUS = 9;
     */
    STATUS = 9,
    /**
     * @generated from protobuf enum value: AGING_DETAIL_SORT_BY_DAYS_OVERDUE = 10;
     */
    DAYS_OVERDUE = 10,
    /**
     * @generated from protobuf enum value: AGING_DETAIL_SORT_BY_LATE_1_TO_30 = 11;
     */
    LATE_1_TO_30 = 11,
    /**
     * @generated from protobuf enum value: AGING_DETAIL_SORT_BY_LATE_31_TO_60 = 12;
     */
    LATE_31_TO_60 = 12,
    /**
     * @generated from protobuf enum value: AGING_DETAIL_SORT_BY_LATE_61_PLUS = 13;
     */
    LATE_61_PLUS = 13
}
/**
 * @generated from protobuf enum rd.analyticsv2.PurchaseJournalSortBy
 */
export enum PurchaseJournalSortBy {
    /**
     * @generated from protobuf enum value: PURCHASE_JOURNAL_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: PURCHASE_JOURNAL_SORT_BY_TRANSACTION_ID = 1;
     */
    TRANSACTION_ID = 1,
    /**
     * @generated from protobuf enum value: PURCHASE_JOURNAL_SORT_BY_DATE = 2;
     */
    DATE = 2,
    /**
     * @generated from protobuf enum value: PURCHASE_JOURNAL_SORT_BY_GL_NUMBER = 3;
     */
    GL_NUMBER = 3,
    /**
     * @generated from protobuf enum value: PURCHASE_JOURNAL_SORT_BY_GL_NAME = 4;
     */
    GL_NAME = 4,
    /**
     * @generated from protobuf enum value: PURCHASE_JOURNAL_SORT_BY_VENDOR_NAME = 5;
     */
    VENDOR_NAME = 5,
    /**
     * @generated from protobuf enum value: PURCHASE_JOURNAL_SORT_BY_CLASS = 6;
     */
    CLASS = 6,
    /**
     * @generated from protobuf enum value: PURCHASE_JOURNAL_SORT_BY_AMOUNT = 7;
     */
    AMOUNT = 7,
    /**
     * @generated from protobuf enum value: PURCHASE_JOURNAL_SORT_BY_INVOICE_NUMBER = 8;
     */
    INVOICE_NUMBER = 8,
    /**
     * @generated from protobuf enum value: PURCHASE_JOURNAL_SORT_BY_ORDER_ID = 9;
     */
    ORDER_ID = 9,
    /**
     * @generated from protobuf enum value: PURCHASE_JOURNAL_SORT_BY_VENDOR_ADDRESS = 10;
     */
    VENDOR_ADDRESS = 10,
    /**
     * @generated from protobuf enum value: PURCHASE_JOURNAL_SORT_BY_DUE_DATE = 11;
     */
    DUE_DATE = 11,
    /**
     * @generated from protobuf enum value: PURCHASE_JOURNAL_SORT_BY_VOUCHERED_AT = 12;
     */
    VOUCHERED_AT = 12
}
/**
 * @generated from protobuf enum rd.analyticsv2.AccountPaymentAdjustmentsSortBy
 */
export enum AccountPaymentAdjustmentsSortBy {
    /**
     * @generated from protobuf enum value: ACCOUNT_PAYMENT_ADJUSTMENTS_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: ACCOUNT_PAYMENT_ADJUSTMENTS_SORT_BY_CUSTOMER_NAME = 1;
     */
    CUSTOMER_NAME = 1,
    /**
     * @generated from protobuf enum value: ACCOUNT_PAYMENT_ADJUSTMENTS_SORT_BY_TRANSACTION_DATE = 2;
     */
    TRANSACTION_DATE = 2,
    /**
     * @generated from protobuf enum value: ACCOUNT_PAYMENT_ADJUSTMENTS_SORT_BY_TRANSACTION_AMOUNT = 3;
     */
    TRANSACTION_AMOUNT = 3,
    /**
     * @generated from protobuf enum value: ACCOUNT_PAYMENT_ADJUSTMENTS_SORT_BY_DUE_DATE = 5;
     */
    DUE_DATE = 5,
    /**
     * @generated from protobuf enum value: ACCOUNT_PAYMENT_ADJUSTMENTS_SORT_BY_TERMS = 6;
     */
    TERMS = 6,
    /**
     * @generated from protobuf enum value: ACCOUNT_PAYMENT_ADJUSTMENTS_SORT_BY_PAYMENT_DATE = 7;
     */
    PAYMENT_DATE = 7
}
/**
 * @generated from protobuf enum rd.analyticsv2.AccountPaymentAdjustmentsTransactionType
 */
export enum AccountPaymentAdjustmentsTransactionType {
    /**
     * @generated from protobuf enum value: ACCOUNT_PAYMENT_ADJUSTMENTS_TRANSACTION_TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: ACCOUNT_PAYMENT_ADJUSTMENTS_TRANSACTION_TYPE_INVOICE = 1;
     */
    INVOICE = 1,
    /**
     * @generated from protobuf enum value: ACCOUNT_PAYMENT_ADJUSTMENTS_TRANSACTION_TYPE_REFUND = 2;
     */
    REFUND = 2
}
/**
 * @generated from protobuf enum rd.analyticsv2.AccountPaymentAdjustmentsAdjustmentType
 */
export enum AccountPaymentAdjustmentsAdjustmentType {
    /**
     * @generated from protobuf enum value: ACCOUNT_PAYMENT_ADJUSTMENTS_ADJUSTMENT_TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: ACCOUNT_PAYMENT_ADJUSTMENTS_ADJUSTMENT_TYPE_EARLY_PAYMENT_DISCOUNT = 1;
     */
    EARLY_PAYMENT_DISCOUNT = 1,
    /**
     * @generated from protobuf enum value: ACCOUNT_PAYMENT_ADJUSTMENTS_ADJUSTMENT_TYPE_EARLY_PAYMENT_DISCOUNT_REVERSAL = 2;
     */
    EARLY_PAYMENT_DISCOUNT_REVERSAL = 2
}
/**
 * @generated from protobuf enum rd.analyticsv2.AdditionalFeesSortBy
 */
export enum AdditionalFeesSortBy {
    /**
     * @generated from protobuf enum value: ADDITIONAL_FEES_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: ADDITIONAL_FEES_SORT_BY_NAME = 1;
     */
    NAME = 1,
    /**
     * @generated from protobuf enum value: ADDITIONAL_FEES_SORT_BY_TRANSACTIONS = 2;
     */
    TRANSACTIONS = 2,
    /**
     * @generated from protobuf enum value: ADDITIONAL_FEES_SORT_BY_REVENUE = 3;
     */
    REVENUE = 3,
    /**
     * @generated from protobuf enum value: ADDITIONAL_FEES_SORT_BY_FEES_COLLECTED = 4;
     */
    FEES_COLLECTED = 4
}
/**
 * @generated from protobuf enum rd.analyticsv2.SalesByLocationSortBy
 */
export enum SalesByLocationSortBy {
    /**
     * @generated from protobuf enum value: SALES_BY_LOCATION_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: SALES_BY_LOCATION_SORT_BY_LOCATION_NAME = 1;
     */
    LOCATION_NAME = 1,
    /**
     * @generated from protobuf enum value: SALES_BY_LOCATION_SORT_BY_SALES_COUNT = 2;
     */
    SALES_COUNT = 2,
    /**
     * @generated from protobuf enum value: SALES_BY_LOCATION_SORT_BY_SALES_TOTAL = 3;
     */
    SALES_TOTAL = 3,
    /**
     * @generated from protobuf enum value: SALES_BY_LOCATION_SORT_BY_RETURNS_COUNT = 4;
     */
    RETURNS_COUNT = 4,
    /**
     * @generated from protobuf enum value: SALES_BY_LOCATION_SORT_BY_RETURNS_TOTAL = 5;
     */
    RETURNS_TOTAL = 5,
    /**
     * @generated from protobuf enum value: SALES_BY_LOCATION_SORT_BY_REVENUE = 6;
     */
    REVENUE = 6,
    /**
     * @generated from protobuf enum value: SALES_BY_LOCATION_SORT_BY_COGS = 7;
     */
    COGS = 7,
    /**
     * @generated from protobuf enum value: SALES_BY_LOCATION_SORT_BY_GROSS_PROFIT = 8;
     */
    GROSS_PROFIT = 8,
    /**
     * @generated from protobuf enum value: SALES_BY_LOCATION_SORT_BY_GROSS_MARGIN = 9;
     */
    GROSS_MARGIN = 9
}
/**
 * @generated from protobuf enum rd.analyticsv2.SalesByRepSortBy
 */
export enum SalesByRepSortBy {
    /**
     * @generated from protobuf enum value: SALES_BY_REP_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: SALES_BY_REP_SORT_BY_REP_NAME = 1;
     */
    REP_NAME = 1,
    /**
     * @generated from protobuf enum value: SALES_BY_REP_SORT_BY_SALES_COUNT = 2;
     */
    SALES_COUNT = 2,
    /**
     * @generated from protobuf enum value: SALES_BY_REP_SORT_BY_SALES_TOTAL = 3;
     */
    SALES_TOTAL = 3,
    /**
     * @generated from protobuf enum value: SALES_BY_REP_SORT_BY_RETURNS_COUNT = 4;
     */
    RETURNS_COUNT = 4,
    /**
     * @generated from protobuf enum value: SALES_BY_REP_SORT_BY_RETURNS_TOTAL = 5;
     */
    RETURNS_TOTAL = 5,
    /**
     * @generated from protobuf enum value: SALES_BY_REP_SORT_BY_REVENUE = 6;
     */
    REVENUE = 6,
    /**
     * @generated from protobuf enum value: SALES_BY_REP_SORT_BY_COGS = 7;
     */
    COGS = 7,
    /**
     * @generated from protobuf enum value: SALES_BY_REP_SORT_BY_GROSS_PROFIT = 8;
     */
    GROSS_PROFIT = 8,
    /**
     * @generated from protobuf enum value: SALES_BY_REP_SORT_BY_GROSS_MARGIN = 9;
     */
    GROSS_MARGIN = 9
}
/**
 * @generated from protobuf enum rd.analyticsv2.JobAgingSortBy
 */
export enum JobAgingSortBy {
    /**
     * @generated from protobuf enum value: JOB_AGING_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: JOB_AGING_SORT_BY_TOTAL_BALANCE = 1;
     */
    TOTAL_BALANCE = 1,
    /**
     * @generated from protobuf enum value: JOB_AGING_SORT_BY_CURRENT_BALANCE = 2;
     */
    CURRENT_BALANCE = 2,
    /**
     * @generated from protobuf enum value: JOB_AGING_SORT_BY_LATE_1_TO_30_BALANCE = 3;
     */
    LATE_1_TO_30_BALANCE = 3,
    /**
     * @generated from protobuf enum value: JOB_AGING_SORT_BY_LATE_31_TO_60_BALANCE = 4;
     */
    LATE_31_TO_60_BALANCE = 4,
    /**
     * @generated from protobuf enum value: JOB_AGING_SORT_BY_LATE_61_PLUS_BALANCE = 5;
     */
    LATE_61_PLUS_BALANCE = 5,
    /**
     * @generated from protobuf enum value: JOB_AGING_SORT_BY_FINANCE_CHARGE_BALANCE = 6;
     */
    FINANCE_CHARGE_BALANCE = 6,
    /**
     * @generated from protobuf enum value: JOB_AGING_SORT_BY_JOB_NAME = 7;
     */
    JOB_NAME = 7,
    /**
     * @generated from protobuf enum value: JOB_AGING_SORT_BY_CUSTOMER_NAME = 8;
     */
    CUSTOMER_NAME = 8
}
/**
 * @generated from protobuf enum rd.analyticsv2.InventoryByDepartmentSortBy
 */
export enum InventoryByDepartmentSortBy {
    /**
     * @generated from protobuf enum value: INVENTORY_BY_DEPARTMENT_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: INVENTORY_BY_DEPARTMENT_SORT_BY_LOCATION_NAME = 1;
     */
    LOCATION_NAME = 1,
    /**
     * @generated from protobuf enum value: INVENTORY_BY_DEPARTMENT_SORT_BY_DEPARTMENT_NAME = 2;
     */
    DEPARTMENT_NAME = 2,
    /**
     * @generated from protobuf enum value: INVENTORY_BY_DEPARTMENT_SORT_BY_ON_HAND_VALUE = 3;
     */
    ON_HAND_VALUE = 3,
    /**
     * @generated from protobuf enum value: INVENTORY_BY_DEPARTMENT_SORT_BY_COMMITTED_VALUE = 4;
     */
    COMMITTED_VALUE = 4,
    /**
     * @generated from protobuf enum value: INVENTORY_BY_DEPARTMENT_SORT_BY_ORDERED_VALUE = 5;
     */
    ORDERED_VALUE = 5
}
/**
 * @generated from protobuf enum rd.analyticsv2.InventoryByProductSortBy
 */
export enum InventoryByProductSortBy {
    /**
     * @generated from protobuf enum value: INVENTORY_BY_PRODUCT_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: INVENTORY_BY_PRODUCT_SORT_BY_LOCATION_NAME = 1;
     */
    LOCATION_NAME = 1,
    /**
     * @generated from protobuf enum value: INVENTORY_BY_PRODUCT_SORT_BY_PRODUCT_NAME = 2;
     */
    PRODUCT_NAME = 2,
    /**
     * @generated from protobuf enum value: INVENTORY_BY_PRODUCT_SORT_BY_ON_HAND_VALUE = 3;
     */
    ON_HAND_VALUE = 3,
    /**
     * @generated from protobuf enum value: INVENTORY_BY_PRODUCT_SORT_BY_COMMITTED_VALUE = 4;
     */
    COMMITTED_VALUE = 4,
    /**
     * @generated from protobuf enum value: INVENTORY_BY_PRODUCT_SORT_BY_ORDERED_VALUE = 5;
     */
    ORDERED_VALUE = 5,
    /**
     * @generated from protobuf enum value: INVENTORY_BY_PRODUCT_SORT_BY_DEPARTMENT_NAME = 6;
     */
    DEPARTMENT_NAME = 6,
    /**
     * @generated from protobuf enum value: INVENTORY_BY_PRODUCT_SORT_BY_CLASS_NAME = 7;
     */
    CLASS_NAME = 7,
    /**
     * @generated from protobuf enum value: INVENTORY_BY_PRODUCT_SORT_BY_FINELINE_NAME = 8;
     */
    FINELINE_NAME = 8,
    /**
     * @generated from protobuf enum value: INVENTORY_BY_PRODUCT_SORT_BY_VENDOR_NAME = 9;
     */
    VENDOR_NAME = 9,
    /**
     * @generated from protobuf enum value: INVENTORY_BY_PRODUCT_SORT_BY_TURNS = 10;
     */
    TURNS = 10
}
/**
 * @generated from protobuf enum rd.analyticsv2.TransferDiscrepanciesSortBy
 */
export enum TransferDiscrepanciesSortBy {
    /**
     * @generated from protobuf enum value: TRANSFER_DISCREPANCIES_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: TRANSFER_DISCREPANCIES_SORT_BY_RECEIVED_AT = 1;
     */
    RECEIVED_AT = 1,
    /**
     * @generated from protobuf enum value: TRANSFER_DISCREPANCIES_SORT_BY_DIFFERENCE = 2;
     */
    DIFFERENCE = 2
}
/**
 * @generated from protobuf enum rd.analyticsv2.InteractionsByStaffSortBy
 */
export enum InteractionsByStaffSortBy {
    /**
     * @generated from protobuf enum value: INTERACTIONS_BY_STAFF_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: INTERACTIONS_BY_STAFF_SORT_BY_STAFF_NAME = 1;
     */
    STAFF_NAME = 1,
    /**
     * @generated from protobuf enum value: INTERACTIONS_BY_STAFF_SORT_BY_INTERACTIONS_COUNT = 2;
     */
    INTERACTIONS_COUNT = 2,
    /**
     * @generated from protobuf enum value: INTERACTIONS_BY_STAFF_SORT_BY_CUSTOMERS_COUNT = 3;
     */
    CUSTOMERS_COUNT = 3
}
/**
 * @generated from protobuf enum rd.analyticsv2.InteractionsSortBy
 */
export enum InteractionsSortBy {
    /**
     * @generated from protobuf enum value: INTERACTIONS_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: INTERACTIONS_SORT_BY_DATE_TIME = 1;
     */
    DATE_TIME = 1,
    /**
     * @generated from protobuf enum value: INTERACTIONS_SORT_BY_STAFF_NAME = 2;
     */
    STAFF_NAME = 2,
    /**
     * @generated from protobuf enum value: INTERACTIONS_SORT_BY_CUSTOMER_ID = 3;
     */
    CUSTOMER_ID = 3,
    /**
     * @generated from protobuf enum value: INTERACTIONS_SORT_BY_CUSTOMER_NAME = 4;
     */
    CUSTOMER_NAME = 4
}
// @generated message type with reflection information, may provide speed optimized methods
class Filters$Type extends MessageType<Filters> {
    constructor() {
        super("rd.analyticsv2.Filters", [
            { no: 1, name: "timestamp_range_filter", kind: "message", T: () => Filter },
            { no: 2, name: "clerk_filter", kind: "message", T: () => Filter },
            { no: 3, name: "rep_filter", kind: "message", T: () => Filter },
            { no: 4, name: "location_filter", kind: "message", T: () => Filter },
            { no: 5, name: "product_filter", kind: "message", T: () => Filter },
            { no: 6, name: "customer_filter", kind: "message", T: () => Filter },
            { no: 7, name: "vendor_filter", kind: "message", T: () => Filter },
            { no: 8, name: "department_filter", kind: "message", T: () => Filter },
            { no: 9, name: "class_filter", kind: "message", T: () => Filter },
            { no: 10, name: "fineline_filter", kind: "message", T: () => Filter },
            { no: 11, name: "account_filter", kind: "message", T: () => Filter },
            { no: 12, name: "terms_filter", kind: "message", T: () => Filter },
            { no: 13, name: "product_tag_filter", kind: "message", T: () => Filter },
            { no: 14, name: "customer_tag_filter", kind: "message", T: () => Filter },
            { no: 15, name: "job_filter", kind: "message", T: () => Filter },
            { no: 16, name: "secondary_location_filter", kind: "message", T: () => Filter }
        ]);
    }
    create(value?: PartialMessage<Filters>): Filters {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Filters>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Filters): Filters {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filter timestamp_range_filter */ 1:
                    message.timestampRangeFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.timestampRangeFilter);
                    break;
                case /* rd.analyticsv2.Filter clerk_filter */ 2:
                    message.clerkFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.clerkFilter);
                    break;
                case /* rd.analyticsv2.Filter rep_filter */ 3:
                    message.repFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.repFilter);
                    break;
                case /* rd.analyticsv2.Filter location_filter */ 4:
                    message.locationFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.locationFilter);
                    break;
                case /* rd.analyticsv2.Filter product_filter */ 5:
                    message.productFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.productFilter);
                    break;
                case /* rd.analyticsv2.Filter customer_filter */ 6:
                    message.customerFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.customerFilter);
                    break;
                case /* rd.analyticsv2.Filter vendor_filter */ 7:
                    message.vendorFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.vendorFilter);
                    break;
                case /* rd.analyticsv2.Filter department_filter */ 8:
                    message.departmentFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.departmentFilter);
                    break;
                case /* rd.analyticsv2.Filter class_filter */ 9:
                    message.classFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.classFilter);
                    break;
                case /* rd.analyticsv2.Filter fineline_filter */ 10:
                    message.finelineFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.finelineFilter);
                    break;
                case /* rd.analyticsv2.Filter account_filter */ 11:
                    message.accountFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.accountFilter);
                    break;
                case /* rd.analyticsv2.Filter terms_filter */ 12:
                    message.termsFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.termsFilter);
                    break;
                case /* rd.analyticsv2.Filter product_tag_filter */ 13:
                    message.productTagFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.productTagFilter);
                    break;
                case /* rd.analyticsv2.Filter customer_tag_filter */ 14:
                    message.customerTagFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.customerTagFilter);
                    break;
                case /* rd.analyticsv2.Filter job_filter */ 15:
                    message.jobFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.jobFilter);
                    break;
                case /* rd.analyticsv2.Filter secondary_location_filter */ 16:
                    message.secondaryLocationFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.secondaryLocationFilter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Filters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filter timestamp_range_filter = 1; */
        if (message.timestampRangeFilter)
            Filter.internalBinaryWrite(message.timestampRangeFilter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter clerk_filter = 2; */
        if (message.clerkFilter)
            Filter.internalBinaryWrite(message.clerkFilter, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter rep_filter = 3; */
        if (message.repFilter)
            Filter.internalBinaryWrite(message.repFilter, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter location_filter = 4; */
        if (message.locationFilter)
            Filter.internalBinaryWrite(message.locationFilter, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter product_filter = 5; */
        if (message.productFilter)
            Filter.internalBinaryWrite(message.productFilter, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter customer_filter = 6; */
        if (message.customerFilter)
            Filter.internalBinaryWrite(message.customerFilter, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter vendor_filter = 7; */
        if (message.vendorFilter)
            Filter.internalBinaryWrite(message.vendorFilter, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter department_filter = 8; */
        if (message.departmentFilter)
            Filter.internalBinaryWrite(message.departmentFilter, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter class_filter = 9; */
        if (message.classFilter)
            Filter.internalBinaryWrite(message.classFilter, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter fineline_filter = 10; */
        if (message.finelineFilter)
            Filter.internalBinaryWrite(message.finelineFilter, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter account_filter = 11; */
        if (message.accountFilter)
            Filter.internalBinaryWrite(message.accountFilter, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter terms_filter = 12; */
        if (message.termsFilter)
            Filter.internalBinaryWrite(message.termsFilter, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter product_tag_filter = 13; */
        if (message.productTagFilter)
            Filter.internalBinaryWrite(message.productTagFilter, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter customer_tag_filter = 14; */
        if (message.customerTagFilter)
            Filter.internalBinaryWrite(message.customerTagFilter, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter job_filter = 15; */
        if (message.jobFilter)
            Filter.internalBinaryWrite(message.jobFilter, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter secondary_location_filter = 16; */
        if (message.secondaryLocationFilter)
            Filter.internalBinaryWrite(message.secondaryLocationFilter, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analyticsv2.Filters
 */
export const Filters = new Filters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Filter$Type extends MessageType<Filter> {
    constructor() {
        super("rd.analyticsv2.Filter", [
            { no: 1, name: "type", kind: "enum", T: () => ["rd.analyticsv2.Filter.Type", Filter_Type, "TYPE_"] },
            { no: 2, name: "range_type", kind: "enum", T: () => ["rd.analyticsv2.Filter.RangeType", Filter_RangeType, "RANGE_TYPE_"] },
            { no: 3, name: "client_timestamp_range", kind: "message", T: () => TimestampRange },
            { no: 4, name: "client_timezone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Filter>): Filter {
        const message = { type: 0, rangeType: 0, clientTimezone: "", ids: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Filter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Filter): Filter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filter.Type type */ 1:
                    message.type = reader.int32();
                    break;
                case /* rd.analyticsv2.Filter.RangeType range_type */ 2:
                    message.rangeType = reader.int32();
                    break;
                case /* rd.proto.timestamprange.TimestampRange client_timestamp_range */ 3:
                    message.clientTimestampRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.clientTimestampRange);
                    break;
                case /* string client_timezone */ 4:
                    message.clientTimezone = reader.string();
                    break;
                case /* repeated rd.proto.uuid.UUID ids */ 5:
                    message.ids.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Filter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filter.Type type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* rd.analyticsv2.Filter.RangeType range_type = 2; */
        if (message.rangeType !== 0)
            writer.tag(2, WireType.Varint).int32(message.rangeType);
        /* rd.proto.timestamprange.TimestampRange client_timestamp_range = 3; */
        if (message.clientTimestampRange)
            TimestampRange.internalBinaryWrite(message.clientTimestampRange, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string client_timezone = 4; */
        if (message.clientTimezone !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.clientTimezone);
        /* repeated rd.proto.uuid.UUID ids = 5; */
        for (let i = 0; i < message.ids.length; i++)
            UUID.internalBinaryWrite(message.ids[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analyticsv2.Filter
 */
export const Filter = new Filter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProfitAndLoss$Type extends MessageType<ProfitAndLoss> {
    constructor() {
        super("rd.analyticsv2.ProfitAndLoss", [
            { no: 1, name: "company_id", kind: "message", T: () => UUID },
            { no: 2, name: "location_id", kind: "message", T: () => UUID },
            { no: 3, name: "timestamp_range", kind: "message", T: () => TimestampRange },
            { no: 4, name: "category_revenues", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProfitAndLoss_ProductCategoryRevenue },
            { no: 5, name: "sales_revenue", kind: "message", T: () => ProfitAndLoss_TransactionRevenue },
            { no: 6, name: "returns_revenue", kind: "message", T: () => ProfitAndLoss_TransactionRevenue },
            { no: 7, name: "bill_payments_revenue", kind: "message", T: () => ProfitAndLoss_TransactionRevenue },
            { no: 8, name: "totals_by_payment_method", kind: "message", T: () => ProfitAndLoss_TransactionRevenue },
            { no: 9, name: "bank_deposit", kind: "message", T: () => Money },
            { no: 10, name: "revenue", kind: "message", T: () => Money },
            { no: 11, name: "gross_profit_margin", kind: "message", T: () => Decimal },
            { no: 12, name: "card_fees", kind: "message", T: () => Money },
            { no: 13, name: "ledger_entries_hash", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "write_offs", kind: "message", T: () => Money },
            { no: 15, name: "count_write_offs", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "petty_cash", kind: "message", T: () => Money },
            { no: 17, name: "count_petty_cash", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "close_outs", kind: "message", T: () => Money },
            { no: 19, name: "count_close_outs", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 20, name: "id", kind: "message", T: () => UUID },
            { no: 21, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 22, name: "customer_id", kind: "message", T: () => UUID },
            { no: 23, name: "rep_staff_id", kind: "message", T: () => UUID },
            { no: 24, name: "petty_and_close_outs", kind: "message", T: () => Money },
            { no: 25, name: "count_petty_and_close_outs", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 26, name: "adjusted_cash_total", kind: "message", T: () => Money },
            { no: 27, name: "count_adjusted_cash_total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 28, name: "adjusted_card_total", kind: "message", T: () => Money },
            { no: 29, name: "petty_and_close_outs_minus_fees", kind: "message", T: () => Money },
            { no: 30, name: "adjusted_total_revenue", kind: "message", T: () => Money },
            { no: 31, name: "count_adjusted_total_revenue", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 32, name: "cogs", kind: "message", T: () => Money },
            { no: 33, name: "sales_tax", kind: "message", T: () => Money },
            { no: 34, name: "additional_fees", kind: "message", T: () => Money },
            { no: 35, name: "gross_profit", kind: "message", T: () => Money },
            { no: 36, name: "discounts", kind: "message", T: () => Money },
            { no: 37, name: "count_discounts", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 38, name: "adjusted_account_total", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<ProfitAndLoss>): ProfitAndLoss {
        const message = { categoryRevenues: [], ledgerEntriesHash: "", countWriteOffs: 0, countPettyCash: 0, countCloseOuts: 0, countPettyAndCloseOuts: 0, countAdjustedCashTotal: 0, countAdjustedTotalRevenue: 0, countDiscounts: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProfitAndLoss>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProfitAndLoss): ProfitAndLoss {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID company_id */ 1:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 2:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* rd.proto.timestamprange.TimestampRange timestamp_range */ 3:
                    message.timestampRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.timestampRange);
                    break;
                case /* repeated rd.analyticsv2.ProfitAndLoss.ProductCategoryRevenue category_revenues */ 4:
                    message.categoryRevenues.push(ProfitAndLoss_ProductCategoryRevenue.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.analyticsv2.ProfitAndLoss.TransactionRevenue sales_revenue */ 5:
                    message.salesRevenue = ProfitAndLoss_TransactionRevenue.internalBinaryRead(reader, reader.uint32(), options, message.salesRevenue);
                    break;
                case /* rd.analyticsv2.ProfitAndLoss.TransactionRevenue returns_revenue */ 6:
                    message.returnsRevenue = ProfitAndLoss_TransactionRevenue.internalBinaryRead(reader, reader.uint32(), options, message.returnsRevenue);
                    break;
                case /* rd.analyticsv2.ProfitAndLoss.TransactionRevenue bill_payments_revenue */ 7:
                    message.billPaymentsRevenue = ProfitAndLoss_TransactionRevenue.internalBinaryRead(reader, reader.uint32(), options, message.billPaymentsRevenue);
                    break;
                case /* rd.analyticsv2.ProfitAndLoss.TransactionRevenue totals_by_payment_method */ 8:
                    message.totalsByPaymentMethod = ProfitAndLoss_TransactionRevenue.internalBinaryRead(reader, reader.uint32(), options, message.totalsByPaymentMethod);
                    break;
                case /* rd.proto.money.Money bank_deposit */ 9:
                    message.bankDeposit = Money.internalBinaryRead(reader, reader.uint32(), options, message.bankDeposit);
                    break;
                case /* rd.proto.money.Money revenue */ 10:
                    message.revenue = Money.internalBinaryRead(reader, reader.uint32(), options, message.revenue);
                    break;
                case /* rd.proto.decimal.Decimal gross_profit_margin */ 11:
                    message.grossProfitMargin = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.grossProfitMargin);
                    break;
                case /* rd.proto.money.Money card_fees */ 12:
                    message.cardFees = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardFees);
                    break;
                case /* string ledger_entries_hash */ 13:
                    message.ledgerEntriesHash = reader.string();
                    break;
                case /* rd.proto.money.Money write_offs */ 14:
                    message.writeOffs = Money.internalBinaryRead(reader, reader.uint32(), options, message.writeOffs);
                    break;
                case /* int32 count_write_offs */ 15:
                    message.countWriteOffs = reader.int32();
                    break;
                case /* rd.proto.money.Money petty_cash */ 16:
                    message.pettyCash = Money.internalBinaryRead(reader, reader.uint32(), options, message.pettyCash);
                    break;
                case /* int32 count_petty_cash */ 17:
                    message.countPettyCash = reader.int32();
                    break;
                case /* rd.proto.money.Money close_outs */ 18:
                    message.closeOuts = Money.internalBinaryRead(reader, reader.uint32(), options, message.closeOuts);
                    break;
                case /* int32 count_close_outs */ 19:
                    message.countCloseOuts = reader.int32();
                    break;
                case /* rd.proto.uuid.UUID id */ 20:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 21:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 22:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.proto.uuid.UUID rep_staff_id */ 23:
                    message.repStaffId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.repStaffId);
                    break;
                case /* rd.proto.money.Money petty_and_close_outs */ 24:
                    message.pettyAndCloseOuts = Money.internalBinaryRead(reader, reader.uint32(), options, message.pettyAndCloseOuts);
                    break;
                case /* int32 count_petty_and_close_outs */ 25:
                    message.countPettyAndCloseOuts = reader.int32();
                    break;
                case /* rd.proto.money.Money adjusted_cash_total */ 26:
                    message.adjustedCashTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.adjustedCashTotal);
                    break;
                case /* int32 count_adjusted_cash_total */ 27:
                    message.countAdjustedCashTotal = reader.int32();
                    break;
                case /* rd.proto.money.Money adjusted_card_total */ 28:
                    message.adjustedCardTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.adjustedCardTotal);
                    break;
                case /* rd.proto.money.Money petty_and_close_outs_minus_fees */ 29:
                    message.pettyAndCloseOutsMinusFees = Money.internalBinaryRead(reader, reader.uint32(), options, message.pettyAndCloseOutsMinusFees);
                    break;
                case /* rd.proto.money.Money adjusted_total_revenue */ 30:
                    message.adjustedTotalRevenue = Money.internalBinaryRead(reader, reader.uint32(), options, message.adjustedTotalRevenue);
                    break;
                case /* int32 count_adjusted_total_revenue */ 31:
                    message.countAdjustedTotalRevenue = reader.int32();
                    break;
                case /* rd.proto.money.Money cogs */ 32:
                    message.cogs = Money.internalBinaryRead(reader, reader.uint32(), options, message.cogs);
                    break;
                case /* rd.proto.money.Money sales_tax */ 33:
                    message.salesTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.salesTax);
                    break;
                case /* rd.proto.money.Money additional_fees */ 34:
                    message.additionalFees = Money.internalBinaryRead(reader, reader.uint32(), options, message.additionalFees);
                    break;
                case /* rd.proto.money.Money gross_profit */ 35:
                    message.grossProfit = Money.internalBinaryRead(reader, reader.uint32(), options, message.grossProfit);
                    break;
                case /* rd.proto.money.Money discounts */ 36:
                    message.discounts = Money.internalBinaryRead(reader, reader.uint32(), options, message.discounts);
                    break;
                case /* int32 count_discounts */ 37:
                    message.countDiscounts = reader.int32();
                    break;
                case /* rd.proto.money.Money adjusted_account_total */ 38:
                    message.adjustedAccountTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.adjustedAccountTotal);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProfitAndLoss, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID company_id = 1; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 2; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange timestamp_range = 3; */
        if (message.timestampRange)
            TimestampRange.internalBinaryWrite(message.timestampRange, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.analyticsv2.ProfitAndLoss.ProductCategoryRevenue category_revenues = 4; */
        for (let i = 0; i < message.categoryRevenues.length; i++)
            ProfitAndLoss_ProductCategoryRevenue.internalBinaryWrite(message.categoryRevenues[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.ProfitAndLoss.TransactionRevenue sales_revenue = 5; */
        if (message.salesRevenue)
            ProfitAndLoss_TransactionRevenue.internalBinaryWrite(message.salesRevenue, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.ProfitAndLoss.TransactionRevenue returns_revenue = 6; */
        if (message.returnsRevenue)
            ProfitAndLoss_TransactionRevenue.internalBinaryWrite(message.returnsRevenue, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.ProfitAndLoss.TransactionRevenue bill_payments_revenue = 7; */
        if (message.billPaymentsRevenue)
            ProfitAndLoss_TransactionRevenue.internalBinaryWrite(message.billPaymentsRevenue, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.ProfitAndLoss.TransactionRevenue totals_by_payment_method = 8; */
        if (message.totalsByPaymentMethod)
            ProfitAndLoss_TransactionRevenue.internalBinaryWrite(message.totalsByPaymentMethod, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money bank_deposit = 9; */
        if (message.bankDeposit)
            Money.internalBinaryWrite(message.bankDeposit, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money revenue = 10; */
        if (message.revenue)
            Money.internalBinaryWrite(message.revenue, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal gross_profit_margin = 11; */
        if (message.grossProfitMargin)
            Decimal.internalBinaryWrite(message.grossProfitMargin, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_fees = 12; */
        if (message.cardFees)
            Money.internalBinaryWrite(message.cardFees, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* string ledger_entries_hash = 13; */
        if (message.ledgerEntriesHash !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.ledgerEntriesHash);
        /* rd.proto.money.Money write_offs = 14; */
        if (message.writeOffs)
            Money.internalBinaryWrite(message.writeOffs, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* int32 count_write_offs = 15; */
        if (message.countWriteOffs !== 0)
            writer.tag(15, WireType.Varint).int32(message.countWriteOffs);
        /* rd.proto.money.Money petty_cash = 16; */
        if (message.pettyCash)
            Money.internalBinaryWrite(message.pettyCash, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* int32 count_petty_cash = 17; */
        if (message.countPettyCash !== 0)
            writer.tag(17, WireType.Varint).int32(message.countPettyCash);
        /* rd.proto.money.Money close_outs = 18; */
        if (message.closeOuts)
            Money.internalBinaryWrite(message.closeOuts, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* int32 count_close_outs = 19; */
        if (message.countCloseOuts !== 0)
            writer.tag(19, WireType.Varint).int32(message.countCloseOuts);
        /* rd.proto.uuid.UUID id = 20; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 21; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_id = 22; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID rep_staff_id = 23; */
        if (message.repStaffId)
            UUID.internalBinaryWrite(message.repStaffId, writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money petty_and_close_outs = 24; */
        if (message.pettyAndCloseOuts)
            Money.internalBinaryWrite(message.pettyAndCloseOuts, writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        /* int32 count_petty_and_close_outs = 25; */
        if (message.countPettyAndCloseOuts !== 0)
            writer.tag(25, WireType.Varint).int32(message.countPettyAndCloseOuts);
        /* rd.proto.money.Money adjusted_cash_total = 26; */
        if (message.adjustedCashTotal)
            Money.internalBinaryWrite(message.adjustedCashTotal, writer.tag(26, WireType.LengthDelimited).fork(), options).join();
        /* int32 count_adjusted_cash_total = 27; */
        if (message.countAdjustedCashTotal !== 0)
            writer.tag(27, WireType.Varint).int32(message.countAdjustedCashTotal);
        /* rd.proto.money.Money adjusted_card_total = 28; */
        if (message.adjustedCardTotal)
            Money.internalBinaryWrite(message.adjustedCardTotal, writer.tag(28, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money petty_and_close_outs_minus_fees = 29; */
        if (message.pettyAndCloseOutsMinusFees)
            Money.internalBinaryWrite(message.pettyAndCloseOutsMinusFees, writer.tag(29, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money adjusted_total_revenue = 30; */
        if (message.adjustedTotalRevenue)
            Money.internalBinaryWrite(message.adjustedTotalRevenue, writer.tag(30, WireType.LengthDelimited).fork(), options).join();
        /* int32 count_adjusted_total_revenue = 31; */
        if (message.countAdjustedTotalRevenue !== 0)
            writer.tag(31, WireType.Varint).int32(message.countAdjustedTotalRevenue);
        /* rd.proto.money.Money cogs = 32; */
        if (message.cogs)
            Money.internalBinaryWrite(message.cogs, writer.tag(32, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money sales_tax = 33; */
        if (message.salesTax)
            Money.internalBinaryWrite(message.salesTax, writer.tag(33, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money additional_fees = 34; */
        if (message.additionalFees)
            Money.internalBinaryWrite(message.additionalFees, writer.tag(34, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money gross_profit = 35; */
        if (message.grossProfit)
            Money.internalBinaryWrite(message.grossProfit, writer.tag(35, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money discounts = 36; */
        if (message.discounts)
            Money.internalBinaryWrite(message.discounts, writer.tag(36, WireType.LengthDelimited).fork(), options).join();
        /* int32 count_discounts = 37; */
        if (message.countDiscounts !== 0)
            writer.tag(37, WireType.Varint).int32(message.countDiscounts);
        /* rd.proto.money.Money adjusted_account_total = 38; */
        if (message.adjustedAccountTotal)
            Money.internalBinaryWrite(message.adjustedAccountTotal, writer.tag(38, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analyticsv2.ProfitAndLoss
 */
export const ProfitAndLoss = new ProfitAndLoss$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProfitAndLoss_ProductCategoryRevenue$Type extends MessageType<ProfitAndLoss_ProductCategoryRevenue> {
    constructor() {
        super("rd.analyticsv2.ProfitAndLoss.ProductCategoryRevenue", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "revenue", kind: "message", T: () => Money },
            { no: 3, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "gross_profit_margin", kind: "message", T: () => Decimal },
            { no: 5, name: "gross_profit", kind: "message", T: () => Money },
            { no: 6, name: "cogs", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<ProfitAndLoss_ProductCategoryRevenue>): ProfitAndLoss_ProductCategoryRevenue {
        const message = { name: "", idString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProfitAndLoss_ProductCategoryRevenue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProfitAndLoss_ProductCategoryRevenue): ProfitAndLoss_ProductCategoryRevenue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* rd.proto.money.Money revenue */ 2:
                    message.revenue = Money.internalBinaryRead(reader, reader.uint32(), options, message.revenue);
                    break;
                case /* string id_string */ 3:
                    message.idString = reader.string();
                    break;
                case /* rd.proto.decimal.Decimal gross_profit_margin */ 4:
                    message.grossProfitMargin = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.grossProfitMargin);
                    break;
                case /* rd.proto.money.Money gross_profit */ 5:
                    message.grossProfit = Money.internalBinaryRead(reader, reader.uint32(), options, message.grossProfit);
                    break;
                case /* rd.proto.money.Money cogs */ 6:
                    message.cogs = Money.internalBinaryRead(reader, reader.uint32(), options, message.cogs);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProfitAndLoss_ProductCategoryRevenue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* rd.proto.money.Money revenue = 2; */
        if (message.revenue)
            Money.internalBinaryWrite(message.revenue, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string id_string = 3; */
        if (message.idString !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.idString);
        /* rd.proto.decimal.Decimal gross_profit_margin = 4; */
        if (message.grossProfitMargin)
            Decimal.internalBinaryWrite(message.grossProfitMargin, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money gross_profit = 5; */
        if (message.grossProfit)
            Money.internalBinaryWrite(message.grossProfit, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money cogs = 6; */
        if (message.cogs)
            Money.internalBinaryWrite(message.cogs, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analyticsv2.ProfitAndLoss.ProductCategoryRevenue
 */
export const ProfitAndLoss_ProductCategoryRevenue = new ProfitAndLoss_ProductCategoryRevenue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProfitAndLoss_TransactionRevenue$Type extends MessageType<ProfitAndLoss_TransactionRevenue> {
    constructor() {
        super("rd.analyticsv2.ProfitAndLoss.TransactionRevenue", [
            { no: 1, name: "cash", kind: "message", T: () => Money },
            { no: 2, name: "check", kind: "message", T: () => Money },
            { no: 3, name: "card", kind: "message", T: () => Money },
            { no: 4, name: "account", kind: "message", T: () => Money },
            { no: 5, name: "count_cash", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "count_check", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "count_card", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "count_account", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "total", kind: "message", T: () => Money },
            { no: 10, name: "count_total", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ProfitAndLoss_TransactionRevenue>): ProfitAndLoss_TransactionRevenue {
        const message = { countCash: 0, countCheck: 0, countCard: 0, countAccount: 0, countTotal: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProfitAndLoss_TransactionRevenue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProfitAndLoss_TransactionRevenue): ProfitAndLoss_TransactionRevenue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money cash */ 1:
                    message.cash = Money.internalBinaryRead(reader, reader.uint32(), options, message.cash);
                    break;
                case /* rd.proto.money.Money check */ 2:
                    message.check = Money.internalBinaryRead(reader, reader.uint32(), options, message.check);
                    break;
                case /* rd.proto.money.Money card */ 3:
                    message.card = Money.internalBinaryRead(reader, reader.uint32(), options, message.card);
                    break;
                case /* rd.proto.money.Money account */ 4:
                    message.account = Money.internalBinaryRead(reader, reader.uint32(), options, message.account);
                    break;
                case /* int32 count_cash */ 5:
                    message.countCash = reader.int32();
                    break;
                case /* int32 count_check */ 6:
                    message.countCheck = reader.int32();
                    break;
                case /* int32 count_card */ 7:
                    message.countCard = reader.int32();
                    break;
                case /* int32 count_account */ 8:
                    message.countAccount = reader.int32();
                    break;
                case /* rd.proto.money.Money total */ 9:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* int32 count_total */ 10:
                    message.countTotal = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProfitAndLoss_TransactionRevenue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money cash = 1; */
        if (message.cash)
            Money.internalBinaryWrite(message.cash, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money check = 2; */
        if (message.check)
            Money.internalBinaryWrite(message.check, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card = 3; */
        if (message.card)
            Money.internalBinaryWrite(message.card, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money account = 4; */
        if (message.account)
            Money.internalBinaryWrite(message.account, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int32 count_cash = 5; */
        if (message.countCash !== 0)
            writer.tag(5, WireType.Varint).int32(message.countCash);
        /* int32 count_check = 6; */
        if (message.countCheck !== 0)
            writer.tag(6, WireType.Varint).int32(message.countCheck);
        /* int32 count_card = 7; */
        if (message.countCard !== 0)
            writer.tag(7, WireType.Varint).int32(message.countCard);
        /* int32 count_account = 8; */
        if (message.countAccount !== 0)
            writer.tag(8, WireType.Varint).int32(message.countAccount);
        /* rd.proto.money.Money total = 9; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* int32 count_total = 10; */
        if (message.countTotal !== 0)
            writer.tag(10, WireType.Varint).int32(message.countTotal);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analyticsv2.ProfitAndLoss.TransactionRevenue
 */
export const ProfitAndLoss_TransactionRevenue = new ProfitAndLoss_TransactionRevenue$Type();
