import { ComponentPropsWithRef, MouseEventHandler, forwardRef } from "react"

import { classNames } from "lib/classNames"

export type ButtonMode = "primary" | "secondary" | "positive"

export interface ButtonProps extends ComponentPropsWithRef<"button"> {
  mode: ButtonMode
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { children, mode, onClick, onMouseDown, className, ...otherProps } =
      props

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
      event.stopPropagation()
      onClick?.(event)
    }

    const handleMouseDown: MouseEventHandler<HTMLButtonElement> = (event) => {
      event.stopPropagation()
      event.preventDefault()
      onMouseDown?.(event)
    }

    return (
      <button
        ref={ref}
        type="button"
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        className={classNames(
          "h-7 px-2 text-sm",
          "rounded-lg",
          "select-none",
          "whitespace-nowrap",
          getModeClassName(mode),
          className
        )}
        {...otherProps}
      >
        {children}
      </button>
    )
  }
)

function getModeClassName(mode: ButtonMode): string {
  const classesByMode: Record<ButtonMode, string> = {
    secondary: classNames(
      "border border-border-accent-light dark:border-border-accent-dark",

      "text-primary",
      "bg-surface-primary-light dark:bg-surface-primary-dark",

      "enabled:hover:bg-surface-accent-light enabled:hover:dark:bg-surface-accent-dark",
      "enabled:hover:text-text-primary-light enabled:hover:dark:text-text-primary-dark",

      "enabled:active:bg-border-primary-light enabled:active:dark:bg-border-primary-dark",
      "enabled:active:text-text-primary-light enabled:active:dark:text-text-primary-dark"
    ),
    positive: classNames(
      "border border-accent-primary-light dark:border-accent-primary-dark",

      "text-accent-primary",
      "bg-surface-primary-light dark:bg-surface-primary-dark",

      "enabled:hover:bg-surface-accent-light enabled:hover:dark:bg-surface-accent-dark",

      "enabled:active:bg-border-primary-light enabled:active:dark:bg-border-primary-dark"
    ),
    primary: classNames(
      "font-semibold",
      "text-text-primary-dark",
      "bg-accent-button-medium-light",

      "enabled:hover:bg-cobalt-400",
      "enabled:hover:text-text-primary-dark",

      "enabled:active:bg-cobalt-400",
      "enabled:active:text-text-primary-dark"
    ),
  }
  return classesByMode[mode]
}
